import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map, fromJS } from 'immutable';
import { reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import { isEqualWith } from 'lodash';

import { getWeatherImage } from 'modules/Helpers';
import { checkEquivalent } from 'modules/Helpers/function';

import {
  mergeRuleTypes,
  toggleRuleTypeCollapsed,
  toggleRuleTypeSelected,
  saveRuleSet,
  setActiveRuleSetId,
  toggleActiveFramesCollapsed,
} from 'actions/ruleSets';
import notify, { NotificationTypes } from 'actions/snackbar';

import Input from 'components/patterns/Input';
import ProgressBar from 'components/patterns/ProgressBar';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Drawer from 'assets/components/presentational/Drawer';
import Accordion from 'assets/components/presentational/Accordion';
import Checkbox from 'assets/components/presentational/Checkbox';
import { ListSubHeader } from 'assets/components/presentational/List';
import Table from 'assets/components/presentational/Table';

import FrameGroupForm from './FrameGroupForm';
import WeatherForm from './WeatherForm';
import WeatherForm2 from './WeatherForm2';
import TemperatureForm from './TemperatureForm';
import TemperatureForm2 from './TemperatureForm2';
import LocationForm from './LocationForm';
import DayPartForm from './DayPartForm';
import TimeOfDayForm from './TimeOfDayForm';
import DayOfWeekForm from './DayOfWeekForm';
import FrameForm from './FrameForm';
import UvIndexForm from './UvIndexForm';
import FlightPropertiesForm from './FlightPropertiesForm';
import DatesForm from './DatesForm';
import TagsForm, { TagsFormFields } from './TagsForm';

import style from './ruleSetDrawer.scss';

class RuleSetDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRuleSetValues: List([]),
    };
  }

  componentDidUpdate(prevProps) {
    const { ruleSet: prevRuleSet } = prevProps;
    const { ruleSet, dispatch } = this.props;
    let { ruleTypes } = this.props;

    // When the drawer is first activated with a new rule-set,
    if (prevRuleSet.get('id') !== ruleSet.get('id')) {
      // Collapse and mark as selected any rule types which have rules applied
      ruleTypes = ruleTypes.map((ruleType) => {
        const hasRules =
          ruleSet.has('rules') &&
          ruleSet.get('rules').findIndex((rule) => rule.get('type') === ruleType.get('type')) > -1;

        if (hasRules) {
          const savedRuleSetValues = ruleSet.get('rules');
          const initialRuleSetValues = ruleTypes.map((ruleType) => {
            const savedRuleSetIndex = savedRuleSetValues.findIndex(
              (savedRuleSet) => savedRuleSet.get('type') === ruleType.get('type'),
            );
            if (savedRuleSetIndex !== -1) {
              return Map({
                id: ruleType.get('id'),
                type: ruleType.get('type'),
                data: savedRuleSetValues.getIn([savedRuleSetIndex, 'data']),
                name: ruleType.get('name'),
                'is-selected': true,
              });
            }
            return Map({
              id: ruleType.get('id'),
              data: ruleType.get('default-value'),
              type: ruleType.get('type'),
              name: ruleType.get('name'),
              'is-selected': false,
            });
          });
          this.setState({ initialRuleSetValues: List(initialRuleSetValues) });
          return ruleType.merge({ 'is-collapsed': true, 'is-selected': true });
        }

        return ruleType.merge({ 'is-collapsed': false, 'is-selected': false });
      });

      dispatch(mergeRuleTypes(ruleTypes));
    }
  }

  handleSave = (data, dispatch) => {
    const { campaign, ruleTypes, resetForm, onCloseClick, t } = this.props;

    const selectedRuleTypes = ruleTypes.filter((ruleType) => ruleType.get('is-selected'));
    if (!selectedRuleTypes.size) {
      this.notifyError(t('Please add conditions to the rule before saving'));
      return;
    }

    data.campaign_id = campaign.get('id'); // eslint-disable-line camelcase

    if (data.rules && data.rules.tags) {
      data.rules.tags.data = data.rules.tags.data.map((tagRule) => {
        const { TAG_OPERATOR, SUB_TAG } = TagsFormFields;

        const isSubTagEmpty = !tagRule[SUB_TAG];
        const isOperatorHasAnyValueSelected = tagRule[TAG_OPERATOR] === 3;
        if (isSubTagEmpty || isOperatorHasAnyValueSelected) {
          return {
            ...tagRule,
            [SUB_TAG]: null,
          };
        }

        return tagRule;
      });
    }

    // Only submit selected rule types

    // eslint-disable-next-line camelcase
    ruleTypes
      .filter((ruleType) => !ruleType.get('is-selected'))
      .forEach((ruleType) => {
        delete data.rules[ruleType.get('id')];
      });

    return dispatch(saveRuleSet(campaign.get('id'), data)).then((ruleSet) => {
      const nextInitialRuleSetValues = ruleTypes.map((ruleType) => {
        const nextRuleSets = fromJS(data.rules);
        const nextRuleSetIndex = nextRuleSets.findIndex(
          (nextRuleSet) => nextRuleSet.get('type') === ruleType.get('type'),
        );
        if (nextRuleSetIndex !== -1) {
          return Map({
            id: ruleType.get('id'),
            data: nextRuleSets.getIn([nextRuleSetIndex, 'data']),
            'is-selected': true,
            type: ruleType.get('type'),
            name: ruleType.get('name'),
          });
        }
        return Map({
          id: ruleType.get('id'),
          data: ruleType.get('default-value'),
          'is-selected': false,
          type: ruleType.get('type'),
          name: ruleType.get('name'),
        });
      });

      dispatch(setActiveRuleSetId(ruleSet.get('id')));
      dispatch(notify(NotificationTypes.SUCCESS, t('The rule has been saved successfully')));
      dispatch(resetForm());

      onCloseClick();

      return this.setState({
        initialRuleSetValues: nextInitialRuleSetValues,
      });
    });
  };

  handleClose = (data) => {
    const { onCancelClick, onCloseClick, ruleTypes } = this.props;
    const { initialRuleSetValues } = this.state;
    let dirtyRules = new List();

    initialRuleSetValues.forEach((initialRuleSetValue) => {
      const activeRuleType = ruleTypes.find((ruleType) => ruleType.get('id') === initialRuleSetValue.get('id'));
      const hasCheckboxChanged = activeRuleType.get('is-selected') !== initialRuleSetValue.get('is-selected');
      const dataValue = data.rules[initialRuleSetValue.get('id')];
      const hasDataChanged = !isEqualWith(dataValue.data, initialRuleSetValue.get('data').toJS(), checkEquivalent);
      if (hasDataChanged || hasCheckboxChanged) {
        dirtyRules = dirtyRules.push(initialRuleSetValue.get('id'));
      }
    });

    if (dirtyRules.size > 0) {
      return onCancelClick();
    }
    return onCloseClick();
  };

  handleAccordionClick = (ruleType) => {
    const { dispatch } = this.props;
    dispatch(toggleRuleTypeCollapsed(ruleType, !ruleType.get('is-collapsed')));
  };

  handleAccordionCheckChanged = (ruleType) => {
    const { dispatch } = this.props;
    dispatch(toggleRuleTypeCollapsed(ruleType, !ruleType.get('is-selected')));
    dispatch(toggleRuleTypeSelected(ruleType, !ruleType.get('is-selected')));
  };

  handleActiveFramesClick = () => {
    const { dispatch, isActiveFramesCollapsed } = this.props;

    dispatch(toggleActiveFramesCollapsed(!isActiveFramesCollapsed));
  };

  notifyError = (message) => {
    const { dispatch } = this.props;

    dispatch(notify(NotificationTypes.ERROR, message));
  };

  isNewRuleSet = (ruleSet) => !ruleSet.get('id', '').length;

  getRuleTypeClassName = (ruleType) => {
    switch (ruleType.get('id')) {
      case 'weather':
        return style.ruleTypeAccordionWeather;
      case 'weather2':
        return style.ruleTypeAccordionWeather2;
      case 'temperature':
        return style.ruleTypeAccordionTemperature;
      case 'temperature2':
        return style.ruleTypeAccordionTemperature2;
      case 'location':
        return style.ruleTypeAccordionLocation;
      case 'daypart':
        return style.ruleTypeAccordionDayPart;
      case 'timeofday':
        return style.ruleTypeAccordionTimeOfDay;
      case 'frame':
        return style.ruleTypeAccordionFrame;
      case 'dayofweek':
        return style.ruleTypeAccordionDayOfWeek;
      case 'uvindex':
        return style.ruleTypeAccordionUvindex;
      case 'flightproperties':
        return style.ruleTypeAccordionFlightProperties;
      case 'dates':
        return style.ruleTypeAccordionDates;
      case 'tags':
        return style.ruleTypeAccordionTags;
      default:
        return style.ruleTypeAccordion;
    }
  };

  renderWeatherIcon = (condition) => {
    const icon = getWeatherImage(condition);

    if (icon) {
      return <img alt={condition} className={style.weatherIcon} title={condition} src={icon} />;
    }
  };

  getActiveFramesForDataTable = (ruleSet) => {
    let frames = [];

    if (List.isList(ruleSet.get('frames'))) {
      frames = ruleSet
        .get('frames')
        .map((frame) => {
          // handle null locations and weather
          let location = frame.get('location', new Map());
          location = !Map.isMap(location) ? new Map() : location;
          let weather = location.get('weather', new Map());
          weather = !Map.isMap(weather) ? new Map() : weather;

          return {
            'external Id': frame.get('external-id', ''),
            name: frame.get('name', ''),
            spec: frame.getIn(['specification', 'name'], ''),
            size: `${frame.getIn(['specification', 'width'], '')} x ${frame.getIn(['specification', 'height'], '')}`,
            location: location.size
              ? `${location.get('route', '')}, ${location.get('locality', '')}, ${location.get('country-code', '')}`
              : '',
            weather: this.renderWeatherIcon(weather.get('description', '')),
            temp: weather.get('temperature', ''),
            uvIndex: weather.get('uv-index', 0).toString(),
            localTime: location.get('local-time', ''),
          };
        })
        .toJS();
    }

    return frames;
  };

  renderActiveFrames = (ruleSet) => {
    const { isActiveFramesCollapsed, t } = this.props;

    const frameModel = {
      'external Id': { type: String, title: 'External Id' },
      name: { type: String, title: 'Name' },
      spec: { type: String, title: 'Spec' },
      size: { type: String, title: 'Size' },
      location: { type: String, title: 'Location' },
      weather: { type: Object, title: 'Weather' },
      temp: { type: Number, title: 'Temp' },
      uvIndex: { type: Number, title: 'UV Index' },
      localTime: { type: String, title: 'Local Time' },
    };

    let label = `${t('Frames this rule applies to')}: `;
    const frames = this.getActiveFramesForDataTable(ruleSet);

    label += frames.length;

    return (
      <Accordion
        collapsed={isActiveFramesCollapsed && frames.length > 0}
        disabled={!frames.length}
        label={label}
        onClick={this.handleActiveFramesClick}
      >
        <Table model={frameModel} source={frames} />
      </Accordion>
    );
  };

  renderProgress = () => {
    const { isFetchingRuleSet, isSavingRuleSet } = this.props;

    if (isFetchingRuleSet || isSavingRuleSet) {
      return (
        <div className={style.progress}>
          <ProgressBar />
        </div>
      );
    }
  };

  renderContent = () => {
    const { fields, isFetchingRuleSet, isSavingRuleSet, handleSubmit, ruleSet, ruleTypes, t } = this.props;
    const nameTip = this.isNewRuleSet(ruleSet) ? ` (${t('Name your rule tip')})` : '';

    if (!isFetchingRuleSet) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input autoFocus className={style.nameInput} hint={t('Name your rule').concat(nameTip)} {...fields.name} />

          <ListSubHeader caption={t('Create your rule by adding one or many of the following as needed')} />

          <div className={style.rulesContainer}>
            {ruleTypes.map((ruleType, key) => this.renderRuleType(ruleType, key))}

            {this.renderActiveFrames(ruleSet)}
          </div>

          <div className={style.editingButtons}>
            <Button
              className={style.editingButton}
              label={this.isNewRuleSet(ruleSet) ? t('Save') : t('Update')}
              onClick={handleSubmit((data, dispatch) => this.handleSave(data, dispatch))}
              type="button"
              disabled={isSavingRuleSet}
            />

            <Button
              className={style.editingButton}
              label={t('Close')}
              theme={ButtonThemes.WHITE}
              onClick={handleSubmit((data) => this.handleClose(data))}
              type="button"
              disabled={isSavingRuleSet}
            />
          </div>
        </form>
      );
    }

    return null;
  };

  render() {
    const { onCloseClick, showEditDrawer } = this.props;

    return (
      <Drawer active={showEditDrawer} onOverlayClick={onCloseClick}>
        {this.renderProgress()}
        {this.renderContent()}
      </Drawer>
    );
  }

  renderWeatherForm = (ruleType, fields) => <WeatherForm fields={fields} ruleType={ruleType} />;

  renderWeatherForm2 = (ruleType, fields) => <WeatherForm2 fields={fields} ruleType={ruleType} />;

  renderTemperatureForm = (ruleType, fields) => (
    <TemperatureForm fields={fields} ruleType={ruleType} notifyError={this.notifyError} />
  );

  renderTemperatureForm2 = (ruleType, fields) => (
    <TemperatureForm2 fields={fields} ruleType={ruleType} notifyError={this.notifyError} />
  );

  renderLocationForm = (ruleType, fields) => {
    const { user } = this.props;

    return <LocationForm defaultLocation={user.get('location', new Map())} fields={fields} ruleType={ruleType} />;
  };

  renderDayPartForm = (ruleType, fields) => <DayPartForm fields={fields} ruleType={ruleType} />;

  renderTimeOfDayForm = (ruleType, fields) => (
    <TimeOfDayForm fields={fields} ruleType={ruleType} notifyError={this.notifyError} />
  );

  renderDayOfWeekForm = (ruleType, fields) => <DayOfWeekForm fields={fields} ruleType={ruleType} />;

  renderFrameForm = (ruleType, fields) => <FrameForm fields={fields} ruleType={ruleType} />;

  renderUvIndexForm = (ruleType, fields) => (
    <UvIndexForm fields={fields} ruleType={ruleType} notifyError={this.notifyError} />
  );

  renderFlightPropertiesForm = (ruleType, fields) => {
    const { dispatch, translations, airports, airlines, isFetchingi18n } = this.props;

    return (
      <FlightPropertiesForm
        airports={airports}
        airlines={airlines}
        translations={translations}
        dispatch={dispatch}
        fields={fields}
        isFetchingi18n={isFetchingi18n}
        ruleType={ruleType}
      />
    );
  };

  renderDatesForm = (ruleType, fields) => (
    <DatesForm fields={fields} ruleType={ruleType} notifyError={this.notifyError} />
  );

  renderTagsForm = (ruleType, fields, suggestions) => {
    const { tagKeys, user } = this.props;

    return (
      <TagsForm
        user={user}
        tagKeys={tagKeys}
        fields={fields}
        ruleType={ruleType}
        suggestions={suggestions}
        notifyError={this.notifyError}
      />
    );
  };

  renderFrameGroupForm = (ruleType, fields) => <FrameGroupForm fields={fields} ruleType={ruleType} />;

  getRuleTypeSelectedClassName = (ruleType) => {
    if (ruleType.get('is-selected')) {
      return style.ruleTypeSelected;
    }
    return null;
  };

  renderRuleType = (ruleType, key) => {
    const { isSavingRuleSet, t } = this.props;
    const label = `${t('Add rule by')} ${t(ruleType.get('name'))}`;

    return (
      <div key={key}>
        <Accordion
          className={`${this.getRuleTypeClassName(ruleType)} ${this.getRuleTypeSelectedClassName(ruleType)}`}
          collapsed={ruleType.get('is-collapsed')}
          disabled={isSavingRuleSet}
          onClick={() => this.handleAccordionClick(ruleType)}
          label={label}
        >
          <Checkbox
            checked={ruleType.get('is-selected')}
            className={style.ruleTypeSelector}
            onChange={(checked) => this.handleAccordionCheckChanged(ruleType, checked)}
          />

          {this.renderRuleTypeForm(ruleType)}
        </Accordion>
      </div>
    );
  };

  renderRuleTypeForm = (ruleType) => {
    const { fields } = this.props;

    if (typeof fields.rules === 'undefined') {
      return null;
    }

    switch (ruleType.get('id')) {
      case 'weather':
        return this.renderWeatherForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'weather2':
        return this.renderWeatherForm2(ruleType, fields.rules[ruleType.get('id')].data);
      case 'temperature':
        return this.renderTemperatureForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'temperature2':
        return this.renderTemperatureForm2(ruleType, fields.rules[ruleType.get('id')].data);
      case 'location':
        return this.renderLocationForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'daypart':
        return this.renderDayPartForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'timeofday':
        return this.renderTimeOfDayForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'dayofweek':
        return this.renderDayOfWeekForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'frame':
        return this.renderFrameForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'uvindex':
        return this.renderUvIndexForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'flightproperties':
        return this.renderFlightPropertiesForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'dates':
        return this.renderDatesForm(ruleType, fields.rules[ruleType.get('id')].data);
      case 'tags':
        return this.renderTagsForm(
          ruleType,
          fields.rules[ruleType.get('id')].data,
          fields.rules[ruleType.get('id')].initialTagSuggestions,
        );
      case 'framegroup':
        return this.renderFrameGroupForm(ruleType, fields.rules[ruleType.get('id')].data);
      default:
        return null;
    }
  };
}

RuleSetDrawer.propTypes = {
  tagKeys: PropTypes.instanceOf(List).isRequired,
  airports: PropTypes.instanceOf(List).isRequired,
  airlines: PropTypes.instanceOf(List).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  error: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translations: PropTypes.shape({ continents: PropTypes.instanceOf(List) }).isRequired,
  isActiveFramesCollapsed: PropTypes.bool.isRequired,
  isFetchingi18n: PropTypes.bool.isRequired,
  isFetchingRuleSet: PropTypes.bool.isRequired,
  isSavingRuleSet: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  ruleSet: PropTypes.instanceOf(Map).isRequired,
  ruleTypes: PropTypes.instanceOf(List).isRequired,
  showEditDrawer: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  values: PropTypes.object,
};

RuleSetDrawer.defaultProps = {
  error: false,
};

export default withNamespaces(['common', 'rules'], { wait: false })(
  reduxForm(
    {
      form: 'ruleSet',
    },
    (state) => ({
      // mapStateToProps
      airports: state.transport.airports,
      airlines: state.transport.airlines,
      campaign: state.campaign.campaign,
      translations: state.i18n,
      tagKeys: state.tagKeys.list,
      isFetchingRuleSet: state.ruleSets.isFetchingRuleSet,
      isFetchingi18n: state.i18n.isFetching,
      isSavingRuleSet: state.ruleSets.isSavingRuleSet,
      isActiveFramesCollapsed: state.ruleSets.isActiveFramesCollapsed,
      ruleTypes: state.ruleSets.ruleTypes,
      showEditDrawer: state.ruleSets.showEditDrawer,
      user: state.auth.user,
    }),
  )(RuleSetDrawer),
);
