import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  getBookings,
  getPromotedTotal,
  getPromotingTotal,
  getUnpromotedTotal,
  getPromotableTotal,
  getHaveErrorsTotal,
  isCampaignFullyPromoted,
  isCampaignFullyPromotable,
  isCampaignPartiallyPromoted,
  isCampaignPartiallyPromotable,
  isCampaignUnpromoted,
} from 'store/bookings/selectors';

import { fetchCampaign } from 'actions/campaign';
import {
  fetchBookings,
  promoteBooking,
  bulkPromoteBookings,
  attachBooking,
  detachBooking,
} from 'store/bookings/actions';
import { setActiveSection } from 'actions/campaign/navigation';

import Bookings from './Bookings';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  bookings: getBookings(state),
  promotedTotal: getPromotedTotal(state),
  promotingTotal: getPromotingTotal(state),
  unpromotedTotal: getUnpromotedTotal(state),
  promotableTotal: getPromotableTotal(state),
  haveErrorsTotal: getHaveErrorsTotal(state),
  isFullyPromotable: isCampaignFullyPromotable(state),
  isPartiallyPromotable: isCampaignPartiallyPromotable(state),
  isFullyPromoted: isCampaignFullyPromoted(state),
  isPartiallyPromoted: isCampaignPartiallyPromoted(state),
  isUnpromoted: isCampaignUnpromoted(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveSection,
      fetchCampaign,
      fetchBookings,
      promoteBooking,
      bulkPromoteBookings,
      attachBooking,
      detachBooking,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
