import React from 'react';
import PropTypes from 'prop-types';
import AuthLite from 'assets/components/containers/AuthLite';

const AuthLiteDropZone = ({ authenticateUser, params: { token } }) => (
  <AuthLite authenticateUser={authenticateUser} token={token} baseUrl="/drop-zone/view" />
);

AuthLiteDropZone.propTypes = {
  authenticateUser: PropTypes.func.isRequired,
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuthLiteDropZone;
