import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './columnRow.scss';

class ColumnRow extends Component {
  render() {
    const { children } = this.props;

    return <div className={style.pageRow}>{children}</div>;
  }
}

export default ColumnRow;

ColumnRow.propTypes = {
  children: PropTypes.node,
};
