import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { userHasCampaignPermission } from 'store/user/helpers';

import SubNavigation from 'assets/components/presentational/SubNavigation';

export const SubNavigationLabels = {
  ALL_CREATIVE: 'All Creatives',
  MODERATION_SUMMARY: 'Moderation Summary',
  ENHANCED_MODERATION: 'Enhanced Moderation',
  SCHEDULE: 'Schedule',
  PLAYLISTS: 'Playlists',
  FAILED_HISTORY: 'Failed History',
};

class SubNavigationCreative extends Component {
  items = () => {
    const { user, campaignId } = this.props;

    const nav = [
      {
        label: SubNavigationLabels.ALL_CREATIVE,
        to: `campaigns/${campaignId}/creative`,
      },
    ];

    if (userHasCampaignPermission(user, campaignId, 'creative_decisions')) {
      nav.push({
        label: SubNavigationLabels.MODERATION_SUMMARY,
        to: `campaigns/${campaignId}/creative/moderation/summary`,
      });
    }

    if (userHasCampaignPermission(user, campaignId, 'enhanced_moderation')) {
      nav.push({
        label: SubNavigationLabels.ENHANCED_MODERATION,
        to: `campaigns/${campaignId}/creative/moderation/manage`,
      });
    }

    nav.push({
      label: SubNavigationLabels.SCHEDULE,
      to: `campaigns/${campaignId}/schedule`,
    });

    if (userHasCampaignPermission(user, campaignId, 'upload_creative')) {
      nav.push({
        label: SubNavigationLabels.PLAYLISTS,
        to: `campaigns/${campaignId}/playlists`,
      });
    }

    nav.push({
      label: SubNavigationLabels.FAILED_HISTORY,
      to: `campaigns/${campaignId}/creative/failed-history`,
    });

    return nav;
  };

  render() {
    const { active } = this.props;

    return <SubNavigation active={active} labels={SubNavigationLabels} items={this.items()} />;
  }
}

SubNavigationCreative.propTypes = {
  active: PropTypes.string,
  campaignId: PropTypes.string,
  user: PropTypes.instanceOf(Map),
};

SubNavigationCreative.defaultProps = {
  action: '',
  campaignId: '',
  user: new Map(),
};

export default SubNavigationCreative;
