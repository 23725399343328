import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';

import { IconTypes } from 'assets/components/presentational/Icon';
import AlertBar, { AlertBarColors } from 'assets/components/presentational/Campaign/AlertBar';

const WeatherAlert = ({ campaign, t }) => {
  if (campaign.get('requires-weather')) {
    return null;
  }

  const text = t(
    "If you need weather data, the field '{{fieldName}}' should be turned on from the campaign settings page",
    {
      fieldName: t('Weather opt-in'),
    },
  );
  return <AlertBar icon={IconTypes.INFO_OUTLINE} text={text} color={AlertBarColors.PRIMARY_BLUE} />;
};

export default withNamespaces(['common', 'campaignForm'], { wait: false })(WeatherAlert);

WeatherAlert.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};

WeatherAlert.defaultProps = {};
