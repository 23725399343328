import SearchService from 'modules/Search';
import { transformCampaign, transformBooking } from './helpers';

const searchCampaigns = async (query) => {
  try {
    const hits = await SearchService.searchCampaigns(query);
    return hits.map((hit) => transformCampaign(hit));
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return [];
  }
};

const searchBookings = async (query, businessUnits) => {
  try {
    const hits = await SearchService.searchBookings(query, businessUnits);
    return hits.map((hit) => transformBooking(hit));
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return [];
  }
};

const searchTags = async (query) => {
  try {
    return await SearchService.searchTags(query);
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return [];
  }
};

const searchSubTags = async (query, tagId) => {
  try {
    return await SearchService.searchSubTags(query, tagId);
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return [];
  }
};

export { searchCampaigns, searchBookings, searchTags, searchSubTags };
