import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { error as notifyError } from 'actions/notify';
import {
  clearCampaignRelation,
  fetchCampaign,
  deleteRelationRequest,
  setActiveCampaignId,
  setFrameSpecsFilter,
  clearFrameSpecsFilter,
} from 'actions/campaign';
import { setActiveSection } from 'actions/campaign/navigation';
import {
  addCreative,
  replaceCreative,
  fetchFrameSpecifications,
  resetState,
  setActiveFrameSpec,
  fetchCreativesForSingleFrameSpecification,
} from 'actions/creative';
import { addUpload, updateUpload, removeUpload, resetUploads } from 'store/uploads/actions';
import { getFrameSpecifications } from 'selectors/campaign';

import Creative from 'assets/components/containers/Campaign/Creative/Creative';

const mapStateToProps = (state) => ({
  activeCampaignId: state.campaign.activeCampaignId,
  activeFrameSpecId: state.creative.activeFrameSpecId,
  areCreativesUpdating: state.creative.areCreativesUpdating,
  campaign: state.campaign.campaign,
  frameSpecifications: getFrameSpecifications(state.campaign.campaign),
  user: state.auth.user,
  uploads: state.uploads,
  frameSpecsFilter: state.campaign.frameSpecsFilter,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addCreative,
      addUpload,
      deleteCreative: deleteRelationRequest,
      fetchCampaign,
      fetchFrameSpecifications,
      notifyError,
      removeUpload,
      replaceCreative,
      resetUploads,
      setActiveFrameSpec,
      setActiveSection,
      setActiveCampaignId,
      updateUpload,
      clearCampaignRelation,
      resetState,
      fetchCreativesForSingleFrameSpecification,
      setFrameSpecsFilter,
      clearFrameSpecsFilter,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Creative);
