import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import config from 'app-config';
import EchoChannel from './EchoChannel';
import Request from '../API/request';
import EchoPresenceChannel from './EchoPresenceChannel';

export default class EchoService {
  client = null;

  jwt = null;

  connect() {
    if (this.client) {
      return this;
    }

    if (!Object.prototype.hasOwnProperty.call(config, 'echo')) {
      throw new Error('`echo` property missing from config.');
    }

    const { key, wsHost, wsPort, wssPort, forceTLS, enabledTransports } = config.echo;
    const options = {
      Pusher,
      namespace: '',
      broadcaster: 'reverb',
      channelAuthorization: {
        endpoint: Request.buildUrl('broadcasting/auth'),
        headersProvider: () => (this.jwt ? { Authorization: this.jwt } : {}),
      },
      key,
      wsHost,
      wsPort,
      wssPort,
      forceTLS,
      enabledTransports,
    };

    this.client = new Echo(options);

    return this;
  }

  disconnect() {
    if (!this.client) {
      return this;
    }
    this.client.disconnect();
    this.client = null;
    this.jwt = null;

    return this;
  }

  subscribe(channel) {
    this.connect();
    return new EchoChannel(this.client, this.client.channel(channel), channel);
  }

  join(presenceChannel) {
    this.connect();
    return new EchoPresenceChannel(this.client, this.client.join(presenceChannel), presenceChannel);
  }

  setJwt(jwt) {
    this.jwt = jwt;
    return this;
  }
}
