import React from 'react';
import PropTypes from 'prop-types';

import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './MenuOption.scss';

const MenuOption = ({ isSelected, onSelect, children }) => {
  let className = `${style.component} ${style.button}`;

  if (isSelected) {
    className = `${className} ${style.active}`;
  }

  return (
    <button type="button" onClick={onSelect} className={className}>
      {isSelected && <Icon iconType={IconTypes.DONE} />}
      {children}
    </button>
  );
};

MenuOption.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PropTypes.node,
};

MenuOption.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  children: null,
};

export default MenuOption;
