import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import ProgressBar from 'components/patterns/ProgressBar';
import { ColumnContainer } from 'assets/components/presentational/Layout';
import Heading from 'assets/components/presentational/Heading';
import UserFormUserCreate from 'assets/components/containers/Users/UserFormUserCreate';

import style from './userCreate.scss';

class UserCreate extends Component {
  renderProgress() {
    const { isUpdatingUser } = this.props;

    if (isUpdatingUser) {
      return <ProgressBar />;
    }
  }

  render() {
    const { t, userId } = this.props;

    return (
      <ColumnContainer>
        <Heading className={style.heading} tag="H1" size="largest">
          {t('Add User')}
        </Heading>
        <div className={style.userProfile}>
          <UserFormUserCreate t={t} userId={userId} />
        </div>
      </ColumnContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.get('id'),
  isUpdatingUser: state.users.isUpdatingUser,
  activeUser: state.users.activeUser,
});

export default withNamespaces(['common', 'users'], { wait: true })(connect(mapStateToProps)(UserCreate));

UserCreate.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isUpdatingUser: PropTypes.bool,
};
