import React from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Link from 'assets/components/presentational/Link';
import style from './backLink.scss';

const BackLink = ({ label, url }) => (
  <span className={style.backLink}>
    <Icon iconType={IconTypes.CHEVRON_LEFT} />
    <Link className={style.link} to={url}>
      {label}
    </Link>
  </span>
);

export default BackLink;

BackLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
