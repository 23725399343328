import Request from 'modules/API/request';

import { fetchCampaign, fetchCampaignRelations } from 'actions/campaign';
import { fetchCreativeForPreview } from 'actions/creative';
import { notifyApiError } from 'actions/notify';

export const CLEAR = 'campaign/creative/preview/CLEAR';

export const FETCH_REQUEST = 'campaign/creative/preview/FETCH_REQUEST';
export const FETCH_SUCCESS = 'campaign/creative/preview/FETCH_SUCCESS';
export const FETCH_COMPLETE = 'campaign/creative/preview/FETCH_COMPLETE';

export const FETCH_ALL_CREATIVES_REQUEST = 'campaign/creative/preview/FETCH_ALL_CREATIVES_REQUEST';
export const FETCH_ALL_CREATIVES_SUCCESS = 'campaign/creative/preview/FETCH_ALL_CREATIVES_SUCCESS';
export const FETCH_ALL_CREATIVES_COMPLETE = 'campaign/creative/preview/FETCH_ALL_CREATIVES_COMPLETE';

export const UPDATE_REQUEST = 'campaign/creative/preview/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'campaign/creative/preview/UPDATE_SUCCESS';
export const UPDATE_COMPLETE = 'campaign/creative/preview/UPDATE_COMPLETE';

export const UPDATE_MODERATION_STATUS = 'campaign/creative/preview/UPDATE_MODERATION_STATUS';

export const SYNC_REQUEST = 'campaign/creative/preview/SYNC_REQUEST';
export const SYNC_SUCCESS = 'campaign/creative/preview/SYNC_SUCCESS';
export const SYNC_COMPLETE = 'campaign/creative/preview/SYNC_COMPLETE';

export const MERGE_CREATIVE = 'campaign/creative/preview/MERGE_CREATIVE';

export const CHECK_MODERATION_REQUEST = 'campaign/creative/preview/CHECK_MODERATION_REQUEST';
export const CHECK_MODERATION_SUCCESS = 'campaign/creative/preview/CHECK_MODERATION_SUCCESS';
export const CHECK_MODERATION_COMPLETE = 'campaign/creative/preview/CHECK_MODERATION_COMPLETE';
export const CHECK_MODERATION_FAILURE = 'campaign/creative/preview/CHECK_MODERATION_FAILURE';

export const SAVE_MODERATION_REQUEST = 'campaign/creative/preview/SAVE_MODERATION_REQUEST';
export const SAVE_MODERATION_COMPLETE = 'campaign/creative/preview/SAVE_MODERATION_COMPLETE';
export const SAVE_MODERATION_SUCCESS = 'campaign/creative/preview/SAVE_MODERATION_SUCCESS';

export const FETCH_MODERATION_SUMMARY_REQUEST = 'campaign/creative/preview/FETCH_MODERATION_SUMMARY_REQUEST';
export const FETCH_MODERATION_SUMMARY_SUCCESS = 'campaign/creative/preview/FETCH_MODERATION_SUMMARY_SUCCESS';
export const FETCH_MODERATION_SUMMARY_COMPLETE = 'campaign/creative/preview/FETCH_MODERATION_SUMMARY_COMPLETE';

export const RECALCULATE_MODERATION_SUMMARY_REQUEST =
  'campaign/creative/preview/RECALCULATE_MODERATION_SUMMARY_REQUEST';
export const RECALCULATE_MODERATION_SUMMARY_SUCCESS =
  'campaign/creative/preview/RECALCULATE_MODERATION_SUMMARY_SUCCESS';
export const RECALCULATE_MODERATION_SUMMARY_COMPLETE =
  'campaign/creative/preview/RECALCULATE_MODERATION_SUMMARY_COMPLETE';

export const fetchCreative = (campaignId, creativeId, shouldFetchCampaign = false) => (dispatch) => {
  dispatch({ type: FETCH_REQUEST, creativeId });

  // Used for the creative settings component
  if (shouldFetchCampaign) {
    dispatch(fetchCampaign(campaignId, { includes: ['rule-sets', 'content-types'] }));
  }

  return dispatch(
    fetchCreativeForPreview(
      creativeId,
      [
        'prepared-asset',
        'frame-specification',
        'rule-sets',
        'content-types',
        'asset',
        'user',
        'asset-history',
        'asset-history.user',
        'warnings',
      ],
      ['preview-frame', 'preview-content-items', 'preview-rule-sets'],
    ),
  )
    .then((creative) => {
      dispatch({ type: FETCH_SUCCESS, creative });
      dispatch({ type: FETCH_COMPLETE, creativeId });

      return Promise.resolve(creative);
    })
    .catch(() => {
      dispatch({ type: FETCH_COMPLETE, creativeId });
    });
};

export const fetchAllCreatives = (campaignId) => (dispatch) => {
  dispatch({ type: FETCH_ALL_CREATIVES_REQUEST, campaignId });

  return Request.send({ endpoint: `campaigns/${campaignId}/creatives` })
    .then((response) => {
      const creatives = response.data.body.parsed;

      dispatch({ type: FETCH_ALL_CREATIVES_SUCCESS, campaignId, creatives });
      dispatch({ type: FETCH_ALL_CREATIVES_COMPLETE, campaignId });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_ALL_CREATIVES_COMPLETE, campaignId });
    });
};

export const fetchFrameSpecifications = (campaignId) => (dispatch) => {
  dispatch(fetchCampaignRelations(campaignId, 'frame-specifications', { includes: ['creatives'] }));
};

export const updateCreativeModerationStatus = (creativeId, status) => (dispatch) => {
  dispatch({ type: UPDATE_MODERATION_STATUS, status });
};

export const updateDefaultStatus = (creativeId, isDefault = false) => (dispatch) => {
  dispatch({ type: UPDATE_REQUEST, creativeId });
  return Request.send({
    data: { 'is-default': isDefault },
    endpoint: `creatives/${creativeId}`,
    method: 'PATCH',
  })
    .then((response) => {
      const creative = response.data.body.parsed;
      dispatch({ type: UPDATE_SUCCESS, creative });
      dispatch({ type: UPDATE_COMPLETE, creativeId });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: UPDATE_COMPLETE, creativeId });
    });
};

export const syncRelations = (creativeId, relation, ids = []) => (dispatch) => {
  const data = ids.map((id) => ({ id }));

  const options = {
    endpoint: `creatives/${creativeId}/relationships/${relation}`,
    method: 'PATCH',
    data,
  };

  dispatch({ type: SYNC_REQUEST, creativeId, relation });
  return Request.send(options)
    .then((response) => {
      dispatch({ type: SYNC_SUCCESS, relation, ids });
      dispatch({ type: SYNC_COMPLETE, relation });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: SYNC_COMPLETE, relation });
    });
};

export const canUserModerate = (userId, creativeId) => (dispatch) => {
  dispatch({ type: CHECK_MODERATION_REQUEST, userId, creativeId });
  return Request.send({
    endpoint: `users/${userId}/creatives/${creativeId}/moderate`,
  })
    .then(() => {
      dispatch({ type: CHECK_MODERATION_SUCCESS, userId, creativeId });
      dispatch({ type: CHECK_MODERATION_COMPLETE, userId, creativeId });
      return Promise.resolve(true);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CHECK_MODERATION_FAILURE, userId, creativeId });
      dispatch({ type: CHECK_MODERATION_COMPLETE, userId, creativeId });
      return Promise.resolve(false);
    });
};

export const moderate = (creativeId, status, notes = '') => (dispatch) => {
  const options = {
    endpoint: `creatives/${creativeId}/moderate`,
    method: 'POST',
    data: { status, notes },
  };

  dispatch({ type: SAVE_MODERATION_REQUEST, creativeId });

  return Request.send(options)
    .then((response) => {
      const creative = response.data.body.parsed;

      dispatch({ type: SAVE_MODERATION_SUCCESS, creativeId, creative });
      dispatch({ type: SAVE_MODERATION_COMPLETE, creativeId });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: SAVE_MODERATION_COMPLETE, creativeId });

      return Promise.resolve(error);
    });
};

export const mergeCreative = (creative) => ({ type: MERGE_CREATIVE, creative });

export const clear = () => ({ type: CLEAR });

export const fetchModerationSummary = (creativeId, options = {}) => (dispatch) => {
  dispatch({ type: FETCH_MODERATION_SUMMARY_REQUEST }, creativeId);

  return Request.send({
    ...options,
    endpoint: `creatives/${creativeId}/moderation-decisions`,
  })
    .then((response) => {
      const moderationSummary = response.data.body.parsed;

      dispatch({ type: FETCH_MODERATION_SUMMARY_SUCCESS, creativeId, moderationSummary });
      dispatch({ type: FETCH_MODERATION_SUMMARY_COMPLETE, creativeId });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_MODERATION_SUMMARY_COMPLETE, creativeId });
      return Promise.resolve(error);
    });
};

export const recalculateModerationSummary = (creativeId, options = {}) => (dispatch) => {
  dispatch({ type: RECALCULATE_MODERATION_SUMMARY_REQUEST });

  return Request.send({
    ...options,
    endpoint: `creatives/${creativeId}/moderation-decisions/trigger`,
    method: 'POST',
  })
    .then((response) => {
      dispatch({ type: RECALCULATE_MODERATION_SUMMARY_SUCCESS });
      dispatch({ type: RECALCULATE_MODERATION_SUMMARY_COMPLETE });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: RECALCULATE_MODERATION_SUMMARY_COMPLETE });
      return Promise.reject(error);
    });
};
