import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import * as actions from './actions';

const getInitialState = () => ({
  campaigns: new List(),
  totalPages: 1,
});

const dashboard = handleActions(
  {
    [actions.FETCH_CAMPAIGNS]: (state, action) => ({
      ...state,
      campaigns: action.campaigns,
      totalPages: action.totalPages,
    }),
  },
  getInitialState(),
);

export default dashboard;
