import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { debounce, last } from 'lodash';

import { fetchIndex as fetchI18nIndexAction } from 'actions/i18n';
import {
  fetchIndex as fetchTransportIndexAction,
  TRANSPORT_RESOURCE_AIRLINES,
  TRANSPORT_RESOURCE_AIRPORTS,
} from 'actions/transport';

import Dropdown from 'assets/components/presentational/Dropdown';
import Autocomplete from 'assets/components/presentational/Autocomplete';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './flightPropertiesForm.scss';

const Options = {
  FLIGHT_PROPERTY: 0,
  FLIGHT_VALUE: 1,
};

class FlightPropertiesForm extends Component {
  constructor(props) {
    super(props);
    this.fetchI18nIndex = debounce(this.fetchI18nIndex, 500);
    this.fetchTransportIndex = debounce(this.fetchTransportIndex, 500);
    this.state = { hasSearchValue: false };
  }

  componentDidMount() {
    this.fetchI18nIndex('continents');
  }

  handlePropertyChange = (set, field, value) => {
    const { airports, airlines, translations } = this.props;
    const optionsKey = this.getOptionsKey(value);

    field.onChange(value);

    if (optionsKey === TRANSPORT_RESOURCE_AIRPORTS) {
      if (!airports.size) {
        this.fetchTransportIndex(TRANSPORT_RESOURCE_AIRPORTS);
      }
    } else if (optionsKey === TRANSPORT_RESOURCE_AIRLINES) {
      if (!airlines.size) {
        this.fetchTransportIndex(TRANSPORT_RESOURCE_AIRLINES);
      }
    } else if (!(optionsKey in translations) || !translations[optionsKey].size) {
      const options = {};

      this.fetchI18nIndex(optionsKey, this.addCountryIncludeIfRequired(optionsKey, options));
    }

    // Reset location value
    set[Options.FLIGHT_VALUE].onChange([]);
  };

  handlePropertyValueChange = (field, value) => {
    field.onChange(value);
  };

  handleAddSetClick = () => {
    const { fields, ruleType } = this.props;
    fields.addField(ruleType.get('default-value').toJS()[0]);
  };

  handleRemoveSetClick = (key) => {
    const { fields } = this.props;
    if (fields.length > 1) {
      return fields.removeField(key);
    }
  };

  handlePropertyValueQueryChange = (set, search) => {
    const { hasSearchValue } = this.state;
    const optionsKey = this.getOptionsKey(set[Options.FLIGHT_PROPERTY].value);
    const options = { filters: [{ name: `%${search}%` }] };

    if (!hasSearchValue && search.length > 0) {
      this.setState({ hasSearchValue: true });
    } else if (hasSearchValue && search.length < 1) {
      this.setState({ hasSearchValue: false });
    }

    if (optionsKey === TRANSPORT_RESOURCE_AIRPORTS) {
      options.filters = [{ 'name,iata_code': `%${search}%` }];

      return this.fetchTransportIndex(optionsKey, options);
    }

    if (optionsKey === TRANSPORT_RESOURCE_AIRLINES) {
      options.filters = [{ name: `%${search}%` }];

      return this.fetchTransportIndex(optionsKey, options);
    }

    this.fetchI18nIndex(optionsKey, this.addCountryIncludeIfRequired(optionsKey, options));
  };

  addCountryIncludeIfRequired = (translations, options = {}) => {
    if (translations === 'cities') {
      options.includes = ['country'];
    }

    return options;
  };

  getPropertyValuesBySet = (set) => {
    const optionsKey = this.getOptionsKey(set[Options.FLIGHT_PROPERTY].value);
    const { airports, airlines, translations } = this.props;
    const { hasSearchValue } = this.state;

    if (!hasSearchValue) {
      return new Map();
    }

    if (optionsKey === TRANSPORT_RESOURCE_AIRPORTS) {
      return airports.reduce(
        (result, item) => result.set(item.get('id'), `${item.get('name', '')} (${item.get('iata-code')})`),
        Map(),
      );
    }

    if (optionsKey === TRANSPORT_RESOURCE_AIRLINES) {
      return airlines.reduce((result, item) => result.set(item.get('id'), item.get('name', '')), Map());
    }

    if (!(optionsKey in translations)) {
      return new Map();
    }

    return translations[optionsKey].reduce((result, item) => {
      const country = item.has('country') ? `, ${item.get('country').get('name')}` : '';

      return result.set(item.get('id'), item.get('name', '') + country);
    }, Map());
  };

  getOptionsKey = (value) => last(value.split('_'));

  fetchI18nIndex = (translations, options = {}) => {
    const { dispatch } = this.props;

    dispatch(fetchI18nIndexAction(translations, options));
  };

  fetchTransportIndex = (resource, options = {}) => {
    const { dispatch } = this.props;

    dispatch(fetchTransportIndexAction(resource, options));
  };

  renderSets = () => {
    const { fields } = this.props;

    return fields.map((set, key) => this.renderSet(set, key));
  };

  renderSet = (set, key) => (
    <div className={style.setContainer} key={key}>
      {this.renderSetSeparator(key)}
      {this.renderTypeDropdown(set)}
      {this.renderFlightValueAutocomplete(set)}
      {this.renderRemoveSetButton(key)}
    </div>
  );

  renderSetSeparator = (key) => {
    if (key) {
      return <span className={style.setSeparatorLabel}>AND</span>;
    }

    return null;
  };

  renderTypeDropdown = (set) => {
    const { ruleType } = this.props;
    const field = set[Options.FLIGHT_PROPERTY];

    return (
      <Dropdown
        auto={false}
        className={style.dropdown}
        disabled={!ruleType.get('is-selected')}
        source={ruleType
          .get('options')
          .first()
          .toJS()}
        onChange={(value) => this.handlePropertyChange(set, field, value)}
        value={field.value}
        allowBlank={false}
      />
    );
  };

  renderFlightValueAutocomplete = (set) => {
    const { isFetchingi18n, ruleType, t } = this.props;
    const field = set[1];

    return (
      <Autocomplete
        className={style.autocomplete}
        direction="down"
        disabled={!ruleType.get('is-selected')}
        onChange={(value) => this.handlePropertyValueChange(field, value)}
        label={t('Search for value')}
        onQueryChange={(value) => this.handlePropertyValueQueryChange(set, value)}
        showSelectedWhenNotInSource
        source={isFetchingi18n ? {} : this.getPropertyValuesBySet(set).toJS()}
        suggestionMatch="anywhere"
        // Default value is supplied as an array but we need an object here
        value={Array.isArray(field.value) ? {} : field.value}
      />
    );
  };

  renderAddSetButton = () => {
    const { ruleType, t } = this.props;

    return (
      <Button
        className={style.setAddButton}
        disabled={!ruleType.get('is-selected')}
        icon={<Icon iconType={IconTypes.ADD} />}
        label={t('Add flight property')}
        onClick={this.handleAddSetClick}
        theme={ButtonThemes.WHITE}
      />
    );
  };

  renderRemoveSetButton = (key) => {
    const { fields, ruleType } = this.props;

    return (
      <Button
        className={fields.length <= 1 ? style.removeButtonDisabled : style.setRemoveButton}
        disabled={!ruleType.get('is-selected') || fields.length <= 1}
        floating
        icon={<Icon iconType={IconTypes.DELETE} />}
        onClick={() => this.handleRemoveSetClick(key)}
        theme={ButtonThemes.DANGER}
      />
    );
  };

  render() {
    return (
      <div className={style.component}>
        {this.renderSets()}
        {this.renderAddSetButton()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(FlightPropertiesForm);

FlightPropertiesForm.propTypes = {
  airports: PropTypes.instanceOf(List).isRequired,
  airlines: PropTypes.instanceOf(List).isRequired,
  translations: PropTypes.shape({
    continents: PropTypes.instanceOf(List),
  }).isRequired,
  isFetchingi18n: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
