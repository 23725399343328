import React from 'react';
import PropTypes from 'prop-types';
import AuthLite from 'assets/components/containers/AuthLite';

const AuthLiteLiteModeration = ({ authenticateUser, params: { token } }) => (
  <AuthLite authenticateUser={authenticateUser} token={token} baseUrl="/lite/moderation/view" />
);

AuthLiteLiteModeration.propTypes = {
  authenticateUser: PropTypes.func.isRequired,
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuthLiteLiteModeration;
