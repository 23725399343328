export const SET_MEMBERS = 'campaign/presence-indicator/SET_MEMBERS';
export const MERGE_MEMBER = 'campaign/presence-indicator/MERGE_MEMBER';
export const REMOVE_MEMBER = 'campaign/presence-indicator/REMOVE_MEMBER';
export const CLEAR_MEMBERS = 'campaign/presence-indicator/CLEAR_MEMBERS';

export const setMembers = (members) => ({
  type: SET_MEMBERS,
  members,
});

export const mergeMember = (member) => ({
  type: MERGE_MEMBER,
  member,
});

export const removeMember = (member) => ({
  type: REMOVE_MEMBER,
  member,
});

export const clearMembers = () => ({
  type: CLEAR_MEMBERS,
});
