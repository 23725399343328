import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import EmptyState from 'components/common/EmptyState';
import BookingsSearch from 'components/common/BookingsSearch';
import ProgressBar from 'components/patterns/ProgressBar';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';

import BulkPromotion from './BulkPromotion';
import BookingCard, { BookingPropTypes } from './BookingCard';
import Recap from './Recap';

import style from './Bookings.scss';

const propTypes = {
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  bookings: PropTypes.arrayOf(BookingPropTypes).isRequired,
  setActiveSection: PropTypes.func.isRequired,
  promoteBooking: PropTypes.func.isRequired,
  bulkPromoteBookings: PropTypes.func.isRequired,
  attachBooking: PropTypes.func.isRequired,
  detachBooking: PropTypes.func.isRequired,
  fetchCampaign: PropTypes.func.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  promotableTotal: PropTypes.number.isRequired,
  promotedTotal: PropTypes.number.isRequired,
  promotingTotal: PropTypes.number.isRequired,
  unpromotedTotal: PropTypes.number.isRequired,
  haveErrorsTotal: PropTypes.number.isRequired,
  isFullyPromoted: PropTypes.bool.isRequired,
  isFullyPromotable: PropTypes.bool.isRequired,
  isPartiallyPromotable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

class Bookings extends Component {
  state = {
    isFetching: true,
  };

  componentDidMount() {
    const { fetchCampaign, fetchBookings, setActiveSection, params } = this.props;
    fetchCampaign(params.campaignId);
    fetchBookings(params.campaignId).then(() => {
      this.setState({
        isFetching: false,
      });
    });
    setActiveSection('bookings');
  }

  bookingSearchHandler = (booking) => {
    const {
      params: { campaignId },
      attachBooking,
    } = this.props;
    attachBooking(campaignId, booking);
  };

  renderRecapSection = () => {
    const { promotedTotal, promotingTotal, unpromotedTotal, haveErrorsTotal } = this.props;
    return (
      <Recap
        promotedTotal={promotedTotal}
        promotingTotal={promotingTotal}
        unpromotedTotal={unpromotedTotal}
        haveErrorsTotal={haveErrorsTotal}
      />
    );
  };

  renderBulkPromotionSection = () => {
    const {
      user,
      params: { campaignId },
      bookings,
      bulkPromoteBookings,
      promotableTotal,
      isFullyPromoted,
      isFullyPromotable,
      isPartiallyPromotable,
    } = this.props;
    return (
      <BulkPromotion
        user={user}
        bookings={bookings}
        campaignId={campaignId}
        bulkPromoteBookings={bulkPromoteBookings}
        promotableTotal={promotableTotal}
        isFullyPromotable={isFullyPromotable}
        isPartiallyPromotable={isPartiallyPromotable}
        isFullyPromoted={isFullyPromoted}
      />
    );
  };

  renderSummarySection = () => (
    <section className={style.dashboard}>
      {this.renderRecapSection()}
      {this.renderBulkPromotionSection()}
    </section>
  );

  renderBookings = () => {
    const {
      user,
      params: { campaignId },
      bookings,
      promoteBooking,
      detachBooking,
    } = this.props;
    return bookings.map((booking) => (
      <BookingCard
        key={`${booking['external-id']}${booking.network}`}
        booking={booking}
        user={user}
        campaignId={campaignId}
        promoteBooking={promoteBooking}
        detachBooking={detachBooking}
      />
    ));
  };

  render() {
    const { bookings, t } = this.props;
    const { isFetching } = this.state;

    const hasBookings = bookings && bookings.length > 0; // TODO: Bookings should always be an array, but it's sometimes undefined

    return (
      <div>
        <div className={style.heading}>
          <HeadingGroup title={t('Bookings')} intro={t('bookings page intro')} />
        </div>
        {isFetching ? (
          <ProgressBar />
        ) : (
          <>
            {hasBookings && this.renderSummarySection()}
            <div className={style.search}>
              <BookingsSearch onChange={this.bookingSearchHandler} />
            </div>
            {hasBookings ? this.renderBookings() : <EmptyState text={t('booking_list_empty')} />}
          </>
        )}
      </div>
    );
  }
}

Bookings.propTypes = propTypes;

export default withNamespaces(['common', 'bookings'], { wait: false })(Bookings);
