import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import ConfigurableForm from 'assets/components/containers/ConfigurableForm';

class ConfigurableFormContainer extends Component {
  fields() {
    const { formObject } = this.props;
    // get the field names out of the config object
    const fields = get(formObject, 'fields', []);
    return fields.filter((field) => field.type !== 'heading').map((field) => field.slug);
  }

  render() {
    const {
      name,
      readonly,
      onCancel,
      shouldAddAnotherContentItem,
      onAddAnotherItem,
      onSubmit,
      formObject,
      initialValues,
      isAddAnotherItemHidden,
    } = this.props;

    return (
      <ConfigurableForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        formObject={formObject}
        form={name}
        fields={this.fields()}
        initialValues={initialValues}
        readonly={readonly}
        onAddAnotherItem={onAddAnotherItem}
        shouldAddAnotherContentItem={shouldAddAnotherContentItem}
        isAddAnotherItemHidden={isAddAnotherItemHidden}
      />
    );
  }
}

ConfigurableFormContainer.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  formObject: PropTypes.shape({
    fields: PropTypes.array.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  readonly: PropTypes.bool,
  shouldAddAnotherContentItem: PropTypes.bool,
  onAddAnotherItem: PropTypes.func,
  isAddAnotherItemHidden: PropTypes.bool,
};

ConfigurableFormContainer.defaultProps = {
  onSubmit: () => {},
  initialValues: {},
  readonly: false,
  shouldAddAnotherContentItem: false,
  onAddAnotherItem: () => {},
  isAddAnotherItemHidden: false,
};

export default ConfigurableFormContainer;
