import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateUpload, removeUpload } from 'store/uploads/actions';
import { addCreative } from 'actions/creative';

import FrameSpecification from './FrameSpecification';

const mapStateToProps = (state) => ({
  uploads: state.uploads,
  isFetchingCreativesByFrameSpec: state.creative.isFetchingCreativesByFrameSpec,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUpload,
      removeUpload,
      addCreative,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FrameSpecification);
