import Request from 'modules/API/request';
import { fromJS, Map } from 'immutable';
import { reset } from 'redux-form';
import { map } from 'lodash';
import { ContentModerationStatuses, guid } from 'modules/Helpers';
import {
  clearCampaignRelation,
  mergeCampaignRelation,
  fetchCampaignRelations,
  fetchCampaignRelation,
} from 'actions/campaign';
import { notifyApiError } from 'actions/notify';

export const CAMPAIGN_RULE_SETS_TOGGLE_IS_FETCHING_RULE_SET = 'CAMPAIGN_RULE_SETS_TOGGLE_IS_FETCHING_RULE_SET';
export const CAMPAIGN_RULE_SETS_SET_ACTIVE = 'CAMPAIGN_RULE_SETS_SET_ACTIVE';
export const CAMPAIGN_RULE_TYPES_FETCH_REQUEST = 'CAMPAIGN_RULE_TYPES_FETCH_REQUEST';
export const CAMPAIGN_RULE_TYPES_FETCH_COMPLETE = 'CAMPAIGN_RULE_TYPES_FETCH_COMPLETE';
export const CAMPAIGN_RULE_TYPES_MERGE = 'CAMPAIGN_RULE_TYPES_MERGE';
export const CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_COLLAPSED = 'CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_COLLAPSED';
export const CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_SELECTED = 'CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_SELECTED';
export const CAMPAIGN_RULE_SETS_SAVE_REQUEST = 'CAMPAIGN_RULE_SETS_SAVE_REQUEST';
export const CAMPAIGN_RULE_SETS_SAVE_COMPLETE = 'CAMPAIGN_RULE_SETS_SAVE_COMPLETE';
export const CAMPAIGN_RULE_SETS_TOGGLE_ACTIVE_FRAMES_COLLAPSED = 'CAMPAIGN_RULE_SETS_TOGGLE_ACTIVE_FRAMES_COLLAPSED';
export const CAMPAIGN_RULE_SETS_TOGGLE_UNSAVED_CHANGES_DIALOG = 'CAMPAIGN_RULE_SETS_TOGGLE_UNSAVED_CHANGES_DIALOG';
export const CAMPAIGN_RULE_SETS_TOGGLE_SHOW_DRAWER = 'CAMPAIGN_RULE_SETS_TOGGLE_SHOW_DRAWER';
export const CAMPAIGN_RULE_SETS_PAGINATION_MERGE = 'CAMPAIGN_RULE_SETS_PAGINATION_MERGE';

export const fetchRuleSets = (campaignId, pageNumber = 1, filters = [], sorts = []) => (dispatch) => {
  return dispatch(
    fetchCampaignRelations(campaignId, 'rule-sets', {
      includes: ['creatives', 'content-items', 'frames', 'rules'],
      pageNumber,
      filters,
      sorts,
    }),
  ).then((response) => {
    dispatch(mergePagination(response.data.body.meta.pagination));
  });
};

export const mergePagination = (pagination) => ({
  type: CAMPAIGN_RULE_SETS_PAGINATION_MERGE,
  pagination,
});

export const fetchRuleSetForEdit = (campaignId, ruleSetId) => (dispatch) => {
  dispatch(toggleIsFetchingRuleSet());
  dispatch(toggleShowDrawer('edit'));

  return dispatch(
    fetchCampaignRelation(campaignId, 'rule-sets', ruleSetId, [
      'rules',
      'frames.location.weather',
      'creatives',
      'content-items',
    ]),
  )
    .then((ruleSet) => {
      dispatch(setActiveRuleSetId(ruleSet.get('id')));
      dispatch(toggleIsFetchingRuleSet());

      return Promise.resolve();
    })
    .catch(() => {
      dispatch(toggleIsFetchingRuleSet());
    });
};

export const fetchRuleSetForSettings = (campaignId, ruleSetId) => (dispatch) => {
  dispatch(toggleIsFetchingRuleSet());
  dispatch(toggleShowDrawer('settings'));
  dispatch(clearCampaignRelation(campaignId, ['frame-specifications']));
  dispatch(clearCampaignRelation(campaignId, ['content-items']));

  return Promise.all([
    dispatch(fetchCampaignRelation(campaignId, 'rule-sets', ruleSetId, ['creatives', 'content-items'])),
    dispatch(
      fetchCampaignRelations(campaignId, 'frame-specifications', {
        includes: ['creatives'],
      }),
    ),
    dispatch(
      fetchCampaignRelations(campaignId, 'content-items', {
        filters: [{ status: ContentModerationStatuses.APPROVED }],
      }),
    ),
  ])
    .then((responses) => {
      const ruleSet = responses[0];

      dispatch(setActiveRuleSetId(ruleSet.get('id')));
      dispatch(toggleIsFetchingRuleSet());

      return Promise.resolve();
    })
    .catch(() => {
      dispatch(toggleIsFetchingRuleSet());
    });
};

export const syncRelations = (campaignId, ruleSet, items, relation) => (dispatch) => {
  const ids = items.map((i) => new Map({ id: i.get('id') })).toJS();

  dispatch({ type: CAMPAIGN_RULE_SETS_SAVE_REQUEST });

  return Request.send({
    endpoint: `rule-sets/${ruleSet.get('id')}/relationships/${relation}`,
    method: 'PATCH',
    data: ids,
  })
    .then(() => {
      ruleSet = ruleSet.set(relation, items);

      dispatch({ type: CAMPAIGN_RULE_SETS_SAVE_COMPLETE });
      dispatch(mergeCampaignRelation(campaignId, ['rule-sets'], ruleSet));
    })
    .catch(() => {
      dispatch({ type: CAMPAIGN_RULE_SETS_SAVE_COMPLETE });
      return Promise.reject(new Error(`failed to sync rule sets relations for ${relation} type`));
    });
};

export const setActiveRuleSetId = (ruleSetId) => ({
  type: CAMPAIGN_RULE_SETS_SET_ACTIVE,
  ruleSetId,
});

export const fetchRuleTypes = (campaignId) => (dispatch) => {
  dispatch({ type: CAMPAIGN_RULE_TYPES_FETCH_REQUEST });

  return Request.send({
    endpoint: 'rule-types',
    method: 'GET',
    filters: [{ campaign_id: campaignId }], // eslint-disable-line camelcase
  })
    .then((response) => {
      const ruleTypes = response.data.body.parsed;
      dispatch(mergeRuleTypes(ruleTypes));
      dispatch({ type: CAMPAIGN_RULE_TYPES_FETCH_COMPLETE });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_RULE_TYPES_FETCH_COMPLETE });
    });
};

export const mergeRuleTypes = (ruleTypes) => ({
  type: CAMPAIGN_RULE_TYPES_MERGE,
  ruleTypes,
});

export const toggleRuleTypeCollapsed = (ruleType, isCollapsed) => ({
  type: CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_COLLAPSED,
  ruleType,
  isCollapsed,
});

export const toggleRuleTypeSelected = (ruleType, isSelected) => ({
  type: CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_SELECTED,
  ruleType,
  isSelected,
});

export const saveRuleSet = (campaignId, data) => (dispatch) => {
  let endpoint = 'rule-sets';
  let method = 'POST';
  // The relationship data to be returned from the new or updated model
  const includes = ['content-items', 'creatives', 'rules', 'frames.location.weather'];
  const isNew = data.id.length > 0;

  if (isNew) {
    endpoint = endpoint.concat('/', data.id);
    method = 'PATCH';
  }

  // Transform rules to JSON API spec
  data.rules = map(data.rules, (rule) => ({ id: !rule.id ? guid() : rule.id, data: rule.data, type: rule.type }));

  // Transform request body to include correct JSON API relationships structure
  const options = {
    endpoint,
    method,
    data,
    includes,
    extraSerializerConfiguration: {
      included: true,
      type: 'rule-sets',
      attributes: ['campaign_id', 'name', 'ruleset_id', 'rules'],
      rules: {
        ref: (ruleSet, rule) => rule.id,
        attributes: ['id', 'data', 'type'],
      },
    },
  };

  dispatch({ type: CAMPAIGN_RULE_SETS_SAVE_REQUEST });

  return Request.send(options)
    .then((response) => {
      const ruleSet = fromJS(response.data.body.parsed);

      dispatch({ type: CAMPAIGN_RULE_SETS_SAVE_COMPLETE });
      dispatch(mergeCampaignRelation(campaignId, ['rule-sets'], ruleSet));

      return Promise.resolve(ruleSet);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_RULE_SETS_SAVE_COMPLETE });

      return Promise.reject(error);
    });
};

export const toggleActiveFramesCollapsed = (isCollapsed) => ({
  type: CAMPAIGN_RULE_SETS_TOGGLE_ACTIVE_FRAMES_COLLAPSED,
  isCollapsed,
});

export const toggleUnsaveChangesDialog = (active) => ({
  type: CAMPAIGN_RULE_SETS_TOGGLE_UNSAVED_CHANGES_DIALOG,
  active,
});

export const toggleShowDrawer = (component) => ({
  type: CAMPAIGN_RULE_SETS_TOGGLE_SHOW_DRAWER,
  component,
});

export const closeDrawer = (type) => (dispatch) => {
  dispatch(toggleShowDrawer(type));
  dispatch(setActiveRuleSetId(''));
  dispatch(reset('ruleSet'));
};

const toggleIsFetchingRuleSet = () => ({
  type: CAMPAIGN_RULE_SETS_TOGGLE_IS_FETCHING_RULE_SET,
});
