import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import moment from 'moment';
import { ContentModerationStatuses } from 'modules/Helpers';

import { fetchCampaignRelations, mergeCampaignRelation, clearCampaignRelation } from 'actions/campaign';
import { notifyApiError } from 'actions/notify';

const CONTENT_MODERATION_FETCH_REQUEST = 'CONTENT_MODERATION_FETCH_REQUEST';
const CONTENT_MODERATION_FETCH_SUCCESS = 'CONTENT_MODERATION_FETCH_SUCCESS';
const CONTENT_MODERATION_FETCH_COMPLETE = 'CONTENT_MODERATION_FETCH_COMPLETE';

const CONTENT_MODERATION_UPDATE_REQUEST = 'CONTENT_MODERATION_UPDATE_REQUEST';
const CONTENT_MODERATION_UPDATE_COMPLETE = 'CONTENT_MODERATION_UPDATE_COMPLETE';

const CONTENT_MODERATION_DELETE_PROMPT_TOGGLE = 'CONTENT_MODERATION_DELETE_PROMPT_TOGGLE';

const CONTENT_MODERATION_MERGE_SELECTED_CONTENT_ITEM_IDS = 'CONTENT_MODERATION_MERGE_SELECTED_CONTENT_ITEM_IDS';

const CONTENT_MODERATION_MERGE_COLLAPSED_SECTIONS = 'CONTENT_MODERATION_MERGE_COLLAPSED_SECTIONS';

const CONTENT_MODERATION_RESET = 'CONTENT_MODERATION_RESET';
const CONTENT_MODERATION_MERGE_PAGINATION = 'CONTENT_MODERATION_MERGE_PAGINATION';
const CONTENT_MODERATION_SET_POLLING = 'CONTENT_MODERATION_SET_POLLING';

const CONTENT_MODERATION_SET_ACTIVE_CONTENT_ITEM_ID = 'CONTENT_MODERATION_SET_ACTIVE_CONTENT_ITEM_ID';
const CONTENT_MODERATION_TOGGLE_CONTENT_ITEM_DRAWER = 'CONTENT_MODERATION_TOGGLE_CONTENT_ITEM_DRAWER';

const SET_CONTENT_SEARCH_VALUE = 'SET_CONTENT_SEARCH_VALUE';
const SET_CONTENT_TYPE_FILTER = 'SET_CONTENT_TYPE_FILTER';

const CONTENT_MODERATION_UPDATE_BYPASS_PROFANITY_FILTER = 'CONTENT_MODERATION_UPDATE_BYPASS_PROFANITY_FILTER';

export const fetchContentItems = (campaignId, pageNumber = 1, { contentTypeId, contentItemName } = {}) => (
  dispatch,
) => {
  dispatch(fetchContentItemsRequest());

  return Promise.all([
    dispatch(fetchAllApprovedAndRejectedContentItems(campaignId, contentTypeId, contentItemName)),
    dispatch(fetchPendingContentItemsPaged(campaignId, pageNumber, contentTypeId, contentItemName)),
  ])
    .then((responses) => {
      const lastFetchAt = moment(new Date(responses[1].headers.date));

      dispatch(fetchContentItemsSuccess(lastFetchAt));
      dispatch(fetchContentItemsComplete());
      dispatch(fetchContentItemsComplete(true));

      return responses;
    })
    .catch(() => {
      dispatch(fetchContentItemsComplete());
      dispatch(fetchContentItemsComplete(true));
    });
};

export const fetchAllApprovedAndRejectedContentItems = (campaignId, contentTypeId = null, contentItemName = null) => (
  dispatch,
) => {
  const filters = [{ approved_and_rejected: true }]; // eslint-disable-line camelcase

  if (contentTypeId) {
    filters.push({ content_type_id: contentTypeId }); // eslint-disable-line camelcase
  }

  if (contentItemName) {
    filters.push({ 'content_items.name': `%${contentItemName}%` });
  }

  dispatch(clearCampaignRelation(campaignId, ['content-items']));

  return dispatch(
    fetchCampaignRelations(campaignId, 'content-items', {
      includes: ['content-type'],
      sorts: ['-created_at'],
      filters,
    }),
  ).then((response) => response);
};

export const fetchPendingContentItemsPaged = (
  campaignId,
  pageNumber = 1,
  contentTypeId = null,
  contentItemName = null,
) => (dispatch) => {
  const filters = [{ status: ContentModerationStatuses.PENDING }];

  if (contentTypeId) {
    filters.push({ content_type_id: contentTypeId }); // eslint-disable-line camelcase
  }

  if (contentItemName) {
    filters.push({ 'content_items.name': `%${contentItemName}%` });
  }

  dispatch(fetchContentItemsRequest(true));

  dispatch(clearCampaignRelation(campaignId, ['content-items']));

  return dispatch(
    fetchCampaignRelations(campaignId, 'content-items', {
      includes: ['content-type'],
      sorts: ['-created_at'],
      filters,
      pageNumber,
    }),
  )
    .then((response) => {
      const lastFetchAt = moment(new Date(response.headers.date));

      dispatch(mergePagination(response.data.body.meta.pagination));
      dispatch(fetchContentItemsSuccess(lastFetchAt));

      return response;
    })
    .catch(() => {
      dispatch(fetchContentItemsComplete(true));
    });
};

export const fetchNewContentItems = (campaignId, createdSince, contentTypeId = null, contentItemName = null) => (
  dispatch,
) => {
  const includes = ['content-type'];
  const sorts = ['-created_at'];
  const filters = [];

  if (contentTypeId) {
    filters.push({ content_type_id: contentTypeId }); // eslint-disable-line camelcase
  }

  if (contentItemName) {
    filters.push({ 'content_items.name': `%${contentItemName}%` });
  }

  dispatch(setIsPollingContentItems(true));

  return dispatch(
    fetchCampaignRelations(campaignId, 'content-items', {
      createdSince,
      includes,
      sorts,
      filters,
      pageSize: 20,
    }),
  )
    .then((response) => {
      dispatch(setIsPollingContentItems(false));

      return response;
    })
    .catch(() => {
      dispatch(setIsPollingContentItems(false));
    });
};

export const updateStatuses = (campaignId, contentItems, newStatus, bypassProfanityFilter = false) => (dispatch) => {
  dispatch({ type: CONTENT_MODERATION_UPDATE_REQUEST });
  const queryString = bypassProfanityFilter ? '?bypassProfanityFilter=true' : '';
  return Request.send({
    method: 'PATCH',
    endpoint: `content-items/status/${newStatus}${queryString}`,
    data: contentItems.toJS(),
  })
    .then((response) => {
      contentItems = fromJS(response.data.body.parsed);
      dispatch(mergeCampaignRelation(campaignId, ['content-items'], contentItems));
    })
    .then(() => {
      dispatch({ type: CONTENT_MODERATION_UPDATE_COMPLETE });
      return Promise.resolve(contentItems);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CONTENT_MODERATION_UPDATE_COMPLETE });
    });
};

export const deleteContentItemsPromptToggle = () => ({
  type: CONTENT_MODERATION_DELETE_PROMPT_TOGGLE,
});

export const mergeSelectedContentItemIds = (selectedContentItemIds) => ({
  type: CONTENT_MODERATION_MERGE_SELECTED_CONTENT_ITEM_IDS,
  selectedContentItemIds,
});

export const mergeCollapsedSections = (collapsedSections) => ({
  type: CONTENT_MODERATION_MERGE_COLLAPSED_SECTIONS,
  collapsedSections,
});

export const resetState = () => ({
  type: CONTENT_MODERATION_RESET,
});

export const mergePagination = (pagination) => ({
  type: CONTENT_MODERATION_MERGE_PAGINATION,
  pagination,
});

export const setIsPollingContentItems = (isPollingContentItems) => ({
  type: CONTENT_MODERATION_SET_POLLING,
  isPollingContentItems,
});

export const fetchContentItemsComplete = (pending = false) => ({
  type: CONTENT_MODERATION_FETCH_COMPLETE,
  pending,
});

export const setActiveContentItemId = (contentItemId = '') => ({
  type: CONTENT_MODERATION_SET_ACTIVE_CONTENT_ITEM_ID,
  contentItemId,
});

export const toggleContentItemDrawer = () => ({
  type: CONTENT_MODERATION_TOGGLE_CONTENT_ITEM_DRAWER,
});

const fetchContentItemsRequest = (pending = false) => ({
  type: CONTENT_MODERATION_FETCH_REQUEST,
  pending,
});

const fetchContentItemsSuccess = (lastFetchAt) => ({
  type: CONTENT_MODERATION_FETCH_SUCCESS,
  lastFetchAt,
});

export const setContentSearchValue = (searchValue) => ({
  type: SET_CONTENT_SEARCH_VALUE,
  searchValue,
});

export const setContentTypeFilter = (filter) => ({
  type: SET_CONTENT_TYPE_FILTER,
  filter,
});

export const updateBypassProfanityFilter = (bypass) => ({
  type: CONTENT_MODERATION_UPDATE_BYPASS_PROFANITY_FILTER,
  bypass,
});
