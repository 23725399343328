import { isEqual, isFunction, toArray, isPlainObject } from 'lodash';
import { List } from 'immutable';
/**
 * Checks if the given callback is a valid function and executes.
 *
 * @param {Function} callback
 * @param {Event} e
 */
export const callback = (callback, e = null) => {
  if (isFunction(callback)) {
    callback(e);
  }
};

export const objectToArray = (targetObj) => {
  targetObj = toArray(targetObj);
  targetObj.forEach((v, index) => {
    if (isPlainObject(v)) {
      targetObj[index] = objectToArray(v);
    }
  });
  return targetObj;
};

// checks if object values are equivalent to array values
// e.g. a = {"0": "foobar", "1": {"0": "foo", "1": "bar"}}  b = [foobar, [foo, bar]]
// checkEquivalent(a, b) returns true
export const checkEquivalent = (a, b) => {
  a = objectToArray(a);
  b = objectToArray(b);
  return isEqual(a, b);
};

export const mergeObjCustomizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const downloadBlob = (filename, data) => {
  const blob = new Blob([data], { type: 'text/csv' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const escapeFilterCommas = (value) =>
  String(value)
    .split(',')
    .join('\\,');

export const escapeNewLineItems = (value) =>
  List(
    value
      .split(/\n/)
      .join(',')
      .split(','),
  ).filter((f) => f.trim() !== '');
