import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setActiveSection } from 'actions/campaign/navigation';
import { fetchPublications } from 'store/publications/actions';
import { fetchCampaign } from 'actions/campaign';

import { isPending, ACTIVITIES } from 'store/activity';
import Publish from './Publish';

const mapStateToProps = (state) => ({
  publishStatus: state.campaign.campaign.get('publishing-status'),
  isPublishable: state.campaign.campaign.get('is-publishable'),
  publications: state.publications,
  isFetchingPublications: isPending(state, ACTIVITIES.FETCH_PUBLICATIONS),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveSection,
      fetchPublications,
      fetchCampaign,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
