import { connect } from 'react-redux';

import {
  getDaysUntilLive,
  checkEachFrameSpecHasAtLeastOneCreative,
  checkEachFrameSpecHasAtLeastOneDefaultCreative,
  checkCampaignIsAboutToGoLive,
} from 'selectors/campaign';

import CampaignAlert from './CampaignAlert';

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  daysUntilLive: getDaysUntilLive(state.campaign.campaign),
  frameSpecificationsHaveAtLeastOneCreative: checkEachFrameSpecHasAtLeastOneCreative(
    state.campaign.campaign,
    state.campaign.frameSpecsFilter,
  ),
  frameSpecificationsHaveAtLeastOneDefaultCreative: checkEachFrameSpecHasAtLeastOneDefaultCreative(
    state.campaign.campaign,
    state.campaign.frameSpecsFilter,
  ),
  isCampaignAboutToGoLive: checkCampaignIsAboutToGoLive(state.campaign.campaign),
  isFetchingFrameSpecifications: state.creative.isFetchingFrameSpecifications,
  publishStatus: state.campaign.campaign.get('publishing-status'),
});

export const CampaignAlertContainer = connect(mapStateToProps)(CampaignAlert);
