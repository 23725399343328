import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'react-toolbox/lib/button';

import ProgressBar from 'components/patterns/ProgressBar';
import Link from 'assets/components/presentational/Link';
import { PushPin, ICON_COLORS } from 'assets/components/presentational/CustomIcon';

import style from './PinnedCampaigns.scss';

const PinnedCampaigns = ({ pinnedCampaigns, togglePinCampaign, fetchPinnedCampaigns, t }) => {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchPinnedCampaigns(setIsFetching);
  }, [fetchPinnedCampaigns]);

  return (
    <div className={style.component}>
      {isFetching ? (
        <ProgressBar />
      ) : (
        <>
          {pinnedCampaigns.length ? (
            <ul>
              {pinnedCampaigns.map((campaign) => (
                <li key={campaign.id}>
                  <Link to={`/campaigns/${campaign.id}`}>
                    <span>{campaign.name}</span>
                  </Link>
                  <IconButton
                    onClick={() => togglePinCampaign(campaign.id)}
                    icon={<PushPin fill={ICON_COLORS.BLUE} className={style.pinIcon} />}
                    theme={{ toggle: style.pinButton }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div className={style.noData}>{t('You have not pinned any campaigns')}</div>
          )}
        </>
      )}
    </div>
  );
};

PinnedCampaigns.propTypes = {
  pinnedCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  togglePinCampaign: PropTypes.func.isRequired,
  fetchPinnedCampaigns: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default PinnedCampaigns;
