import { API } from 'app-constants';
import appConfig from 'app-config';
import axios from 'axios';
import queryString from 'query-string';
import deepmerge from 'deepmerge';
import { Serializer, Deserializer } from 'jsonapi-serializer';
import { refresh } from 'actions/auth';
import { redirect } from 'modules/Helpers';
import { getErrors, getCode } from './response';
import * as constants from './constants';

const defaults = {
  endpoint: '',
  method: 'GET',
  data: null,
  headers: {},
  filters: [],
  includes: [],
  sorts: [],
  extraSerializerConfiguration: {},
  extraDeserializerConfiguration: {},
  attemptToRefresh: true,
  tryLogoutWhenUnauthorized: true,
  unauthenticated: false,
};

const paginationDefaults = {
  pagination: {
    pageNumber: 1,
    pageRange: {},
    pageSize: API.MAX_PAGE_SIZE,
  },
};

const setupPagination = (pagination) => {
  if (!pagination) {
    return { paginationConfig: {}, hasPageRange: false };
  }

  const paginationConfig = { pagination };
  let hasPageRange = false;
  if (paginationConfig.pagination.pageSize > API.MAX_PAGE_SIZE) {
    paginationConfig.pagination.pageSize = API.MAX_PAGE_SIZE;
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.warn(
        `The specified page size exceeds the maximum page size value for the API. Defaults to (${API.MAX_PAGE_SIZE})`,
      );
    }
  }

  hasPageRange = paginationConfig.pagination.pageRange.from && paginationConfig.pagination.pageRange.to;
  if (hasPageRange) {
    if (paginationConfig.pagination.pageRange.from < 1) {
      if (process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.warn(`wrong "pagination" parameters: "pageRange.from" is less than 1. Defaulting to 1`);
      }
      paginationConfig.pagination.pageRange.from = 1;
    }

    if (paginationConfig.pagination.pageRange.from === paginationConfig.pagination.pageRange.to) {
      paginationConfig.pagination.pageNumber = paginationConfig.pagination.pageRange.from;
      hasPageRange = false;
    } else if (paginationConfig.pagination.pageRange.from > paginationConfig.pagination.pageRange.to) {
      if (process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.warn(
          `wrong "pagination" parameters: "pageRange.from" is greater than "pageRange.to". Defaulting to "pageNumber=${paginationConfig.pagination.pageRange.from}"`,
        );
      }
      paginationConfig.pagination.pageNumber = paginationConfig.pagination.pageRange.from;
      hasPageRange = false;
    }
  }

  return { paginationConfig, hasPageRange };
};

export default class Request {
  static ALL_PAGES = {
    pageRange: {
      from: 1,
      to: Number.MAX_SAFE_INTEGER,
    },
    pageSize: API.MAX_PAGE_SIZE,
  };

  static buildUrl = (endpoint, pagination = null, filters = [], includes = [], sorts = []) => {
    const url = new URL(`${appConfig.api.url.trim('/')}/${endpoint.trim('/')}`);
    const search = queryString.parse(url.search);

    if (pagination && pagination.pageNumber !== null && pagination.pageSize !== null) {
      search['page[number]'] = pagination.pageNumber;
      search['page[size]'] = pagination.pageSize;
    }

    if (filters.length) {
      filters.forEach((filter) => {
        const field = Object.keys(filter)[0];
        const value = filter[field];
        search[`filter[${field}]`] = value;
      });
    }

    if (includes.length) {
      search.include = includes.join(',');
    }

    if (sorts.length) {
      search.sort = sorts.join(',');
    }

    if (appConfig.xdebug.enabled && appConfig.xdebug.key) {
      search.XDEBUG_SESSION_START = appConfig.xdebug.key;
    }

    url.search = encodeURI(queryString.stringify(search, { encode: false }));
    return url.href;
  };

  static mergeAuthorizationHeader = (headers = {}, jwt = '') => {
    jwt = jwt.length > 0 ? jwt : localStorage.getItem('jwt');

    if (jwt) {
      Object.assign(headers, { Authorization: decodeURI(jwt) });
    }

    return headers;
  };

  static mergeHeaders = (headers = {}, unauthenticated = false) => {
    const common = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    };

    if (unauthenticated) {
      return Object.assign(common, headers);
    }

    return Object.assign(Request.mergeAuthorizationHeader(common), headers);
  };

  static getAttributes = (data) => {
    if (Array.isArray(data) && data.length) {
      return this.getAttributes(data[0]);
    }

    if (!data || typeof data !== 'object') {
      return [];
    }

    return Object.keys(data);
  };

  static send = (extraOptions = {}) => {
    let config = extraOptions.pagination ? deepmerge(defaults, paginationDefaults) : deepmerge(defaults, {});
    config = deepmerge(config, extraOptions);
    const { paginationConfig, hasPageRange } = setupPagination(config.pagination);
    config = deepmerge(config, paginationConfig);

    const options = {
      url: Request.buildUrl(config.endpoint, config.pagination, config.filters, config.includes, config.sorts),
      data: config.data,
      method: config.method,
      responseType:
        config.headers && Object.keys(config.headers).includes('Content-Type')
          ? config.headers['Content-Type']
          : 'json',
      headers: Request.mergeHeaders(config.headers, config.unauthenticated),
      withCredentials: true,
      // Transform to JSON API spec
      transformRequest: (data) => {
        if (!data) {
          return JSON.stringify(data);
        }

        const serializedData = new Serializer('', {
          attributes: this.getAttributes(data),
          keyForAttribute: 'snake_case',
          ...config.extraSerializerConfiguration,
        }).serialize(data);

        return JSON.stringify(serializedData);
      },
      transformResponse: (rawData) => {
        if (!rawData || options.responseType !== 'json') {
          return rawData;
        }

        let body;

        try {
          body = typeof rawData === 'object' ? rawData : JSON.parse(rawData);
        } catch (e) {
          body = rawData;
        }

        return { body };
      },
    };

    return axios(options)
      .then((response) => {
        const body = (response && response.data && response.data.body) || {};

        if (!Object.keys(body).length) {
          return response;
        }

        const requests = [];
        const hasResponseMetaPagination =
          response.data.body && response.data.body.meta && response.data.body.meta.pagination;

        if (hasPageRange && hasResponseMetaPagination) {
          const totalPages = response.data.body.meta.pagination.total_pages;
          if (config.pagination.pageRange.to > totalPages) {
            config.pagination.pageRange.to = totalPages;
          }

          for (
            let currentPage = config.pagination.pageRange.from + 1;
            currentPage <= config.pagination.pageRange.to;
            currentPage++
          ) {
            requests.push(
              axios({
                ...options,
                url: Request.buildUrl(
                  config.endpoint,
                  { pageNumber: currentPage, pageSize: config.pagination.pageSize },
                  config.filters,
                  config.includes,
                  config.sorts,
                ),
              }),
            );
          }
        }

        return Promise.all(requests)
          .then((results) => {
            return results.reduce((prev, curr) => deepmerge(prev, curr), response);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      })
      .then((response) => {
        const body = (response && response.data && response.data.body) || {};

        if (!Object.keys(body).length) {
          return response;
        }

        return new Deserializer(config.extraDeserializerConfiguration)
          .deserialize(body)
          .then((parsed) => {
            response.data.body.parsed = parsed;
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      })
      .catch((e) => {
        const error = e;
        const hasResponse = typeof error === 'object' && error.response && error.response.data;
        if (!hasResponse) {
          return Promise.reject(error);
        }

        error.response.data.errors = getErrors(error.response.data.body);
        error.response.data.code = getCode(error.response.data.body);

        const errorCode = error.response.data.code;

        // If the JWT has expired
        if (
          (errorCode && errorCode === constants.API_EXCEPTION_AUTHJWTEXPIRED && config.attemptToRefresh) ||
          (errorCode && errorCode === constants.API_EXCEPTION_AUTHJWTINVALID && config.attemptToRefresh)
        ) {
          // Attempt to refresh token
          return refresh()
            .then(() => {
              // We managed to refresh, retry the initial request
              return Request.send({ ...config });
            })
            .catch(() => {
              // Retry request with attemptToRefresh set to false to avoid losing changes when refresh fails
              const args = { ...config, attemptToRefresh: false };
              return Request.send({ ...args });
            });
        }

        // If the user has been inactive or the jwt is no longer valid
        if (
          (errorCode && errorCode === constants.API_EXCEPTION_AUTHEXPIRED) ||
          (errorCode && errorCode === constants.API_EXCEPTION_AUTHNOMETHOD)
        ) {
          if (config.tryLogoutWhenUnauthorized) {
            // user will be redirected to this url if he logs in again
            let redirectTo = queryString.parse(window.location.search).next;
            if (!redirectTo) {
              const currentUrl = window.location.pathname;
              redirectTo = encodeURIComponent(currentUrl);
            }
            redirect(`/auth/logout?next=${redirectTo}`);
          }
          return Promise.reject(error);
        }

        error.response = Object.assign(error.response, {
          body: error.response.data.body,
          code: error.response.data.code,
          errors: error.response.data.errors,
        });

        return Promise.reject(error);
      });
  };
}
