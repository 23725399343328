import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Scroll from 'react-scroll';
import { Map, List } from 'immutable';
import moment from 'moment';
import { debounce, isEqual } from 'lodash';
import { userHasCampaignPermission } from 'store/user/helpers';
import { ContentModerationStatuses, redirect, getURLQueryString, smoothScroll } from 'modules/Helpers';
import { fetchCampaignRelations, clearCampaignRelation, deleteRelationRequest } from 'actions/campaign';
import { setActiveSection } from 'actions/campaign/navigation';
import {
  addContentItem,
  editContentItem,
  fetchContentItemForSettings,
  updateContentItemRequest,
  closeContentItemForm,
  createContentItemRequest,
  setActiveContentItemId,
  toggleShowDrawer,
  syncRuleSets,
  setContentSearchValue,
  setContentTypeFilter,
  setModerationStatusFilter,
  fetchContentItems,
  resetState,
} from 'actions/content';
import { getContentItems, getRuleSets, getContentTypes } from 'selectors/campaign';

import ProgressBar from 'components/patterns/ProgressBar';
import Drawer from 'assets/components/presentational/Drawer';
import ConfigurableFormContainer from 'assets/components/containers/ConfigurableForm/ConfigurableFormContainer';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import Pagination from 'assets/components/presentational/Pagination';
import ContentItemsFilters from 'assets/components/presentational/Campaign/ContentItemsFilters';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Content';
import Create from 'assets/components/presentational/Rows/Create';
import ContentItemRow from 'assets/components/presentational/Campaign/Content/ContentItemRow';

import AddContentItemForm from './AddContentItemForm';
import ContentItemSettings from './ContentItemSettings';

import style from './content.scss';

class Content extends Component {
  constructor(props) {
    super(props);
    this.handleFetchContentItems = debounce(this.handleFetchContentItems, 200);
    this.state = {
      // The rule prompting deletion
      prompting: new Map(),
      selectedContentItems: new List(),
    };
    this.drawerRef = null;
  }

  componentDidMount = () => {
    const {
      dispatch,
      params: { campaignId, pageNumber },
      location: {
        query: { contentTypeId, contentItemName, modStatus },
      },
    } = this.props;

    dispatch(setActiveSection('content'));
    dispatch(clearCampaignRelation(campaignId, ['content-items']));

    // Fetch only if no filters present, otherwise let ContentItemsFilters do the fetching
    if (!contentTypeId && !contentItemName && !modStatus) {
      dispatch(resetState());
      dispatch(fetchContentItems(campaignId, pageNumber));
    }

    // Required to populate the content type filter
    dispatch(fetchCampaignRelations(campaignId, 'content-types'));
  };

  handleFetchContentItems = (pageNumber, filters) => {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(fetchContentItems(campaignId, pageNumber, filters)).then(() => {
      Scroll.animateScroll.scrollToTop();
    });
  };

  handleContentTypeFilterChange = (nextContentTypeFilter) => {
    const { dispatch } = this.props;
    dispatch(setContentTypeFilter(nextContentTypeFilter));
  };

  handleContentSearchValueChange = (nextSearchContent) => {
    const { dispatch } = this.props;
    dispatch(setContentSearchValue(nextSearchContent));
  };

  handleContentModerationStatusFilterChange = (nextModStatus) => {
    const { dispatch } = this.props;
    dispatch(setModerationStatusFilter(nextModStatus));
  };

  updatePageNumber = (pageNumber) => {
    const {
      params: { campaignId },
    } = this.props;
    const queryString = getURLQueryString();

    return redirect(`/campaigns/${campaignId}/content/${pageNumber}${queryString}`);
  };

  getPageNumber = () => {
    let {
      params: { pageNumber },
    } = this.props;
    pageNumber = parseInt(pageNumber);

    return Number.isNaN(pageNumber) ? 1 : pageNumber;
  };

  handlePaginationClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;

    this.updatePageNumber(pageNumber);
  };

  handleCloseDrawer = () => {
    const { dispatch } = this.props;

    dispatch(toggleShowDrawer('settings'));
    dispatch(setActiveContentItemId(''));
  };

  handleContentItemRuleSetsChange = (contentItem, ruleSets) => {
    const { activeCampaignId, dispatch } = this.props;

    return dispatch(syncRuleSets(activeCampaignId, contentItem, ruleSets));
  };

  renderAddForm = () => {
    const { campaign, shouldAddAnotherContentItem, isSaving, contentTypes, selectedContentType, dispatch } = this.props;

    return (
      <AddContentItemForm
        campaignId={campaign.get('id')}
        dispatch={dispatch}
        onSubmit={(values, dispatch) => {
          const valid = this.validate(values);

          if (valid !== true) {
            if (this.drawerRef) {
              smoothScroll(this.drawerRef);
            }
            return Promise.reject(valid);
          }

          return createContentItemRequest(
            campaign.get('id'),
            values,
            dispatch,
            selectedContentType.id,
            shouldAddAnotherContentItem,
          ).then(() => redirect(`/campaigns/${campaign.get('id')}/content/1`));
        }}
        isSaving={isSaving}
        availableContentTypes={contentTypes}
        selectedContentType={selectedContentType}
        shouldAddAnotherContentItem={shouldAddAnotherContentItem}
      />
    );
  };

  renderEditForm = (contentItem) => {
    const { activeCampaignId, isSaving, dispatch, t } = this.props;

    if (!contentItem.size) {
      return null;
    }

    const form = contentItem
      .get('content-type')
      .get('form')
      .toJS();

    const fields = contentItem
      .get('content-fields')
      .toJS()
      .map((field) => {
        if (field.value && field.value.date) {
          field.value = moment.utc(field.value.date).toDate();
        }
        return field;
      });

    const initialValues = fields.reduce((prev, curr) => {
      const fieldConfig = form.fields.find((field) => field.name === curr.name);
      prev[fieldConfig.slug] = curr.value;
      return prev;
    }, {});

    form.fields.unshift({
      name: t('Edit your content'),
      type: 'heading',
    });

    form.fields.unshift({
      name: t('Name your manual content block'),
      slug: 'name',
      type: 'text',
    });

    initialValues.name = contentItem.get('name');

    return (
      <ConfigurableFormContainer
        name={`contentItem${contentItem.get('id')}`}
        formObject={form}
        submitting={isSaving}
        onCancel={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(closeContentItemForm());
        }}
        onSubmit={(values, dispatch) => {
          const valid = this.validate(values);

          if (valid !== true) {
            if (this.drawerRef) {
              this.drawerRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
            return Promise.reject(valid);
          }
          return updateContentItemRequest(
            activeCampaignId,
            this.filterValues(values, `contentItem${contentItem.get('id')}`),
            dispatch,
            contentItem,
          );
        }}
        initialValues={initialValues}
        isAddAnotherItemHidden
      />
    );
  };

  validate(values) {
    const { t } = this.props;
    const errors = {};

    // validate name
    if (!values.name || typeof values.name !== 'string' || values.name.length < 1) {
      errors.name = t('Name required');
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return true;
  }

  /**
   * Remove any field that has not changed
   * @param values
   * @param formName
   * @returns {*}
   */
  filterValues(values, formName) {
    const { reduxForm } = this.props;
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      if (isEqual(reduxForm[formName][keys[i]].initial, reduxForm[formName][keys[i]].value)) {
        delete values[keys[i]];
      }
    }
    return values;
  }

  renderPagination = () => {
    const { isFetchingContentItems, pagination } = this.props;
    const pageNumber = this.getPageNumber();
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <div>
        {pagination.get('total_pages') > 1 ? (
          <Pagination
            forceSelected={pageNumber - 1}
            hidden={isFetchingContentItems}
            initialSelected={initialSelected}
            onClick={this.handlePaginationClick}
            pageNum={pagination.get('total_pages')}
          />
        ) : null}
      </div>
    );
  };

  renderProgress() {
    const { isFetchingCampaign, isFetchingContentItems } = this.props;
    if (isFetchingCampaign || isFetchingContentItems) {
      return <ProgressBar />;
    }
    return null;
  }

  handleSettingsClick = (contentItem) => {
    const { campaign, dispatch } = this.props;

    return dispatch(fetchContentItemForSettings(campaign.get('id'), contentItem.get('id')));
  };

  handleViewClick = (contentItem) => {
    const { campaign } = this.props;

    return redirect(`/campaigns/${campaign.get('id')}/preview?content_item_ids[]=${contentItem.get('id')}`);
  };

  handleDeleteContentItemConfirmClick = (contentItem) => {
    const { campaign, dispatch } = this.props;
    const { prompting } = this.state;
    this.setState({
      prompting: prompting.clear(),
    });
    // Delete forever
    return dispatch(deleteRelationRequest(campaign.get('id'), 'content-items', ['content-items'], contentItem));
  };

  handleDeleteContentItemClick = (contentItem) => {
    this.setState({
      prompting: contentItem,
    });
  };

  handleDeleteContentItemCancelClick = () => {
    const { prompting } = this.state;

    this.setState({
      prompting: prompting.clear(),
    });
  };

  setDrawerRef = (ref) => {
    this.drawerRef = ref;
  };

  renderContentItems = () => {
    const { contentItems, isFetchingCampaign, isFetchingContentItems } = this.props;

    if (!(isFetchingCampaign || isFetchingContentItems)) {
      return (
        <div>
          {this.renderNewContentItem()}
          {contentItems
            .sort((a, b) => b.get('created-at') > a.get('created-at'))
            .map((contentItem, key) => this.renderContentItem(contentItem, key))}
        </div>
      );
    }

    return null;
  };

  renderNewContentItem = () => {
    const { campaign, dispatch, t, user } = this.props;
    const userHasPermission = userHasCampaignPermission(user, campaign.get('id'), 'edit_dynamic_content');

    if (!userHasPermission) {
      return null;
    }

    return <Create createLabel={t('Create new manual content')} onCreateClick={() => dispatch(addContentItem())} />;
  };

  renderContentItem = (contentItem, contentItemKey) => {
    const { campaign, dispatch, user } = this.props;
    const { prompting, selectedContentItems } = this.state;

    return (
      <ContentItemRow
        campaign={campaign}
        contentItem={contentItem}
        isDeleting={prompting.get('id') === contentItem.get('id')}
        key={contentItemKey}
        isModerationStatusDisplayed
        isSelected={selectedContentItems.indexOf(contentItem) !== -1}
        isDeletable={userHasCampaignPermission(user, campaign.get('id'), 'edit_dynamic_content')}
        isEditable={userHasCampaignPermission(user, campaign.get('id'), 'edit_dynamic_content')}
        isSelectable={false}
        isViewable
        isConfigurable={
          userHasCampaignPermission(user, campaign.get('id'), 'target') &&
          contentItem.get('status') === ContentModerationStatuses.APPROVED
        }
        onViewClick={this.handleViewClick}
        onEditClick={() => dispatch(editContentItem(contentItem))}
        onDeleteClick={this.handleDeleteContentItemClick}
        onDeleteCancelClick={this.handleDeleteContentItemCancelClick}
        onDeleteConfirmClick={this.handleDeleteContentItemConfirmClick}
        onSettingsClick={this.handleSettingsClick}
      />
    );
  };

  renderContentItemForm = () => {
    const { activeContentItem, dispatch, showContentItemForm } = this.props;

    return (
      <Drawer
        getRef={this.setDrawerRef}
        active={showContentItemForm}
        onOverlayClick={() => dispatch(closeContentItemForm())}
      >
        {activeContentItem.size ? this.renderEditForm(activeContentItem) : this.renderAddForm()}
      </Drawer>
    );
  };

  renderSettingsForm = () => {
    const { activeContentItem, isFetchingContentItem, isSavingRuleSets, ruleSets, showSettingsForm } = this.props;

    return (
      <ContentItemSettings
        active={showSettingsForm}
        contentItem={activeContentItem}
        fetching={isFetchingContentItem}
        onClose={this.handleCloseDrawer}
        onRuleSetsChange={this.handleContentItemRuleSetsChange}
        ruleSets={ruleSets}
        saving={isSavingRuleSets}
      />
    );
  };

  render() {
    const {
      t,
      location,
      params,
      contentTypes,
      selectedContentTypeFilter,
      selectedModerationStatusFilter,
      searchContent,
      activeNavSection,
      user,
    } = this.props;

    return (
      <div className={style.component}>
        <HeadingGroup
          subNavigation={
            <SubNavigation active={SubNavigationLabels.ALL_CONTENT} campaignId={params.campaignId} user={user} />
          }
          title={t('Content')}
          intro={t('Content section introduction')}
        />

        <div className={style.filterContainer}>
          <ContentItemsFilters
            activeNavSection={activeNavSection}
            location={location}
            params={params}
            contentTypeFilterLabel={t('Filter by content type')}
            moderationStatusFilterLabel={t('Filter by moderation status')}
            searchPlaceholderText={t('Search content')}
            contentTypes={contentTypes}
            selectedContentTypeFilter={selectedContentTypeFilter}
            selectedModerationStatusFilter={selectedModerationStatusFilter}
            searchContent={searchContent}
            onContentTypeFilterChange={this.handleContentTypeFilterChange}
            onContentSearchValueChange={this.handleContentSearchValueChange}
            onContentModerationStatusFilterChange={this.handleContentModerationStatusFilterChange}
            fetchContentItems={this.handleFetchContentItems}
          />
        </div>

        {this.renderContentItemForm()}
        {this.renderSettingsForm()}
        {this.renderProgress()}
        {this.renderContentItems()}
        {this.renderPagination()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeNavSection: state.campaignNavigation.activeSection,
  activeCampaignId: state.campaign.activeCampaignId,
  activeContentItem: getContentItems(state).find(
    (c) => c.get('id') === state.content.activeContentItemId,
    null,
    new Map(),
  ),
  campaign: state.campaign.campaign,
  contentItems: state.campaign.campaign.get('content-items', List()),
  contentTypes: getContentTypes(state),
  isFetchingContentItem: state.content.isFetchingContentItem,
  isFetchingContentItems: state.content.isFetchingContentItems,
  isFetchingCampaign: state.campaign.isFetching,
  isSaving: state.content.isSaving,
  isSavingRuleSets: state.content.isSavingRuleSets,
  pagination: state.content.pagination,
  reduxForm: state.form,
  ruleSets: getRuleSets(state),
  selectedContentType: state.content.selectedContentType,
  selectedContentTypeFilter: state.content.selectedContentTypeFilter,
  selectedModerationStatusFilter: state.content.selectedModerationStatusFilter,
  searchContent: state.content.searchContentValue,
  showContentItemForm: state.content.showContentItemForm,
  showSettingsForm: state.content.showSettingsForm,
  user: state.auth.user,
  shouldAddAnotherContentItem: state.content.shouldAddAnotherContentItem,
});

export default withNamespaces(['content', 'common'], { wait: false })(connect(mapStateToProps)(Content));

Content.propTypes = {
  activeNavSection: PropTypes.string.isRequired,
  activeCampaignId: PropTypes.string.isRequired,
  activeContentItem: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  contentItems: PropTypes.instanceOf(List).isRequired,
  contentTypes: PropTypes.instanceOf(List).isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingContentItem: PropTypes.bool.isRequired,
  isFetchingContentItems: PropTypes.bool.isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isSavingRuleSets: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  params: PropTypes.object.isRequired,
  reduxForm: PropTypes.object.isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  selectedContentType: PropTypes.oneOfType([PropTypes.object, PropTypes.null]),
  selectedContentTypeFilter: PropTypes.string,
  selectedModerationStatusFilter: PropTypes.string,
  searchContent: PropTypes.string,
  showContentItemForm: PropTypes.bool.isRequired,
  showSettingsForm: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  shouldAddAnotherContentItem: PropTypes.bool,
};

Content.defaultProps = {
  selectedContentType: null,
};
