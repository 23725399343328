import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import activity from 'store/activity/reducer';
import search from 'store/search/reducer';
import bookings from 'store/bookings/reducer';
import publications from 'store/publications/reducer';
import uploads from 'store/uploads/reducer';
import dashboardNew from 'store/dashboardNew/reducer';
import pinned from 'store/pinnedCampaigns/reducer';
import snooze from 'store/snoozeCampaign/reducer';

import auth from './auth';
import businessUnits from './businessUnits';
import campaign from './campaign';
import campaignBuild from './campaign/build';
import campaignContentSchedule from './campaign/contentSchedule';
import campaignCreativeFailed from './campaign/creative/failed';
import campaignCreativePreview from './campaign/creative/preview';
import campaignInvites from './campaign/invites';
import campaignMediaBuy from './campaign/mediaBuy';
import campaignNavigation from './campaign/navigation';
import campaignPlaylists from './campaign/playlists';
import campaignPresenceIndicator from './campaign/presenceIndicator';
import content from './content';
import contentTypes from './contentTypes';
import creative from './creative';
import dropZone from './drop-zone';
import enhancedModeration from './enhancedModeration';
import frames from './frames/frames';
import i18n from './i18n';
import liteModeration from './liteModeration';
import moderation from './moderation';
import permissions from './permissions';
import preview from './preview';
import ruleSets from './ruleSets';
import screenshots from './frames/screenshots';
import snackbar from './snackbar';
import tagKeys from './tagKeys';
import transport from './transport';
import unsavedChangesDialog from './unsavedChangesDialog';
import users from './users';

// eslint-disable-next-line no-unused-vars
export default function getReducer(FEATURES = {}) {
  const reducers = {
    activity,
    auth,
    bookings,
    businessUnits,
    campaign,
    campaignBuild,
    campaignContentSchedule,
    campaignCreativeFailed,
    campaignCreativePreview,
    campaignInvites,
    campaignMediaBuy,
    campaignNavigation,
    campaignPlaylists,
    campaignPresenceIndicator,
    content,
    contentTypes,
    creative,
    dashboard: dashboardNew,
    dropZone,
    enhancedModeration,
    form: formReducer,
    frames,
    i18n,
    liteModeration,
    moderation,
    permissions,
    pinned,
    preview,
    publications,
    routing: routerReducer,
    ruleSets,
    screenshots,
    search,
    snackbar,
    snooze,
    tagKeys,
    transport,
    unsavedChangesDialog,
    uploads,
    users,
  };

  /*
   * I.E. add reducers conditionally based on feature flags
   *
   */

  // foo would be the imported reducer. Declared here as a variable to avoid exceptions
  const foo = (state) => state;

  if (FEATURES.FOO) {
    reducers.foo = foo;
  }

  return combineReducers(reducers);
}
