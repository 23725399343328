import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import moment from 'moment';
import { IconButton } from 'react-toolbox/lib/button';

import { redirect } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import Table from 'assets/components/presentational/Table';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import { PushPin, ICON_COLORS } from 'assets/components/presentational/CustomIcon';

import style from './Grid.scss';

const Grid = ({ campaigns, pinnedCampaigns, campaignsInProcess, togglePinCampaign, t }) => {
  const model = {
    isPinned: { type: String, title: '' },
    name: { type: String, title: 'NAME' },
    timeline: { type: String, title: 'TIMELINE' },
    bookingReference: { type: String, title: 'BOOKING REFERENCE' },
    frameSpecs: { type: String, title: 'FRAME SPECS' },
    bookingBrands: { type: String, title: 'BOOKING BRANDS' },
    bookingExternalReferences: { type: String, title: 'BOOKING EXTERNAL REFRENCES' },
    status: { type: String, title: 'STATUS' },
  };

  const source = campaigns
    .map((campaign) => {
      const isCampaignLive =
        moment.utc(campaign.get('starts-at')) <= moment.utc() && moment.utc() <= moment.utc(campaign.get('ends-at'));
      const isPinned = pinnedCampaigns.filter((c) => c.id === campaign.get('id')).length > 0;
      const isFetching = campaignsInProcess.filter((campaignId) => campaignId === campaign.get('id')).length > 0;
      const bookingBrands = campaign.get('booking-brands', []).size
        ? campaign
            .get('booking-brands')
            .toSet()
            .join(', ')
        : [];

      const bookingExternalReferences = campaign.get('booking-external-references', []).size
        ? campaign
            .get('booking-external-references')
            .toSet()
            .join(', ')
        : [];
      return {
        isPinned: isFetching ? (
          <ProgressBar />
        ) : (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              togglePinCampaign(campaign.get('id'));
            }}
            icon={<PushPin fill={isPinned ? ICON_COLORS.BLUE : ICON_COLORS.MEDIUM_GRAY} className={style.pinIcon} />}
            theme={{ toggle: style.pinButton }}
          />
        ),
        name: campaign.get('name'),
        timeline: `${moment.utc(campaign.get('starts-at')).format('DD MMM YYYY')} to ${moment
          .utc(campaign.get('ends-at'))
          .format('DD MMM YYYY')}`,
        bookingReference: <span className={style.bookingReference}>{campaign.get('booking-engine-id')}</span>,
        frameSpecs: campaign
          .get('frame-specifications')
          .toJS()
          .reduce((acc, frameSpec) => (!acc ? frameSpec.name : `${acc}, ${frameSpec.name}`), ''),
        bookingBrands,
        bookingExternalReferences,
        status: (
          <span className={style.status}>
            <Icon
              iconType={IconTypes.FIBER_MANUAL_RECORD}
              color={isCampaignLive ? IconColors.GREEN : IconColors.RED}
              className={style.statusIcon}
            />
            <span className={style.statusName}>{isCampaignLive ? 'Live' : 'Inactive'}</span>
          </span>
        ),

        rowData: campaign,
      };
    })
    .toJS();

  const onClickRow = (rowData) => {
    redirect(`/campaigns/${rowData.get('id')}`);
  };

  return (
    <div data-testid="campaigns">
      <Table className={style.grid} model={model} source={source} onClickRow={onClickRow} />
      {!campaigns.size && (
        <div className={style.noData}>
          <p>{t('No campaigns found')}</p>
        </div>
      )}
    </div>
  );
};

Grid.propTypes = {
  campaigns: PropTypes.instanceOf(List).isRequired,
  pinnedCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  campaignsInProcess: PropTypes.arrayOf(PropTypes.string).isRequired,
  togglePinCampaign: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Grid;
