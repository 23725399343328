import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { CreativeTypes } from 'modules/Helpers';
import ProgressBar from 'components/patterns/ProgressBar';
import Link from 'assets/components/presentational/Link';
import Thumbnail from 'assets/components/presentational/Rows/Thumbnail';

import style from '../popup.scss';

export const placeholder = (
  <div className={style.thumbnailContainer}>
    <Thumbnail key={Date.now()} />
  </div>
);

const CreativeDetail = ({ campaignId, creative, fetchAdditionalCreativeData }) => {
  const creativeId = creative.get('id');

  const [additionalData, setAdditionalData] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    let processingTimer;

    const extraOptions = { includes: ['asset', 'prepared-asset', 'thumbnail', 'content-types'] };
    fetchAdditionalCreativeData(creativeId, extraOptions).then((data) => {
      setAdditionalData(data);
      processingTimer = setTimeout(() => {
        setIsFetching(false);
      }, 3000);
    });

    return () => clearTimeout(processingTimer);
  }, [creativeId, fetchAdditionalCreativeData]);

  const url = `campaigns/${campaignId}/creative/${creative.get('id')}`;
  const src =
    [CreativeTypes.IMAGE, CreativeTypes.VIDEO].includes(additionalData.type) && additionalData.thumbnail
      ? additionalData.thumbnail.url
      : null;

  return (
    <>
      <div className={style.thumbnailContainer}>
        <Thumbnail key={src} src={src} alt={`Creative: ${creative.get('name', '')}`} link={url} />
        {isFetching && (
          <div className={style.progressBar}>
            <ProgressBar />
          </div>
        )}
      </div>
      <Link to={url} className={style.creativeNameLink}>
        <span className={style.creativeName}>{creative.get('name')}</span>
      </Link>
    </>
  );
};

CreativeDetail.propTypes = {
  campaignId: PropTypes.string.isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  fetchAdditionalCreativeData: PropTypes.func.isRequired,
};

export default CreativeDetail;
