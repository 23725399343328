import React from 'react';
import PropTypes from 'prop-types';
import {
  Table as ReactToolboxTable,
  TableCell as ReactToolboxTableCell,
  TableHead as ReactToolboxTableHead,
  TableRow as ReactToolboxTableRow,
} from 'react-toolbox/lib/table';
import style from './table.scss';

const Table = ({ className, model, multiSelectable, onSelect, selectable, selected, source, onClickRow }) => (
  <div className={style.component.concat(' ', className)}>
    <ReactToolboxTable multiSelectable={multiSelectable} selectable={selectable} onRowSelect={onSelect}>
      <ReactToolboxTableHead>
        {Object.values(model).map(({ title }, index) => (
          <ReactToolboxTableCell key={index}>{title}</ReactToolboxTableCell>
        ))}
      </ReactToolboxTableHead>
      {source.map((data, index) => (
        <ReactToolboxTableRow
          selected={selected.includes(index)}
          key={index}
          onClick={(e) => onClickRow(data.rowData, e)}
        >
          {Object.entries(data).map(([key, item], index) => {
            const isDataRow = key === 'rowData';
            if (isDataRow) {
              return null;
            }

            return <ReactToolboxTableCell key={index}>{item}</ReactToolboxTableCell>;
          })}
        </ReactToolboxTableRow>
      ))}
    </ReactToolboxTable>
  </div>
);

export default Table;

Table.propTypes = {
  className: PropTypes.string,
  model: PropTypes.object,
  multiSelectable: PropTypes.bool,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.array,
  source: PropTypes.array,
  onClickRow: PropTypes.func,
};

Table.defaultProps = {
  className: '',
  selectable: false,
  multiSelectable: false,
  selected: [],
  source: [],
  onSelect: () => {},
  onClickRow: () => {},
};
