import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import { setActiveSection } from 'actions/campaign/navigation';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import ProgressBar from 'components/patterns/ProgressBar';
import Table from 'assets/components/presentational/Table';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import { createBuild, fetchBuilds, fetchBuild, deleteBuild } from 'actions/campaign/build';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import ConfirmButton from 'assets/components/presentational/ConfirmButton';

import style from './builds.scss';
import {
  BROADCAST_EVENTS,
  privateCampaignChannel,
  broadcasting,
} from '../../../../../modules/Broadcasting/broadcasting';

class Build extends Component {
  broadcastingChannel = null;

  componentDidMount() {
    const { dispatch } = this.props;
    const {
      params: { campaignId },
    } = this.props;

    dispatch(setActiveSection('builds'));
    this.connectToBroadcasting();

    dispatch(fetchBuilds(campaignId));
  }

  componentWillUnmount = () => {
    this.unsubscribeFromBroadcasting();
  };

  connectToBroadcasting = () => {
    const {
      params: { campaignId },
    } = this.props;

    this.broadcastingChannel = broadcasting()
      .setJwt(localStorage.getItem('jwt'))
      .subscribe(privateCampaignChannel(campaignId))
      .bind(BROADCAST_EVENTS.BUILD_COMPLETED, this.handlePusherBuildUpdate)
      .bind(BROADCAST_EVENTS.BUILD_FAILED, this.handlePusherBuildUpdate);
  };

  unsubscribeFromBroadcasting() {
    if (!this.broadcastingChannel) {
      return;
    }

    this.broadcastingChannel.unsubscribe();
  }

  handlePusherBuildUpdate = (data) => {
    const { dispatch } = this.props;
    dispatch(fetchBuild(data.build_id));
  };

  handleCreateBuild = () => {
    const {
      params: { campaignId },
      dispatch,
    } = this.props;
    return dispatch(createBuild(campaignId));
  };

  renderHeading = () => {
    const { t } = this.props;
    return <HeadingGroup title={t('Builds')} intro={t('Builds section introduction')} />;
  };

  renderProgress = () => {
    const { isFetchingBuilds } = this.props;

    if (isFetchingBuilds) {
      return <ProgressBar />;
    }
  };

  renderCreateButton = () => {
    const { isFetchingBuilds, t } = this.props;
    if (isFetchingBuilds) return null;
    return (
      <ConfirmButton
        onConfirmClick={this.handleCreateBuild}
        theme={ButtonThemes.PRIMARY}
        label={t('Create Build')}
        confirmLabel={t('Are you sure?')}
        buttonTheme={ButtonThemes.PRIMARY}
        confirmButtonTheme={ButtonThemes.WARNING}
      />
    );
  };

  handleDeleteBuild = (buildId) => {
    const { dispatch } = this.props;
    return dispatch(deleteBuild(buildId));
  };

  renderBuildStatus = (build) => {
    const buildStatus = build.get('status');
    switch (buildStatus) {
      case Build.BuildStatuses.STARTED:
        return (
          <div className={style.status}>
            <div className={style.statusIcon}>
              <Icon iconType={IconTypes.QUERY_BUILDER} color={IconColors.ORANGE} />
            </div>
            <span className={style.statusText}>{buildStatus}</span>
          </div>
        );
      case Build.BuildStatuses.COMPLETED:
        return (
          <div className={style.status}>
            <div className={style.statusIcon}>
              <Icon iconType={IconTypes.CHECK_CIRCLE} color={IconColors.GREEN} />
            </div>
            <span className={style.statusText}>{buildStatus}</span>
          </div>
        );
      case Build.BuildStatuses.FAILED:
        return (
          <div className={style.status}>
            <div className={style.statusIcon}>
              <Icon iconType={IconTypes.ERROR_OUTLINE} color={IconColors.RED} />
            </div>
            <span className={style.statusText}>{buildStatus}</span>
          </div>
        );
      default:
        return null;
    }
  };

  renderActionButton = (build) => {
    const { isDeletingBuilds, t } = this.props;
    const buildStatus = build.get('status');
    const isDeleting = isDeletingBuilds.get(build.get('id'), false);

    switch (buildStatus) {
      case Build.BuildStatuses.STARTED:
        return <ProgressBar />;
      case Build.BuildStatuses.COMPLETED:
        return <Button theme={ButtonThemes.WHITE} href={build.get('url')} label={t('Download')} />;
      case Build.BuildStatuses.FAILED:
        return (
          <ConfirmButton
            onConfirmClick={() => this.handleDeleteBuild(build.get('id'))}
            theme={ButtonThemes.WARNING}
            label={t('Delete')}
            confirmLabel={t('Are you sure?')}
            disabled={isDeleting}
            buttonTheme={ButtonThemes.WARNING}
            confirmButtonTheme={ButtonThemes.DANGER}
            showProgress={false}
          />
        );
    }
  };

  renderBuilds = () => {
    const { isFetchingBuilds, builds, t } = this.props;

    if (isFetchingBuilds) {
      return null;
    }

    const model = {
      name: { type: String, title: 'Name' },
      created: { type: String, title: 'Created' },
      updated: { type: String, title: 'Updated' },
      status: { type: String, title: 'Status' },
      [t('Action')]: { type: String, title: 'Action' },
    };

    const buildData = builds
      .sortBy((build) => (build.get('updated-at') ? build.get('updated-at') : build.get('created-at')))
      .reverse()
      .map((build) => ({
        name: build.get('name'),
        created: moment(build.get('created-at')).format('DD MMM YYYY h:mm:ss a'),
        updated: build.get('updated-at') ? moment(build.get('updated-at')).format('DD MMM YYYY h:mm:ss a') : '',
        status: this.renderBuildStatus(build),
        [t('Action')]: this.renderActionButton(build),
      }))
      .toJS();

    return (
      <div className={style.tableContainer}>
        <Heading className={style.tableHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
          <Icon iconType={IconTypes.UNARCHIVE} />
          {t('Builds')}
        </Heading>
        <Table model={model} source={buildData} />
      </div>
    );
  };

  render = () => (
    <div className={style.component}>
      {this.renderHeading()}
      {this.renderProgress()}
      {this.renderCreateButton()}
      {this.renderBuilds()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  builds: state.campaignBuild.builds,
  isFetchingBuilds: state.campaignBuild.isFetching,
  isDeletingBuilds: state.campaignBuild.isDeleting,
});

export default withNamespaces(['common'], { wait: false })(connect(mapStateToProps)(Build));

Build.propTypes = {
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  builds: PropTypes.instanceOf(List).isRequired,
  isFetchingBuilds: PropTypes.bool.isRequired,
  isDeletingBuilds: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};

Build.BuildStatuses = {
  STARTED: 'started',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
