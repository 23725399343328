import React from 'react';
import PropTypes from 'prop-types';

import style from './Accordion.scss';

const Accordion = ({ title, expandIcon, collapseIcon, isExpanded, children }) => {
  return (
    <div className={style.component}>
      <div className={style.toggle}>
        <span className={style.title}>{title}</span>
        <span>{isExpanded ? collapseIcon : expandIcon}</span>
      </div>
      <div className={isExpanded ? style.expanded : style.collapsed}>{children}</div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.node,
  expandIcon: PropTypes.node,
  collapseIcon: PropTypes.node,
  isExpanded: PropTypes.bool,
  children: PropTypes.node,
};

Accordion.defaultProps = {
  title: null,
  expandIcon: null,
  collapseIcon: null,
  isExpanded: false,
  children: null,
};

export default Accordion;
