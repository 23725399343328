import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { List } from 'immutable';
import { reduxForm } from 'redux-form';

import { toggleCampaignFormDialog, setCampaignFormDialogMessage } from 'actions/campaign';

import Input from 'components/patterns/Input';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import DatePicker from 'components/patterns/DatePicker';
import Autocomplete from 'assets/components/presentational/Autocomplete';
import Dialog from 'assets/components/presentational/Dialog';
import TagWidget from 'assets/components/presentational/TagWidget';

import Switch from 'components/patterns/Switch';
import BookingsSearch from 'components/common/BookingsSearch';
import BookingsTable from 'components/pages/CampaignSettings/BookingsTable';

import style from './campaignForm.scss';

class Form extends Component {
  state = {
    hasAutoPopulatedBefore: false,
  };

  componentDidMount() {
    const { updateBusinessUnits, businessUnits } = this.props;
    updateBusinessUnits(businessUnits);
  }

  componentDidUpdate() {
    const { updateBusinessUnits, businessUnits } = this.props;
    updateBusinessUnits(businessUnits);
  }

  updateFormFieldsWithBookingData = (bookings) => {
    const {
      fields: { EndDate, StartDate, Brand, Name, UpdateFrequency, BusinessUnit },
    } = this.props;
    const { hasAutoPopulatedBefore } = this.state;

    if (Array.isArray(bookings) && bookings.length && !hasAutoPopulatedBefore) {
      // Set update frequency
      UpdateFrequency.onChange(5);

      Name.onChange(bookings[0].name);

      // Set brand
      Brand.onChange(bookings[0].advertiser);

      // Set BusinessUnits
      BusinessUnit.onChange([bookings[0]['business-unit-id']]);

      // Set dates
      this.updateDates(bookings, EndDate, StartDate);

      this.setState({ hasAutoPopulatedBefore: true });
    }
  };

  onAddBooking = (booking) => {
    const {
      fields: { Bookings },
    } = this.props;

    Bookings.onChange(Bookings.value.concat([booking]));
    this.updateFormFieldsWithBookingData([booking]);
  };

  onBookingManagementWidgetChange = (values) => {
    const {
      fields: { Bookings },
    } = this.props;

    Bookings.onChange(values);
  };

  checkBookingsConsistency(event) {
    const { includeBookings } = this.props;

    if (!includeBookings) {
      return;
    }

    const {
      fields: { Bookings },
      campaign,
      dispatch,
      t,
    } = this.props;
    if (!Array.isArray(Bookings.value) || Bookings.value.length === 0) {
      return;
    }
    let message = '';

    // Check to see if any bookings already have a campaign attached, just smush all the ids together to see if
    // there were any there
    const campaignIds = Bookings.value.reduce((carry, item) => {
      if (campaign && campaign.get('id') === item['campaign-id']) {
        return `${carry}`;
      }
      if (item['campaign-id']) {
        return carry + item['campaign-id'];
      }
      return `${carry}`;
    }, '');
    if (campaignIds.length) {
      message = `${message +
        t(
          'One or more selected bookings is already attached to a campaign. If you continue, this booking will be removed from the other campaign',
        )}. `;
    }

    // Check to see if any bookings have already ended
    const earliestEndDate = Bookings.value
      .map((item) => moment(item['ends-at'].date).endOf('day'))
      .sort()
      .shift()
      .toDate();
    if (
      earliestEndDate <
      moment()
        .startOf('day')
        .toDate()
    ) {
      message = `${message + t('One or more selected bookings have already ended')}. `;
    }

    if (message.length) {
      event.preventDefault(); // Stop the submit
      dispatch(toggleCampaignFormDialog());
      dispatch(setCampaignFormDialogMessage(message + t('Would you like to continue saving this campaign?')));
    }
  }

  handleCancel() {
    const { resetForm, onCancelClick } = this.props;
    resetForm();
    onCancelClick();
  }

  businessUnits() {
    const { campaign, businessUnits } = this.props;
    const campaignBusinessUnits = campaign ? campaign.get('business-units').toJS() : [];
    return campaignBusinessUnits.concat(businessUnits.toJS()).reduce((carry, item) => {
      carry[item.id] = item.name;
      return carry;
    }, {});
  }

  updateDates(values, EndDate, StartDate) {
    const offset = new Date().getTimezoneOffset();

    let earliestStartDate = values
      .map((item) => moment(item['starts-at']))
      .sort()
      .shift();

    earliestStartDate = earliestStartDate
      .add(earliestStartDate.toDate().getTimezoneOffset(), 'minutes')
      .startOf('day')
      .toDate();

    let latestEndDate = values
      .map((item) => moment(item['ends-at']))
      .sort()
      .pop();

    latestEndDate = latestEndDate
      .add(latestEndDate.toDate().getTimezoneOffset(), 'minutes')
      .endOf('day')
      .toDate();

    // Makes sure the new date is later than the already selected one
    if (!EndDate.value || latestEndDate > EndDate.value) {
      // Earliest selected end date must be at least the end of tomorrow, if it isn't, do nothing
      if (
        latestEndDate >=
        moment()
          .startOf('day')
          .add(offset, 'minutes')
          .add(1, 'days')
          .toDate()
      ) {
        EndDate.onChange(latestEndDate);
      }
    }

    // Make sure the new date is earlier than the one previously selected
    if (!StartDate.value || earliestStartDate < StartDate.value) {
      const startDateToday = moment()
        .startOf('day')
        .add(offset, 'minutes')
        .toDate();
      // Only move the start date back as far as today
      if (earliestStartDate > startDateToday) {
        StartDate.onChange(earliestStartDate);
      } else {
        StartDate.onChange(startDateToday);
      }
    }
  }

  renderUnsavedChangesDialog() {
    const { isDialogActive, dialogMessage, t, dispatch, handleSubmit } = this.props;
    const actions = [
      {
        label: t('Yes'),
        onClick: () => {
          dispatch(toggleCampaignFormDialog());
          dispatch(setCampaignFormDialogMessage(''));
          handleSubmit();
        },
      },
      {
        label: t('No'),
        onClick: () => {
          dispatch(toggleCampaignFormDialog());
          dispatch(setCampaignFormDialogMessage(''));
        },
      },
    ];

    return <Dialog active={isDialogActive} actions={actions} title={dialogMessage} type="small" />;
  }

  renderButtons() {
    const { submitting, successLabel, t } = this.props;

    return (
      <div className={style.actionGroup}>
        <Button
          label={t('Cancel')}
          className={style.cancelButton}
          type="button"
          onClick={(event) => this.handleCancel(event)}
          theme={ButtonThemes.WHITE}
          disabled={submitting}
          tabIndex="-1"
        />
        <Button
          label={successLabel || t('Create Campaign')}
          type="submit"
          className={style.saveButton}
          disabled={submitting}
          onClick={(event) => {
            this.checkBookingsConsistency(event);
          }}
          tabIndex="-1"
        />
      </div>
    );
  }

  render() {
    const {
      fields: {
        Brand,
        Name,
        BusinessUnit,
        StartDate,
        EndDate,
        UpdateFrequency,
        Bookings,
        Managers,
        RequiresWeatherData,
      },
      handleSubmit,
      businessUnits,
      includeManagers,
      includeBookings,
      t,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          label={t('Brand')}
          value={Brand.value}
          onChange={Brand.onChange}
          onFocus={Brand.onFocus}
          onBlur={Brand.onBlur}
          error={Brand.error}
        />
        <Input
          type="text"
          label={t('Campaign Name')}
          value={Name.value}
          onChange={Name.onChange}
          onFocus={Name.onFocus}
          onBlur={Name.onBlur}
          error={Name.error}
        />
        <Autocomplete
          label={t('Business Units')}
          source={this.businessUnits()}
          value={BusinessUnit.value}
          onChange={BusinessUnit.onChange}
          error={BusinessUnit.error}
          disabled={businessUnits === null}
          selectedPosition="below"
          multiple={false}
        />
        {includeBookings && (
          <div>
            <BookingsSearch onChange={this.onAddBooking} />
            <BookingsTable
              bookings={Array.isArray(Bookings.value) ? Bookings.value : []}
              businessUnits={businessUnits.toJS()}
              onChange={this.onBookingManagementWidgetChange}
            />
          </div>
        )}

        <DatePicker
          label={t('Starts At')}
          value={StartDate.value}
          onChange={StartDate.onChange}
          error={StartDate.error}
          minDate={moment()
            .startOf('day')
            .toDate()}
          maxDate={
            EndDate.value
              ? moment(EndDate.value)
                  .endOf('day')
                  .toDate()
              : null
          }
        />
        <DatePicker
          label={t('Ends At')}
          value={EndDate.value}
          onChange={EndDate.onChange}
          error={EndDate.error}
          minDate={
            StartDate.value
              ? moment(StartDate.value)
                  .startOf('day')
                  .toDate()
              : moment()
                  .startOf('day')
                  .toDate()
          }
        />
        {includeManagers ? (
          <TagWidget
            value={Managers.value}
            onChange={Managers.onChange}
            error={Managers.error}
            label={t('Campaign Managers')}
            hint={t('Enter a comma separated list of emails')}
            emailsOnly
          />
        ) : null}
        <Input
          className={style.updatedFrequencyInput}
          type="number"
          label={t('Update Frequency (mins)')}
          hint={t('How often {{application}} will process the rules/data of this campaign', {
            application: config.app.name,
          })}
          value={UpdateFrequency.value}
          onChange={UpdateFrequency.onChange}
          onFocus={UpdateFrequency.onFocus}
          onBlur={UpdateFrequency.onBlur}
          error={UpdateFrequency.error}
          min="0"
        />
        <div className={style.weatherOptIn}>
          <span className={style.label}>{t('Weather opt-in')}</span>
          <Switch checked={RequiresWeatherData.value} onChange={RequiresWeatherData.onChange} label="" />
        </div>
        {this.renderButtons()}
        {this.renderUnsavedChangesDialog()}
      </form>
    );
  }
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  businessUnits: PropTypes.object,
  updateBusinessUnits: PropTypes.func,
  onCancelClick: PropTypes.func,
  isDialogActive: PropTypes.bool,
  dialogMessage: PropTypes.string,
  campaign: PropTypes.object,
  user: PropTypes.object,
  includeManagers: PropTypes.bool,
  managers: PropTypes.instanceOf(List),
  successLabel: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  includeBookings: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

Form.defaultProps = {
  businessUnits: new List([]),
  managers: new List([]),
  includeBookings: false,
  updateBusinessUnits: null,
  onCancelClick: null,
  isDialogActive: false,
  dialogMessage: '',
  includeManagers: true,
  successLabel: '',
};

export default reduxForm({
  form: 'createCampaign',
  fields: [
    'Brand',
    'Name',
    'BusinessUnit',
    'StartDate',
    'EndDate',
    'Managers',
    'UpdateFrequency',
    'Bookings',
    'RequiresWeatherData',
  ],
  initialValues: { Managers: List(), Bookings: [], RequiresWeatherData: false },
})(Form);
