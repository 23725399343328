import { hideDialog, confirmDialog } from 'actions/unsavedChangesDialog';

const mapStateToProps = ({ unsavedChangesDialog }) => ({
  showDialog: unsavedChangesDialog.showDialog,
  nextLocation: unsavedChangesDialog.nextLocation,
});

const mapDispatchToProps = (dispatch) => ({
  confirm: (router, nextLocation) => dispatch(confirmDialog(router, nextLocation)),
  cancel: () => dispatch(hideDialog()),
});

export { mapStateToProps, mapDispatchToProps };
