import React from 'react';
import PropTypes from 'prop-types';

import PrivateLite from 'components/common/Layouts/PrivateLite';

const PrivateDropZone = ({ children, params: { campaignId } }) => (
  <PrivateLite baseUrl={`drop-zone/view/${campaignId}`}>{children}</PrivateLite>
);

PrivateDropZone.propTypes = {
  children: PropTypes.node.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string,
  }).isRequired,
};

export default PrivateDropZone;
