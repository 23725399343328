import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map, fromJS } from 'immutable';
import { CreativeTypes } from 'modules/Helpers';
import { HttpMethods } from 'modules/Helpers/http';

import { userHasCampaignPermission } from 'store/user/helpers';

import LazyRender from 'components/common/LazyRender';
import ProgressBar from 'components/patterns/ProgressBar';
import Warnings from 'components/common/Warnings';

import CreativeRow from 'assets/components/presentational/Campaign/Creative/CreativeRow';
import RowActions from 'assets/components/presentational/Rows/RowActions';
import RowActionsDialogActions from 'assets/components/presentational/Rows/RowActionsDialogActions';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import style from './CreativeRows.scss';

export const CreativeRowActions = {
  SET_SELECTED_AS_DEFAULT: 'SET_SELECTED_AS_DEFAULT',
  UNSET_SELECTED_AS_DEFAULT: 'UNSET_SELECTED_AS_DEFAULT',
  MANAGE_RULES: 'MANAGE_RULES',
  MANAGE_CONTENT_TYPES: 'MANAGE_CONTENT_TYPES',
  DELETE_MULTIPLE: 'DELETE_MULTIPLE',
  REPROCESS: 'REPROCESS',
};

class CreativeRows extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // The creative prompting deletion
      prompting: '',
      selectedCreatives: new List(),
    };
  }

  handleCreativeDeleteClick = (creative) => {
    const { prompting } = this.state;
    this.setState({
      prompting: prompting === creative.get('id') ? '' : creative.get('id'),
    });
  };

  handleCreativeDeleteConfirmClick = (creative, frameSpecificationIndex) => {
    const { onDelete } = this.props;
    const { selectedCreatives } = this.state;

    this.setState({
      prompting: '',
    });

    onDelete(creative, frameSpecificationIndex).then(() => {
      this.setState({
        selectedCreatives: selectedCreatives.update((creatives) =>
          creatives.filter((c) => c.get('id') !== creative.get('id')),
        ),
      });
    });
  };

  handleCreativeCheckboxChange = (creative, checked) => {
    const { selectedCreatives } = this.state;
    this.setState({
      selectedCreatives: selectedCreatives.update((creatives) => {
        if (checked) {
          return creatives.includes(creative) ? creatives : creatives.push(creative);
        }
        return selectedCreatives.filter((selectedCreative) => selectedCreative.get('id') !== creative.get('id'));
      }),
    });
  };

  onReprocessCreative = async (creativeId) => {
    const { addUpload, removeUpload, reprocessCreative } = this.props;

    try {
      addUpload(creativeId, {
        creativeId,
        oldCreativeId: creativeId,
      });

      await reprocessCreative(creativeId);
    } catch (error) {
      removeUpload(creativeId);
    }
  };

  reprocessSelectedCreatives = (creativesToBeReprocessed) => {
    const promises = [];
    creativesToBeReprocessed.forEach((creative) => {
      promises.push(this.onReprocessCreative(creative.get('id')));
    });

    Promise.allSettled(promises).then(() => {
      this.setState({
        selectedCreatives: new List(),
      });
    });
  };

  handleConfirmAction = (activeAction) => {
    if (activeAction.get('value') === CreativeRowActions.DELETE_MULTIPLE) {
      this.deleteSelectedCreatives(activeAction.get('data'));
    } else if (activeAction.get('value') === CreativeRowActions.REPROCESS) {
      this.reprocessSelectedCreatives(activeAction.get('data'));
    } else {
      const { activeCampaignId, patchCreatives, frameSpecification, frameSpecificationIndex } = this.props;
      const { selectedCreatives } = this.state;

      if (activeAction.get('data') === null) {
        return;
      }
      const data = activeAction.get('data').toJS();
      patchCreatives(data, frameSpecification.get('id'), frameSpecificationIndex, activeCampaignId).then(
        (updatedCreatives) => {
          this.setState({
            selectedCreatives: selectedCreatives.update((creatives) =>
              creatives.map((creative) => {
                const updatedCreative = updatedCreatives.find(
                  (c) => c.get('id') === creative.get('id'),
                  null,
                  new Map(),
                );
                if (updatedCreative.size) {
                  return creative.merge(updatedCreative);
                }
                return creative;
              }),
            ),
          });
        },
      );
    }
  };

  deleteSelectedCreatives = (creativesToDelete) => {
    const { onDelete, frameSpecificationIndex } = this.props;

    const promises = [];
    creativesToDelete.toJS().forEach((creative) => {
      promises.push(
        new Promise((resolve) => {
          onDelete(fromJS(creative), frameSpecificationIndex).then(() => {
            resolve(creative.id);
          });
        }),
      );
    });

    Promise.all(promises).then((creativeIds) => {
      const creativesAfterDelete = creativesToDelete.filter((c) => creativeIds.indexOf(c.get('id')) === -1);
      this.setState({
        selectedCreatives: creativesAfterDelete,
      });
    });
  };

  handleCreativesCheckboxChange = (checked) => {
    const { creatives } = this.props;
    this.setState({
      selectedCreatives: checked ? creatives : new List(),
    });
  };

  createAction = (
    value,
    label,
    disabled,
    confirmMessage,
    confirmLabel,
    cancelLabel,
    data,
    dialogContent,
    confirmLabelDisabled = false,
  ) => ({
    value,
    label,
    disabled,
    confirmMessage,
    confirmLabel,
    cancelLabel,
    data,
    dialogContent,
    confirmLabelDisabled,
  });

  handleApplyRules = (rule) => {
    const { activeCampaignId, updateRelations, frameSpecification, frameSpecificationIndex } = this.props;
    const { selectedCreatives } = this.state;
    updateRelations(
      frameSpecification.get('id'),
      frameSpecificationIndex,
      activeCampaignId,
      selectedCreatives,
      'rule-sets',
      rule,
      HttpMethods.POST,
    ).then((updatedCreatives) => this.setState({ selectedCreatives: updatedCreatives }));
  };

  handleRemoveRules = (rule) => {
    const { activeCampaignId, updateRelations, frameSpecification, frameSpecificationIndex } = this.props;
    const { selectedCreatives } = this.state;
    updateRelations(
      frameSpecification.get('id'),
      frameSpecificationIndex,
      activeCampaignId,
      selectedCreatives,
      'rule-sets',
      rule,
      HttpMethods.DELETE,
    ).then((updatedCreatives) => this.setState({ selectedCreatives: updatedCreatives }));
  };

  handleApplyContentTypes = (contentType) => {
    const { activeCampaignId, updateRelations, frameSpecification, frameSpecificationIndex } = this.props;
    const { selectedCreatives } = this.state;
    updateRelations(
      frameSpecification.get('id'),
      frameSpecificationIndex,
      activeCampaignId,
      selectedCreatives,
      'content-types',
      contentType,
      HttpMethods.POST,
    ).then((updatedCreatives) => this.setState({ selectedCreatives: updatedCreatives }));
  };

  handleRemoveContentTypes = (contentType) => {
    const { activeCampaignId, updateRelations, frameSpecification, frameSpecificationIndex } = this.props;
    const { selectedCreatives } = this.state;
    updateRelations(
      frameSpecification.get('id'),
      frameSpecificationIndex,
      activeCampaignId,
      selectedCreatives,
      'content-types',
      contentType,
      HttpMethods.DELETE,
    ).then((updatedCreatives) => this.setState({ selectedCreatives: updatedCreatives }));
  };

  renderManageContentTypesDialog() {
    const { areCreativesUpdating, campaign, frameSpecification } = this.props;
    const { selectedCreatives } = this.state;
    const contentTypes = campaign.get('content-types');

    return (
      <RowActionsDialogActions
        actionList={contentTypes}
        isUpdating={areCreativesUpdating.get(frameSpecification.get('id'), false)}
        className={style.rowActionsDialog}
        onApplyActionItem={this.handleApplyContentTypes}
        onRemoveActionItem={this.handleRemoveContentTypes}
        selectedCreatives={selectedCreatives}
      />
    );
  }

  renderManageRulesDialog() {
    const { areCreativesUpdating, campaign, frameSpecification } = this.props;
    const { selectedCreatives } = this.state;
    const rulesets = campaign.get('rule-sets');

    return (
      <RowActionsDialogActions
        actionList={rulesets}
        className={style.rowActionsDialog}
        isUpdating={areCreativesUpdating.get(frameSpecification.get('id'), false)}
        onApplyActionItem={this.handleApplyRules}
        onRemoveActionItem={this.handleRemoveRules}
        selectedCreatives={selectedCreatives}
      />
    );
  }

  renderWarnings = (warnings) => {
    return (
      <div className={style.additionalInfo}>
        <Warnings warnings={warnings} icon={<Icon iconType={IconTypes.WARNING} color={IconColors.RED} />} />
      </div>
    );
  };

  renderCreativeRowActions() {
    const { shouldShowRowActions, t, creatives, allowedRowActions } = this.props;
    const { selectedCreatives } = this.state;

    if (!shouldShowRowActions) {
      return null;
    }

    const noSelectedCreativesAreDefault =
      selectedCreatives.size > 0 && selectedCreatives.every((creative) => !creative.get('is-default'));
    const allSelectedCreativesAreDefault =
      selectedCreatives.size > 0 && selectedCreatives.every((creative) => creative.get('is-default'));

    const allSelectedCreativesAreHtml5 =
      selectedCreatives.size > 0 && selectedCreatives.every((creative) => creative.get('type') === CreativeTypes.HTML5);

    let actions = new List();

    if (allowedRowActions.includes(CreativeRowActions.MANAGE_RULES)) {
      const manageRules = this.createAction(
        CreativeRowActions.MANAGE_RULES,
        t('Manage Rules'),
        false,
        null,
        t('Done'),
        null,
        null,
        this.renderManageRulesDialog(),
      );
      actions = actions.push(new Map(manageRules));
    }

    if (allowedRowActions.includes(CreativeRowActions.MANAGE_CONTENT_TYPES)) {
      const manageContentTypes = this.createAction(
        CreativeRowActions.MANAGE_CONTENT_TYPES,
        allSelectedCreativesAreHtml5 ? t('Manage Content Types') : t('Manage Content Types (HTML5 only)'),
        !allSelectedCreativesAreHtml5,
        null,
        t('Done'),
        null,
        null,
        this.renderManageContentTypesDialog(),
      );
      actions = actions.push(new Map(manageContentTypes));
    }

    if (
      allowedRowActions.includes(CreativeRowActions.SET_SELECTED_AS_DEFAULT) &&
      allowedRowActions.includes(CreativeRowActions.UNSET_SELECTED_AS_DEFAULT)
    ) {
      const setSelectedAsDefaultAction = this.createAction(
        CreativeRowActions.SET_SELECTED_AS_DEFAULT,
        t('Set selected as Default'),
        false,
        t('Are you sure you want to set {{count}} creative as Default?', { count: selectedCreatives.size }),
        t('Confirm'),
        t('Cancel'),
        selectedCreatives.map((creative) => ({ id: creative.get('id'), 'is-default': true })),
        null,
      );

      const areAllDefaultCreativesSelected =
        selectedCreatives.filter((c) => c.get('is-default')).size === creatives.filter((c) => c.get('is-default')).size;
      const unsetSelectedAsDefaultAction = this.createAction(
        CreativeRowActions.UNSET_SELECTED_AS_DEFAULT,
        t('Unset selected as Default'),
        false,
        t('Are you sure you want to unset {{count}} creative as Default?', { count: selectedCreatives.size }),
        t('Confirm'),
        t('Cancel'),
        selectedCreatives.map((creative) => ({ id: creative.get('id'), 'is-default': false })),
        areAllDefaultCreativesSelected
          ? this.renderWarnings([t('There must be at least one default creative on the frame spec')])
          : null,
        areAllDefaultCreativesSelected,
      );

      if (allSelectedCreativesAreDefault) {
        actions = actions.push(new Map(unsetSelectedAsDefaultAction));
      } else if (noSelectedCreativesAreDefault) {
        actions = actions.push(new Map(setSelectedAsDefaultAction));
      } else {
        actions = actions.push(new Map(setSelectedAsDefaultAction));
        actions = actions.push(new Map(unsetSelectedAsDefaultAction));
      }
    }

    if (allowedRowActions.includes(CreativeRowActions.DELETE_MULTIPLE)) {
      const creativesToBeDeleted = selectedCreatives.filter((c) => this.canUserDeleteCreative(c));
      let shouldCreativesBeDeleted;
      const hasFrameSpecAnyDefaultCreative = creatives.filter((c) => c.get('is-default')).size > 0;
      if (hasFrameSpecAnyDefaultCreative) {
        const areAllDefaultCreativesSelected =
          creativesToBeDeleted.filter((c) => c.get('is-default')).size ===
          creatives.filter((c) => c.get('is-default')).size;
        shouldCreativesBeDeleted = !areAllDefaultCreativesSelected;
      } else {
        const areAllCreativesSelected = creativesToBeDeleted.size === creatives.size;
        shouldCreativesBeDeleted = !areAllCreativesSelected;
      }

      const deleteOwnCreativesMessage = t(
        'You can only delete creatives you have uploaded. {{count}} / {{totalCount}} creatives will be deleted.',
        {
          count: creativesToBeDeleted.size,
          totalCount: selectedCreatives.size,
        },
      );
      const deleteSelectedCreativesMessage = t('Are you sure you want to delete {{count}} creative?', {
        count: creativesToBeDeleted.size,
      });

      const message =
        selectedCreatives.size > creativesToBeDeleted.size ? deleteOwnCreativesMessage : deleteSelectedCreativesMessage;

      const deleteMultiple = this.createAction(
        CreativeRowActions.DELETE_MULTIPLE,
        t('Delete Multiple'),
        !creativesToBeDeleted.size,
        message,
        t('Confirm'),
        t('Cancel'),
        creativesToBeDeleted,
        shouldCreativesBeDeleted
          ? null
          : this.renderWarnings([t('There must be at least one approved default creative on the frame spec')]),
        !shouldCreativesBeDeleted,
      );

      actions = actions.push(new Map(deleteMultiple));
    }

    if (allowedRowActions.includes(CreativeRowActions.REPROCESS)) {
      const confirmationMessage = t('Are you sure you want to reprocess {{count}} creative?', {
        count: selectedCreatives.size,
      });

      const reprocess = this.createAction(
        CreativeRowActions.REPROCESS,
        t('Reprocess creatives'),
        false,
        confirmationMessage,
        t('Confirm'),
        t('Cancel'),
        selectedCreatives,
        null,
        false,
      );

      actions = actions.push(new Map(reprocess));
    }

    return (
      <RowActions
        actions={actions}
        disableDropdown={!selectedCreatives.size}
        onCheckboxChange={this.handleCreativesCheckboxChange}
        onConfirmAction={this.handleConfirmAction}
        isAllCheckboxChecked={selectedCreatives.size === creatives.size}
      />
    );
  }

  renderProgress() {
    const { areCreativesUpdating, frameSpecification } = this.props;

    if (areCreativesUpdating.get(frameSpecification.get('id'), false)) {
      return (
        <div className={style.progressContainer}>
          <div className={style.rowBlocker} />
          <div className={style.progressBar}>
            <ProgressBar />
          </div>
        </div>
      );
    }

    return null;
  }

  canUserDeleteCreative = (creative) => {
    const { user, activeCampaignId } = this.props;
    return (
      userHasCampaignPermission(user, activeCampaignId, 'delete_creative') ||
      (userHasCampaignPermission(user, activeCampaignId, 'delete_own_creative') &&
        creative.get('user-id') === user.get('id'))
    );
  };

  renderCreatives() {
    const {
      activeCampaignId,
      user,
      frameSpecificationIndex,
      creatives,
      baseCreativeUrl,
      canUserReplaceCreative,
      onLoad,
      shouldShowRowActions,
    } = this.props;
    const { prompting, selectedCreatives } = this.state;

    const placeholder = <div className={style.creativeRowPlaceholder} />;

    return creatives
      .sortBy((c) => c.get('updated-at'))
      .reverse()
      .map((c) => {
        const isDeleting = prompting === c.get('id');
        const isSelected = selectedCreatives.findIndex((creative) => creative.get('id') === c.get('id')) !== -1;

        return (
          <LazyRender placeholder={placeholder} wait={500} key={c.get('id')}>
            <CreativeRow
              activeCampaignId={activeCampaignId}
              creative={c}
              frameSpecificationIndex={frameSpecificationIndex}
              creativeUrl={baseCreativeUrl + c.get('id')}
              isDeleting={isDeleting}
              isSelected={isSelected}
              onCheckboxChange={this.handleCreativeCheckboxChange}
              onDeleteClick={this.handleCreativeDeleteClick}
              onDeleteCancelClick={this.handleCreativeDeleteClick}
              onDeleteConfirmClick={this.handleCreativeDeleteConfirmClick}
              onLoad={onLoad}
              user={user}
              canUserReplaceCreative={canUserReplaceCreative}
              canUserDeleteCreative={this.canUserDeleteCreative(c)}
              shouldShowRowActions={shouldShowRowActions}
            />
          </LazyRender>
        );
      });
  }

  render() {
    return (
      <div className={style.creativeRows}>
        {this.renderProgress()}
        <div>
          {this.renderCreativeRowActions()}
          {this.renderCreatives()}
        </div>
      </div>
    );
  }
}

CreativeRows.propTypes = {
  activeCampaignId: PropTypes.string.isRequired,
  areCreativesUpdating: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  creatives: PropTypes.instanceOf(List).isRequired,
  updateRelations: PropTypes.func.isRequired,
  patchCreatives: PropTypes.func.isRequired,
  frameSpecification: PropTypes.instanceOf(Map).isRequired,
  frameSpecificationIndex: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  baseCreativeUrl: PropTypes.string.isRequired,
  canUserReplaceCreative: PropTypes.func,
  onLoad: PropTypes.func,
  onDelete: PropTypes.func,
  shouldShowRowActions: PropTypes.bool,
  allowedRowActions: PropTypes.arrayOf(PropTypes.string),
  addUpload: PropTypes.func.isRequired,
  removeUpload: PropTypes.func.isRequired,
  reprocessCreative: PropTypes.func.isRequired,
};

CreativeRows.defaultProps = {
  onLoad: () => {},
  onDelete: () => {},
  canUserReplaceCreative: () => {},
  shouldShowRowActions: false,
  allowedRowActions: [],
};

export default CreativeRows;
