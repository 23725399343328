import { handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';
import * as at from 'actions/permissions';

const getInitialState = () => ({
  isFetchingBusinessUnitsData: false,
  isFetchingCampaignsPermissionsData: false,
  isFetchingUserSettingsCampaign: false,
  isFetchingCampaigns: false,
  availableBusinessUnits: new List(),
  availableBusinessUnitsPermissionOptions: new List(),
  availableCampaignPermissions: new List(),
  userSettingsBusinessUnits: new List(),
  userSettingsBusinessUnitsUpdated: new Map(),
  userSettingsCampaignDefault: new List(),
  userSettingsCampaignDefaultUpdated: new Map(),
  userSettingsCampaign: new List(),
  userSettingsCampaignUpdated: new Map(),
  isUpdatingUserSettingsBusinessUnits: false,
  isUpdatingUserSettingsCampaign: false,
  isPropagatingCampaignPermissions: false,
  isRevokingPermissions: false,
  campaigns: new List(),
  activeInPane: fromJS({ id: 'default' }),
  filterSearchValue: '',
});

export default handleActions(
  {
    [at.FETCH_BUSINESSUNIT_DATA_REQUEST]: (state) => ({
      ...state,
      isFetchingBusinessUnitsData: true,
    }),
    [at.FETCH_BUSINESSUNIT_DATA_SUCCESS]: (state, action) => ({
      ...state,
      availableBusinessUnits: action.permissionsData.availableBusinessUnits,
      availableBusinessUnitsPermissionOptions: action.permissionsData.availableBusinessUnitsPermissionOptions,
      userSettingsBusinessUnits: action.permissionsData.userSettingsBusinessUnits,
    }),
    [at.FETCH_BUSINESSUNIT_DATA_COMPLETE]: (state) => ({
      ...state,
      isFetchingBusinessUnitsData: false,
    }),
    [at.FETCH_CAMPAIGNPERMS_DATA_REQUEST]: (state) => ({
      ...state,
      isFetchingCampaignsPermissionsData: true,
    }),
    [at.FETCH_CAMPAIGNPERMS_DATA_SUCCESS]: (state, action) => ({
      ...state,
      availableCampaignPermissions: action.permissionsData.availableCampaignPermissions,
      userSettingsCampaignDefault: action.permissionsData.userSettingsCampaignDefault,
    }),
    [at.FETCH_CAMPAIGNPERMS_DATA_COMPLETE]: (state) => ({
      ...state,
      isFetchingCampaignsPermissionsData: false,
    }),
    [at.ADD_BUSINESSUNIT_SETTING_REQUEST]: (state) => ({
      ...state,
      isUpdatingUserSettingsBusinessUnits: true,
    }),
    [at.ADD_BUSINESSUNIT_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsBusinessUnits: state.userSettingsBusinessUnits.push(action.newUserSetting),
      userSettingsBusinessUnitsUpdated: action.newUserSetting,
    }),
    [at.ADD_BUSINESSUNIT_SETTING_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUserSettingsBusinessUnits: false,
    }),
    [at.DELETE_BUSINESSUNIT_SETTING_REQUEST]: (state) => ({
      ...state,
      isUpdatingUserSettingsBusinessUnits: true,
    }),
    [at.DELETE_BUSINESSUNIT_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsBusinessUnits: state.userSettingsBusinessUnits.filter(
        (setting) => setting.get('id') !== action.newUserSetting.get('id'),
      ),
      userSettingsBusinessUnitsUpdated: action.newUserSetting,
    }),
    [at.DELETE_BUSINESSUNIT_SETTING_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUserSettingsBusinessUnits: false,
    }),
    [at.FETCH_USER_SETTINGS_CAMPAIGN_REQUEST]: (state) => ({
      ...state,
      isFetchingUserSettingsCampaign: true,
    }),
    [at.FETCH_USER_SETTINGS_CAMPAIGN_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsCampaign: action.campaignUserSettings,
      activeInPane: action.activeInPane,
    }),
    [at.FETCH_USER_SETTINGS_CAMPAIGN_COMPLETE]: (state) => ({
      ...state,
      isFetchingUserSettingsCampaign: false,
    }),
    [at.ADD_CAMPAIGN_DEFAULT_SETTING_REQUEST]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: true,
    }),
    [at.ADD_CAMPAIGN_DEFAULT_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsCampaignDefault: state.userSettingsCampaignDefault.push(action.newUserSetting),
      userSettingsCampaignDefaultUpdated: action.newUserSetting,
    }),
    [at.ADD_CAMPAIGN_DEFAULT_SETTING_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: false,
    }),
    [at.ADD_CAMPAIGN_SETTING_REQUEST]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: true,
    }),
    [at.ADD_CAMPAIGN_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsCampaign: state.userSettingsCampaign.push(action.newUserSetting),
      userSettingsCampaignUpdated: action.newUserSetting,
    }),
    [at.ADD_CAMPAIGN_SETTING_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: false,
    }),
    [at.DELETE_CAMPAIGN_DEFAULT_SETTING_REQUEST]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: true,
    }),
    [at.DELETE_CAMPAIGN_DEFAULT_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsCampaignDefault: state.userSettingsCampaignDefault.filter(
        (permission) => permission.get('id') !== action.newUserSetting.get('id'),
      ),
      userSettingsCampaignDefaultUpdated: action.newUserSetting,
    }),
    [at.DELETE_CAMPAIGN_DEFAULT_SETTING_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: false,
    }),
    [at.DELETE_CAMPAIGN_SETTING_REQUEST]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: true,
    }),
    [at.DELETE_CAMPAIGN_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      userSettingsCampaign: state.userSettingsCampaign.filter(
        (permission) => permission.get('id') !== action.newUserSetting.get('id'),
      ),
      userSettingsCampaignUpdated: action.newUserSetting,
    }),
    [at.DELETE_CAMPAIGN_SETTING_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUserSettingsCampaign: false,
    }),
    [at.RESET_ACTIVE_IN_PANE]: (state) => ({
      ...state,
      activeInPane: fromJS({ id: 'default' }),
    }),
    [at.FETCH_CAMPAIGNS_REQUEST]: (state) => ({
      ...state,
      isFetchingCampaigns: true,
    }),
    [at.FETCH_CAMPAIGNS_SUCCESS]: (state, action) => ({
      ...state,
      campaigns: action.campaigns,
    }),
    [at.FETCH_CAMPAIGNS_COMPLETE]: (state) => ({
      ...state,
      isFetchingCampaigns: false,
    }),
    [at.PROPAGATE_CAMPAIGN_PERMISSIONS_REQUEST]: (state) => ({
      ...state,
      isPropagatingCampaignPermissions: true,
    }),
    [at.PROPAGATE_CAMPAIGN_PERMISSIONS_COMPLETE]: (state) => ({
      ...state,
      isPropagatingCampaignPermissions: false,
    }),
    [at.REVOKE_CAMPAIGN_PERMISSIONS_REQUEST]: (state) => ({
      ...state,
      isRevokingPermissions: true,
    }),
    [at.REVOKE_CAMPAIGN_PERMISSIONS_COMPLETE]: (state) => ({
      ...state,
      isRevokingPermissions: false,
    }),
    [at.RESET]: () => getInitialState(),
  },
  getInitialState(),
);
