import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { redirect, getURLQueryString, validatePageNumber } from 'modules/Helpers';
import ROUTES from 'modules/Constants/routes';

import ProgressBar from 'components/patterns/ProgressBar';
import Pagination from 'assets/components/presentational/Pagination';

import { isValidDate, toBoolean, getBookingStatus, getContentStatus } from './helpers';
import InDateFilter from './Filters/InDateFilter';
import StatusFilter from './Filters/StatusFilter';
import RefineFilter from './Filters/RefineFilter';

import Grid from './Grid';

import style from './Campaigns.scss';

const Campaigns = ({
  fetchCampaigns,
  totalPages,
  params: { pageNumber },
  location: {
    query: { startAfter, startBefore, inDate, bookingStatus, contentStatus, refineSearch },
  },
}) => {
  const [isFetching, setIsFetching] = useState(true);

  const validatedCurrentPage = validatePageNumber(pageNumber);
  const validatedStartAfter = isValidDate(startAfter) ? moment(startAfter).format('YYYY-MM-DD') : null;
  const validatedStartBefore = isValidDate(startBefore) ? moment(startBefore).format('YYYY-MM-DD') : null;
  const validatedInDate = toBoolean(inDate);
  const validatedBookingStatus = bookingStatus ? getBookingStatus(bookingStatus) : null;
  const validatedContentStatus = contentStatus ? getContentStatus(contentStatus) : null;

  const getCampaignFilters = useCallback(() => {
    const filters = [];

    if (validatedStartAfter) {
      filters.push({ starts_after: validatedStartAfter });
    }

    if (validatedStartBefore) {
      filters.push({ starts_before: validatedStartBefore });
    }

    if (typeof validatedInDate === 'boolean') {
      filters.push({ live: validatedInDate });
    }

    const status = [];
    if (validatedBookingStatus) {
      status.push(...validatedBookingStatus.split(','));
    }

    if (validatedContentStatus) {
      status.push(...validatedContentStatus.split(','));
    }

    if (status.length) {
      filters.push({ dashboard_workflow_stage: status.join(',') });
    }

    if (refineSearch) {
      filters.push({ search: `%${refineSearch}%` });
    }

    return filters;
  }, [
    validatedStartAfter,
    validatedStartBefore,
    validatedInDate,
    validatedBookingStatus,
    validatedContentStatus,
    refineSearch,
  ]);

  useEffect(() => {
    const filters = getCampaignFilters();

    if (!filters.length) {
      redirect(`${ROUTES.DASHBOARD}${validatedCurrentPage}?inDate=true`, true);
      return;
    }

    fetchCampaigns(
      {
        filters,
        includes: ['frame-specifications'],
        sorts: ['+starts_at'],
      },
      validatedCurrentPage,
      setIsFetching,
    );
  }, [validatedCurrentPage, fetchCampaigns, getCampaignFilters]);

  const onPageChange = (event) => {
    const nextPage = parseInt(event.selected) + 1;
    const queryString = getURLQueryString();
    redirect(`${ROUTES.DASHBOARD}${nextPage}${queryString}`, true);
  };

  return (
    <section>
      <div data-testid="campaignFilters" className={style.filters}>
        <div className={style.filtersWrapper}>
          <div className={style.inDateFilter}>
            <InDateFilter
              validatedStartAfter={validatedStartAfter ? moment(validatedStartAfter) : null}
              validatedStartBefore={validatedStartBefore ? moment(validatedStartBefore) : null}
              validatedInDate={validatedInDate}
            />
          </div>
          <div data-testid="refineFilter" className={style.refineFilter}>
            <RefineFilter value={refineSearch} />
          </div>
        </div>
        <div data-testid="statusFilter" className={style.statusFilter}>
          <StatusFilter
            validatedBookingStatus={validatedBookingStatus}
            validatedContentStatus={validatedContentStatus}
          />
        </div>
      </div>
      {isFetching ? (
        <ProgressBar />
      ) : (
        <>
          <Grid />
          <Pagination
            initialSelected={validatedCurrentPage - 1}
            onClick={onPageChange}
            pageNum={totalPages}
            hidden={totalPages === 1 || validatedCurrentPage > totalPages}
          />
        </>
      )}
    </section>
  );
};

Campaigns.propTypes = {
  fetchCampaigns: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  params: PropTypes.shape({
    pageNumber: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      inDate: PropTypes.string,
      startAfter: PropTypes.string,
      startBefore: PropTypes.string,
      bookingStatus: PropTypes.string,
      contentStatus: PropTypes.string,
      refineSearch: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Campaigns;
