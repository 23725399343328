import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';

import { uploadsPropTypes } from 'store/uploads/propTypes';
import { userHasCampaignPermission } from 'store/user/helpers';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import BaseCreative from 'assets/components/containers/Campaign/Creative/BaseCreative';
import { CreativeRowActions } from 'assets/components/containers/Campaign/Creative/CreativeRows';

class CreativeDropZone extends Component {
  state = {
    isFetching: true,
  };

  canUserReplaceCreative = (creative) => {
    const { activeCampaignId, user } = this.props;
    return (
      userHasCampaignPermission(user, activeCampaignId, 'replace_own_creative') &&
      creative['user-id'] === user.get('id') &&
      !creative['-failed']
    );
  };

  handleCreativeDeleteConfirmClick = (creative) => {
    const { deleteCreative } = this.props;
    return deleteCreative(creative);
  };

  renderHeading = () => {
    const { t } = this.props;
    return <HeadingGroup title={t('Creative')} intro={t('Creative section introduction')} />;
  };

  fetchAdditionalCreativeData = (creativeId) => {
    const { fetchCreative } = this.props;
    const extraOptions = {
      includes: ['asset', 'user', 'prepared-asset', 'rule-sets', 'thumbnail', 'warnings', 'content-types'],
    };
    fetchCreative(creativeId, extraOptions).catch(() => Promise.resolve());
  };

  fetchFrameSpecifications = (campaignId) => {
    const { fetchFrameSpecifications } = this.props;
    fetchFrameSpecifications(campaignId).then(() => {
      this.setState({
        isFetching: false,
      });
    });
  };

  render() {
    const {
      activeCampaignId,
      activeFrameSpecId,
      addCreative,
      addUpload,
      areCreativesUpdating,
      campaign,
      fetchCampaign,
      frameSpecifications,
      notifyError,
      params: { campaignId },
      removeUpload,
      replaceCreative,
      resetUploads,
      setActiveFrameSpec,
      setActiveSection,
      updateUpload,
      uploads,
      user,
      setActiveCampaignId,
    } = this.props;
    const { isFetching } = this.state;

    const previewUrl = `drop-zone/view/${campaignId}/creative/`;
    return (
      <BaseCreative
        activeCampaignId={activeCampaignId}
        activeFrameSpecId={activeFrameSpecId}
        addCreative={addCreative}
        addUpload={addUpload}
        areCreativesUpdating={areCreativesUpdating}
        campaign={campaign}
        campaignId={campaignId}
        fetchCampaign={fetchCampaign}
        fetchFrameSpecifications={this.fetchFrameSpecifications}
        frameSpecifications={frameSpecifications}
        isFetching={isFetching}
        notifyError={notifyError}
        previewUrl={previewUrl}
        removeUpload={removeUpload}
        renderHeading={this.renderHeading}
        replaceCreative={replaceCreative}
        resetUploads={resetUploads}
        setActiveFrameSpec={setActiveFrameSpec}
        setActiveSection={setActiveSection}
        setActiveCampaignId={setActiveCampaignId}
        updateUpload={updateUpload}
        uploads={uploads}
        user={user}
        canUserReplaceCreative={this.canUserReplaceCreative}
        handleCreativeDeleteConfirmClick={this.handleCreativeDeleteConfirmClick}
        fetchAdditionalCreativeData={this.fetchAdditionalCreativeData}
        shouldShowRowActions
        allowedRowActions={[CreativeRowActions.DELETE_MULTIPLE]}
      />
    );
  }
}

CreativeDropZone.propTypes = {
  activeCampaignId: PropTypes.string.isRequired,
  activeFrameSpecId: PropTypes.string.isRequired,
  addCreative: PropTypes.func.isRequired,
  addUpload: PropTypes.func.isRequired,
  areCreativesUpdating: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  deleteCreative: PropTypes.func.isRequired,
  fetchCampaign: PropTypes.func.isRequired,
  fetchFrameSpecifications: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  notifyError: PropTypes.func.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  removeUpload: PropTypes.func.isRequired,
  replaceCreative: PropTypes.func.isRequired,
  resetUploads: PropTypes.func.isRequired,
  setActiveFrameSpec: PropTypes.func.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  setActiveCampaignId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateUpload: PropTypes.func.isRequired,
  uploads: uploadsPropTypes.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  fetchCreative: PropTypes.func.isRequired,
};

export default withNamespaces(['common'], { wait: false })(CreativeDropZone);
