import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import Dialog from 'assets/components/presentational/Dialog';
import { mapStateToProps, mapDispatchToProps } from './ConfirmationDialog.connected';

class ConfirmationDialog extends Component {
  handleHideDialog = () => {
    const { cancel } = this.props;

    cancel();
  };

  handleConfirmClick = () => {
    const { confirm, router, nextLocation } = this.props;

    confirm(() => {
      router.push(nextLocation.pathname + nextLocation.search);
    });
  };

  render() {
    const { t, showDialog, message } = this.props;

    const actions = [
      { label: t('Cancel'), onClick: this.handleHideDialog },
      { label: t('Yes'), onClick: this.handleConfirmClick },
    ];

    return (
      <Dialog
        active={showDialog}
        actions={actions}
        onEscKeyDown={this.handleHideDialog}
        onOverlayClick={this.handleHideDialog}
        type="small"
      >
        <p>{message}</p>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  nextLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withNamespaces(['common'], { wait: false })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog)),
);
