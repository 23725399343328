import { Map } from 'immutable';
import { slugify } from './string';

/**
 * Returns the slugified creative name and extension
 *
 * @param  {Map} creative The creative including the asset
 * @return {String}       The slugified filename with extension
 */
export const filenameFromCreativeWithAsset = (creative) => {
  const fileExtension = creative.asset.extension ? creative.asset.extension : '.unknown';

  return slugify(`${creative.name}.${fileExtension}`);
};

/**
 * Returns the creative's sign asset url.
 *
 * @param  {Map} creative The creative including the asset
 * @return {String}       The signed asset url
 */
export const assetDownloadUrl = (creative) => {
  const creativeObj = Map.isMap(creative) ? creative.toJS() : creative;

  if (!creativeObj.asset) {
    return '';
  }

  const filename = filenameFromCreativeWithAsset(creativeObj);

  return `${creativeObj.asset.url}?download=1&filename=${filename}`;
};
