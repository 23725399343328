import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { getRuleIcon } from 'modules/Helpers';

import { IconButton } from 'react-toolbox/lib/button';
import Tooltip from 'components/patterns/Tooltip';
import Checkbox from 'assets/components/presentational/Checkbox';
import Dialog from 'assets/components/presentational/Dialog';

import Row from 'assets/components/presentational/Rows/Row';
import Details from 'assets/components/presentational/Rows/Details';
import Actions from 'assets/components/presentational/Rows/Actions';
import Action from 'assets/components/presentational/Rows/Action';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './ruleSetRow.scss';

const TooltipIconButton = Tooltip(IconButton);

class RuleSetRow extends Component {
  handleRuleSetEditClick() {
    const { onEditClick, ruleSet } = this.props;
    onEditClick(ruleSet);
  }

  handleRuleSetSettingsClick() {
    const { onSettingsClick, ruleSet } = this.props;
    onSettingsClick(ruleSet);
  }

  handleRuleSetDeleteClick() {
    const { onDeleteClick, ruleSet } = this.props;
    onDeleteClick(ruleSet);
  }

  handleRuleSetDeleteConfirmClick() {
    const { onDeleteConfirmClick, ruleSet } = this.props;
    onDeleteConfirmClick(ruleSet);
  }

  handleRuleSetDeleteCancelClick() {
    const { onDeleteCancelClick, ruleSet } = this.props;
    onDeleteCancelClick(ruleSet);
  }

  renderIcon = (rule, key, t) => {
    const icon = getRuleIcon(rule.get('type'));
    // type contains the API namespace of the rule e.g. App\\Rule\\DayPart
    // This just strips the last part so we're left with DayPart

    return (
      <TooltipIconButton
        className={style.ruleIcon}
        icon={<Icon iconType={icon} />}
        key={key}
        tooltip={rule.get('display-name') + t(' Rule - ') + rule.get('description')}
      />
    );
  };

  renderIcons = () => {
    const { ruleSet, t } = this.props;

    const rules = ruleSet.get('rules', List()).sortBy((r) => r.get('type'));

    return <div className={style.ruleIcons}>{rules.map((r, k) => this.renderIcon(r, k, t))}</div>;
  };

  renderEditAction() {
    const { isEditable } = this.props;

    return (
      <Action
        disabled={!isEditable}
        icon={<Icon iconType={IconTypes.EDIT} />}
        onClick={() => this.handleRuleSetEditClick()}
      />
    );
  }

  renderSettingsAction() {
    const { isConfigurable } = this.props;

    if (!isConfigurable) {
      return null;
    }

    return <Action icon={<Icon iconType={IconTypes.SETTINGS} />} onClick={() => this.handleRuleSetSettingsClick()} />;
  }

  renderDeleteDialog() {
    const { isDeleting, ruleSet, t } = this.props;

    const actions = [
      { label: t('Cancel'), onClick: () => this.handleRuleSetDeleteCancelClick() },
      { label: t('Yes'), onClick: () => this.handleRuleSetDeleteConfirmClick() },
    ];

    return (
      <Dialog
        actions={actions}
        active={isDeleting}
        onEscKeyDown={() => this.handleRuleSetDeleteCancelClick()}
        onOverlayClick={() => this.handleRuleSetDeleteCancelClick()}
        type="small"
      >
        <p>{t('Are you sure you want to delete {{name}}?', { name: ruleSet.get('name') })}</p>
      </Dialog>
    );
  }

  renderDeleteAction() {
    const { isDeletable } = this.props;

    return (
      <Action
        disabled={!isDeletable}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        onClick={() => this.handleRuleSetDeleteClick()}
      />
    );
  }

  renderSelectAction() {
    const { isSelectable, isSelected } = this.props;

    if (!isSelectable) {
      return null;
    }

    return (
      <Checkbox
        className={style.checkBox}
        checked={isSelected}
        disabled={!isSelectable}
        onChange={this.handleCheckboxChange}
      />
    );
  }

  render() {
    const { ruleSet, t } = this.props;

    const subtitle = `${t('Updated')}: ${
      ruleSet.get('updated-at')
        ? moment(ruleSet.get('updated-at')).fromNow()
        : moment(ruleSet.get('created-at')).fromNow()
    }`;
    const frameCount = ruleSet.get('frames', List()).size;
    const frameCountText = t('FrameWithCount', { count: frameCount });

    return (
      <div className={style.component}>
        <Row className={style.ruleSetRow}>
          <Details className={style.detailsRow}>
            <div className={style.rulesetName}>
              <span className={style.rulesetNameRow}>
                <strong>{ruleSet.get('name')}</strong>
                &nbsp;
                <span className={style.lastUpdated}>{` -${subtitle}`}</span>
                <span className={style.frameCount}>{` -${frameCountText}`}</span>
                {this.renderIcons()}
              </span>
            </div>
          </Details>
          <Actions className={style.actions}>
            {this.renderEditAction()}
            {this.renderSettingsAction()}
            {this.renderDeleteAction()}
            {this.renderSelectAction()}
          </Actions>
        </Row>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(RuleSetRow);

RuleSetRow.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  isConfigurable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDeleting: PropTypes.bool.isRequired,
  onDeleteCancelClick: PropTypes.func.isRequired,
  onDeleteConfirmClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  ruleSet: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
};

RuleSetRow.defaultProps = {
  isDeletable: false,
  isDeleting: false,
  isSelectable: false,
  isSelected: false,
  isEditable: false,
  isConfigurable: false,
};
