import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import CreativeModerationSection from 'assets/components/presentational/Campaign/CreativeModerationSection';

class CreativeModerationSectionList extends Component {
  renderCreativeModerationSection() {
    const { creativesModerationSummary, t } = this.props;
    return creativesModerationSummary.map((creative) => (
      <CreativeModerationSection
        key={creative.get('id')}
        creativeName={creative.get('name')}
        deletedAt={creative.get('deleted-at')}
        frameSpecification={creative.get('frame-specification')}
        moderationSummary={creative.get('moderation-decisions')}
        numberOfModerationDecisionsDisplayed={5}
        t={t}
      />
    ));
  }

  render() {
    return <div>{this.renderCreativeModerationSection()}</div>;
  }
}

export default CreativeModerationSectionList;

CreativeModerationSectionList.propTypes = {
  creativesModerationSummary: PropTypes.instanceOf(List),
  t: PropTypes.func.isRequired,
};
