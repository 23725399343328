import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import FontIcon from 'react-toolbox/lib/font_icon';

import style from './icon.scss';

const cx = classnames.bind(style);

export const IconTypes = {
  ACCESS_TIME: 'access_time',
  ACCOUNT_CIRCLE: 'account_circle',
  ADD: 'add',
  ARROW_BACK: 'arrow_back',
  ARROW_DOWNWARD: 'arrow_downward',
  ARROW_DROP_DOWN: 'arrow_drop_down',
  ARROW_DROP_UP: 'arrow_drop_up',
  ARROW_UPWARD: 'arrow_upward',
  AUTORENEW: 'autorenew',
  BUG_REPORT: 'bug_report',
  CAMERA_ALT: 'camera_alt',
  CHECK_CIRCLE: 'check_circle',
  CHEVRON_LEFT: 'chevron_left',
  CHEVRON_RIGHT: 'chevron_right',
  CLEAR: 'clear',
  CLEAR_ALL: 'clear_all',
  CLOUD_DOWNLOAD: 'cloud_download',
  CODE: 'code',
  COMMENT: 'comment',
  CREATE: 'create',
  DATE_RANGE: 'date_range',
  DELETE: 'delete',
  DELETE_FOREVER: 'delete_forever',
  DESKTOP_WINDOWS: 'desktop_windows',
  DIALPAD: 'dialpad',
  DONE: 'done',
  DONE_ALL: 'done_all',
  DRAG_HANDLE: 'drag_handle',
  EDIT: 'edit',
  EMAIL: 'email',
  ERROR_OUTLINE: 'error_outline',
  EXIT_TO_APP: 'exit_to_app',
  FIBER_NEW: 'fiber_new',
  FILE_DOWNLOAD: 'file_download',
  FILE_UPLOAD: 'file_upload',
  GRADE: 'grade',
  GROUP: 'group',
  GROUP_ADD: 'group_add',
  HELP: 'help',
  HELP_OUTLINE: 'help_outline',
  HISTORY: 'history',
  HOURGLASS_EMPTY: 'hourglass_empty',
  IMAGE: 'image',
  INFO_OUTLINE: 'info_outline',
  INFO: 'info',
  LABEL: 'label',
  LABEL_OUTLINE: 'label_outline',
  LINE_STYLE: 'line_style',
  LIST_ALT: 'list_alt',
  LOCAL_AIRPORT: 'local_airport',
  LOCK: 'lock',
  LOCK_OPEN: 'lock_open',
  LOCK_OUTLINE: 'lock_outline',
  MAIL: 'mail',
  MENU: 'menu',
  MODE_EDIT: 'mode_edit',
  NOTIFICATIONS: 'notifications',
  OPEN_WITH: 'open_with',
  PERSON_ADD: 'person_add',
  PHOTO: 'photo',
  PHOTO_CAMERA: 'photo_camera',
  PICTURE_AS_PDF: 'picture_as_pdf',
  PIN_DROP: 'pin_drop',
  PLAY_ARROW: 'play_arrow',
  PLAY_CIRCLE_OUTLINE: 'play_circle_outline',
  PLUS_ONE: 'plus_one',
  QUERY_BUILDER: 'query_builder',
  REFRESH: 'refresh',
  REMOVE: 'remove',
  REMOVE_CIRCLE: 'remove_circle',
  REMOVE_CIRCLE_OUTLINE: 'remove_circle_outline',
  RESTORE: 'restore',
  SEARCH: 'search',
  SEND: 'send',
  SETTINGS: 'settings',
  SMS_FAILED: 'sms_failed',
  STAR: 'star',
  STAY_PRIMARY_PORTRAIT: 'stay_primary_portrait',
  SWAP_HORIZ: 'swap_horiz',
  SYNC: 'sync',
  THUMB_DOWN: 'thumb_down',
  THUMB_UP: 'thumb_up',
  TODAY: 'today',
  TV: 'tv',
  UNARCHIVE: 'unarchive',
  UNDO: 'undo',
  UPDATE: 'update',
  VERIFIED_USER: 'verified_user',
  VIDEOCAM: 'videocam',
  VISIBILITY: 'visibility',
  VOLUME_UP: 'volume_up',
  WARNING: 'warning',
  WB_CLOUDY: 'wb_cloudy',
  WB_SUNNY: 'wb_sunny',
  WHATSHOT: 'whatshot',
  SORT_BY_ALPHA: 'sort_by_alpha',
  EVENT_NOTE: 'event_note',
  FIBER_MANUAL_RECORD: 'fiber_manual_record',
};

export const IconColors = {
  WHITE: 'white',
  LIGHTBLUE: 'lightBlue',
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  YELLOW: 'yellow',
  LIGHTGREEN: 'lightGreen',
  MEDIUMGRAY: 'mediumGray',
  LIGHTGRAY: 'lightGray',
  GRAY: 'gray',
  BLUE: 'infoBlue',
};

export const IconSizes = {
  EXTRALARGE: 'extraLarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  REGULAR: 'regular',
  SMALL: 'small',
};

const Icon = ({ iconType, color, size, disabled, className, ...props }) => (
  <FontIcon
    {...props}
    className={cx(style.component, color, size, { [IconColors.LIGHTGRAY]: disabled }, className)}
    value={iconType}
  />
);

export default Icon;

Icon.propTypes = {
  iconType: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Icon.defaultProps = {
  color: '',
  size: IconSizes.REGULAR,
  disabled: false,
  className: '',
};
