import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { includes, reject } from 'lodash';
import { withNamespaces } from 'react-i18next';

import { getWeatherImage } from 'modules/Helpers';

import Dropdown from 'assets/components/presentational/Dropdown';
import Checkbox from 'assets/components/presentational/Checkbox';
import Input from 'components/patterns/Input';

import style from './weatherForm.scss';

const Options = {
  DAY: 0,
  CONDITION: 1,
  LOCATION_OPTIONS: 2,
  LOCATION: 3,
};

const LocationOptions = {
  FRAME: 'frame',
  LOCATION: 'location',
};

class WeatherForm extends Component {
  handleCheckboxChanged = (value, checked, field) => {
    if (checked) {
      field.value.push(value);
    } else {
      field.value = reject(field.value, (c) => c === value);
    }
    return field.onChange(field.value);
  };

  renderConditionIcon = (condition) => {
    const icon = getWeatherImage(condition.get('value'));

    return <img alt={condition.get('label')} className={style.icon} src={icon} />;
  };

  renderDaysDropDown = () => {
    const { fields, ruleType, t } = this.props;
    const days = ruleType
      .get('options')
      .get(Options.DAY)
      .toJS();

    return (
      <Dropdown
        className={style.dayInput}
        disabled={!ruleType.get('is-selected')}
        label={t('Select a day')}
        onChange={(param) => fields[Options.DAY].onChange(param.val)}
        source={days}
        allowBlank={false}
        {...fields[Options.DAY]}
      />
    );
  };

  renderLocationDropDown = () => {
    const { fields, ruleType, t } = this.props;
    const locationOptions = ruleType
      .get('options')
      .get(Options.LOCATION_OPTIONS)
      .toJS();
    return (
      <Dropdown
        className={style.locationInput}
        disabled={!ruleType.get('is-selected')}
        label={t('Select a location')}
        onChange={(param) => fields[Options.LOCATION_OPTIONS].onChange(param.val)}
        source={locationOptions}
        allowBlank={false}
        {...fields[Options.LOCATION_OPTIONS]}
      />
    );
  };

  renderLocationInput = () => {
    const { fields, ruleType, t } = this.props;
    if (fields[Options.LOCATION_OPTIONS].value !== LocationOptions.LOCATION) {
      return;
    }

    const value = fields[Options.LOCATION].value === null ? '' : fields[Options.LOCATION].value;

    return (
      <Input
        className={style.locationInput}
        disabled={!ruleType.get('is-selected') || fields[Options.LOCATION_OPTIONS].value === 0}
        label={t('Location e.g. 51.52,-0.15')}
        type="text"
        onChange={(newValue) => fields[Options.LOCATION].onChange(newValue)}
        value={value}
      />
    );
  };

  renderConditionsGrid = () => {
    const { fields, ruleType } = this.props;

    return (
      <div className={ruleType.get('is-selected') ? style.grid : style.gridDisabled}>
        {ruleType
          .get('options')
          .get(Options.CONDITION)
          .map((condition, key) => {
            const selected = includes(fields[Options.CONDITION].value, condition.get('value'));

            return (
              <div className={selected ? style.gridItemSelected : style.gridItem} key={key}>
                <Checkbox
                  label={condition.get('label')}
                  checked={selected}
                  disabled={!ruleType.get('is-selected')}
                  onChange={(checked) =>
                    this.handleCheckboxChanged(condition.get('value'), checked, fields[Options.CONDITION])
                  }
                >
                  {this.renderConditionIcon(condition)}
                </Checkbox>
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    return (
      <div className={style.component}>
        <div className={style.fieldContainer}>
          {this.renderDaysDropDown()}
          {this.renderLocationDropDown()}
          {this.renderLocationInput()}
        </div>
        {this.renderConditionsGrid()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(WeatherForm);

WeatherForm.propTypes = {
  fields: PropTypes.object.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
