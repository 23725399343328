import React from 'react';
import PropTypes from 'prop-types';
import style from './heading.scss';

export const HeadingSizes = {
  LARGEST: 'largest',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  SMALLEST: 'smallest',
};

export const HeadingTags = {
  SPAN: 'span',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
};

const getClassName = (className, size) => {
  switch (size) {
    case HeadingSizes.LARGEST:
      return style.componentLargest.concat(' ', className);
    case HeadingSizes.LARGE:
      return style.componentLarge.concat(' ', className);
    case HeadingSizes.MEDIUM:
      return style.componentMedium.concat(' ', className);
    case HeadingSizes.SMALL:
      return style.componentSmall.concat(' ', className);
    case HeadingSizes.SMALLEST:
      return style.componentSmallest.concat(' ', className);
    default:
      return style.componentMedium.concat(' ', className);
  }
};

const getTag = (children, className, onClick, size, tag) => {
  // TODO: Someone for the love of God figure out a nice way to inject this.props.tag
  // TODO: as a variable tag so we can get rid of this hideous switch statement
  switch (tag) {
    case HeadingTags.H1:
      return (
        <h1 className={getClassName(className, size)} onClick={onClick}>
          {children}
        </h1>
      );
    case HeadingTags.H2:
      return (
        <h2 className={getClassName(className, size)} onClick={onClick}>
          {children}
        </h2>
      );
    case HeadingTags.H3:
      return (
        <h3 className={getClassName(className, size)} onClick={onClick}>
          {children}
        </h3>
      );
    case HeadingTags.H4:
      return (
        <h4 className={getClassName(className, size)} onClick={onClick}>
          {children}
        </h4>
      );
    case HeadingTags.H5:
      return (
        <h5 className={getClassName(className, size)} onClick={onClick}>
          {children}
        </h5>
      );
    default:
      return (
        <span className={getClassName(className, size)} onClick={onClick}>
          {children}
        </span>
      );
  }
};

const Heading = ({ children, className, onClick, size, tag }) => getTag(children, className, onClick, size, tag);

export default Heading;

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string.isRequired,
  tag: PropTypes.string,
};

Heading.defaultProps = {
  children: null,
  className: '',
  size: HeadingSizes.SMALLEST,
  tag: HeadingTags.span,
};
