import React, { useState } from 'react';
import { IconButton } from 'react-toolbox/lib/button';

import { useWindowWidth } from 'modules/CustomHooks';
import { checkDeviceWidthSmallerThanDesktop } from 'modules/Helpers';
import Recent from 'assets/components/presentational/DashboardNew/Recent';
import PinnedCampaigns from 'assets/components/presentational/DashboardNew/PinnedCampaigns';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import Accordion from './Accordion';

import style from './Sidebar.scss';

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const windowWidth = useWindowWidth();

  const isMobile = checkDeviceWidthSmallerThanDesktop(windowWidth);

  if (isMobile) {
    return (
      <div>
        <Accordion
          title="Campaigns"
          isExpanded={isExpanded}
          expandIcon={
            <IconButton
              onClick={() => setIsExpanded(true)}
              icon={<Icon iconType={IconTypes.MENU} color={IconColors.WHITE} />}
            />
          }
          collapseIcon={
            <IconButton
              onClick={() => setIsExpanded(false)}
              icon={<Icon iconType={IconTypes.CLEAR} color={IconColors.WHITE} />}
            />
          }
        >
          <div className={style.accordionBody}>
            <div data-testid="pinnedCampaigns">
              <h2 className={style.title}>PINNED CAMPAIGNS</h2>
              <PinnedCampaigns />
            </div>
            <div className={style.recentCampaigns}>
              <h2 className={style.title}>RECENTLY VIEWED</h2>
              <Recent />
            </div>
          </div>
        </Accordion>
      </div>
    );
  }

  return (
    <div className={style.component}>
      <div className={style.sidebarFixed}>
        <div data-testid="pinnedCampaigns">
          <h2 className={style.title}>PINNED CAMPAIGNS</h2>
          <PinnedCampaigns />
        </div>
        <div className={style.recentCampaigns}>
          <h2 className={style.title}>RECENTLY VIEWED</h2>
          <Recent />
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
