import { fetchDeploymentStatus } from 'requests/system';
import notify, { NotificationTypes } from 'actions/snackbar';
import { SYSTEM } from 'app-constants';

const notifyDeploymentStatus = (deploymentStatus) => (dispatch) => {
  if (deploymentStatus === SYSTEM.DEPLOYMENT_STATUS_DEPLOYING) {
    dispatch(notify(NotificationTypes.WARNING, 'Currently deploying'));
  } else {
    dispatch(notify(NotificationTypes.WARNING, 'Deploy complete'));
  }
};

const warnDeployingInProgress = () => async (dispatch) => {
  const deploymentStatus = await dispatch(fetchDeploymentStatus());
  if (deploymentStatus === SYSTEM.DEPLOYMENT_STATUS_DEPLOYING) {
    dispatch(notifyDeploymentStatus(deploymentStatus));
  }
};

export { notifyDeploymentStatus, warnDeployingInProgress };
