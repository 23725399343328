import { handleActions } from 'redux-actions';
import * as at from 'actions/unsavedChangesDialog';

const getInitialState = () => ({
  showDialog: false,
  nextLocation: {},
});

const unsavedChangesDialog = handleActions(
  {
    [at.SHOW]: (state, action) => ({
      ...state,
      showDialog: true,
      nextLocation: action.nextLocation,
    }),
    [at.HIDE]: (state) => ({
      ...state,
      showDialog: false,
      nextLocation: {},
    }),
  },
  getInitialState(),
);

export default unsavedChangesDialog;
