import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { userHasCampaignPermission } from 'store/user/helpers';

import SubNavigation from 'assets/components/presentational/SubNavigation';

export const SubNavigationLabels = {
  ALL_RULESETS: 'All Rulesets',
  SCHEDULE: 'Schedule',
  PLAYLISTS: 'Playlists',
};

class SubNavigationRules extends Component {
  items = () => {
    const { user, campaignId } = this.props;

    const nav = [
      {
        label: SubNavigationLabels.ALL_RULESETS,
        to: `campaigns/${campaignId}/rules`,
      },
    ];

    nav.push({
      label: SubNavigationLabels.SCHEDULE,
      to: `campaigns/${campaignId}/schedule`,
    });

    if (userHasCampaignPermission(user, campaignId, 'upload_creative')) {
      nav.push({
        label: SubNavigationLabels.PLAYLISTS,
        to: `campaigns/${campaignId}/playlists`,
      });
    }

    return nav;
  };

  render() {
    const { active } = this.props;

    return <SubNavigation active={active} labels={SubNavigationLabels} items={this.items()} />;
  }
}

SubNavigationRules.propTypes = {
  active: PropTypes.string,
  campaignId: PropTypes.string,
  user: PropTypes.instanceOf(Map),
};

SubNavigationRules.defaultProps = {
  action: '',
  campaignId: '',
  user: new Map(),
};

export default SubNavigationRules;
