import { connect } from 'react-redux';

import { isPending, ACTIVITIES } from 'store/activity';
import { getSearchResults } from 'store/search/selectors';
import { searchCampaigns, clearSearchCampaigns } from 'store/search/actions';
import { SEARCH_TYPES } from 'store/search/constants';
import Header from './Header';

const mapStateToProps = (state) => {
  const { query, hits } = getSearchResults(state, SEARCH_TYPES.CAMPAIGNS);

  return {
    authUser: state.auth.user,
    campaign: state.campaign.campaign,
    isFetchingCampaign: state.campaign.isFetching,
    isFetchingSearchResults: isPending(state, ACTIVITIES.SEARCH_CAMPAIGNS),
    searchQuery: query,
    searchHits: hits,
    userIsAuthenticated: state.auth.isAuthenticated,
    editableUser: state.users.editableUser,
    framesScreenshotsLoaded: state.screenshots.isLoaded,
    mediaBuyScreenshotsLoaded: state.campaignMediaBuy.areScreenshotsLoaded,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearchQueryChange: (query) => dispatch(searchCampaigns(query)),
  clearSearchCampaigns: () => dispatch(clearSearchCampaigns()),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
