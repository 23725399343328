import React, { Component } from 'react';
import PropTypes from 'prop-types';

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const withExternalApiSearch = ({ suggestionKeyToUse, suggestionValueToStore }) => (WrappedComponent) => {
  class WithExternalApiSearch extends Component {
    constructor(props) {
      super(props);

      const { value, suggestions } = props;
      this.state = {
        value,
        suggestions,
        query: '',
      };
    }

    componentDidUpdate(prevProps) {
      this.updateValueWithSuggestions(prevProps);
    }

    updateValueWithSuggestions = (prevProps) => {
      const { value, suggestions } = this.props;

      if (prevProps.value !== value) {
        this.setState({
          value,
          suggestions,
        });
      }
    };

    onChange = (value) => {
      const { onChange } = this.props;
      const { suggestions } = this.state;

      this.setState({ value });
      onChange(value, { [value]: suggestions[value] });
    };

    search = async (query) => {
      const {
        search: { callback, callbackParams },
      } = this.props;

      const results = await callback(query, ...callbackParams);

      this.setState({
        suggestions: results.reduce(
          (suggestions, suggestion) => ({
            ...suggestions,
            [suggestion[suggestionKeyToUse]]: suggestion[suggestionValueToStore],
          }),
          {},
        ),
      });
    };

    onQueryChange = (query) => {
      this.setState({
        value: '',
        query,
      });

      if (!query) {
        this.setState({
          suggestions: {},
        });
        return;
      }

      this.search(query);
    };

    onBlur = () => {
      const { onChange } = this.props;
      const { value, suggestions } = this.state;

      this.setState({
        query: '',
      });

      onChange(value, value ? { [value]: suggestions[value] } : {});
    };

    render() {
      const { value, suggestions, query } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          onQueryChange={this.onQueryChange}
          onChange={this.onChange}
          onBlur={this.onBlur}
          value={value || query}
          source={suggestions}
        />
      );
    }
  }

  WithExternalApiSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    suggestions: PropTypes.objectOf(PropTypes.string),
    search: PropTypes.shape({
      callback: PropTypes.func,
      callbackParams: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  WithExternalApiSearch.defaultProps = {
    value: '',
    suggestions: {},
  };

  WithExternalApiSearch.displayName = `WithExternalApiSearch(${getDisplayName(WrappedComponent)})`;

  return WithExternalApiSearch;
};

export default withExternalApiSearch;
