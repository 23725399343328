import { Map } from 'immutable';
import config from 'app-config';

const FEATURES = {
  LATE_AD_COPY: config.features.LATE_AD_COPY,
  PUBLISHING: (campaign = Map()) => campaign.get('supports-publishing', false),
  PROMOTION: (campaign = Map()) => campaign.get('supports-promotion', true),
};

export default FEATURES;
