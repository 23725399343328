import { bindActionCreators } from 'redux';
import { setActiveSection } from 'actions/campaign/navigation';
import { fetchPlaylistsData, setActiveFrameSpec, setActiveRuleSet } from 'actions/campaign/playlists';
import { triggerConfirmationDialog } from 'actions/unsavedChangesDialog';
import { getFrameSpecifications, getRuleSets } from 'selectors/campaign';

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  playlists: state.campaignPlaylists.playlists,
  frameSpecifications: getFrameSpecifications(state.campaign.campaign),
  ruleSets: getRuleSets(state),
  activeCampaignId: state.campaign.activeCampaignId,
  activeFrameSpecId: state.campaignPlaylists.activeFrameSpecId,
  activeRuleSetId: state.campaignPlaylists.activeRuleSetId,
  isFetching: state.campaignPlaylists.isFetching,
  user: state.auth.user,
  hasUnsavedChanges: state.campaignPlaylists.hasUnsavedChanges,
  showDialog: state.unsavedChangesDialog.showDialog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveSection,
      fetchPlaylistsData,
      setActiveFrameSpec,
      setActiveRuleSet,
      triggerConfirmationDialog,
    },
    dispatch,
  );

export { mapStateToProps, mapDispatchToProps };
