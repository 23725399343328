import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import ReactDOM from 'react-dom';

import Dropdown from 'assets/components/presentational/Dropdown';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Input from 'components/patterns/Input';
import Dialog from 'assets/components/presentational/Dialog';
import CircleButton, { CircleButtonThemes } from 'assets/components/presentational/CircleButton';

import style from './group.scss';
import User from './User';

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateHeader: false,
      showDeleteDialog: false,
    };
  }

  componentDidUpdate() {
    const { updateHeader } = this.state;
    if (updateHeader) {
      ReactDOM.findDOMNode(this)
        .querySelector('input')
        .focus();
    }
  }

  handleUserChange = (user, email) => {
    const { onUserChange } = this.props;

    if (onUserChange) {
      onUserChange(user, email);
    }
  };

  handleModerationRuleChange = (ruleValue) => {
    const { onModerationRuleChange } = this.props;
    const ruleValueBoolean = ruleValue === 'true';

    if (onModerationRuleChange) {
      onModerationRuleChange(ruleValueBoolean);
    }
  };

  handleModerationNumberChange = (number) => {
    const { onModerationNumberChange } = this.props;
    // check lower limit- cannot be less than 0
    const numberOfModerators = number < 0 ? 0 : number;

    if (onModerationNumberChange) {
      onModerationNumberChange(numberOfModerators);
    }
  };

  handleDeleteGroup = () => {
    const { onDeleteGroup, stage, group } = this.props;

    onDeleteGroup(group, stage);
    this.hideDeleteGroupDialog();
  };

  showDeleteGroupDialog = () => {
    this.setState({
      showDeleteDialog: true,
    });
  };

  hideDeleteGroupDialog = () => {
    this.setState({
      showDeleteDialog: false,
    });
  };

  renderDialog() {
    const { t, group } = this.props;
    const { showDeleteDialog } = this.state;

    const actions = [
      { label: t('Cancel'), onClick: this.hideDeleteGroupDialog },
      { label: t('Yes'), onClick: this.handleDeleteGroup },
    ];

    return (
      <Dialog
        actions={actions}
        active={showDeleteDialog}
        onEscKeyDown={this.hideDeleteGroupDialog}
        onOverlayClick={this.hideDeleteGroupDialog}
        type="small"
      >
        <p>{t('Delete Group prompt', { group: group.get('name') })}</p>
      </Dialog>
    );
  }

  renderUsers() {
    const { group, validate, validationData } = this.props;
    const error = validationData.getIn(['errors', 'users']);

    return group
      .get('users', List())
      .push(new Map({ id: '', email: '' }))
      .map((user, key) => (
        <User key={key} user={user} error={error} validate={validate} onChange={this.handleUserChange} />
      ));
  }

  renderRulesInput() {
    const { group, validate, validationData, t } = this.props;
    const requireAll = group.get('require-all');
    const requireN = group.get('require-n', 0);
    const error = validationData.getIn(['errors', 'require-n']);

    if (!requireAll) {
      return (
        <Input
          className={style.rulesInput}
          type="number"
          label={t('Number of moderators')}
          value={requireN}
          onChange={this.handleModerationNumberChange}
          error={error}
          onBlur={() => validate()}
        />
      );
    }
  }

  renderRules() {
    const { t, group } = this.props;
    const requireAll = group.get('require-all');

    const source = [
      { label: t('All users must moderate'), value: String(true) },
      { label: t('n users must moderate'), value: String(false) },
    ];

    return (
      <div>
        <Dropdown
          auto={false}
          className={style.dropdown}
          label={t('Select a rule')}
          onChange={this.handleModerationRuleChange}
          source={source}
          value={String(requireAll)}
        />
        {this.renderRulesInput()}
      </div>
    );
  }

  render() {
    const { isLastOfStage, group, stage, onGroupNameChange, validate, validationData } = this.props;
    const { updateHeader } = this.state;

    // TODO: Replace with a proper button
    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    const regularHeader = (
      <div className={style.headingContainer} onClick={() => this.setState({ updateHeader: true })}>
        <span className={style.heading}>{group.get('name')}</span>
        <Icon iconType={IconTypes.EDIT} />
      </div>
    );
    /* eslint-enable */

    const error = validationData.getIn(['errors', 'name']);

    const changeHeader = (
      <div className={style.headingContainer}>
        <Input
          className={style.headingEdit}
          type="text"
          label=""
          placeholder=""
          value={group.get('name')}
          onChange={(name) => onGroupNameChange(stage, group, name)}
          onBlur={() => {
            validate();
            this.setState({ updateHeader: false });
          }}
          error={error}
        />
      </div>
    );

    return (
      <div className={style.component}>
        <div className={style.group}>
          {updateHeader || error ? changeHeader : regularHeader}
          {this.renderRules()}

          <span>
            <Icon iconType={IconTypes.GROUP} />
            Users
          </span>
          {this.renderUsers()}
          <span className={style.deleteButtonContainer}>
            <CircleButton
              onClick={this.showDeleteGroupDialog}
              icon={<Icon iconType={IconTypes.CLEAR} />}
              theme={CircleButtonThemes.WARNING}
            />
          </span>
        </div>
        {!isLastOfStage ? <div className={style.separator}>OR</div> : null}
        {this.renderDialog()}
      </div>
    );
  }
}

Group.propTypes = {
  group: PropTypes.instanceOf(Map),
  stage: PropTypes.instanceOf(Map),
  isLastOfStage: PropTypes.bool,
  onUserChange: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onGroupNameChange: PropTypes.func,
  onModerationRuleChange: PropTypes.func,
  onModerationNumberChange: PropTypes.func,
  validate: PropTypes.func,
  validationData: PropTypes.instanceOf(Map),
  t: PropTypes.func,
};

export default withNamespaces(['common', 'enhancedModeration'], { wait: false })(Group);
