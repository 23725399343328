import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { reduxForm, reset } from 'redux-form';
import { Map } from 'immutable';
import { fetchActiveUser, profileEdited, hideProfileDrawer, updateUser } from 'actions/users';
import notify, { NotificationTypes } from 'actions/snackbar';
import { getUserFormInitialValues } from 'store/user/helpers';
import UserFormProfile from 'assets/components/presentational/Users/UserFormProfile';

class UserFormProfileContainer extends Component {
  componentDidMount() {
    const { dispatch, userId } = this.props;
    dispatch(fetchActiveUser(userId));
  }

  handleCancel = () => {
    const { dispatch } = this.props;

    dispatch(hideProfileDrawer());
    dispatch(reset('userFormProfile'));
  };

  handleOnChange = () => {
    const { dispatch } = this.props;

    dispatch(profileEdited());
  };

  handleUpdateUser = (data) => {
    const { t, dispatch, userId } = this.props;

    return dispatch(updateUser(data, userId)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('User updated')));
    });
  };

  render() {
    const { isProfileEdited, isUpdatingUser } = this.props;

    return (
      <UserFormProfile
        {...this.props}
        showActions
        handleCancel={this.handleCancel}
        handleOnChange={this.handleOnChange}
        handleUpdateUser={this.handleUpdateUser}
        disableSubmitAction={isUpdatingUser || !isProfileEdited}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  isSuper: state.auth.user.get('is-super-user'),
  initialValues: getUserFormInitialValues(state.users.activeUser),
  isProfileEdited: state.users.isProfileEdited,
  isUpdatingUser: state.users.isUpdatingUser,
});

export default withNamespaces(['common', 'users', 'errorCodes'], { wait: true })(
  connect(mapStateToProps)(
    reduxForm({
      form: 'userFormProfile',
      fields: ['name', 'email', 'isSubscribedToCampaignDigest'],
    })(UserFormProfileContainer),
  ),
);

UserFormProfileContainer.propTypes = {
  authUser: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  isProfileEdited: PropTypes.bool,
  isUpdatingUser: PropTypes.bool,
  isSuper: PropTypes.bool,
};
