import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { List, Map } from 'immutable';

import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Day from 'assets/components/presentational/Campaign/ContentSchedule/Day';

import style from './schedule.scss';

const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

class Schedule extends Component {
  renderHeader() {
    const { activeMonth, calendarMonths, handleNextClick, handlePrevClick } = this.props;

    return (
      <div className={style.header}>
        <a className={cx(style.prev, { [style.disabled]: activeMonth <= 0 })} onClick={handlePrevClick}>
          <Icon iconType={IconTypes.CHEVRON_LEFT} />
        </a>
        <Heading size={HeadingSizes.SMALL} tag={HeadingTags.H4}>
          {calendarMonths.get(activeMonth)}
        </Heading>
        <a
          className={cx(style.next, { [style.disabled]: activeMonth >= calendarMonths.size - 1 })}
          onClick={handleNextClick}
        >
          <Icon iconType={IconTypes.CHEVRON_RIGHT} />
        </a>
      </div>
    );
  }

  renderWeekdays() {
    return (
      <div className={style.weekdays}>
        {weekdays.map((weekday) => (
          <div key={weekday}>
            <p>{weekday}</p>
          </div>
        ))}
      </div>
    );
  }

  renderCalendar() {
    const { campaignId, calendar, activePopup, handleSlotClick, handleClosePopup } = this.props;

    return (
      <div className={style.calendar}>
        {calendar.map((day, key) => (
          <Day
            key={key}
            day={day}
            campaignId={campaignId}
            activePopup={activePopup}
            handleSlotClick={handleSlotClick}
            handleClosePopup={handleClosePopup}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className={style.schedule}>
        {this.renderHeader()}
        {this.renderWeekdays()}
        {this.renderCalendar()}
      </div>
    );
  }
}

export default Schedule;

Schedule.propTypes = {
  campaignId: PropTypes.string.isRequired,
  activeMonth: PropTypes.number.isRequired,
  calendarMonths: PropTypes.instanceOf(List).isRequired,
  calendar: PropTypes.instanceOf(List).isRequired,
  activePopup: PropTypes.instanceOf(Map),
  handleNextClick: PropTypes.func.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  handleSlotClick: PropTypes.func.isRequired,
  handleClosePopup: PropTypes.func.isRequired,
};
