import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { notifyApiError } from 'actions/notify';

export const FETCH_BUSINESSUNIT_DATA_REQUEST = 'users/permissions/FETCH_BUSINESSUNIT_DATA_REQUEST';
export const FETCH_BUSINESSUNIT_DATA_SUCCESS = 'users/permissions/FETCH_BUSINESSUNIT_DATA_SUCCESS';
export const FETCH_BUSINESSUNIT_DATA_COMPLETE = 'users/permissions/FETCH_BUSINESSUNIT_DATA_COMPLETE';

export const FETCH_CAMPAIGNPERMS_DATA_REQUEST = 'users/permissions/FETCH_CAMPAIGNPERMS_DATA_REQUEST';
export const FETCH_CAMPAIGNPERMS_DATA_SUCCESS = 'users/permissions/FETCH_CAMPAIGNPERMS_DATA_SUCCESS';
export const FETCH_CAMPAIGNPERMS_DATA_COMPLETE = 'users/permissions/FETCH_CAMPAIGNPERMS_DATA_COMPLETE';

export const ADD_BUSINESSUNIT_SETTING_REQUEST = 'users/ADD_BUSINESSUNIT_SETTING_REQUEST';
export const ADD_BUSINESSUNIT_SETTING_SUCCESS = 'users/ADD_BUSINESSUNIT_SETTING_SUCCESS';
export const ADD_BUSINESSUNIT_SETTING_COMPLETE = 'users/ADD_BUSINESSUNIT_SETTING_COMPLETE';

export const DELETE_BUSINESSUNIT_SETTING_REQUEST = 'users/DELETE_BUSINESSUNIT_SETTING_REQUEST';
export const DELETE_BUSINESSUNIT_SETTING_SUCCESS = 'users/DELETE_BUSINESSUNIT_SETTING_SUCCESS';
export const DELETE_BUSINESSUNIT_SETTING_COMPLETE = 'users/DELETE_BUSINESSUNIT_SETTING_COMPLETE';

export const FETCH_USER_SETTINGS_CAMPAIGN_REQUEST = 'users/FETCH_USER_SETTINGS_CAMPAIGN_REQUEST';
export const FETCH_USER_SETTINGS_CAMPAIGN_SUCCESS = 'users/FETCH_USER_SETTINGS_CAMPAIGN_SUCCESS';
export const FETCH_USER_SETTINGS_CAMPAIGN_COMPLETE = 'users/FETCH_USER_SETTINGS_CAMPAIGN_COMPLETE';

export const ADD_CAMPAIGN_DEFAULT_SETTING_REQUEST = 'users/ADD_CAMPAIGN_DEFAULT_SETTING_REQUEST';
export const ADD_CAMPAIGN_DEFAULT_SETTING_SUCCESS = 'users/ADD_CAMPAIGN_DEFAULT_SETTING_SUCCESS';
export const ADD_CAMPAIGN_DEFAULT_SETTING_COMPLETE = 'users/ADD_CAMPAIGN_DEFAULT_SETTING_COMPLETE';

export const DELETE_CAMPAIGN_DEFAULT_SETTING_REQUEST = 'users/DELETE_CAMPAIGN_DEFAULT_SETTING_REQUEST';
export const DELETE_CAMPAIGN_DEFAULT_SETTING_SUCCESS = 'users/DELETE_CAMPAIGN_DEFAULT_SETTING_SUCCESS';
export const DELETE_CAMPAIGN_DEFAULT_SETTING_COMPLETE = 'users/DELETE_CAMPAIGN_DEFAULT_SETTING_COMPLETE';

export const ADD_CAMPAIGN_SETTING_REQUEST = 'users/ADD_CAMPAIGN_SETTING_REQUEST';
export const ADD_CAMPAIGN_SETTING_SUCCESS = 'users/ADD_CAMPAIGN_SETTING_SUCCESS';
export const ADD_CAMPAIGN_SETTING_COMPLETE = 'users/ADD_CAMPAIGN_SETTING_COMPLETE';

export const DELETE_CAMPAIGN_SETTING_REQUEST = 'users/DELETE_CAMPAIGN_SETTING_REQUEST';
export const DELETE_CAMPAIGN_SETTING_SUCCESS = 'users/DELETE_CAMPAIGN_SETTING_SUCCESS';
export const DELETE_CAMPAIGN_SETTING_COMPLETE = 'users/DELETE_CAMPAIGN_SETTING_COMPLETE';

export const FETCH_CAMPAIGNS_REQUEST = 'users/FETCH_CAMPAIGNS_REQUEST';
export const FETCH_CAMPAIGNS_SUCCESS = 'users/FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_COMPLETE = 'users/FETCH_CAMPAIGNS_COMPLETE';

export const PROPAGATE_CAMPAIGN_PERMISSIONS_REQUEST = 'users/PROPAGATE_CAMPAIGN_PERMISSIONS_REQUEST';
export const PROPAGATE_CAMPAIGN_PERMISSIONS_COMPLETE = 'users/PROPAGATE_CAMPAIGN_PERMISSIONS_COMPLETE';

export const REVOKE_CAMPAIGN_PERMISSIONS_REQUEST = 'users/REVOKE_CAMPAIGN_PERMISSIONS_REQUEST';
export const REVOKE_CAMPAIGN_PERMISSIONS_COMPLETE = 'users/REVOKE_CAMPAIGN_PERMISSIONS_COMPLETE';

export const RESET_ACTIVE_IN_PANE = 'users/RESET_ACTIVE_IN_PANE';
export const RESET = 'users/RESET';

export const fetchPermissions = (options = {}) => (dispatch) => {
  Object.assign(options, { endpoint: 'permissions' });

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchAvailableBusinessUnits = () => (dispatch) => {
  const options = {
    endpoint: 'business-units',
    method: 'GET',
    pagination: Request.ALL_PAGES,
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchAvailableBusinessUnitsPermissionOptions = () => (dispatch) => {
  const options = {
    endpoint: 'business-units/available-permissions',
    method: 'GET',
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchUserSettingsBusinessUnits = (userId) => (dispatch) => {
  const options = {
    endpoint: `users/${userId}/permissions`,
    method: 'GET',
    filters: [{ object: 'App\\Models\\BusinessUnit' }],
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchBusinessUnitsPermissionsData = (userId) => (dispatch) => {
  dispatch({ type: FETCH_BUSINESSUNIT_DATA_REQUEST });

  return Promise.all([
    dispatch(fetchAvailableBusinessUnits()),
    dispatch(fetchAvailableBusinessUnitsPermissionOptions()),
    dispatch(fetchUserSettingsBusinessUnits(userId)),
  ]).then((responses) => {
    const permissionsData = {
      availableBusinessUnits: fromJS(responses[0].data.body.parsed),
      availableBusinessUnitsPermissionOptions: fromJS(responses[1].data.body.parsed),
      userSettingsBusinessUnits: fromJS(responses[2].data.body.parsed),
    };

    dispatch({ type: FETCH_BUSINESSUNIT_DATA_SUCCESS, permissionsData });
    dispatch({ type: FETCH_BUSINESSUNIT_DATA_COMPLETE });

    return Promise.resolve();
  });
};

export const postUserSettingsBusinessUnits = (userId, objectId, targetOption) => (dispatch) => {
  const data = {
    object: 'App\\Models\\BusinessUnit',
    object_id: objectId, // eslint-disable-line camelcase
    action: targetOption.name,
  };

  dispatch({ type: ADD_BUSINESSUNIT_SETTING_REQUEST });

  return Request.send({ endpoint: `users/${userId}/permissions`, method: 'POST', data })
    .then((response) => {
      const newUserSetting = fromJS(response.data.body.parsed);
      dispatch({ type: ADD_BUSINESSUNIT_SETTING_SUCCESS, newUserSetting });
      dispatch({ type: ADD_BUSINESSUNIT_SETTING_COMPLETE });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: ADD_BUSINESSUNIT_SETTING_COMPLETE });
      return Promise.reject(new Error('failed to update permissions'));
    });
};

export const deletePermissionSettingBusinessUnits = (userId, objectId, targetOption) => (dispatch) => {
  dispatch({ type: DELETE_BUSINESSUNIT_SETTING_REQUEST });

  return Request.send({ endpoint: `users/${userId}/permissions/${targetOption.permissionId}`, method: 'DELETE' })
    .then(() => {
      const newUserSetting = fromJS({
        id: targetOption.permissionId,
        'object-id': objectId,
        action: targetOption.name,
      });
      dispatch({ type: DELETE_BUSINESSUNIT_SETTING_SUCCESS, newUserSetting });
      dispatch({ type: DELETE_BUSINESSUNIT_SETTING_COMPLETE });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: DELETE_BUSINESSUNIT_SETTING_COMPLETE });
      return Promise.reject(new Error('failed to delete permissions'));
    });
};

export const updateUserSettingsBusinessUnits = (userId, objectId, targetOption, checked) => {
  if (checked) {
    return postUserSettingsBusinessUnits(userId, objectId, targetOption);
  }
  return deletePermissionSettingBusinessUnits(userId, objectId, targetOption);
};

export const fetchAvailableCampaignsPermissions = () => (dispatch) => {
  const options = {
    endpoint: 'campaigns/available-permissions',
    method: 'GET',
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchAvailableCampaignsPermissionsGroups = () => (dispatch) => {
  const options = {
    endpoint: 'campaigns/available-permission-groups',
    method: 'GET',
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchUserSettingsCampaign = (userId, campaign) => (dispatch) => {
  const options = {
    endpoint: `users/${userId}/permissions`,
    method: 'GET',
    filters: [{ object: 'App\\Campaign' }, { object_id: campaign.get('id') }], // eslint-disable-line camelcase
  };

  dispatch({ type: FETCH_USER_SETTINGS_CAMPAIGN_REQUEST });

  return Request.send(options)
    .then((response) => {
      const campaignUserSettings = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_USER_SETTINGS_CAMPAIGN_SUCCESS, activeInPane: campaign, campaignUserSettings });
      dispatch({ type: FETCH_USER_SETTINGS_CAMPAIGN_COMPLETE });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const fetchUserSettingsCampaignDefault = (userId) => (dispatch) => {
  const options = {
    endpoint: `users/${userId}/default-permissions`,
    method: 'GET',
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchCampaignPermissionsData = (userId, fetchDefaultPermissions = true) => (dispatch) => {
  dispatch({ type: FETCH_CAMPAIGNPERMS_DATA_REQUEST });

  const promises = [dispatch(fetchAvailableCampaignsPermissionsGroups())];

  if (fetchDefaultPermissions) {
    promises.push(dispatch(fetchUserSettingsCampaignDefault(userId)));
  }

  return Promise.all(promises).then((responses) => {
    const permissionsData = {
      availableCampaignPermissions: fromJS(responses[0].data.body.parsed),
      userSettingsCampaignDefault: fetchDefaultPermissions ? fromJS(responses[1].data.body.parsed) : fromJS([]),
    };

    dispatch({ type: FETCH_CAMPAIGNPERMS_DATA_SUCCESS, permissionsData });
    dispatch({ type: FETCH_CAMPAIGNPERMS_DATA_COMPLETE });

    return Promise.resolve();
  });
};

export const postUserSettingsCampaignDefault = (userId, targetOption) => (dispatch) => {
  const data = {
    permissionable_type: 'App\\Campaign', // eslint-disable-line camelcase
    action: targetOption.name,
  };

  dispatch({ type: ADD_CAMPAIGN_DEFAULT_SETTING_REQUEST });

  return Request.send({ endpoint: `users/${userId}/default-permissions`, method: 'POST', data })
    .then((response) => {
      const newUserSetting = fromJS(response.data.body.parsed);

      dispatch({ type: ADD_CAMPAIGN_DEFAULT_SETTING_SUCCESS, newUserSetting });
      dispatch({ type: ADD_CAMPAIGN_DEFAULT_SETTING_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: ADD_CAMPAIGN_DEFAULT_SETTING_COMPLETE });

      return Promise.reject(new Error('failed to update permissions'));
    });
};

export const deletePermissionSettingCampaignDefault = (userId, targetOption) => (dispatch) => {
  const { permissionId } = targetOption;

  dispatch({ type: DELETE_CAMPAIGN_DEFAULT_SETTING_REQUEST });

  return Request.send({ endpoint: `users/${userId}/default-permissions/${permissionId}`, method: 'DELETE' })
    .then(() => {
      const newUserSetting = fromJS({
        id: targetOption.permissionId,
        action: targetOption.name,
      });

      dispatch({ type: DELETE_CAMPAIGN_DEFAULT_SETTING_SUCCESS, newUserSetting });
      dispatch({ type: DELETE_CAMPAIGN_DEFAULT_SETTING_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: DELETE_CAMPAIGN_DEFAULT_SETTING_COMPLETE });

      return Promise.reject(new Error('failed to delete permissions'));
    });
};

export const updateUserSettingsCampaignDefault = (userId, targetOption, checked) => {
  if (checked) {
    return postUserSettingsCampaignDefault(userId, targetOption);
  }
  return deletePermissionSettingCampaignDefault(userId, targetOption);
};

export const propagateCampaignPermissions = (userId) => (dispatch) => {
  dispatch({ type: PROPAGATE_CAMPAIGN_PERMISSIONS_REQUEST });

  return Request.send({ endpoint: `users/${userId}/propagate-campaign-permissions`, method: 'POST' })
    .then(() => {
      dispatch({ type: PROPAGATE_CAMPAIGN_PERMISSIONS_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: PROPAGATE_CAMPAIGN_PERMISSIONS_COMPLETE });

      return Promise.reject(new Error('failed to propagate permissions'));
    });
};

export const revokeCampaignPermissions = (userId, bu) => (dispatch) => {
  dispatch({ type: REVOKE_CAMPAIGN_PERMISSIONS_REQUEST });

  let endpoint = `users/${userId}/revoke-campaign-permissions`;

  if (bu) {
    endpoint += `/${bu}`;
  }

  return Request.send({ endpoint, method: 'POST' })
    .then(() => {
      dispatch({ type: REVOKE_CAMPAIGN_PERMISSIONS_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: REVOKE_CAMPAIGN_PERMISSIONS_COMPLETE });

      return Promise.reject(new Error('failed to revoke permissions'));
    });
};

export const postUserSettingsCampaign = (userId, objectId, targetOption) => (dispatch) => {
  const data = {
    object: 'App\\Campaign',
    object_id: objectId, // eslint-disable-line camelcase
    action: targetOption.name,
  };

  dispatch({ type: ADD_CAMPAIGN_SETTING_REQUEST });

  return Request.send({ endpoint: `users/${userId}/permissions`, method: 'POST', data })
    .then((response) => {
      const newUserSetting = fromJS(response.data.body.parsed);

      dispatch({ type: ADD_CAMPAIGN_SETTING_SUCCESS, newUserSetting });
      dispatch({ type: ADD_CAMPAIGN_SETTING_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: ADD_CAMPAIGN_SETTING_COMPLETE });

      return Promise.reject(new Error('failed to update user permissions'));
    });
};

export const deletePermissionSettingCampaign = (userId, targetOption) => (dispatch) => {
  dispatch({ type: DELETE_CAMPAIGN_SETTING_REQUEST });

  return Request.send({ endpoint: `users/${userId}/permissions/${targetOption.permissionId}`, method: 'DELETE' })
    .then(() => {
      const newUserSetting = fromJS({
        id: targetOption.permissionId,
        action: targetOption.name,
      });

      dispatch({ type: DELETE_CAMPAIGN_SETTING_SUCCESS, newUserSetting });
      dispatch({ type: DELETE_CAMPAIGN_SETTING_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: DELETE_CAMPAIGN_SETTING_COMPLETE });

      return Promise.reject(new Error('failed to delete user permissions'));
    });
};

export const updateUserSettingsCampaign = (userId, objectId, targetOption, checked) => {
  if (checked) {
    return postUserSettingsCampaign(userId, objectId, targetOption);
  }
  return deletePermissionSettingCampaign(userId, targetOption);
};

export const fetchfilteredCampaigns = (searchTerm = '') => (dispatch) => {
  const options = {
    endpoint: 'campaigns',
    filters: [{ search: '%'.concat(searchTerm, '%') }],
    method: 'GET',
    includes: ['client'],
  };

  dispatch({ type: FETCH_CAMPAIGNS_REQUEST });

  return Request.send(options)
    .then((response) => {
      const campaigns = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_CAMPAIGNS_SUCCESS, campaigns });
      dispatch({ type: FETCH_CAMPAIGNS_COMPLETE });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const resetActiveInPaneToDefault = () => ({
  type: RESET_ACTIVE_IN_PANE,
});

export const resetState = () => ({
  type: RESET,
});
