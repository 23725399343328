/**
 * Returns the slugified version of a string.
 *
 * @param  {String} string
 * @return {String}
 */
export const slugify = (string) =>
  string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/([^a-zA-Z0-9._-]+)/, '');
