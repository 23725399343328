import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map, List } from 'immutable';

import { redirect } from 'modules/Helpers';

import BaseCreativePreview from './BaseCreativePreview';

const CreativePreviewLiteModeration = ({
  campaign,
  creative,
  fetchCreativeForPreview,
  frameSpecifications,
  isFetching,
  moderate,
  params,
  setActiveSection,
  success,
  syncRelations,
  t,
}) => {
  const handleModerateClick = (status, notes) => {
    const { campaignId, creativeId } = params;

    moderate(creativeId, status, notes).then((response) => {
      success(t('Successfully moderated', { creative: response.data.body.parsed.name }));
      return redirect(`/lite/moderation/view/${campaignId}`);
    });
  };

  const baseUrl = '/lite/moderation/view';

  return (
    <BaseCreativePreview
      allowModeration
      baseUrl={baseUrl}
      campaign={campaign}
      creative={creative}
      fetchCreativeForPreview={fetchCreativeForPreview}
      frameSpecifications={frameSpecifications}
      handleModerateClick={handleModerateClick}
      isFetching={isFetching}
      isSavingCreative={false}
      params={params}
      setActiveSection={setActiveSection}
      shouldRenderSettings
      syncRelations={syncRelations}
    />
  );
};

export default withNamespaces(['common', 'creative', 'liteModeration'], { wait: false })(CreativePreviewLiteModeration);

CreativePreviewLiteModeration.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  fetchCreativeForPreview: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  moderate: PropTypes.func.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    creativeId: PropTypes.string.isRequired,
  }).isRequired,
  setActiveSection: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  syncRelations: PropTypes.func.isRequired,
};
