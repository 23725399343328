import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
import * as at from 'actions/enhancedModeration';
import { v4 } from 'uuid';

const enhancedModeration = handleActions(
  {
    [at.FETCH_STAGES_SUCCESS]: (state, action) => ({
      ...state,
      stages: action.stages,
      dirtyStages: new Map(),
      loadedStages: action.stages,
    }),
    [at.FETCH_STAGES_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [at.FETCH_STAGES_COMPLETE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [at.FETCH_CAMPAIGNS_WITH_STAGES_REQUEST]: (state) => ({
      ...state,
      isFetchingCampaigns: true,
    }),
    [at.FETCH_CAMPAIGNS_WITH_STAGES_SUCCESS]: (state, action) => ({
      ...state,
      campaigns: action.campaigns,
    }),
    [at.FETCH_CAMPAIGNS_WITH_STAGES_COMPLETE]: (state) => ({
      ...state,
      isFetchingCampaigns: false,
    }),
    [at.REPLACE_STAGES_BY_CAMPAIGN]: (state, action) => ({
      ...state,
      stages: action.stages,
      dirtyStages: action.stages.reduce((ds, value) => ds.set(value.get('id'), true), new Map()),
    }),
    [at.PATCH_STAGES_SUCCESS]: (state, action) => ({
      ...state,
      stages: action.stages,
      dirtyStages: new Map(),
      loadedStages: action.stages,
    }),
    [at.PATCH_STAGES_REQUEST]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [at.PATCH_STAGES_COMPLETE]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [at.ADD_NEW_STAGE]: (state, action) => ({
      ...state,
      stages: state.stages.update((stages) => stages.push(action.stage)),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stage.get('id'), true)),
    }),
    [at.UPDATE_USER]: (state, action) => ({
      ...state,
      stages: updateModerationGroup(state.stages, action.stageId, action.groupId, (g) =>
        g.update('users', List, (users) => {
          const existingUser = users.findIndex((u) => u.get('id') === action.userId) > -1;
          if (existingUser) {
            // if no email, remove the user
            if (!action.email) {
              return users.filter((u) => u.get('id') !== action.userId);
              // else update the user email
            }
            return users.map((u) => {
              if (action.userId === u.get('id')) {
                return u.set('email', action.email);
              }
              return u;
            });
          }

          // if no user, create one with this email address
          if (action.email) {
            return users.push(
              new Map({
                id: v4(),
                email: action.email,
              }),
            );
          }

          return users;
        }),
      ),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
    }),
    [at.UPDATE_MODERATION_RULE]: (state, action) => ({
      ...state,
      stages: updateModerationGroup(state.stages, action.stageId, action.groupId, (g) => {
        if (action.requireAll) {
          return g.merge({
            'require-all': action.requireAll,
            'require-n': null,
          });
        }

        return g.set('require-all', action.requireAll);
      }),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
    }),
    [at.UPDATE_MODERATION_NUMBER]: (state, action) => ({
      ...state,
      stages: updateModerationGroup(state.stages, action.stageId, action.groupId, (g) =>
        g.set('require-n', action.requireN),
      ),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
    }),
    [at.UPDATE_GROUP_NAME]: (state, action) => ({
      ...state,
      stages: updateModerationGroup(state.stages, action.stageId, action.groupId, (g) => g.set('name', action.name)),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
    }),
    [at.ADD_NEW_GROUP]: (state, action) => ({
      ...state,
      stages: state.stages.update((stages) =>
        stages.map((s) => {
          if (s.get('id') === action.stageId) {
            return s.updateIn(['moderation-groups'], List, (groups) => groups.push(action.group));
          }
          return s;
        }),
      ),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
    }),
    [at.DELETE_STAGE]: (state, action) => ({
      ...state,
      stages: state.stages.update((stages) => stages.delete(stages.findIndex((s) => s.get('id') === action.stageId))),
      dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
    }),
    [at.DELETE_GROUP]: (state, action) => {
      const isLastGroupToBeDeleted =
        state.stages.filter(
          (stage) =>
            stage.get('id') === action.stageId &&
            stage.get('moderation-groups').size === 1 &&
            stage.get('moderation-groups').find((group) => group.get('id') === action.groupId),
        ).size > 0;

      return {
        ...state,
        stages: isLastGroupToBeDeleted
          ? state.stages.update((stages) => stages.delete(stages.findIndex((s) => s.get('id') === action.stageId)))
          : state.stages.update((stages) =>
              stages.map((s) => {
                if (s.get('id') === action.stageId) {
                  return s.updateIn(['moderation-groups'], List, (groups) =>
                    groups.delete(groups.findIndex((g) => g.get('id') === action.groupId)),
                  );
                }
                return s;
              }),
            ),
        dirtyStages: state.dirtyStages.update((dirtyStages) => dirtyStages.set(action.stageId, true)),
      };
    },
    [at.MOVE_STAGE]: (state, action) => ({
      ...state,
      stages: state.stages.update((stages) => {
        if (action.toPos < 0) {
          return stages;
        }

        const stageToMove = stages.get(action.fromPos);
        const newStages = stages.delete(action.fromPos).insert(action.toPos, stageToMove);

        return newStages.map((stage, i) => stage.set('sort-order', i));
      }),
      shouldScrollToStage: action.toPos.toString(),
      dirtyStages: state.dirtyStages.update(() =>
        state.stages.reduce((newUnsaved, stage) => newUnsaved.set(stage.get('id'), true), new Map()),
      ),
    }),
    [at.RESET_SCROLL_TO_STAGE]: (state) => ({
      ...state,
      shouldScrollToStage: '',
    }),
    [at.UPDATE_VALIDATION_DATA]: (state, action) => ({
      ...state,
      validationData: action.validationData,
    }),
    [at.RESEND_INVITES_REQUEST]: (state, action) => ({
      ...state,
      isResendingInvites: state.isResendingInvites.update((isResendingInvites) =>
        isResendingInvites.set(action.stageId, true),
      ),
    }),
    [at.RESEND_INVITES_COMPLETE]: (state, action) => ({
      ...state,
      isResendingInvites: state.isResendingInvites.update((isResendingInvites) =>
        isResendingInvites.set(action.stageId, false),
      ),
    }),
  },
  {
    campaigns: new List(),
    stages: new List(),
    loadedStages: new List(),
    isFetchingCampaigns: false,
    isLoading: false,
    isSaving: false,
    shouldScrollToStage: '',
    dirtyStages: new Map(),
    validationData: new List(),
    // below is a map keyed by stage IDs - each is a boolean indicating the status of the resending invites button
    isResendingInvites: new Map(),
  },
);

/**
 * updateModerationGroup: finds the correct stage in the data, updates the moderation group and returns a new updated version of the stage
 * @param {*} stages List of stages from the state
 * @param {*} stageId ID of state to update
 * @param {*} groupId ID of group to update
 * @param {*} callback instructions to update the correct group, receives the found group as a param
 */
const updateModerationGroup = (stages, stageId, groupId, callback) =>
  stages.update((stages) =>
    stages.map((s) => {
      if (s.get('id') === stageId) {
        return s.update('moderation-groups', List, (groups) =>
          groups.map((g) => {
            if (g.get('id') === groupId) {
              return callback(g);
            }
            return g;
          }),
        );
      }
      return s;
    }),
  );

export default enhancedModeration;
