import { fromJS } from 'immutable';
import { get } from 'lodash';

import { SESSION } from 'app-constants';
import Request from 'modules/API/request';
import ROUTES from 'modules/Constants/routes';
import { redirect, removeJwtFromLocalStorage } from 'modules/Helpers';
import { userHasCampaignPermission, userCanCreateCampaigns, userCanEditFrames } from 'store/user/helpers';
import { error as notifyError, notifyApiError } from './notify';
import { clearState } from './app';
import { fetchUserPermissions } from './user';
import { broadcasting } from '../modules/Broadcasting/broadcasting';

const defaultNext = ROUTES.DASHBOARD;

const AUTH_MERGE = 'AUTH_MERGE';

const AUTH_CHECK_REQUEST = 'AUTH_CHECK_REQUEST';
const AUTH_CHECK_COMPLETE = 'AUTH_CHECK_COMPLETE';

const AUTH_SEND_SMS_REQUEST = 'AUTH_SEND_SMS_REQUEST';
const AUTH_SEND_SMS_COMPLETE = 'AUTH_SEND_SMS_COMPLETE';

export const USER_BUSINESS_UNITS_FETCH_REQUEST = 'USER_BUSINESS_UNITS_FETCH_REQUEST';
export const USER_BUSINESS_UNITS_FETCH_COMPLETE = 'USER_BUSINESS_UNITS_FETCH_COMPLETE';
export const USER_BUSINESS_UNITS_FETCH_SUCCESS = 'USER_BUSINESS_UNITS_FETCH_SUCCESS';

const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION';
const UPDATE_USER_LOCATION_FAILED = 'UPDATE_USER_LOCATION_FAILED';

export const oneFactor = (email = '', password = '', next = defaultNext) => (dispatch) =>
  Request.send({
    endpoint: 'auth',
    method: 'POST',
    data: { email, password },
    attemptToRefresh: false,
    tryLogoutWhenUnauthorized: false,
  })
    .then((response) => {
      if (response.headers.authorization && (response.status === 200 || response.status === 201)) {
        const jwt = response.headers.authorization;
        const factor = response.status === 200 ? 2 : 1;
        const isAuthenticated = response.status === 201;

        localStorage.setItem(SESSION.JWT_LOCAL_STORAGE_KEY, jwt);
        dispatch(mergeAuth(factor, isAuthenticated, response.data.body.parsed));
        redirect(next.length ? next : defaultNext);
      }
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });

export const twoFactor = (smsCode, next = defaultNext) => (dispatch) =>
  Request.send({
    endpoint: 'auth/2fa',
    method: 'POST',
    data: { sms_code: smsCode },
    attemptToRefresh: false,
    tryLogoutWhenUnauthorized: false,
  }) // eslint-disable-line camelcase
    .then((response) => {
      const jwt = get(response, 'headers.authorization');

      if (response.status === 201 && jwt) {
        localStorage.setItem(SESSION.JWT_LOCAL_STORAGE_KEY, jwt);
        broadcasting().setJwt();

        dispatch(mergeAuth(2, true, response.data.body.parsed));

        return Promise.resolve(redirect(next.length ? next : defaultNext));
      }
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });

/*
  Attempts to authenticate a lite user based on the given JWT. As this is usually supplied in the
  URL, we run decodeURIComponent first.
*/
export const lite = (jwt) => (dispatch) => {
  const options = {
    endpoint: 'auth/lite',
    includes: ['user'],
    headers: Request.mergeAuthorizationHeader({}, decodeURIComponent(jwt)),
  };

  return Request.send(options)
    .then((response) => {
      const { user } = response.data.body.parsed;
      dispatch(mergeAuth(1, true, user));
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const checkLiteUser = (nextState, callback) => async (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (campaignId) {
    await dispatch(lite(localStorage.getItem('jwt')));
  }
  callback();
};

export const resendSms = () => (dispatch) => {
  dispatch({ type: AUTH_SEND_SMS_REQUEST });
  return Request.send({ endpoint: 'auth/2fa/resend', method: 'POST' })
    .then(() => {
      dispatch({ type: AUTH_SEND_SMS_COMPLETE });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: AUTH_SEND_SMS_COMPLETE });
      return Promise.reject(error);
    });
};

export const check = () => (dispatch) => {
  if (localStorage.getItem(SESSION.JWT_LOCAL_STORAGE_KEY)) {
    dispatch({ type: AUTH_CHECK_REQUEST });

    return Request.send({
      endpoint: 'auth/user',
      includes: ['moderation-agents'],
    })
      .then((response) => {
        const user = fromJS(response.data.body.parsed);
        dispatch({ type: AUTH_CHECK_COMPLETE });
        dispatch(mergeAuth(1, true, user));
        return Promise.resolve(user);
      })
      .catch((error) => {
        dispatch(notifyApiError(error));
        dispatch({ type: AUTH_CHECK_COMPLETE });
        return Promise.reject(error);
      });
  }

  return Promise.reject();
};

export const logout = () => async (dispatch) => {
  removeJwtFromLocalStorage();
  broadcasting().disconnect();
  dispatch(clearState());
  try {
    await Request.send({
      endpoint: 'auth',
      method: 'DELETE',
      attemptToRefresh: false,
      tryLogoutWhenUnauthorized: false,
    });
  } catch (error) {
    // Ignored. Tried to invalidate a session while
    // the session was already invalid.
    // This will also happen if you manually delete
    // the JWT from local storage.
  }
};

export const refresh = () => {
  if (localStorage.getItem(SESSION.JWT_LOCAL_STORAGE_KEY)) {
    return Request.send({
      endpoint: 'auth',
      method: 'PATCH',
      attemptToRefresh: false,
    })
      .then((response) => {
        // We've refreshed. Set the token and resolve
        const jwt = response && response.headers && response.headers.authorization;
        localStorage.setItem(SESSION.JWT_LOCAL_STORAGE_KEY, jwt);
        broadcasting().setJwt(jwt);
        return Promise.resolve(jwt);
      })
      .catch(() =>
        // We can't refresh, so reject
        Promise.reject(),
      );
  }

  // We weren't logged in anyway, reject
  return Promise.reject();
};

export const resetLink = (email) => (dispatch) =>
  Request.send({ endpoint: 'auth/password', method: 'POST', data: { email } })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });

export const reset = (email, password, passwordConfirmation, token) => (dispatch) => {
  const data = { email, password, password_confirmation: passwordConfirmation, token }; // eslint-disable-line camelcase

  return Request.send({ endpoint: 'auth/password', method: 'PATCH', data })
    .then((response) => Promise.resolve(response))
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

/**
 * Checks if the user is a guest (not authenticated). If they are, continue as normal otherwise redirect to dashboard.
 *
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkGuest = (nextState, replace, callback) => (dispatch) =>
  dispatch(check())
    .then(() => {
      callback(
        replace({
          pathname: '',
        }),
      );

      return Promise.resolve();
    })
    .catch(() => {
      callback();

      return Promise.resolve();
    });

/**
 * Checks if the user is authenticated and if not, redirect to login.
 *
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkAuth = (nextState, replace, callback) => (dispatch) =>
  dispatch(check())
    .then(() => {
      callback();

      return Promise.resolve();
    })
    .catch(() => {
      callback(
        replace({
          pathname: '/auth/login',
          query: { next: nextState.location.query.next },
        }),
      );

      return Promise.resolve(); // Why do we resolve here?
    });

/**
 * Checks if the user is authenticated and has super user access
 * If not, redirect to campaign dashboard.
 *
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkIsSuperUser = (nextState, replace, callback) => (dispatch) => {
  dispatch(check())
    .then((user) => {
      if (user.get('is-super-user')) {
        return callback();
      }

      dispatch(notifyError('You are not permitted to access this page'));

      return callback(
        replace({
          pathname: '',
          query: { next: nextState.location.pathname },
        }),
      );
    })
    .catch(() =>
      Promise.resolve(
        callback(
          replace({
            pathname: '',
            query: { next: nextState.location.pathname },
          }),
        ),
      ),
    );
};

/**
 * Checks if the user is authenticated is a business unit admin.
 * If not, redirect to campaign dashboard.
 *
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkIsSuperUserOrBusinessUnitAdmin = (nextState, replace, callback) => (dispatch) => {
  dispatch(check())
    .then((user) => {
      if (user.get('is-super-user') || user.get('is-business-unit-admin')) {
        return callback();
      }

      dispatch(notifyError('You are not permitted to access this page'));

      return callback(
        replace({
          pathname: '',
          query: { next: nextState.location.pathname },
        }),
      );
    })
    .catch(() =>
      Promise.resolve(
        callback(
          replace({
            pathname: '',
            query: { next: nextState.location.pathname },
          }),
        ),
      ),
    );
};

/**
 * Checks if the user is authenticated and has permission to view the campaign. If not, redirect to dashboard.
 *
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkCanAccessCampaign = (nextState, replace, callback) => (dispatch) => {
  dispatch(check())
    .then((user) => {
      const {
        params: { campaignId },
      } = nextState;

      dispatch(fetchUserPermissions(user.get('id'), 'App\\Campaign', campaignId))
        .then((permissions) => {
          user = user.set('permissions', permissions);

          if (userHasCampaignPermission(user, campaignId, 'view')) {
            return Promise.resolve(callback());
          }

          dispatch(notifyError('You are not permitted to view this campaign'));

          return Promise.resolve(
            callback(
              replace({
                pathname: '',
              }),
            ),
          );
        })
        .catch(() =>
          Promise.resolve(
            callback(
              replace({
                pathname: '',
              }),
            ),
          ),
        );
    })
    .catch(() =>
      Promise.resolve(
        callback(
          replace({
            pathname: '',
            query: { next: nextState.location.pathname },
          }),
        ),
      ),
    );
};

/**
 * Determine if the authenticated user and has permission to access the
 * campaign's bookings. If not, redirect to the campaign's creative page.
 */
export const checkCanAccessBookings = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (user.get('is-business-unit-admin') || userHasCampaignPermission(user, campaignId, 'deploy')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to access bookings'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}`,
    }),
  );
};

/**
 * Checks if the user is authenticated and has permission to moderate content on the campaign.
 * If not, redirect to campaign dashboard.
 *
 * @param user
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkCanModerateCampaignContent = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (userHasCampaignPermission(user, campaignId, 'moderate')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to moderate content'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}`,
    }),
  );
};

/**
 * Checks if the user is authenticated and has permission to add builds on the campaign.
 * If not, redirect to campaign dashboard.
 *
 * @param user
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkCanAddBuilds = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (userHasCampaignPermission(user, campaignId, 'add_build')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to add builds on this campaign'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}`,
    }),
  );
};

/**
 * Checks if the user is authenticated and has permission to moderate content on the campaign.
 * If not, redirect to campaign dashboard.
 *
 * @param user
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkCanViewCampaignContent = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (userHasCampaignPermission(user, campaignId, 'view_dynamic_content')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to view content'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}`,
    }),
  );
};

/**
 * Checks if the user is authenticated and has permission to manage content types on the campaign.
 * If not, redirect to campaign dashboard.
 *
 * @param user
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkCanManageContentTypes = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (userHasCampaignPermission(user, campaignId, 'manage_dynamic_content_types')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to manage content types'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}/content`,
    }),
  );
};

/**
 * Checks if the user is authenticated and has permission to manage the media buy on the campaign.
 * If not, redirect to campaign dashboard.
 *
 * @param user
 * @param nextState
 * @param replace
 * @param callback
 *
 * @returns {function(*)}
 */
export const checkCanManageCampaignMediaBuy = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (userHasCampaignPermission(user, campaignId, 'manage_media_buy')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to manage the media buy'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}`,
    }),
  );
};

/**
 * Checks if the user is authenticated and has permission to create campaigns
 * Notifies and rejects if not
 */
export const checkCanCreateCampaigns = () => (dispatch) =>
  dispatch(check()).then((user) => {
    dispatch(fetchUserPermissions(user.get('id'), 'App\\Models\\BusinessUnit')).then((permissions) => {
      user = user.set('permissions', permissions);

      if (userCanCreateCampaigns(user)) {
        return user;
      }

      return dispatch(notifyError('You are not permitted to create campaigns'));
    });
  });

/**
 * Checks if the user is authenticated and has permission to edit frames
 * Notifies and rejects if not
 */
export const checkCanEditFrames = (replace, callback) => (dispatch) =>
  dispatch(check()).then((user) =>
    dispatch(fetchUserPermissions(user.get('id'), 'App\\Models\\BusinessUnit')).then((permissions) => {
      user = user.set('permissions', permissions);

      if (userCanEditFrames(user)) {
        return callback();
      }

      dispatch(notifyError('You are not permitted to edit frames'));

      return callback(
        replace({
          pathname: '',
        }),
      );
    }),
  );

export const mergeAuth = (factor = 1, isAuthenticated = false, user = {}) => ({
  type: AUTH_MERGE,
  factor,
  isAuthenticated,
  user,
});

export const fetchBusinessUnits = (userId) => (dispatch) => {
  const endpoint = `users/${userId}/business-units`;

  dispatch({
    type: USER_BUSINESS_UNITS_FETCH_REQUEST,
  });
  return Request.send({
    endpoint,
  })
    .then((response) =>
      dispatch({
        type: USER_BUSINESS_UNITS_FETCH_SUCCESS,
        businessUnits: fromJS(response.data.body.parsed),
      }),
    )
    .catch((error) => {
      dispatch(notifyError('Failed to fetch Business Units'));
      dispatch({
        type: USER_BUSINESS_UNITS_FETCH_COMPLETE,
      });
      return Promise.reject(error);
    });
};

export const updateUserLocation = () => (dispatch) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      dispatch({
        type: UPDATE_USER_LOCATION,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    },
    () => {
      dispatch({ type: UPDATE_USER_LOCATION_FAILED });
    },
  );
};

export const checkCanManageCampaignInvites = (user, nextState, replace, callback) => (dispatch) => {
  const {
    params: { campaignId },
  } = nextState;

  if (userHasCampaignPermission(user, campaignId, 'invite_users')) {
    return callback();
  }

  dispatch(notifyError('You are not permitted to invite users'));

  return callback(
    replace({
      pathname: `/campaigns/${campaignId}`,
    }),
  );
};
