export default (values, t) => {
  const errors = {};

  if (!values.Brand) {
    errors.Brand = t('{{name}} is required', { name: t('Brand') });
  }

  if (!values.StartDate) {
    errors.StartDate = t('{{name}} is required', { name: t('Start Date') });
  }

  if (!values.EndDate) {
    errors.EndDate = t('{{name}} is required', { name: t('End Date') });
  }

  if (!values.Name) {
    errors.Name = t('{{name}} is required', { name: t('Name') });
  }

  if (!values.Managers) {
    errors.Managers = t('{{name}} is required', { name: t('An email') });
  }

  if (!values.UpdateFrequency) {
    errors.UpdateFrequency = t('{{name}} is required', { name: t('Update Frequency') });
  }

  const updateFrequency = parseFloat(values.UpdateFrequency);
  if (updateFrequency) {
    if (!(typeof updateFrequency === 'number' && updateFrequency % 1 === 0)) {
      errors.UpdateFrequency = t('{{name}} must be a whole number of minutes', { name: t('Update Frequency') });
    }
  }

  if (!values.BusinessUnit) {
    errors.BusinessUnit = t('{{name}} is required', { name: t('Business Unit') });
  }

  // Check to see if there are any booking business units not represented in the selected bookings list
  const BusinessUnitValue = values.BusinessUnit ? [values.BusinessUnit] : [];
  const BookingsValue = values.Bookings;
  const selectedBusinessUnitIdsFromBookings =
    BookingsValue && Array.isArray(BookingsValue) ? BookingsValue.map((item) => item['business-unit-id']) : [];
  const selectedBusinessUnitIdsFromBusinessUnit =
    BusinessUnitValue && Array.isArray(BusinessUnitValue) ? BusinessUnitValue : [];
  selectedBusinessUnitIdsFromBookings.forEach((item) => {
    if (!selectedBusinessUnitIdsFromBusinessUnit.includes(item)) {
      errors.BusinessUnit = t('Not all booking business units have been selected in the Business Units field');
    }
  });

  return errors;
};
