import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Accordion from 'assets/components/presentational/Accordion';
import { List, Map } from 'immutable';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import { ModerationDecisionStatuses } from 'modules/Helpers';

import Table from 'assets/components/presentational/Table';
import ModerationStatusChip from 'assets/components/presentational/ModerationStatusChip';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './creativeModerationSection.scss';

class CreativeModerationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
    };
  }

  renderModerationDecisions() {
    const { moderationSummary, t } = this.props;
    const { isCollapsed } = this.state;
    if (moderationSummary.size < 1) {
      return <p className={style.noDecisionsCopy}>{t('No decisions made')}</p>;
    }
    return moderationSummary.map((summary, key) => {
      const status = summary.get('approval-text');
      const moderationDecisions = summary.get('moderation-decision-logs');
      const decisions = this.getModerationDecisionsForTable(moderationDecisions);
      const model = {
        [t('type')]: { type: String, title: 'Type' },
        [t('status')]: { type: String, title: 'Status' },
        [t('message')]: { type: String, title: 'Message' },
        [t('User(s)')]: { type: String, title: 'User(s)' },
      };
      const accordionId = summary.get('id');
      const accordionClassName = key === 0 ? this.getAccordionClassName(status) : '';
      return (
        <Accordion
          key={accordionId}
          className={accordionClassName}
          collapsed={this.state[`${accordionId}isCollapsed`] || isCollapsed} // eslint-disable-line react/destructuring-assignment
          label={this.getModerationDecisionLabel(summary)}
          onClick={() => this.handleClickAccordion(accordionId)}
        >
          <Table className={style.moderationDecisionTable} model={model} source={decisions} />
        </Accordion>
      );
    });
  }

  getModerationDecisionsForTable = (moderationDecisions) => {
    const decisions = moderationDecisions
      .map((moderationDecision) => {
        const userData = moderationDecision.get('user-data');
        const usersText = userData.reduce((userString, user) => {
          const notes = user.get('notes') !== null ? ` - ${user.get('notes')}` : '';
          return `${userString}${user.get('name')}(${user.get('email')})${notes}  `;
        }, '');

        return {
          type: moderationDecision.get('type'),
          status: moderationDecision.get('status'),
          message: moderationDecision.get('message'),
          'User(s)': usersText,
        };
      })
      .toJS();
    return decisions;
  };

  getModerationDecisionLabel(summary) {
    const status = summary.get('approval-text');
    const time = moment(summary.get('created-at')).format('YYYY-MM-DD h:mm:ss');
    const relativeTime = moment(summary.get('created-at')).fromNow();

    const statusChip = <ModerationStatusChip label={status} status={this.getStatusAsInteger(status)} />;
    return (
      <span>
        {statusChip}
        &nbsp;
        {status} - {time} - (decision made {relativeTime})
      </span>
    );
  }

  getAccordionClassName(moderationDecisionStatus) {
    switch (moderationDecisionStatus) {
      case ModerationDecisionStatuses.PENDING:
        return style.moderationDecisionPending;
      case ModerationDecisionStatuses.APPROVED:
        return style.moderationDecisionAccepted;
      case ModerationDecisionStatuses.REJECTED:
        return style.moderationDecisionRejected;
      default:
        return style.moderationDecision;
    }
  }

  getStatusAsInteger(moderationDecisionStatus) {
    switch (moderationDecisionStatus) {
      case ModerationDecisionStatuses.PENDING:
        return 0;
      case ModerationDecisionStatuses.APPROVED:
        return 1;
      case ModerationDecisionStatuses.REJECTED:
        return -1;
      default:
        return 0;
    }
  }

  renderDeletedAt() {
    const { t, deletedAt } = this.props;

    if (!deletedAt) {
      return null;
    }

    return (
      <span className={style.deletedAt}>
        <small>
          <Icon iconType={IconTypes.INFO_OUTLINE} /> {`${t('Deleted')} ${moment(deletedAt).fromNow()}`}
        </small>
      </span>
    );
  }

  renderFrameSpecification() {
    const { frameSpecification, t } = this.props;

    return (
      <div className={style.frameSpecs}>
        <span className={style.frameSpec}>
          <Icon iconType={IconTypes.TV} /> {frameSpecification.get('name')}
        </span>
        <span className={style.frameSpec}>
          {`${t('Dimensions')}: ${frameSpecification.get('width')} x ${frameSpecification.get('height')}`}
        </span>
        <span className={style.frameSpec}>{`${t('Aspect Ratio')}:  ${frameSpecification.get('aspect-ratio')}`}</span>
        {this.renderDeletedAt()}
      </div>
    );
  }

  handleClickAccordion(id) {
    const { isCollapsed } = this.state;
    const currentState = this.state[`${id}isCollapsed`] || isCollapsed; // eslint-disable-line react/destructuring-assignment
    this.setState({ [`${id}isCollapsed`]: !currentState });
  }

  render() {
    const { creativeName, frameSpecification, deletedAt, numberOfModerationDecisionsDisplayed, t } = this.props;
    return (
      <div className={!deletedAt ? style.moderationSummary : style.moderationSummaryDeleted}>
        {frameSpecification === undefined ? '' : this.renderFrameSpecification()}
        <Heading className={style.moderationSummaryHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H3}>
          <div className={style.moderationSummaryDescription}>{creativeName}</div>
          <div className={style.moderationSummaryInfo}>
            {t('showing latest X moderation decisions', {
              numberOfModerationDecisionsDisplayed,
            })}
          </div>
        </Heading>

        {this.renderModerationDecisions()}
      </div>
    );
  }
}

export default withNamespaces(['common'], { wait: false })(CreativeModerationSection);

CreativeModerationSection.propTypes = {
  creativeName: PropTypes.string,
  frameSpecification: PropTypes.instanceOf(Map),
  deletedAt: PropTypes.string,
  moderationSummary: PropTypes.instanceOf(List),
  numberOfModerationDecisionsDisplayed: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
