import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { notifyApiError } from 'actions/notify';

export const FETCH_CREATIVES_REQUEST = 'campaign/creative/failed/FETCH_CREATIVES_REQUEST';
export const FETCH_CREATIVES_SUCCESS = 'campaign/creative/failed/FETCH_CREATIVES_SUCCESS';
export const FETCH_CREATIVES_COMPLETE = 'campaign/creative/failed/FETCH_CREATIVES_COMPLETE';

export const fetchCreativesFailedHistory = (campaignId) => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/creatives`,
    includes: ['asset', 'user'],
    filters: [{ type: 'failed' }, { with_trashed: true }], // eslint-disable-line camelcase
    pagination: Request.ALL_PAGES,
  };

  dispatch({ type: FETCH_CREATIVES_REQUEST, campaignId });

  return Request.send(options)
    .then((response) => {
      const creatives = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_CREATIVES_SUCCESS, campaignId, creatives });
      dispatch({ type: FETCH_CREATIVES_COMPLETE, campaignId });

      return Promise.resolve(creatives);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_CREATIVES_COMPLETE }, campaignId);
    });
};
