import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { Avatar as BaseAvatar } from 'react-toolbox/lib/avatar';

import style from './avatar.scss';

class Avatar extends Component {
  render = () => {
    const { children, className, icon, image, theme, title } = this.props;

    const rest = omit(this.props, ['children', 'className', 'icon', 'theme', 'title']);

    return (
      <BaseAvatar
        className={`${style.component} ${className}`}
        icon={icon}
        image={image}
        theme={theme}
        title={title}
        {...rest}
      >
        {children}
      </BaseAvatar>
    );
  };
}

export default Avatar;

Avatar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.element,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  theme: PropTypes.shape({
    avatar: PropTypes.string,
    image: PropTypes.string,
    letter: PropTypes.string,
  }),
  title: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  icon: null,
};
