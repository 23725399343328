import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/patterns/Input';
import Chip from 'react-toolbox/lib/chip';
import emailRegex from 'email-regex';

class TagWidget extends Component {
  state = {
    inputValue: '',
  };

  render() {
    const { disabled, label, hint, value, error, onChange } = this.props;
    const { inputValue } = this.state;
    let tags = value;

    if (!Array.isArray(value)) {
      tags = [];
    }

    return (
      <div>
        <Input
          disabled={disabled}
          label={label}
          hint={hint}
          error={error}
          onKeyPress={(keyEvent) => this.handleKeyPress(keyEvent)}
          onChange={(value) => this.setState({ inputValue: value })}
          value={inputValue}
        />
        {tags.map((tag, i) => (
          <Chip
            onDeleteClick={() => {
              const newValue = value;
              newValue.splice(i, 1);
              onChange(newValue);
            }}
            disabled={disabled}
            deletable
            key={i}
          >
            {tag}
          </Chip>
        ))}
      </div>
    );
  }

  handleKeyPress(keyEvent) {
    const { value, onChange, emailsOnly } = this.props;
    const { inputValue } = this.state;
    let newValue = null;
    let newTagValue = null;

    if (keyEvent.key === 'Enter' || keyEvent.key === ',') {
      newValue = Array.isArray(value) ? value : [];
      newTagValue = inputValue.replace(/,$/, '');
      keyEvent.preventDefault();
      if (emailsOnly && !emailRegex({ exact: true }).test(newTagValue)) {
        return;
      }
      newValue.push(newTagValue);
      onChange(newValue);
      this.setState({ inputValue: '' });
    }
  }
}

export default TagWidget;

TagWidget.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  emailsOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.array,
  error: PropTypes.string,
};

TagWidget.defaultProps = {
  id: '',
  type: 'text',
  value: '',
  emailsOnly: true,
  error: null,
};
