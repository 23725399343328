import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { List, Map, fromJS } from 'immutable';

import ProgressBar from 'components/patterns/ProgressBar';

import style from './resultsList.scss';

class ResultsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: null,
    };
  }

  handleResultsListClick = (item) => {
    const { handleResultsListClick } = this.props;

    this.setState({ active: item });
    handleResultsListClick(item);
  };

  renderDefaultResult() {
    const { t, activeInPane, showDefaultPermissions } = this.props;
    const { active } = this.state;

    if (showDefaultPermissions) {
      const classes = cx(style.result, {
        [style.resultActive]: active && active.get('id') === 'default',
        [style.resultActiveInPane]: activeInPane && activeInPane.get('id') === 'default',
      });

      return (
        <li className={classes} onClick={() => this.handleResultsListClick(fromJS({ id: 'default' }))}>
          <span className={style.primaryText}>{t('Default Permissions')}</span>
          <span className={style.secondaryText}>{t('Set defaults and propagate permissions')}</span>
        </li>
      );
    }
  }

  renderCampaignResults() {
    const { isFetchingCampaigns, campaigns, activeInPane } = this.props;
    const { active } = this.state;

    if (isFetchingCampaigns) {
      return (
        <div className={style.progress}>
          <ProgressBar />
        </div>
      );
    }

    if (campaigns.size < 1) {
      return <li className={style.result}>No results</li>;
    }

    return campaigns.map((campaign) => {
      const referenceNumber =
        campaign.get('booking-engine-id') && campaign.get('booking-engine-id').match(/^SM[0-9]+$/);
      const classes = cx(style.result, {
        [style.resultActive]: active && active.get('id') === campaign.get('id'),
        [style.resultActiveInPane]: activeInPane && activeInPane.get('id') === campaign.get('id'),
      });

      return (
        <li key={campaign.get('id')} className={classes} onClick={() => this.handleResultsListClick(campaign)}>
          <span title={campaign.get('name')} className={style.primaryText}>
            {campaign.get('name')}
            {referenceNumber && <span className={style.tertiaryText}>(Ref: {campaign.get('booking-engine-id')})</span>}
          </span>
          <span title={campaign.getIn(['client', 'name'])} className={style.secondaryText}>
            {campaign.getIn(['client', 'name'])}
          </span>
          <span className={style.secondaryText}>
            {`${moment.utc(campaign.get('starts-at')).format('DD MMM YYYY')} to ${moment
              .utc(campaign.get('ends-at'))
              .format('DD MMM YYYY')}`}
          </span>
        </li>
      );
    });
  }

  render() {
    return (
      <ul className={style.results}>
        {this.renderDefaultResult()}
        {this.renderCampaignResults()}
      </ul>
    );
  }
}

export default ResultsList;

ResultsList.propTypes = {
  t: PropTypes.func.isRequired,
  isFetchingCampaigns: PropTypes.bool,
  campaigns: PropTypes.instanceOf(List),
  activeInPane: PropTypes.instanceOf(Map).isRequired,
  handleResultsListClick: PropTypes.func.isRequired,
  showDefaultPermissions: PropTypes.bool,
};

ResultsList.defaultProps = {
  showDefaultPermissions: false,
};
