import moment from 'moment';
import { OPTIONS } from './Filters/StatusFilter';

export const isValidDate = (string) => moment(new Date(string)).isValid();

export const toBoolean = (string) => {
  if (string === 'true') return true;
  if (string === 'false') return false;
  return null;
};

const getCommonElements = (array1, array2) => array1.filter((value) => array2.includes(value));

export const getBookingStatus = (bookingStatus) =>
  getCommonElements(
    bookingStatus.split(','),
    OPTIONS.bookingStatus.map((option) => option.value),
  ).join(',');

export const getContentStatus = (contentStatus) =>
  getCommonElements(
    contentStatus.split(','),
    OPTIONS.contentStatus.map((option) => option.value),
  ).join(',');
