import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces } from 'react-i18next';

import { reprocessCreative, patchCreatives, updateRelations } from 'actions/creative';
import { addUpload, removeUpload } from 'store/uploads/actions';

import CreativeRows from './CreativeRows';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUpload,
      removeUpload,
      reprocessCreative,
      patchCreatives,
      updateRelations,
    },
    dispatch,
  );

export default withNamespaces(['common', 'creative', 'upload'], { wait: false })(
  connect(null, mapDispatchToProps)(CreativeRows),
);
