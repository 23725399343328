import { handleActions } from 'redux-actions';
import * as at from 'actions/campaign/navigation';

const getInitialState = () => ({
  activeSection: '',
});

const navigation = handleActions(
  {
    [at.SET_ACTIVE_SECTION]: (state, action) => ({
      ...state,
      activeSection: action.section,
    }),
  },
  getInitialState(),
);

export default navigation;
