import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { getModelRelationsForCheckboxPicker } from 'modules/Helpers';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Drawer from 'assets/components/presentational/Drawer';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import CheckboxPicker from 'assets/components/presentational/CheckboxPicker';
import ProgressBar from 'components/patterns/ProgressBar';
import style from './ruleSetSettings.scss';

class RuleSetSettings extends Component {
  getFormattedCreatives = () => {
    const { creatives, ruleSet } = this.props;

    return creatives
      .map((c) => ({
        label: `${c.get('name')} - ${this.getFrameSpecificationName(c.get('frame-specification-id'))}`,
        checked: ruleSet.get('creatives', List()).findIndex((rc) => rc.get('id') === c.get('id')) > -1,
        value: c.get('id'),
      }))
      .toJS();
  };

  getFormattedContentItems = () => {
    const { contentItems, ruleSet } = this.props;

    return getModelRelationsForCheckboxPicker(ruleSet, contentItems, ['content-items']);
  };

  getFrameSpecificationName = (frameSpecificationId) => {
    const { frameSpecifications } = this.props;

    return frameSpecifications.find((f) => f.get('id') === frameSpecificationId, null, new Map()).get('name');
  };

  handleCheckboxChange = (checkboxes, items, onChange) => {
    const { ruleSet } = this.props;

    const checked = items.filter(
      (item) =>
        fromJS(checkboxes).findIndex(
          (checkbox) => checkbox.get('value') === item.get('id') && checkbox.get('checked'),
        ) > -1,
    );

    return onChange(ruleSet, checked);
  };

  handleCreativesChange = (checkboxes) => {
    const { creatives, onCreativesChange } = this.props;

    return this.handleCheckboxChange(checkboxes, creatives, onCreativesChange);
  };

  handleContentItemsChange = (checkboxes) => {
    const { contentItems, onContentItemsChange } = this.props;

    return this.handleCheckboxChange(checkboxes, contentItems, onContentItemsChange);
  };

  renderProgress = () => {
    const { fetching, saving } = this.props;

    if (fetching || saving) {
      return (
        <div className={style.progress}>
          <ProgressBar />
        </div>
      );
    }
  };

  renderContent = () => {
    const { fetching, onCloseClick, saving, ruleSet, t } = this.props;

    if (!fetching) {
      return (
        <div>
          <Heading className={style.heading} size={HeadingSizes.LARGE} tag={HeadingTags.H2}>
            {ruleSet.get('name', '')}
          </Heading>

          <div className={style.groupContainer}>{this.renderCreatives()}</div>

          <div className={style.groupContainer}>{this.renderContentItems()}</div>

          <div className={style.buttonContainer}>
            <Button
              className={style.button}
              disabled={saving}
              label={t('Close')}
              onClick={onCloseClick}
              theme={ButtonThemes.WHITE}
            />
          </div>
        </div>
      );
    }
  };

  renderCreatives = () =>
    this.renderCheckboxGroup(
      'Target Creatives',
      this.getFormattedCreatives(),
      'There are no creatives available for this campaign',
      'Creative',
      this.handleCreativesChange,
    );

  renderContentItems = () =>
    this.renderCheckboxGroup(
      'Target Approved Content',
      this.getFormattedContentItems(),
      'There are no approved content items available for this campaign.',
      'Content',
      this.handleContentItemsChange,
    );

  renderCheckboxGroup = (title, items, noOptionsLabel, type, onChangeCallback) => {
    const { saving, t } = this.props;

    return (
      <CheckboxPicker
        key={JSON.stringify(items)}
        disabled={saving}
        noOptionsLabel={t(noOptionsLabel)}
        title={t(title)}
        value={items}
        onChange={onChangeCallback}
        checkboxType={type}
      />
    );
  };

  render() {
    const { active, onCloseClick } = this.props;

    return (
      <Drawer active={active} className={style.component} onOverlayClick={onCloseClick}>
        {this.renderProgress()}
        {this.renderContent()}
      </Drawer>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(RuleSetSettings);

RuleSetSettings.propTypes = {
  active: PropTypes.bool,
  campaign: PropTypes.instanceOf(Map).isRequired,
  contentItems: PropTypes.instanceOf(List).isRequired,
  creatives: PropTypes.instanceOf(List).isRequired,
  fetching: PropTypes.bool,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onContentItemsChange: PropTypes.func.isRequired,
  onCreativesChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  ruleSet: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};

RuleSetSettings.defaultProps = {
  active: false,
  fetching: false,
  saving: false,
};
