import React from 'react';
import PropTypes from 'prop-types';
import TimelineItem from './TimelineItem';
import PublishIcon from './TimelineItem/PublishIcon';
import PublishStatus from './TimelineItem/PublishStatus';
import PublishButton from './TimelineItem/PublishButton';

const PublishChange = ({ variant, status, isPublishable, campaignId }) => (
  <TimelineItem
    badge={<PublishIcon variant={variant} />}
    title={<PublishStatus status={status} />}
    details={<PublishButton isPublishable={isPublishable} campaignId={campaignId} />}
  />
);

PublishChange.propTypes = {
  variant: PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  status: PropTypes.string.isRequired,
  isPublishable: PropTypes.bool.isRequired,
  campaignId: PropTypes.string.isRequired,
};

export default PublishChange;
