import React, { Component } from 'react';
import Dropdown from 'react-toolbox/lib/dropdown';
import { v4 } from 'uuid';

import theme from './dropdown.scss';

class CustomDropdown extends Component {
  state = {
    dropdownKey: v4(),
  };

  render() {
    const { dropdownKey } = this.state;
    const { onChange } = this.props;

    return (
      <Dropdown
        {...this.props}
        key={dropdownKey}
        onChange={(...args) => {
          // Call the props callback with the value
          onChange(...args);
          // TODO: remove this hack ASAP
          // Then reset the key so a re-render happens (ultimately closing the dropdown after a selection)
          // https://github.com/react-toolbox/react-toolbox/issues/1870
          // https://github.com/react-toolbox/react-toolbox/pull/1872 - this PR is merged but not yet published a new version on npm (the solution is not ideal either)
          this.setState({
            dropdownKey: v4(),
          });
        }}
        theme={theme}
      />
    );
  }
}

export default CustomDropdown;

CustomDropdown.propTypes = Dropdown.propTypes;

CustomDropdown.defaultProps = {
  auto: false,
};
