const ACTION_TYPES = {
  BEGIN: 'BEGIN',
  END: 'END',
};

const begin = (activity) => ({
  type: ACTION_TYPES.BEGIN,
  payload: {
    activity,
  },
});

const end = (activity) => ({
  type: ACTION_TYPES.END,
  payload: {
    activity,
  },
});

export { begin, end, ACTION_TYPES };
