import { handleActions } from 'redux-actions';
import { fromJS, List } from 'immutable';

const tagKeys = handleActions(
  {
    TAG_KEYS_FETCH_REQUEST: (state) => ({
      ...state,
    }),
    TAG_KEYS_FETCH_SUCCESS: (state, action) => ({
      ...state,
      list: List.isList(state.list)
        ? state.list.update((existingItems) => {
            const newItems = fromJS(action.data);

            if (!List.isList(newItems)) {
              return existingItems;
            }

            return existingItems.merge(newItems);
          })
        : fromJS(action.data),
    }),
    TAG_KEYS_FETCH_COMPLETE: (state) => ({
      ...state,
    }),
  },
  {
    list: List(),
  },
);

export default tagKeys;
