import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import ProgressBar from 'components/patterns/ProgressBar';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './card-prompt.scss';

const renderDeleteProgress = () => (
  <div className={style.promptContent}>
    <ProgressBar circular />
  </div>
);

const renderDeletePrompt = (onDeleteConfirmClick, onDeleteCancelClick, confirmationText, t) => (
  <div className={style.promptContent}>
    <span>{confirmationText || t('Are you sure you wish to delete this item?')}</span>
    <span>
      <Button
        className={style.promptButton}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        label={t('Delete')}
        onClick={onDeleteConfirmClick}
        theme={ButtonThemes.DANGER}
      />
      <Button
        className={style.promptButton}
        icon={<Icon iconType={IconTypes.CHEVRON_LEFT} />}
        label={t('Cancel')}
        onClick={onDeleteCancelClick}
        theme={ButtonThemes.WHITE}
      />
    </span>
  </div>
);

const CardPrompt = ({
  isDeleting,
  onDeleteConfirmClick,
  onDeleteCancelClick,
  showDeletePrompt,
  confirmationText,
  t,
}) => (
  <div className={style.prompt.concat(' ', showDeletePrompt || isDeleting ? style.promptVisible : '')}>
    {isDeleting
      ? renderDeleteProgress()
      : renderDeletePrompt(onDeleteConfirmClick, onDeleteCancelClick, confirmationText, t)}
  </div>
);

export default withNamespaces(['common'], { wait: false })(CardPrompt);

CardPrompt.propTypes = {
  className: PropTypes.string,
  isDeleting: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onDeleteConfirmClick: PropTypes.func,
  onDeleteCancelClick: PropTypes.func,
  showDeletePrompt: PropTypes.bool,
  confirmationText: PropTypes.string,
  t: PropTypes.func,
};

CardPrompt.defaultProps = {
  className: '',
  isDeleting: false,
  onDeleteConfirmClick: null,
  onDeleteCancelClick: null,
  showDeletePrompt: false,
  confirmationText: null,
};
