import { ADNETWORKS } from 'app-constants';
import { fetchCampaign } from 'actions/campaign';
import { warning } from 'actions/notify';
import {
  fetchBookingsRequest,
  promoteBookingsRequest,
  detachBookingRequest,
  attachBookingRequest,
} from 'requests/bookings';

export const BOOKINGS_STORE = 'BOOKINGS_STORE';
export const BOOKINGS_PROMOTE = 'BOOKINGS_PROMOTE';
export const BOOKING_PROMOTION_SUCCESS = 'BOOKING_PROMOTION_SUCCESS';
export const BOOKING_PROMOTION_FAILED = 'BOOKING_PROMOTION_FAILED';
export const BOOKING_DCM_SUCCESS = 'BOOKING_DCM_SUCCESS';
export const BOOKING_DCM_FAILED = 'BOOKING_DCM_FAILED';
export const BOOKING_ADD = 'BOOKING_ADD';
export const BOOKING_REMOVE = 'BOOKING_REMOVE';

const addBooking = (booking) => ({
  type: BOOKING_ADD,
  payload: {
    booking,
  },
});

const removeBooking = (booking) => ({
  type: BOOKING_REMOVE,
  payload: {
    booking,
  },
});

const setBookingsToPromotingAction = (bookings) => ({ type: BOOKINGS_PROMOTE, payload: { bookings } });

export const storeBookingsAction = (bookings = []) => ({
  type: BOOKINGS_STORE,
  payload: {
    bookings: bookings.map((booking) => ({
      ...booking,
      network: booking.network || ADNETWORKS.BROADSIGN,
    })),
  },
});

export const fetchBookings = (campaignId) => async (dispatch) => {
  const bookings = await dispatch(fetchBookingsRequest(campaignId));
  dispatch(storeBookingsAction(bookings));
};

const promoteBookings = (campaignId, bookings = []) => async (dispatch) => {
  dispatch(setBookingsToPromotingAction(bookings));
  try {
    await dispatch(promoteBookingsRequest(campaignId, bookings));
  } catch (error) {
    dispatch(warning(`Bookings could not all be promoted`));
  } finally {
    // Some campaign properties may change in consequence of a promotion,
    // so we re-fetch the whole campaign in order to get up to date data.
    // Furthermore, there is no feedback on which booking failed to promote
    // so we re-fetch the whole campaign to get fresh data anyway.
    dispatch(fetchCampaign(campaignId));
  }
};

export const promoteBooking = (campaignId, booking) => (dispatch) => {
  dispatch(promoteBookings(campaignId, [booking]));
};

export const bulkPromoteBookings = (campaignId, bookings = []) => async (dispatch) => {
  const bookingsToPromote = bookings
    .filter((booking) => booking['remote-status'].canSend)
    .filter((booking) => !booking['remote-status'].isFailed);
  dispatch(promoteBookings(campaignId, bookingsToPromote));
};

export const detachBooking = (campaignId, booking) => async (dispatch) => {
  dispatch(removeBooking(booking));

  try {
    await dispatch(detachBookingRequest(campaignId, booking));
    return Promise.resolve(); // TODO: remove this once the ConfirmButton component is refactored
  } catch (error) {
    dispatch(addBooking(booking));
    dispatch(warning('There was a problem detaching the selected booking from this campaign'));
    return Promise.reject(error); // TODO: remove this once the ConfirmButton component is refactored
  }
};

export const attachBooking = (campaignId, bookingFromSearch) => async (dispatch) => {
  const booking = {
    ...bookingFromSearch,
    network: bookingFromSearch.network || ADNETWORKS.BROADSIGN,
  };

  dispatch(addBooking(booking));

  try {
    await dispatch(attachBookingRequest(campaignId, booking));
    await dispatch(fetchBookings(campaignId));
    // Some campaign properties may change in consequence of a promotion.
    // Re-fetch the whole campaign in order to get up to date data
    dispatch(fetchCampaign(campaignId));
  } catch (error) {
    dispatch(removeBooking(booking));
    dispatch(warning('There was a problem attaching the selected booking to this campaign'));
  }
};

const convertPusherEventPayloadToBooking = (pusherEventPayload) => ({
  id: String(pusherEventPayload.booking_id),
  network: pusherEventPayload.ad_network,
});

export const bookingPromotionCompleted = (pusherEventPayload) => ({
  type: BOOKING_PROMOTION_SUCCESS,
  payload: {
    booking: convertPusherEventPayloadToBooking(pusherEventPayload),
  },
});

export const bookingPromotionFailed = (pusherEventPayload) => ({
  type: BOOKING_PROMOTION_FAILED,
  payload: {
    booking: convertPusherEventPayloadToBooking(pusherEventPayload),
  },
});

export const bookingDcmCompleted = (pusherEventPayload) => ({
  type: BOOKING_DCM_SUCCESS,
  payload: {
    booking: convertPusherEventPayloadToBooking(pusherEventPayload),
  },
});

export const bookingDcmFailed = (pusherEventPayload) => ({
  type: BOOKING_DCM_FAILED,
  payload: {
    booking: convertPusherEventPayloadToBooking(pusherEventPayload),
  },
});
