import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';
import { debounce } from 'lodash';
import {
  fetchCampaignPermissionsData,
  fetchfilteredCampaigns,
  fetchUserSettingsCampaign,
  resetActiveInPaneToDefault,
  updateUserSettingsCampaign,
  updateUserSettingsCampaignDefault,
} from 'actions/permissions';
import notify, { NotificationTypes } from 'actions/snackbar';
import PermissionsCampaign from 'assets/components/presentational/Users/PermissionsCampaign';

class PermissionsCampaignContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: List(),
      filterSearchValue: '',
    };

    this.fetchfilteredCampaignsDebounced = debounce(this.fetchfilteredCampaignsDebounced, 200);
  }

  componentDidMount() {
    const { authUser, dispatch, userId } = this.props;

    const fetchDefaultPermissions = Boolean(authUser.get('is-super-user', false));

    dispatch(fetchCampaignPermissionsData(userId, fetchDefaultPermissions)).then(() => {
      const { availableCampaignPermissions, userSettingsCampaignDefault } = this.props;

      this.generatePermissionsState(availableCampaignPermissions, userSettingsCampaignDefault);
      this.fetchfilteredCampaignsDebounced();
    });
  }

  componentDidUpdate(prevProps) {
    const {
      activeInPane,
      userSettingsCampaignDefault,
      userSettingsCampaign,
      availableCampaignPermissions,
      userSettingsCampaignDefaultUpdated,
      userSettingsCampaignUpdated,
    } = this.props;
    const isDefault = activeInPane.get('id') === 'default';
    const nextUserSettings = isDefault ? userSettingsCampaignDefault : userSettingsCampaign;

    if (prevProps.activeInPane.get('id') !== activeInPane.get('id')) {
      this.resetPermissionsState(availableCampaignPermissions, nextUserSettings);
    } else if (this.userSettingsHaveChanged(prevProps)) {
      let newSetting;

      if (isDefault) {
        newSetting = userSettingsCampaignDefaultUpdated;
      } else {
        newSetting = userSettingsCampaignUpdated;
      }

      this.updatePermissionState(newSetting);
    }
  }

  userSettingsHaveChanged(prevProps) {
    const { userSettingsCampaign, userSettingsCampaignDefault } = this.props;
    return (
      prevProps.userSettingsCampaign.size !== userSettingsCampaign.size ||
      prevProps.userSettingsCampaignDefault.size !== userSettingsCampaignDefault.size
    );
  }

  fetchfilteredCampaignsDebounced = (searchTerm) => {
    const { dispatch } = this.props;

    dispatch(fetchfilteredCampaigns(searchTerm));
  };

  generatePermissionsState(availablePermissions, userPermissions) {
    const userSettings = userPermissions.reduce((acc, i) => acc.set(i.get('action'), i.get('id')), Map());
    const initialState = availablePermissions.map((group) =>
      group.updateIn(['permissions'], (permissions) =>
        permissions.map((permission) => {
          const permissionId = permission.get('id');
          if (userSettings.has(permissionId)) {
            return permission
              .set('checked', true)
              .set('permissionId', userSettings.get(permissionId))
              .set('disabled', false);
          }
          return permission
            .set('checked', false)
            .set('permissionId', '')
            .set('disabled', false);
        }),
      ),
    );

    this.setState({
      permissions: initialState,
    });
  }

  resetPermissionsState(availableCampaignPermissions, userSettingsCampaignDefault) {
    this.generatePermissionsState(availableCampaignPermissions, userSettingsCampaignDefault);
  }

  updatePermissionState(newSetting) {
    this.setState(({ permissions }) => ({
      permissions: permissions.map((group) =>
        group.updateIn(['permissions'], (perms) =>
          perms.map((permission) => {
            if (permission.get('id') === newSetting.get('action')) {
              return permission.update('permissionId', () => newSetting.get('id')).update('disabled', (bool) => !bool);
            }
            return permission;
          }),
        ),
      ),
    }));
  }

  handleResultsListClick = (campaign) => {
    const { dispatch, userId, availableCampaignPermissions, userSettingsCampaignDefault, activeInPane } = this.props;

    if (campaign.get('id') === activeInPane.get('id')) return;
    if (campaign.get('id') === 'default') {
      dispatch(resetActiveInPaneToDefault());
      this.resetPermissionsState(availableCampaignPermissions, userSettingsCampaignDefault);
    } else {
      dispatch(fetchUserSettingsCampaign(userId, campaign));
    }
  };

  handleFilterSearchChange = (value) => {
    this.setState(
      {
        filterSearchValue: value,
      },
      this.fetchfilteredCampaignsDebounced(value),
    );
  };

  handleCheckboxChange = (targetOption, checked) => {
    this.setState(
      ({ permissions }) => ({
        permissions: permissions.map((group) =>
          group.updateIn(['permissions'], (permissions) =>
            permissions.map((permission) => {
              if (permission.get('id') === targetOption.id) {
                return permission.update('checked', (bool) => !bool).update('disabled', (bool) => !bool);
              }
              return permission;
            }),
          ),
        ),
      }),
      this.handleUpdateCampaignUserSettings(targetOption, checked),
    );
  };

  handleUpdateCampaignUserSettings = (targetOption, checked) => {
    const { t, dispatch, userId, activeInPane } = this.props;
    const activeInPaneId = activeInPane.get('id');

    if (activeInPaneId === 'default') {
      dispatch(updateUserSettingsCampaignDefault(userId, targetOption, checked)).then(() => {
        dispatch(notify(NotificationTypes.SUCCESS, t('User permissions updated')));
      });
    } else {
      dispatch(updateUserSettingsCampaign(userId, activeInPaneId, targetOption, checked)).then(() => {
        dispatch(notify(NotificationTypes.SUCCESS, t('User permissions updated')));
      });
    }
  };

  render() {
    const {
      authUser,
      t,
      activeInPane,
      campaigns,
      isFetchingCampaignsPermissionsData,
      isFetchingCampaigns,
      isFetchingUserSettingsCampaign,
      isUpdatingUserSettingsCampaign,
    } = this.props;
    const { permissions, filterSearchValue } = this.state;

    return (
      <PermissionsCampaign
        t={t}
        campaigns={campaigns}
        permissions={permissions}
        activeInPane={activeInPane}
        handleCheckboxChange={this.handleCheckboxChange}
        handleResultsListClick={this.handleResultsListClick}
        handleFilterSearchChange={this.handleFilterSearchChange}
        filterSearchValue={filterSearchValue}
        isFetchingCampaignsPermissionsData={isFetchingCampaignsPermissionsData}
        isFetchingCampaigns={isFetchingCampaigns}
        isFetchingUserSettingsCampaign={isFetchingUserSettingsCampaign}
        isUpdatingUserSettingsCampaign={isUpdatingUserSettingsCampaign}
        showDefaultPermissions={Boolean(authUser.get('is-super-user', false))}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  campaigns: state.permissions.campaigns,
  activeInPane: state.permissions.activeInPane,
  userSettingsCampaign: state.permissions.userSettingsCampaign,
  userSettingsCampaignUpdated: state.permissions.userSettingsCampaignUpdated,
  userSettingsCampaignDefault: state.permissions.userSettingsCampaignDefault,
  userSettingsCampaignDefaultUpdated: state.permissions.userSettingsCampaignDefaultUpdated,
  availableCampaignPermissions: state.permissions.availableCampaignPermissions,
  isFetchingCampaignsPermissionsData: state.permissions.isFetchingCampaignsPermissionsData,
  isFetchingCampaigns: state.permissions.isFetchingCampaigns,
  isFetchingUserSettingsCampaign: state.permissions.isFetchingUserSettingsCampaign,
  isUpdatingUserSettingsCampaign: state.permissions.isUpdatingUserSettingsCampaign,
});

export default withNamespaces(['common', 'users', 'dashboard'], { wait: true })(
  connect(mapStateToProps)(PermissionsCampaignContainer),
);

PermissionsCampaignContainer.propTypes = {
  authUser: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  campaigns: PropTypes.instanceOf(List).isRequired,
  activeInPane: PropTypes.instanceOf(Map).isRequired,
  userSettingsCampaign: PropTypes.instanceOf(List).isRequired,
  userSettingsCampaignUpdated: PropTypes.instanceOf(Map).isRequired,
  userSettingsCampaignDefault: PropTypes.instanceOf(List).isRequired,
  userSettingsCampaignDefaultUpdated: PropTypes.instanceOf(Map).isRequired,
  availableCampaignPermissions: PropTypes.instanceOf(List).isRequired,
  isFetchingCampaignsPermissionsData: PropTypes.bool.isRequired,
  isFetchingCampaigns: PropTypes.bool.isRequired,
  isFetchingUserSettingsCampaign: PropTypes.bool.isRequired,
  isUpdatingUserSettingsCampaign: PropTypes.bool.isRequired,
};
