import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';
import { debounce } from 'lodash';

import { redirect } from 'modules/Helpers';

import { fetchUserEditData, clearEditableUser } from 'actions/users';
import { resetState } from 'actions/permissions';
import { fetchCampaigns } from 'actions/dashboard';

import { isNormalUser } from 'store/user/helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import { ColumnContainer, ColumnRow, Column } from 'assets/components/presentational/Layout';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Accordion from 'assets/components/presentational/Accordion';
import UserFormUserEdit from 'assets/components/containers/Users/UserFormUserEdit';
import PermissionsBusinessUnits from 'assets/components/containers/Users/PermissionsBusinessUnits';
import PermissionsCampaign from 'assets/components/containers/Users/PermissionsCampaign';

import style from './userEdit.scss';

class UserEdit extends Component {
  constructor(props) {
    super(props);

    this.BUModMaxHeightOffset = 200;

    this.state = {
      buCollapsed: false,
      campaignCollapsed: false,
      BUModMaxHeight: window.innerHeight - this.BUModMaxHeightOffset,
      isConvertingLiteToFull: null,
    };

    this.handleResizeDebounced = debounce(this.handleResize, 200);
  }

  componentDidMount() {
    const {
      dispatch,
      params: { userId, mode },
    } = this.props;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      isConvertingLiteToFull: mode === 'lite-to-full',
    });

    dispatch(fetchUserEditData(userId));
    dispatch(fetchCampaigns());

    window.addEventListener('resize', this.handleResizeDebounced);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearEditableUser());
    dispatch(resetState());
    window.removeEventListener('resize', this.handleResizeDebounced);
  }

  handleConvertLiteToFullSuccess = () => {
    const {
      params: { userId },
    } = this.props;

    redirect(`/users/${userId}/edit`);
    this.setState({
      isConvertingLiteToFull: false,
    });
  };

  handleResize = () => {
    this.setState({
      BUModMaxHeight: window.innerHeight - this.BUModMaxHeightOffset,
    });
  };

  handleAccordionClick = (accordion) => {
    this.setState((prevState) => ({ [`${accordion}Collapsed`]: !prevState[`${accordion}Collapsed`] }));
  };

  isSelf() {
    const {
      authUser,
      params: { userId },
    } = this.props;

    return authUser.get('id') === userId;
  }

  renderProfile() {
    const {
      t,
      params: { userId },
    } = this.props;
    const { isConvertingLiteToFull } = this.state;

    return (
      <div>
        <div className={style.userProfileHeading}>{this.renderProfileHeading(t('Profile'))}</div>
        <div className={style.userProfile}>
          <UserFormUserEdit
            userId={userId}
            isConvertingLiteToFull={isConvertingLiteToFull}
            handleConvertingLiteToFullSuccess={this.handleConvertLiteToFullSuccess}
          />
        </div>
      </div>
    );
  }

  renderPermissions() {
    const { authUser, t } = this.props;
    const { buCollapsed, campaignCollapsed } = this.state;

    return (
      <div>
        {authUser.get('is-super-user', false) ? (
          <Accordion
            className={style.accordionBUPermissions}
            collapsed={buCollapsed}
            onClick={() => this.handleAccordionClick('bu')}
            label={t('Business Units & Moderation Permissions')}
          >
            {buCollapsed && this.renderBUModPermissions()}
          </Accordion>
        ) : null}
        <Accordion
          className={style.accordionCampaignPermissions}
          collapsed={campaignCollapsed}
          label={t('Campaign Permissions')}
          onClick={() => this.handleAccordionClick('campaign')}
        >
          {campaignCollapsed && this.renderCampampaignPermissions()}
        </Accordion>
      </div>
    );
  }

  renderBUModPermissions() {
    const {
      params: { userId },
    } = this.props;
    const { BUModMaxHeight } = this.state;

    return (
      <ColumnRow>
        <Column flexProportion={2}>
          <PermissionsBusinessUnits userId={userId} maxHeight={BUModMaxHeight} />
        </Column>
      </ColumnRow>
    );
  }

  renderCampampaignPermissions() {
    const {
      params: { userId },
    } = this.props;

    return <PermissionsCampaign userId={userId} />;
  }

  renderProfileHeading(text) {
    return (
      <div className={style.permissionH2}>
        <Heading className={style.subHeading} size={HeadingSizes.MEDIUM} tag={HeadingTags.H2}>
          {text}
        </Heading>
      </div>
    );
  }

  renderContent() {
    const { isFetchingAllEditData, isNormalUser } = this.props;

    if (isFetchingAllEditData) {
      return (
        <div className={style.progress}>
          <ProgressBar />
        </div>
      );
    }

    return (
      <ColumnContainer>
        {this.renderProfile()}
        {isNormalUser && !this.isSelf() && this.renderPermissions()}
      </ColumnContainer>
    );
  }

  render() {
    const { t } = this.props;
    const { isConvertingLiteToFull } = this.state;
    const text = isConvertingLiteToFull ? t('Convert to Full User') : t('Edit User');
    return (
      <div className={style.component}>
        <Heading className={style.heading} tag="H1" size="largest">
          {text}
        </Heading>
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  isFetchingAllEditData: state.users.isFetchingAllEditData,
  isNormalUser: isNormalUser(state.users.editableUser),
});

export default withNamespaces(['common', 'users'], { wait: true })(connect(mapStateToProps)(UserEdit));

UserEdit.propTypes = {
  authUser: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  isFetchingAllEditData: PropTypes.bool,
  isNormalUser: PropTypes.bool.isRequired,
};
