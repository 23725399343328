import React from 'react';
import PropTypes from 'prop-types';

import MenuOption from '../MenuOption';

import style from './OptionsGroup.scss';

const OptionsGroup = ({ title, options, selected, multiple, onChange }) => {
  const onSelect = (value) => {
    if (multiple) {
      const isSelected = selected.includes(value);
      if (isSelected) {
        onChange(selected.filter((item) => item !== value));
      } else {
        onChange([...selected, value]);
      }
    } else {
      onChange(value);
    }
  };

  return (
    <div className={style.component}>
      <div className={style.title}>{title}</div>
      {options.map((option) => {
        const isSelected = multiple ? selected.includes(option.value) : option.value === selected;
        return (
          <MenuOption key={option.value} isSelected={isSelected} onSelect={() => onSelect(option.value)}>
            {option.label}
          </MenuOption>
        );
      })}
    </div>
  );
};

OptionsGroup.propTypes = {
  title: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
};

OptionsGroup.defaultProps = {
  title: '',
  options: [],
  selected: '',
  multiple: false,
  onChange: () => {},
};

export default OptionsGroup;
