import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';

import { setActiveSection } from 'actions/campaign/navigation';
import { fetchCreative, setActiveFrameSpec, fetchCampaign, fetchFrameSpecifications } from 'actions/liteModeration';
import { setActiveCampaignId } from 'actions/campaign';

import CreativeLiteModeration from 'assets/components/containers/Campaign/Creative/CreativeLiteModeration';

const mapStateToProps = (state) => ({
  activeCampaignId: state.liteModeration.campaign.get('id', ''),
  activeFrameSpecId: state.liteModeration.activeFrameSpecId,
  areCreativesUpdating: state.creative.areCreativesUpdating,
  campaign: state.liteModeration.campaign,
  frameSpecifications: state.liteModeration.creatives.get('frame-specifications', new List()),
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCampaign,
      fetchFrameSpecifications,
      setActiveFrameSpec,
      setActiveSection,
      fetchCreative,
      setActiveCampaignId,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreativeLiteModeration);
