import React from 'react';
import PropTypes from 'prop-types';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

const PrivateLite = ({ children, baseUrl }) => (
  <div>
    <Header baseUrl={baseUrl} showBackLink={false} showSearch={false} showSettings={false} showUser />
    {children}
    <Footer />
  </div>
);

PrivateLite.propTypes = {
  children: PropTypes.node.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default PrivateLite;
