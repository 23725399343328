import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { v4 } from 'uuid';
import { withNamespaces } from 'react-i18next';
import { searchTags, searchSubTags } from 'requests/search';

import Dropdown from 'assets/components/presentational/Dropdown';
import Autocomplete from 'assets/components/presentational/Autocomplete';

import AddSetButton from '../AddSetButton';
import SetContainer from '../SetContainer';
import withExternalApiSearch from './withExternalApiSearch';

import style from './tagsForm.scss';

const TagsAutocomplete = withExternalApiSearch({ suggestionKeyToUse: 'objectID', suggestionValueToStore: 'key' })(
  Autocomplete,
);
const SubTagsAutocomplete = withExternalApiSearch({ suggestionKeyToUse: 'value', suggestionValueToStore: 'value' })(
  Autocomplete,
);

export const TagsFormFields = {
  TAG: 0,
  TAG_OPERATOR: 1,
  SUB_TAG: 2,
};

export const getTagsFormInitialEmptyState = () => [{}, null, {}];

class TagsForm extends Component {
  keysForSetContainer = [];

  componentDidMount() {
    this.updateKeys();
  }

  updateKeys = () => {
    const { fields } = this.props;

    this.keysForSetContainer = [];
    fields.forEach(() => this.keysForSetContainer.push(v4()));
  };

  handleTagChange = (value, suggestionValue, set, suggestionSet) => {
    const { TAG, SUB_TAG } = TagsFormFields;

    set[TAG].onChange(value);
    set[SUB_TAG].onChange('');
    suggestionSet[TAG].onChange(suggestionValue);
    suggestionSet[SUB_TAG].onChange({});
  };

  renderTag = (set, suggestionSet) => {
    const { ruleType, t } = this.props;
    const field = set[TagsFormFields.TAG];
    const suggestions = suggestionSet[TagsFormFields.TAG];

    return (
      <div className={style.tagKey}>
        <TagsAutocomplete
          label={t('Enter a value')}
          value={field.value}
          suggestions={suggestions.value}
          disabled={!ruleType.get('is-selected')}
          onChange={(value, suggestionValue) => this.handleTagChange(value, suggestionValue, set, suggestionSet)}
          search={{ callback: searchTags, callbackParams: [] }}
          multiple={false}
          direction="down"
          suggestionMatch="anywhere"
          theme={{ suggestions: style.suggestions }}
        />
      </div>
    );
  };

  renderTagOperator = (set) => {
    const { ruleType, t } = this.props;
    const field = set[TagsFormFields.TAG_OPERATOR];

    return (
      <Dropdown
        auto={false}
        className={style.dropdown}
        disabled={!ruleType.get('is-selected')}
        source={ruleType
          .get('options')
          .get(1)
          .toJS()}
        onChange={(value) => field.onChange(value)}
        value={field.value}
        label={t('Select an operator')}
      />
    );
  };

  handleSubTagChange = (value, suggestionValue, set, suggestionSet) => {
    const { SUB_TAG } = TagsFormFields;

    set[SUB_TAG].onChange(value);
    suggestionSet[SUB_TAG].onChange(suggestionValue);
  };

  renderSubTag = (set, suggestionSet) => {
    const { t } = this.props;
    const { TAG, SUB_TAG, TAG_OPERATOR } = TagsFormFields;
    const isOperatorHasAnyValue = parseInt(set[TAG_OPERATOR].value) === 3;

    if (isOperatorHasAnyValue) {
      return null;
    }

    const field = set[SUB_TAG];
    const suggestions = suggestionSet[SUB_TAG];
    const tagId = set[TAG].value;

    return (
      <div className={style.tagKey}>
        <SubTagsAutocomplete
          label={t('Search for tag key')}
          value={field.value}
          suggestions={suggestions.value}
          disabled={!tagId}
          onChange={(value, suggestionValue) => this.handleSubTagChange(value, suggestionValue, set, suggestionSet)}
          search={{ callback: searchSubTags, callbackParams: [tagId] }}
          multiple={false}
          direction="down"
          suggestionMatch="anywhere"
          theme={{ suggestions: style.suggestions }}
        />
      </div>
    );
  };

  handleRemoveSetClick = (key) => {
    const { fields, suggestions, notifyError, t } = this.props;
    if (fields.length > 1) {
      fields.removeField(key);
      suggestions.removeField(key);
      this.updateKeys();
    } else {
      notifyError(t('You cannot delete this since it is the only sub rule available'));
    }
  };

  renderSet = (set, suggestionSet, index) => {
    const { ruleType } = this.props;
    return (
      <SetContainer
        enabled={ruleType.get('is-selected')}
        key={this.keysForSetContainer[index]}
        onDeleteClick={this.handleRemoveSetClick}
        sequence={index}
      >
        <span className={style.tagRuleSeparator}>Tag in</span>
        {this.renderTag(set, suggestionSet)}
        {this.renderTagOperator(set)}
        {this.renderSubTag(set, suggestionSet)}
      </SetContainer>
    );
  };

  renderSets = () => {
    const { fields, suggestions } = this.props;
    return fields.map((set, index) => this.renderSet(set, suggestions[index], index));
  };

  handleAddSetClick = () => {
    const { fields, ruleType, suggestions } = this.props;

    fields.addField(ruleType.get('default-value').toJS()[0]);
    suggestions.addField(getTagsFormInitialEmptyState());
  };

  renderAddSetButton = () => {
    const { ruleType, t } = this.props;

    return (
      <AddSetButton
        label={t('Add tag rule')}
        onClick={this.handleAddSetClick}
        disabled={!ruleType.get('is-selected')}
      />
    );
  };

  render() {
    return (
      <div className={style.component}>
        {this.renderSets()}
        {this.renderAddSetButton()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(TagsForm);

TagsForm.propTypes = {
  fields: PropTypes.arrayOf(Object).isRequired,
  suggestions: PropTypes.arrayOf(Object).isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  notifyError: PropTypes.func,
};

TagsForm.defaultProps = {
  notifyError: () => {},
};
