import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'assets/components/presentational/Logo';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import style from './auth.scss';

const Auth = ({ children, heading }) => (
  <div className={style.component}>
    <Logo />

    <div className={style.prompt}>
      <div className={style.inner}>
        <Heading size={HeadingSizes.LARGEST} tag={HeadingTags.H1}>
          {heading}
        </Heading>

        {children}
      </div>
    </div>
  </div>
);

export default Auth;

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};
