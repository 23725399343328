import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import notify, { NotificationTypes } from 'actions/snackbar';
import { notifyApiError } from 'actions/notify';

export const FETCH_STAGES_REQUEST = 'enhanced-moderation/FETCH_STAGES_REQUEST';
export const FETCH_STAGES_SUCCESS = 'enhanced-moderation/FETCH_STAGES_SUCCESS';
export const FETCH_STAGES_COMPLETE = 'enhanced-moderation/FETCH_STAGES_COMPLETE';

export const PATCH_STAGES_REQUEST = 'enhanced-moderation/PATCH_STAGES_REQUEST';
export const PATCH_STAGES_SUCCESS = 'enhanced-moderation/PATCH_STAGES_SUCCESS';
export const PATCH_STAGES_COMPLETE = 'enhanced-moderation/PATCH_STAGES_COMPLETE';

export const ADD_NEW_STAGE = 'enhanced-moderation/ADD_NEW_STAGE';
export const ADD_NEW_GROUP = 'enhanced-moderation/ADD_NEW_GROUP';
export const UPDATE_USER = 'enhanced-moderation/UPDATE_USER';
export const UPDATE_MODERATION_RULE = 'enhanced-moderation/UPDATE_MODERATION_RULE';
export const UPDATE_MODERATION_NUMBER = 'enhanced-moderation/UPDATE_MODERATION_NUMBER';
export const UPDATE_GROUP_NAME = 'enhanced-moderation/UPDATE_GROUP_NAME';
export const UPDATE_VALIDATION_DATA = 'enhanced-moderation/UPDATE_VALIDATION_DATA';
export const DELETE_STAGE = 'enhanced-moderation/DELETE_STAGE';
export const DELETE_GROUP = 'enhanced-moderation/DELETE_GROUP';
export const MOVE_STAGE = 'enhanced-moderation/MOVE_STAGE';
export const RESET_SCROLL_TO_STAGE = 'enhanced-moderation/RESET_SCROLL_TO_STAGE';
export const RESEND_INVITES_REQUEST = 'enhanced-moderation/RESEND_INVITES_REQUEST';
export const RESEND_INVITES_COMPLETE = 'enhanced-moderation/RESEND_INVITES_COMPLETE';

export const TRIGGER_ROUTER_WILL_LEAVE_WARNING = 'enhanced-moderation/TRIGGER_ROUTER_WILL_LEAVE_WARNING';
export const HIDE_WARNING_DIALOG = 'enhanced-moderation/HIDE_WARNING_DIALOG';
export const CONFIRM_ROUTER_WILL_LEAVE = 'enhanced-moderation/CONFIRM_ROUTER_WILL_LEAVE';

export const FETCH_CAMPAIGNS_WITH_STAGES_REQUEST = 'enhanced-moderation/FETCH_CAMPAIGNS_WITH_STAGES_REQUEST';
export const FETCH_CAMPAIGNS_WITH_STAGES_SUCCESS = 'enhanced-moderation/FETCH_CAMPAIGNS_WITH_STAGES_SUCCESS';
export const FETCH_CAMPAIGNS_WITH_STAGES_COMPLETE = 'enhanced-moderation/FETCH_CAMPAIGNS_WITH_STAGES_COMPLETE';
export const REPLACE_STAGES_BY_CAMPAIGN = 'enhanced-moderation/REPLACE_STAGES_BY_CAMPAIGN';

export const fetchCampaignsWithStages = () => (dispatch) => {
  const options = {
    endpoint: 'campaigns',
    includes: ['moderation-stages'],
    filters: [{ has_moderation_stages: true }], // eslint-disable-line camelcase
  };

  dispatch({ type: FETCH_CAMPAIGNS_WITH_STAGES_REQUEST });
  return Request.send(options)
    .then((response) => {
      const campaigns = fromJS(response.data.body.parsed);
      dispatch({ type: FETCH_CAMPAIGNS_WITH_STAGES_SUCCESS, campaigns });
      dispatch({ type: FETCH_CAMPAIGNS_WITH_STAGES_COMPLETE });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const replaceStagesByCampaign = (selectedCampaign) => (dispatch) => {
  const stages = selectedCampaign.get('moderation-stages');
  dispatch({ type: REPLACE_STAGES_BY_CAMPAIGN, stages });
};

export const fetchModerationStages = (campaignId) => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/moderation-stages`,
    method: 'GET',
  };

  dispatch({ type: FETCH_STAGES_REQUEST });

  return Request.send(options)
    .then((response) => {
      const stages = parseModerationStages(fromJS(response.data.body.parsed));

      dispatch({ type: FETCH_STAGES_SUCCESS, stages });
      dispatch({ type: FETCH_STAGES_COMPLETE });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_STAGES_COMPLETE });
    });
};

export const patchModerationStages = (campaignId, data, onSuccess, onError) => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/moderation-stages`,
    method: 'PATCH',
    data: transformModerationStages(data),
  };

  dispatch({ type: PATCH_STAGES_REQUEST });

  return Request.send(options)
    .then((response) => {
      const stages = parseModerationStages(fromJS(response.data.body.parsed));

      dispatch({ type: PATCH_STAGES_SUCCESS, stages });
      dispatch({ type: PATCH_STAGES_COMPLETE });

      onSuccess();
    })
    .catch((error) => {
      const errorRefined = error.response.data.errors.map((err) => {
        const errorMessage = Object.values(err)[0];
        if (errorMessage.includes('Name')) {
          return { translationKey: 'Cannot set stage. Group name should be unique' };
        }

        if (errorMessage.includes('Existing')) {
          const errorMessageArr = errorMessage.split(' ');
          const email = errorMessageArr[errorMessageArr.length - 1];
          return { translationKey: 'email_belongs_to_full_user', email: email.substring(0, email.length - 1) };
        }

        return { translationKey: errorMessage };
      });

      dispatch({ type: PATCH_STAGES_COMPLETE });

      onError(errorRefined[0]);
    });
};

const transformModerationStages = (data) => {
  if (!Array.isArray(data)) {
    return data;
  }

  return data.map((stage) => ({
    ...stage,
    'moderation-groups': stage['moderation-groups'].map((group) => ({
      id: group.id,
      name: group.name,
      require_all: group['require-all'],
      require_n: group['require-n'],
      users: group.users.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        is_expired: user['is-expired'],
        is_super_user: user['is-super-user'],
        is_lite_user: user['is-lite-user'],
        is_business_unit_admin: user['is-business-unit-admin'],
        is_subscribed_to_campaign_digest: user['is-subscribed-to-campaign-digest'],
        created_at: user['created-at'],
        updated_at: user['updated-at'],
      })),
    })),
  }));
};

export const parseModerationStages = (stages) => {
  const output = [];

  stages.forEach((stage) => {
    const parsedStage = {
      id: stage.get('id'),
      'sort-order': stage.get('sort-order'),
      'moderation-groups': [],
    };

    stage.get('moderation-groups').forEach((group) => {
      parsedStage['moderation-groups'].push({
        id: group.get('id'),
        name: group.get('name'),
        'require-all': group.get('require-all'),
        'require-n': group.get('require-n'),
        users: group.get('users'),
      });
    });

    output.push(parsedStage);
  });

  return fromJS(output);
};

export const postSendNewInvites = (campaignId, stageId) => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/moderation-stages/${stageId}`,
    method: 'PATCH',
  };

  dispatch({ type: RESEND_INVITES_REQUEST, stageId });

  return Request.send(options)
    .then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, 'Invites were successfully resent to all users in this stage'));
      dispatch({ type: RESEND_INVITES_COMPLETE, stageId });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const addNewStage = (stage) => ({
  type: ADD_NEW_STAGE,
  stage,
});

export const addNewGroup = (stageId, group) => ({
  type: ADD_NEW_GROUP,
  stageId,
  group,
});

export const updateUser = (stageId, groupId, userId, email) => ({
  type: UPDATE_USER,
  stageId,
  groupId,
  userId,
  email,
});

export const updateModerationRule = (stageId, groupId, requireAll) => ({
  type: UPDATE_MODERATION_RULE,
  stageId,
  groupId,
  requireAll,
});

export const updateModerationNumber = (stageId, groupId, requireN) => ({
  type: UPDATE_MODERATION_NUMBER,
  stageId,
  groupId,
  requireN,
});

export const updateGroupName = (stageId, groupId, name) => ({
  type: UPDATE_GROUP_NAME,
  stageId,
  groupId,
  name,
});

export const updateValidationData = (validationData) => ({
  type: UPDATE_VALIDATION_DATA,
  validationData,
});

export const deleteStage = (stageId) => ({
  type: DELETE_STAGE,
  stageId,
});

export const deleteGroup = (stageId, groupId) => ({
  type: DELETE_GROUP,
  stageId,
  groupId,
});

export const moveStage = (fromPos, toPos) => ({
  type: MOVE_STAGE,
  fromPos,
  toPos,
});

export const resetScrollToStage = () => ({
  type: RESET_SCROLL_TO_STAGE,
});
