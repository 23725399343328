import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import queryString from 'query-string';
import { withNamespaces } from 'react-i18next';
import { oneFactor, twoFactor, resendSms } from 'actions/auth';
import { success } from 'actions/notify';
import authStyle from 'assets/components/presentational/Auth/auth.scss';
import Input from 'components/patterns/Input';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Button from 'assets/components/presentational/Button';
import Auth from 'assets/components/presentational/Auth';
import Link from 'assets/components/presentational/Link';

import style from './login.scss';

const fields = ['email', 'password', 'smsCode'];

class Login extends Component {
  handleOneFactor = (values, dispatch) => {
    const qs = queryString.parse(window.location.search);
    dispatch(oneFactor(values.email, values.password, qs.next));
  };

  handleTwoFactor = (values, dispatch) => {
    const qs = queryString.parse(window.location.search);

    return dispatch(twoFactor(values.smsCode, qs.next));
  };

  handleResendSms = (event) => {
    const { auth, dispatch, t } = this.props;

    event.preventDefault();

    return dispatch(resendSms()).then(() => {
      dispatch(success(`${t("We've sent a X-digit verification code to", { number: 6 })} ${auth.user.get('email')}`));
    });
  };

  renderOneFactor = (isWorking) => {
    const {
      fields: { email, password },
      handleSubmit,
      t,
    } = this.props;

    return (
      <Auth heading={t('Login to app', { app: config.app.name })}>
        <form id="login-form" className={authStyle.form} onSubmit={handleSubmit(this.handleOneFactor)}>
          <div className={authStyle.inputs}>
            <Input
              name="email"
              type="text"
              icon={<Icon iconType={IconTypes.EMAIL} />}
              label={t('Email')}
              value={email.value}
              onChange={(value) => email.onChange(value)}
              error={email.error}
              autoFocus
              disabled={isWorking}
            />

            <Input
              name="password"
              type="password"
              icon={<Icon iconType={IconTypes.LOCK} />}
              label={t('Password')}
              value={password.value}
              onChange={(value) => password.onChange(value)}
              error={password.error}
              disabled={isWorking}
            />
          </div>

          <div className={authStyle.buttons}>{this.renderLoginButton(isWorking)}</div>

          <Link to="/auth/reset">Forgotten Password?</Link>
        </form>
      </Auth>
    );
  };

  renderTwoFactor = (isWorking) => {
    const {
      auth,
      fields: { smsCode },
      handleSubmit,
      t,
    } = this.props;

    const name = auth.user.get('name').split(' ')[0];

    return (
      <Auth heading={t('Hello name', { name })}>
        <p className={style.verificationSentMessage}>
          {t("We've sent a X-digit verification code to", { number: 6 })}
          <em>{auth.user.get('email')}</em>
        </p>

        <form className={authStyle.form} onSubmit={handleSubmit(this.handleTwoFactor)}>
          <div className={authStyle.inputs}>
            <Input
              autoFocus
              type="number"
              icon={<Icon iconType={IconTypes.DIALPAD} />}
              label={t('X-digit code', { number: 6 })}
              value={smsCode.value}
              error={smsCode.error}
              onChange={(value) => smsCode.onChange(value)}
              disabled={isWorking}
            />
          </div>

          <div className={authStyle.buttons}>
            {this.renderResendSmsButton()}
            {this.renderLoginButton(isWorking)}
          </div>
        </form>
      </Auth>
    );
  };

  renderLoginButton = (isWorking) => {
    const { auth, t } = this.props;

    if (!auth.isSendingSms) {
      return (
        <Button
          name="login"
          type="submit"
          className={auth.factor === 1 ? authStyle.button : authStyle.buttonMultiple}
          disabled={isWorking}
          isWorking={isWorking}
          label={t('Log me in')}
        />
      );
    }

    return null;
  };

  renderResendSmsButton = () => {
    const { auth, submitting, t } = this.props;

    if (!submitting) {
      return (
        <Button
          className={authStyle.buttonMultiple}
          type="button"
          disabled={auth.isSendingSms}
          onClick={this.handleResendSms}
          isWorking={auth.isSendingSms}
          theme="white"
          label={t('Resend Email Auth Code')}
        />
      );
    }

    return null;
  };

  render() {
    const { auth, submitting } = this.props;
    const isWorking = (auth.isAuthenticated && auth.isChecking) || submitting;

    if (auth.factor === 2) {
      return this.renderTwoFactor(isWorking);
    }

    return this.renderOneFactor(isWorking);
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'auth'], { wait: false })(
  reduxForm(
    {
      form: 'login',
      fields,
      // returnRejectedSubmitPromise: true,
    },
    (state) => ({
      // mapStateToProps
      auth: state.auth,
    }),
  )(Login),
);
