import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { setActiveRuleSetIds } from 'actions/preview';
import { getRuleSets } from 'selectors/campaign';
import Autocomplete from 'assets/components/presentational/Autocomplete';
import style from '../Preview.scss';

class SetRuleSetsWidget extends Component {
  getAvailableRuleSetsForSelect = () => {
    const { ruleSets } = this.props;
    const availableRuleSets = {};
    ruleSets.forEach((ruleSet) => {
      availableRuleSets[ruleSet.get('id')] = ruleSet.get('name');
    });
    return availableRuleSets;
  };

  handleRuleSetsSelectChange = (ruleSetIds) => {
    const { dispatch } = this.props;
    dispatch(setActiveRuleSetIds(ruleSetIds));
  };

  render() {
    const { activeRuleSetIds, t } = this.props;
    return (
      <div className={style.rulesetWidget}>
        <Autocomplete
          className={style.rulesetAutocomplete}
          direction="down"
          onChange={this.handleRuleSetsSelectChange}
          label={t('Preview with rules')}
          source={this.getAvailableRuleSetsForSelect()}
          value={activeRuleSetIds.toJS()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeRuleSetIds: state.preview.activeRuleSetIds,
  ruleSets: getRuleSets(state),
});

export default withNamespaces(['common', 'preview'], { wait: false })(connect(mapStateToProps)(SetRuleSetsWidget));

SetRuleSetsWidget.propTypes = {
  activeRuleSetIds: PropTypes.instanceOf(List).isRequired,
  dispatch: PropTypes.func.isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  t: PropTypes.func.isRequired,
};
