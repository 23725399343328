const ACTIVITIES = {
  SEARCH_CAMPAIGNS: 'SEARCH_CAMPAIGNS',
  FETCH_PUBLICATIONS: 'FETCH_PUBLICATIONS',
};

const ACTIVITY_STATUS = {
  PENDING: 'PENDING',
  IDLE: 'IDLE',
};

export { ACTIVITY_STATUS, ACTIVITIES };
