import { List, Map } from 'immutable';

export const isNormalUser = (user) => !user.get('is-lite-user') && !user.get('is-super-user');

export const isContentProviderForCampaign = (user = new Map(), campaign = new Map()) => {
  const businessUnitIds = campaign
    .get('business-units', new List())
    .map((bu) => bu.get('id'))
    .flatten();

  return (
    user
      .get('permissions', new List())
      .filter((permission) => permission.get('object') === 'App\\Models\\BusinessUnit')
      .findIndex(
        (permission) =>
          permission.get('action') === 'provide_content' && businessUnitIds.includes(permission.get('object-id')),
      ) > -1
  );
};

export const getUserFormInitialValues = (user) => ({
  name: user.get('name') ? user.get('name') : '',
  email: user.get('email') ? user.get('email') : '',
  isSubscribedToCampaignDigest: user.get('is-subscribed-to-campaign-digest') || false,
});

export const userHasCampaignPermission = (user, campaignId, action) => {
  if (user.get('is-super-user')) {
    return true;
  }

  return (
    user
      .get('permissions', List())
      .findIndex(
        (permission) =>
          permission.get('object') === 'App\\Campaign' &&
          permission.get('object-id') === campaignId &&
          permission.get('action') === action,
      ) > -1
  );
};

/**
 * Given a user, object and action, returns whether the passed user has that action
 * (meaning permission) on any object of the passed type (eg. "App\\Models\\BusinessUnit") in
 * the currently loaded set.
 *
 * @param user
 * @param object
 * @param action
 * @returns {boolean}
 */
export const userHasPermissionOnAny = (user, object, action) =>
  user
    .get('permissions', List())
    .findIndex(
      (permission) =>
        Map.isMap(permission) && permission.get('object') === object && permission.get('action') === action,
    ) > -1;

export const userCanCreateCampaigns = (user) => {
  if (!user) return false;
  return user.get('is-super-user') || userHasPermissionOnAny(user, 'App\\Models\\BusinessUnit', 'add_campaign');
};

export const userCanEditFrames = (user) => {
  if (!user) return false;
  return user.get('is-super-user') || userHasPermissionOnAny(user, 'App\\Models\\BusinessUnit', 'edit_frames');
};

export const userCanEditUsers = (user) => {
  if (!user) return false;
  return user.get('is-super-user') || user.get('is-business-unit-admin');
};
