import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';
import * as at from 'actions/campaign/mediaBuy';

const getInitialState = () => ({
  isFetchingFrames: false,
  isFetchingFrameHistoryStats: false,
  frames: new List(),
  screenshotFrame: new Map(),
  frameHistoryStats: new List(),
  paginationFrames: new Map(),
  filterValue: '',
  isFetchingExportData: false,
  exportDataItems: List(),
  isFetchingScreenshots: false,
  screenshots: new List(),
  areScreenshotsLoaded: false,
  isFetchingFrame: false,
  addFramesError: '',
  addFramesInput: '',
});

const mediaBuy = handleActions(
  {
    [at.FETCH_FRAMES_REQUEST]: (state) => ({
      ...state,
      isFetchingFrames: true,
    }),
    [at.FETCH_FRAMES_COMPLETE]: (state) => ({
      ...state,
      isFetchingFrames: false,
    }),
    [at.FETCH_FRAMES_SUCCESS]: (state, action) => ({
      ...state,
      frames: action.frames,
    }),
    [at.FETCH_HISTORY_REQUEST]: (state) => ({
      ...state,
      isFetchingFrameHistoryStats: true,
    }),
    [at.FETCH_HISTORY_COMPLETE]: (state) => ({
      ...state,
      isFetchingFrameHistoryStats: false,
    }),
    [at.FETCH_HISTORY_SUCCESS]: (state, action) => ({
      ...state,
      frameHistoryStats: state.frameHistoryStats.merge(action.frameHistoryStats),
    }),
    [at.FETCH_FRAMES_PAGINATION_MERGE]: (state, action) => ({
      ...state,
      paginationFrames: fromJS(action.paginationFrames),
    }),
    [at.SET_FRAMES_FILTER_VALUE]: (state, action) => ({
      ...state,
      filterValue: action.filterValue,
    }),
    [at.RESET]: () => getInitialState(),
    [at.FETCH_FRAMES_CSV_REQUEST]: (state) => ({
      ...state,
      isFetchingExportData: true,
    }),
    [at.FETCH_FRAMES_CSV_COMPLETE]: (state) => ({
      ...state,
      isFetchingExportData: false,
    }),
    [at.FETCH_FRAMES_CSV_SUCCESS]: (state, action) => ({
      ...state,
      exportDataItems: action.items,
    }),
    [at.FETCH_SCREENSHOTS_REQUEST]: (state) => ({
      ...state,
      areScreenshotsLoaded: true,
      isFetchingScreenshots: true,
    }),
    [at.FETCH_SCREENSHOTS_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingScreenshots: false,
      screenshots: action.screenshots,
    }),
    [at.CLEAR_SCREENSHOTS]: (state) => ({
      ...state,
      areScreenshotsLoaded: false,
      screenshots: new List(),
    }),
    [at.FETCH_SCREENSHOTS_COMPLETE]: (state) => ({
      ...state,
      isFetchingScreenshots: false,
    }),
    [at.FETCH_FRAME_REQUEST]: (state) => ({
      ...state,
      isFetchingFrame: true,
    }),
    [at.FETCH_FRAME_SUCCESS]: (state, action) => ({
      ...state,
      screenshotFrame: action.frame,
    }),
    [at.FETCH_FRAME_COMPLETE]: (state) => ({
      ...state,
      isFetchingFrame: false,
    }),
    [at.SET_ADD_FRAMES_ERROR]: (state, action) => ({
      ...state,
      addFramesError: action.error,
      addFramesInput: action.missingFrames,
    }),
    [at.CLEAR_ADD_FRAMES_ERROR]: (state) => ({
      ...state,
      addFramesError: '',
      addFramesInput: '',
    }),
    [at.SET_ADD_FRAMES_INPUT]: (state, action) => ({
      ...state,
      addFramesInput: action.addFramesInput,
    }),
  },
  getInitialState(),
);

export default mediaBuy;
