import config from 'app-config';

import React from 'react';

import logo from 'assets/images/logos/VIOOH-content-logo.svg';

import style from './Logo.scss';

const Logo = () => <img src={logo} className={style.logo} alt={config.app.name} />;

export default Logo;
