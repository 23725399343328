import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { redirect } from 'modules/Helpers';
import { userHasCampaignPermission } from 'store/user/helpers';

import { fetchCreativesModerationSummary } from 'actions/creative';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Pagination from 'assets/components/presentational/Pagination';
import CreativeModerationSectionList from 'assets/components/presentational/Campaign/CreativeModerationSectionList';
import ProgressBar from 'components/patterns/ProgressBar';

import style from './creativesModerationSummary.scss';

class CreativesModerationSummary extends Component {
  componentDidMount() {
    const {
      params: { pageNumber },
    } = this.props;
    this.fetchCreativesModerationSummary(pageNumber);
  }

  componentDidUpdate(prevProps) {
    const {
      params: { pageNumber: currentPage },
    } = prevProps;
    const {
      params: { pageNumber: nextPage },
    } = this.props;

    if (this.isPageChanged(currentPage, nextPage)) {
      this.fetchCreativesModerationSummary(nextPage);
    }
  }

  isPageChanged = (currentPage, nextPage) => {
    const isPageChanged = currentPage && nextPage && currentPage !== nextPage;
    const isCurrentPageNumber = !Number.isNaN(parseInt(currentPage));

    return isPageChanged && isCurrentPageNumber;
  };

  getPageNumber = () => {
    let {
      params: { pageNumber },
    } = this.props;
    pageNumber = parseInt(pageNumber);

    return Number.isNaN(pageNumber) ? 1 : pageNumber;
  };

  updatePageNumber = (pageNumber) => {
    const {
      params: { campaignId },
    } = this.props;
    return redirect(`/campaigns/${campaignId}/creative/moderation/summary/${pageNumber}`);
  };

  handleRefreshModerationSummaryClick = () => {
    this.fetchCreativesModerationSummary();
  };

  fetchCreativesModerationSummary = (pageNumber) => {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;
    dispatch(fetchCreativesModerationSummary(campaignId, pageNumber));
  };

  handlePaginationClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;
    this.updatePageNumber(pageNumber);
  };

  renderPagination = () => {
    const { isFetchingCreativesModerationSummary, creativesModerationSummaryPagination } = this.props;

    const pageNumber = this.getPageNumber();
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <div>
        {creativesModerationSummaryPagination.get('total_pages') > 1 ? (
          <Pagination
            forceSelected={pageNumber - 1}
            hidden={isFetchingCreativesModerationSummary}
            initialSelected={initialSelected}
            onClick={this.handlePaginationClick}
            pageNum={creativesModerationSummaryPagination.get('total_pages')}
          />
        ) : null}
      </div>
    );
  };

  renderAccessForbidden() {
    const { t } = this.props;
    return (
      <Heading className={style.moderationSummaryHeading} size={HeadingSizes.LARGE} tag={HeadingTags.H3}>
        <span>{t('You are not permitted to view creative moderation decisions')}</span>
      </Heading>
    );
  }

  renderCreativesModerationSummary() {
    return (
      <div className={style.moderationSummary}>
        {this.renderProgress()}
        {this.renderModerationSection()}
      </div>
    );
  }

  renderProgress() {
    const { isFetchingCreativesModerationSummary } = this.props;

    if (!isFetchingCreativesModerationSummary) {
      return null;
    }

    return (
      <span className={style.progress}>
        <ProgressBar />
      </span>
    );
  }

  renderModerationSection = () => {
    const { creativesModerationSummary, isFetchingCreativesModerationSummary, t } = this.props;
    if (isFetchingCreativesModerationSummary) {
      return null;
    }

    return <CreativeModerationSectionList creativesModerationSummary={creativesModerationSummary} t={t} />;
  };

  render() {
    const {
      params: { campaignId },
      user,
      t,
    } = this.props;
    const shouldRenderCreativesModerationSummary = userHasCampaignPermission(user, campaignId, 'creative_decisions');

    return (
      <div className={style.component}>
        <HeadingGroup
          intro={t('Moderation Summary displays a record of the latest moderation decisions for each creative.')}
          subNavigation={
            <SubNavigation active={SubNavigationLabels.MODERATION_SUMMARY} campaignId={campaignId} user={user} />
          }
          title={t('Creative')}
        />
        {shouldRenderCreativesModerationSummary
          ? this.renderCreativesModerationSummary()
          : this.renderAccessForbidden()}
        {this.renderPagination()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  creativesModerationSummary: state.creative.creativesModerationSummary,
  creativesModerationSummaryPagination: state.creative.creativesModerationSummaryPagination,
  isFetchingCreativesModerationSummary: state.creative.isFetchingCreativesModerationSummary,
  user: state.auth.user,
});

export default withNamespaces(['common', 'creative'], { wait: false })(
  connect(mapStateToProps)(CreativesModerationSummary),
);

CreativesModerationSummary.propTypes = {
  creativesModerationSummary: PropTypes.instanceOf(List).isRequired,
  creativesModerationSummaryPagination: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingCreativesModerationSummary: PropTypes.bool,
  params: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
};
