import React, { Component } from 'react';
import { ListItem as BaseListItem, ListItemProps } from 'react-toolbox/lib/list';
import theme from './listItem.scss';

class ListItem extends Component {
  render() {
    const { theme: themeOverride } = this.props;
    return <BaseListItem {...this.props} theme={Object.assign(theme, themeOverride)} />;
  }
}

export default ListItem;

ListItem.propTypes = ListItemProps;
ListItem.defaultProps = BaseListItem.defaultProps;
