import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { redirect } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import CreativePreview from 'assets/components/presentational/CreativePreview';
import CreativeHeading from 'assets/components/presentational/Campaign/CreativeHeading';
import CreativeSettings from 'assets/components/presentational/Campaign/CreativeSettings';

import style from './CreativePreview.scss';

class BaseCreativePreview extends Component {
  campaignChannel = null;

  async componentDidMount() {
    const {
      setActiveSection,
      fetchCreativeForPreview,
      params: { creativeId },
    } = this.props;

    setActiveSection('creative');
    await fetchCreativeForPreview(creativeId);
  }

  async componentDidUpdate(prevProps) {
    const {
      params: { creativeId: prevCreativeId },
    } = prevProps;

    const {
      params: { creativeId },
      fetchCreativeForPreview,
    } = this.props;

    if (prevCreativeId !== creativeId) {
      await fetchCreativeForPreview(creativeId);
    }
  }

  getCreativesInOrder = () => {
    const { frameSpecifications } = this.props;
    const creativeIDsByUpdatedDate = frameSpecifications
      .map((frameSpec) =>
        frameSpec
          .get('creatives', List())
          .sortBy((creative) => creative.get('updated-at'))
          .reverse()
          .reduce((acc, creative) => acc.concat(creative.get('id')), []),
      )
      .reduce((acc, frame) => acc.concat(frame), []);

    return creativeIDsByUpdatedDate;
  };

  handleViewAllCreativeClick = () => {
    const {
      baseUrl,
      params: { campaignId },
    } = this.props;
    return redirect(`${baseUrl}/${campaignId}`);
  };

  handleViewPrevCreativeClick = () => {
    const {
      baseUrl,
      params: { campaignId, creativeId },
    } = this.props;

    const creativesInOrder = this.getCreativesInOrder();
    const index = creativesInOrder.indexOf(creativeId);
    const prevId = creativesInOrder[index - 1]
      ? creativesInOrder[index - 1]
      : creativesInOrder[creativesInOrder.length - 1];

    return redirect(`${baseUrl}/${campaignId}/creative/${prevId}`);
  };

  handleViewNextCreativeClick = () => {
    const {
      baseUrl,
      params: { campaignId, creativeId },
    } = this.props;

    const creativesInOrder = this.getCreativesInOrder();
    const index = creativesInOrder.indexOf(creativeId);
    const nextId = creativesInOrder[index + 1] ? creativesInOrder[index + 1] : creativesInOrder[0];

    return redirect(`${baseUrl}/${campaignId}/creative/${nextId}`);
  };

  renderPreviewHeading() {
    const {
      creative,
      handleViewModerationSummaryClick,
      handleViewAssetHistoryClick,
      showModerationSummaryLink,
      showAssetHistoryLink,
    } = this.props;

    return (
      <CreativeHeading
        creative={creative}
        onViewAllCreativeClick={this.handleViewAllCreativeClick}
        onViewPrevCreativeClick={this.handleViewPrevCreativeClick}
        onViewNextCreativeClick={this.handleViewNextCreativeClick}
        onViewAssetHistoryClick={handleViewAssetHistoryClick}
        onViewModerationSummaryClick={handleViewModerationSummaryClick}
        showModerationSummaryLink={showModerationSummaryLink}
        showAssetHistoryLink={showAssetHistoryLink}
        showPrevNextLinks={this.getCreativesInOrder().length > 1}
      />
    );
  }

  renderCreativePreview() {
    const {
      allowModeration,
      allowTargeting,
      campaign,
      creative,
      handleDefaultChange,
      handleModerateClick,
      handleRuleSetsChange,
      handleContentTypesChange,
      htmlPlayClick,
      isFetching,
      isModerating,
      isSavingCreative,
      shouldRenderSettings,
      showHtmlPlayButton,
    } = this.props;

    return (
      <div className={style.contentContainer}>
        <CreativePreview
          key={creative.get('id')}
          className={style.previewContainer}
          creative={creative}
          frameSpecification={creative.get('frame-specification', new Map())}
          showHtmlPlayButton={showHtmlPlayButton}
          onHtmlPlayClick={htmlPlayClick}
          working={isFetching}
          htmlPlayButtonPosition="top_left"
        />

        {shouldRenderSettings && (
          <div>
            <CreativeSettings
              contentTypes={campaign.get('content-types', List())}
              className={style.creativeSettingsContainer}
              fetching={isModerating}
              frameSpecification={creative.get('frame-specification', new Map())}
              creative={creative}
              onContentTypesChange={handleContentTypesChange}
              onModerateClick={handleModerateClick}
              ruleSets={campaign.get('rule-sets', List())}
              saving={isSavingCreative}
              allowModeration={allowModeration}
              onDefaultChange={handleDefaultChange}
              onRuleSetsChange={handleRuleSetsChange}
              shouldRenderDefaultToggle={allowTargeting}
              showApplyContent={allowTargeting}
              showApplyRules={allowTargeting}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { isFetching, renderModerationSummaryDrawer, renderAssetHistoryDrawer } = this.props;

    if (isFetching) {
      return <ProgressBar />;
    }

    return (
      <div className={style.component}>
        {this.renderPreviewHeading()}
        {this.renderCreativePreview()}
        {renderModerationSummaryDrawer()}
        {renderAssetHistoryDrawer()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'creative'], { wait: false })(BaseCreativePreview);

BaseCreativePreview.propTypes = {
  // General props.
  baseUrl: PropTypes.string.isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  fetchCreativeForPreview: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    creativeId: PropTypes.string.isRequired,
    drawer: PropTypes.string,
  }).isRequired,
  setActiveSection: PropTypes.func.isRequired,
  shouldRenderSettings: PropTypes.bool,
  showAssetHistoryLink: PropTypes.bool,
  showModerationSummaryLink: PropTypes.bool,

  // Normal Preview and CreativePreviewLiteModeration props.
  allowModeration: PropTypes.bool,
  campaign: PropTypes.instanceOf(Map),
  handleModerateClick: PropTypes.func,
  isSavingCreative: PropTypes.bool,

  // Normal Preview props.
  allowTargeting: PropTypes.bool,
  handleDefaultChange: PropTypes.func,
  handleViewAssetHistoryClick: PropTypes.func,
  handleViewModerationSummaryClick: PropTypes.func,
  handleRuleSetsChange: PropTypes.func,
  handleContentTypesChange: PropTypes.func,
  htmlPlayClick: PropTypes.func,
  isModerating: PropTypes.bool,
  renderModerationSummaryDrawer: PropTypes.func,
  renderAssetHistoryDrawer: PropTypes.func,
  showHtmlPlayButton: PropTypes.bool,
};

BaseCreativePreview.defaultProps = {
  allowModeration: false,
  allowTargeting: false,
  campaign: Map(),
  handleDefaultChange: () => {},
  handleViewAssetHistoryClick: () => {},
  handleViewModerationSummaryClick: () => {},
  handleRuleSetsChange: () => {},
  handleContentTypesChange: () => {},
  htmlPlayClick: () => {},
  isSavingCreative: false,
  isModerating: false,
  handleModerateClick: () => {},
  renderModerationSummaryDrawer: () => {},
  renderAssetHistoryDrawer: () => {},
  shouldRenderSettings: false,
  showAssetHistoryLink: false,
  showHtmlPlayButton: false,
  showModerationSummaryLink: false,
};
