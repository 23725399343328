import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import { redirect } from 'modules/Helpers';
import { reset } from 'actions/auth';
import notify, { NotificationTypes } from 'actions/snackbar';
import authStyle from 'assets/components/presentational/Auth/auth.scss';
import Input from 'components/patterns/Input';
import Button from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Auth from 'assets/components/presentational/Auth';
import Link from 'assets/components/presentational/Link';

const fields = ['email', 'password', 'passwordConfirmation'];

class Reset extends Component {
  render() {
    const {
      fields: { email, password, passwordConfirmation },
      handleSubmit,
      onSubmit,
      submitting,
      t,
    } = this.props;

    return (
      <Auth heading={t('Password Reset')}>
        <form
          className={authStyle.form}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit);
          }}
        >
          <div className={authStyle.inputs}>
            <Input
              type="text"
              icon={<Icon iconType={IconTypes.EMAIL} />}
              label={t('Email')}
              onChange={(param) => email.onChange(param.val)}
              autoFocus
              {...email}
            />

            <Input
              type="password"
              icon={<Icon iconType={IconTypes.LOCK} />}
              label={t('New Password')}
              onChange={(param) => password.onChange(param.val)}
              {...password}
            />

            <Input
              type="password"
              icon={<Icon iconType={IconTypes.LOCK} />}
              label={t('Confirm Password')}
              onChange={(param) => passwordConfirmation.onChange(param.val)}
              {...passwordConfirmation}
            />
          </div>

          <div className={authStyle.buttons}>
            <Button
              type="submit"
              className={authStyle.button}
              disabled={!email.value.length}
              label={t('Reset Password')}
              isWorking={submitting}
            />
          </div>

          <Link to="/auth/login">{t('Back to login')}</Link>
        </form>
      </Auth>
    );
  }
}

Reset.propTypes = {
  error: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const ResetInner = reduxForm({
  form: 'reset',
  fields,
})(Reset);

class ResetWrapper extends Component {
  constructor(props) {
    super(props);
    this.handleResetClick.bind(this);
  }

  handleResetClick = (values, dispatch) => {
    const {
      t,
      params: { token },
    } = this.props;
    const { email, password, passwordConfirmation } = values;

    return dispatch(reset(email, password, passwordConfirmation, token)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('Your password has been successfully reset')));
      return redirect('/auth/login');
    });
  };

  render() {
    const { params, t } = this.props;
    return <ResetInner onSubmit={this.handleResetClick} t={t} params={params} />;
  }
}

ResetWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

export default withNamespaces(['common', 'auth'])(ResetWrapper);
