/* eslint-disable camelcase */
import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { toMonthDateYear } from 'modules/Helpers/dates';

import { searchBookings as searchBookingsRequest } from 'requests/search';

import Autocomplete from 'assets/components/presentational/Autocomplete';

function bookingToString(item, t, f = toMonthDateYear) {
  const { name, 'external-id': reservationId, advertiser, brand, 'starts-at': startsAt, 'ends-at': endsAt } = item;

  const CampaignName = name ? `${t('Campaign Name')}: ${name}` : '';
  const ReservationId = reservationId ? `${t('Reservation Id')}: ${reservationId}` : '';
  const Advertiser = advertiser ? `${t('Advertiser')}: ${advertiser}` : '';
  const Brand = brand ? `${t('Brand')}: ${brand}` : '';
  const Starts = startsAt ? `${t('Starts')}: ${f(startsAt)}` : '';
  const Ends = endsAt ? `${t('Ends')}: ${f(endsAt)}` : '';

  const bookingInfo = [CampaignName, ReservationId, Advertiser, Brand, Starts, Ends];
  const bookingInfoWithoutEmptyChunks = bookingInfo.filter((i) => i !== '');
  const bookingAsString = bookingInfoWithoutEmptyChunks.join(' / ');

  return bookingAsString;
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  businessUnitIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchBookings: PropTypes.func,
  formatBooking: PropTypes.func,
};

const defaultProps = {
  searchBookings: searchBookingsRequest,
  formatBooking: bookingToString,
};

class BookingsSearch extends Component {
  state = {
    bookingsSuggestions: [],
  };

  onChange = (bookingIds) => {
    const { onChange } = this.props;
    const { bookingsSuggestions } = this.state;
    const selectedBookingId = bookingIds[0];
    const selectedBooking = bookingsSuggestions.find((booking) => booking.id === selectedBookingId);
    onChange(selectedBooking);
  };

  searchBookings = async (query) => {
    const { searchBookings, businessUnitIds } = this.props;
    this.setState({
      bookingsSuggestions: await searchBookings(query, businessUnitIds),
    });
  };

  getBookingsSuggestionsById() {
    const { formatBooking, t } = this.props;
    const { bookingsSuggestions } = this.state;

    return bookingsSuggestions.reduce(
      (carry, item) => ({
        ...carry,
        [item.id]: formatBooking(item, t),
      }),
      {},
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Autocomplete
        label={t('Search bookings saved in {{application}}', { application: config.app.name })}
        suggestionMatch="anywhere"
        selectedPosition="none"
        onQueryChange={this.searchBookings}
        onChange={this.onChange}
        source={this.getBookingsSuggestionsById()}
      />
    );
  }
}

BookingsSearch.propTypes = propTypes;
BookingsSearch.defaultProps = defaultProps;

const withTranslation = withNamespaces(['common', 'campaignForm'], { wait: false })(BookingsSearch);
export default withTranslation;
export { BookingsSearch, bookingToString };
