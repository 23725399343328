import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';

import Navigation from 'assets/components/presentational/Campaign/Navigation';

import style from './lite-moderation.scss';

class LiteModeration extends Component {
  componentWillUnmount() {
    // Unset memoized links in case component is reused
    this.navLinks = null;
  }

  navigationLinks() {
    if (this.navLinks) {
      return this.navLinks;
    }

    const {
      params: { campaignId },
      t,
    } = this.props;

    this.navLinks = [
      {
        to: `/lite/moderation/view/${campaignId}`,
        label: t('Creative'),
      },
      {
        to: `/lite/moderation/view/${campaignId}/history`,
        label: t('History'),
      },
    ];

    return this.navLinks;
  }

  renderNavigation() {
    const { activeSection, campaign, user, isFetchingCampaign } = this.props;

    return (
      <Navigation
        activeSection={activeSection}
        campaign={campaign}
        isFetchingCampaign={isFetchingCampaign}
        isInitialFetch={false}
        user={user}
        links={this.navigationLinks()}
      />
    );
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <div className={style.component}>
          {this.renderNavigation()}
          <div className={style.content}>{children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSection: state.campaignNavigation.activeSection,
  campaign: state.liteModeration.campaign,
  isFetchingCampaign: state.liteModeration.isFetchingCampaign,
  user: state.auth.user,
});

LiteModeration.propTypes = {
  activeSection: PropTypes.string.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    pageNumber: PropTypes.string,
  }).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces(['common', 'liteModeration'], { wait: false })(connect(mapStateToProps)(LiteModeration));
