import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Dropdown from 'assets/components/presentational/Dropdown';

import style from './frameGroupForm.scss';

const Options = {
  RULE_SET_ID: 0,
  GROUP_ID: 1,
};

class FrameGroupForm extends Component {
  renderDropDown(field, options, label) {
    const { ruleType, t } = this.props;

    return (
      <Dropdown
        allowBlank={false}
        label={t(label)}
        disabled={!ruleType.get('is-selected')}
        onChange={(param) => field.onChange(param.val)}
        source={options.insert(0, new Map({ value: null, label: '' })).toJS()}
        {...field}
      />
    );
  }

  renderRuleSetDropDown() {
    const { fields, ruleType } = this.props;
    const field = fields[Options.RULE_SET_ID];
    const options = ruleType.getIn(['options', Options.RULE_SET_ID], List());

    return this.renderDropDown(field, options, 'Select a rule');
  }

  renderGroupDropdown() {
    const { fields, ruleType } = this.props;
    const field = fields[Options.GROUP_ID];
    const options = ruleType.getIn(['options', Options.GROUP_ID], List());

    return this.renderDropDown(field, options, 'Select a group');
  }

  render() {
    const { t } = this.props;

    return (
      <div className={style.component}>
        <span className={style.intro}>{t('Please select an existing rule and group')}</span>
        {this.renderRuleSetDropDown()}
        {this.renderGroupDropdown()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(FrameGroupForm);

FrameGroupForm.propTypes = {
  fields: PropTypes.object.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
