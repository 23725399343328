import { handleActions } from 'redux-actions';
import { fromJS, Map } from 'immutable';

const contentTypes = handleActions(
  {
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetching: action.relation === 'content-types' ? true : state.isFetching,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetching: action.relation === 'content-types' ? false : state.isFetching,
    }),
    CAMPAIGN_CONTENT_TYPE_PAGINATION_MERGE: (state, action) => ({
      ...state,
      pagination: state.pagination.merge(fromJS(action.pagination)),
    }),
    SHOW_CONTENT_TYPE_ADD_FORM: (state) => ({
      ...state,
      isDrawerActive: true,
      editingContentType: null,
    }),
    CLOSE_CONTENT_TYPE_ADD_FORM: (state) => ({
      ...state,
      isDrawerActive: false,
    }),
    ADD_CONTENT_TYPE_REQUEST: (state) => ({
      ...state,
      isSaving: true,
    }),
    ADD_CONTENT_TYPE_SUCCESS: (state) => ({
      ...state,
      isSaving: false,
    }),
    ADD_CONTENT_TYPE_FAILURE: (state) => ({
      ...state,
      isSaving: false,
    }),
    SHOW_CONTENT_TYPE_EDIT_FORM: (state, action) => ({
      ...state,
      isDrawerActive: true,
      editingContentType: action.contentType,
    }),
    CLOSE_CONTENT_TYPE_EDIT_FORM: (state) => ({
      ...state,
      isDrawerActive: false,
      editingContentType: null,
    }),
  },
  {
    isDrawerActive: false,
    isSaving: false,
    isFetching: false,
    editingContentType: null,
    pagination: new Map(),
  },
);

export default contentTypes;
