import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';

import notify from 'actions/snackbar';
import {
  fetchCampaignsWithStages,
  fetchModerationStages,
  patchModerationStages,
  addNewStage,
  addNewGroup,
  updateUser,
  updateModerationRule,
  updateModerationNumber,
  updateGroupName,
  updateValidationData,
  deleteStage,
  deleteGroup,
  moveStage,
  replaceStagesByCampaign,
  resetScrollToStage,
  postSendNewInvites,
} from 'actions/enhancedModeration';
import { triggerConfirmationDialog } from 'actions/unsavedChangesDialog';

const mapStateToProps = (state) => ({
  campaigns: state.enhancedModeration.campaigns,
  stages: state.enhancedModeration.stages,
  isFetchingCampaigns: state.enhancedModeration.isFetchingCampaigns,
  isLoading: state.enhancedModeration.isLoading,
  isSaving: state.enhancedModeration.isSaving,
  shouldScrollToStage: state.enhancedModeration.shouldScrollToStage,
  showDialog: state.unsavedChangesDialog.showDialog,
  nextLocation: state.unsavedChangesDialog.nextLocation,
  dirtyStages: state.enhancedModeration.dirtyStages,
  validationData: state.enhancedModeration.validationData,
  isResendingInvites: state.enhancedModeration.isResendingInvites,
  user: state.auth.user,
  hasUnsavedChanges: !isEqual(state.enhancedModeration.loadedStages.toJS(), state.enhancedModeration.stages.toJS()),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notify,
      fetchCampaignsWithStages,
      fetchModerationStages,
      patchModerationStages,
      addNewStage,
      addNewGroup,
      updateUser,
      updateModerationRule,
      updateModerationNumber,
      updateGroupName,
      updateValidationData,
      deleteStage,
      deleteGroup,
      moveStage,
      replaceStagesByCampaign,
      resetScrollToStage,
      postSendNewInvites,
      triggerConfirmationDialog,
    },
    dispatch,
  );

export { mapStateToProps, mapDispatchToProps };
