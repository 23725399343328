import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { notifyApiError } from 'actions/notify';

export const FETCH_USER_EDIT_DATA_REQUEST = 'users/FETCH_USER_EDIT_DATA_REQUEST';
export const FETCH_USER_EDIT_DATA_SUCCESS = 'users/FETCH_USER_EDIT_DATA_SUCCESS';
export const FETCH_USER_EDIT_DATA_COMPLETE = 'users/FETCH_USER_EDIT_DATA_COMPLETE';

export const FETCH_USER_REQUEST = 'users/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'users/FETCH_USER_SUCCESS';
export const FETCH_USER_COMPLETE = 'users/FETCH_USER_COMPLETE';

export const CREATE_USER_REQUEST = 'users/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS';
export const CREATE_USER_COMPLETE = 'users/CREATE_USER_COMPLETE';

export const DELETE_USER_REQUEST = 'users/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'users/DELETE_USER_SUCCESS';
export const DELETE_USER_COMPLETE = 'users/DELETE_USER_COMPLETE';

export const RESTORE_USER_REQUEST = 'users/RESTORE_USER_REQUEST';
export const RESTORE_USER_SUCCESS = 'users/RESTORE_USER_SUCCESS';
export const RESTORE_USER_COMPLETE = 'users/RESTORE_USER_COMPLETE';

export const FETCH_ACTIVE_USER_REQUEST = 'users/FETCH_ACTIVE_USER_REQUEST';
export const FETCH_ACTIVE_USER_SUCCESS = 'users/FETCH_ACTIVE_USER_SUCCESS';
export const FETCH_ACTIVE_USER_COMPLETE = 'users/FETCH_ACTIVE_USER_COMPLETE';

export const FETCH_USERS_REQUEST = 'users/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'users/FETCH_USERS_SUCCESS';
export const FETCH_USERS_COMPLETE = 'users/FETCH_USERS_COMPLETE';

export const UPDATE_ACTIVE_USER_REQUEST = 'users/UPDATE_ACTIVE_USER_REQUEST';
export const UPDATE_ACTIVE_USER_SUCCESS = 'users/UPDATE_ACTIVE_USER_SUCCESS';
export const UPDATE_ACTIVE_USER_COMPLETE = 'users/UPDATE_ACTIVE_USER_COMPLETE';

export const UPDATE_PERMISSIONS_USER_REQUEST = 'users/UPDATE_PERMISSIONS_USER_REQUEST';
export const UPDATE_PERMISSIONS_USER_SUCCESS = 'users/UPDATE_PERMISSIONS_USER_SUCCESS';
export const UPDATE_PERMISSIONS_USER_COMPLETE = 'users/UPDATE_PERMISSIONS_USER_COMPLETE';

export const SET_USERS_FILTER_USERTYPE_VALUE = 'users/SET_USERS_FILTER_USERTYPE_VALUE';
export const SET_USERS_FILTER_SEARCH_VALUE = 'users/SET_USERS_FILTER_SEARCH_VALUE';
export const CLEAR_USERS_STATE = 'users/CLEAR_USERS_STATE';
export const PAGINATION_MERGE = 'users/PAGINATION_MERGE';

export const SHOW_PROFILE_DRAWER = 'users/SHOW_PROFILE_DRAWER';
export const HIDE_PROFILE_DRAWER = 'users/HIDE_PROFILE_DRAWER';

export const PROFILE_EDITED = 'users/PROFILE_EDITED';
export const PERMISSIONS_PROFILE_EDITED = 'users/PERMISSIONS_PROFILE_EDITED';
export const USER_CREATE_PROFILE_EDITED = 'users/USER_CREATE_PROFILE_EDITED';
export const PROFILE_EDIT_CANCELLED = 'users/PROFILE_EDIT_CANCELLED';
export const PERMISSIONS_PROFILE_EDIT_CANCELLED = 'users/PERMISSIONS_PROFILE_EDIT_CANCELLED';
export const USER_CREATE_PROFILE_EDIT_CANCELLED = 'users/USER_CREATE_PROFILE_EDIT_CANCELLED';
export const CLEAR_EDITABLE_USER = 'users/CLEAR_EDITABLE_USER';

export const fetchUserEditData = (userId) => (dispatch) => {
  dispatch({ type: FETCH_USER_EDIT_DATA_REQUEST });

  return Promise.all([dispatch(fetchUser(userId))])
    .then((responses) => {
      const userEditData = {
        user: fromJS(responses[0].data.body.parsed),
      };

      dispatch({ type: FETCH_USER_EDIT_DATA_SUCCESS, userEditData });
      dispatch({ type: FETCH_USER_EDIT_DATA_COMPLETE });

      return Promise.resolve();
    })
    .catch((e) => {
      dispatch({ type: FETCH_USER_EDIT_DATA_COMPLETE });

      return Promise.reject(e);
    });
};

export const fetchActiveUser = (userId) => (dispatch) => {
  const options = {
    endpoint: `users/${userId}`,
  };

  dispatch({ type: FETCH_ACTIVE_USER_REQUEST });

  return Request.send(options)
    .then((response) => {
      const activeUser = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_ACTIVE_USER_SUCCESS, activeUser });
      dispatch({ type: FETCH_ACTIVE_USER_COMPLETE });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const fetchUser = (userId) => (dispatch) => {
  const options = {
    endpoint: `users/${userId}`,
  };

  dispatch({ type: FETCH_USER_REQUEST });

  return Request.send(options)
    .then((response) => {
      const user = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_USER_SUCCESS, user });
      dispatch({ type: FETCH_USER_COMPLETE });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const createUser = (data) => (dispatch) => {
  dispatch({ type: CREATE_USER_REQUEST });

  return Request.send({
    endpoint: 'users',
    method: 'POST',
    data,
  })
    .then((response) => {
      const newUser = fromJS(response.data.body.parsed);

      dispatch({ type: CREATE_USER_SUCCESS });
      dispatch({ type: CREATE_USER_COMPLETE });

      return Promise.resolve(newUser);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CREATE_USER_COMPLETE });

      return Promise.reject(error);
    });
};

export const deleteUser = (userId) => (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });
  return Request.send({
    endpoint: `users/${userId}`,
    method: 'DELETE',
  })
    .then(() => {
      dispatch({ type: DELETE_USER_SUCCESS, userId });
      dispatch({ type: DELETE_USER_COMPLETE });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: DELETE_USER_COMPLETE });
      return Promise.reject();
    });
};

export const restoreUser = (userId) => (dispatch) => {
  dispatch({ type: RESTORE_USER_REQUEST });

  return Request.send({
    endpoint: `users/${userId}/restore`,
    method: 'PATCH',
  })
    .then((response) => {
      const user = fromJS(response.data.body.parsed);
      dispatch({ type: RESTORE_USER_SUCCESS, user });
      dispatch({ type: RESTORE_USER_COMPLETE });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: RESTORE_USER_COMPLETE });
      return Promise.reject();
    });
};

export const fetchUsers = (pageNumber, userType, searchTerm) => (dispatch) => {
  const options = {
    endpoint: 'users/',
    pagination: {
      pageNumber,
    },
    filters: [],
  };

  const filterableFields = ['name', 'email'];

  if (searchTerm) {
    options.filters.push({ [filterableFields.join(',')]: `%${searchTerm}%` });
  }

  if (userType) {
    if (userType === 'is_lite_user') {
      options.filters.push({ [userType]: 1 });
    } else {
      options.filters.push({ [userType]: true });
    }
  }

  dispatch({ type: FETCH_USERS_REQUEST });

  return Request.send(options)
    .then((response) => {
      const users = fromJS(response.data.body.parsed);
      const pagination = fromJS(response.data.body.meta.pagination);

      dispatch({ type: FETCH_USERS_SUCCESS, users });
      dispatch({ type: FETCH_USERS_COMPLETE });
      dispatch(mergePagination(pagination));

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const updateUser = (data, userId) => (dispatch) => {
  dispatch({ type: UPDATE_ACTIVE_USER_REQUEST, userId });

  return Request.send({
    endpoint: `users/${userId}`,
    method: 'PATCH',
    data,
  })
    .then((response) => {
      const user = fromJS(response.data.body.parsed);

      dispatch({ type: UPDATE_ACTIVE_USER_SUCCESS, user });
      dispatch({ type: UPDATE_ACTIVE_USER_COMPLETE });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: UPDATE_ACTIVE_USER_COMPLETE });

      return Promise.reject(error);
    });
};

export const updatePermissionsUser = (data, userId, liteToFull = false) => {
  const endpoint = liteToFull ? `users/${userId}/convert-lite-to-full` : `users/${userId}`;

  return (dispatch) => {
    dispatch({ type: UPDATE_PERMISSIONS_USER_REQUEST, userId });

    return Request.send({
      endpoint,
      method: 'PATCH',
      data,
    })
      .then((response) => {
        const user = fromJS(response.data.body.parsed);

        dispatch({ type: UPDATE_PERMISSIONS_USER_SUCCESS, user });
        dispatch({ type: UPDATE_PERMISSIONS_USER_COMPLETE });

        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(notifyApiError(error));
        dispatch({ type: UPDATE_PERMISSIONS_USER_COMPLETE });

        return Promise.reject(error);
      });
  };
};

export const profileEdited = () => ({
  type: PROFILE_EDITED,
});

export const permissionsProfileEdited = () => ({
  type: PERMISSIONS_PROFILE_EDITED,
});

export const profileEditCancelled = () => ({
  type: PROFILE_EDIT_CANCELLED,
});

export const permissionsProfileEditCancelled = () => ({
  type: PERMISSIONS_PROFILE_EDIT_CANCELLED,
});

export const userCreateProfileEdited = () => ({
  type: USER_CREATE_PROFILE_EDITED,
});

export const userCreateProfileEditCancelled = () => ({
  type: USER_CREATE_PROFILE_EDIT_CANCELLED,
});

export const setUsersFilterSearchValue = (filterValue = '') => ({
  type: SET_USERS_FILTER_SEARCH_VALUE,
  filterValue,
});

export const setUsersFilterUserTypeValue = (filterValue = '') => ({
  type: SET_USERS_FILTER_USERTYPE_VALUE,
  filterValue,
});

export const clearUsersState = () => ({
  type: CLEAR_USERS_STATE,
});

export const mergePagination = (pagination) => ({
  type: PAGINATION_MERGE,
  pagination,
});

export const showProfileDrawer = () => ({
  type: SHOW_PROFILE_DRAWER,
});

export const hideProfileDrawer = () => ({
  type: HIDE_PROFILE_DRAWER,
});

export const clearEditableUser = () => ({
  type: CLEAR_EDITABLE_USER,
});
