import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, Map } from 'immutable';

import { setActiveSection } from 'actions/campaign/navigation';
import { error as notifyError } from 'actions/notify';
import {
  fetchCreative,
  deleteCreative,
  setActiveFrameSpec,
  addCreative,
  replaceCreative,
  fetchCampaign,
  fetchFrameSpecifications,
} from 'actions/drop-zone';
import { addUpload, updateUpload, removeUpload, resetUploads } from 'store/uploads/actions';
import { setActiveCampaignId } from 'actions/campaign';

import CreativeDropZone from 'assets/components/containers/Campaign/Creative/CreativeDropZone';

const mapStateToProps = (state) => ({
  activeCampaignId: state.dropZone.campaignInvite.get('campaign-id', ''),
  activeFrameSpecId: state.dropZone.activeFrameSpecId,
  areCreativesUpdating: state.creative.areCreativesUpdating,
  campaign: state.dropZone.campaignInvite.get('campaign', new Map()),
  frameSpecifications: state.dropZone.campaignInvite.getIn(['campaign', 'frame-specifications'], new List()),
  user: state.auth.user,
  uploads: state.uploads,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addCreative,
      addUpload,
      deleteCreative,
      fetchCampaign,
      fetchFrameSpecifications,
      notifyError,
      removeUpload,
      replaceCreative,
      resetUploads,
      setActiveFrameSpec,
      setActiveSection,
      updateUpload,
      fetchCreative,
      setActiveCampaignId,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreativeDropZone);
