import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Map, List } from 'immutable';

import { setActiveSection } from 'actions/campaign/navigation';
import { fetchContentScheduleData, setActiveFrameSpec } from 'actions/campaign/contentSchedule';

import { getFrameSpecifications } from 'selectors/campaign';

import ProgressBar from 'components/patterns/ProgressBar';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative';
import FrameSpecifications from 'assets/components/presentational/Campaign/FrameSpecifications';
import FrameSpecification from 'assets/components/presentational/Campaign/FrameSpecification';
import ScheduleContainer from 'assets/components/containers/Campaign/ContentSchedule/Schedule';

import style from './contentSchedule.scss';

class ContentSchedule extends Component {
  componentDidMount() {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(setActiveSection('contentSchedule'));
    dispatch(fetchContentScheduleData(campaignId));
  }

  componentDidUpdate(prevProps) {
    this.expandFirstFrameSpecAfterFetch(prevProps, this.props);
  }

  expandFirstFrameSpecAfterFetch = (props, nextProps) => {
    const { isFetching } = props;
    const { isFetching: nextIsFetching, frameSpecifications: nextFrameSpecifications } = nextProps;

    if (isFetching && !nextIsFetching && nextFrameSpecifications.size) {
      const firstFrameSpec = nextFrameSpecifications.first();

      this.handleFrameSpecClick(firstFrameSpec);
    }
  };

  handleFrameSpecClick = (frameSpec) => {
    const { dispatch } = this.props;

    dispatch(setActiveFrameSpec(frameSpec.get('id')));
  };

  renderHeading = () => {
    const { t, user, activeCampaignId } = this.props;

    return (
      <HeadingGroup
        intro={t('Creative schedule introduction')}
        subNavigation={
          <SubNavigation active={SubNavigationLabels.SCHEDULE} campaignId={activeCampaignId} user={user} />
        }
        title={t('Creative')}
      />
    );
  };

  renderFrameSpecs() {
    const { isFetching, frameSpecifications, activeFrameSpecId, activeCampaignId } = this.props;

    if (isFetching) {
      return <ProgressBar />;
    }

    return (
      <FrameSpecifications
        frameSpecifications={frameSpecifications}
        emptyFrameSpecsMessage="There are no frames available for this campaign"
      >
        {frameSpecifications.map((frameSpec, frameSpecKey) => (
          <FrameSpecification
            active={frameSpec.get('id') === activeFrameSpecId}
            campaignId={activeCampaignId}
            frameSpecification={frameSpec}
            key={frameSpec.get('id')}
            onClick={this.handleFrameSpecClick}
          >
            {this.renderSchedule(frameSpec, frameSpecKey)}
          </FrameSpecification>
        ))}
      </FrameSpecifications>
    );
  }

  renderSchedule(frameSpec, frameSpecKey) {
    return <ScheduleContainer frameSpec={frameSpec} frameSpecKey={frameSpecKey} />;
  }

  render() {
    return (
      <div className={style.component}>
        {this.renderHeading()}
        {this.renderFrameSpecs()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  activeCampaignId: state.campaign.activeCampaignId,
  activeFrameSpecId: state.campaignContentSchedule.activeFrameSpecId,
  isFetching: state.campaignContentSchedule.isFetching,
  frameSpecifications: getFrameSpecifications(state.campaign.campaign),
});

export default withNamespaces(['common', 'contentSchedule'], { wait: false })(
  connect(mapStateToProps)(ContentSchedule),
);

ContentSchedule.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.instanceOf(Map),
  isFetching: PropTypes.bool.isRequired,
  activeCampaignId: PropTypes.string.isRequired,
  activeFrameSpecId: PropTypes.string.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
};
