/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Track from './common/Track';
import createSlider from './common/createSlider';
import * as utils from './utils';

class Range extends React.Component {
  constructor(props) {
    super(props);

    const { count, min, max } = props;
    const initialValue = Array(...Array(count + 1)).map(() => min);
    const defaultValue = 'defaultValue' in props ? props.defaultValue : initialValue;
    const value = props.value !== undefined ? props.value : defaultValue;
    const bounds = value.map((v) => this.trimAlignValue(v));
    const recent = bounds[0] === max ? 0 : bounds.length - 1;

    this.state = {
      handle: null,
      recent,
      bounds,
    };
  }

  componentDidUpdate(prevProps) {
    if (!('value' in this.props || 'min' in this.props || 'max' in this.props)) return;
    const { value, onChange } = this.props;
    const { bounds } = this.state;
    const val = value || bounds;
    const nextBounds = val.map((v) => this.trimAlignValue(v, prevProps));

    if (bounds.toString() !== nextBounds.toString()) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ bounds: nextBounds });
    }

    if (bounds.some((v) => utils.isValueOutOfRange(v, this.props))) {
      onChange(nextBounds);
    }
  }

  onChange(state) {
    const { props } = this;
    const isNotControlled = !('value' in props);
    if (isNotControlled) {
      this.setState(state);
    } else if (state.handle !== undefined) {
      this.setState({ handle: state.handle });
    }

    const data = { ...this.state, ...state };
    const changedValue = data.bounds;
    props.onChange(changedValue);
  }

  onStart(position) {
    const { props } = this;
    const { state } = this;
    const bounds = this.getValue();
    props.onBeforeChange(bounds);

    const value = this.calcValueByPos(position);
    this.startValue = value;
    this.startPosition = position;

    const closestBound = this.getClosestBound(value);
    const boundNeedMoving = this.getBoundNeedMoving(value, closestBound);

    this.setState({
      handle: boundNeedMoving,
      recent: boundNeedMoving,
    });

    const prevValue = bounds[boundNeedMoving];
    if (value === prevValue) return;

    const nextBounds = [...state.bounds];
    nextBounds[boundNeedMoving] = value;
    this.onChange({ bounds: nextBounds });
  }

  onEnd = () => {
    const { onAfterChange } = this.props;
    this.setState({ handle: null });
    this.removeDocumentEvents();
    onAfterChange(this.getValue());
  };

  onMove(e, position) {
    utils.pauseEvent(e);
    const { props } = this;
    const { state } = this;

    const value = this.calcValueByPos(position);
    const oldValue = state.bounds[state.handle];
    if (value === oldValue) return;

    const nextBounds = [...state.bounds];
    nextBounds[state.handle] = value;
    let nextHandle = state.handle;
    if (props.pushable !== false) {
      const originalValue = state.bounds[nextHandle];
      this.pushSurroundingHandles(nextBounds, nextHandle, originalValue);
    } else if (props.allowCross) {
      nextBounds.sort((a, b) => a - b);
      nextHandle = nextBounds.indexOf(value);
    }
    this.onChange({
      handle: nextHandle,
      bounds: nextBounds,
    });
  }

  getValue() {
    const { bounds } = this.state;
    return bounds;
  }

  getClosestBound(value) {
    const { bounds } = this.state;
    let closestBound = 0;
    for (let i = 1; i < bounds.length - 1; ++i) {
      if (value > bounds[i]) {
        closestBound = i;
      }
    }
    if (Math.abs(bounds[closestBound + 1] - value) < Math.abs(bounds[closestBound] - value)) {
      closestBound += 1;
    }
    return closestBound;
  }

  getBoundNeedMoving(value, closestBound) {
    const { bounds, recent } = this.state;
    let boundNeedMoving = closestBound;
    const isAtTheSamePoint = bounds[closestBound + 1] === bounds[closestBound];
    if (isAtTheSamePoint) {
      boundNeedMoving = recent;
    }

    if (isAtTheSamePoint && value !== bounds[closestBound + 1]) {
      boundNeedMoving = value < bounds[closestBound + 1] ? closestBound : closestBound + 1;
    }
    return boundNeedMoving;
  }

  getLowerBound() {
    const { bounds } = this.state;
    return bounds[0];
  }

  getUpperBound() {
    const { bounds } = this.state;
    return bounds[bounds.length - 1];
  }

  /**
   * Returns an array of possible slider points, taking into account both
   * `marks` and `step`. The result is cached.
   */
  getPoints() {
    const { marks, step, min, max } = this.props;
    const cache = this._getPointsCache;
    if (!cache || cache.marks !== marks || cache.step !== step) {
      const pointsObject = { ...marks };
      if (step !== null) {
        for (let point = min; point <= max; point += step) {
          pointsObject[point] = point;
        }
      }
      const points = Object.keys(pointsObject).map(parseFloat);
      points.sort((a, b) => a - b);
      this._getPointsCache = { marks, step, points };
    }
    return this._getPointsCache.points;
  }

  pushSurroundingHandles(bounds, handle, originalValue) {
    const { pushable: threshold } = this.props;
    const value = bounds[handle];

    let direction = 0;
    if (bounds[handle + 1] - value < threshold) {
      direction = +1; // push to right
    }
    if (value - bounds[handle - 1] < threshold) {
      direction = -1; // push to left
    }

    if (direction === 0) {
      return;
    }

    const nextHandle = handle + direction;
    const diffToNext = direction * (bounds[nextHandle] - value);
    if (!this.pushHandle(bounds, nextHandle, direction, threshold - diffToNext)) {
      // revert to original value if pushing is impossible
      bounds[handle] = originalValue;
    }
  }

  pushHandle(bounds, handle, direction, amount) {
    const originalValue = bounds[handle];
    let currentValue = bounds[handle];
    while (direction * (currentValue - originalValue) < amount) {
      if (!this.pushHandleOnePoint(bounds, handle, direction)) {
        // can"t push handle enough to create the needed `amount` gap, so we
        // revert its position to the original value
        bounds[handle] = originalValue;
        return false;
      }
      currentValue = bounds[handle];
    }
    // the handle was pushed enough to create the needed `amount` gap
    return true;
  }

  pushHandleOnePoint(bounds, handle, direction) {
    const { stepIsMin, step } = this.props;
    const points = this.getPoints();
    const pointIndex = points.indexOf(bounds[handle]);
    const nextPointIndex = pointIndex + direction;
    const minimumPushValue = stepIsMin ? step : 0;
    if (nextPointIndex >= points.length || nextPointIndex < minimumPushValue) {
      // reached the minimum or maximum available point, can"t push anymore
      return false;
    }
    const nextHandle = handle + direction;
    const nextValue = points[nextPointIndex];
    const { pushable: threshold } = this.props;
    const diffToNext = direction * (bounds[nextHandle] - nextValue);
    if (!this.pushHandle(bounds, nextHandle, direction, threshold - diffToNext)) {
      // couldn"t push next handle, so we won"t push this one either
      return false;
    }
    // push the handle
    bounds[handle] = nextValue;
    return true;
  }

  trimAlignValue(v, prevProps = {}) {
    const state = this.state || {};
    const { handle } = state;
    const mergedProps = { ...prevProps, ...this.props };
    const valInRange = utils.ensureValueInRange(v, mergedProps, handle);
    const valNotConflict = this.ensureValueNotConflict(valInRange, mergedProps);
    return utils.ensureValuePrecision(valNotConflict, mergedProps);
  }

  ensureValueNotConflict(val, { allowCross }) {
    const state = this.state || {};
    const { handle, bounds } = state;
    if (!allowCross && handle != null) {
      if (handle > 0 && val <= bounds[handle - 1]) {
        return bounds[handle - 1];
      }
      if (handle < bounds.length - 1 && val >= bounds[handle + 1]) {
        return bounds[handle + 1];
      }
    }
    return val;
  }

  render() {
    const { handle, bounds } = this.state;
    const {
      prefixCls,
      vertical,
      included,
      disabled,
      min,
      max,
      handle: handleGenerator,
      trackStyle,
      handleStyle,
    } = this.props;

    const offsets = bounds.map((v) => this.calcOffset(v));
    const handleClassName = `${prefixCls}-handle`;

    const handles = bounds.map((v, i) =>
      handleGenerator({
        prefixCls,
        className: classNames({
          [handleClassName]: true,
          [`${handleClassName}-${i + 1}`]: true,
        }),
        vertical,
        offset: offsets[i],
        value: v,
        percentage: v - parseInt(offsets[i - 1] || 0),
        dragging: handle === i,
        index: i,
        min,
        max,
        disabled,
        style: handleStyle[i],
        ref: (h) => this.saveHandle(i, h),
      }),
    );

    const adjustedTrackStyle = trackStyle.slice(1);

    const tracks = bounds.slice(0, -1).map((_, index) => {
      const i = index + 1;
      const trackClassName = classNames({
        [`${prefixCls}-track`]: true,
        [`${prefixCls}-track-${i}`]: true,
      });
      return (
        <Track
          className={trackClassName}
          vertical={vertical}
          included={included}
          offset={offsets[i - 1]}
          length={offsets[i] - offsets[i - 1]}
          style={adjustedTrackStyle[index]}
          key={i}
        />
      );
    });

    return { tracks, handles };
  }
}

Range.displayName = 'Range';

Range.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  count: PropTypes.number,
  pushable: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

  allowCross: PropTypes.bool,
  disabled: PropTypes.bool,
};

Range.defaultProps = {
  count: 1,
  allowCross: true,
  pushable: false,
};

export default createSlider(Range);
