import { connect } from 'react-redux';
import { Map } from 'immutable';

import {
  getDaysUntilLive,
  checkEachFrameSpecHasAtLeastOneCreative,
  checkEachFrameSpecHasAtLeastOneDefaultCreative,
  checkCampaignIsAboutToGoLive,
} from 'selectors/campaign';

import CampaignAlert from './CampaignAlert';

const mapStateToProps = (state) => {
  const campaign = state.dropZone.campaignInvite.get('campaign', Map());

  return {
    campaign,
    daysUntilLive: getDaysUntilLive(campaign),
    frameSpecificationsHaveAtLeastOneCreative: checkEachFrameSpecHasAtLeastOneCreative(campaign),
    frameSpecificationsHaveAtLeastOneDefaultCreative: checkEachFrameSpecHasAtLeastOneDefaultCreative(campaign),
    isCampaignAboutToGoLive: checkCampaignIsAboutToGoLive(campaign),
    isFetchingFrameSpecifications: state.creative.isFetchingFrameSpecifications,
  };
};

export const CampaignAlertDropZoneContainer = connect(mapStateToProps)(CampaignAlert);
