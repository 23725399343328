import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { callback } from 'modules/Helpers/function';
import SubNavigation from 'assets/components/presentational/SubNavigation';

export const SubNavigationLabels = {
  CURRENT_CREATIVE: 'Current',
  MODERATION_SUMMARY: 'Moderation Summary',
  ASSET_HISTORY: 'Asset History',
  PREVIOUS_CREATIVE: 'Previous',
  ALL_CREATIVE: 'All Creatives',
  NEXT_CREATIVE: 'Next',
};

class SubNavigationCreativePreview extends Component {
  items = () => {
    const {
      creative,
      onCurrentClick,
      onAllClick,
      onPrevClick,
      onNextClick,
      onModerationSummaryClick,
      onAssetHistoryClick,
      showModerationSummaryLink,
      showAssetHistoryLink,
      showPrevNextLinks,
    } = this.props;

    let nav = [
      {
        displayLabel: creative.get('name', ''),
        label: SubNavigationLabels.CURRENT_CREATIVE,
        onClick: (e) => callback(onCurrentClick, e),
      },
    ];

    if (showModerationSummaryLink) {
      nav.push({
        label: SubNavigationLabels.MODERATION_SUMMARY,
        onClick: (e) => callback(onModerationSummaryClick, e),
      });
    }

    if (showAssetHistoryLink) {
      nav.push({
        label: SubNavigationLabels.ASSET_HISTORY,
        onClick: (e) => callback(onAssetHistoryClick, e),
      });
    }

    if (showPrevNextLinks) {
      nav = nav.concat([
        {
          label: SubNavigationLabels.PREVIOUS_CREATIVE,
          onClick: (e) => callback(onPrevClick, e),
          right: true,
        },
        {
          label: SubNavigationLabels.ALL_CREATIVE,
          onClick: (e) => callback(onAllClick, e),
          right: true,
        },
        {
          label: SubNavigationLabels.NEXT_CREATIVE,
          onClick: (e) => callback(onNextClick, e),
          right: true,
        },
      ]);
    } else {
      nav.push({
        label: SubNavigationLabels.ALL_CREATIVE,
        onClick: (e) => callback(onAllClick, e),
        right: true,
      });
    }

    return nav;
  };

  render() {
    const { active } = this.props;

    return <SubNavigation active={active} labels={SubNavigationLabels} items={this.items()} />;
  }
}

SubNavigationCreativePreview.propTypes = {
  active: PropTypes.string,
  creative: PropTypes.instanceOf(Map),
  onCurrentClick: PropTypes.func,
  onAllClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onAssetHistoryClick: PropTypes.func,
  onModerationSummaryClick: PropTypes.func,
  showModerationSummaryLink: PropTypes.bool,
  showAssetHistoryLink: PropTypes.bool,
  showPrevNextLinks: PropTypes.bool,
};

SubNavigationCreativePreview.defaultProps = {
  action: '',
  creative: new Map(),
  showModerationSummaryLink: false,
  showAssetHistoryLink: false,
  showPrevNextLinks: false,
};

export default SubNavigationCreativePreview;
