export const SHOW = 'dialog/confirmation/SHOW';
export const HIDE = 'dialog/confirmation/HIDE';

export const triggerConfirmationDialog = (nextLocation) => ({
  type: SHOW,
  nextLocation,
});

export const hideDialog = () => ({
  type: HIDE,
});

export const confirmDialog = (callback) => (dispatch) => {
  callback();
  dispatch(hideDialog());
};
