import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ROUTES from 'modules/Constants/routes';
import { redirect, getURLQueryString } from 'modules/Helpers';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import InDateLink from './InDateLink';
import DateRange from './DateRange';
import style from './InDateFilter.scss';

const START_DATE = 'startDate';

const InDateFilter = ({ validatedStartAfter, validatedStartBefore, validatedInDate }) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [{ startDate, endDate }, setDates] = useState({
    startDate: validatedStartAfter,
    endDate: validatedStartBefore,
  });

  useEffect(() => {
    if (!validatedStartAfter && !validatedStartBefore) {
      setDates({ startDate: null, endDate: null });
    }
  }, [validatedStartAfter, validatedStartBefore]);

  // eslint-disable-next-line no-shadow
  const onDatesChange = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });

    if (endDate) {
      setIsDatePickerVisible(false);

      const queryString = getURLQueryString({
        startAfter: startDate.format('YYYY-MM-DD'),
        startBefore: endDate.format('YYYY-MM-DD'),
        inDate: null,
      });

      redirect(`${ROUTES.DASHBOARD}1${queryString}`, true);
    }
  };

  const onFocusChange = (input) => (!input ? setFocusedInput(START_DATE) : setFocusedInput(input));
  const onOutsideClick = () => setIsDatePickerVisible(false);

  return (
    <>
      <button
        onClick={() => setIsDatePickerVisible(true)}
        type="button"
        disabled={isDatePickerVisible}
        className={style.inDateButton}
      >
        {validatedStartAfter || validatedStartBefore ? (
          <>
            {validatedStartAfter && <>from {validatedStartAfter.format('DD MMM YYYY')}</>}
            {validatedStartAfter && validatedStartBefore && ' '}
            {validatedStartBefore && <>to {validatedStartBefore.format('DD MMM YYYY')}</>}
          </>
        ) : (
          <>In Date</>
        )}

        <span className={style.icon}>
          <Icon iconType={IconTypes.ARROW_DROP_DOWN} color={IconColors.GRAY} />
        </span>
      </button>
      {isDatePickerVisible && (
        <>
          <DateRange
            numberOfMonths={2}
            onDatesChange={onDatesChange}
            onFocusChange={onFocusChange}
            onOutsideClick={onOutsideClick}
            startDate={startDate}
            endDate={endDate}
            focusedInput={focusedInput}
            calendarInfoPosition="top"
            renderCalendarInfo={() => {
              return !validatedInDate ? <InDateLink onClick={onOutsideClick} /> : null;
            }}
          />
        </>
      )}
    </>
  );
};

InDateFilter.propTypes = {
  validatedStartAfter: PropTypes.instanceOf(moment),
  validatedStartBefore: PropTypes.instanceOf(moment),
  validatedInDate: PropTypes.bool,
};

InDateFilter.defaultProps = {
  validatedStartAfter: null,
  validatedStartBefore: null,
  validatedInDate: null,
};

export default InDateFilter;
