import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import style from './setContainer.scss';

const renderSetSeparator = (sequence) => {
  if (sequence) {
    return <span className={style.separatorLabel}>AND</span>;
  }

  return null;
};

const SetContainer = ({ children, enabled, onDeleteClick, sequence }) => (
  <div>
    <div className={enabled ? style.componentEnabled : style.component}>
      {renderSetSeparator(sequence)}
      {children}
      <Button
        className={style.removeButton}
        disabled={!enabled}
        floating
        icon={<Icon iconType={IconTypes.DELETE} />}
        onClick={(e) => onDeleteClick(sequence, e)}
        theme={ButtonThemes.DANGER}
      />
    </div>
  </div>
);

export default withNamespaces(['common', 'rules'], { wait: false })(SetContainer);

SetContainer.propTypes = {
  children: PropTypes.node,
  enabled: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
  sequence: PropTypes.number,
  t: PropTypes.func.isRequired,
};

SetContainer.defaultProps = {
  enabled: false,
  sequence: 0,
};
