import { handleActions } from 'redux-actions';
import { List, Map } from 'immutable';
import * as at from 'actions/frames/screenshots';
import * as framesAt from 'actions/frames/frames';

const getInitialState = () => ({
  isFetching: false,
  screenshots: new List(),
  isLoaded: false,
  frame: new Map(),
  isFetchingFrame: false,
});

const frames = handleActions(
  {
    [at.FETCH_REQUEST]: (state) => ({
      ...state,
      isLoaded: true,
      isFetching: true,
    }),
    [at.FETCH_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      screenshots: action.screenshots,
    }),
    [at.CLEAR_SCREENSHOTS]: (state) => ({
      ...state,
      isLoaded: false,
      screenshots: new List(),
    }),
    [at.FETCH_COMPLETE]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [framesAt.FETCH_FRAME_REQUEST]: (state) => ({
      ...state,
      isFetchingFrame: true,
    }),
    [framesAt.FETCH_FRAME_SUCCESS]: (state, action) => ({
      ...state,
      frame: action.frame,
    }),
    [framesAt.FETCH_FRAME_COMPLETE]: (state) => ({
      ...state,
      isFetchingFrame: false,
    }),
  },
  getInitialState(),
);

export default frames;
