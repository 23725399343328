import React from 'react';
import PropTypes from 'prop-types';

import Link from 'assets/components/presentational/Link';
import { ArrowRight, ICON_COLORS } from 'assets/components/presentational/CustomIcon';

import style from './Recent.scss';

const Recent = ({ userId, t }) => {
  const recentCampaigns = JSON.parse(localStorage.getItem(`recentCampaigns-${userId}`)) || [];

  return (
    <div className={style.component}>
      {recentCampaigns.length ? (
        <ul className={style.recentList}>
          {recentCampaigns.map((campaign) => (
            <li key={campaign.id}>
              <Link to={`/campaigns/${campaign.id}`}>
                <span className={style.campaignName}>{campaign.name}</span>
                <span className={style.arrowRightIconWrapper}>
                  <ArrowRight fill={ICON_COLORS.MEDIUM_GRAY} />
                </span>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <div className={style.noData}>{t('No recent campaigns to display')}</div>
      )}
    </div>
  );
};

Recent.propTypes = {
  userId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default Recent;
