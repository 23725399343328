import React from 'react';
import PropTypes from 'prop-types';

import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

const EmptyState = ({ text }) => (
  <div>
    <Heading tag={HeadingTags.H2} size={HeadingSizes.SMALL}>
      <Icon iconType={IconTypes.LIST_ALT} /> {text}
    </Heading>
  </div>
);

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
};

export default EmptyState;
