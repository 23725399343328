import { List, Map, fromJS } from 'immutable';

export const getFrameSpecifications = (campaign = Map()) => campaign.get('frame-specifications', List());

export const getCreatives = (state) =>
  state.campaign.campaign
    .get('frame-specifications', List())
    .reduce(
      (creatives, frameSpec) => (frameSpec.has('creatives') ? creatives.concat(frameSpec.get('creatives')) : creatives),
      List(),
    );

export const getContentItems = (state) => state.campaign.campaign.get('content-items', List());

export const getContentTypes = (state) => state.campaign.campaign.get('content-types', List());

export const getRuleSets = (state) => state.campaign.campaign.get('rule-sets', List());

/**
 * Can the default status of this creative be edited?
 * @param state
 * @param creativeId
 * @returns {boolean}
 */
export const getIsDefaultEditable = (state, creativeId) => {
  if (!creativeId) {
    return false;
  }
  return isLastDefaultCreative(state, creativeId);
};

/**
 * Is the the creative passed in the last creative left
 * on its Frame Spec with is_default set to true?
 * @param state
 * @param creativeId
 * @returns {*}
 */
const isLastDefaultCreative = (state, creativeId) => {
  // TODO: I need me some null objects
  // let creative = state.campaign.campaign
  //   .get("creatives", new List([])).find(el => el.get("id") === creativeId)
  const creatives = getCreatives(state);

  const creative = creatives.find((el) => el.get('id') === creativeId);

  if (!creative) {
    return false;
  }

  const frameSpecificationId = creative.get('frame-specification-id');

  if (!frameSpecificationId) {
    return false;
  }

  const defaultCount = creatives.count(
    (el) => el.get('frame-specification-id') === frameSpecificationId && el.get('is-default'),
  );

  if (defaultCount === 1 && creative.get('is-default')) {
    return false;
  }

  return true;
};

export const checkEachFrameSpecHasAtLeastOneCreative = (campaign, frameSpecFilter) => {
  const moderationStatusFilter = frameSpecFilter ? frameSpecFilter.get('moderationStatus') : null;
  const frameSpecifications = getFrameSpecifications(campaign);
  return frameSpecifications.every((frameSpec) => {
    if (moderationStatusFilter && moderationStatusFilter[frameSpec.get('id')]) {
      return true;
    }
    const creatives = frameSpec.get('creatives');
    return creatives && creatives.size > 0;
  });
};

export const checkEachFrameSpecHasAtLeastOneDefaultCreative = (campaign, frameSpecFilter) => {
  const moderationStatusFilter = frameSpecFilter ? frameSpecFilter.get('moderationStatus') : null;
  const frameSpecifications = getFrameSpecifications(campaign);
  return frameSpecifications.every((frameSpec) => {
    if (moderationStatusFilter && moderationStatusFilter[frameSpec.get('id')]) {
      return true;
    }
    const creatives = frameSpec.get('creatives');
    return creatives && creatives.some((creative) => creative.get('is-default') === true);
  });
};

export const getDaysUntilLive = (campaign = Map()) => campaign.get('days-until-live');

export const checkCampaignIsAboutToGoLive = (campaign) => {
  const daysUntilLive = getDaysUntilLive(campaign);
  return daysUntilLive > 0 && daysUntilLive <= 2;
};

export const groupCreativesByRuleSet = (state, ownProps) => {
  const { frameSpec } = ownProps;
  const creatives = frameSpec.get('creatives', List());
  const ruleSets = generateRuleSetsArray(state);

  creatives.toJS().forEach((creative) => {
    if (creative['is-default'] && creative['moderation-status'] === 1) {
      ruleSets.filter((rs) => rs.id === 'default')[0].creatives.push(creative);
    }

    if (!creative['rule-sets']) return;

    creative['rule-sets'].forEach((ruleSet) => {
      if (creative['moderation-status'] !== 1) return;
      if (!ruleSets.filter((rs) => rs.id === ruleSet.id).length) {
        const newrs = { id: ruleSet.id, name: ruleSet.name, creatives: [creative] };
        ruleSets.push(newrs);
      } else {
        ruleSets.filter((rs) => rs.id === ruleSet.id)[0].creatives.push(creative);
      }
    });
  });

  return fromJS(ruleSets).sortBy((rs) => (rs.get('id') !== 'default' ? rs.get('name') : ''));
};

export const getBusinessUnitIds = (state) => {
  if (!state.campaign.campaign.size) {
    return [];
  }

  return state.campaign.campaign
    .get('business-units', new List())
    .toJS()
    .map((bu) => bu.id);
};

const generateRuleSetsArray = (state) => {
  const ruleSets = getRuleSets(state);
  const initArray = [{ id: 'default', name: 'Default', creatives: [] }];

  const rsArray = ruleSets.reduce(
    (acc, rs) => acc.concat({ id: rs.get('id'), name: rs.get('name'), creatives: [] }),
    initArray,
  );

  return rsArray;
};
