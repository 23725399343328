import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import Icon, { IconColors, IconSizes } from 'assets/components/presentational/Icon';
import style from './PublishIcon.scss';

const VARIANTS = {
  PUBLISHED: {
    className: 'tick',
    color: IconColors.GREEN,
    icon: 'done',
  },
  DRAFT: {
    className: 'cross',
    color: IconColors.RED,
    icon: 'clear',
  },
  STEP: {
    className: 'circle',
    color: IconColors.GREEN,
    icon: 'fiber_manual_record',
  },
};

const cx = classnames.bind(style);

const PublishIcon = ({ variant }) => (
  <div className={cx(style.iconWrapper, variant.className)}>
    <div className={cx(style.icon, variant.className)}>
      <Icon iconType={variant.icon} size={IconSizes.LARGE} color={variant.color} />
    </div>
  </div>
);

PublishIcon.propTypes = {
  variant: PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};

export default PublishIcon;
export { VARIANTS as IconVariants };
