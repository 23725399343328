import 'react-dates/initialize';

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { I18nextProvider } from 'react-i18next';

import i18n from 'i18n';
import getRoutes from 'routes';
import Redirect from './Redirect';

const Root = ({ store, history }) => (
  <I18nextProvider i18n={i18n}>
    <Redirect currentUrl={window.location.href} />
    <Provider store={store}>
      <Router history={history} routes={getRoutes(store)} />
    </Provider>
  </I18nextProvider>
);

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default Root;
