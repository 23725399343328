import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import cx from 'classnames';
import { withNamespaces } from 'react-i18next';
import { isEqual } from 'lodash';

import { isCreativeProcessed } from 'modules/Helpers';

import {
  fetchCreative,
  fetchComputedPlaylist,
  setHasUnsavedChanges,
  clearHasUnsavedChanges,
  playlistUpdate,
  clearComputedPlaylist,
} from 'actions/campaign/playlists';
import notify, { NotificationTypes } from 'actions/snackbar';

import Switch from 'components/patterns/Switch';
import Action from 'assets/components/presentational/Rows/Action';
import Heading, { HeadingTags } from 'assets/components/presentational/Heading';
import PlaylistCreativeRows from 'assets/components/presentational/Campaign/Playlists/PlaylistCreativeRows';
import PlaylistCreativeRow from 'assets/components/presentational/Campaign/Playlists/PlaylistCreativeRow';
import PlaylistSlider from 'assets/components/presentational/Campaign/Playlists/PlaylistSlider/Range';
import ComputedPlaylist from 'assets/components/presentational/Campaign/Playlists/ComputedPlaylist';
import CreativeReorderActions from 'assets/components/presentational/Campaign/CreativeReorderActions';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './playlistContainer.scss';

class PlaylistContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.colors = [
      '#1c333e',
      '#e89628',
      '#006900',
      '#eb4928',
      '#8e44ad',
      '#3e7fc7',
      '#6dceed',
      '#aa5e42',
      '#80c544',
      '#f3946f',
      '#6a5d66',
    ];

    this.state = {
      playlist: this.getPlaylistInitialState(props.playlist),
      changeHistory: new List(),
      shouldUpdateHistory: null,
    };
  }

  componentDidMount() {
    const { dispatch, playlist, activeFrameSpecId } = this.props;

    if (
      playlist.get('frame-specification-id') === activeFrameSpecId &&
      playlist.get('is-weighted') &&
      !playlist.get('is-shuffled')
    ) {
      dispatch(fetchComputedPlaylist(playlist.get('id')));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, playlist: currentPlaylist } = this.props;
    const { playlist, shouldUpdateHistory } = this.state;
    const creativeWeights = this.getCreativeWeights(playlist).toJS();
    const prevCreativeWeights = this.getCreativeWeights(prevState.playlist).toJS();

    if (!isEqual(creativeWeights, prevCreativeWeights) && shouldUpdateHistory) {
      this.handleUpdateChangeHistory(creativeWeights);
    }

    if (!prevProps.playlist.get('is-weighted') && currentPlaylist.get('is-weighted')) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        playlist: playlist.set('is-weighted', true),
      });
      dispatch(fetchComputedPlaylist(prevProps.playlist.get('id')));
    }
  }

  getCreativeWeights = (playlist) => {
    const weights = playlist.get('creatives').reduce((reduction, creative, key) => {
      const currentItemWeight = creative.get('weight');
      const previousItemValue = reduction.get(key - 1) || 0;

      // sum with previous value in array
      let newValue = previousItemValue + currentItemWeight;

      // if last, force 100%
      if (key + 1 === playlist.get('creatives').size) {
        newValue = 100;
      }

      return reduction.concat(newValue);
    }, List());

    return weights;
  };

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearComputedPlaylist());
    dispatch(clearHasUnsavedChanges());
  }

  getPlaylistInitialState = (playlist) => {
    let initialState = playlist;

    if (!initialState) return List();

    initialState = initialState.update('creatives', (creatives) => this.assignColor(creatives));

    if (!initialState.get('is-weighted')) {
      initialState = initialState.update('creatives', (creatives) => this.distributeEqually(creatives));
    }

    return initialState;
  };

  resetStateToStore = () => {
    const { playlist } = this.props;
    this.setState({
      playlist,
    });
  };

  assignColor = (creatives) =>
    creatives.map((creative, key) => {
      const index = key < this.colors.length ? key : key - this.colors.length;
      return creative.set('color', this.colors[index]);
    });

  sliderColorsArray = () => {
    const { playlist } = this.state;

    return playlist.get('creatives').reduce((acc, c) => acc.concat({ backgroundColor: c.get('color') }), []);
  };

  distributeEqually = (creatives) => {
    const equalValue = Math.floor(100 / creatives.size);
    const adjustedValue = equalValue + 1;
    const remainder = 100 % creatives.size;

    return creatives.reduce(
      (reduction, creative, key) =>
        reduction.push(creative.update('weight', () => (key < remainder ? adjustedValue : equalValue))),
      List(),
    );
  };

  handleDistributeEquallyAction = () => {
    const { dispatch } = this.props;
    this.setState(
      ({ playlist }) => ({
        playlist: playlist.update('creatives', (creatives) => this.distributeEqually(creatives)),
        shouldUpdateHistory: true,
      }),
      this.unlockAll(),
    );
    dispatch(setHasUnsavedChanges());
  };

  handleDiscardChanges = () => {
    const { playlist, dispatch } = this.props;
    this.setState({
      playlist: this.getPlaylistInitialState(playlist),
      changeHistory: new List(),
    });

    dispatch(clearHasUnsavedChanges());
  };

  handleShuffle = () => {
    const { dispatch } = this.props;
    const { playlist } = this.state;
    const value = !playlist.get('is-shuffled');

    this.setState({
      playlist: playlist.set('is-shuffled', value),
    });

    dispatch(setHasUnsavedChanges());

    if (value === false) {
      dispatch(fetchComputedPlaylist(playlist.get('id')));
    }
  };

  handleSliderChange = (values) => {
    const { dispatch } = this.props;
    values.map((value, index) => {
      this.setState(({ playlist }) => {
        const prevIndexValue = values[index - 1] || 0;
        const newValue = value - prevIndexValue;

        if (values[values.length - 1] !== 100) {
          return;
        }

        dispatch(setHasUnsavedChanges());

        return {
          playlist: playlist.updateIn(['creatives', index], (creative) => {
            const oldValue = creative.get('weight');
            if (newValue !== oldValue) {
              return creative.merge({ weight: newValue, locked: false });
            }
            return creative;
          }),
          shouldUpdateHistory: false,
        };
      });
    });
  };

  handleUpdateChangeHistory = (values) => {
    const { changeHistory } = this.state;
    this.setState({ changeHistory: changeHistory.push(values) });
  };

  handleUndoChanges = () => {
    const { changeHistory } = this.state;
    if (changeHistory.size > 1) {
      const prevChangeHistory = changeHistory.pop();
      const prevValues = prevChangeHistory.last();
      this.handleSliderChange(prevValues);
      return this.setState({ changeHistory: prevChangeHistory });
    }
    return this.handleDiscardChanges();
  };

  handlePercentInputChange = (creativeId, newWeight) => {
    this.updateWeights(creativeId, newWeight);
  };

  activeCreatives = () => {
    const { playlist } = this.state;
    return playlist.get('creatives').filter((c) => !c.get('locked'));
  };

  activeWeight = () => this.activeCreatives().reduce((acc, c) => (acc += c.get('weight')), 0);

  activeCreativesExceptCurrent = (creativeId) =>
    this.activeCreatives().filter((creative) => creative.get('id') !== creativeId);

  totalActiveWeightExceptCurrent = (creativeId) =>
    this.activeCreatives()
      .filter((creative) => creative.get('id') !== creativeId)
      .reduce((acc, c) => (acc += c.get('weight')), 0);

  totalLockedWeight = () => {
    const { playlist } = this.state;
    return playlist
      .get('creatives')
      .filter((creative) => creative.get('locked') === true)
      .reduce((acc, c) => (acc += c.get('weight')), 0);
  };

  // Update weights
  updateWeights = (creativeId, newWeight) => {
    this.updateCurrentCreativeWeight(creativeId, newWeight);
  };

  highestRemainder = (values, target) => {
    const sortedValued = values.sortBy((v) => v.get('weight')).reverse();
    const remainder = values.reduce((acc, v) => acc - Math.floor(v.get('weight')), target);
    const adjustedValues = sortedValued.reduce(
      (acc, v, key) =>
        acc.push(v.update('weight', (weight) => (key < remainder ? Math.floor(weight) + 1 : Math.floor(weight)))),
      List(),
    );

    return adjustedValues;
  };

  unlockAll = (callback) => {
    const { playlist } = this.state;

    const newPlaylistState = playlist.updateIn(['creatives'], (creatives) =>
      creatives.reduce((acc, c) => acc.push(c.update('locked', () => false)), List()),
    );

    this.setState({ playlist: newPlaylistState });

    if (callback) {
      callback(newPlaylistState);
    }
  };

  updateCurrentCreativeWeight = (creativeId, newValue) => {
    const { dispatch } = this.props;
    const { playlist } = this.state;

    const creatives = playlist.get('creatives');

    // Get diff value
    const newWeight = parseInt(newValue || 0);
    const editedCreativeIndex = creatives.findIndex((creative) => creative.get('id') === creativeId);
    const editedCreativePreviousWeight = playlist.getIn(['creatives', editedCreativeIndex || 0]).get('weight');
    const diff = newWeight - editedCreativePreviousWeight;

    // Calculate max available value
    const activeCreativesExceptCurrent = this.activeCreativesExceptCurrent(creativeId);
    const totalLockedWeight = this.totalLockedWeight();
    const maxValue = 100 - totalLockedWeight - activeCreativesExceptCurrent.size;

    this.setState(
      ({ playlist }) => ({
        playlist: playlist.updateIn(['creatives', editedCreativeIndex], (creative) =>
          creative.merge({
            weight: newWeight < maxValue ? newWeight : maxValue,
            locked: this.activeCreatives().size > 2,
          }),
        ),
        shouldUpdateHistory: true,
      }),
      this.updateActiveCreativeWeights(creativeId, newWeight, diff),
    );

    dispatch(setHasUnsavedChanges());
  };

  updateActiveCreativeWeights = (creativeId, newWeight, diff) => {
    const totalActiveWeightExceptCurrent = this.totalActiveWeightExceptCurrent(creativeId);
    const activeCreativesExceptCurrent = this.activeCreativesExceptCurrent(creativeId);
    const totalLockedWeight = this.totalLockedWeight();
    const targetTotal = 100 - totalLockedWeight - newWeight;

    // Generate an array of the available creatives to be updated,
    // include the new proportionally alotted values
    const updateWeightsArray = activeCreativesExceptCurrent.reduce((acc, creative) => {
      const proportion = creative.get('weight') / totalActiveWeightExceptCurrent;
      const proportionalUpdateValue = diff * proportion;
      const maxValue = 100 - totalLockedWeight - activeCreativesExceptCurrent.size;

      let newVal = creative.get('weight') - proportionalUpdateValue;

      // Restrict to between 1 and 100
      newVal = newVal < 1 ? 1 : newVal;
      newVal = newVal > 100 ? 100 : newVal;

      // Restrict to the max available value
      newVal = Math.abs(newVal) > maxValue ? maxValue : newVal;

      return acc.push(
        Map({
          id: creative.get('id'),
          weight: newVal,
        }),
      );
    }, List());

    // Adjust values to whole percentages using the largest remainder method
    const adjustedValues = this.highestRemainder(updateWeightsArray, targetTotal);
    adjustedValues.map((adjustedValue) => {
      this.setState(({ playlist }) => ({
        playlist: playlist.updateIn(
          ['creatives', playlist.get('creatives').findIndex((c) => c.get('id') === adjustedValue.get('id'))],
          (creative) => creative.update('weight', () => adjustedValue.get('weight')),
        ),
        shouldUpdateHistory: true,
      }));
    });
  };

  handleCreativeWeightLock = (creativeId) => {
    this.setState(({ playlist }) => ({
      playlist: playlist.updateIn(
        ['creatives', playlist.get('creatives').findIndex((creative) => creative.get('id') === creativeId)],
        (creative) => creative.update('locked', (bool) => !bool),
      ),
    }));
  };

  fetchAdditionalCreativeData = (creativeId) => {
    const { activeCampaignId, creatives, dispatch, frameSpecKey } = this.props;
    const creative = creatives.find((c) => c.get('id') === creativeId);
    const extraOptions = { includes: ['asset', 'prepared-asset', 'thumbnail'] };

    if (!isCreativeProcessed(creative)) {
      dispatch(fetchCreative(activeCampaignId, frameSpecKey, creativeId, extraOptions)).catch(() => Promise.resolve());
    }
  };

  handleMoveCreative = (dragId, hoverId) => {
    const { dispatch } = this.props;
    const { playlist } = this.state;

    const sortedPlaylistCreatives = playlist.get('creatives').sortBy((c) => c.get('position'));
    const draggedCreativeIndex = sortedPlaylistCreatives.findIndex((c) => c.get('id') === dragId);
    const draggedCreative = sortedPlaylistCreatives.get(draggedCreativeIndex);
    const hoveredCreativeIndex = sortedPlaylistCreatives.findIndex((c) => c.get('id') === hoverId);

    const updatedPlaylistCreatives = sortedPlaylistCreatives
      .delete(draggedCreativeIndex)
      .insert(hoveredCreativeIndex, draggedCreative)
      .reduce((reduction, creative, key) => reduction.push(creative.update('position', () => key)), new List());

    this.setState(({ playlist }) => ({
      playlist: playlist.update('creatives', () => updatedPlaylistCreatives),
    }));

    dispatch(setHasUnsavedChanges());
  };

  handleSubmitChanges = () => {
    this.unlockAll(this.dispatchUpdatePlaylist);
  };

  dispatchUpdatePlaylist = (newPlaylist) => {
    const { t, dispatch } = this.props;

    const playlistId = newPlaylist.get('id');
    const data = newPlaylist.update('is-weighted', () => 1).toJS();

    dispatch(playlistUpdate(data, playlistId)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('Playlist was successfully updated')));
      dispatch(clearHasUnsavedChanges());
      if (newPlaylist.get('is-weighted') && !newPlaylist.get('is-shuffled')) {
        dispatch(fetchComputedPlaylist(newPlaylist.get('id')));
      }
    });
  };

  getCreativePosition = (creative) => {
    const { playlist } = this.state;

    const position = playlist
      .getIn(['creatives', playlist.get('creatives').findIndex((pos) => pos.get('id') === creative.get('id'))])
      .get('position');

    return parseInt(position);
  };

  getCreativesByPositionAsc = () => {
    const { creatives } = this.props;

    return creatives.sortBy((c) => this.getCreativePosition(c));
  };

  getComputedPlaylist = () => {
    const { computedPlaylist } = this.props;
    const { playlist } = this.state;

    return computedPlaylist.map((item) =>
      item
        .set(
          'color',
          playlist
            .get('creatives')
            .filter((c) => c.get('id') === item.get('id'))
            .getIn([0, 'color']),
        )
        .set(
          'position',
          playlist
            .get('creatives')
            .filter((c) => c.get('id') === item.get('id'))
            .getIn([0, 'position']),
        ),
    );
  };

  renderPlaylistControls = () => (
    <div className={style.playlistSliderControls}>
      {this.renderSlider()}
      {this.renderControls()}
    </div>
  );

  renderSlider = () => {
    const { playlist } = this.state;

    const weights = this.getCreativeWeights(playlist);

    return (
      <div className={style.sliderContainer}>
        <div className={style.sliderBackground}>
          <div className={style.sliderScale}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <div key={num}>
                <span>{num * 10}</span>
              </div>
            ))}
          </div>
          <span className={style.sliderLast}>100</span>
        </div>
        <PlaylistSlider
          pushable
          stepIsMin
          className={style.rcSlider}
          value={weights.toJS()}
          count={weights.size}
          step={1}
          tipFormatter={(value) => `${value}%`}
          trackStyle={this.sliderColorsArray()}
          handleStyle={this.sliderColorsArray()}
          railStyle={{ backgroundColor: playlist.getIn(['creatives', 0, 'color']) }}
          onChange={this.handleSliderChange}
          onAfterChange={this.handleUpdateChangeHistory}
        />
      </div>
    );
  };

  renderComputedPlaylist = () => {
    const { isFetchingComputedPlaylist, t } = this.props;
    const { playlist } = this.state;

    if (!playlist.get('is-weighted')) return;

    let heading;
    let computedPlaylist;
    const containerClass = cx(style.computedPlayListContainer, {
      [style.computedPlaylistFetching]: !playlist.get('is-shuffled') && isFetchingComputedPlaylist,
    });

    if (playlist.get('is-shuffled')) {
      heading = <span>{t('Creatives will playout in a shuffled order using the defined weightings')}</span>;
    } else {
      heading = <span>{`${t('Playout order')}:`}</span>;
      computedPlaylist = (
        <ComputedPlaylist isFetching={isFetchingComputedPlaylist} computedPlaylist={this.getComputedPlaylist()} />
      );
    }

    return (
      <div className={containerClass}>
        <Heading tag={HeadingTags.H4}>{heading}</Heading>
        {computedPlaylist}
      </div>
    );
  };

  renderControls = () => {
    const { t } = this.props;
    const { changeHistory, playlist } = this.state;

    return (
      <div className={style.controlsContainer}>
        <Action
          className={style.actionDistribute}
          icon={<Icon iconType={IconTypes.LINE_STYLE} />}
          onClick={this.handleDistributeEquallyAction}
          tooltip={t('Distribute percentages equally')}
        />
        <Action
          className={style.actionUndo}
          icon={<Icon iconType={IconTypes.UNDO} />}
          onClick={this.handleUndoChanges}
          tooltip={t('Undo')}
          disabled={changeHistory.size < 1}
        />
        <Switch
          label={playlist.get('is-shuffled') ? t('Shuffled') : t('Not Shuffled')}
          checked={playlist.get('is-shuffled', false)}
          onChange={this.handleShuffle}
        />
      </div>
    );
  };

  renderCreatives() {
    const { activeCampaignId } = this.props;
    const { playlist } = this.state;

    const creatives = this.getCreativesByPositionAsc();
    const unlockedCreatives = playlist.get('creatives').filter((c) => !c.get('locked'));

    return creatives.map((c) => {
      const isBackgroundProcessing = !isCreativeProcessed(c);
      const creativeIndex = playlist.get('creatives').findIndex((creative) => creative.get('id') === c.get('id'));
      const creativeWeight = playlist.getIn(['creatives', creativeIndex]).get('weight');
      const position = playlist.getIn(['creatives', creativeIndex]).get('position');
      const color = playlist.getIn(['creatives', creativeIndex]).get('color');
      const locked = playlist.getIn(['creatives', creativeIndex]).get('locked');
      const isViewable = !isBackgroundProcessing;
      const unlockedIndex = unlockedCreatives.findIndex((creative) => creative.get('id') === c.get('id'));
      let showLock = true;

      if (unlockedCreatives.size <= 2 && unlockedIndex >= 0) {
        showLock = false;
      }

      return (
        <PlaylistCreativeRow
          activeCampaignId={activeCampaignId}
          creative={c}
          creativeUrl={`campaigns/${activeCampaignId}/creative/${c.get('id')}`}
          isDraggable={creatives.size > 1}
          isViewable={isViewable}
          weight={creativeWeight}
          position={position}
          locked={locked}
          showLock={showLock}
          key={c.get('id')}
          onMoved={this.handleMoveCreative}
          onLoad={() => {
            this.fetchAdditionalCreativeData(c.get('id'));
          }}
          onSubmitDragChanges={this.handleSubmitChanges}
          onHandlePercentInputChange={this.handlePercentInputChange}
          onHandleCreativeWeightLock={this.handleCreativeWeightLock}
          color={color}
        />
      );
    });
  }

  renderCreativeReorderActions() {
    const { isPlaylistUpdating, hasUnsavedChanges } = this.props;

    return (
      <CreativeReorderActions
        isUpdating={isPlaylistUpdating}
        isActive={hasUnsavedChanges}
        onDiscard={this.handleDiscardChanges}
        onSave={this.handleSubmitChanges}
      />
    );
  }

  render() {
    return (
      <PlaylistCreativeRows>
        {this.renderComputedPlaylist()}
        {this.renderPlaylistControls()}
        {this.renderCreatives()}
        {this.renderCreativeReorderActions()}
      </PlaylistCreativeRows>
    );
  }
}

const mapStateToProps = (state) => ({
  activeCampaignId: state.campaign.activeCampaignId,
  isPlaylistUpdating: state.campaignPlaylists.isPlaylistUpdating,
  user: state.auth.user,
  isFetchingComputedPlaylist: state.campaignPlaylists.isFetchingComputedPlaylist,
  computedPlaylist: state.campaignPlaylists.computedPlaylist,
  activeFrameSpecId: state.campaignPlaylists.activeFrameSpecId,
});

export default withNamespaces(['common', 'campaignPlaylists'], { wait: false })(
  connect(mapStateToProps)(PlaylistContainer),
);

PlaylistContainer.propTypes = {
  activeCampaignId: PropTypes.string.isRequired,
  isPlaylistUpdating: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  imageFallbackSrc: PropTypes.string,
  frameSpecification: PropTypes.instanceOf(Map).isRequired,
  frameSpecKey: PropTypes.number.isRequired,
  playlist: PropTypes.instanceOf(Map).isRequired,
  creatives: PropTypes.instanceOf(List).isRequired,
  ruleSet: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  isFetchingComputedPlaylist: PropTypes.bool.isRequired,
  computedPlaylist: PropTypes.instanceOf(List).isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  activeFrameSpecId: PropTypes.bool.isRequired,
};
