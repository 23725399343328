import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import ConfirmButton from 'assets/components/presentational/ConfirmButton';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import { ButtonThemes } from 'assets/components/presentational/Button';
import style from '../rowActionsDialogActions.scss';

class DialogActionItem extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isApplyActive: true,
      isRemoveActive: true,
    };
  }

  handleApplyActionItem = () => {
    const { onApplyActionItem } = this.props;
    this.setState({ isApplyActive: false, isRemoveActive: true });
    return onApplyActionItem();
  };

  handleRemoveActionItem = () => {
    const { onRemoveActionItem } = this.props;
    this.setState({ isApplyActive: true, isRemoveActive: false });
    return onRemoveActionItem();
  };

  render() {
    const { actionItem, isUpdating, t } = this.props;
    const { isApplyActive, isRemoveActive } = this.state;

    return (
      <div className={style.actionItem}>
        <div className={style.actionName}>{actionItem.get('name')}</div>
        <div className={style.actionButtons}>
          <ConfirmButton
            className={style.applyButton}
            disabled={isUpdating || !isApplyActive}
            onConfirmClick={this.handleApplyActionItem}
            theme={ButtonThemes.PRIMARY}
            label={t('Apply')}
            confirmLabel={t('Are you sure?')}
            icon={<Icon iconType={IconTypes.ADD} />}
            buttonTheme={ButtonThemes.PRIMARY}
            confirmButtonTheme={ButtonThemes.WARNING}
            showProgress={false}
          />
          <ConfirmButton
            className={style.removeButton}
            disabled={isUpdating || !isRemoveActive}
            onConfirmClick={this.handleRemoveActionItem}
            theme={ButtonThemes.PRIMARY}
            label={t('Remove')}
            confirmLabel={t('Are you sure?')}
            icon={<Icon iconType={IconTypes.REMOVE} />}
            buttonTheme={ButtonThemes.PRIMARY}
            confirmButtonTheme={ButtonThemes.WARNING}
            showProgress={false}
          />
        </div>
      </div>
    );
  }
}

export default withNamespaces(['common'], { wait: false })(DialogActionItem);

DialogActionItem.propTypes = {
  actionItem: PropTypes.instanceOf(Map),
  isUpdating: PropTypes.bool,
  onApplyActionItem: PropTypes.func,
  onRemoveActionItem: PropTypes.func,
  t: PropTypes.func,
};

DialogActionItem.defaultProps = {
  actionItem: {},
  isUpdating: false,
  onApplyActionItem: null,
  onRemoveActionItem: null,
  t: null,
};
