import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import ProgressBar from 'components/patterns/ProgressBar';
import Input from 'components/patterns/Input';
import ResultsList from 'assets/components/presentational/Users/PermissionsCampaign/ResultsList';
import ViewPane from 'assets/components/presentational/Users/PermissionsCampaign/ViewPane';

import style from './permissionsCampaign.scss';

class PermissionsCampaign extends Component {
  renderProgressBar() {
    return (
      <div className={style.progress}>
        <ProgressBar />
      </div>
    );
  }

  renderHeader() {
    const { t, filterSearchValue, handleFilterSearchChange } = this.props;

    return (
      <div className={style.header}>
        <div className={style.filterSearch}>
          <Input
            label={t('Filter campaigns')}
            hint={t('Filter by campaign, client name, booking ref, or campaign manager email address')}
            type="text"
            value={filterSearchValue}
            onChange={handleFilterSearchChange}
          />
        </div>
      </div>
    );
  }

  renderContent() {
    const {
      t,
      isFetchingCampaigns,
      isFetchingUserSettingsCampaign,
      isUpdatingUserSettingsCampaign,
      campaigns,
      permissions,
      activeInPane,
      showDefaultPermissions,
    } = this.props;
    const { handleCheckboxChange, handleResultsListClick } = this.props;

    return (
      <div className={style.content}>
        <ResultsList
          t={t}
          isFetchingCampaigns={isFetchingCampaigns}
          campaigns={campaigns}
          activeInPane={activeInPane}
          handleResultsListClick={handleResultsListClick}
          showDefaultPermissions={showDefaultPermissions}
        />
        <ViewPane
          t={t}
          isFetchingUserSettingsCampaign={isFetchingUserSettingsCampaign}
          isUpdatingUserSettingsCampaign={isUpdatingUserSettingsCampaign}
          permissions={permissions}
          activeInPane={activeInPane}
          onCheckboxChange={handleCheckboxChange}
          showDefaultPermissions={showDefaultPermissions}
        />
      </div>
    );
  }

  render() {
    const { isFetchingCampaignsPermissionsData } = this.props;

    if (isFetchingCampaignsPermissionsData) {
      return this.renderProgressBar();
    }

    return (
      <div className={style.component}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

export default PermissionsCampaign;

PermissionsCampaign.propTypes = {
  t: PropTypes.func.isRequired,
  campaigns: PropTypes.instanceOf(List).isRequired,
  isFetchingCampaignsPermissionsData: PropTypes.bool.isRequired,
  isFetchingCampaigns: PropTypes.bool.isRequired,
  isFetchingUserSettingsCampaign: PropTypes.bool.isRequired,
  isUpdatingUserSettingsCampaign: PropTypes.bool.isRequired,
  permissions: PropTypes.instanceOf(List).isRequired,
  activeInPane: PropTypes.instanceOf(Map).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleResultsListClick: PropTypes.func.isRequired,
  handleFilterSearchChange: PropTypes.func.isRequired,
  filterSearchValue: PropTypes.string,
  showDefaultPermissions: PropTypes.bool,
};

PermissionsCampaign.defaultProps = {
  showDefaultPermissions: false,
};
