import config from 'app-config';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { isEqual, reject } from 'lodash';
import { withNamespaces } from 'react-i18next';
import { withGoogleMap, GoogleMap, Rectangle, withScriptjs } from 'react-google-maps';
import { SearchBox } from 'react-google-maps/lib/components/places/SearchBox';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { updateUserLocation } from 'actions/auth';
import style from './locationForm.scss';

// Map an array of location arrays into an array of location strings, e.g. [1,2,3,4] becomes ["1,2,3,4"]
// Used when sending the save request tot he API
export const mapLocationArraysToStrings = (locations) => {
  if (!Array.isArray(locations)) {
    return locations;
  }

  locations = mapLocationStringsToArray(locations);

  return locations.map((location) => location.join(','));
};

// Map an array of location strings into an array of location arrays, e.g. ["1,2,3,4"] becomes [1,2,3,4]
const mapLocationStringsToArray = (locations) => {
  if (!Array.isArray(locations)) {
    return locations;
  }

  return locations.map((location) => {
    // If location is a comma separated list, transform to array
    if (location.length === 1) {
      location = location.split(',');
    }

    return mapLocationCoordinatesToFloat(location);
  });
};

const mapLocationCoordinatesToFloat = (location) => {
  // If location is a comma separated list, transform to array
  if (!Array.isArray(location)) {
    location = location.split(',');
  }

  return location.map((coordinate) => parseFloat(coordinate));
};

const rectangleOptions = {
  fillColor: '#50b2e3',
  strokeColor: '#3e7fc7',
  strokeWeight: 1,
};

const MapComponent = withScriptjs(
  withGoogleMap(
    ({
      locations,
      center,
      zoom,
      handleZoomChanged,
      handleCenterChanged,
      handlePlacesChanged,
      handleRectangleComplete,
      handleRectangleClick,
      getMapRef,
      getSearchBoxRef,
      onIdle,
    }) => (
      <GoogleMap
        center={center}
        zoom={zoom}
        ref={(el) => getMapRef(el)}
        onZoomChanged={handleZoomChanged}
        onCenterChanged={handleCenterChanged}
        onIdle={onIdle}
      >
        <SearchBox
          ref={(el) => getSearchBoxRef(el)}
          onPlacesChanged={handlePlacesChanged}
          controlPosition={window.google.maps.ControlPosition.TOP_RIGHT}
        >
          <input type="text" placeholder="Search&hellip;" className={style.searchBox} />
        </SearchBox>
        <DrawingManager
          defaultDrawingMode={window.google.maps.drawing.OverlayType.RECTANGLE}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.RECTANGLE],
            },
            rectangleOptions,
          }}
          onRectangleComplete={handleRectangleComplete}
        />
        {Array.isArray(locations) &&
          locations.map((location, key) => (
            <Rectangle
              bounds={{ north: location[0], south: location[2], east: location[1], west: location[3] }}
              onClick={() => handleRectangleClick(location)}
              options={rectangleOptions}
              key={`${location}${key}`} // eslint-disable-line react/no-array-index-key
            />
          ))}
      </GoogleMap>
    ),
  ),
);

class LocationForm extends Component {
  constructor(props, context) {
    super(props, context);

    const { defaultLocation } = this.props;

    this.state = {
      center: {
        lat: defaultLocation.get('latitude'),
        lng: defaultLocation.get('longitude'),
      },
      zoom: 14,
      isMapsLoaded: false,
    };

    this.googleMapComponent = null;
    this.searchBox = null;
  }

  componentDidMount = () => {
    const locationsField = this.getLocationsField();
    const locations = mapLocationArraysToStrings(locationsField.value);

    // Locations are fetched as an array of coordinates, however we need to send them back as comma separated strings
    locationsField.onChange(locations);
  };

  componentDidUpdate(prevProps, prevState) {
    const { ruleType } = this.props;
    const { isMapsLoaded } = this.state;

    if (prevProps.ruleType.get('is-collapsed', false) !== ruleType.get('is-collapsed', false)) {
      const { dispatch } = this.props;

      dispatch(updateUserLocation());
    }

    if (prevState.isMapsLoaded !== isMapsLoaded) {
      this.fitBounds();
    }
  }

  handleRectangleComplete = (rectangle) => {
    const locationsField = this.getLocationsField();
    const newLocation = [
      rectangle
        .getBounds()
        .getNorthEast()
        .lat(),
      rectangle
        .getBounds()
        .getNorthEast()
        .lng(),
      rectangle
        .getBounds()
        .getSouthWest()
        .lat(),
      rectangle
        .getBounds()
        .getSouthWest()
        .lng(),
    ];
    const locations = Array.isArray(locationsField.value) ? locationsField.value : [];

    locations.push(newLocation);

    this.fitBounds();
    // Add listener for clicking a new rectangle, which we'll use to delete
    window.google.maps.event.addListener(rectangle, 'click', () => {
      // Remove from our google map instance
      rectangle.setMap(null);

      this.handleRectangleOverlayClick(newLocation);
    });

    locationsField.onChange(mapLocationArraysToStrings(locations));
  };

  handleRectangleOverlayClick = (location) => {
    const locationsField = this.getLocationsField();
    let locations = mapLocationArraysToStrings(locationsField.value);
    location = mapLocationArraysToStrings([location])[0];
    locations = reject(locations, (l) => isEqual(l, location));
    locationsField.onChange(locations);
  };

  getLocationsField = () => {
    const { fields } = this.props;
    return fields[0];
  };

  getLocations = () => {
    let locations = this.getLocationsField();
    locations = mapLocationStringsToArray(locations.value);
    return locations.map((location) => ({
      north: location[0],
      south: location[2],
      east: location[1],
      west: location[3],
    }));
  };

  fitBounds = () => {
    const locations = this.getLocations();
    const bounds = new window.google.maps.LatLngBounds();

    if (locations.length) {
      locations.forEach((location) => {
        const sw = new window.google.maps.LatLng(location.south, location.west);
        const ne = new window.google.maps.LatLng(location.north, location.east);
        bounds.extend(sw);
        bounds.extend(ne);
      });

      if (this.googleMapComponent) {
        this.googleMapComponent.fitBounds(bounds);
      }
    }
  };

  handleSearchBoxMounted = (searchBox) => {
    this.searchBox = searchBox;
  };

  handlePlacesChanged = () => {
    const { center } = this.state;
    const places = this.searchBox.getPlaces();

    // Add a marker for each place returned from search bar
    const markers = places.map((place) => ({
      position: place.geometry.location,
    }));

    // Set markers; set map center to first search result
    const mapCenter = markers.length > 0 ? markers[0].position : center;

    this.setState({
      center: mapCenter,
    });
  };

  renderDisabledMap = () => {
    const { ruleType } = this.props;
    if (!ruleType.get('is-selected')) {
      return <div className={style.mapDisabled} />;
    }
    return null;
  };

  handleZoomChanged = () => {
    if (!this.googleMapComponent) {
      return;
    }
    const newZoom = this.googleMapComponent.getZoom();
    this.setState({ zoom: newZoom });
  };

  handleCenterChanged = () => {
    if (!this.googleMapComponent) {
      return;
    }
    this.setState({ center: this.googleMapComponent.getCenter() });
  };

  render() {
    const { t } = this.props;
    const { zoom, center, isMapsLoaded } = this.state;
    const { key, version, url } = config.googleMapsJavascriptApi;
    const libraries = ['drawing', 'places'].join(',');
    const googleMapURL = `${url}?v=${version}&libraries=${libraries}&key=${key}`;

    let locations = this.getLocationsField();
    locations = mapLocationStringsToArray(locations.value);

    return (
      <div className={style.component}>
        <span className={style.prompt}>{t('Location tip')}</span>
        <div className={style.mapContainer}>
          {this.renderDisabledMap()}
          <MapComponent
            mapElement={<div className={style.map} />}
            containerElement={<div style={{ height: '100%' }} />}
            googleMapURL={googleMapURL}
            loadingElement={<div style={{ height: `100%` }} />}
            locations={locations}
            center={center}
            zoom={zoom}
            handleZoomChanged={this.handleZoomChanged}
            handleCenterChanged={this.handleCenterChanged}
            handlePlacesChanged={this.handlePlacesChanged}
            handleRectangleComplete={this.handleRectangleComplete}
            handleRectangleClick={this.handleRectangleOverlayClick}
            getMapRef={(el) => {
              this.googleMapComponent = el;
            }}
            getSearchBoxRef={(el) => {
              this.searchBox = el;
            }}
            onIdle={() => {
              if (!isMapsLoaded) {
                this.setState({ isMapsLoaded: true });
              }
            }}
          />
        </div>
      </div>
    );
  }
}

LocationForm.propTypes = {
  defaultLocation: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'rules'], { wait: false })(connect()(LocationForm));
