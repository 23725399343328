import { handleActions } from 'redux-actions';
import { ACTION_TYPES as SEARCH } from './actions';
import { SEARCH_TYPES } from './constants';

const initialState = Object.values(SEARCH_TYPES).reduce(
  (state, searchType) => ({
    ...state,
    [searchType]: {
      query: '',
      hits: [],
    },
  }),
  {},
);

const reducer = handleActions(
  {
    [SEARCH.CLEAR_RESULTS]: (state, action) => ({
      ...state,
      [action.payload.searchType]: {
        query: state[action.payload.searchType].query,
        hits: [],
      },
    }),
    [SEARCH.STORE_RESULTS]: (state, action) => ({
      ...state,
      [action.payload.searchType]: {
        query: state[action.payload.searchType].query,
        hits: action.payload.hits,
      },
    }),
    [SEARCH.STORE_QUERY]: (state, action) => ({
      ...state,
      [action.payload.searchType]: {
        query: action.payload.query,
        hits: state[action.payload.searchType].hits,
      },
    }),
  },
  initialState,
);

export default reducer;

// exported for testing
export { initialState };
