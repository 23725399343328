import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import { uploadsPropTypes } from 'store/uploads/propTypes';
import { userHasCampaignPermission } from 'store/user/helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import FrameSpecifications from 'assets/components/presentational/Campaign/FrameSpecifications';
import FrameSpecification from 'assets/components/presentational/Campaign/FrameSpecification';
import CreativeRows from 'assets/components/containers/Campaign/Creative/CreativeRows';

import style from './creative.scss';
import { BROADCAST_EVENTS, privateUserChannel, broadcasting } from '../../../../../modules/Broadcasting/broadcasting';

class BaseCreative extends Component {
  constructor(props) {
    super(props);
    this.broadcastingChannel = null;
  }

  async componentDidMount() {
    const { campaignId, setActiveSection, fetchCampaign, fetchFrameSpecifications, setActiveCampaignId } = this.props;

    setActiveSection('creative');
    const campaign = await fetchCampaign(campaignId, { includes: ['rule-sets', 'content-types'] });
    await fetchFrameSpecifications(campaignId);
    setActiveCampaignId((campaign && campaign.get('id')) || campaignId);

    this.subscribeToNotificationChannels();
  }

  async componentDidUpdate(prevProps) {
    const { activeFrameSpecId, campaignId, fetchFrameSpecifications, fetchCampaign } = this.props;
    if (prevProps.campaignId !== campaignId) {
      const includes = ['content-types', 'rule-sets'];
      await fetchCampaign(campaignId, { includes });
      await fetchFrameSpecifications(campaignId);
    }

    if (!this.expandFrameSpecById(activeFrameSpecId)) {
      this.expandFirstFrameSpec();
    }
  }

  componentWillUnmount() {
    const { resetUploads } = this.props;
    resetUploads();
  }

  subscribeToNotificationChannels = () => {
    const { user } = this.props;
    if (!user.size) {
      return;
    }

    this.broadcastingChannel = broadcasting()
      .setJwt(localStorage.getItem('jwt'))
      .subscribe(privateUserChannel(user.get('id')))
      .bind(BROADCAST_EVENTS.CREATIVE_UPLOAD_SUCCESSFUL, this.handleBroadcastingCreativeUploadSuccessful)
      .bind(BROADCAST_EVENTS.CREATIVE_UPLOAD_FAILED, this.handleBroadcastingCreativeUploadFailed);
  };

  expandFirstFrameSpec = () => {
    const { frameSpecifications } = this.props;
    if (frameSpecifications.size) {
      this.handleFrameSpecClick(frameSpecifications.first());
    }
  };

  expandFrameSpecById = (id) => {
    if (id == null) {
      return false;
    }

    const { frameSpecifications } = this.props;
    const frameSpecification = frameSpecifications.find((f) => f.get('id') === id);
    if (frameSpecification) {
      this.handleFrameSpecClick(frameSpecification);
      return true;
    }

    return false;
  };

  handleBroadcastingCreativeUploadSuccessful = (payload) => {
    // Confirm that this creative was uploaded to this campaign
    // This prevents is being added to campaigns open in other tabs
    const {
      uploads,
      frameSpecifications,
      replaceCreative,
      addCreative,
      removeUpload,
      addUpload,
      activeCampaignId,
    } = this.props;
    const { creative_id: creativeId, frame_specification_id: frameSpecificationId, campaign_id: campaignId } = payload;

    if (activeCampaignId !== campaignId) {
      return;
    }

    const frameSpecificationKey = frameSpecifications.findIndex((f) => f.get('id') === frameSpecificationId);
    const frameSpecification = frameSpecifications.get(frameSpecificationKey);
    const upload = uploads.find((u) => u.creativeId === creativeId);
    // We fetch the asset to generate the download link,
    // the prepared-asset to check if the creative has been fully processed and
    // the thumbnail for display purposes
    if (upload) {
      if (upload.errors.length) {
        return;
      }
      const { uniqueIdentifier, oldCreativeId } = upload;
      if (oldCreativeId) {
        const oldCreative = frameSpecification
          .get('creatives', List())
          .find((creative) => creative.get('id') === oldCreativeId);
        replaceCreative(frameSpecificationKey, creativeId, oldCreative, campaignId, {
          includes: ['rule-sets', 'content-types'],
        });
      } else {
        addCreative(creativeId, campaignId, frameSpecificationKey);
      }
      removeUpload(uniqueIdentifier);
      removeUpload(creativeId);
    } else {
      // In some cases it may happen that the broadcasting event comes before
      // the endpoint that we call to upload the file returns a response
      addUpload(creativeId, {
        creativeId,
      });
    }
  };

  handleBroadcastingCreativeUploadFailed = (payload) => {
    const { uploads, updateUpload, notifyError, addUpload, t } = this.props;
    const { creative_id: creativeId, exception, filename } = payload;
    const upload = uploads.find((u) => u.creativeId === creativeId);

    if (upload) {
      const { uniqueIdentifier } = upload;

      updateUpload(uniqueIdentifier, {
        errors: [
          {
            code: exception,
          },
        ],
      });
      notifyError(t(exception), { filename });
    } else {
      addUpload(creativeId, {
        creativeId,
        errors: [
          {
            exception,
            details: '',
            status: null,
            trace: [],
          },
        ],
      });
    }
  };

  handleFrameSpecClick = (frameSpec) => {
    const { setActiveFrameSpec } = this.props;
    setActiveFrameSpec(frameSpec.get('id'));
  };

  renderFrameSpecs() {
    const {
      activeCampaignId,
      activeFrameSpecId,
      areCreativesUpdating,
      campaign,
      emptyFrameSpecsMessage,
      fetchAdditionalCreativeData,
      frameSpecifications,
      isFetching,
      user,
      canUserReplaceCreative,
      handleCreativeDeleteConfirmClick,
      previewUrl,
      shouldShowRowActions,
      allowedRowActions,
      fetchCreativesByModerationStatus,
      showModerationSwitch,
    } = this.props;
    if (isFetching) {
      return <ProgressBar />;
    }

    return (
      <FrameSpecifications frameSpecifications={frameSpecifications} emptyFrameSpecsMessage={emptyFrameSpecsMessage}>
        {frameSpecifications.map((f, i) => (
          <FrameSpecification
            active={f.get('id') === activeFrameSpecId}
            allowNewUploads={userHasCampaignPermission(user, activeCampaignId, 'upload_creative')}
            campaignId={activeCampaignId}
            frameSpecification={f}
            frameSpecificationIndex={i}
            key={f.get('id')}
            onClick={this.handleFrameSpecClick}
            fetchCreativesByModerationStatus={fetchCreativesByModerationStatus}
            showModerationSwitch={showModerationSwitch}
            campaign={campaign}
          >
            <CreativeRows
              creatives={f.get('creatives', List())}
              frameSpecification={f}
              frameSpecificationIndex={i}
              baseCreativeUrl={previewUrl}
              canUserReplaceCreative={canUserReplaceCreative}
              onDelete={handleCreativeDeleteConfirmClick}
              shouldShowRowActions={f.get('creatives', List()).size > 0 && shouldShowRowActions}
              activeCampaignId={activeCampaignId}
              areCreativesUpdating={areCreativesUpdating}
              campaign={campaign}
              user={user}
              onLoad={(creativeId) => {
                fetchAdditionalCreativeData(creativeId);
              }}
              allowedRowActions={allowedRowActions}
            />
          </FrameSpecification>
        ))}
      </FrameSpecifications>
    );
  }

  render() {
    const { renderHeading } = this.props;

    return (
      <div className={style.component}>
        <div>
          {renderHeading()}
          {this.renderFrameSpecs()}
        </div>
      </div>
    );
  }
}

BaseCreative.propTypes = {
  activeCampaignId: PropTypes.string.isRequired,
  activeFrameSpecId: PropTypes.string.isRequired,
  addCreative: PropTypes.func,
  addUpload: PropTypes.func,
  areCreativesUpdating: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  campaignId: PropTypes.string.isRequired,
  emptyFrameSpecsMessage: PropTypes.string,
  fetchCampaign: PropTypes.func.isRequired,
  fetchFrameSpecifications: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  isFetching: PropTypes.bool.isRequired,
  notifyError: PropTypes.func,
  previewUrl: PropTypes.string.isRequired,
  removeUpload: PropTypes.func,
  renderHeading: PropTypes.func.isRequired,
  replaceCreative: PropTypes.func,
  resetUploads: PropTypes.func,
  setActiveFrameSpec: PropTypes.func.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  setActiveCampaignId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateUpload: PropTypes.func,
  uploads: uploadsPropTypes,
  user: PropTypes.instanceOf(Map).isRequired,
  canUserReplaceCreative: PropTypes.func,
  handleCreativeDeleteConfirmClick: PropTypes.func,
  fetchAdditionalCreativeData: PropTypes.func,
  shouldShowRowActions: PropTypes.bool,
  allowedRowActions: PropTypes.arrayOf(PropTypes.string),
  fetchCreativesByModerationStatus: PropTypes.func.isRequired,
  showModerationSwitch: PropTypes.bool,
};

BaseCreative.defaultProps = {
  emptyFrameSpecsMessage: 'There are no frames available for this campaign',
  // props that are used in dropzone and normal.
  addCreative: () => {},
  addUpload: () => {},
  canUserReplaceCreative: () => {},
  handleCreativeDeleteConfirmClick: () => {},
  notifyError: () => {},
  removeUpload: () => {},
  replaceCreative: () => {},
  resetUploads: () => {},
  updateUpload: () => {},
  uploads: [],
  // props that are only in used in dropzone and liteModeration.
  fetchAdditionalCreativeData: () => {},
  shouldShowRowActions: false,
  allowedRowActions: [],
  showModerationSwitch: false,
};

export default withNamespaces(['common', 'creative', 'upload', 'errorCodes'], { wait: false })(BaseCreative);
