import React from 'react';
import PropTypes from 'prop-types';

import Footer from 'components/common/Footer';

const Public = ({ children }) => (
  <div>
    {children}
    <Footer />
  </div>
);

Public.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Public;
