/* global VERSION */
/* global SHORTHASH */
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import logo from 'assets/images/logos/VIOOH-logo.svg';
import config from 'app-config';
import style from './Footer.scss';

const { name, supportUrl, supportEmail } = config.app;
const copyrightYear = new Date().getFullYear();
const version = (t) => `${t('Version')} ${VERSION}-${SHORTHASH}`;

const Footer = ({ t }) => (
  <footer className={style.footerNew}>
    <img src={logo} className={style.logo} alt={name} />
    <p>
      &copy; {copyrightYear} - {version(t)}
    </p>
    <p className={style.support}>
      <a href={`mailto:${supportEmail}`}>{t('Email Support')}</a>
      &nbsp;|&nbsp;
      <a target="_blank" href={supportUrl} rel="noreferrer">
        {t('Support Portal')}
      </a>
    </p>
  </footer>
);

export default withNamespaces(['common'], { wait: false })(Footer);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};
