import Request from 'modules/API/request';
import { begin, end, ACTIVITIES } from 'store/activity';
import { notifyApiError } from 'actions/notify';

export const PUBLICATIONS_STORE = 'PUBLICATIONS_STORE';

export const storePublications = (publications) => ({
  type: PUBLICATIONS_STORE,
  payload: {
    publications,
  },
});

export const fetchPublications = (campaignId) => async (dispatch) => {
  dispatch(begin(ACTIVITIES.FETCH_PUBLICATIONS));
  try {
    const response = await Request.send({
      endpoint: `campaigns/${campaignId}/publications`,
      method: 'GET',
    });

    const publications = response.data.body.parsed;

    dispatch(storePublications(publications));
    dispatch(end(ACTIVITIES.FETCH_PUBLICATIONS));
  } catch (error) {
    dispatch(notifyApiError(error));
    dispatch(end(ACTIVITIES.FETCH_PUBLICATIONS));
  }
};

export const publish = (campaignId) => async (dispatch) => {
  try {
    await Request.send({
      endpoint: `campaigns/${campaignId}/publications`,
      method: 'POST',
    });
  } catch (error) {
    dispatch(notifyApiError(error));
  }

  dispatch(fetchPublications(campaignId));
};
