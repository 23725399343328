import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import ROUTES from 'modules/Constants/routes';
import { showCreateCampaignForm } from 'actions/campaign';
import { checkCanCreateCampaigns } from 'actions/auth';
import { fetchUserPermissions as fetchUserPermissionsAction } from 'actions/user';
import { showProfileDrawer } from 'actions/users';

import { IconButton } from 'react-toolbox/lib/button';
import Input from 'components/patterns/Input';
import Heading, { HeadingSizes } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import ProgressBar from 'components/patterns/ProgressBar';
import Link from 'assets/components/presentational/Link';
import BackLink from 'assets/components/presentational/Header/BackLink';
import { redirect, triggerBodyClickToClose } from 'modules/Helpers';
import { userCanCreateCampaigns, userCanEditFrames, userCanEditUsers } from 'store/user/helpers';

import Logo from 'assets/components/presentational/Logo';

import style from './Header.scss';

const cx = classnames.bind(style);
const headerSearchId = 'header-search-input';

class Header extends Component {
  state = {
    settingsMenuOpen: false,
    notificationsMenuOpen: false,
  };

  constructor() {
    super();
    this.clickOutsideElements = {
      searchInput: null,
      searchResults: null,
      settingsMenu: null,
      notificationsMenu: null,
    };
  }

  componentDidMount() {
    const { userIsAuthenticated, authUser } = this.props;

    document.addEventListener('click', this.handleDocumentClick);

    if (userIsAuthenticated) {
      this.fetchUserPermissions(authUser.get('id'));
    }
  }

  componentDidUpdate(prevProps) {
    const { userIsAuthenticated: prevUserIsAuthenticated } = prevProps;
    const { authUser, userIsAuthenticated } = this.props;

    if (!prevUserIsAuthenticated && userIsAuthenticated) {
      this.fetchUserPermissions(authUser.get('id'));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  onKeyUp = (event) => {
    if (event.key === 'Escape') {
      const { clearSearchCampaigns } = this.props;
      clearSearchCampaigns();
      return;
    }

    const { searchHits } = this.props;
    if (event.key === 'Enter' && searchHits.length) {
      redirect(`/campaigns/${searchHits[0].id}`);
    }
  };

  handleDocumentClick = (event) => {
    const clickedElement = event.target;

    const elementsThatKeepsMenuOpen = Object.values(this.clickOutsideElements);

    for (let i = 0; i < elementsThatKeepsMenuOpen.length; i++) {
      if (elementsThatKeepsMenuOpen[i] && elementsThatKeepsMenuOpen[i].contains(clickedElement)) {
        return;
      }
    }

    this.closeMenus();
  };

  openMenu = (menuName) => {
    // eslint-disable-next-line react/destructuring-assignment
    const alreadyOpened = this.state[`${menuName}Open`];
    if (alreadyOpened) {
      this.closeMenus();
      return;
    }

    this.closeMenus();
    this.setState({
      [`${menuName}Open`]: true,
    });
  };

  closeMenus() {
    const { clearSearchCampaigns } = this.props;
    clearSearchCampaigns();
    this.setState({
      settingsMenuOpen: false,
      notificationsMenuOpen: false,
    });
  }

  fetchUserPermissions = (userId) => {
    const { dispatch, showSettings } = this.props;

    if (showSettings) {
      dispatch(fetchUserPermissionsAction(userId, 'App\\Models\\BusinessUnit'));
    }
  };

  handleViewProfileClick = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(showProfileDrawer());
  };

  renderSearchResults() {
    const { searchHits } = this.props;

    if (searchHits.length) {
      return (
        <div
          ref={(el) => {
            this.clickOutsideElements.searchResults = el;
          }}
        >
          {searchHits.map((hit, key) => this.renderSearchResult(hit, key))}
        </div>
      );
    }

    return this.renderNoSearchResults();
  }

  renderSearchResult(searchHit, key) {
    const { clearSearchCampaigns, t } = this.props;

    return (
      <div className={style.menuItem} key={key}>
        {searchHit.client && (
          <Heading tag="span" size={HeadingSizes.SMALLEST} className={style.searchItem}>
            {searchHit.client}
          </Heading>
        )}
        <Link className={style.searchItem} onClick={clearSearchCampaigns} to={`/campaigns/${searchHit.id}`}>
          {searchHit.name}
        </Link>
        {searchHit.starts_at && searchHit.ends_at && (
          <small className={style.searchItem}>
            {`${moment.utc(searchHit.starts_at).format('DD MMM YYYY')} - ${moment
              .utc(searchHit.ends_at)
              .format('DD MMM YYYY')}`}
          </small>
        )}
        {searchHit.booking_engine_id && (
          <small className={style.searchItem}>{`${t('Booking reference')}: ${searchHit.booking_engine_id}`}</small>
        )}
      </div>
    );
  }

  renderSearchProgress() {
    const { isFetchingSearchResults } = this.props;

    if (!isFetchingSearchResults) {
      return null;
    }

    return (
      <div className={style.menuItem}>
        <ProgressBar />
      </div>
    );
  }

  renderNoSearchResults() {
    const { t } = this.props;

    return <div className={style.menuItemNoResults}>{t('Your search did not match any results')}</div>;
  }

  renderSearchInput() {
    const { showSearch, searchQuery, onSearchQueryChange, searchHits, t } = this.props;

    if (!showSearch) {
      return null;
    }

    return (
      <div
        className={style.columnSearch}
        ref={(el) => {
          this.clickOutsideElements.searchInput = el;
        }}
      >
        <Input
          id={headerSearchId}
          className={style.searchInput}
          type="text"
          hint={t('Search campaigns')}
          icon={<Icon iconType={IconTypes.SEARCH} />}
          value={searchQuery}
          onChange={onSearchQueryChange}
          onFocus={() => {
            this.closeMenus();
            onSearchQueryChange(searchQuery);
          }}
          onKeyUp={this.onKeyUp}
          autoComplete="off"
        />
        <nav
          className={cx('menu', {
            menuVisible: searchHits.length,
          })}
        >
          {this.renderSearchProgress()}
          {this.renderSearchResults()}
        </nav>
      </div>
    );
  }

  renderUsername = () => {
    const { authUser } = this.props;
    return <div className={style.email}>{authUser.get('email')}</div>;
  };

  renderSettingsMenu() {
    const { showSettings, t } = this.props;
    const { settingsMenuOpen } = this.state;

    if (!showSettings) {
      return null;
    }

    return (
      <div
        className={style.columnUserInfo}
        ref={(el) => {
          this.clickOutsideElements.settingsMenu = el;
        }}
      >
        {this.renderUsername()}
        <div className={style.settingsIconWrapper}>
          <IconButton
            className={style.settingsIcon}
            icon={<Icon iconType={IconTypes.SETTINGS} />}
            onClick={() => {
              this.openMenu('settingsMenu');
            }}
          />
        </div>
        <div
          className={cx('menu', 'menuNarrow', {
            menuVisible: settingsMenuOpen,
          })}
        >
          {this.renderViewProfileMenuItem()}
          {this.renderUsersMenuItem()}
          {this.renderCreateCampaignMenuItem()}
          {this.renderEditFramesMenuItem()}
          <li className={style.menuItemAnchor}>
            <a href={config.app.helpUrl} onClick={triggerBodyClickToClose} target="_blank" rel="noopener noreferrer">
              <Icon iconType={IconTypes.HELP_OUTLINE} />
              {t('Need help?')}
            </a>
          </li>
          <li className={style.menuItemAnchor}>
            <Link
              to={`/auth/logout?next=${encodeURIComponent(window.location.pathname)}`}
              onClick={triggerBodyClickToClose}
            >
              <Icon iconType={IconTypes.EXIT_TO_APP} />
              {t('Logout')}
            </Link>
          </li>
        </div>
      </div>
    );
  }

  renderViewProfileMenuItem() {
    const { t, authUser } = this.props;

    return (
      <li className={style.menuItemAnchor}>
        <a onClick={this.handleViewProfileClick}>
          <Icon iconType={IconTypes.ACCOUNT_CIRCLE} />
          <span className={style.userName}>
            {authUser.get('name')}
            <span>{t('View Profile')}</span>
          </span>
        </a>
      </li>
    );
  }

  renderUsersMenuItem() {
    const { t, authUser } = this.props;
    if (!userCanEditUsers(authUser)) {
      return null;
    }

    return (
      <li className={style.menuItemAnchor}>
        <Link to="/users" onClick={triggerBodyClickToClose}>
          <Icon iconType={IconTypes.GROUP} />
          {t('Users')}
        </Link>
      </li>
    );
  }

  renderEditFramesMenuItem() {
    const { t, authUser } = this.props;
    if (!userCanEditFrames(authUser)) {
      return null;
    }

    return (
      <li className={style.menuItemAnchor}>
        <Link to="/frames" onClick={triggerBodyClickToClose}>
          <Icon iconType={IconTypes.STAY_PRIMARY_PORTRAIT} />
          {t('Edit Frames')}
        </Link>
      </li>
    );
  }

  renderCreateCampaignMenuItem() {
    const { t, dispatch, authUser } = this.props;
    if (!userCanCreateCampaigns(authUser)) {
      return null;
    }

    return (
      <li className={style.menuItemAnchor}>
        <a
          onClick={() => {
            dispatch(checkCanCreateCampaigns()).then(() => dispatch(showCreateCampaignForm()));
          }}
        >
          <Icon iconType={IconTypes.CREATE} />
          {`${t('Create')} ${t('Campaign')}`}
        </a>
      </li>
    );
  }

  renderBackToLink() {
    const {
      campaign,
      isFetchingCampaign,
      editableUser,
      framesScreenshotsLoaded,
      showBackLink,
      mediaBuyScreenshotsLoaded,
      t,
    } = this.props;

    if (!showBackLink) {
      return null;
    }

    if (framesScreenshotsLoaded) {
      return <BackLink url="/frames" label={t('Back to frames')} />;
    }
    if (mediaBuyScreenshotsLoaded) {
      return <BackLink url={`/campaigns/${campaign.get('id')}/media-buy`} label={t('Back to media-buy')} />;
    }
    if (campaign.size || isFetchingCampaign) {
      return <BackLink url={ROUTES.DASHBOARD} label={t('Back to dashboard')} />;
    }
    if (editableUser.size) {
      return <BackLink url="/users" label={t('Back to all users')} />;
    }

    return null;
  }

  renderUser() {
    const { authUser, showUser } = this.props;

    if (!authUser.size || !showUser) {
      return null;
    }

    return <span className={style.columnUser}>{authUser.get('email')}</span>;
  }

  render() {
    const { baseUrl } = this.props;

    return (
      <div>
        <header className={style.component}>
          <div className={style.columnLogo} data-testid="headerLogo">
            <Link className={style.iconLink} to={baseUrl}>
              <Logo />
            </Link>
          </div>
          <div className={style.columnSpacer}>{this.renderBackToLink()}</div>
          {this.renderSearchInput()}
          {this.renderSettingsMenu()}
          {this.renderUser()}
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  authUser: PropTypes.instanceOf(Map).isRequired,
  baseUrl: PropTypes.string,
  campaign: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isFetchingSearchResults: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  searchHits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      booking_engine_id: PropTypes.string, // eslint-disable-line camelcase
      client: PropTypes.string,
      starts_at: PropTypes.string, // eslint-disable-line camelcase
      ends_at: PropTypes.string, // eslint-disable-line camelcase
    }),
  ),
  showBackLink: PropTypes.bool,
  showSearch: PropTypes.bool,
  showSettings: PropTypes.bool,
  showUser: PropTypes.bool,
  userIsAuthenticated: PropTypes.bool,
  editableUser: PropTypes.instanceOf(Map),
  framesScreenshotsLoaded: PropTypes.bool,
  mediaBuyScreenshotsLoaded: PropTypes.bool,
  onSearchQueryChange: PropTypes.func,
  clearSearchCampaigns: PropTypes.func,
  t: PropTypes.func.isRequired,
};

Header.defaultProps = {
  baseUrl: `${ROUTES.DASHBOARD}1?inDate=true`,
  showBackLink: false,
  showSearch: false,
  showSettings: false,
  showUser: false,
  searchQuery: '',
  searchHits: [],
  userIsAuthenticated: false,
  editableUser: {},
  framesScreenshotsLoaded: false,
  mediaBuyScreenshotsLoaded: false,
  onSearchQueryChange: () => {},
  clearSearchCampaigns: () => {},
};

export default withNamespaces(['common', 'header'], { wait: false })(Header);
