import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Heading from 'assets/components/presentational/Heading';
import { List, Map } from 'immutable';
import { debounce } from 'lodash';

import { redirect, getURLQueryString } from 'modules/Helpers';

import { fetchUserPermissions } from 'actions/user';
import notify, { NotificationTypes } from 'actions/snackbar';
import { fetchFrames, updateFrame, deleteFrame, restoreFrame, fetchFramesCsv } from 'actions/frames/frames';
import { fetchBusinessUnits } from 'actions/businessUnits';

import Warnings from 'components/common/Warnings';
import ProgressBar from 'components/patterns/ProgressBar';
import DataTable from 'assets/components/presentational/DataTable';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import Action from 'assets/components/presentational/Rows/Action';
import Dialog from 'assets/components/presentational/Dialog';
import ExportCsvButton from 'assets/components/presentational/ExportCsvButton';
import Checkbox from 'assets/components/presentational/Checkbox';
import Button from 'assets/components/presentational/Button';

import style from './frames.scss';

const initialState = {
  sorts: [],
  filters: [],
  page: 1,
  showMultipleDeleteDialog: false,
  showDeleteDialog: false,
  deletedFrame: null,
  showRestoreDialog: false,
  restoredFrame: null,
  isFetchingScreenshots: false,
  screenshots: new List(),
  areScreenshotsLoaded: false,
  selected: [],
};

class Frames extends Component {
  constructor(props, context) {
    super(props, context);

    const {
      location: {
        query: { filters, sorts },
      },
      params: { page },
    } = props;

    const qsState = { page };

    if (filters !== undefined) {
      qsState.filters = JSON.parse(filters);
    }

    if (sorts !== undefined) {
      qsState.sorts = JSON.parse(sorts);
    }

    this.state = Object.assign({}, initialState, qsState);
    this.filterFetch = debounce(this.filterFetch, 800, { leading: false });
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    const { filters, sorts, page } = this.state;

    dispatch(fetchFrames(page, sorts, filters));
    dispatch(fetchUserPermissions(user.get('id'), 'App\\Models\\BusinessUnit'));
    dispatch(fetchBusinessUnits());
  }

  componentDidUpdate(prevProps) {
    const { isDeleting, isRestoring } = this.props;
    if (prevProps.isDeleting === true && isDeleting === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        deletedFrame: null,
      });
    }
    if (prevProps.isRestoring === true && isRestoring === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        restoredFrame: null,
      });
    }
  }

  handlePaginationChange = (selected) => {
    const { sorts, filters, page } = this.state;

    if (!selected) {
      selected = 0;
    }

    if (selected === page - 1) {
      return;
    }

    this.setState({
      page: selected + 1,
    });

    this.setNewLocation(selected + 1, sorts, filters);
  };

  handleCellChange = (frameId, key, v) => {
    const { dispatch, t } = this.props;
    const data = {};
    let updateFieldWarning = '';
    switch (key) {
      case 'player_id':
        data['player-id'] = v.split(',').map((v) => v.trim());
        updateFieldWarning = '`player id`';
        break;
      case 'display_unit_id':
        data['display-unit-id'] = v.split(',').map((v) => v.trim());
        updateFieldWarning = '`display unit id`';

        break;
      case 'external_id':
        data['external-id'] = v.trim();
        break;

      case 'extended_code':
        data['extended-code'] = v.trim();
        break;

      case 'route_code':
        data['route-code'] = v.trim();
        break;
    }

    return dispatch(updateFrame(frameId, data)).then(() => {
      if (updateFieldWarning.length) {
        return dispatch(
          notify(
            NotificationTypes.WARNING,
            t('It may take several minutes for {{frameType}} updates to be reflected in rules and search results', {
              frameType: updateFieldWarning,
            }),
          ),
        );
      }
    });
  };

  handleSortChange = (field, direction) => {
    const { sorts, filters, page } = this.state;

    let newSorts = sorts.filter((sort) => sort.substr(1, sort.length) !== field);

    if (direction) {
      const directionSymbol = direction === 'DESC' ? '-' : '+';
      newSorts = newSorts.concat([directionSymbol + field]);
    }

    this.setState({
      sorts: newSorts,
    });

    this.setNewLocation(page, newSorts, filters);
  };

  handleFilterChange = (field, value) => {
    const { sorts, filters } = this.state;

    let newFilters = filters.filter((filter) => !filter.hasOwnProperty(field));

    if (value) {
      newFilters = newFilters.concat([{ [field]: value }]);
    }

    this.setState({
      filters: newFilters,
      page: 1,
    });

    this.filterFetch(1, sorts, newFilters);
  };

  setNewLocation = (page, sorts, filters) => {
    const { dispatch } = this.props;
    const qsObject = {};

    if (sorts instanceof Array && sorts.length) {
      qsObject.sorts = JSON.stringify(sorts);
    } else {
      qsObject.sorts = '';
    }

    if (filters instanceof Array && filters.length) {
      qsObject.filters = JSON.stringify(filters);
    } else {
      qsObject.filters = '';
    }

    const queryString = getURLQueryString(qsObject);
    const shouldReplaceHistory = true;

    redirect(`/frames/${page}${queryString}`, shouldReplaceHistory);
    dispatch(fetchFrames(page, sorts, filters));
  };

  handleDeleteClick = (frame) => {
    this.setState({
      deletedFrame: frame,
      showDeleteDialog: true,
    });
  };

  handleDeleteDialogCancelClick = () => {
    this.setState({
      deletedFrame: null,
      showDeleteDialog: false,
    });
  };

  handleDeleteDialogConfirmClick = () => {
    const { deletedFrame } = this.state;
    const { dispatch, t } = this.props;
    const frameName = deletedFrame ? deletedFrame.get('external-id') : '';

    this.setState({
      showDeleteDialog: false,
    });

    dispatch(deleteFrame(deletedFrame.get('id'))).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('The frame: {{frameName}}, has been deleted.', { frameName })));
    });
  };

  handleRestoreClick = (frame) => {
    this.setState({
      restoredFrame: frame,
      showRestoreDialog: true,
    });
  };

  handleRestoreDialogCancelClick = () => {
    this.setState({
      restoredFrame: null,
      showRestoreDialog: false,
    });
  };

  handleRestoreDialogConfirmClick = () => {
    const { restoredFrame } = this.state;
    const { dispatch, t } = this.props;
    const frameName = restoredFrame ? restoredFrame.get('external-id') : '';

    this.setState({
      showRestoreDialog: false,
    });

    dispatch(restoreFrame(restoredFrame.get('id'))).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('The frame: {{frameName}}, has been restored.', { frameName })));
    });
  };

  filterFetch = (page, sorts, fetchFilters) => {
    this.setNewLocation(page, sorts, fetchFilters);
  };

  handleFetchFramesCsv = () => {
    const { sorts, filters } = this.state;
    const { dispatch } = this.props;
    dispatch(fetchFramesCsv(sorts, filters));
  };

  render() {
    const { t } = this.props;

    return (
      <div className={style.component}>
        {this.renderDeleteDialog()}
        {this.renderRestoreDialog()}
        {this.renderMultipleDeleteDialog()}
        <Heading className={style.heading} tag="H1" size="largest">
          {t('Edit Frames')}
        </Heading>
        <div className={style.pageContainer}>{this.renderDataTable()}</div>
      </div>
    );
  }

  deleteSelectedFrames = () => {
    const { selected } = this.state;
    const { dispatch, t } = this.props;

    this.hideMultipleDeleteDialog();

    const promises = selected.map(async (frame) => {
      await dispatch(deleteFrame(frame.id));
      return frame.id;
    });

    Promise.allSettled(promises).then((results) => {
      const frameIds = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
      const nameOfDeletedFrames = selected
        .filter((frame) => frameIds.indexOf(frame.id) !== -1)
        .map((frame) => frame['external-id'])
        .reduce((accumulator, currentValue) => `${accumulator}, ${currentValue}`);

      if (nameOfDeletedFrames) {
        dispatch(
          notify(
            NotificationTypes.SUCCESS,
            t('The frames: {{frames}} have been deleted.', { frames: nameOfDeletedFrames }),
          ),
        );
      }

      const selectedFramesAfterDelete = selected.filter((frame) => frameIds.indexOf(frame.id) === -1);
      this.setState({
        selected: selectedFramesAfterDelete,
      });
    });
  };

  showMultipleDeleteDialog = () => {
    this.setState({ showMultipleDeleteDialog: true });
  };

  hideMultipleDeleteDialog = () => {
    this.setState({ showMultipleDeleteDialog: false });
  };

  renderDeleteButton = () => {
    const { t } = this.props;
    const { selected } = this.state;
    return (
      <Button
        className={style.deleteButton}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        label={t('Delete selected frames')}
        disabled={!selected.length}
        onClick={this.showMultipleDeleteDialog}
      />
    );
  };

  onSelectFrame = (checked, frame) => {
    this.setState(({ selected }) => ({
      selected: checked ? [...selected, frame] : selected.filter((selectedFrame) => selectedFrame.id !== frame.id),
    }));
  };

  renderWarnings = (warnings) => {
    return (
      <div className={style.additionalInfo}>
        <Warnings warnings={warnings} icon={<Icon iconType={IconTypes.WARNING} color={IconColors.RED} />} />
      </div>
    );
  };

  renderMultipleDeleteDialog = () => {
    const { t } = this.props;
    const { showMultipleDeleteDialog, selected } = this.state;

    const framesAttachedToCampaigns = selected.filter((frame) => frame['campaign-count'] > 0);

    const actions = [
      { label: t('Cancel'), onClick: this.hideMultipleDeleteDialog },
      { label: t('Confirm'), onClick: this.deleteSelectedFrames },
    ];

    return (
      <Dialog
        active={showMultipleDeleteDialog}
        actions={actions}
        title={t('Delete frames')}
        onEscKeyDown={this.hideMultipleDeleteDialog}
        onOverlayClick={this.hideMultipleDeleteDialog}
      >
        <span>{t('Are you sure you want to delete {{count}} frame?', { count: selected.length })}</span>
        {framesAttachedToCampaigns.length > 0 &&
          this.renderWarnings([
            t('{{count}}/{{totalCount}} frames you have selected are attached to campaigns', {
              count: framesAttachedToCampaigns.length,
              totalCount: selected.length,
            }),
          ])}
      </Dialog>
    );
  };

  renderDeleteDialog() {
    const { t } = this.props;
    const { showDeleteDialog, deletedFrame } = this.state;
    const frameName = deletedFrame ? deletedFrame.get('external-id') : '';

    const actions = [
      { label: t('Cancel'), onClick: this.handleDeleteDialogCancelClick },
      { label: t('Delete'), onClick: this.handleDeleteDialogConfirmClick, icon: 'delete_forever', primary: true },
    ];

    return (
      <Dialog active={showDeleteDialog} actions={actions} type="small">
        <p>{t('Are you sure you want to delete the frame: {{frameName}}?', { frameName })}</p>
      </Dialog>
    );
  }

  renderRestoreDialog() {
    const { t } = this.props;
    const { showRestoreDialog, restoredFrame } = this.state;
    const frameName = restoredFrame ? restoredFrame.get('external-id') : '';

    const actions = [
      { label: t('Cancel'), onClick: this.handleRestoreDialogCancelClick },
      { label: t('Restore'), onClick: this.handleRestoreDialogConfirmClick, icon: 'restore', primary: true },
    ];

    return (
      <Dialog active={showRestoreDialog} actions={actions} type="small">
        <p>{t('Are you sure you want to restore the frame: {{frameName}}?', { frameName })}</p>
      </Dialog>
    );
  }

  renderExportCsvButton = () => {
    const { isFetchingExportData, exportDataItems } = this.props;

    const csvFileFieldSpec = {
      uniqueId: 'external-id',
      extendedCode: 'extended-code',
      routeFrameCode: 'route-code',
      countryCode: ['location', 'country-code'],
      businessDivision: 'business-unit',
      latitude: 'lat',
      longitude: 'lng',
      locality: 'name',
      marketingName: ['specification', 'name'],
      width: ['specification', 'width'],
      height: ['specification', 'height'],
      displayUnitID: 'display-unit-id',
      playerID: 'player-id',
      defaultDuration: ['specification', 'motion-length'],
      fps: ['specification', 'motion-fps'],
      contentSubmission: ['specification', 'notes'],
    };

    return (
      <ExportCsvButton
        onFetchComplete={this.handleFetchFramesCsv}
        dataItems={exportDataItems}
        fieldSpec={csvFileFieldSpec}
        fileName="frames.csv"
        fetching={isFetchingExportData}
      />
    );
  };

  renderDataTable() {
    const { t, frames, pagination, isFetching, businessUnits, user, isFetchingExportData } = this.props;
    const { sorts, filters, selected } = this.state;

    const businessUnitOptions = this.getBusinessUnitOptions(user, businessUnits);
    const softDeleteOptions = [
      {
        value: undefined,
        label: t('Exclude Deleted Frames'),
      },
      {
        value: 'with_trashed',
        label: t('Include Deleted Frames'),
      },
      {
        value: 'only_trashed',
        label: t('Only Deleted Frames'),
      },
    ];

    const model = {
      external_id: { type: String, title: t('External Id'), sortable: true, filterable: true, editable: true }, // eslint-disable-line camelcase
      extended_code: { type: String, title: t('Frame Code'), sortable: true, filterable: true, editable: true }, // eslint-disable-line camelcase
      route_code: { type: String, title: t('Route Code'), sortable: true, filterable: true, editable: true }, // eslint-disable-line camelcase
      name: { type: String, title: t('Locality'), sortable: true, filterable: true },
      // eslint-disable-next-line camelcase
      business_unit: {
        type: String,
        title: t('Business Unit'),
        sortable: false,
        filterable: true,
        filterOptions: businessUnitOptions,
        editable: false,
      },
      display_unit_id: { type: String, title: t('Display Units'), filterable: true, editable: true }, // eslint-disable-line camelcase
      player_id: { type: String, title: t('Players'), filterable: true, editable: true }, // eslint-disable-line camelcase
      location: {
        type: String,
        title: t('Location'),
        renderItem: (v) => (
          <a href={`https://www.google.co.uk/maps/place/${v}`} target="_blank" rel="noopener noreferrer">
            {v}
          </a>
        ),
      },
      specification: { type: String, title: t('Specification') },
      actions: {
        type: String,
        title: '',
        filterable: true,
        filterOptions: softDeleteOptions,
        filterLabel: t('Deleted Frame Visibility'),
      },
    };

    if (user.get('is-super-user')) {
      model.selected = {
        type: String,
        title: '',
        columnWidth: '80px',
      };
    }

    if (businessUnitOptions.length < 2) {
      delete model.business_unit;
    }

    const items = frames.map((frame) => {
      const displayUnits = frame.get('display-unit-id');
      const players = frame.get('player-id');
      const actions = [];

      if (!frame.get('deleted-at')) {
        actions.push(
          <Action
            disabled={isFetching}
            key="restore"
            icon={<Icon iconType={IconTypes.CAMERA_ALT} />}
            tooltip={t('View screenshots')}
            onClick={() => {
              redirect(`/frames/${frame.get('id')}/screenshots`);
            }}
          />,
        );
      }

      if (frame.get('is-deletable') && !frame.get('deleted-at')) {
        actions.push(
          <Action
            key="delete"
            disabled={isFetching}
            icon={<Icon iconType={IconTypes.DELETE} />}
            tooltip={t('Delete frame')}
            onClick={() => this.handleDeleteClick(frame)}
          />,
        );
      } else if (frame.get('deleted-at')) {
        actions.push(
          <Action
            key="restore"
            disabled={isFetching}
            icon={<Icon iconType={IconTypes.RESTORE} />}
            tooltip={t('Restore frame')}
            onClick={() => this.handleRestoreClick(frame)}
          />,
        );
      }

      const isSelected = !!selected.find((selectedFrame) => selectedFrame.id === frame.get('id'));
      return {
        id: frame.get('id'),
        name: frame.get('name'),
        external_id: frame.get('external-id'), // eslint-disable-line camelcase
        extended_code: frame.get('extended-code'), // eslint-disable-line camelcase
        route_code: frame.get('route-code'), // eslint-disable-line camelcase
        business_unit: frame.get('business-unit'), // eslint-disable-line camelcase
        display_unit_id: displayUnits ? displayUnits.join(', ') : '', // eslint-disable-line camelcase
        player_id: players ? players.join(', ') : '', // eslint-disable-line camelcase
        location: `${frame.get('lat')},${frame.get('lng')}`,
        specification: `${frame.getIn(['specification', 'name'])}, ${frame.getIn([
          'specification',
          'width',
        ])}x${frame.getIn(['specification', 'height'])}, ${frame.getIn([
          'specification',
          'aspect-ratio',
        ])}, ${frame.getIn(['specification', 'rotation'])}°`,
        actions: <div className={style.actions}>{actions}</div>,
        selected: (
          <div className={style.checkboxWrapper}>
            <Checkbox
              className={style.checkbox}
              checked={isSelected}
              disabled={!!frame.get('deleted-at')}
              onChange={(value) => this.onSelectFrame(value, frame.toJS())}
            />
          </div>
        ),
      };
    });

    const rowsDeleteStatus = frames
      .map((frame) => ({
        id: frame.get('id'),
        deleted: frame.get('deleted-at') !== undefined,
      }))
      .toJS();

    const sortObject = sorts.reduce((carry, item) => {
      const direction = item.substr(0, 1) === '-' ? 'DESC' : 'ASC';
      const field = item.substr(1, item.length);
      carry[field] = direction;
      return carry;
    }, {});

    const filterObject = filters.reduce((accumulator, filter) => Object.assign(accumulator, filter), {});

    return (
      <div>
        <div>
          {this.renderExportCsvButton()}
          {user.get('is-super-user') && this.renderDeleteButton()}
          <div className={style.progressBarContainer}>{isFetchingExportData ? <ProgressBar /> : null}</div>
        </div>
        <div className={style.tableContainer}>
          {isFetching && <ProgressBar />}
          <DataTable
            items={items}
            model={model}
            sorts={sortObject}
            filters={filterObject}
            pagination={pagination}
            isFetching={isFetching}
            onSortChange={this.handleSortChange}
            onFilterChange={this.handleFilterChange}
            onCellChange={this.handleCellChange}
            onPaginationChange={this.handlePaginationChange}
            className={style.table}
            rowsDeleteStatus={rowsDeleteStatus}
          />
        </div>
      </div>
    );
  }

  /**
   * Get a list of filter options for the business_unit field
   * @param user
   * @param businessUnits
   * @returns array In the format [{value: "XXX", "label": "XXX"}, ...]
   */
  getBusinessUnitOptions = (user, businessUnits) => {
    if (user.get('is-super-user')) {
      return businessUnits.map((value) => ({ value: value.get('id'), label: value.get('name') })).toJS();
    }

    const perms = user
      .get('permissions')
      .filter((perm) => perm.get('object') === 'App\\Models\\BusinessUnit')
      .filter((perm) => perm.get('action') === 'admin')
      .map((perm) => perm.get('object-id'));

    return businessUnits
      .filter((bu) => perms.includes(bu.get('id')))
      .map((value) => ({ value: value.get('id'), label: value.get('name') }))
      .toJS();
  };
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  businessUnits: state.businessUnits.businessUnits,
  frames: state.frames.frames,
  pagination: state.frames.pagination,
  isFetching: state.frames.isFetching,
  isDeleting: state.frames.isFetching,
  isRestoring: state.frames.isFetching,
  isFetchingExportData: state.frames.isFetchingExportData,
  exportDataItems: state.frames.exportDataItems,
});

export default withNamespaces(['common', 'frames'], { wait: true })(connect(mapStateToProps)(Frames));

Frames.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  businessUnits: PropTypes.instanceOf(List).isRequired,
  frames: PropTypes.instanceOf(List).isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isRestoring: PropTypes.bool.isRequired,
  isFetchingExportData: PropTypes.bool.isRequired,
  exportDataItems: PropTypes.object.isRequired,
};
