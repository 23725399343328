import { PropTypes } from 'prop-types';

export const uploadsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    uniqueIdentifier: PropTypes.string,
    progress: PropTypes.number,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        exception: PropTypes.string,
        details: PropTypes.string,
        status: PropTypes.string,
        trace: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ),
  }),
);
