import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';

const getInitialState = () => ({
  activeRuleSetId: '',
  isActiveFramesCollapsed: true,
  isFetchingRuleSet: false,
  isFetchingRuleSets: false,
  isFetchingRuleTypes: false,
  isSavingRuleSet: false,
  isUnsavedChangesDialogActive: false,
  ruleTypes: List(),
  showEditDrawer: false,
  showSettingsDrawer: false,
  pagination: new Map(),
});

const moderation = handleActions(
  {
    CAMPAIGN_RULE_SETS_SET_ACTIVE: (state, action) => ({
      ...state,
      activeRuleSetId: action.ruleSetId,
    }),
    CAMPAIGN_RULE_TYPES_FETCH_REQUEST: (state) => ({
      ...state,
      isFetchingRuleTypes: true,
    }),
    CAMPAIGN_RULE_TYPES_FETCH_COMPLETE: (state) => ({
      ...state,
      isFetchingRuleTypes: false,
    }),
    CAMPAIGN_RULE_TYPES_MERGE: (state, action) => ({
      ...state,
      ruleTypes: state.ruleTypes.merge(fromJS(action.ruleTypes)),
    }),
    CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_COLLAPSED: (state, action) => ({
      ...state,
      ruleTypes: state.ruleTypes.map((ruleType) => {
        if (ruleType.get('id') === action.ruleType.get('id')) {
          return ruleType.set('is-collapsed', action.isCollapsed);
        }

        return ruleType;
      }),
    }),
    CAMPAIGN_RULE_SETS_TOGGLE_RULE_TYPE_SELECTED: (state, action) => ({
      ...state,
      ruleTypes: state.ruleTypes.map((ruleType) => {
        if (ruleType.get('id') === action.ruleType.get('id')) {
          return ruleType.set('is-selected', action.isSelected);
        }

        return ruleType;
      }),
    }),
    CAMPAIGN_RULE_SETS_SAVE_REQUEST: (state) => ({
      ...state,
      isSavingRuleSet: true,
    }),
    CAMPAIGN_RULE_SETS_SAVE_COMPLETE: (state) => ({
      ...state,
      isSavingRuleSet: false,
    }),
    CAMPAIGN_RULE_SETS_TOGGLE_ACTIVE_FRAMES_COLLAPSED: (state, action) => ({
      ...state,
      isActiveFramesCollapsed: action.isCollapsed,
    }),
    CAMPAIGN_RULE_SETS_TOGGLE_UNSAVED_CHANGES_DIALOG: (state, action) => ({
      ...state,
      isUnsavedChangesDialogActive: action.active,
    }),
    CAMPAIGN_RULE_SETS_TOGGLE_SHOW_DRAWER: (state, action) => ({
      ...state,
      showEditDrawer: action.component === 'edit' ? !state.showEditDrawer : state.showEditDrawer,
      showSettingsDrawer: action.component === 'settings' ? !state.showSettingsDrawer : state.showSettingsDrawer,
    }),
    CAMPAIGN_RULE_SETS_TOGGLE_IS_FETCHING_RULE_SET: (state) => ({
      ...state,
      isFetchingRuleSet: !state.isFetchingRuleSet,
    }),
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingRuleSets: action.relation === 'rule-sets' ? true : state.isFetchingRuleSets,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingRuleSets: action.relation === 'rule-sets' ? false : state.isFetchingRuleSets,
    }),
    CAMPAIGN_RULE_SETS_PAGINATION_MERGE: (state, action) => ({
      ...state,
      pagination: state.pagination.merge(fromJS(action.pagination)),
    }),
  },
  getInitialState(),
);

export default moderation;
