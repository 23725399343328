import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconMenu, MenuItem } from 'react-toolbox/lib/menu';

import { SNOOZE_OPTIONS } from 'app-constants';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import DatePicker from 'components/patterns/DatePicker';
import ProgressBar from 'components/patterns/ProgressBar';

import style from './SnoozeCampaign.scss';

const SnoozeCampaign = ({ selectedValue, snoozeCampaign, t, onDropdownChange, className }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isDatePickerActive, setIsDatePickerActive] = useState(false);

  const { FOR_WEEK, UNTIL_CUSTOM_DATE, PERMANENTLY, UN_SNOOZE } = SNOOZE_OPTIONS;

  const snoozeOptions = [
    { value: FOR_WEEK, label: t('For a week'), formatLabel: (value) => moment(value).format('DD MMM YYYY') },
    {
      value: UNTIL_CUSTOM_DATE,
      label: t('Until custom date'),
      formatLabel: (value) => moment(value).format('DD MMM YYYY'),
    },
    { value: PERMANENTLY, label: t('Permanently') },
    {
      value: UN_SNOOZE,
      label: t('Un-snooze'),
      formatLabel: () => t('Snooze'),
      hide: selectedValue === UN_SNOOZE,
    },
  ];

  const snoozeUntilDate = (date) => {
    onDropdownChange(
      UNTIL_CUSTOM_DATE,
      moment(date)
        .endOf('date')
        .toDate(),
      setIsFetching,
    );
    setIsDatePickerActive(false);
  };

  const onSnoozeCampaign = (value) => {
    switch (value) {
      case FOR_WEEK: {
        onDropdownChange(
          value,
          moment()
            .add(7, 'days')
            .endOf('date')
            .toDate(),
          setIsFetching,
        );
        break;
      }
      case UNTIL_CUSTOM_DATE: {
        setIsDatePickerActive(true);
        break;
      }
      case PERMANENTLY:
      default: {
        onDropdownChange(value, null, setIsFetching);
      }
    }
  };

  const getFormattedLabel = () => {
    const selectedItem = snoozeOptions.find((item) => item.value === selectedValue);

    if (selectedItem) {
      return selectedItem.formatLabel ? selectedItem.formatLabel(snoozeCampaign.untilDate) : selectedItem.label;
    }

    return null;
  };

  const minDate = moment()
    .add(1, 'days')
    .startOf('date')
    .toDate();

  return (
    <div className={`${style.component} ${className}`}>
      {isFetching ? (
        <ProgressBar />
      ) : (
        <>
          <IconMenu
            className={style.iconMenu}
            icon={
              <div data-testid="snoozeDropdownLabel" className={style.snoozeLabel}>
                {getFormattedLabel()}
                <Icon iconType={IconTypes.ARROW_DROP_DOWN} />
              </div>
            }
            position="topLeft"
            onSelect={onSnoozeCampaign}
            selected={selectedValue}
            selectable
            iconRipple={false}
          >
            {snoozeOptions.map((item) =>
              !item.hide ? <MenuItem key={item.value} value={item.value} caption={item.label} /> : null,
            )}
          </IconMenu>
          <DatePicker
            active={isDatePickerActive}
            value={snoozeCampaign.untilDate || minDate}
            minDate={minDate}
            inputClassName={style.hide}
            onChange={snoozeUntilDate}
            onDismiss={() => setIsDatePickerActive(false)}
          />
        </>
      )}
    </div>
  );
};

SnoozeCampaign.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  snoozeCampaign: PropTypes.shape({
    id: PropTypes.string,
    untilDate: PropTypes.instanceOf(Date),
  }).isRequired,
  t: PropTypes.func.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SnoozeCampaign.defaultProps = { className: '' };

export default SnoozeCampaign;
