import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Dropdown from 'assets/components/presentational/Dropdown';
import Input from 'components/patterns/Input';

import AddSetButton from '../AddSetButton';
import SetContainer from '../SetContainer';

import style from './uvIndexForm.scss';

const Options = {
  DAY: 0,
  OPERATOR: 1,
  UVINDEX: 2,
};

class UvIndexForm extends Component {
  handleRemoveSetClick = (key) => {
    const { fields, notifyError, t } = this.props;
    if (fields.length > 1) {
      fields.removeField(key);
    } else {
      notifyError(t('You cannot delete this since it is the only sub rule available'));
    }
  };

  addNewSet = () => {
    const { fields, ruleType } = this.props;

    return fields.addField(ruleType.get('default-value').toJS()[0]);
  };

  renderDropDown = (field, options, key) => {
    const { ruleType } = this.props;

    return (
      <Dropdown
        className={style.dropdown}
        disabled={!ruleType.get('is-selected')}
        key={key}
        onChange={(param) => field.onChange(param.val)}
        source={options.toJS()}
        allowBlank={false}
        {...field}
      />
    );
  };

  renderDropDowns = (set) => {
    const { ruleType } = this.props;

    return ruleType.get('options').map((options, key) => {
      // First three options require a dropdown
      if (key <= Options.OPERATOR) {
        const field = set[key];

        return this.renderDropDown(field, options, key);
      }
    });
  };

  renderSets = () => {
    const { fields } = this.props;

    return fields.map((set, key) => this.renderSet(set, key));
  };

  renderSet = (set, key) => {
    const { ruleType, t } = this.props;

    return (
      <SetContainer
        enabled={ruleType.get('is-selected')}
        key={key}
        onDeleteClick={this.handleRemoveSetClick}
        sequence={key}
      >
        {this.renderDropDowns(set)}

        <Input
          className={style.uvIndexInput}
          disabled={!ruleType.get('is-selected')}
          label={t('UV Index')}
          min={0}
          max={50}
          type="text"
          {...set[Options.UVINDEX]}
        />
      </SetContainer>
    );
  };

  render() {
    const { ruleType, t } = this.props;

    return (
      <div className={style.component}>
        {this.renderSets()}
        <AddSetButton disabled={!ruleType.get('is-selected')} label={t('Add UV Index rule')} onClick={this.addNewSet} />
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(UvIndexForm);

UvIndexForm.propTypes = {
  fields: PropTypes.array.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  notifyError: PropTypes.func,
};

UvIndexForm.defaultProps = {
  notifyError: () => {},
};
