import { begin, end, ACTIVITIES } from 'store/activity';
import { searchCampaigns as searchCampaignsRequest } from 'requests/search';

import { SEARCH_TYPES } from './constants';

const ACTION_TYPES = {
  STORE_QUERY: 'SEARCH/STORE_QUERY',
  STORE_RESULTS: 'SEARCH/STORE_RESULTS',
  CLEAR_RESULTS: 'SEARCH/CLEAR_RESULTS',
};

const searchCampaigns = (query, _searchCampaigns = searchCampaignsRequest) => async (dispatch) => {
  dispatch(storeSearchQuery(SEARCH_TYPES.CAMPAIGNS, query));

  if (!query.length) {
    dispatch(clearSearchHits(SEARCH_TYPES.CAMPAIGNS));
    return;
  }

  dispatch(begin(ACTIVITIES.SEARCH_CAMPAIGNS));
  const hits = await _searchCampaigns(query);
  dispatch(storeSearchHits(SEARCH_TYPES.CAMPAIGNS, hits));
  dispatch(end(ACTIVITIES.SEARCH_CAMPAIGNS));
};

const clearSearchCampaigns = () => clearSearchHits(SEARCH_TYPES.CAMPAIGNS);

function storeSearchQuery(searchType, query = '') {
  return {
    type: ACTION_TYPES.STORE_QUERY,
    payload: {
      searchType,
      query,
    },
  };
}

function storeSearchHits(searchType, hits = []) {
  return {
    type: ACTION_TYPES.STORE_RESULTS,
    payload: { searchType, hits },
  };
}

function clearSearchHits(searchType) {
  return { type: ACTION_TYPES.CLEAR_RESULTS, payload: { searchType } };
}

export { searchCampaigns, clearSearchCampaigns, ACTION_TYPES };

// exported for testing
export { storeSearchQuery, storeSearchHits };
