import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { debounce } from 'lodash';

import { redirect, getURLQueryString } from 'modules/Helpers';
import {
  fetchFrames,
  fetchFramesCsv,
  fetchFrameHistoryStats,
  setFramesFilterValue,
  resetState,
} from 'actions/campaign/mediaBuy';
import { setActiveSection } from 'actions/campaign/navigation';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import ProgressBar from 'components/patterns/ProgressBar';
import Table from 'assets/components/presentational/Table';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Input from 'components/patterns/Input';
import DeleteConfirmButton from 'assets/components/presentational/DeleteConfirmButton';
import Pagination from 'assets/components/presentational/Pagination';
import { detachCampaignFrames } from 'actions/campaign';
import ExportCsvButton from 'assets/components/presentational/ExportCsvButton';
import { withRouter } from 'react-router';
import Actions from 'assets/components/presentational/Rows/Actions';
import Action from 'assets/components/presentational/Rows/Action';

import AddFramesWidget from './AddFramesWidget';

import style from './mediaBuy.scss';

class MediaBuy extends Component {
  componentDidMount() {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;
    const {
      location: {
        query: { filter: filterSearchTerm },
      },
    } = this.props;

    dispatch(setActiveSection('media buy'));
    dispatch(setFramesFilterValue(filterSearchTerm));
    this.fetchFramesDebounced(filterSearchTerm);
    dispatch(fetchFrameHistoryStats(campaignId));
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      location: {
        query: { filter: nextFilterSearchTerm },
      },
    } = this.props;

    if (this.pageNumberHasChanged(prevProps)) {
      window.scrollTo(0, 0);
      this.fetchFramesDebounced(this.props);
    }

    if (this.filterHasChanged(prevProps)) {
      this.fetchFramesDebounced(this.props);
      dispatch(setFramesFilterValue(nextFilterSearchTerm));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetState());
  }

  getPageNumber = () => {
    let {
      params: { pageNumber },
    } = this.props;
    pageNumber = parseInt(pageNumber);

    return Number.isNaN(pageNumber) ? 1 : pageNumber;
  };

  fetchFramesDebounced = debounce((nextProps) => {
    const {
      dispatch,
      params: { campaignId, pageNumber },
      location: {
        query: { filter: currentfilterSearchTerm },
      },
    } = this.props;
    let filterSearchTerm = currentfilterSearchTerm;

    if (nextProps && nextProps.nextFilterSearchTerm) {
      filterSearchTerm = nextProps.nextFilterSearchTerm;
    }

    dispatch(fetchFrames(campaignId, pageNumber, filterSearchTerm));
  }, 200);

  updatePageNumber = (pageNumber) => {
    const {
      params: { campaignId },
    } = this.props;
    const queryString = getURLQueryString();

    return redirect(`/campaigns/${campaignId}/media-buy/${pageNumber}${queryString}`);
  };

  pageNumberHasChanged = (prevProps) => {
    const {
      params: { pageNumber: currentPageNumber },
    } = prevProps;
    const {
      params: { pageNumber: nextPageNumber },
    } = this.props;

    const pageNumberHasChanged = currentPageNumber !== nextPageNumber;
    const pageNumberIsNumber = !Number.isNaN(parseInt(currentPageNumber));

    return pageNumberHasChanged && currentPageNumber && nextPageNumber && pageNumberIsNumber;
  };

  filterHasChanged = (prevProps) => {
    const {
      location: {
        query: { filter: currentFilter },
      },
    } = this.props;
    const {
      location: {
        query: { filter: nextFilter },
      },
    } = prevProps;

    return currentFilter !== nextFilter;
  };

  handleFilterChange = (value) => {
    const {
      params: { campaignId },
    } = this.props;
    const queryString = getURLQueryString({ filter: value });
    const shouldReplaceHistory = true;

    return redirect(`/campaigns/${campaignId}/media-buy/1${queryString}`, shouldReplaceHistory);
  };

  handleFramesPaginationClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;
    this.updatePageNumber(pageNumber);
  };

  handleFrameDelete = (externalId) => {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(detachCampaignFrames(campaignId, externalId)).then(() => {
      this.fetchFramesDebounced();
    });
  };

  handleFetchFramesCsv = () => {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;
    dispatch(fetchFramesCsv(campaignId));
  };

  renderScreenshotsAction(frameId) {
    const {
      params: { campaignId },
      t,
    } = this.props;
    return (
      <Action
        icon={<Icon iconType={IconTypes.PHOTO_CAMERA} />}
        to={`/campaigns/${campaignId}/media-buy/${frameId}/screenshots`}
        tooltip={t('Screenshots')}
      />
    );
  }

  renderMediaBuyTable = () => {
    const { isFetchingFrames, t, campaign } = this.props;
    let { frames } = this.props;
    if (isFetchingFrames) {
      return <ProgressBar />;
    }

    const model = Object.assign(
      {
        externalId: { type: String, title: 'External Id' },
        extendedCode: { type: String, title: 'Frame Code' },
        routeFrameCode: { type: String, title: 'Route Code' },
        playerId: { type: String, title: 'Player Id' },
        displayUnitId: { type: String, title: 'Display Unit Id' },
        name: { type: String, title: 'Name' },
        type: { type: String, title: 'Type' },
        group: { type: String, title: 'Group' },
        size: { type: String, title: 'Size' },
        location: { type: String, title: 'Location' },
        [t('Remove')]: { type: String, title: 'Remove' },
      },
      campaign.get('is-active') ? { [t('View')]: { type: String, title: 'View' } } : null,
    );

    frames = frames
      .map((frame) => {
        let location = frame.get('location', new Map());
        const specification = frame.get('specification', new Map());
        // handle null locations
        location = !Map.isMap(location) ? new Map() : location;
        const deletedClass = frame.get('deleted-at') === undefined ? null : style.deleted;
        return Object.assign(
          {
            externalId: <span className={deletedClass}>{frame.get('external-id')}</span>,
            extendedCode: <span className={deletedClass}>{frame.get('extended-code')}</span>,
            routeFrameCode: <span className={deletedClass}>{frame.get('route-code')}</span>,
            playerId: (
              <span className={deletedClass}>
                {List.isList(frame.get('player-id', List()))
                  ? frame
                      .get('player-id', List())
                      .toJS()
                      .join(', ')
                  : null}
              </span>
            ),
            displayUnitId: (
              <span className={deletedClass}>
                {List.isList(frame.get('display-unit-id', List()))
                  ? frame
                      .get('display-unit-id', List())
                      .toJS()
                      .join(', ')
                  : null}
              </span>
            ),
            name: <span className={deletedClass}>{frame.get('name')}</span>,
            type: <span className={deletedClass}>{specification.get('name')}</span>,
            group: <span className={deletedClass}>{frame.get('group')}</span>,
            size: (
              <span className={deletedClass}>{`${specification.get('width')} x ${specification.get('height')}`}</span>
            ),
            location: (
              <span className={deletedClass}>
                {location.size
                  ? `${location.get('route', '')}, ${location.get('locality', '')}, ${location.get('country-code', '')}`
                  : ''}
              </span>
            ),
            [t('Remove')]: frame.get('is-manually-attached') && (
              <DeleteConfirmButton
                className={style.deleteConfirmButton}
                onConfirmClick={() => this.handleFrameDelete(frame.get('external-id'))}
                key={frame.get('external-id')}
              />
            ),
          },
          campaign.get('is-active')
            ? {
                [t('View')]: (
                  <Actions className={style.actions}>{this.renderScreenshotsAction(frame.get('id'))}</Actions>
                ),
              }
            : null,
        );
      })
      .toJS();

    return (
      <div>
        <Table className={style.mediaBuyTable} model={model} source={frames} />
        {this.renderPaginationFrames()}
        {this.renderExportCsvButton()}
        <AddFramesWidget fetchFrames={this.fetchFramesDebounced} />
      </div>
    );
  };

  renderMediaBuyFilter = () => {
    const { t, filterValue } = this.props;

    return (
      <Input
        className={style.filter}
        label={t('Filter')}
        type="text"
        value={filterValue}
        onChange={this.handleFilterChange}
      />
    );
  };

  renderMediaBuyHeading = () => {
    const { paginationFrames, isFetchingFrames, t } = this.props;
    let framesTotal = '';
    if (!isFetchingFrames) {
      framesTotal = ` (${paginationFrames.get('total', 0)})`;
    }

    return (
      <Heading className={style.tableHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
        <Icon iconType={IconTypes.TV} />
        {t('Frames')}
        {framesTotal}
      </Heading>
    );
  };

  renderMediaBuy = () => (
    <div className={style.tableContainer}>
      {this.renderMediaBuyHeading()}
      {this.renderMediaBuyFilter()}
      {this.renderMediaBuyTable()}
    </div>
  );

  renderHeading = () => {
    const { t } = this.props;
    return <HeadingGroup title={t('Media Buy')} intro={t('Media Buy section introduction')} />;
  };

  renderPaginationFrames = () => {
    const { isFetchingFrames, paginationFrames } = this.props;
    const pageNumber = this.getPageNumber();
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <Pagination
        forceSelected={pageNumber - 1}
        hidden={isFetchingFrames}
        initialSelected={initialSelected}
        onClick={this.handleFramesPaginationClick}
        pageNum={paginationFrames.get('total_pages')}
      />
    );
  };

  renderMediaBuyHistory = () => (
    <div className={style.tableContainer}>
      {this.renderMediaBuyHistoryHeading()}
      {this.renderMediaBuyHistoryTable()}
    </div>
  );

  renderMediaBuyHistoryHeading = () => (
    <Heading className={style.tableHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
      <Icon iconType={IconTypes.HISTORY} />
      History
    </Heading>
  );

  renderMediaBuyHistoryTable = () => {
    const { isFetchingFrameHistoryStats } = this.props;
    let { frameHistoryStats } = this.props;

    if (isFetchingFrameHistoryStats) {
      return <ProgressBar />;
    }

    const model = {
      date: { type: String, title: 'Change Date (UTC)' },
      frames: { type: String, title: 'Total Frames' },
      added: { type: String, title: 'Added' },
      removed: { type: String, title: 'Removed' },
    };

    frameHistoryStats = frameHistoryStats
      .map((history) => ({
        date: moment(history.get('created-at')).format('DD MMM YYYY h:mm:ss a'),
        frames: history.get('frame-ids').size.toString(),
        added: history.get('new').toString(),
        removed: history.get('removed').toString(),
      }))
      .reverse()
      .toJS();

    return (
      <div>
        <Table model={model} source={frameHistoryStats} />
      </div>
    );
  };

  renderExportCsvButton = () => {
    const { isFetchingExportData, exportDataItems, isFetchingFrames } = this.props;

    if (isFetchingFrames) {
      return null;
    }

    const csvFileFieldSpec = {
      'External Id': 'external-id',
      'Frame Code': 'extended-code',
      'Route Code': 'route-code',
      'Player Id': 'player-id',
      'Display Unit Id': 'display-unit-id',
      Name: 'name',
      Type: ['specification', 'name'],
      Group: 'group',
      Width: ['specification', 'width'],
      Height: ['specification', 'height'],
      Latitude: 'lat',
      Longitude: 'lng',
      'Business Unit': 'business-unit',
    };

    return (
      <ExportCsvButton
        onFetchComplete={this.handleFetchFramesCsv}
        dataItems={exportDataItems}
        fieldSpec={csvFileFieldSpec}
        fileName="mb-frames.csv"
        fetching={isFetchingExportData}
        hidden={isFetchingFrames}
      />
    );
  };

  render = () => (
    <div className={style.component}>
      {this.renderHeading()}
      {this.renderMediaBuy()}
      {this.renderMediaBuyHistory()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  isFetchingFrames: state.campaignMediaBuy.isFetchingFrames,
  isFetchingFrameHistoryStats: state.campaignMediaBuy.isFetchingFrameHistoryStats,
  frames: state.campaignMediaBuy.frames,
  frameHistoryStats: state.campaignMediaBuy.frameHistoryStats,
  paginationFrames: state.campaignMediaBuy.paginationFrames,
  filterValue: state.campaignMediaBuy.filterValue,
  isFetchingExportData: state.campaignMediaBuy.isFetchingExportData,
  exportDataItems: state.campaignMediaBuy.exportDataItems,
  user: state.auth.user,
});

export default withNamespaces(['common'], { wait: false })(withRouter(connect(mapStateToProps)(MediaBuy)));

MediaBuy.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isFetchingFrames: PropTypes.bool.isRequired,
  isFetchingFrameHistoryStats: PropTypes.bool.isRequired,
  frames: PropTypes.instanceOf(List).isRequired,
  filterValue: PropTypes.string.isRequired,
  frameHistoryStats: PropTypes.instanceOf(List).isRequired,
  paginationFrames: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  isFetchingExportData: PropTypes.bool.isRequired,
  exportDataItems: PropTypes.object.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
};
