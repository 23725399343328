import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import ProgressBar from 'components/patterns/ProgressBar';
import FixedHeadings from 'assets/components/presentational/Users/PermissionsBusinessUnits/FixedHeadings';
import Row from 'assets/components/presentational/Users/PermissionsBusinessUnits/Row';

import style from './permissionsBusinessUnits.scss';

class PermissionsBusinessUnits extends Component {
  renderProgressBar() {
    return (
      <div className={style.progress}>
        <ProgressBar />
      </div>
    );
  }

  renderMatrixHeadings() {
    const { permissions, t } = this.props;

    if (permissions.first().size < 1) {
      return null;
    }

    const headings = permissions
      .first()
      .get('options')
      .reduce((acc, heading) => acc.concat(heading.get('display-name')), []);

    headings.push(t('Revoke Campaign Permissions'));
    return <FixedHeadings headings={headings} />;
  }

  renderMatrixRows() {
    const { t, permissions, onCheckboxChange, onRevokePermissionsClick, isRevokingPermissions } = this.props;
    const matrixKeys = permissions
      .sortBy((i) => i.get('name'))
      .keySeq()
      .toJS();

    return matrixKeys.map((item) => (
      <Row
        key={item}
        rowId={item}
        rowHeading={permissions.getIn([item, 'name'])}
        options={permissions.getIn([item, 'options'])}
        onCheckboxChange={onCheckboxChange}
        onRevokePermissionsClick={onRevokePermissionsClick}
        isRevokingPermissions={isRevokingPermissions}
        t={t}
      />
    ));
  }

  render() {
    const { isFetching, isUpdating, permissions, maxHeight } = this.props;

    if (isFetching || permissions.size < 1) {
      return this.renderProgressBar();
    }

    return (
      <div className={style.component}>
        {isUpdating && this.renderProgressBar()}
        {this.renderMatrixHeadings()}
        <ul style={{ maxHeight }}>{this.renderMatrixRows()}</ul>
      </div>
    );
  }
}

export default PermissionsBusinessUnits;

PermissionsBusinessUnits.propTypes = {
  t: PropTypes.func.isRequired,
  permissions: PropTypes.instanceOf(Map).isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onRevokePermissionsClick: PropTypes.func.isRequired,
  isRevokingPermissions: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
};
