import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { fetchAdditionalCreativeData } from 'actions/creative';

import CreativeDetail from './CreativeDetail';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAdditionalCreativeData,
    },
    dispatch,
  );

export default withNamespaces(['common', 'contentSchedule'], { wait: false })(
  connect(null, mapDispatchToProps)(CreativeDetail),
);
