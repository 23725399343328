import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { userHasCampaignPermission } from 'store/user/helpers';

import SubNavigation from 'assets/components/presentational/SubNavigation';

export const SubNavigationLabels = {
  ALL_CONTENT: 'All Content',
  CONTENT_TYPES: 'Content Types',
};

class SubNavigationContent extends Component {
  items = () => {
    const { campaignId, user } = this.props;

    const nav = [
      {
        label: SubNavigationLabels.ALL_CONTENT,
        to: `campaigns/${campaignId}/content`,
      },
    ];

    if (userHasCampaignPermission(user, campaignId, 'manage_dynamic_content_types')) {
      nav.push({
        label: SubNavigationLabels.CONTENT_TYPES,
        to: `campaigns/${campaignId}/content-types`,
      });
    }

    return nav;
  };

  render() {
    const { active } = this.props;

    return <SubNavigation active={active} labels={SubNavigationLabels} items={this.items()} />;
  }
}

SubNavigationContent.propTypes = {
  active: PropTypes.string,
  campaignId: PropTypes.string,
  user: PropTypes.instanceOf(Map),
};

SubNavigationContent.defaultProps = {
  action: '',
  campaignId: '',
  user: new Map(),
};

export default SubNavigationContent;
