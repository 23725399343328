import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import style from './htmlPlayButton.scss';

class HtmlPlayButton extends Component {
  getPosition = () => {
    const { position } = this.props;
    const parentClassName = style.playButtonContainer;
    let className = '';

    switch (position) {
      case 'bottom_left':
        className = style.playButton_bottomLeft;
        break;
      case 'top_left':
        className = style.playButton_topLeft;
        break;
      case 'top_right':
        className = style.playButton_topRight;
        break;
      case 'bottom_right':
        className = style.playButton_bottomRight;
        break;
      default:
        className = style.playButton_topLeft;
        break;
    }

    return className.concat(' ', parentClassName);
  };

  render() {
    const { handleHtmlPlayClick } = this.props;
    return (
      <span className={this.getPosition()}>
        <Button
          className={style.playButton}
          icon={<Icon iconType={IconTypes.PLAY_CIRCLE_OUTLINE} />}
          theme={ButtonThemes.GREEN}
          floating
          onClick={handleHtmlPlayClick}
        />
      </span>
    );
  }
}

HtmlPlayButton.propTypes = {
  handleHtmlPlayClick: PropTypes.func,
  position: PropTypes.string,
};

HtmlPlayButton.defaultProps = {
  position: 'top_left',
};

export default HtmlPlayButton;
