import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { snoozeDropdownChange } from 'store/snoozeCampaign/actions';

import SnoozeCampaign from './SnoozeCampaign';

const mapStateToProps = (state) => ({
  selectedValue: state.snooze.selectedValue,
  snoozeCampaign: state.snooze.campaign,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDropdownChange: snoozeDropdownChange,
    },
    dispatch,
  );

export default withNamespaces(['snoozeCampaign'], { wait: false })(
  connect(mapStateToProps, mapDispatchToProps)(SnoozeCampaign),
);
