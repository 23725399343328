import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const calcPoints = (vertical, marks, dots, step, min, max) => {
  const points = Object.keys(marks).map(parseFloat);
  if (dots) {
    for (let i = min; i <= max; i += step) {
      if (points.indexOf(i) >= 0) continue; // eslint-disable-line no-continue
      points.push(i);
    }
  }
  return points;
};

const Steps = ({ prefixCls, vertical, marks, dots, step, included, lowerBound, upperBound, max, min }) => {
  const range = max - min;
  const elements = calcPoints(vertical, marks, dots, step, min, max).map((point) => {
    const offset = `${(Math.abs(point - min) / range) * 100}%`;
    const style = vertical ? { bottom: offset } : { left: offset };

    const isActived = (!included && point === upperBound) || (included && point <= upperBound && point >= lowerBound);
    const pointClassName = classNames({
      [`${prefixCls}-dot`]: true,
      [`${prefixCls}-dot-active`]: isActived,
    });

    return <span className={pointClassName} style={style} key={point} />;
  });

  return <div className={`${prefixCls}-step`}>{elements}</div>;
};

Steps.propTypes = {
  prefixCls: PropTypes.string,
  vertical: PropTypes.bool,
  marks: PropTypes.object,
  step: PropTypes.number,
  dots: PropTypes.bool,
  included: PropTypes.bool,
  upperBound: PropTypes.number,
  lowerBound: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default Steps;
