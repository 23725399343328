import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Dropdown from 'assets/components/presentational/Dropdown';
import Input from 'components/patterns/Input';

import AddSetButton from '../AddSetButton';
import SetContainer from '../SetContainer';

import style from './temperatureForm.scss';

const Options = {
  LOCATION_OPTIONS: 0,
  DAY_OPTIONS: 1,
  OP_OPTIONS: 2,
  TEMP_OPTIONS: 3,
  TEMP: 4,
  UNIT_OPTIONS: 5,
  LOCATION_1: 6,
  LOCATION_2: 7,
};

const LocationOptions = {
  FRAME_VALUE: 'frame_value',
  FRAME_LOCATION: 'frame_location',
  LOCATION_VALUE: 'location_value',
  LOCATION_LOCATION: 'location_location',
};

class TemperatureForm extends Component {
  handleRemoveSetClick = (key) => {
    const { fields, notifyError, t } = this.props;
    if (fields.length > 1) {
      fields.removeField(key);
    } else {
      notifyError(t('You cannot delete this since it is the only sub rule available'));
    }
  };

  addNewSet = () => {
    const { fields, ruleType } = this.props;

    return fields.addField(ruleType.get('default-value').toJS()[0]);
  };

  renderGroupFromLocationOptions = (set) => {
    const locationOption = set[Options.LOCATION_OPTIONS].value;

    switch (locationOption) {
      case LocationOptions.FRAME_VALUE:
        return this.renderGroupCompareToChosenValue(set);
      case LocationOptions.LOCATION_VALUE:
        return this.renderGroupCompareToChosenValue(set);
      case LocationOptions.FRAME_LOCATION:
        return this.renderGroupFrameLocation(set);
      case LocationOptions.LOCATION_LOCATION:
        return this.renderGroupLocationLocation(set);
    }
  };

  renderGroupCompareToChosenValue = (set) => {
    const { t, ruleType } = this.props;

    return (
      <div className={style.rulesSection}>
        {this.renderDropDown(
          set[Options.OP_OPTIONS],
          ruleType.getIn(['options', Options.OP_OPTIONS]),
          Options.OP_OPTIONS,
        )}
        <Input className={style.temperatureInput} label={t('Temperature')} type="text" {...set[Options.TEMP]} />
        <span className={style.separator}>{t('degrees')}</span>
        {this.renderDropDown(
          set[Options.UNIT_OPTIONS],
          ruleType.getIn(['options', Options.UNIT_OPTIONS]),
          Options.UNIT_OPTIONS,
          style.temperatureUnitDropdown,
        )}
      </div>
    );
  };

  renderGroupFrameLocation = (set) => {
    const { t, ruleType } = this.props;

    return (
      <div className={style.rulesSection}>
        {this.renderDropDown(
          set[Options.OP_OPTIONS],
          ruleType.getIn(['options', Options.OP_OPTIONS]),
          Options.OP_OPTIONS,
        )}
        <span className={style.separator}>{t('the temperature at')}</span>
        <Input
          className={style.locationInput}
          label={t('latitude, longitude (e.g. 51.52,-0.15)')}
          type="text"
          {...set[Options.LOCATION_1]}
        />
      </div>
    );
  };

  renderGroupLocationLocation = (set) => {
    const { t, ruleType } = this.props;

    return (
      <div className={style.rulesSection}>
        {this.renderDropDown(
          set[Options.OP_OPTIONS],
          ruleType.getIn(['options', Options.OP_OPTIONS]),
          Options.OP_OPTIONS,
        )}
        <span className={style.separator}>{t('the temperature at')}</span>
        <Input
          className={style.locationInput}
          label={t('latitude, longitude (e.g. 51.52,-0.15)')}
          type="text"
          {...set[Options.LOCATION_2]}
        />
      </div>
    );
  };

  renderDropDown = (field, options, key, className = null) => {
    const { ruleType } = this.props;

    return (
      <Dropdown
        className={className || style.dropdown}
        disabled={!ruleType.get('is-selected')}
        key={key}
        onChange={(param) => field.onChange(param.val)}
        source={options.toJS()}
        allowBlank={false}
        {...field}
      />
    );
  };

  renderSets = () => {
    const { fields } = this.props;

    return fields.map((set, key) => this.renderSet(set, key));
  };

  renderSet = (set, key) => {
    const { ruleType, t } = this.props;

    const comparingFrameToTemp = [LocationOptions.FRAME_VALUE].includes(set[Options.LOCATION_OPTIONS].value);
    const comparingFrameToLocation = [LocationOptions.FRAME_LOCATION].includes(set[Options.LOCATION_OPTIONS].value);
    const comparingLocationToTemp = [LocationOptions.LOCATION_VALUE].includes(set[Options.LOCATION_OPTIONS].value);
    const comparingLocationToLocation = [LocationOptions.LOCATION_LOCATION].includes(
      set[Options.LOCATION_OPTIONS].value,
    );

    const isFrameRule = comparingFrameToTemp || comparingFrameToLocation;

    return (
      <SetContainer
        enabled={ruleType.get('is-selected')}
        key={key}
        onDeleteClick={this.handleRemoveSetClick}
        sequence={key}
      >
        <div className={style.ruleHeader}>
          <span className={style.separator}>{t('I want this rule to work based on:')}</span>
          {this.renderDropDown(
            set[Options.LOCATION_OPTIONS],
            ruleType.getIn(['options', Options.LOCATION_OPTIONS]),
            Options.LOCATION_OPTIONS,
            style.dropdownWide,
          )}
          {comparingFrameToTemp ? <p className={style.example}>{t('example: comparingFrameToTemp')}</p> : null}
          {comparingFrameToLocation ? <p className={style.example}>{t('example: comparingFrameToLocation')}</p> : null}
          {comparingLocationToTemp ? <p className={style.example}>{t('example: comparingLocationToTemp')}</p> : null}
          {comparingLocationToLocation ? (
            <p className={style.example}>{t('example: comparingLocationToLocation')}</p>
          ) : null}
        </div>

        <div className={style.temperatureContainer}>
          <span className={style.separator}>{t('If the')}</span>
          {this.renderDropDown(
            set[Options.TEMP_OPTIONS],
            ruleType.getIn(['options', Options.TEMP_OPTIONS]),
            Options.TEMP_OPTIONS,
          )}
          {isFrameRule ? (
            <span className={style.separator}>{t('at the frame')}</span>
          ) : (
            <div>
              <span className={style.separator}>{t('at the location')}</span>
              <Input
                className={style.locationInput}
                label={t('latitude, longitude (e.g. 51.52,-0.15)')}
                type="text"
                {...set[Options.LOCATION_1]}
              />
            </div>
          )}
          {this.renderDropDown(
            set[Options.DAY_OPTIONS],
            ruleType.getIn(['options', Options.DAY_OPTIONS]),
            Options.DAY_OPTIONS,
            style.dropdownShort,
          )}
          <span className={style.separator}>{t('is')}</span>
          {this.renderGroupFromLocationOptions(set)}
        </div>
      </SetContainer>
    );
  };

  render() {
    const { ruleType, t } = this.props;
    return (
      <div className={style.component}>
        {this.renderSets()}
        <AddSetButton
          disabled={!ruleType.get('is-selected')}
          label={t('Add temperature range')}
          onClick={this.addNewSet}
        />
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(TemperatureForm);

TemperatureForm.propTypes = {
  fields: PropTypes.array.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  notifyError: PropTypes.func,
};

TemperatureForm.defaultProps = {
  notifyError: () => {},
};
