import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';

import { fetchCreativeForPreview } from 'actions/drop-zone';
import { setActiveSection } from 'actions/campaign/navigation';

import CreativePreviewDropZone from './CreativePreviewDropZone';

const mapStateToProps = (state) => ({
  campaignInvite: state.dropZone.campaignInvite,
  creative: state.dropZone.creative,
  isFetching: state.dropZone.isFetchingCreative,
  frameSpecifications: state.dropZone.campaignInvite.getIn(['campaign', 'frame-specifications'], new List()),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveSection,
      fetchCreativeForPreview,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreativePreviewDropZone);
