import { fetchCreativesForPreview } from './creative';

export const CAMPAIGN_PREVIEW_RESET_STATE = 'CAMPAIGN_PREVIEW_RESET_STATE';
export const CAMPAIGN_PREVIEW_FETCH_CREATIVES_REQUEST = 'CAMPAIGN_PREVIEW_FETCH_CREATIVES_REQUEST';
export const CAMPAIGN_PREVIEW_FETCH_CREATIVES_SUCCESS = 'CAMPAIGN_PREVIEW_FETCH_CREATIVES_SUCCESS';
export const CAMPAIGN_PREVIEW_FETCH_CREATIVES_COMPLETE = 'CAMPAIGN_PREVIEW_FETCH_CREATIVES_COMPLETE';
export const CAMPAIGN_PREVIEW_SET_ACTIVE_CREATIVE_ID = 'CAMPAIGN_PREVIEW_SET_ACTIVE_CREATIVE_ID';
export const CAMPAIGN_PREVIEW_SET_ACTIVE_RULESET_IDS = 'CAMPAIGN_PREVIEW_SET_ACTIVE_RULESET_IDS';
export const CAMPAIGN_PREVIEW_SET_ACTIVE_CONTENT = 'CAMPAIGN_PREVIEW_SET_ACTIVE_CONTENT';
export const CAMPAIGN_PREVIEW_CLEAR_ACTIVE_CONTENT = 'CAMPAIGN_PREVIEW_CLEAR_ACTIVE_CONTENT';

export const fetchCreatives = (campaignId, ruleSetIds = [], frameSpecificationId = '', contentItemIds = []) => (
  dispatch,
) => {
  dispatch({ type: CAMPAIGN_PREVIEW_FETCH_CREATIVES_REQUEST });
  return dispatch(
    fetchCreativesForPreview(
      campaignId,
      ['prepared-asset', 'frame-specification', 'rule-sets', 'content-types'],
      ['preview-frame', 'preview-content-items', 'preview-rule-sets'],
      ruleSetIds,
      frameSpecificationId,
      contentItemIds,
    ),
  )
    .then((creatives) => {
      dispatch({
        type: CAMPAIGN_PREVIEW_FETCH_CREATIVES_SUCCESS,
        creatives,
        ruleSetIds,
        contentItemIds,
        frameSpecificationId,
      });
    })
    .then(() => {
      dispatch({ type: CAMPAIGN_PREVIEW_FETCH_CREATIVES_COMPLETE });

      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: CAMPAIGN_PREVIEW_FETCH_CREATIVES_COMPLETE });

      return Promise.resolve();
    });
};

export const clearPreview = () => ({
  type: CAMPAIGN_PREVIEW_RESET_STATE,
});

export const setActiveCreativeId = (activeCreativeId) => ({
  type: CAMPAIGN_PREVIEW_SET_ACTIVE_CREATIVE_ID,
  activeCreativeId,
});

export const setActiveRuleSetIds = (activeRuleSetIds) => ({
  type: CAMPAIGN_PREVIEW_SET_ACTIVE_RULESET_IDS,
  activeRuleSetIds,
});

export const setActiveContent = (activeContent) => ({
  type: CAMPAIGN_PREVIEW_SET_ACTIVE_CONTENT,
  activeContent,
});
