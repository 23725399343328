import React from 'react';
import PropTypes from 'prop-types';
import SimpleFileInput from 'assets/components/presentational/SimpleFileInput';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import styles from './field.scss';

const formatHint = (t, spec) => {
  if (!spec) return '';
  const keys = Object.keys(spec);
  if (!keys.length) return;
  const hint = t('Video should match the following specification: ');
  return `${hint} ${keys
    .map((k) => {
      // All keys should have integer values that can be parsed as ints
      if (isNaN(parseInt(spec[k]))) {
        return null;
      }
      switch (k) {
        case 'width':
          return t('Width {{x}} pixels', { x: spec[k] });
        case 'height':
          return t('Height {{x}} pixels', { x: spec[k] });
        case 'bitrate':
          return t('Maximum {{x}} megabits per second', { x: spec[k] });
        case 'fps':
          return t('Maximum {{x}} frames per second', { x: spec[k] });
        default:
          return '';
      }
    })
    .reduce((v, c) => `${v}, ${c}`)}`;
};

const Video = function(props) {
  const { config, field, readonly, t } = props;
  const hint = formatHint(t, config.meta);
  return (
    <div>
      <SimpleFileInput label={config.name} fileType="video" {...field} disabled={readonly} />
      {hint ? (
        <div className={styles.fieldHint}>
          <div className={styles.infoIcon}>
            <Icon iconType={IconTypes.INFO_OUTLINE} />
          </div>
          {hint}
        </div>
      ) : null}
    </div>
  );
};

Video.propTypes = {
  config: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default Video;
