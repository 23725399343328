import React from 'react';
import PropTypes from 'prop-types';

import ROUTES from 'modules/Constants/routes';

import Link from 'assets/components/presentational/Link';

const InDateLink = ({ onClick }) => {
  return (
    <div style={{ padding: '16px' }}>
      <Link to={`${ROUTES.DASHBOARD}1?inDate=true`} onClick={onClick}>
        Show In Date
      </Link>
    </div>
  );
};

InDateLink.propTypes = {
  onClick: PropTypes.func,
};

InDateLink.defaultProps = {
  onClick: () => {},
};

export default InDateLink;
