import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { truncate } from 'lodash';

import { ContentModerationStatuses } from 'modules/Helpers';

import Tooltip from 'components/patterns/Tooltip';
import Checkbox from 'assets/components/presentational/Checkbox';
import Dialog from 'assets/components/presentational/Dialog';
import ModerationStatusChip from 'assets/components/presentational/ModerationStatusChip';
import Tag, { TagTheme } from 'assets/components/presentational/Tag';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import Row from 'assets/components/presentational/Rows/Row';
import Details from 'assets/components/presentational/Rows/Details';
import Actions from 'assets/components/presentational/Rows/Actions';
import Action from 'assets/components/presentational/Rows/Action';

import style from './contentItemRow.scss';

const TooltipIcon = Tooltip(Icon);

class ContentItemRow extends Component {
  getStatusText = (status, t) => {
    switch (status) {
      case ContentModerationStatuses.REJECTED:
        return t('Rejected');
      case ContentModerationStatuses.PENDING:
        return t('Unmoderated');
      default:
        return t('Approved');
    }
  };

  getFieldsText = (contentFields, t) => {
    if (!contentFields) {
      return t('X fields', { count: 0 });
    }
    return t('X fields', { count: contentFields.size });
  };

  handleCheckboxChange = (checked) => {
    const { onCheckboxChange, contentItem } = this.props;
    onCheckboxChange(contentItem.get('id'), checked);
  };

  handleContentItemEditClick() {
    const { onEditClick, contentItem } = this.props;
    onEditClick(contentItem);
  }

  handleContentItemDeleteClick() {
    const { onDeleteClick, contentItem } = this.props;
    onDeleteClick(contentItem);
  }

  handleContentItemDeleteConfirmClick() {
    const { onDeleteConfirmClick, contentItem } = this.props;
    onDeleteConfirmClick(contentItem);
  }

  handleContentItemDeleteCancelClick() {
    const { onDeleteCancelClick, contentItem } = this.props;
    onDeleteCancelClick(contentItem);
  }

  handleContentItemSettingsClick() {
    const { onSettingsClick, contentItem } = this.props;
    onSettingsClick(contentItem);
  }

  handleContentItemViewClick() {
    const { onViewClick, contentItem } = this.props;
    onViewClick(contentItem);
  }

  isContentItemNew = () => {
    const { contentItem } = this.props;
    return moment().diff(contentItem.get('created-at'), 'minutes') < 20;
  };

  renderContentItemNewIcon = (contentItem) => {
    if (this.isContentItemNew(contentItem)) {
      return <Icon iconType={IconTypes.FIBER_NEW} color={IconColors.LIGHTBLUE} />;
    }

    return null;
  };

  renderDeleteDialog() {
    const { contentItem, isDeleting, isDeletable, t } = this.props;

    if (!isDeletable) {
      return null;
    }

    const actions = [
      { label: t('Cancel'), onClick: () => this.handleContentItemDeleteCancelClick() },
      { label: t('Yes'), onClick: () => this.handleContentItemDeleteConfirmClick() },
    ];

    return (
      <Dialog
        actions={actions}
        active={isDeleting}
        onEscKeyDown={() => this.handleContentItemDeleteCancelClick()}
        onOverlayClick={() => this.handleContentItemDeleteCancelClick()}
        type="small"
      >
        <p>{t('Are you sure you want to delete {{name}}?', { name: contentItem.get('name') })}</p>
      </Dialog>
    );
  }

  renderDeleteAction() {
    const { isDeletable } = this.props;

    if (!isDeletable) {
      return null;
    }

    return (
      <Action
        disabled={!isDeletable}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        onClick={() => this.handleContentItemDeleteClick()}
      />
    );
  }

  renderSettingsAction() {
    const { isConfigurable } = this.props;

    if (!isConfigurable) {
      return null;
    }

    return (
      <Action
        disabled={!isConfigurable}
        icon={<Icon iconType={IconTypes.SETTINGS} />}
        onClick={() => this.handleContentItemSettingsClick()}
      />
    );
  }

  renderSelectAction() {
    const { isSelectable, isSelected, isSaving } = this.props;

    if (!isSelectable) {
      return null;
    }

    return (
      <Checkbox
        className={style.checkBox}
        checked={isSelected}
        disabled={isSaving}
        onChange={this.handleCheckboxChange}
      />
    );
  }

  renderEditAction() {
    const { isEditable } = this.props;

    if (!isEditable) {
      return null;
    }

    return (
      <Action
        disabled={!isEditable}
        icon={<Icon iconType={IconTypes.EDIT} />}
        onClick={() => this.handleContentItemEditClick()}
      />
    );
  }

  renderViewAction() {
    const { isViewable } = this.props;

    if (!isViewable) {
      return null;
    }

    return (
      <Action
        disabled={!isViewable}
        icon={<Icon iconType={IconTypes.VISIBILITY} />}
        onClick={() => this.handleContentItemViewClick()}
      />
    );
  }

  renderModerationStatus = () => {
    const { contentItem, isModerationStatusDisplayed, t } = this.props;
    if (!isModerationStatusDisplayed) {
      return null;
    }

    const label = this.getStatusText(contentItem.get('status'), t);
    return <ModerationStatusChip label={label} status={contentItem.get('status')} />;
  };

  renderRejectReason = () => {
    const { contentItem, isModerationStatusDisplayed } = this.props;
    if (isModerationStatusDisplayed || !contentItem.get('reject-reason')) {
      return null;
    }
    return <TooltipIcon iconType={IconTypes.INFO_OUTLINE} tooltip={contentItem.get('reject-reason')} />;
  };

  render() {
    const { contentItem, isSelected, t } = this.props;
    const contentItemRowClass = isSelected ? style.contentItemRowSelected : style.contentItemRow;

    const title = contentItem.get('name');
    const contentTypeText = truncate(contentItem.get('content-type').get('name'), {
      length: 24,
      separator: ' ',
    });
    const fieldsText = this.getFieldsText(contentItem.get('content-type').get('fields'), t);
    const updatedText = `${t('Updated')}: ${
      contentItem.get('updated-at')
        ? moment(contentItem.get('updated-at')).fromNow()
        : moment(contentItem.get('created-at')).fromNow()
    }`;

    return (
      <div className={style.component}>
        <Row className={contentItemRowClass}>
          <Details className={style.detailsRow}>
            <div className={style.contentItemName}>
              <span className={style.contentItemNameRow}>
                <strong>{title}</strong>
                &nbsp;
                <span className={style.lastUpdated}>{` -${updatedText}`}</span>
                {this.renderModerationStatus()}
                {this.renderContentItemNewIcon()}
                {this.renderRejectReason()}
              </span>
              <div>
                <Tag className={style.tag} tagTheme={TagTheme.DEFAULT} copy={contentTypeText} />
                <Tag className={style.tag} tagTheme={TagTheme.GREY} copy={fieldsText} />
                {contentItem.get('rule-sets', List()).map((rule, key) => (
                  <Tag className={style.tag} tagTheme={TagTheme.GREY} copy={rule.get('name')} key={key} />
                ))}
              </div>
            </div>
          </Details>
          <Actions className={style.actions}>
            {this.renderEditAction()}
            {this.renderViewAction()}
            {this.renderSettingsAction()}
            {this.renderDeleteAction()}
            {this.renderSelectAction()}
          </Actions>
        </Row>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'content'], { wait: false })(ContentItemRow);

ContentItemRow.propTypes = {
  campaign: PropTypes.instanceOf(Map),
  contentItem: PropTypes.instanceOf(Map),
  isConfigurable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isViewable: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isModerationStatusDisplayed: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onDeleteCancelClick: PropTypes.func,
  onDeleteConfirmClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
  onViewClick: PropTypes.func,
  t: PropTypes.func,
};

ContentItemRow.defaultProps = {
  isDeletable: false,
  isDeleting: false,
  isConfigurable: false,
  isSelectable: false,
  isSelected: false,
  isEditable: false,
  isViewable: false,
  isSaving: false,
  isModerationStatusDisplayed: false,
};
