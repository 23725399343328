import config from 'app-config';

import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Logo from 'assets/components/presentational/Logo';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes, IconColors, IconSizes } from 'assets/components/presentational/Icon';

import style from './DownTime.scss';

const DownTime = ({ t }) => (
  <div className={style.component}>
    <Logo />

    <div className={style.prompt}>
      <div className={style.inner}>
        <Heading className={style.heading} size={HeadingSizes.LARGEST} tag={HeadingTags.H1}>
          {t('{{application}} is temporarily unavailable.', { application: config.app.name })}
        </Heading>
        <em className={style.messageBody}>
          {t(
            'We are performing some maintenance checks and performance improvements. We are working hard to be back up and running as soon as possible, please bear with us.',
          )}
        </em>
        <p className={style.messageOutro}>{t('Thank you for your patience')}</p>
        <span className={style.messageIcon}>
          <Icon iconType={IconTypes.SMS_FAILED} color={IconColors.LIGHTGREEN} size={IconSizes.EXTRALARGE} />
        </span>
      </div>
    </div>
  </div>
);

export default withNamespaces(['common'], { wait: false })(DownTime);

DownTime.propTypes = {
  t: PropTypes.func.isRequired,
};
