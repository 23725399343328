import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Input from 'components/patterns/Input';

import style from './user.scss';

class User extends Component {
  handleEmailChange = (email) => {
    const { user, onChange } = this.props;
    if (onChange) {
      onChange(user, email);
    }
  };

  render() {
    const { user, error, validate, t } = this.props;

    return (
      <div className={style.component}>
        <Input
          label={t('Email')}
          value={user.get('email', '')}
          onChange={this.handleEmailChange}
          type="text"
          onBlur={() => validate()}
          error={error}
        />
      </div>
    );
  }
}

User.propTypes = {
  user: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
  validate: PropTypes.func,
  t: PropTypes.func,
  error: PropTypes.string,
};

export default withNamespaces(['common', 'enhancedModeration'], { wait: false })(User);
