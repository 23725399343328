/**
 * Return the relevant rule type string
 *
 * @param  type {string}
 * @return {string|null}
 */
export const ruleType = (type) => {
  const ruleTypeMap = {
    weather: 'App\\Rules\\Weather',
    weather2: 'App\\Rules\\Weather2',
    temperature: 'App\\Rules\\Temperature',
    temperature2: 'App\\Rules\\Temperature2',
    location: 'App\\Rules\\Location',
    dayPart: 'App\\Rules\\DayPart',
    timeOfDay: 'App\\Rules\\TimeOfDay',
    dayOfWeek: 'App\\Rules\\DayOfWeek',
    displayUnit: 'App\\Rules\\DisplayUnit',
    uvIndex: 'App\\Rules\\UvIndex',
    flightProperties: 'App\\Rules\\Transport\\FlightProperties',
    tags: 'App\\Rules\\Tags',
    dates: 'App\\Rules\\Dates',
  };

  return type in ruleTypeMap ? ruleTypeMap[type] : null;
};
