import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import Input from 'components/patterns/Input';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Checkbox from 'assets/components/presentational/Checkbox';

import style from './userFormProfile.scss';

class ProfileForm extends Component {
  handleSubmit = (data) => this.props.handleUpdateUser(data); // eslint-disable-line react/destructuring-assignment

  isSelf = () => this.props.authUser.get('id') === this.props.userId; // eslint-disable-line react/destructuring-assignment

  renderUserType() {
    const { t, isSuper, isLite } = this.props;
    let userType = '';

    if (isSuper) {
      userType = t('Super user');
    }

    if (isLite) {
      userType = t('Lite user');
    }

    if (userType === '') return;

    return <span className={style.userType}>{` (${userType})`}</span>;
  }

  renderProfilePicture() {
    return (
      <div className={style.profilePicContainer}>
        <div className={style.profilePic}>
          <div className={style.profilePicImage}>
            <Icon iconType={IconTypes.ACCOUNT_CIRCLE} />
          </div>
        </div>
        {this.renderUserType()}
      </div>
    );
  }

  renderForm() {
    const {
      authUser,
      t,
      handleOnChange,
      handleSubmit,
      fields: { name, email, isSubscribedToCampaignDigest },
    } = this.props;

    return (
      <div className={style.profileForm}>
        <form onSubmit={handleSubmit(this.handleSubmit)} onChange={handleOnChange}>
          <Input
            type="text"
            label={t('Name')}
            value={name.value}
            onChange={name.onChange}
            error={name.error}
            disabled={!authUser.get('is-super-user') && !this.isSelf()}
          />
          <Input
            type="text"
            label={t('Email')}
            value={email.value}
            onChange={email.onChange}
            error={email.error}
            disabled={!authUser.get('is-super-user')}
          />
          <div className={style.digestEmail}>
            <Checkbox
              checked={!!isSubscribedToCampaignDigest.value}
              onChange={isSubscribedToCampaignDigest.onChange}
              disabled={!authUser.get('is-super-user') && !this.isSelf()}
            >
              <span className={style.digestEmailLabel}>{t('Receive campaign digest emails')}</span>
            </Checkbox>
          </div>
          {this.renderActions()}
        </form>
      </div>
    );
  }

  renderActions() {
    const {
      t,
      disableCancelAction,
      disableSubmitAction,
      handleCancel,
      showActions,
      isUserEdit,
      isConvertingLiteToFull,
    } = this.props;

    if (!showActions) {
      return null;
    }

    const saveText = isConvertingLiteToFull ? t('Convert and Save') : t('Save');

    return (
      <div className={style.actionGroup}>
        <Button
          label={isUserEdit || isConvertingLiteToFull ? t('Reset') : t('Cancel')}
          className={style.cancelButton}
          type="button"
          onClick={handleCancel}
          theme={ButtonThemes.WHITE}
          disabled={disableCancelAction}
          tabIndex="-1"
        />
        <Button
          label={saveText}
          type="submit"
          className={style.saveButton}
          disabled={disableSubmitAction}
          tabIndex="-1"
        />
      </div>
    );
  }

  render() {
    return (
      <div className={style.profileContainer}>
        {this.renderProfilePicture()}
        {this.renderForm()}
      </div>
    );
  }
}

export default ProfileForm;

ProfileForm.propTypes = {
  authUser: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleUpdateUser: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  showActions: PropTypes.bool.isRequired,
  disableCancelAction: PropTypes.bool,
  disableSubmitAction: PropTypes.bool,
  isSuper: PropTypes.bool.isRequired,
  isLite: PropTypes.bool,
  isUserEdit: PropTypes.bool,
  isConvertingLiteToFull: PropTypes.bool,
};

ProfileForm.defailtProps = {
  isConvertingLiteToFull: false,
  isSuper: false,
};
