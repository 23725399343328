import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { List as ReactList, ListItem } from 'react-toolbox/lib/list';
import UsersListItem from 'assets/components/presentational/Users/UsersList/UsersListItem';

import style from './usersList.scss';

class UsersList extends Component {
  renderContent() {
    return <ReactList>{this.renderListItems()}</ReactList>;
  }

  renderListItems() {
    const {
      t,
      users,
      handleEditUserClick,
      handleDeleteUserClick,
      handleConvertToFullUserClick,
      handleRestoreUserClick,
      hasFetchedUsers,
      isFetchingUsers,
      allowDelete,
    } = this.props;

    if (hasFetchedUsers && users.size < 1) {
      return <ListItem key="0" className={style.row} itemContent={<p>{t('No matching users')}</p>} />;
    }

    return users.map((user) => (
      <UsersListItem
        allowDelete={allowDelete}
        key={user.get('id')}
        user={user}
        t={t}
        isFetchingUsers={isFetchingUsers}
        onEditUserClick={handleEditUserClick}
        onDeleteUserClick={handleDeleteUserClick}
        onRestoreUserClick={handleRestoreUserClick}
        onConvertToFullUserClick={handleConvertToFullUserClick}
      />
    ));
  }

  render() {
    return <div className={style.component}>{this.renderListItems()}</div>;
  }
}

export default UsersList;

UsersList.propTypes = {
  allowDelete: PropTypes.bool,
  t: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(List).isRequired,
  handleEditUserClick: PropTypes.func.isRequired,
  handleDeleteUserClick: PropTypes.func.isRequired,
  handleRestoreUserClick: PropTypes.func.isRequired,
  handleConvertToFullUserClick: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  hasFetchedUsers: PropTypes.bool,
  isFetchingUsers: PropTypes.bool,
};

UsersList.defaultProps = {
  allowDelete: false,
};
