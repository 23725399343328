import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'assets/components/presentational/Chip';

import style from './Chips.scss';

const Chips = ({ source, onDelete }) => (
  <>
    {source.map((item) => {
      return (
        <Chip key={item.value} deletable onDeleteClick={(e) => onDelete(item.value, e)} theme={{ chip: style.chip }}>
          {item.label}
        </Chip>
      );
    })}
  </>
);

Chips.propTypes = {
  source: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func,
};

Chips.defaultProps = {
  source: [],
  onDelete: () => {},
};

export default Chips;
