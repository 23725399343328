import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List, Map } from 'immutable';

import { mapLocationArraysToStrings } from 'assets/components/containers/Campaign/Rulesets/RuleSetDrawerContainer/RuleSetDrawer/LocationForm';
import {
  TagsFormFields,
  getTagsFormInitialEmptyState,
} from 'assets/components/containers/Campaign/Rulesets/RuleSetDrawerContainer/RuleSetDrawer/TagsForm';

import RuleSetDrawer from './RuleSetDrawer';

class RuleSetDrawerContainer extends Component {
  // Dynamically creates the required redux-form fields from the rule types.
  mapFields = () => {
    const { ruleTypes } = this.props;

    // Start with the id and name of the rule-set
    const fields = ['id', 'name'];

    // For each rule-type
    ruleTypes.forEach((ruleType) => {
      const id = ruleType.get('id');

      // We need to create an id and type field, e.g. "rules.weather.id" and "rules.weather.type"
      fields.push('rules.'.concat(id, '.id'));
      fields.push('rules.'.concat(id, '.type'));

      // Finally, for each option we need to create a data field, e.g. "rules.weather.data.0"
      // This corresponds to the value expected by the API when saving
      ruleType.get('options').forEach((option, key) => {
        // Determines whether we can have an array of rule data, for example on the temperature rule
        // where we can add multiple constraints
        const isArray = List.isList(ruleType.get('default-value'));

        fields.push('rules.'.concat(id, '.data', isArray ? '[]' : '', '.', key.toString()));

        if (ruleType.get('type') === 'App\\Rules\\Tags') {
          const initialTagSuggestionKey = `rules.${id}.initialTagSuggestions${isArray ? '[]' : ''}.${key}`;
          fields.push(initialTagSuggestionKey);
        }
      });
    });
    return fields;
  };

  getInitialValues = () => {
    const { ruleSet, ruleTypes } = this.props;

    const initialValues = {
      id: ruleSet.get('id', ''),
      name: ruleSet.get('name', ''),
      rules: [],
    };

    ruleTypes.forEach((ruleType) => {
      const id = ruleType.get('id');
      const rules =
        ruleSet.has('rules') && ruleSet.get('rules').filter((rule) => rule.get('type') === ruleType.get('type'));

      if (rules.size) {
        const filteredRules = rules
          .map((rule) => {
            // FIXME: Fixes SMAR-426 intermittent location rule bug
            // Nicer alternative would be to add a componentWillReceiveProps() method to LocationForm
            // and check if the form has just been initialised. If it has, perform the map.
            if (ruleType.get('type') === 'App\\Rules\\Location') {
              return new Map({
                id: rule.get('id'),
                data: fromJS([mapLocationArraysToStrings(rule.get('data').toJS()[0])]),
              });
            }
            // TODO: Do something sane here
            if (ruleType.get('type') === 'App\\Rules\\TimeOfDay' || ruleType.get('type') === 'App\\Rules\\Dates') {
              return new Map({
                id: rule.get('id'),
                data: rule.get('data').map((d) => [d.get('from'), d.get('to')]),
              });
            }

            if (ruleType.get('type') === 'App\\Rules\\Tags') {
              return new Map({
                id: rule.get('id'),
                data: rule.get('data').map((d) => {
                  const { SUB_TAG, TAG_OPERATOR } = TagsFormFields;

                  const isSubTagEmpty = !d.get(SUB_TAG);
                  const isOperatorHasAnyValueSelected = d.get(TAG_OPERATOR) === 3;
                  if (isSubTagEmpty || isOperatorHasAnyValueSelected) {
                    return d.set(SUB_TAG, '');
                  }

                  return d;
                }),
                initialTagSuggestions: rule.get('data').map((d, index) => {
                  const { TAG, TAG_OPERATOR, SUB_TAG } = TagsFormFields;
                  const suggestion = getTagsFormInitialEmptyState();

                  suggestion[TAG] = {
                    [d.get(TAG)]: rule
                      .get('display-data')
                      .get(index)
                      .get('tag-key'),
                  };

                  const isSubTag = d.get(SUB_TAG);
                  const isOperatorHasAnyValueSelected = d.get(TAG_OPERATOR) === 3;
                  if (isSubTag && !isOperatorHasAnyValueSelected) {
                    suggestion[SUB_TAG] = {
                      [d.get(SUB_TAG)]: d.get(SUB_TAG),
                    };
                  }

                  return suggestion;
                }),
              });
            }

            return new Map({ id: rule.get('id'), data: rule.get('data') });
          })
          .toJS()[0];

        initialValues.rules[id] = filteredRules;
      } else {
        initialValues.rules[id] = { data: ruleType.get('default-value').toJS() };

        if (id === 'tags') {
          initialValues.rules[id].initialTagSuggestions = [getTagsFormInitialEmptyState()];
        }
      }

      initialValues.rules[id].type = ruleType.get('type');
    });

    return initialValues;
  };

  render() {
    const { onCancelClick, onCloseClick, ruleSet, ruleTypes } = this.props;

    return (
      <RuleSetDrawer
        fields={this.mapFields()}
        initialValues={this.getInitialValues()}
        onCancelClick={onCancelClick}
        onCloseClick={onCloseClick}
        ruleSet={ruleSet}
        ruleTypes={ruleTypes}
      />
    );
  }
}

RuleSetDrawerContainer.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  ruleSet: PropTypes.instanceOf(Map).isRequired,
  ruleTypes: PropTypes.instanceOf(List).isRequired,
};

export default RuleSetDrawerContainer;
