import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { withNamespaces } from 'react-i18next';

import notify, { NotificationTypes } from 'actions/snackbar';

import { Snackbar as ReactToolboxSnackbar } from 'react-toolbox/lib/snackbar';
import Icon from 'assets/components/presentational/Icon';

import styles from './Snackbar.scss';

const cx = classNames.bind(styles);

class Snackbar extends Component {
  getLabel = () => {
    const { notifications, t } = this.props;
    let label = '';
    notifications.forEach((notification) => {
      label = label.concat(' ', t(notification.first(), { application: config.app.name }));
    });

    return label;
  };

  getIcon = () => {
    const { notificationType } = this.props;
    switch (notificationType) {
      case NotificationTypes.SUCCESS:
        return 'done';
      case NotificationTypes.WARNING:
        return 'warning';
      case NotificationTypes.ERROR:
        return 'error_outline';
      default:
        return '';
    }
  };

  clearNotifications = () => {
    const { dispatch } = this.props;
    dispatch(notify());
  };

  handleTimeout = () => {
    const { timesOut } = this.props;
    if (timesOut) {
      this.clearNotifications();
    }
  };

  render() {
    const { notifications, notificationType, action } = this.props;
    return (
      <ReactToolboxSnackbar
        active={notifications.size > 0}
        className={cx('snackbar', {
          'snackbar--info': notificationType === NotificationTypes.INFO,
          'snackbar--success': notificationType === NotificationTypes.SUCCESS,
          'snackbar--warning': notificationType === NotificationTypes.WARNING,
          'snackbar--error': notificationType === NotificationTypes.ERROR,
        })}
        icon={<Icon iconType={this.getIcon()} />}
        label={this.getLabel()}
        onClick={this.clearNotifications}
        onTimeout={this.handleTimeout}
        timeout={10000}
        action={action}
        type="accept"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.snackbar.notifications,
  notificationType: state.snackbar.notificationType,
  timesOut: state.snackbar.timesOut,
});

export default withNamespaces(['common', 'notifications', 'errorCodes'], { wait: false })(
  connect(mapStateToProps)(Snackbar),
);

Snackbar.propTypes = {
  action: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  notifications: PropTypes.instanceOf(List),
  notificationType: PropTypes.string,
  timesOut: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
  action: 'Dismiss',
  notifications: new List(),
  notificationType: NotificationTypes.INFO,
  timesOut: true,
};
