import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import Input from 'components/patterns/Input';

import AddSetButton from '../AddSetButton';
import SetContainer from '../SetContainer';

import style from './timeOfDayForm.scss';

const FIELDS = {
  FROM: 0,
  TO: 1,
};

class TimeOfDayForm extends Component {
  handleRemoveSetClick = (key) => {
    const { fields, notifyError, t } = this.props;
    if (fields.length > 1) {
      fields.removeField(key);
    } else {
      notifyError(t('You cannot delete this since it is the only sub rule available'));
    }
  };

  addNewSet = () => {
    const { fields, ruleType } = this.props;

    return fields.addField(ruleType.get('default-value').toJS()[0]);
  };

  render() {
    const { fields, ruleType, t } = this.props;

    return (
      <div>
        {fields.map((fieldSet, fieldSetIndex) => (
          <SetContainer
            enabled={ruleType.get('is-selected')}
            key={fieldSetIndex}
            onDeleteClick={this.handleRemoveSetClick}
            sequence={fieldSetIndex}
          >
            <div className={style.fieldSet}>
              <div className={style.field}>
                <Input
                  type="text"
                  label={t('From')}
                  theme={style}
                  disabled={!ruleType.get('is-selected')}
                  onChange={(value) => fieldSet[FIELDS.FROM].onChange(value)}
                  value={fieldSet[FIELDS.FROM].value}
                />
              </div>
              <div className={style.field}>
                <Input
                  type="text"
                  label={t('To')}
                  theme={style}
                  disabled={!ruleType.get('is-selected')}
                  onChange={(value) => fieldSet[FIELDS.TO].onChange(value)}
                  value={fieldSet[FIELDS.TO].value}
                />
              </div>
            </div>
          </SetContainer>
        ))}
        <AddSetButton disabled={!ruleType.get('is-selected')} label={t('Add time range')} onClick={this.addNewSet} />
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(TimeOfDayForm);

TimeOfDayForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  notifyError: PropTypes.func,
};

TimeOfDayForm.defaultProps = {
  notifyError: () => {},
};
