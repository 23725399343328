import React from 'react';
import PropTypes from 'prop-types';
import { PublicationPropTypes } from 'store/publications/propTypes';
import TimelineItem from './TimelineItem';
import PublishIcon from './TimelineItem/PublishIcon';
import PublishStatus from './TimelineItem/PublishStatus';
import PublishDetails from './TimelineItem/PublishDetails';

const PublishedTimelineItem = ({ variant, status, publication }) => (
  <TimelineItem
    badge={<PublishIcon variant={variant} />}
    title={<PublishStatus status={status} />}
    details={<PublishDetails publication={publication} />}
  />
);

PublishedTimelineItem.propTypes = {
  variant: PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  status: PropTypes.string.isRequired,
  publication: PublicationPropTypes.isRequired,
};

export default PublishedTimelineItem;
