import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import moment from 'moment';

import Checkbox from 'assets/components/presentational/Checkbox';
import ProgressBar from 'components/patterns/ProgressBar';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import style from './viewPane.scss';

class ViewPane extends Component {
  handleCheckboxChange(permissionId, checked) {
    const { onCheckboxChange } = this.props;

    onCheckboxChange(permissionId, checked);
  }

  renderProgressBar() {
    return (
      <div className={style.progress}>
        <ProgressBar />
      </div>
    );
  }

  renderContent() {
    const { isFetchingUserSettingsCampaign, isUpdatingUserSettingsCampaign } = this.props;

    if (isFetchingUserSettingsCampaign) {
      return this.renderProgressBar();
    }

    return (
      <div className={style.viewPaneContent}>
        {isUpdatingUserSettingsCampaign && this.renderProgressBar()}
        {this.renderPaneHeader()}
        {this.renderPermissions()}
      </div>
    );
  }

  renderPaneHeader() {
    const { t, activeInPane, showDefaultPermissions } = this.props;

    const campaignDates = `${moment(activeInPane['starts-at']).format('DD MMM YYYY')} - ${moment(
      activeInPane['ends-at'],
    ).format('DD MMM YYYY')}`;

    if (activeInPane.get('id') !== 'default') {
      return (
        <div className={style.paneHeading}>
          <Heading size={HeadingSizes.SMALL} tag={HeadingTags.H4}>
            {activeInPane.get('name')}
          </Heading>
          <p className={style.paneSecondaryHeading}>{activeInPane.getIn(['client', 'name'])}</p>
          <p className={style.campaignDates}>{campaignDates}</p>
        </div>
      );
    }

    if (showDefaultPermissions) {
      return (
        <div className={style.paneHeading}>
          <Heading className={style.subHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H4}>
            {t('Default campaign permissions')}
          </Heading>
        </div>
      );
    }

    return null;
  }

  renderPermissions() {
    const { activeInPane, permissions, showDefaultPermissions, t } = this.props;

    if (activeInPane.get('id') !== 'default' || (activeInPane.get('id') && showDefaultPermissions)) {
      return (
        <div className={style.permissions}>{permissions.toJS().map((group) => this.renderPermissonGroup(group))}</div>
      );
    }

    return (
      <div className={style.noPermissions}>{t('Select a campaign from the left navigation to view permissions')}</div>
    );
  }

  renderPermissonGroup(group) {
    return (
      <div key={group.name} className={style.permissionsGroup}>
        <Heading className={style.subHeading} tag={HeadingTags.H5} key={group.id}>
          {group['display-name']}
        </Heading>
        <ul>{group.permissions.map((permission) => this.renderPermission(permission))}</ul>
      </div>
    );
  }

  renderPermission(permission) {
    return (
      <li key={permission.id} className={style.permissionItem}>
        <span className={style.checkboxWrap}>
          <Checkbox
            checked={permission.checked}
            disabled={permission.disabled}
            onChange={(checked) => this.handleCheckboxChange(permission, checked)}
            label={
              <span className={style.copyWrap}>
                <span className={style.primaryText}>{permission['display-name']}</span>
                <span className={style.secondaryText}>{permission.description}</span>
              </span>
            }
          />
        </span>
      </li>
    );
  }

  render() {
    return <div className={style.viewPane}>{this.renderContent()}</div>;
  }
}

export default ViewPane;

ViewPane.propTypes = {
  t: PropTypes.func.isRequired,
  permissions: PropTypes.instanceOf(List).isRequired,
  activeInPane: PropTypes.instanceOf(Map).isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  isFetchingUserSettingsCampaign: PropTypes.bool.isRequired,
  isUpdatingUserSettingsCampaign: PropTypes.bool.isRequired,
  showDefaultPermissions: PropTypes.bool,
};

ViewPane.defaultProps = {
  showDefaultPermissions: false,
};
