import polyfills from 'polyfills'; // eslint-disable-line no-unused-vars

import normalize from 'normalize.css/normalize.css'; // eslint-disable-line no-unused-vars
import materialIcons from 'assets/stylesheets/third-party/material-icons/material-icons.scss'; // eslint-disable-line no-unused-vars

import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import Root from 'Root';
import store from 'store';

const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));
