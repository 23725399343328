import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames/bind';
import { triggerBodyClickToClose } from 'modules/Helpers';
import Row from 'assets/components/presentational/Rows/Row';
import Checkbox from 'assets/components/presentational/Checkbox';
import Dropdown from 'assets/components/presentational/Dropdown';
import { List, Map } from 'immutable';
import Dialog from 'assets/components/presentational/Dialog';
import ProgressBar from 'components/patterns/ProgressBar';

import style from './rowActions.scss';

const cx = classNames.bind(style);

class RowActions extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeAction: new Map(),
      isPrompting: false,
    };
  }

  handleActionChange = (actionValue) => {
    const { actions } = this.props;
    const activeAction = actions.find((action) => action.get('value') === actionValue);
    this.setState({ activeAction, isPrompting: true });
  };

  handleConfirmAction = () => {
    const { onConfirmAction } = this.props;
    const { activeAction } = this.state;
    onConfirmAction(activeAction);
    this.setState({
      isPrompting: false,
      activeAction: activeAction.clear(),
    });
  };

  handleCancelAction = () => {
    const { activeAction } = this.state;
    this.setState({
      isPrompting: false,
      activeAction: activeAction.clear(),
    });
  };

  getAvailableActionsForDropdown = () => {
    const { actions } = this.props;
    const availableActions = actions.toJS();
    return availableActions;
  };

  renderActionDialog = () => {
    const { activeAction } = this.state;
    const { isPrompting } = this.state;

    let dialogActions = [];

    if (activeAction.get('cancelLabel') !== null) {
      const cancelAction = {
        label: activeAction.get('cancelLabel'),
        onClick: () => this.handleCancelAction(),
      };
      dialogActions = dialogActions.concat([cancelAction]);
    }

    if (activeAction.get('confirmLabel') !== null) {
      const confirmAction = {
        label: activeAction.get('confirmLabel'),
        onClick: () => this.handleConfirmAction(),
        disabled: activeAction.get('confirmLabelDisabled'),
      };
      dialogActions = dialogActions.concat([confirmAction]);
    }

    return (
      <Dialog
        active={isPrompting}
        actions={dialogActions}
        className={style.dialog}
        onEscKeyDown={this.handleCancelAction}
        onOverlayClick={this.handleCancelAction}
        title={activeAction.get('label')}
      >
        <span>{activeAction.get('confirmMessage')}</span>
        {this.renderUpdatingProgress()}
        {activeAction.get('dialogContent')}
      </Dialog>
    );
  };

  renderUpdatingProgress = () => {
    const { isUpdating } = this.props;

    if (isUpdating) {
      return (
        <div className={style.updatingProgressContainer}>
          <ProgressBar />
        </div>
      );
    }
  };

  render() {
    const { disableDropdown, t, isAllCheckboxChecked, onCheckboxChange } = this.props;
    const { activeAction } = this.state;

    return (
      <Row className={cx(style.rowActions, { rowActionsActive: !disableDropdown })}>
        <div className={style.dropDownSection}>
          <Dropdown
            allowBlank
            auto={false}
            className={style.dropDown}
            disabled={disableDropdown}
            hint={t('Select an action')}
            onChange={this.handleActionChange}
            onBlur={triggerBodyClickToClose}
            source={this.getAvailableActionsForDropdown()}
            value={activeAction.get('value', '')}
          />
        </div>
        <Checkbox className={style.checkbox} checked={isAllCheckboxChecked} onChange={onCheckboxChange} />
        {this.renderActionDialog()}
      </Row>
    );
  }
}

export default withNamespaces(['common'], { wait: false })(RowActions);

RowActions.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.instanceOf(List),
  isUpdating: PropTypes.bool,
  onConfirmAction: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  disableDropdown: PropTypes.bool,
  isAllCheckboxChecked: PropTypes.bool,
};

RowActions.defaultProps = {
  actions: new List(),
  isUpdating: false,
  onConfirmAction: () => {},
  onCheckboxChange: () => {},
  disableDropdown: false,
  isAllCheckboxChecked: false,
};
