import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map, List } from 'immutable';

import { NotificationTypes } from 'actions/snackbar';
import { CreativeModerationStatuses, redirect } from 'modules/Helpers';
import { userHasCampaignPermission } from 'store/user/helpers';

import ModerationSummary from 'assets/components/presentational/Campaign/ModerationSummary';
import AssetHistoryDrawer from 'assets/components/presentational/AssetHistoryDrawer';
import BaseCreativePreview from './BaseCreativePreview';
import {
  BROADCAST_EVENTS,
  privateCampaignChannel,
  privateUserChannel,
  broadcasting,
} from '../../../../../../modules/Broadcasting/broadcasting';

class CreativePreview extends Component {
  baseUrl = '/campaigns';

  campaignChannel = null;

  componentDidMount() {
    const {
      fetchModerationSummary,
      params: { creativeId, drawer, option },
    } = this.props;

    this.subscribeToBroadcasting();

    if (this.shouldShowModerationSummary({ drawer, option })) {
      fetchModerationSummary(creativeId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchModerationSummary,
      params: { creativeId, drawer, option },
    } = this.props;

    if (!this.shouldShowModerationSummary(prevProps.params) && this.shouldShowModerationSummary({ drawer, option })) {
      fetchModerationSummary(creativeId);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromBroadcasting();
  }

  subscribeToBroadcasting = () => {
    const {
      user,
      params: { campaignId },
    } = this.props;

    this.campaignChannel = broadcasting()
      .setJwt(localStorage.getItem('jwt'))
      .subscribe(privateCampaignChannel(campaignId))
      .bind(BROADCAST_EVENTS.CREATIVE_MODERATION_DECISION_MADE, this.handleNewCreativeDecision)
      .bind(BROADCAST_EVENTS.CREATIVE_MODERATION_CAMPAIGN_INDEXED, this.handleUserOrCampaignIndexed);

    this.userChannel = broadcasting()
      .subscribe(privateUserChannel(user.get('id')))
      .bind(BROADCAST_EVENTS.CREATIVE_MODERATION_USER_INDEXED, this.handleUserOrCampaignIndexed);
  };

  unsubscribeFromBroadcasting = () => {
    if (this.userChannel) {
      this.userChannel.unsubscribe();
    }

    if (!this.campaignChannel) {
      return;
    }

    this.campaignChannel
      .unbind(BROADCAST_EVENTS.CREATIVE_MODERATION_DECISION_MADE)
      .unbind(BROADCAST_EVENTS.CREATIVE_MODERATION_CAMPAIGN_INDEXED);
  };

  handleNewCreativeDecision = (payload) => {
    const moderationStatus = {
      [true]: CreativeModerationStatuses.APPROVED,
      [false]: CreativeModerationStatuses.REJECTED,
      [null]: CreativeModerationStatuses.PENDING,
    }[payload.is_approved];

    const {
      updateCreativeModerationStatus,
      canUserModerate,
      user,
      fetchModerationSummary,
      params: { campaignId, creativeId },
    } = this.props;

    if (payload.creative_id === creativeId) {
      updateCreativeModerationStatus(payload.creative_id, moderationStatus);
      canUserModerate(user.get('id'), creativeId);

      const shouldFetchModerationSummary = userHasCampaignPermission(user, campaignId, 'creative_decisions');
      if (shouldFetchModerationSummary) {
        fetchModerationSummary(creativeId);
      }
    }
  };

  handleUserOrCampaignIndexed = () => {
    const {
      canUserModerate,
      user,
      params: { creativeId },
    } = this.props;
    canUserModerate(user.get('id'), creativeId);
  };

  allowTargeting = () => {
    const {
      user,
      params: { campaignId },
    } = this.props;
    return userHasCampaignPermission(user, campaignId, 'target');
  };

  allowModeration = () => {
    const { creative, userCanModerate } = this.props;
    return creative.get('moderation-status', 0) === CreativeModerationStatuses.PENDING && userCanModerate;
  };

  fetchCreativeForPreview = async () => {
    const {
      clear,
      fetchCreative,
      canUserModerate,
      user,
      params: { campaignId, creativeId },
    } = this.props;

    clear();
    await fetchCreative(campaignId, creativeId, true);
    canUserModerate(user.get('id'), creativeId);
  };

  isFetching = () => {
    const { isInitialFetch, isFetchingCreative } = this.props;

    return isInitialFetch && isFetchingCreative;
  };

  shouldShowModerationSummary = ({ drawer, option }) => {
    return drawer === 'moderation' && option === 'summary';
  };

  handleDrawerClose = () => {
    const {
      params: { campaignId, creativeId },
    } = this.props;
    redirect(`/campaigns/${campaignId}/creative/${creativeId}`);
  };

  handleRecalculateModerationSummaryClick = () => {
    const {
      recalculateModerationSummary,
      notify,
      params: { creativeId },
      t,
    } = this.props;

    recalculateModerationSummary(creativeId)
      .then(() => {
        notify(
          NotificationTypes.SUCCESS,
          t(
            'Your recalculation request has been successful. Check back here in a few moments to see the latest decision.',
          ),
        );
      })
      .catch(() => {
        notify(NotificationTypes.ERROR, t('Your recalculation request was unsuccessful.'));
      });
  };

  handleRefreshModerationSummaryClick = () => {
    const {
      fetchModerationSummary,
      params: { creativeId },
    } = this.props;
    fetchModerationSummary(creativeId);
  };

  shouldShowAssetHistory = () => {
    const {
      params: { drawer },
    } = this.props;

    return drawer === 'assetHistory';
  };

  handleDefaultChange = (checked) => {
    const {
      updateDefaultStatus,
      params: { creativeId },
    } = this.props;
    updateDefaultStatus(creativeId, checked);
  };

  handleRuleSetsChange = (ids) => {
    const {
      syncRelations,
      params: { creativeId },
    } = this.props;
    syncRelations(creativeId, 'rule-sets', ids);
  };

  handleContentTypesChange = (ids) => {
    const {
      params: { creativeId },
      syncRelations,
    } = this.props;
    syncRelations(creativeId, 'content-types', ids);
  };

  handleViewModerationSummaryClick = () => {
    const {
      params: { campaignId, creativeId },
    } = this.props;
    redirect(`/campaigns/${campaignId}/creative/${creativeId}/moderation/summary`);
  };

  handleViewAssetHistoryClick = () => {
    const {
      params: { campaignId, creativeId },
    } = this.props;
    return redirect(`/campaigns/${campaignId}/creative/${creativeId}/assetHistory`);
  };

  handleModerateClick = (status, notes) => {
    const {
      params: { creativeId },
      moderate,
    } = this.props;
    moderate(creativeId, status, notes);
  };

  renderModerationSummaryDrawer = () => {
    const {
      user,
      creative,
      isFetchingModerationSummary,
      isRecalculatingModeration,
      moderationSummary,
      params: { campaignId, drawer, option },
    } = this.props;
    const shouldRenderModerationSummary =
      userHasCampaignPermission(user, campaignId, 'creative_decisions') &&
      this.shouldShowModerationSummary({ drawer, option });

    if (!shouldRenderModerationSummary) {
      return null;
    }

    return (
      <ModerationSummary
        active={this.shouldShowModerationSummary({ drawer, option })}
        creative={creative}
        isFetchingModerationSummary={isFetchingModerationSummary}
        isRecalculatingModeration={isRecalculatingModeration}
        onRecalculateModerationSummaryClick={this.handleRecalculateModerationSummaryClick}
        onRefreshModerationSummaryClick={this.handleRefreshModerationSummaryClick}
        onClose={this.handleDrawerClose}
        moderationSummary={moderationSummary}
      />
    );
  };

  renderAssetHistoryDrawer = () => {
    const { user, creative, isFetchingCreative } = this.props;

    return (
      <AssetHistoryDrawer
        active={this.shouldShowAssetHistory()}
        authenticatedUserId={user.get('id', '')}
        creative={creative}
        isFetching={isFetchingCreative}
        onClose={this.handleDrawerClose}
        title={creative.get('name', 'Creative')}
      />
    );
  };

  render() {
    const {
      campaign,
      creative,
      frameSpecifications,
      htmlPlayClick,
      isCheckingModeration,
      isSavingCreative,
      params,
      setActiveSection,
      showHtmlPlayButton,
    } = this.props;

    return (
      <BaseCreativePreview
        allowModeration={this.allowModeration()}
        allowTargeting={this.allowTargeting()}
        campaign={campaign}
        creative={creative}
        fetchCreativeForPreview={this.fetchCreativeForPreview}
        frameSpecifications={frameSpecifications}
        handleViewAssetHistoryClick={this.handleViewAssetHistoryClick}
        handleDefaultChange={this.handleDefaultChange}
        handleModerateClick={this.handleModerateClick}
        handleViewModerationSummaryClick={this.handleViewModerationSummaryClick}
        handleRuleSetsChange={this.handleRuleSetsChange}
        handleContentTypesChange={this.handleContentTypesChange}
        htmlPlayClick={htmlPlayClick}
        isFetching={this.isFetching()}
        isModerating={isCheckingModeration}
        isSavingCreative={isSavingCreative}
        params={params}
        baseUrl={this.baseUrl}
        renderAssetHistoryDrawer={this.renderAssetHistoryDrawer}
        renderModerationSummaryDrawer={this.renderModerationSummaryDrawer}
        setActiveSection={setActiveSection}
        shouldRenderSettings
        showAssetHistoryLink
        showModerationSummaryLink
        showHtmlPlayButton={showHtmlPlayButton}
        syncRelations={this.syncRelations}
      />
    );
  }
}

export default withNamespaces(['common'])(CreativePreview);

CreativePreview.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  canUserModerate: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  fetchCreative: PropTypes.func.isRequired,
  fetchModerationSummary: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  htmlPlayClick: PropTypes.func.isRequired,
  isCheckingModeration: PropTypes.bool.isRequired,
  isFetchingCreative: PropTypes.bool.isRequired,
  isFetchingModerationSummary: PropTypes.bool.isRequired,
  isInitialFetch: PropTypes.bool.isRequired,
  isRecalculatingModeration: PropTypes.bool.isRequired,
  isSavingCreative: PropTypes.bool.isRequired,
  moderate: PropTypes.func.isRequired,
  moderationSummary: PropTypes.instanceOf(List).isRequired,
  notify: PropTypes.func.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    creativeId: PropTypes.string.isRequired,
    drawer: PropTypes.string,
  }).isRequired,
  recalculateModerationSummary: PropTypes.func.isRequired,
  updateCreativeModerationStatus: PropTypes.func.isRequired,
  updateDefaultStatus: PropTypes.func.isRequired,
  userCanModerate: PropTypes.bool.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  showHtmlPlayButton: PropTypes.bool.isRequired,
  syncRelations: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
};
