import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import ROUTES from 'modules/Constants/routes';

import Logo from 'assets/components/presentational/Logo';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Link from 'assets/components/presentational/Link';

import style from './notFound.scss';

const NotFound = ({ t }) => (
  <div className={style.component}>
    <Logo />

    <div className={style.prompt}>
      <div className={style.inner}>
        <Heading className={style.heading} size={HeadingSizes.LARGEST} tag={HeadingTags.H1}>
          {t('Page not found')}
        </Heading>

        <em>{t('The page you are looking for has moved or no longer exists')}</em>

        <Link className={style.indexLink} to={ROUTES.DASHBOARD}>
          Head to the homepage
        </Link>
      </div>
    </div>
  </div>
);

export default withNamespaces(['common'], { wait: false })(NotFound);

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};
