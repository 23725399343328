import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import BaseCreative from 'assets/components/containers/Campaign/Creative/BaseCreative';

class CreativeLiteModeration extends Component {
  state = {
    isFetching: true,
  };

  renderHeading = () => {
    const { t } = this.props;
    return <HeadingGroup title={t('Moderate Creative')} intro={t('The following creatives require your approval.')} />;
  };

  fetchAdditionalCreativeData = (creativeId) => {
    const { fetchCreative } = this.props;

    const extraOptions = {
      includes: ['asset', 'user', 'prepared-asset', 'rule-sets', 'thumbnail', 'warnings', 'content-types'],
    };
    fetchCreative(creativeId, extraOptions).catch(() => Promise.resolve());
  };

  fetchFrameSpecifications = (campaignId) => {
    const { fetchFrameSpecifications } = this.props;
    fetchFrameSpecifications(campaignId).then(() => {
      this.setState({
        isFetching: false,
      });
    });
  };

  render() {
    const {
      activeCampaignId,
      activeFrameSpecId,
      areCreativesUpdating,
      campaign,
      fetchCampaign,
      frameSpecifications,
      params: { campaignId },
      setActiveFrameSpec,
      setActiveSection,
      user,
      setActiveCampaignId,
    } = this.props;
    const { isFetching } = this.state;

    const previewUrl = `lite/moderation/view/${campaignId}/creative/`;
    return (
      <BaseCreative
        activeCampaignId={activeCampaignId}
        activeFrameSpecId={activeFrameSpecId}
        areCreativesUpdating={areCreativesUpdating}
        campaign={campaign}
        campaignId={campaignId}
        emptyFrameSpecsMessage="You have no creatives that need moderating"
        fetchCampaign={fetchCampaign}
        fetchFrameSpecifications={this.fetchFrameSpecifications}
        frameSpecifications={frameSpecifications}
        isFetching={isFetching}
        previewUrl={previewUrl}
        renderHeading={this.renderHeading}
        setActiveFrameSpec={setActiveFrameSpec}
        setActiveSection={setActiveSection}
        setActiveCampaignId={setActiveCampaignId}
        user={user}
        fetchAdditionalCreativeData={this.fetchAdditionalCreativeData}
      />
    );
  }
}

CreativeLiteModeration.propTypes = {
  activeCampaignId: PropTypes.string.isRequired,
  activeFrameSpecId: PropTypes.string.isRequired,
  areCreativesUpdating: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  fetchCampaign: PropTypes.func.isRequired,
  fetchFrameSpecifications: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  setActiveFrameSpec: PropTypes.func.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  setActiveCampaignId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  fetchCreative: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'liteModeration'], { wait: false })(CreativeLiteModeration);
