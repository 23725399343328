import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import Pagination from 'assets/components/presentational/Pagination';
import Input from 'components/patterns/Input';
import Dropdown from 'assets/components/presentational/Dropdown';
import LargeEditDialog from 'assets/components/presentational/LargeEditDialog';

import styles from './styles.scss';

const initialState = {
  editing: null,
  value: null,
};

class DataTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  render() {
    const { className } = this.props;
    return (
      <div className={`${styles.component} ${className}`}>
        {this.renderItems()}
        {this.renderPagination()}
      </div>
    );
  }

  renderItems() {
    const { model, items, isFetching } = this.props;

    return (
      <div className={styles.component}>
        <table className={`${styles.table} ${isFetching ? styles.faded : ''}`}>
          <thead>
            <tr className={styles.row}>{Object.keys(model).map((key) => this.renderHeaderCell(model, key))}</tr>
            <tr className={styles.row}>{Object.keys(model).map((key) => this.renderFilterFormWidget(key))}</tr>
          </thead>
          <tbody>
            {items.toJS().map((item) => (
              <tr key={item.id} className={styles.row}>
                {Object.keys(model).map((key) => this.renderCell(item, model, key))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderPagination() {
    const { pagination, items } = this.props;

    const pageNumber = pagination.get('current_page');
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    if (!pagination || pagination.get('total_pages') === 1 || items.isEmpty()) {
      return null;
    }

    return (
      <Pagination
        forceSelected={pageNumber - 1}
        initialSelected={initialSelected}
        onClick={this.handlePaginationClick}
        pageNum={pagination.get('total_pages')}
      />
    );
  }

  isItemSoftDeleted = (item) => {
    const { rowsDeleteStatus } = this.props;
    if (!rowsDeleteStatus) {
      return false;
    }

    const rowDeleteStatus = rowsDeleteStatus.find((row) => row.id === item.id) || { deleted: false };

    return rowDeleteStatus.deleted;
  };

  renderCell(item, model, key) {
    const { editing, value } = this.state;

    if (editing === item.id + key) {
      return (
        <td key={item.id + key}>
          {model[key].renderItem ? model[key].renderItem(item[key]) : item[key]}
          <LargeEditDialog
            value={value}
            onChange={(v) => this.handleCellChange(item.id, key, v)}
            onCancel={() => this.clear()}
          />
        </td>
      );
    }

    const itemIsSoftDeleted = this.isItemSoftDeleted(item);
    const deletedClass = itemIsSoftDeleted ? styles.deleted : null;

    if (model[key].editable && !itemIsSoftDeleted) {
      return (
        <td key={item.id + key} className={deletedClass} onClick={() => this.handleCellClick(item.id + key, item[key])}>
          {model[key].renderItem ? model[key].renderItem(item[key]) : item[key]}
          <span className={styles.cellIcon}>
            <Icon iconType={IconTypes.MODE_EDIT} />
          </span>
        </td>
      );
    }

    return (
      <td key={item.id + key} className={deletedClass}>
        {model[key].renderItem ? model[key].renderItem(item[key]) : item[key]}
      </td>
    );
  }

  renderFilterFormWidget(key) {
    const { model, filters } = this.props;

    if (!model[key].filterable) {
      return <td key={key} />;
    }

    if (model[key].filterOptions) {
      const { filterOptions } = model[key];

      if (filters[key]) {
        const blankIndex = model[key].filterOptions.findIndex((filterItem) =>
          ['', null, undefined].includes(filterItem.value),
        );

        if (blankIndex === -1) {
          filterOptions.unshift({ '': null });
        }
      }

      return (
        <td key={key}>
          <Dropdown
            className={styles.inlineDropdown}
            label={model[key].filterLabel || model[key].title}
            value={filters[key]}
            source={model[key].filterOptions}
            onChange={(value) => this.handleFilterChange(key, value)}
          />
        </td>
      );
    }

    return (
      <td key={key}>
        <Input
          className={styles.inlineInput}
          key={key}
          label={model[key].title}
          value={filters[key] || ''}
          onChange={(value) => this.handleFilterChange(key, value)}
        />
      </td>
    );
  }

  renderHeaderCell(model, key) {
    const { sorts } = this.props;
    if (model[key].sortable) {
      const sortedActiveClass = sorts[key] ? ` ${styles['orderIcon--active']}` : '';
      return (
        <th
          key={key}
          className={styles.sortable}
          style={{ width: model[key].columnWidth }}
          onClick={() => this.handleSortChange(key)}
        >
          <span className={styles.orderIcon + sortedActiveClass}>
            <Icon
              iconType={sorts[key] === 'DESC' ? IconTypes.ARROW_DOWNWARD : IconTypes.ARROW_UPWARD}
              color={IconColors.WHITE}
            />
          </span>
          {model[key].title}
        </th>
      );
    }
    return (
      <th key={key} style={{ width: model[key].columnWidth }}>
        {model[key].title}
      </th>
    );
  }

  handlePaginationClick = ({ selected }) => {
    const { onPaginationChange } = this.props;
    this.clear();
    onPaginationChange(selected);
  };

  handleCellClick = (editing, value) => {
    this.setState({
      editing,
      value,
    });
  };

  handleCellChange = (frameId, key, v) => {
    const { onCellChange } = this.props;

    return onCellChange(frameId, key, v)
      .then(() => {
        this.clear();
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  handleSortChange = (key) => {
    const { sorts, onSortChange } = this.props;
    let direction;
    if (sorts[key] === 'ASC') {
      direction = 'DESC';
    }
    if (!sorts[key]) {
      direction = 'ASC';
    }
    onSortChange(key, direction);
  };

  handleFilterChange = (key, value) => {
    const { onFilterChange } = this.props;
    onFilterChange(key, value);
  };

  clear = () => {
    this.setState(initialState);
  };
}

DataTable.defaultProps = {
  items: List(),
  model: {},
  sorts: {},
  filters: {},
  pagination: Map(),
  isFetching: false,
  onSortChange: () => {},
  onFilterChange: () => {},
  onCellChange: () => {},
  onPaginationChange: () => {},
};

DataTable.propTypes = {
  items: PropTypes.instanceOf(List).isRequired,
  rowsDeleteStatus: PropTypes.array,
  model: PropTypes.object.isRequired,
  sorts: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCellChange: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default DataTable;
