import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'react-toolbox/lib/button';
import Tooltip from 'components/patterns/Tooltip';
import Link from 'assets/components/presentational/Link';

import style from './action.scss';

const TooltipIconButton = Tooltip(IconButton);

const Action = (props) => {
  const { className, disabled, icon, onClick, to, tooltip } = props;

  const linkAction = (wrapped) => <Link to={to}>{wrapped}</Link>;

  if (tooltip) {
    const wrapped = (
      <TooltipIconButton
        className={`${className} ${style.component}`}
        disabled={disabled}
        icon={icon}
        onClick={onClick}
        tooltip={tooltip}
      />
    );

    if (to && !disabled) {
      return linkAction(wrapped);
    }

    return wrapped;
  }

  const wrapped = (
    <IconButton className={`${className} ${style.component}`} disabled={disabled} icon={icon} onClick={onClick} />
  );

  if (to && !disabled) {
    return linkAction(wrapped);
  }

  return wrapped;
};

export default Action;

Action.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

Action.defaultProps = {
  className: '',
  disabled: false,
  tooltip: '',
  to: '',
};
