import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { redirect, getURLQueryString } from 'modules/Helpers';
import ROUTES from 'modules/Constants/routes';
import { useDebounce } from 'modules/CustomHooks';

import Input from 'components/patterns/Input';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './RefineFilter.scss';

const RefineFilter = ({ value, t }) => {
  const [query, setQuery] = useState(value);

  useDebounce(
    useCallback(() => {
      const queryString = getURLQueryString({ refineSearch: query });
      redirect(`${ROUTES.DASHBOARD}1${queryString}`, true);
    }, [query]),
    500,
  );

  return (
    <Input
      theme={style}
      hint={t('Filter by campaign, client name, or campaign manager email address')}
      type="text"
      value={query}
      onChange={setQuery}
      icon={<Icon iconType={IconTypes.SEARCH} />}
      name="dashboardRefineFilter"
    />
  );
};

RefineFilter.propTypes = {
  value: PropTypes.string,
  t: PropTypes.func.isRequired,
};

RefineFilter.defaultProps = {
  value: '',
};

export default withNamespaces(['dashboardNew', 'dashboard'], { wait: false })(RefineFilter);
