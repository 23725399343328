import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';

import { setMembers, mergeMember, removeMember, clearMembers } from 'actions/campaign/presenceIndicator';

import Tooltip from 'components/patterns/Tooltip';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import Avatar from '../../../presentational/Avatar';

import style from './presenceIndicator.scss';
import { presenceCampaignRequestChannel, broadcasting } from '../../../../../modules/Broadcasting/broadcasting';

const TooltipAvatar = Tooltip(Avatar);

class PresenceIndicator extends Component {
  broadcastingChannel = null;

  componentDidMount = () => {
    const { campaignId } = this.props;
    this.subscribeToBroadcastingChannel(campaignId);
  };

  componentDidUpdate(prevProps) {
    const { campaignId: prevCampaignId } = prevProps;
    const { dispatch, campaignId } = this.props;

    if (prevCampaignId !== campaignId) {
      dispatch(clearMembers());
      this.unsubscribeFromBroadcastingChannel();
      this.subscribeToBroadcastingChannel(campaignId);
    }
  }

  componentWillUnmount = () => {
    const { dispatch, campaignId } = this.props;
    this.unsubscribeFromBroadcastingChannel(campaignId);
    dispatch(clearMembers());
  };

  subscribeToBroadcastingChannel = (campaignId) => {
    const { dispatch } = this.props;

    this.broadcastingChannel = broadcasting()
      .setJwt(localStorage.getItem('jwt'))
      .join(presenceCampaignRequestChannel(campaignId))
      .here((members) => {
        dispatch(setMembers(fromJS(members)));
      })
      .joining((member) => dispatch(mergeMember(fromJS(member))))
      .leaving((member) => dispatch(removeMember(fromJS(member))));
  };

  unsubscribeFromBroadcastingChannel = () => {
    if (!this.broadcastingChannel) {
      return;
    }

    this.broadcastingChannel.leave();
  };

  getOtherMembers = () => {
    const { members, myID } = this.props;
    return members.delete(myID);
  };

  renderMembers = () =>
    this.getOtherMembers()
      .valueSeq()
      .map((member, id) => {
        const name = member.get('name', '');
        return this.renderMember(id, name);
      });

  renderMember = (id, name) => {
    const tooltip = (
      <span className={style.tooltipIcon}>
        <Icon iconType={IconTypes.VISIBILITY} />
        {name}
      </span>
    );

    return <TooltipAvatar key={id} className={style.member} title={name} tooltip={tooltip} />;
  };

  render = () =>
    this.getOtherMembers().size < 1 ? null : <div className={style.component}>{this.renderMembers()}</div>;
}

const mapStateToProps = (state) => ({
  members: state.campaignPresenceIndicator.members,
});

export default connect(mapStateToProps)(PresenceIndicator);

PresenceIndicator.propTypes = {
  members: PropTypes.instanceOf(Map).isRequired,
  myID: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

PresenceIndicator.defaultProps = {};
