import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './columnContainer.scss';

class ColumnContainer extends Component {
  render() {
    const { children } = this.props;

    return <div className={style.pageContainer}>{children}</div>;
  }
}

export default ColumnContainer;

ColumnContainer.propTypes = {
  children: PropTypes.node,
};
