import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import cx from 'classnames';
import { convertDateFormat } from 'modules/Helpers/dates';

import Slot from 'assets/components/presentational/Campaign/ContentSchedule/Slot';

import style from './day.scss';

class ScheduleDay extends Component {
  static getLastIndexOfRuleSet(slots) {
    const lastIndexOfEvent = slots.map((slot, index) => (slot !== false ? index : false)).filter((item) => item);

    return lastIndexOfEvent.last() || 0;
  }

  static isCurrentDateWithinRuleDates(date, ruleDateRanges) {
    const currentDate = convertDateFormat(date, 'DD MMM YYYY', 'YYYY-MM-DD');
    return ruleDateRanges.some((ruleDateRange) => currentDate >= ruleDateRange.from && currentDate <= ruleDateRange.to);
  }

  static isCurrentDayInDayOfWeekRule(currentDay, rules) {
    const dayOfWeekRule = rules.find((rule) => {
      return rule.get('type').includes('DayOfWeek');
    });

    return dayOfWeekRule.get('description').includes(currentDay);
  }

  renderSlots() {
    const { campaignId, day, activePopup, handleSlotClick, handleClosePopup } = this.props;

    if (day.get('slots').size < 1) {
      return null;
    }

    const slots = day.get('slots').slice(0, ScheduleDay.getLastIndexOfRuleSet(day.get('slots')) + 1);

    return (
      <div className={style.slots}>
        {slots.map((slot, key) => {
          if (!slot) {
            return <div key={key} className={style.spacer} />;
          }

          // if the rule is out of campaign date range then put a spacer.
          const hasDateRange = slot.has('dateRange') && slot.get('dateRange');
          if (
            hasDateRange &&
            !ScheduleDay.isCurrentDateWithinRuleDates(day.get('date'), slot.get('dateRange').toJS())
          ) {
            return <div key={key} className={style.spacer} />;
          }

          // if the current day is not one of the day of the week rule put a spacer.
          if (
            slot.get('position') === 'dayofweek' &&
            !ScheduleDay.isCurrentDayInDayOfWeekRule(day.get('dow'), slot.get('rules'))
          ) {
            return <div key={key} className={style.spacer} />;
          }

          return (
            <Slot
              key={key}
              day={day}
              slot={slot}
              slotNumber={key}
              campaignId={campaignId}
              activePopup={activePopup}
              campaignPosition={day.get('position')}
              handleSlotClick={handleSlotClick}
              handleClosePopup={handleClosePopup}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { day } = this.props;

    if (!day) {
      return <div className={style.day} />;
    }

    const position = day.get('position');

    const dayClasses = cx(style.day, {
      [style.campaign]: !!position,
      [style.campaignStart]: position === 'start' || position === 'single',
      [style.campaignMid]: position === 'middle',
      [style.campaignEnd]: position === 'end' || position === 'single',
    });

    return (
      <div className={dayClasses}>
        <div className={style.flag}>
          <span className={style.inner} />
          <p className={style.dayNum}>{day.get('dayNum') && day.get('dayNum')}</p>
          {day.get('date') && day.get('position') && this.renderSlots()}
        </div>
      </div>
    );
  }
}

export default ScheduleDay;

ScheduleDay.propTypes = {
  campaignId: PropTypes.string.isRequired,
  day: PropTypes.instanceOf(Map).isRequired,
  activePopup: PropTypes.instanceOf(Map),
  handleSlotClick: PropTypes.func.isRequired,
  handleClosePopup: PropTypes.func.isRequired,
};
