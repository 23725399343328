import React from 'react';
import PropTypes from 'prop-types';

import { ICON_COLORS } from '../helpers';

const TV = ({ fill, preserveAspectRatio, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill={fill}
    preserveAspectRatio={preserveAspectRatio}
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z" />
  </svg>
);

TV.propTypes = {
  fill: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

TV.defaultProps = {
  fill: ICON_COLORS.BLACK,
  preserveAspectRatio: 'xMidYMid meet',
  height: 24,
  width: 24,
};

export default TV;
