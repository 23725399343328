import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { redirect } from 'modules/Helpers';
import { assetDownloadUrl } from 'modules/Helpers/creative';
import { setActiveSection } from 'actions/campaign/navigation';
import { fetchModerationHistory } from 'actions/liteModeration';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import ProgressBar from 'components/patterns/ProgressBar';
import Tooltip from 'components/patterns/Tooltip';
import Table from 'assets/components/presentational/Table';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import Pagination from 'assets/components/presentational/Pagination';

import style from './history.scss';

const TooltipIcon = Tooltip(Icon);

class ModerationHistory extends Component {
  componentDidMount() {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(setActiveSection('history'));
    dispatch(fetchModerationHistory(campaignId));
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      params: { campaignId, pageNumber },
    } = this.props;

    if (this.pageNumberHasChanged(prevProps)) {
      dispatch(fetchModerationHistory(campaignId, pageNumber));
    }
  }

  pageNumberHasChanged = (prevProps) => {
    const {
      params: { pageNumber: prevPageNumber },
    } = prevProps;
    const {
      params: { pageNumber },
    } = this.props;

    const pageNumberHasChanged = prevPageNumber !== pageNumber;
    const pageNumberIsNumber = !isNaN(parseInt(prevPageNumber));

    return pageNumberHasChanged && prevPageNumber && pageNumber && pageNumberIsNumber;
  };

  renderHeading() {
    const { t } = this.props;

    return (
      <HeadingGroup
        title={t('Moderation History')}
        intro={t('View historic moderation decisions and download creatives.')}
      />
    );
  }

  historyTableModel = () => ({
    status: { type: String, title: 'Status' },
    date: { type: Date, title: 'Date' },
    name: { type: String, title: 'Creative Name' },
    frame: { type: String, title: 'Frame Specification' },
    notes: { type: String, title: 'Rejection Notes' },
  });

  historyTableSource = () => {
    const { history, t } = this.props;

    if (!history) return [];

    return history
      .map((item) => {
        const row = {};
        row.status = item.get('is-approved') ? (
          <TooltipIcon iconType={IconTypes.DONE} color={IconColors.GREEN} tooltip={t('Accepted')} />
        ) : (
          <TooltipIcon iconType={IconTypes.ERROR_OUTLINE} color={IconColors.RED} tooltip={t('Rejected')} />
        );
        row.date = moment(item.get('updated-at')).format('DD MMM YYYY h:mm:ss a');
        row.name = (
          <a target="_blank" rel="noopener noreferrer" href={assetDownloadUrl(item.get('creative'))}>
            {item.getIn(['creative', 'name'])}
          </a>
        );
        row.frame = item.getIn(['creative', 'frame-specification', 'name']);
        row.notes = item.get('notes');
        return row;
      })
      .toJS();
  };

  handlePaginationClick = (event) => {
    const {
      params: { campaignId },
    } = this.props;
    const pageNumber = parseInt(event.selected) + 1;

    redirect(`/lite/moderation/view/${campaignId}/history/${pageNumber}`);
  };

  renderPagination = () => {
    const {
      isFetchingHistory,
      pagination,
      params: { pageNumber },
    } = this.props;
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <div>
        {pagination.get('total_pages') > 1 ? (
          <Pagination
            forceSelected={pageNumber - 1}
            initialSelected={initialSelected}
            hidden={isFetchingHistory}
            onClick={this.handlePaginationClick}
            pageNum={pagination.get('total_pages')}
          />
        ) : null}
      </div>
    );
  };

  renderHistory = () => {
    const { isFetchingHistory } = this.props;

    if (isFetchingHistory) {
      return <ProgressBar />;
    }

    return (
      <div className={style.tableContainer}>
        <Table model={this.historyTableModel()} source={this.historyTableSource()} />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderHeading()}
        {this.renderHistory()}
        {this.renderPagination()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.liteModeration.history,
  pagination: state.liteModeration.paginationHistory,
  isFetchingHistory: state.liteModeration.isFetchingHistory,
});

export default withNamespaces(['common', 'liteModeration'], { wait: false })(
  connect(mapStateToProps)(ModerationHistory),
);

ModerationHistory.propTypes = {
  history: PropTypes.instanceOf(List).isRequired,
  isFetchingHistory: PropTypes.bool.isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    pageNumber: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
