import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
import * as at from '../../actions/campaign/presenceIndicator';

const getInitialState = () => ({
  members: new Map(),
});

export default handleActions(
  {
    [at.SET_MEMBERS]: (state, action) => ({
      ...state,
      members: Map(action.members.map((member) => [member.get('id'), member])),
    }),
    [at.MERGE_MEMBER]: (state, action) => ({
      ...state,
      members: state.members.set(action.member.get('id'), action.member),
    }),
    [at.REMOVE_MEMBER]: (state, action) => ({
      ...state,
      members: state.members.delete(action.member.get('id')),
    }),
    [at.CLEAR_MEMBERS]: (state) => ({
      ...state,
      members: new Map({}),
    }),
  },
  getInitialState(),
);
