import Request from 'modules/API/request';
import { pick } from 'lodash';
import { fromJS, List, Map } from 'immutable';
import { HttpMethods } from 'modules/Helpers/http';
import queryString from 'query-string';
import { notifyApiError } from 'actions/notify';

import {
  clearCampaignRelation,
  fetchCampaignRelation,
  fetchCampaignRelations,
  mergeCampaignRelation,
  replaceCampaignRelation,
  syncCampaignRelation,
} from 'actions/campaign';

export const SET_ACTIVE_CREATIVE_ID = 'campaign/creative/SET_ACTIVE_CREATIVE_ID';

export const SAVE_REQUEST = 'campaign/creative/SAVE_REQUEST';
export const SAVE_SUCCESS = 'campaign/creative/SAVE_SUCCESS';
export const SAVE_COMPLETE = 'campaign/creative/SAVE_COMPLETE';

export const PATCH_CREATIVES_REQUEST = 'campaign/creative/PATCH_CREATIVES_REQUEST';
export const PATCH_CREATIVES_COMPLETE = 'campaign/creative/PATCH_CREATIVES_COMPLETE';

export const TOGGLE_SHOW_SETTINGS_FORM = 'campaign/creative/TOGGLE_SHOW_SETTINGS_FORM';

export const MERGE_PREVIEW = 'campaign/creative/MERGE_PREVIEW';

export const RESET_STATE = 'campaign/creative/RESET_STATE';

export const HTML_PLAY_CLICK = 'campaign/creative/HTML_PLAY_CLICK';

export const FETCH_MODERATION_SUMMARY_REQUEST = 'campaign/creative/FETCH_MODERATION_SUMMARY_REQUEST';
export const FETCH_MODERATION_SUMMARY_SUCCESS = 'campaign/creative/FETCH_MODERATION_SUMMARY_SUCCESS';
export const FETCH_MODERATION_SUMMARY_COMPLETE = 'campaign/creative/FETCH_MODERATION_SUMMARY_COMPLETE';
export const MODERATION_SUMMARY_PAGINATION_MERGE = 'campaign/creative/MODERATION_SUMMARY_PAGINATION_MERGE';

export const SET_ACTIVE_FRAME_SPEC = 'campaign/creative/SET_ACTIVE_FRAME_SPEC';

export const REPROCESS_CREATIVE = 'campaign/creative/REPROCESS_CREATIVE';

export const FETCHING_CREATIVES_BY_FRAME_SPEC_START = 'FETCHING_CREATIVES_BY_FRAME_SPEC_START';
export const FETCHING_CREATIVES_BY_FRAME_SPEC_COMPLETE = 'FETCHING_CREATIVES_BY_FRAME_SPEC_COMPLETE';

export const mergeCreativeIntoFrameSpecs = (campaignId, frameSpecificationKey, newCreative, oldCreative = null) => {
  if (Map.isMap(oldCreative)) {
    return (dispatch) => {
      dispatch(
        replaceCampaignRelation(
          campaignId,
          ['frame-specifications', frameSpecificationKey, 'creatives'],
          newCreative,
          oldCreative,
        ),
      );
    };
  }

  return (dispatch) => {
    dispatch(
      mergeCampaignRelation(campaignId, ['frame-specifications', frameSpecificationKey, 'creatives'], newCreative),
    );
  };
};

export const fetchAdditionalCreativeData = (creativeId, extraOptions = {}) => async (dispatch) => {
  try {
    const response = await Request.send({
      endpoint: `creatives/${creativeId}`,
      ...extraOptions,
    });

    return response.data.body.parsed;
  } catch (error) {
    dispatch(notifyApiError(error));
  }

  return {};
};

export const fetchCreative = (campaignId, frameSpecificationKey, creativeId, extraOptions = {}, oldCreative = {}) => (
  dispatch,
) => {
  Request.send({
    endpoint: `creatives/${creativeId}`,
    ...extraOptions,
  })
    .then((response) => {
      const creative = fromJS(response.data.body.parsed);
      dispatch(mergeCreativeIntoFrameSpecs(campaignId, frameSpecificationKey, creative, oldCreative));
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
    });
};

export const addCreative = (creativeId, campaignId, frameSpecificationKey, extraOptions) => (dispatch) => {
  dispatch(fetchCreative(campaignId, frameSpecificationKey, creativeId, extraOptions));
};

export const replaceCreative = (frameSpecificationKey, creativeId, oldCreative, campaignId, extraOptions) => (
  dispatch,
) => {
  dispatch(fetchCreative(campaignId, frameSpecificationKey, creativeId, extraOptions, oldCreative));
};

export const fetchCreativesModerationSummary = (campaignId, pageNumber, numberOfDecisions = 5) => (dispatch) => {
  dispatch({ type: FETCH_MODERATION_SUMMARY_REQUEST }, campaignId);

  // figure out how to send a query-string of moderation_decision_count = 5 without hardcoding it.

  const options = {
    endpoint: `campaigns/${campaignId}/moderation-decisions?moderation_decision_count=${numberOfDecisions}`,
    includes: ['frame-specification'],
    pagination: {
      pageNumber,
      pageSize: 15,
    },
  };

  return Request.send(options)
    .then((response) => {
      const creativesModerationSummary = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_MODERATION_SUMMARY_SUCCESS, creativesModerationSummary });
      dispatch({ type: FETCH_MODERATION_SUMMARY_COMPLETE });
      dispatch(mergeCreativesModerationPagination(response.data.body.meta.pagination));

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_MODERATION_SUMMARY_COMPLETE });
      return Promise.resolve(error);
    });
};

export const mergeCreativesModerationPagination = (pagination) => ({
  type: MODERATION_SUMMARY_PAGINATION_MERGE,
  pagination,
});

export const fetchCreativesForSingleFrameSpecification = (
  campaignId,
  frameSpec,
  frameSpecIndex,
  options = {},
) => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_CREATIVES_BY_FRAME_SPEC_START, frameSpecId: frameSpec.id });
    const { includes = [], filters = [], sorts = [] } = options;
    await dispatch(
      fetchCampaignRelations(campaignId, 'creatives', { includes, filters, sorts }, [
        'frame-specifications',
        frameSpecIndex,
        'creatives',
      ]),
    );
  } finally {
    dispatch({ type: FETCHING_CREATIVES_BY_FRAME_SPEC_COMPLETE, frameSpecId: frameSpec.id });
  }
};

export const fetchCreativesByFrameSpecifications = (campaignId, frameSpecs, options = {}) => async (dispatch) => {
  frameSpecs.forEach(async (frameSpec, frameSpecIndex) => {
    let { filters = [] } = options;
    const { includes = [], sorts = [] } = options;
    filters = [...filters, { frame_specification_id: frameSpec.id }];
    dispatch(
      fetchCreativesForSingleFrameSpecification(campaignId, frameSpec, frameSpecIndex, { includes, filters, sorts }),
    );
  });
};

export const fetchFrameSpecifications = (campaignId, options = {}) => async (dispatch) => {
  const responseOfFrameSpecs = await dispatch(fetchCampaignRelations(campaignId, 'frame-specifications'));
  const frameSpecs = responseOfFrameSpecs.data.body.parsed;
  const { includes = ['rule-sets', 'content-types'], filters = [] } = options;
  dispatch(fetchCreativesByFrameSpecifications(campaignId, frameSpecs, { includes, filters }));
};

/*
 * Returns fetched creative for preview
 *
 * The preview data keys need to remain exactly as they were returned from the API in order to send them
 * to the HTML package correctly. To achieve this, we send a separate request with extra deserialization config.
 *
 * We then merge both creative data-sets together.
 *
 * @param string creativeId
 * @param coreIncludes array The "core" data to be included with the creative, e.g. rule-sets
 * @param previewIncludes array The preview data to be included with the creative e.g. preview-frame
 * @param options object Additional request options
 *
 * @return Promise
 */
export const fetchCreativeForPreview = (creativeId, coreIncludes = [], previewIncludes = [], options = {}) => (
  dispatch,
) => {
  const endpoint = `creatives/${creativeId}`;
  const extraDeserializerConfiguration = {
    keyForAttribute: (key) => key,
  };

  return Promise.all([
    Request.send(
      Object.assign(
        {
          endpoint,
          includes: coreIncludes,
        },
        options,
      ),
    ),

    Request.send(
      Object.assign(
        {
          endpoint,
          includes: previewIncludes,
          extraDeserializerConfiguration,
        },
        options,
      ),
    ),
  ])
    .then((responses) =>
      // When the responses have resolved, merge the creative data from both responses together
      Object.assign(responses[0].data.body.parsed, pick(responses[1].data.body.parsed, previewIncludes)),
    )
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

/*
 * Returns fetched creatives for preview
 *
 * The preview data keys need to remain exactly as they were returned from the API in order to send them
 * to the HTML package correctly. To achieve this, we send a separate request with extra deserialization config.
 *
 * We then merge both creative data-sets together.
 *
 * @param string campaignId
 * @param coreIncludes array The "core" data to be included with the creatives, e.g. rule-sets
 * @param previewIncludes array The preview data to be included with the creatives e.g. preview-frame
 * @param ruleSetId string Preview against a given rule-set
 * @param frameSpecificationId string Preview against a given frame specification
 * @param contentItemId string Preview against a given content item
 *
 * @return Promise
 */
export const fetchCreativesForPreview = (
  campaignId,
  coreIncludes = [],
  previewIncludes = [],
  ruleSetIds = [],
  frameSpecificationId = '',
  contentItemIds = [],
) => (dispatch) => {
  ruleSetIds =
    ruleSetIds.length > 0
      ? '&'.concat(queryString.stringify({ preview_ruleset_ids: ruleSetIds }, { arrayFormat: 'bracket' })) // eslint-disable-line camelcase
      : '';
  contentItemIds =
    contentItemIds.length > 0
      ? '&'.concat(queryString.stringify({ preview_content_item_ids: contentItemIds }, { arrayFormat: 'bracket' })) // eslint-disable-line camelcase
      : '';

  const endpoint = 'campaigns/'.concat(
    campaignId,
    '/creatives/preview?',
    'preview_frame_specification_id=',
    frameSpecificationId,
    ruleSetIds,
    contentItemIds,
  );

  const extraDeserializerConfiguration = {
    keyForAttribute: (key) => key,
  };

  return Promise.all([
    Request.send({
      endpoint,
      includes: coreIncludes,
      pagination: Request.ALL_PAGES,
    }),

    Request.send({
      endpoint,
      includes: previewIncludes,
      extraDeserializerConfiguration,
      pagination: Request.ALL_PAGES,
    }),
  ])
    .then((responses) => {
      const creatives = fromJS(responses[0].data.body.parsed);
      const creativesWithPreviewData = fromJS(responses[1].data.body.parsed);

      return creatives.map((c) => {
        const p = creativesWithPreviewData.find((p) => p.get('id') === c.get('id'), null, new Map());

        return c.merge(pick(p.toJS(), previewIncludes));
      });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const fetchCreativeForSettings = (campaignId, creativeId, frameSpecificationKey) => (dispatch) => {
  dispatch(toggleShowSettingsForm());
  dispatch(clearCampaignRelation(campaignId, ['rule-sets']));
  dispatch(clearCampaignRelation(campaignId, ['content-types']));

  return Promise.all([
    dispatch(
      fetchCampaignRelation(
        campaignId,
        'creatives',
        creativeId,
        ['rule-sets', 'content-types'],
        ['frame-specifications', frameSpecificationKey, 'creatives'],
      ),
    ),
    dispatch(fetchCampaignRelations(campaignId, 'rule-sets')),
    dispatch(fetchCampaignRelations(campaignId, 'content-types')),
  ]).then((responses) => {
    const creative = responses[0];

    dispatch(setActiveCreativeId(creative.get('id')));

    return Promise.resolve();
  });
};

export const setActiveCreativeId = (creativeId) => ({
  type: SET_ACTIVE_CREATIVE_ID,
  creativeId,
});

export const syncRuleSets = (campaignId, frameSpecificationKey, creative, relations) => (dispatch) => {
  dispatch(
    syncCampaignRelation(
      campaignId,
      ['frame-specifications', frameSpecificationKey, 'creatives'],
      creative,
      'creatives',
      relations,
      'rule-sets',
    ),
  );
};

export const syncContentTypes = (campaignId, frameSpecificationKey, creative, relations) => (dispatch) =>
  dispatch(
    syncCampaignRelation(
      campaignId,
      ['frame-specifications', frameSpecificationKey, 'creatives'],
      creative,
      'creatives',
      relations,
      'content-types',
    ),
  );

export const toggleShowSettingsForm = () => ({
  type: TOGGLE_SHOW_SETTINGS_FORM,
});

export const mergeCreativePreview = (creative) => ({
  type: MERGE_PREVIEW,
  creative,
});

export const htmlPlayClick = () => ({
  type: HTML_PLAY_CLICK,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const setActiveFrameSpec = (activeFrameSpecId) => ({
  type: SET_ACTIVE_FRAME_SPEC,
  activeFrameSpecId,
});

export const patchCreatives = (data, frameSpecId, frameSpecKey, activeCampaignId) => (dispatch) => {
  dispatch({ type: PATCH_CREATIVES_REQUEST, frameSpecId });
  return Request.send({ endpoint: 'creatives', method: 'PATCH', data })
    .then((response) => {
      dispatch({ type: PATCH_CREATIVES_COMPLETE, frameSpecId });
      const creatives = fromJS(response.data.body.parsed);
      dispatch(mergeCreativeIntoFrameSpecs(activeCampaignId, frameSpecKey, creatives));
      return Promise.resolve(creatives);
    })
    .catch(() => {
      dispatch({ type: PATCH_CREATIVES_COMPLETE, frameSpecId });
      return Promise.reject(new Error('failed to update creative'));
    });
};

export const updateRelations = (
  frameSpecId,
  frameSpecKey,
  activeCampaignId,
  selectedCreatives,
  relation,
  actionItem,
  method,
) => (dispatch) => {
  dispatch({ type: PATCH_CREATIVES_REQUEST, frameSpecId });

  const updatedCreatives = selectedCreatives.map((selectedCreative) => {
    if (method === HttpMethods.POST) {
      const existingRelationList = selectedCreative.get(relation, new List());
      const updatedRelationList = existingRelationList.includes(actionItem)
        ? existingRelationList
        : existingRelationList.push(actionItem);
      return selectedCreative.set(relation, updatedRelationList);
    }
    if (method === HttpMethods.DELETE) {
      const updatedRelationList = selectedCreative
        .get(relation)
        .filter((relationItem) => relationItem.get('id') !== actionItem.get('id'));
      return selectedCreative.set(relation, updatedRelationList);
    }
  });

  const data = selectedCreatives.map((creative) => ({ type: 'creatives', id: creative.get('id') })).toJS();

  const options = {
    endpoint: `${relation}/${actionItem.get('id')}/relationships/creatives`,
    method,
    data,
  };

  return Request.send(options)
    .then(() => {
      dispatch({ type: PATCH_CREATIVES_COMPLETE, frameSpecId });
      dispatch(mergeCreativeIntoFrameSpecs(activeCampaignId, frameSpecKey, updatedCreatives));
      return Promise.resolve(updatedCreatives);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: PATCH_CREATIVES_COMPLETE, frameSpecId });
      return Promise.reject(error);
    });
};

export const reprocessCreative = (creativeId) => async (dispatch) => {
  try {
    const options = {
      endpoint: `creatives/${creativeId}/reprocess`,
      method: 'PATCH',
    };

    await Request.send(options);
  } catch (error) {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  }
};
