import React from 'react';
import { Checkbox as BaseCheckbox } from 'react-toolbox/lib/checkbox';
import theme from './checkbox.scss';

class Checkbox extends BaseCheckbox {
  render() {
    const { props } = this;
    return <BaseCheckbox {...props} theme={theme} />;
  }
}

export default Checkbox;
