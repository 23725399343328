import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Input from 'components/patterns/Input';
import ProgressBar from 'components/patterns/ProgressBar';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';

import style from './style.scss';

class LargeEditDialog extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      internalValue: this.props.value,
      working: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { t } = this.props;
    const { internalValue, working } = this.state;
    return (
      <div className={style.component}>
        {working ? (
          <div className={style.progressBar}>
            <ProgressBar />
          </div>
        ) : null}
        <Input onChange={this.handleInputChange} value={internalValue} disabled={working} autoFocus />
        <Button
          label={t('Save')}
          onClick={this.handleSaveClick}
          theme={ButtonThemes.PRIMARY}
          className={style.button}
          disabled={working}
        />
        <Button
          label={t('Cancel')}
          onClick={this.handleCancelClick}
          theme={ButtonThemes.PRIMARY}
          className={style.button}
          disabled={working}
        />
      </div>
    );
  }

  handleSaveClick = () => {
    this.setState({
      working: true,
    });
    const { onChange } = this.props;
    const { internalValue } = this.state;
    onChange(internalValue)
      .then(() => this.clear())
      .catch(() => {
        this.setState({
          working: false,
        });
      });
  };

  handleCancelClick = () => {
    const { onCancel } = this.props;
    onCancel();
    this.clear();
  };

  clear = () => {
    if (this.mounted) {
      const { value } = this.props;
      this.setState({
        internalValue: value,
        working: false,
      });
    }
  };

  handleInputChange = (internalValue) => this.setState({ internalValue });
}

LargeEditDialog.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

LargeEditDialog.defaultProps = {
  value: '',
};

export default withNamespaces(['common'], { wait: true })(LargeEditDialog);
