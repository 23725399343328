import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { publish } from 'store/publications/actions';
import PublishButton from './PublishButton';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      publish,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(PublishButton);
