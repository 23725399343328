import { handleActions } from 'redux-actions';
import * as actions from './actions';

const getInitialState = () => ({
  campaigns: [],
  campaignsInProcess: [],
});

const pinnedCampaigns = handleActions(
  {
    [actions.PIN_CAMPAIGN_SUCCESS]: (state, action) => {
      const { campaigns } = state;

      return {
        ...state,
        campaigns: [action.campaign, ...campaigns],
      };
    },
    [actions.UNPIN_CAMPAIGN_SUCCESS]: (state, action) => {
      const { campaigns } = state;

      return {
        ...state,
        campaigns: campaigns.filter((c) => c.id !== action.campaignId),
      };
    },
    [actions.FETCH_PIN_CAMPAIGNS_SUCCESS]: (state, action) => ({
      ...state,
      campaigns: action.campaigns,
    }),
    [actions.SET_IS_FETCHING_PIN_BY_CAMPAIGNID]: (state, action) => {
      const { campaignsInProcess } = state;
      const { payload } = action;

      return {
        ...state,
        campaignsInProcess: payload.isPinned
          ? [...campaignsInProcess, payload.campaignId]
          : campaignsInProcess.filter((campaignId) => campaignId !== payload.campaignId),
      };
    },
  },
  getInitialState(),
);

export default pinnedCampaigns;
