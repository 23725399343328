import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import { ContentModerationStatuses, getModerationIcon } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import Switch from 'components/patterns/Switch';
import { IconButton } from 'react-toolbox/lib/button';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import Accordion from 'assets/components/presentational/Accordion';
import Pagination from 'assets/components/presentational/Pagination';

import style from './moderationSection.scss';

class ModerationSection extends Component {
  getIconClassName = () => {
    const { status } = this.props;

    switch (status) {
      case ContentModerationStatuses.APPROVED:
        return IconColors.GREEN;
      case ContentModerationStatuses.REJECTED:
        return IconColors.RED;
      case ContentModerationStatuses.PENDING:
        return IconColors.ORANGE;
      default:
        return style.icon;
    }
  };

  handleBypassProfanityFilter = (bypass) => {
    const { onBypassProfanityFilterChange } = this.props;
    onBypassProfanityFilterChange(bypass);
  };

  renderBypassProfanityFilterSwitch = () => {
    const { bypassProfanityFilter, fetching, saving, status, t, user } = this.props;
    const working = fetching || saving;

    if (status !== ContentModerationStatuses.REJECTED || !user.get('is-super-user')) {
      return null;
    }
    return (
      <div className={working && style.bypassHidden}>
        <Switch
          label={t('Bypass Profanity Filter')}
          checked={bypassProfanityFilter}
          onChange={this.handleBypassProfanityFilter}
        />
      </div>
    );
  };

  render() {
    const { children, collapsed, disabled, fetching, saving, onToggleClick, status, t } = this.props;
    const working = fetching || saving;

    return (
      <div key={status}>
        <Accordion
          className={style.accordion}
          collapsed={collapsed}
          disabled={disabled}
          label={this.renderSectionTitle(status)}
          onClick={() => onToggleClick(status)}
        >
          <div className={style.actions}>
            <div className={working ? style.toggleAllContainerHidden : style.toggleAllContainer}>
              <em className={style.prompt}>{`${t('Select single or multiple items')}:`}</em>
              &nbsp;
              {this.renderToggleAllItemsIcon(status)}
            </div>
            {this.renderBypassProfanityFilterSwitch()}
          </div>
          {this.renderSectionOverlay()}

          <div className={working ? style.itemsGrid : style.itemsGridVisible}>{children}</div>
        </Accordion>

        {this.renderPendingPagination(status)}
        {this.renderSectionButtons(status)}
      </div>
    );
  }

  renderSectionOverlay = () => {
    const { saving, t } = this.props;
    const working = saving;

    const label = t('Saving');

    return (
      <div className={working ? style.progressOverlayVisible : style.progressOverlay}>
        {working ? (
          <span className={style.progressOverlayInner}>
            <ProgressBar circular />
            <span className={style.progressOverlayLabel}>
              {label}
              ...
            </span>
          </span>
        ) : null}
      </div>
    );
  };

  renderSectionTitle = () => {
    const { icon, label } = this.props;

    return (
      <span>
        <Icon iconType={icon} color={this.getIconClassName()} />
        &nbsp;
        <span className={style.title}>{label}</span>
      </span>
    );
  };

  renderSectionButtons = (status) => {
    const { children, collapsed } = this.props;

    if (!collapsed || !children) {
      return null;
    }

    return (
      <div className={style.buttons}>
        {this.renderRejectButton(status)}
        {this.renderApproveButton(status)}
        {this.renderUnmoderateButton(status)}
        {this.renderDeleteButton(status)}
      </div>
    );
  };

  renderButton = (oldStatus, newStatus, label, theme) => {
    const { deleting, saving, onUpdateClick, selectedCount, t } = this.props;

    if (oldStatus === newStatus) {
      return null;
    }

    return (
      <Button
        className={style.button}
        disabled={!selectedCount || saving || deleting}
        icon={<Icon iconType={getModerationIcon(newStatus)} />}
        label={t(label)}
        onClick={() => onUpdateClick(oldStatus, newStatus)}
        theme={theme}
      />
    );
  };

  renderApproveButton = (status) =>
    this.renderButton(status, ContentModerationStatuses.APPROVED, 'Approve', ButtonThemes.GREEN);

  renderUnmoderateButton = (status) =>
    this.renderButton(status, ContentModerationStatuses.PENDING, 'Unmoderate', ButtonThemes.WARNING);

  renderRejectButton = (status) =>
    this.renderButton(status, ContentModerationStatuses.REJECTED, 'Reject', ButtonThemes.DANGER);

  renderDeleteButton = (status) => {
    const { deleting, onDeleteClick, saving, selectedCount, t } = this.props;

    if (status !== ContentModerationStatuses.REJECTED) {
      return null;
    }

    return (
      <Button
        className={style.button}
        disabled={!selectedCount || saving || deleting}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        label={t('Delete')}
        onClick={onDeleteClick}
        theme={ButtonThemes.DANGER}
      />
    );
  };

  renderToggleAllItemsIcon = (status) => {
    const { allItemsSelected, onToggleAllItemsClick } = this.props;

    return (
      <IconButton
        onClick={() => onToggleAllItemsClick(status)}
        icon={<Icon iconType={allItemsSelected ? IconTypes.CLEAR_ALL : IconTypes.DONE_ALL} />}
      />
    );
  };

  renderPendingPagination = (status) => {
    const { children, collapsed, onPaginationClick, pageNumber, totalPages } = this.props;
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <Pagination
        containerClassName={style.pagination}
        forceSelected={pageNumber - 1}
        hidden={!collapsed || status !== ContentModerationStatuses.PENDING || !children}
        initialSelected={initialSelected}
        onClick={onPaginationClick}
        pageNum={totalPages}
      />
    );
  };
}

export default withNamespaces(['common', 'moderation'], { wait: false })(ModerationSection);

ModerationSection.propTypes = {
  allItemsSelected: PropTypes.bool,
  bypassProfanityFilter: PropTypes.bool.isRequired,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  deleting: PropTypes.bool,
  disabled: PropTypes.bool,
  fetching: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBypassProfanityFilterChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  onPaginationClick: PropTypes.func,
  onToggleAllItemsClick: PropTypes.func,
  onToggleClick: PropTypes.func,
  onUpdateClick: PropTypes.func,
  pageNumber: PropTypes.number,
  saving: PropTypes.bool,
  status: PropTypes.number,
  selectedCount: PropTypes.number,
  totalPages: PropTypes.number,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
};

ModerationSection.defaultProps = {
  allItemsSelected: false,
  children: null,
  collapsed: false,
  deleting: false,
  disabled: false,
  fetching: false,
  onDeleteClick: null,
  onPaginationClick: null,
  onToggleAllItemsClick: null,
  onToggleClick: null,
  onUpdateClick: null,
  pageNumber: 1,
  saving: false,
  status: null,
  selectedCount: 0,
  totalPages: 1,
};
