import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { reduxForm, reset } from 'redux-form';
import { Map } from 'immutable';
import { redirect } from 'modules/Helpers';
import notify, { NotificationTypes } from 'actions/snackbar';
import { getUserFormInitialValues } from 'store/user/helpers';
import { userCreateProfileEdited, userCreateProfileEditCancelled, createUser } from 'actions/users';
import UserFormProfile from 'assets/components/presentational/Users/UserFormProfile';

class UserFormUserCreateContainer extends Component {
  handleCancel = () => {
    const { dispatch } = this.props;

    dispatch(userCreateProfileEditCancelled());
    dispatch(reset('userFormNewUser'));
    redirect('users/');
  };

  handleOnChange = () => {
    const { dispatch } = this.props;

    dispatch(userCreateProfileEdited());
  };

  handleUpdateUser = (data) => {
    const { t, dispatch } = this.props;

    return dispatch(createUser(data)).then((newUser) => {
      dispatch(notify(NotificationTypes.SUCCESS, t('New user created')));
      redirect(`/users/${newUser.get('id')}/edit`);
    });
  };

  render() {
    const { isUpdatingUser, userCreateProfileEdited } = this.props;

    return (
      <UserFormProfile
        {...this.props}
        showActions
        handleCancel={this.handleCancel}
        handleOnChange={this.handleOnChange}
        handleUpdateUser={this.handleUpdateUser}
        disableSubmitAction={isUpdatingUser || !userCreateProfileEdited}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  isSuper: state.auth.user.get('is-super-user'),
  userCreateProfileEdited: state.users.userCreateProfileEdited,
  isUpdatingUser: state.users.isUpdatingUser,
  initialValues: getUserFormInitialValues(new Map()),
});

export default withNamespaces(['common', 'users'], { wait: true })(
  connect(mapStateToProps)(
    reduxForm({
      form: 'userFormNewUser',
      fields: ['name', 'email', 'isSubscribedToCampaignDigest'],
    })(UserFormUserCreateContainer),
  ),
);

UserFormUserCreateContainer.propTypes = {
  authUser: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userCreateProfileEdited: PropTypes.bool,
  isUpdatingUser: PropTypes.bool,
  isSuper: PropTypes.bool,
};
