import { handleActions } from 'redux-actions';
import { ACTION_TYPES } from './actions';
import { ACTIVITY_STATUS } from './constants';

const initialState = {};

const reducer = handleActions(
  {
    [ACTION_TYPES.BEGIN]: (state, action) => ({
      ...state,
      [action.payload.activity]: ACTIVITY_STATUS.PENDING,
    }),
    [ACTION_TYPES.END]: (state, action) => ({
      ...state,
      [action.payload.activity]: ACTIVITY_STATUS.IDLE,
    }),
  },
  initialState,
);

export default reducer;
