import { handleActions } from 'redux-actions';

export default handleActions(
  {
    RESET_UPLOADS: () => [],
    CLEAR_UPLOADS: (state, action) => state.filter((upload) => upload.instanceId !== action.instanceId),
    ADD_UPLOAD: (state, action) => [...state, action.upload],
    UPDATE_UPLOAD: (state, action) =>
      state.map((upload) =>
        upload.uniqueIdentifier === action.uniqueIdentifier ? { ...upload, ...action.upload } : upload,
      ),
    REMOVE_UPLOAD: (state, action) => state.filter((upload) => upload.uniqueIdentifier !== action.uniqueIdentifier),
  },
  [],
);
