import React, { Component } from 'react';
import { List as BaseList, ListProps } from 'react-toolbox/lib/list';
import theme from './list.scss';

class List extends Component {
  render() {
    const { theme: themeOverride } = this.props;
    return <BaseList {...this.props} theme={Object.assign(theme, themeOverride)} />;
  }
}

export default List;

List.propTypes = ListProps;
List.defaultProps = BaseList.defaultProps;
