import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import json2csv from 'json2csv';
import Button from 'assets/components/presentational/Button/index';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

const openBrowserDownloadDialog = (filename, data) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

class ExportCsvButton extends Component {
  componentDidUpdate(prevProps) {
    const { fetching } = this.props;
    if (prevProps.fetching && !fetching) {
      this.handleExportFile();
    }
  }

  getData() {
    const { dataItems, fieldSpec } = this.props;
    const data = [];

    dataItems.map((lineItem) => {
      const row = {};

      Object.keys(fieldSpec).forEach((field) => {
        const fieldPath = fieldSpec[field];

        if (typeof fieldPath === 'string') {
          row[field] = lineItem.get(fieldPath);
        } else if (fieldPath !== null && typeof fieldPath === 'object' && typeof fieldPath[1] !== 'undefined') {
          row[field] = lineItem.getIn(fieldPath);
        }

        if (row[field] !== null && typeof row[field] === 'object') {
          row[field] = row[field].filter((e) => e).join(';');
        }

        if (['string', 'boolean', 'number'].indexOf(typeof row[field]) === -1) {
          row[field] = null;
        }
      });

      data.push(row);
    });

    return data;
  }

  handleExportFile() {
    const { fieldSpec, fileName, onDownload } = this.props;
    const data = json2csv({
      fields: Object.keys(fieldSpec),
      data: this.getData(),
    });
    openBrowserDownloadDialog(fileName, data);
    onDownload(fileName, data);
  }

  render = () => {
    const { fetching, onFetchComplete, t } = this.props;
    return (
      <Button
        icon={<Icon iconType={IconTypes.FILE_DOWNLOAD} />}
        label={t('Export CSV')}
        flat
        disabled={fetching}
        onClick={onFetchComplete}
      />
    );
  };
}

export { ExportCsvButton as ExportCsvButtonWithoutTranslation };
export default withNamespaces(['common'], { wait: false })(ExportCsvButton);

ExportCsvButton.propTypes = {
  onFetchComplete: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  dataItems: PropTypes.object.isRequired,
  fieldSpec: PropTypes.object.isRequired,
  fileName: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ExportCsvButton.defaultProps = {
  onDownload: () => {},
};
