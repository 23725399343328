import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';

import notify, { NotificationTypes } from 'actions/snackbar';
import {
  fetchBusinessUnitsPermissionsData,
  updateUserSettingsBusinessUnits,
  propagateCampaignPermissions,
  revokeCampaignPermissions,
} from 'actions/permissions';

import PermissionsBusinessUnits from 'assets/components/presentational/Users/PermissionsBusinessUnits';
import Button, { ButtonThemes } from 'assets/components/presentational/Button/index';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Dialog from 'assets/components/presentational/Dialog';

import style from './style.scss';

class PermissionsBusinessUnitsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: Map(),
      showRevokePermissionsDialog: false,
      revokePermissionsDialogBU: null,
    };
  }

  componentDidMount() {
    const { dispatch, userId } = this.props;

    dispatch(fetchBusinessUnitsPermissionsData(userId)).then(() => {
      this.generatePermissionsState(this.props);
    });
  }

  componentDidUpdate(prevProps) {
    const { userSettingsBusinessUnits, userSettingsBusinessUnitsUpdated } = this.props;
    const settingsHaveChanged = prevProps.userSettingsBusinessUnits.size !== userSettingsBusinessUnits.size;

    if (userSettingsBusinessUnitsUpdated.size > 0 && settingsHaveChanged) {
      this.updatePermissionState(userSettingsBusinessUnitsUpdated);
    }
  }

  generatePermissionsState({
    availableBusinessUnitsPermissionOptions,
    availableBusinessUnits,
    userSettingsBusinessUnits,
  }) {
    const initialState = availableBusinessUnits.reduce((acc, businessUnit) => {
      const businessUnitId = businessUnit.get('id');

      const userSettings = userSettingsBusinessUnits
        .filter((perm) => perm.get('object-id') === businessUnitId)
        .reduce((accumulator, i) => accumulator.set(i.get('action'), i.get('id')), Map());

      return acc.set(
        businessUnitId,
        businessUnit.set(
          'options',
          availableBusinessUnitsPermissionOptions.map((item) => {
            if (userSettings.has(item.get('id'))) {
              return item
                .set('checked', true)
                .set('permissionId', userSettings.get(item.get('id')))
                .set('disabled', false);
            }
            return item.set('checked', false).set('disabled', false);
          }),
        ),
      );
    }, Map());

    this.setState({
      permissions: initialState,
    });
  }

  updatePermissionState(newSetting) {
    this.setState(({ permissions }) => ({
      permissions: permissions.updateIn([newSetting.get('object-id'), 'options'], (options) => {
        const index = options.findIndex((option) => option.get('id') === newSetting.get('action'));
        return options.setIn([index, 'permissionId'], newSetting.get('id')).setIn([index, 'disabled'], false);
      }),
    }));
  }

  handleUpdateUserSettings = (objectId, targetOption, checked) => {
    const { t, dispatch, userId } = this.props;

    dispatch(updateUserSettingsBusinessUnits(userId, objectId, targetOption, checked)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('User permissions updated')));
    });
  };

  handlePropagateCampaignPermissionsClick = () => {
    const { t, dispatch, userId } = this.props;

    dispatch(propagateCampaignPermissions(userId)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('Campaign permissions propagation requested')));
    });
  };

  handleRevokePermissionsClick = (bu) => {
    this.setState({
      revokePermissionsDialogBU: bu,
      showRevokePermissionsDialog: true,
    });
  };

  confirmRevokePermissionsDialog = () => {
    const { t, dispatch, userId } = this.props;
    const { revokePermissionsDialogBU } = this.state;

    this.setState({
      showRevokePermissionsDialog: false,
    });

    dispatch(revokeCampaignPermissions(userId, revokePermissionsDialogBU)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('Campaign permission revocation requested')));
    });
  };

  handleCheckboxChange = (objectId, targetOption, checked) => {
    const { permissions } = this.state;
    const optionIdIndex = permissions
      .first()
      .get('options')
      .findIndex((option) => option.get('id') === targetOption.id);

    this.setState(
      ({ permissions }) => ({
        permissions: permissions.updateIn([objectId, 'options', optionIdIndex], (option) =>
          option
            .update('checked', (bool) => !bool)
            .update('permissionId', () => '')
            .update('disabled', () => true),
        ),
      }),
      this.handleUpdateUserSettings(objectId, targetOption, checked),
    );
  };

  renderRevokePermissionsDialog() {
    const { showRevokePermissionsDialog, revokePermissionsDialogBU } = this.state;
    const { t, availableBusinessUnits } = this.props;

    const buItem = availableBusinessUnits.find((item) => item.get('id') === revokePermissionsDialogBU);
    const buName = buItem ? buItem.get('name') : null;
    const dialogBUText = revokePermissionsDialogBU
      ? t('in the `{{buName}}` business unit', { buName })
      : t('all business units');

    const actions = [
      {
        label: t('Cancel'),
        onClick: () => {
          this.setState({
            showRevokePermissionsDialog: false,
          });
        },
      },
      {
        label: t('Confirm'),
        onClick: () => this.confirmRevokePermissionsDialog(),
      },
    ];

    return (
      <Dialog active={showRevokePermissionsDialog} actions={actions}>
        {t('Revoke all campaign permissions for this user {{dialogBUText}}?', { dialogBUText })}
      </Dialog>
    );
  }

  render() {
    const {
      t,
      isFetching,
      isUpdating,
      maxHeight,
      isPropagatingCampaignPermissions,
      isRevokingPermissions,
    } = this.props;
    const { permissions } = this.state;

    const propagateButton = (
      <div className={style.propagateContainer}>
        <Button
          icon={<Icon iconType={IconTypes.UPDATE} />}
          theme={ButtonThemes.GREY}
          label={t('Propagate Campaign Permissions')}
          flat
          disabled={isPropagatingCampaignPermissions}
          onClick={this.handlePropagateCampaignPermissionsClick}
        />

        <Button
          disabled={isRevokingPermissions}
          icon={<Icon iconType={IconTypes.REMOVE_CIRCLE_OUTLINE} />}
          theme={ButtonThemes.GREY}
          label={t('Revoke Campaign Permissions')}
          flat
          onClick={this.handleRevokePermissionsClick}
        />
      </div>
    );

    return (
      <div>
        {this.renderRevokePermissionsDialog()}
        <PermissionsBusinessUnits
          t={t}
          permissions={permissions}
          onCheckboxChange={this.handleCheckboxChange}
          onRevokePermissionsClick={this.handleRevokePermissionsClick}
          isFetching={isFetching}
          isUpdating={isUpdating}
          maxHeight={maxHeight}
          isRevokingPermissions={isRevokingPermissions}
        />
        {!isFetching ? propagateButton : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.permissions.isFetchingBusinessUnitsData,
  isRevokingPermissions: state.permissions.isRevokingPermissions,
  isPropagatingCampaignPermissions: state.permissions.isPropagatingCampaignPermissions,
  availableBusinessUnits: state.permissions.availableBusinessUnits,
  availableBusinessUnitsPermissionOptions: state.permissions.availableBusinessUnitsPermissionOptions,
  userSettingsBusinessUnits: state.permissions.userSettingsBusinessUnits,
  userSettingsBusinessUnitsUpdated: state.permissions.userSettingsBusinessUnitsUpdated,
  isUpdating: state.permissions.isUpdatingUserSettingsBusinessUnits,
});

export default withNamespaces(['common', 'users'], { wait: true })(
  connect(mapStateToProps)(PermissionsBusinessUnitsContainer),
);

PermissionsBusinessUnitsContainer.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPropagatingCampaignPermissions: PropTypes.bool.isRequired,
  availableBusinessUnits: PropTypes.instanceOf(List).isRequired,
  availableBusinessUnitsPermissionOptions: PropTypes.instanceOf(List).isRequired,
  userSettingsBusinessUnits: PropTypes.instanceOf(List).isRequired,
  userSettingsBusinessUnitsUpdated: PropTypes.instanceOf(Map).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isRevokingPermissions: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
};
