import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';

import { setActiveSection } from 'actions/campaign/navigation';
import { fetchCreativeForPreview } from 'actions/liteModeration';
import { moderate, syncRelations } from 'actions/campaign/creative/preview';
import { success } from 'actions/notify';

import CreativePreviewLiteModeration from './CreativePreviewLiteModeration';

const mapStateToProps = (state) => ({
  campaign: state.liteModeration.campaign,
  creative: state.liteModeration.creative,
  isFetching: state.liteModeration.isFetchingCreative,
  frameSpecifications: state.liteModeration.creatives.getIn(['frame-specifications'], new List()),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveSection,
      fetchCreativeForPreview,
      moderate,
      syncRelations,
      success,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreativePreviewLiteModeration);
