import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { get, debounce, startCase } from 'lodash';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import Scroll from 'react-scroll';

import config from 'app-config';

import {
  ContentModerationStatuses,
  ContentItemsRejectReasons,
  getModerationIcon,
  redirect,
  getURLQueryString,
} from 'modules/Helpers';

import { deleteContentItems, fetchCampaignRelations, clearCampaignRelation } from 'actions/campaign';
import { setActiveSection } from 'actions/campaign/navigation';
import {
  fetchContentItems,
  fetchNewContentItems,
  deleteContentItemsPromptToggle,
  updateStatuses,
  mergeSelectedContentItemIds,
  mergeCollapsedSections,
  resetState,
  setActiveContentItemId,
  toggleContentItemDrawer,
  setContentSearchValue,
  setContentTypeFilter,
  updateBypassProfanityFilter,
} from 'actions/moderation';
import notify, { NotificationTypes } from 'actions/snackbar';
import { getContentTypes } from 'selectors/campaign';

import ProgressBar from 'components/patterns/ProgressBar';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import Dialog from 'assets/components/presentational/Dialog';
import Drawer from 'assets/components/presentational/Drawer';
import ConfigurableFormContainer from 'assets/components/containers/ConfigurableForm/ConfigurableFormContainer';
import ContentItemsFilters from 'assets/components/presentational/Campaign/ContentItemsFilters';
import ContentItemRow from 'assets/components/presentational/Campaign/Content/ContentItemRow';

import ModerationSection from './ModerationSection';

import style from './moderation.scss';

class Moderation extends Component {
  constructor(props) {
    super(props);
    this.handleFetchContentItems = debounce(this.handleFetchContentItems, 200);
  }

  componentDidMount() {
    const {
      dispatch,
      params: { campaignId },
      location: {
        query: { contentTypeId, contentItemName },
      },
    } = this.props;
    const {
      params: { pageNumber },
    } = this.props;

    dispatch(setActiveSection('moderation'));
    // Fetch only if no filters present, otherwise let ContentItemsFilters do the fetching
    if (!contentTypeId && !contentItemName) {
      dispatch(resetState());
      this.handleFetchContentItems(pageNumber);
    }

    // Required for the content type filter
    dispatch(fetchCampaignRelations(campaignId, 'content-types'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    clearTimeout(this.timeout);

    return dispatch(resetState());
  }

  getContentItemsByStatus = (status) => {
    const { contentItems, selectedContentTypeIds } = this.props;

    return contentItems
      .filter((contentItem) => contentItem.get('status') === status)
      .filter((contentItem) => {
        if (!selectedContentTypeIds.size) {
          return contentItem;
        }

        return (
          selectedContentTypeIds.findIndex(
            (selectedContentTypeId) => selectedContentTypeId === contentItem.get('content-type').get('id'),
          ) > -1
        );
      })
      .sortBy((contentItem) =>
        (contentItem.get('updated-at') ? contentItem.get('updated-at') : contentItem.get('created-at'))
          .toString()
          .concat('-', contentItem.get('name')),
      )
      .reverse();
  };

  getSelectedContentItemsByStatus = (status) =>
    this.getContentItemsByStatus(status).filter((contentItem) => this.isContentItemSelected(contentItem.get('id')));

  getStatusTypes = () => {
    const statuses = [];

    Object.keys(ContentModerationStatuses).forEach((key) =>
      statuses.push({
        value: ContentModerationStatuses[key],
        description: key,
        icon: getModerationIcon(ContentModerationStatuses[key]),
        iconClassName: this.getStatusIconClassName(ContentModerationStatuses[key]),
      }),
    );

    return statuses;
  };

  getStatusIconClassName = (status) => {
    switch (status) {
      case ContentModerationStatuses.PENDING:
        return style.sectionIconUnmoderated;
      case ContentModerationStatuses.REJECTED:
        return style.sectionIconRejected;
      case ContentModerationStatuses.APPROVED:
        return style.sectionIconApproved;
      default:
        return null;
    }
  };

  getCurrentPageNumber = () => {
    let {
      params: { pageNumber },
    } = this.props;

    pageNumber = parseInt(pageNumber);

    return Number.isNaN(pageNumber) ? 1 : pageNumber;
  };

  startPoll = () => {
    const {
      dispatch,
      isSaving,
      lastFetchAt,
      params: { campaignId },
      location: {
        query: { contentTypeId, contentItemName },
      },
    } = this.props;
    // Default to 60 seconds if no config
    const interval = get(config, 'contentItemModeration.polling.intervalSeconds', 60) * 1000;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (!get(config, 'contentItemModeration.polling.enabled', false)) {
        return;
      }

      if (isSaving) {
        this.startPoll();
      }

      dispatch(fetchNewContentItems(campaignId, lastFetchAt, contentTypeId, contentItemName)).then(() => {
        this.startPoll();
      });
    }, interval);
  };

  isContentItemSelected = (contentItemId) => {
    const { selectedContentItemIds } = this.props;

    return selectedContentItemIds.findIndex((id) => id === contentItemId) > -1;
  };

  isSectionCollapsed = (sectionStatus) => {
    const { collapsedSections } = this.props;

    return collapsedSections.findIndex((status) => status === sectionStatus) > -1;
  };

  isContentTypeSelected = (contentTypeId) => {
    const { selectedContentTypeIds } = this.props;

    return selectedContentTypeIds.findIndex((selectedContentTypeId) => selectedContentTypeId === contentTypeId) > -1;
  };

  collapseSectionIfEmpty = (status) => {
    const { dispatch } = this.props;
    let { collapsedSections } = this.props;

    if (!this.getContentItemsByStatus(status).size) {
      collapsedSections = collapsedSections.filter((collapsedSectionStatus) => collapsedSectionStatus !== status);

      dispatch(mergeCollapsedSections(collapsedSections));
    }
  };

  redirectToNewPage = (pageNumber = 1) => {
    const {
      params: { campaignId },
      location: {
        query: { contentTypeId, contentItemName },
      },
    } = this.props;
    const queryString = getURLQueryString({ contentTypeId, contentItemName });

    return redirect(`/campaigns/${campaignId}/moderation/${pageNumber}${queryString}`);
  };

  isFetchingContent = (status) => {
    const { isFetchingContentItems, isFetchingContentItemsPending, isPollingContentItems } = this.props;

    return (
      ((isFetchingContentItems && status !== ContentModerationStatuses.PENDING) ||
        (isFetchingContentItemsPending && status === ContentModerationStatuses.PENDING)) &&
      !isPollingContentItems
    );
  };

  handleFetchContentItems = (pageNumber, filters) => {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    // TODO: Why this not always comes as a number? Check and eventually fix
    pageNumber = parseInt(pageNumber); // eslint-disable-line no-param-reassign
    if (pageNumber < 1) pageNumber = 1; // eslint-disable-line no-param-reassign

    dispatch(clearCampaignRelation(campaignId, ['content-items']));

    dispatch(fetchContentItems(campaignId, pageNumber, filters)).then((responses) => {
      let totalPages = parseInt(responses[1].data.body.meta.pagination.total_pages);

      totalPages = totalPages < 1 ? 1 : totalPages;

      if (pageNumber > totalPages) {
        this.redirectToNewPage(totalPages);
      }

      Scroll.animateScroll.scrollToTop();
      this.startPoll();
    });
  };

  handleContentTypeFilterChange = (nextContentTypeFilter) => {
    const { dispatch } = this.props;
    dispatch(setContentTypeFilter(nextContentTypeFilter));
  };

  handleContentSearchValueChange = (nextSearchContent) => {
    const { dispatch } = this.props;
    dispatch(setContentSearchValue(nextSearchContent));
  };

  handleContentItemCheckboxChanged = (selectedId, checked) => {
    const { dispatch, selectedContentItemIds } = this.props;

    const addIdToSelected = (ids, id) => ids.push(id);
    const removeIdFromSelected = (ids, id) => ids.filter((i) => i !== id);
    let newSelectedContentItemIds = [];

    if (checked) {
      newSelectedContentItemIds = addIdToSelected(selectedContentItemIds, selectedId);
    } else {
      newSelectedContentItemIds = removeIdFromSelected(selectedContentItemIds, selectedId);
    }

    dispatch(mergeSelectedContentItemIds(newSelectedContentItemIds));
  };

  handleUpdateStatusClick = (oldStatus, newStatus) => {
    const {
      bypassProfanityFilter,
      dispatch,
      pagination,
      params: { campaignId },
      location: {
        query: { contentTypeId, contentItemName },
      },
      t,
      user,
    } = this.props;
    const { selectedContentItemIds } = this.props;
    const selectedContentItems = this.getSelectedContentItemsByStatus(oldStatus);

    dispatch(updateStatuses(campaignId, selectedContentItems, newStatus, bypassProfanityFilter)).then(
      (contentItems) => {
        const rejectedItemsWithProfanity = contentItems.filter(
          (contentItem) =>
            contentItem.get('status') === ContentModerationStatuses.REJECTED &&
            contentItem.get('reject-reason') === ContentItemsRejectReasons.CONTAINS_PROFANITY,
        );
        dispatch(mergeSelectedContentItemIds(selectedContentItemIds.clear()));
        if (newStatus === ContentModerationStatuses.APPROVED && rejectedItemsWithProfanity.size > 0) {
          const actionTxt = user.get('is-super-user')
            ? t('Please review and amend.')
            : t('Please contact support if you believe this is a mistake.');
          dispatch(
            notify(
              NotificationTypes.WARNING,
              `${t('X items updated successfully', {
                count: selectedContentItemIds.size - rejectedItemsWithProfanity.size,
              })}. ${t('X item was auto-rejected as it may contain profanity.', {
                count: rejectedItemsWithProfanity.size,
              })}${actionTxt}`,
            ),
          );
        } else {
          dispatch(
            notify(
              NotificationTypes.SUCCESS,
              t('X items updated successfully', { count: selectedContentItemIds.size }),
            ),
          );
        }
        // If items have been added or moved to the pending pot
        if (oldStatus === ContentModerationStatuses.PENDING || newStatus === ContentModerationStatuses.PENDING) {
          const currentPageNumber = pagination.get('current_page', 1);

          // Re-fetch the pending data for the latest meta
          this.handleFetchContentItems(currentPageNumber, { contentTypeId, contentItemName }).then(() => {
            this.collapseSectionIfEmpty(oldStatus);
          });
        }

        this.startPoll();
      },
    );
  };

  handleSectionToggleClick = (status) => {
    const { collapsedSections, dispatch } = this.props;

    const addStatusToCollapsedSections = (sections, st) => [...sections, st];
    const removeStatusFromCollapsedSections = (sections, st) =>
      sections.filter((sectionStatus) => sectionStatus !== st);

    let newCollapsedSections = [];

    if (this.isSectionCollapsed(status)) {
      newCollapsedSections = removeStatusFromCollapsedSections(collapsedSections, status);
    } else if (this.getContentItemsByStatus(status).size) {
      newCollapsedSections = addStatusToCollapsedSections(collapsedSections, status);
    }

    dispatch(mergeCollapsedSections(newCollapsedSections));
  };

  handleToggleAllItemsClick = (status) => {
    const { dispatch } = this.props;
    const contentItems = this.getContentItemsByStatus(status);

    let { selectedContentItemIds } = this.props;

    // Determine whether we should be selecting or clearing all items
    const allSelected = contentItems.size === selectedContentItemIds.size;

    // First clear all selected items
    selectedContentItemIds = selectedContentItemIds.clear();

    // Select all items if required
    if (!allSelected) {
      selectedContentItemIds = contentItems.map((contentItem) => contentItem.get('id'));
    }

    dispatch(mergeSelectedContentItemIds(selectedContentItemIds));
  };

  handleDeleteClick = () => {
    const { dispatch } = this.props;
    dispatch(deleteContentItemsPromptToggle());
  };

  handleDeleteConfirmClick = () => {
    const { activeCampaignId, dispatch, t } = this.props;
    const status = ContentModerationStatuses.REJECTED;
    const selectedContentItems = this.getSelectedContentItemsByStatus(status);

    dispatch(deleteContentItems(activeCampaignId, selectedContentItems)).then(() => {
      dispatch(deleteContentItemsPromptToggle());
      dispatch(
        notify(NotificationTypes.SUCCESS, t('X items deleted successfully', { count: selectedContentItems.size })),
      );

      this.collapseSectionIfEmpty(status);
    });
  };

  handleDeleteCancelClick = () => {
    const { dispatch } = this.props;
    dispatch(deleteContentItemsPromptToggle());
  };

  handlePaginationClick = (event) => {
    const { dispatch, selectedContentItemIds } = this.props;
    const pageNumber = parseInt(event.selected) + 1;

    dispatch(mergeSelectedContentItemIds(selectedContentItemIds.clear()));

    return this.redirectToNewPage(pageNumber);
  };

  handleContentItemViewClick = (contentItem) => {
    const { dispatch } = this.props;
    dispatch(setActiveContentItemId(contentItem.get('id')));
    dispatch(toggleContentItemDrawer());
  };

  handleContentItemDrawerCloseClick = () => {
    const { dispatch } = this.props;
    dispatch(setActiveContentItemId(''));
    dispatch(toggleContentItemDrawer());
  };

  handleBypassProfanityFilter = (bypass) => {
    const { dispatch } = this.props;
    dispatch(updateBypassProfanityFilter(bypass));
  };

  renderContent = () => {
    const { t, location, params, contentTypes, activeNavSection } = this.props;
    const { selectedContentTypeFilter, searchContent } = this.props;

    return (
      <div>
        <ContentItemsFilters
          activeNavSection={activeNavSection}
          location={location}
          params={params}
          contentTypeFilterLabel={t('Filter by content type')}
          searchPlaceholderText={t('Search content')}
          contentTypes={contentTypes}
          selectedContentTypeFilter={selectedContentTypeFilter}
          searchContent={searchContent}
          onContentTypeFilterChange={this.handleContentTypeFilterChange}
          onContentSearchValueChange={this.handleContentSearchValueChange}
          fetchContentItems={this.handleFetchContentItems}
        />

        {this.renderSections()}
      </div>
    );
  };

  renderSections = () => {
    const { isFetchingContentItems, isPollingContentItems } = this.props;

    if (isFetchingContentItems && !isPollingContentItems) {
      return <ProgressBar />;
    }

    return <div>{this.getStatusTypes().map((status) => this.renderSection(status))}</div>;
  };

  renderSection = (status) => {
    const contentItems = this.getContentItemsByStatus(status.value);
    const { bypassProfanityFilter, isDeleting, isSaving, pagination, t, user } = this.props;
    const selectedContentItems = this.getSelectedContentItemsByStatus(status.value);

    let total = contentItems.size;
    let totalPages = 1;

    // Use pagination meta data for pending section label
    if (status.value === ContentModerationStatuses.PENDING) {
      total = pagination.get('total', 0);
      totalPages = pagination.get('total_pages', 1);
    }

    const countLabel = selectedContentItems.size ? selectedContentItems.size.toString().concat('/', total) : total;
    const label = t(startCase(status.description.toLowerCase())).concat(' (', countLabel, ')');

    return (
      <ModerationSection
        allItemsSelected={contentItems.size === selectedContentItems.size}
        bypassProfanityFilter={bypassProfanityFilter}
        collapsed={this.isSectionCollapsed(status.value) && contentItems.size > 0}
        deleting={isDeleting}
        disabled={!contentItems.size}
        saving={isSaving}
        fetching={this.isFetchingContent(status.value)}
        key={status.value}
        onBypassProfanityFilterChange={this.handleBypassProfanityFilter}
        onDeleteClick={this.handleDeleteClick}
        onPaginationClick={this.handlePaginationClick}
        onToggleClick={this.handleSectionToggleClick}
        onToggleAllItemsClick={this.handleToggleAllItemsClick}
        onUpdateClick={this.handleUpdateStatusClick}
        selectedCount={selectedContentItems.size}
        totalPages={totalPages}
        pageNumber={this.getCurrentPageNumber()}
        icon={status.icon}
        user={user}
        label={label}
        status={status.value}
      >
        <div>{contentItems.map((contentItem, key) => this.renderContentItem(contentItem, key))}</div>
      </ModerationSection>
    );
  };

  renderContentItem = (contentItem, contentItemKey) => {
    const { isSaving, t } = this.props;
    return (
      <ContentItemRow
        contentItem={contentItem}
        key={contentItemKey}
        isSelected={this.isContentItemSelected(contentItem.get('id'))}
        isSaving={isSaving}
        isSelectable
        isViewable
        onViewClick={this.handleContentItemViewClick}
        onCheckboxChange={this.handleContentItemCheckboxChanged}
        t={t}
      />
    );
  };

  renderRejectDialog = () => {
    const { isPrompting, t } = this.props;
    const selectedItems = this.getSelectedContentItemsByStatus(ContentModerationStatuses.REJECTED);
    const actions = [
      {
        label: t('Cancel'),
        onClick: (e) => this.handleDeleteCancelClick(e),
      },
      {
        label: t('Confirm'),
        onClick: (e) => this.handleDeleteConfirmClick(e),
      },
    ];

    return (
      <Dialog
        active={isPrompting}
        actions={actions}
        onEscKeyDown={() => this.handleToggleRejectDialog(false)}
        onOverlayClick={() => this.handleToggleRejectDialog(false)}
        title={t('Are you sure you wish to delete this item?', { count: selectedItems.size })}
        type="small"
      >
        {this.renderDeletingProgress()}
      </Dialog>
    );
  };

  renderDeletingProgress = () => {
    const { isDeleting } = this.props;

    if (!isDeleting) {
      return null;
    }

    return (
      <div className={style.deletingProgressContainer}>
        <ProgressBar circular />
      </div>
    );
  };

  renderContentItemDrawer = () => {
    const { activeContentItemId, contentItems, isContentItemDrawerActive, t } = this.props;
    const contentItem = contentItems.find((c) => c.get('id') === activeContentItemId, null, new Map());

    let form = {};
    let initialValues = {};

    const fields = contentItem
      .get('content-fields', List())
      .toJS()
      .map((field) => {
        if (field.value && field.value.date) {
          field.value = moment.utc(field.value.date).toDate();
        }
        return field;
      });

    if (contentItem.size) {
      form = contentItem
        .get('content-type', new Map())
        .get('form', new Map())
        .toJS();

      form.fields.unshift({
        name: t(contentItem.get('name')),
        type: 'heading',
      });

      initialValues = fields.reduce((prev, curr) => {
        const fieldConfig = form.fields ? form.fields.find((field) => field.name === curr.name) : {};
        prev[fieldConfig.slug] = curr.value;
        return prev;
      }, {});

      initialValues.name = contentItem.get('name');
    }

    return (
      <Drawer active={isContentItemDrawerActive} onOverlayClick={this.handleContentItemDrawerCloseClick}>
        <ConfigurableFormContainer
          name={`contentItem${contentItem.get('id')}`}
          formObject={form}
          initialValues={initialValues}
          onCancel={this.handleContentItemDrawerCloseClick}
          readonly
        />
      </Drawer>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className={style.component}>
        <HeadingGroup title={t('Moderation')} intro={t('Moderation section introduction')} />

        {this.renderContent()}
        {this.renderRejectDialog()}
        {this.renderContentItemDrawer()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeNavSection: state.campaignNavigation.activeSection,
  activeCampaignId: state.campaign.activeCampaignId,
  activeContentItemId: state.moderation.activeContentItemId,
  bypassProfanityFilter: state.moderation.bypassProfanityFilter,
  collapsedSections: state.moderation.collapsedSections,
  contentItems: state.campaign.campaign.get('content-items', List()),
  contentTypes: getContentTypes(state),
  isContentItemDrawerActive: state.moderation.isContentItemDrawerActive,
  isDeleting: state.campaign.isDeletingContentItems,
  isFetchingContentItems: state.moderation.isFetchingContentItems,
  isFetchingContentItemsPending: state.moderation.isFetchingContentItemsPending,
  isPollingContentItems: state.moderation.isPollingContentItems,
  isPrompting: state.moderation.isPrompting,
  isSaving: state.moderation.isSaving,
  lastFetchAt: state.moderation.lastFetchAt,
  pagination: state.moderation.pagination,
  selectedContentItemIds: state.moderation.selectedContentItemIds,
  selectedContentTypeIds: state.moderation.selectedContentTypeIds,
  selectedContentType: state.moderation.selectedContentType,
  selectedContentTypeFilter: state.moderation.selectedContentTypeFilter,
  searchContent: state.moderation.searchContentValue,
  user: state.auth.user,
});

export default withNamespaces(['common', 'moderation', 'content'], { wait: false })(
  connect(mapStateToProps)(Moderation),
);

Moderation.propTypes = {
  activeNavSection: PropTypes.string.isRequired,
  activeCampaignId: PropTypes.string.isRequired,
  activeContentItemId: PropTypes.string.isRequired,
  bypassProfanityFilter: PropTypes.bool.isRequired,
  collapsedSections: PropTypes.instanceOf(List).isRequired,
  contentItems: PropTypes.instanceOf(List).isRequired,
  contentTypes: PropTypes.instanceOf(List).isRequired,
  dispatch: PropTypes.func.isRequired,
  isContentItemDrawerActive: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isFetchingContentItems: PropTypes.bool.isRequired,
  isFetchingContentItemsPending: PropTypes.bool.isRequired,
  isPollingContentItems: PropTypes.bool.isRequired,
  isPrompting: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  lastFetchAt: PropTypes.shape({}).isRequired, // TODO: define the shape of this object
  params: PropTypes.shape({
    campaignId: PropTypes.string,
    pageNumber: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      contentTypeId: PropTypes.string,
      contentItemName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  selectedContentItemIds: PropTypes.instanceOf(List).isRequired,
  selectedContentTypeIds: PropTypes.instanceOf(List).isRequired,
  t: PropTypes.func.isRequired,
  selectedContentTypeFilter: PropTypes.string, // TODO: add a default value for this prop
  searchContent: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
};
