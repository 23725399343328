import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

const i18n = handleActions(
  {
    I18N_FETCH_REQUEST: (state) => ({
      ...state,
      isFetching: true,
    }),
    I18N_FETCH_SUCCESS: (state, action) => ({
      ...state,
      [action.i18n]: fromJS(action.data),
    }),
    I18N_FETCH_COMPLETE: (state) => ({
      ...state,
      isFetching: false,
    }),
  },
  {
    isFetching: false,
  },
);

export default i18n;
