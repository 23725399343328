import { ADNETWORKS } from 'app-constants';

import moment from 'moment';
import { fromJS, Map } from 'immutable';
import { get, mergeWith } from 'lodash';
import Request from 'modules/API/request';
import { mergeObjCustomizer, escapeNewLineItems } from 'modules/Helpers/function';

import { success, warning, notifyApiError } from 'actions/notify';
import { fetchSnoozedCampaign } from 'store/snoozeCampaign/actions';

import { fetchCampaigns } from 'actions/dashboard';

export const CAMPAIGN_SET_ACTIVE_ID = 'CAMPAIGN_SET_ACTIVE_ID';
export const CAMPAIGN_FETCH_REQUEST = 'CAMPAIGN_FETCH_REQUEST';
export const CAMPAIGN_FETCH_SUCCESS = 'CAMPAIGN_FETCH_SUCCESS';
export const CAMPAIGN_FETCH_COMPLETE = 'CAMPAIGN_FETCH_COMPLETE';

export const DELETE_CAMPAIGN_CONTENT_ITEMS_REQUEST = 'DELETE_CAMPAIGN_CONTENT_ITEMS_REQUEST';
export const DELETE_CAMPAIGN_CONTENT_ITEMS_SUCCESS = 'DELETE_CAMPAIGN_CONTENT_ITEMS_SUCCESS';
export const DELETE_CAMPAIGN_CONTENT_ITEMS_COMPLETE = 'DELETE_CAMPAIGN_CONTENT_ITEMS_COMPLETE';

export const CAMPAIGN_RELATIONS_FETCH_REQUEST = 'CAMPAIGN_RELATIONS_FETCH_REQUEST';
export const CAMPAIGN_RELATIONS_FETCH_COMPLETE = 'CAMPAIGN_RELATIONS_FETCH_COMPLETE';
export const CAMPAIGN_RELATIONS_FETCH_SUCCESS = 'CAMPAIGN_RELATIONS_FETCH_SUCCESS';

export const CAMPAIGN_RELATIONS_SYNC_REQUEST = 'CAMPAIGN_RELATIONS_SYNC_REQUEST';
export const CAMPAIGN_RELATIONS_SYNC_COMPLETE = 'CAMPAIGN_RELATIONS_SYNC_COMPLETE';
export const CAMPAIGN_RELATIONS_SYNC_SUCCESS = 'CAMPAIGN_RELATIONS_SYNC_SUCCESS';

export const CAMPAIGN_FRAMES_ATTACH_REQUEST = 'CAMPAIGN_FRAMES_ATTACH_REQUEST';
export const CAMPAIGN_FRAMES_ATTACH_FAILURE = 'CAMPAIGN_FRAMES_ATTACH_FAILURE';
export const CAMPAIGN_FRAMES_ATTACH_SUCCESS = 'CAMPAIGN_FRAMES_ATTACH_SUCCESS';

export const CAMPAIGN_FRAMES_DETACH_REQUEST = 'CAMPAIGN_FRAMES_DETACH_REQUEST';
export const CAMPAIGN_FRAMES_DETACH_FAILURE = 'CAMPAIGN_FRAMES_DETACH_FAILURE';
export const CAMPAIGN_FRAMES_DETACH_SUCCESS = 'CAMPAIGN_FRAMES_DETACH_SUCCESS';

export const CAMPAIGN_FRAMES_FETCH_REQUEST = 'CAMPAIGN_FRAMES_FETCH_REQUEST';
export const CAMPAIGN_FRAMES_FETCH_SUCCESS = 'CAMPAIGN_FRAMES_FETCH_SUCCESS';
export const CAMPAIGN_FRAMES_FETCH_FAILURE = 'CAMPAIGN_FRAMES_FETCH_FAILURE';

export const CAMPAIGN_RELATION_FETCH_REQUEST = 'CAMPAIGN_RELATION_FETCH_REQUEST';
export const CAMPAIGN_RELATION_FETCH_COMPLETE = 'CAMPAIGN_RELATION_FETCH_COMPLETE';
export const CAMPAIGN_RELATION_FETCH_SUCCESS = 'CAMPAIGN_RELATION_FETCH_SUCCESS';

export const CAMPAIGN_RELATION_REPLACE = 'CAMPAIGN_RELATION_REPLACE';
export const CAMPAIGN_RELATION_MERGE = 'CAMPAIGN_RELATION_MERGE';
export const CAMPAIGN_RELATION_REMOVE = 'CAMPAIGN_RELATION_REMOVE';
export const CAMPAIGN_RELATION_CLEAR = 'CAMPAIGN_RELATION_CLEAR';

export const CAMPAIGN_RELATION_DELETE_PROMPT = 'CAMPAIGN_RELATION_DELETE_PROMPT';
export const CAMPAIGN_RELATION_DELETE_REQUEST = 'CAMPAIGN_RELATION_DELETE_REQUEST';
export const CAMPAIGN_RELATION_DELETE_COMPLETE = 'CAMPAIGN_RELATION_DELETE_COMPLETE';

export const CAMPAIGN_CLEAR = 'CAMPAIGN_CLEAR';

export const CAMPAIGN_TOGGLE_CREATE_FORM = 'CAMPAIGN_TOGGLE_CREATE_FORM';
export const CAMPAIGN_SHOW_CREATE_FORM = 'CAMPAIGN_SHOW_CREATE_FORM';
export const CAMPAIGN_HIDE_CREATE_FORM = 'CAMPAIGN_HIDE_CREATE_FORM';

export const TOGGLE_CAMPAIGN_ACTIVE_REQUEST = 'TOGGLE_CAMPAIGN_ACTIVE_REQUEST';
export const TOGGLE_CAMPAIGN_ACTIVE_SUCCESS = 'TOGGLE_CAMPAIGN_ACTIVE_SUCCESS';
export const TOGGLE_CAMPAIGN_ACTIVE_FAILURE = 'TOGGLE_CAMPAIGN_ACTIVE_FAILURE';

export const ADD_CAMPAIGN_REQUEST = 'ADD_CAMPAIGN_REQUEST';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAILURE = 'ADD_CAMPAIGN_FAILURE';

export const UPDATE_CAMPAIGN_REQUEST = 'UPDATE_CAMPAIGN_REQUEST';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';

export const ADD_CAMPAIGN_ROLE_REQUEST = 'ADD_CAMPAIGN_ROLE_REQUEST';
export const ADD_CAMPAIGN_ROLE_SUCCESS = 'ADD_CAMPAIGN_ROLE_SUCCESS';
export const ADD_CAMPAIGN_ROLE_FAILURE = 'ADD_CAMPAIGN_ROLE_FAILURE';

export const TOGGLE_CAMPAIGN_FORM_DIALOG = 'TOGGLE_CAMPAIGN_FORM_DIALOG';
export const SET_CAMPAIGN_FORM_DIALOG_MESSAGE = 'SET_CAMPAIGN_FORM_DIALOG_MESSAGE';

export const SHOW_CAMPAIGN_SETTINGS_FORM = 'SHOW_CAMPAIGN_SETTINGS_FORM';
export const HIDE_CAMPAIGN_SETTINGS_FORM = 'HIDE_CAMPAIGN_SETTINGS_FORM';

export const CAMPAIGN_CONTENT_COMPLETED_REQUEST = 'CAMPAIGN_CONTENT_COMPLETED_REQUEST';
export const CAMPAIGN_CONTENT_COMPLETED_SUCCESS = 'CAMPAIGN_CONTENT_COMPLETED_SUCCESS';
export const CAMPAIGN_CONTENT_COMPLETED_FAILURE = 'CAMPAIGN_CONTENT_COMPLETED_FAILURE';

export const CAMPAIGN_SET_FLAGGED_AS_LATE_TIME = 'CAMPAIGN_SET_FLAGGED_AS_LATE_TIME';

export const CAMPAIGN_SET_PUBLISH_STATUS = 'CAMPAIGN_SET_PUBLISH_STATUS';

export const SET_FRAMESPECIFICATION_FILTER = 'SET_FRAMESPECIFICATION_FILTER';
export const CLEAR_FRAMESPECIFICATION_FILTER = 'CLEAR_FRAMESPECIFICATION_FILTER';

export const setActiveCampaignId = (campaignId = '') => ({
  type: CAMPAIGN_SET_ACTIVE_ID,
  campaignId,
});

export const fetchCampaignSuccess = (campaign, lastFetchAt) => ({
  type: CAMPAIGN_FETCH_SUCCESS,
  campaign,
  lastFetchAt,
});

export const fetchCampaign = (campaignId, extraOptions = {}) => (dispatch) => {
  dispatch({ type: CAMPAIGN_FETCH_REQUEST });
  dispatch(fetchSnoozedCampaign(campaignId));

  const options = {
    endpoint: `campaigns/${campaignId}`,
    includes: ['client', 'campaign-roles', 'business-units'],
  };

  mergeWith(options, extraOptions, mergeObjCustomizer);

  return Request.send(options)
    .then((response) => {
      const campaign = response.data.body.parsed;
      const lastFetchAt = moment(new Date(response.headers.date));
      dispatch(fetchCampaignSuccess(campaign, lastFetchAt));
      return Promise.resolve();
    })
    .then(() => {
      dispatch({ type: CAMPAIGN_FETCH_COMPLETE });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_FETCH_COMPLETE });
    });
};

export const mergeCampaignRelation = (campaignId, keyPath, data) => ({
  type: CAMPAIGN_RELATION_MERGE,
  campaignId,
  keyPath,
  data,
});

export const fetchCampaignRelations = (campaignId, relation, options = {}, keyPath = []) => async (dispatch) => {
  dispatch({ type: CAMPAIGN_RELATIONS_FETCH_REQUEST, relation });

  const { includes = [], pageNumber = null, pageSize = 15, sorts = [], filters = [] } = options;
  let { createdSince = null } = options;
  if (createdSince && !moment.isMoment(createdSince)) {
    createdSince = moment(createdSince);
  }

  if (moment.isMoment(createdSince)) {
    filters.push({ created_since: createdSince.unix() }); // eslint-disable-line camelcase
  }

  const fetchAllPages = pageNumber === null;

  try {
    const response = await Request.send({
      endpoint: `campaigns/${campaignId}/${relation}`,
      pagination: fetchAllPages ? Request.ALL_PAGES : { pageNumber, pageSize },
      filters,
      includes,
      sorts,
    });
    const items = fromJS(response.data.body.parsed);
    const lastFetchAt = response.headers.date && moment(new Date(response.headers.date));

    dispatch({ type: CAMPAIGN_RELATIONS_FETCH_SUCCESS, relation, items, lastFetchAt });

    const keyPathOfRelation = keyPath.length ? keyPath : [relation];
    dispatch(mergeCampaignRelation(campaignId, keyPathOfRelation, items));
    return response;
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    dispatch({ type: CAMPAIGN_RELATIONS_FETCH_COMPLETE, relation });
  }
};

export const fetchCampaignRelation = (campaignId, relation, relationId, includes = [], keyPath = []) => (dispatch) => {
  // If keyPath is provided, we need to use this when merging the relation
  // For example, a fetch request may be made to the creatives endpoint
  // but we want to store the model at ["frame-specifications", frameSpecificationIndex, "creatives"]
  const keyPathOrRelation = keyPath.length ? keyPath : [relation];

  dispatch({ type: CAMPAIGN_RELATION_FETCH_REQUEST, relation, relationId });

  const options = {
    endpoint: relation.concat('/', relationId),
    includes,
  };

  return Request.send(options)
    .then((response) => {
      const model = fromJS(response.data.body.parsed);

      dispatch({ type: CAMPAIGN_RELATION_FETCH_SUCCESS, relation, relationId });
      dispatch({ type: CAMPAIGN_RELATION_FETCH_COMPLETE, relation, relationId });
      dispatch(mergeCampaignRelation(campaignId, keyPathOrRelation, model));

      return model;
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_RELATION_FETCH_COMPLETE, relation, relationId });
    });
};

export const replaceCampaignRelation = (campaignId, keyPath, newItem, oldItem) => ({
  type: CAMPAIGN_RELATION_REPLACE,
  campaignId,
  keyPath,
  newItem,
  oldItem,
});

export const removeCampaignRelation = (campaignId, keyPath, item) => ({
  type: CAMPAIGN_RELATION_REMOVE,
  campaignId,
  keyPath,
  item,
});

export const syncCampaignRelation = (campaignId, keyPath, model, modelName, relations, relationName) => (dispatch) => {
  const ids = relations.map((i) => new Map({ id: i.get('id') })).toJS();

  dispatch({ type: CAMPAIGN_RELATIONS_SYNC_REQUEST, modelName, id: model.get('id'), relationName });

  return Request.send({
    endpoint: modelName.concat('/', model.get('id'), '/relationships/', relationName),
    method: 'PATCH',
    data: ids,
  })
    .then(() => {
      const modelWithRelations = model.set(relationName, fromJS(relations));

      dispatch(mergeCampaignRelation(campaignId, keyPath, modelWithRelations));

      dispatch({ type: CAMPAIGN_RELATIONS_SYNC_SUCCESS, modelName, id: modelWithRelations.get('id'), relationName });
      dispatch({ type: CAMPAIGN_RELATIONS_SYNC_COMPLETE, modelName, id: modelWithRelations.get('id'), relationName });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_RELATIONS_SYNC_COMPLETE, modelName, id: model.get('id'), relationName });
    });
};

export const deleteContentItems = (campaignId, contentItems) => (dispatch) => {
  dispatch({ type: DELETE_CAMPAIGN_CONTENT_ITEMS_REQUEST });

  return Request.send({ endpoint: 'content-items', method: 'DELETE', data: contentItems.toJS() })
    .then(() => {
      dispatch({ type: DELETE_CAMPAIGN_CONTENT_ITEMS_SUCCESS, campaignId, contentItems });
    })
    .then(() => {
      dispatch({ type: DELETE_CAMPAIGN_CONTENT_ITEMS_COMPLETE });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: DELETE_CAMPAIGN_CONTENT_ITEMS_COMPLETE });
      return Promise.reject(new Error('failed to delete content-items'));
    });
};

export const clearCampaign = () => ({
  type: CAMPAIGN_CLEAR,
});

export const clearCampaignRelation = (campaignId, keyPath) => ({
  type: CAMPAIGN_RELATION_CLEAR,
  campaignId,
  keyPath,
});

export const deleteRelationPrompt = (keyPath, item) => ({
  type: CAMPAIGN_RELATION_DELETE_PROMPT,
  keyPath,
  item,
});

export const deleteRelationRequest = (campaignId, endpoint, keyPath, item) => (dispatch) => {
  item = fromJS(item); // eslint-disable-line no-param-reassign

  dispatch({
    type: CAMPAIGN_RELATION_DELETE_REQUEST,
    keyPath,
    item,
  });
  return Request.send({ endpoint: `${endpoint}/${item.get('id')}`, method: 'DELETE' })
    .then(() => {
      dispatch(success('The item was deleted successfully'));
      dispatch(removeCampaignRelation(campaignId, keyPath, item));

      return Promise.resolve();
    })
    .then(() => {
      dispatch({
        type: CAMPAIGN_RELATION_DELETE_COMPLETE,
        campaignId,
        endpoint,
        keyPath,
        item,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({
        type: CAMPAIGN_RELATION_DELETE_COMPLETE,
        campaignId,
        endpoint,
        keyPath,
        item,
      });
      return Promise.reject(new Error('failed to delete content-item'));
    });
};

export const toggleCreateCampaignForm = () => ({
  type: CAMPAIGN_TOGGLE_CREATE_FORM,
});

export const showCreateCampaignForm = () => ({
  type: CAMPAIGN_SHOW_CREATE_FORM,
});

export const hideCreateCampaignForm = () => (dispatch) => {
  dispatch({
    type: CAMPAIGN_HIDE_CREATE_FORM,
  });
};

const prepareCampaignFormParameters = (values) => {
  // We need to subtract the timezone offset because the date picker isn't doing it
  const offset = new Date().getTimezoneOffset();
  return Object.keys(values).reduce((prev, item) => {
    const carry = prev;
    if (item === 'Brand' && values[item]) {
      carry.client = values[item];
    }
    if (item === 'StartDate' && values[item]) {
      carry.starts_at = moment(values[item]) // eslint-disable-line camelcase
        .startOf('day')
        .subtract(offset, 'minutes')
        .toDate();
    }
    if (item === 'EndDate' && values[item]) {
      carry.ends_at = moment(values[item]) // eslint-disable-line camelcase
        .endOf('day')
        .subtract(offset, 'minutes')
        .toDate();
    }
    if (item === 'UpdateFrequency' && values[item]) {
      carry.build_frequency = values[item]; // eslint-disable-line camelcase
    }
    if (item === 'Name' && values[item]) {
      carry.name = values[item];
    }
    if (item === 'BusinessUnit' && values[item]) {
      carry.business_units = [values[item]]; // eslint-disable-line camelcase
    }
    if (item === 'Bookings' && values[item]) {
      carry.bookings = values[item].map((booking) => ({
        external_id: booking['external-id'], // eslint-disable-line camelcase
        network: booking.network || ADNETWORKS.BROADSIGN,
      }));
    }
    if (item === 'Managers' && values[item] && Array.isArray(values[item])) {
      carry.managers = values[item].map((manager) => ({
        email: manager,
        role: 'manager',
      }));
    }
    if (item === 'RequiresWeatherData') {
      carry.requires_weather = values[item] || false;
    }
    return carry;
  }, {});
};

export const addCampaignRequest = (values) => {
  const data = prepareCampaignFormParameters(values);
  return (dispatch) => {
    dispatch({
      type: ADD_CAMPAIGN_REQUEST,
    });
    return Request.send({
      endpoint: 'campaigns',
      method: 'POST',
      data,
    })
      .then((response) => {
        dispatch(success('Campaign Created'));
        dispatch(hideCreateCampaignForm());
        dispatch({
          type: ADD_CAMPAIGN_SUCCESS,
        });
        const campaign = response.data.body.parsed;
        dispatch(fetchCampaigns());
        return Promise.resolve(campaign);
      })
      .catch((error) => {
        dispatch(notifyApiError(error));
        dispatch({
          type: ADD_CAMPAIGN_FAILURE,
        });

        return Promise.reject(error);
      });
  };
};

export const toggleCampaignActive = (value, campaignId) => (dispatch) => {
  dispatch({
    type: TOGGLE_CAMPAIGN_ACTIVE_REQUEST,
  });
  return Request.send({
    endpoint: `campaigns/${campaignId}`,
    method: 'PATCH',
    data: { is_active: value }, // eslint-disable-line camelcase
  })
    .then((response) => {
      const campaign = response.data.body.parsed;
      dispatch({
        type: TOGGLE_CAMPAIGN_ACTIVE_SUCCESS,
        campaign,
      });
    })
    .catch(() => {
      dispatch(warning('Unable to update Campaign'));
      dispatch({
        type: TOGGLE_CAMPAIGN_ACTIVE_FAILURE,
      });
    });
};

export const hideCampaignSettingsForm = () => (dispatch) => {
  dispatch({
    type: HIDE_CAMPAIGN_SETTINGS_FORM,
  });
};

export const updateCampaignRequest = (values, campaignId) => (dispatch) => {
  dispatch({
    type: UPDATE_CAMPAIGN_REQUEST,
  });
  return Request.send({
    endpoint: `campaigns/${campaignId}`,
    method: 'PATCH',
    data: prepareCampaignFormParameters(values),
  })
    .then((response) => {
      dispatch(hideCampaignSettingsForm());
      const campaign = response.data.body.parsed;
      dispatch({
        type: UPDATE_CAMPAIGN_SUCCESS,
        campaign,
      });
      dispatch(fetchCampaigns());
      return Promise.resolve(campaign);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({
        type: UPDATE_CAMPAIGN_FAILURE,
      });

      return Promise.reject(error);
    });
};

export const toggleCampaignFormDialog = () => ({
  type: TOGGLE_CAMPAIGN_FORM_DIALOG,
});

export const setCampaignFormDialogMessage = (message) => ({
  type: SET_CAMPAIGN_FORM_DIALOG_MESSAGE,
  message,
});

export const showCampaignSettingsForm = (campaignId) => ({
  type: SHOW_CAMPAIGN_SETTINGS_FORM,
  campaignId,
});

export const fetchCampaignFrames = (campaignId) => (dispatch) => {
  const includes = ['frames.specification', 'frames.location', 'frame-history-stats'];

  dispatch({ type: CAMPAIGN_FRAMES_FETCH_REQUEST });

  return Request.send({
    endpoint: 'campaigns/'.concat(campaignId.toString()),
    method: 'GET',
    includes,
  })
    .then((response) => {
      const campaign = response.data.body.parsed;
      dispatch({
        type: CAMPAIGN_FRAMES_FETCH_SUCCESS,
        campaign,
      });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_FRAMES_FETCH_FAILURE });
    });
};

export const attachCampaignFrames = (campaignId, frames = '') => (dispatch) => {
  dispatch({ type: CAMPAIGN_FRAMES_ATTACH_REQUEST, id: campaignId });

  return Request.send({
    endpoint: `campaigns/${campaignId}/relationships/frames`,
    method: 'POST',
    data: escapeNewLineItems(frames)
      .map((frameId) => ({
        id: frameId.replace(/^\s+|\s+$/g, ''),
      }))
      .toJS(),
  })
    .then((response) => {
      // success, just fetch the frames and frame specification then merge them
      dispatch(fetchCampaignFrames(campaignId));
      dispatch({ type: CAMPAIGN_FRAMES_ATTACH_SUCCESS });
      if (Array.isArray(get(response, 'data'))) {
        return Promise.resolve(get(response, 'data'));
      }
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_FRAMES_ATTACH_FAILURE });

      // Just reject with the error
      return Promise.reject(get(error, 'response.data.body.errors'));
    });
};

export const detachCampaignFrames = (campaignId, frames = '') => (dispatch) => {
  dispatch({ type: CAMPAIGN_FRAMES_DETACH_REQUEST, id: campaignId });

  return Request.send({
    endpoint: `campaigns/${campaignId}/relationships/frames`,
    method: 'DELETE',
    data: frames.split(',').map((frameId) => ({
      id: frameId.replace(/^\s+|\s+$/g, ''),
    })),
  })
    .then(() => {
      // success, just fetch the frames and frame specification then merge them
      dispatch(fetchCampaignFrames(campaignId));
      dispatch({ type: CAMPAIGN_FRAMES_DETACH_SUCCESS });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_FRAMES_DETACH_FAILURE });

      return Promise.reject(error);
    });
};

export const markContentCompleted = (campaignId) => (dispatch) => {
  dispatch({ type: CAMPAIGN_CONTENT_COMPLETED_REQUEST });

  return Request.send({
    endpoint: `campaigns/${campaignId}/content-completed`,
    method: 'PATCH',
  })
    .then((response) => {
      dispatch({
        type: CAMPAIGN_CONTENT_COMPLETED_SUCCESS,
        campaign: fromJS(response.data.body.parsed),
      });

      dispatch(success('A notification has been sent to Campaign Managers.'));

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_CONTENT_COMPLETED_FAILURE });
      return Promise.reject(error);
    });
};

const setCampaignFlaggedAsLateTime = (isMarkedAsLate) => ({
  type: CAMPAIGN_SET_FLAGGED_AS_LATE_TIME,
  payload: {
    isMarkedAsLate,
  },
});

export const markAsLate = (campaignId, campaignName) => async (dispatch) => {
  try {
    dispatch(setCampaignFlaggedAsLateTime(true));
    await Request.send({
      endpoint: `campaigns/${campaignId}/add-late-copy-flag`,
      method: 'PATCH',
    });
  } catch (error) {
    dispatch(setCampaignFlaggedAsLateTime(false));
    dispatch(error(`Unable to mark the campaign ${campaignName} as late`));
  }
};

export const setCampaignPublishStatus = (publishStatus) => ({
  type: CAMPAIGN_SET_PUBLISH_STATUS,
  payload: {
    publishStatus: publishStatus.publishing_status,
  },
});

export const setFrameSpecsFilter = (data, keyPath) => (dispatch) =>
  dispatch({
    type: SET_FRAMESPECIFICATION_FILTER,
    data,
    keyPath,
  });

export const clearFrameSpecsFilter = (keyPath) => (dispatch) =>
  dispatch({
    type: CLEAR_FRAMESPECIFICATION_FILTER,
    keyPath,
  });
