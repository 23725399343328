import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { ListItem } from 'react-toolbox/lib/list';
import Action from 'assets/components/presentational/Rows/Action';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Tooltip from 'components/patterns/Tooltip';

import style from './usersListItem.scss';

const TooltipSpan = Tooltip('span');
const TooltipIcon = Tooltip(Icon);

class UsersListItem extends Component {
  handleEditUserClick = () => {
    const { user, onEditUserClick } = this.props;

    onEditUserClick(user.get('id'));
  };

  handleDeleteUserClick = () => {
    const { user, onDeleteUserClick } = this.props;

    onDeleteUserClick(user);
  };

  handleRestoreUserClick = () => {
    const { user, onRestoreUserClick } = this.props;

    onRestoreUserClick(user);
  };

  handleConvertToFullUserClick = () => {
    const { user, onConvertToFullUserClick } = this.props;

    onConvertToFullUserClick(user.get('id'));
  };

  renderActions() {
    const { t, user, isFetchingUsers, allowDelete } = this.props;

    return (
      <span className={style.userActions}>
        {(user.get('deleted-at') || user.get('is-expired')) && allowDelete && (
          <Action
            disabled={isFetchingUsers}
            icon={<Icon iconType={IconTypes.RESTORE} />}
            tooltip={t('Restore User')}
            onClick={this.handleRestoreUserClick}
          />
        )}
        {!user.get('deleted-at') && allowDelete && (
          <Action
            disabled={isFetchingUsers}
            icon={<Icon iconType={IconTypes.DELETE} />}
            tooltip={t('Delete User')}
            onClick={this.handleDeleteUserClick}
          />
        )}
        {!user.get('deleted-at') && (
          <Action
            disabled={isFetchingUsers}
            icon={<Icon iconType={IconTypes.MODE_EDIT} />}
            tooltip={t('Edit User')}
            onClick={this.handleEditUserClick}
          />
        )}
        {!user.get('deleted-at') && user.get('is-lite-user') && allowDelete && (
          <Action
            disabled={isFetchingUsers}
            icon={<Icon iconType={IconTypes.PERSON_ADD} />}
            tooltip={t('Convert to Full User')}
            onClick={this.handleConvertToFullUserClick}
          />
        )}
      </span>
    );
  }

  renderListContent() {
    const { t, user } = this.props;
    return (
      <span className={style.rowContent}>
        <span className={style.indicators}>
          {user.get('is-expired') && !user.get('deleted-at') && (
            <TooltipSpan tooltip={t('This user has expired')} className={style.indicator}>
              <span>{t('exp')}</span>
            </TooltipSpan>
          )}
          {user.get('is-lite-user') && !user.get('deleted-at') && (
            <TooltipSpan tooltip={t('This is a lite user')} className={style.indicator}>
              <span>{t('lite')}</span>
            </TooltipSpan>
          )}
          {user.get('is-super-user') && !user.get('deleted-at') && (
            <TooltipIcon
              iconType={IconTypes.STAR}
              tooltip={t('This is a super user')}
              className={style.indicatorSuperUser}
            />
          )}
          {user.get('deleted-at') && (
            <TooltipIcon
              iconType={IconTypes.DELETE}
              tooltip={t('This user has been deleted')}
              className={style.indicatorIcon}
            />
          )}
          {!user.get('is-expired') &&
            !user.get('is-lite-user') &&
            !user.get('is-super-user') &&
            !user.get('deleted-at') && (
              <TooltipIcon
                iconType={IconTypes.DONE}
                tooltip={t('This is a full user')}
                className={style.indicatorIcon}
              />
            )}
        </span>
        <span className={style.userName}>{user.get('name')}</span>
        <span className={style.userEmail}>{user.get('email')}</span>
        {this.renderActions()}
      </span>
    );
  }

  render() {
    return <ListItem ripple={false} className={style.row} itemContent={this.renderListContent()} />;
  }
}

export default UsersListItem;

UsersListItem.propTypes = {
  allowDelete: PropTypes.bool,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  onEditUserClick: PropTypes.func.isRequired,
  onDeleteUserClick: PropTypes.func.isRequired,
  onRestoreUserClick: PropTypes.func.isRequired,
  onConvertToFullUserClick: PropTypes.func.isRequired,
  isFetchingUsers: PropTypes.bool,
};

UsersListItem.defaultProps = {
  allowDelete: false,
};
