import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchCampaigns } from 'store/dashboardNew/actions';

import Campaigns from './Campaigns';

const mapStateToProps = (state) => ({
  totalPages: state.dashboard.totalPages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCampaigns,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campaigns));
