import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';
import { debounce } from 'lodash';
import queryString from 'query-string';

import { redirect, getURLQueryString } from 'modules/Helpers';

import {
  fetchUsers,
  deleteUser,
  restoreUser,
  setUsersFilterSearchValue,
  setUsersFilterUserTypeValue,
  clearUsersState,
} from 'actions/users';
import { success, error } from 'actions/notify';

import Heading from 'assets/components/presentational/Heading';
import Button from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import { ColumnContainer } from 'assets/components/presentational/Layout';
import ProgressBar from 'components/patterns/ProgressBar';
import Pagination from 'assets/components/presentational/Pagination';
import UsersList from 'assets/components/presentational/Users/UsersList';
import Dialog from 'assets/components/presentational/Dialog';
import Input from 'components/patterns/Input';
import Dropdown from 'assets/components/presentational/Dropdown';

import style from './users.scss';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteUserDialog: false,
      deletedUser: '',
      showRestoreUserDialog: false,
      restoredUser: '',
    };

    this.fetchUsers = debounce(this.fetchUsers, 400);
  }

  componentDidMount() {
    const {
      dispatch,
      location: {
        query: { filterUserType, filterSearch },
      },
    } = this.props;

    dispatch(setUsersFilterUserTypeValue(filterUserType));
    dispatch(setUsersFilterSearchValue(filterSearch));
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      location: {
        query: { filterUserType, filterSearch },
      },
    } = this.props;

    if (this.pageNumberHasChanged(prevProps)) {
      window.scrollTo(0, 0);
      this.fetchUsers();
    }

    if (this.filterHasChanged(prevProps)) {
      this.fetchUsers();
      dispatch(setUsersFilterUserTypeValue(filterUserType));
      dispatch(setUsersFilterSearchValue(filterSearch));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearUsersState());
  }

  fetchUsers = () => {
    const {
      dispatch,
      params: { pageNumber },
      location: {
        query: { filterUserType, filterSearch },
      },
    } = this.props;

    dispatch(fetchUsers(pageNumber, filterUserType, filterSearch));
  };

  getPageNumber = () => {
    let {
      params: { pageNumber },
    } = this.props;
    pageNumber = parseInt(pageNumber);

    return isNaN(pageNumber) ? 1 : pageNumber;
  };

  updatePageNumber = (pageNumber) => {
    const qs = queryString.parse(window.location.search);
    const queryStringParsed = getURLQueryString({ ...qs });
    const shouldReplaceHistory = true;

    return queryStringParsed
      ? redirect(`/users/${pageNumber}${queryStringParsed}`, shouldReplaceHistory)
      : redirect(`/users/${pageNumber}`);
  };

  pageNumberHasChanged = (prevProps) => {
    const {
      params: { pageNumber: prevPageNumber },
    } = prevProps;
    const {
      params: { pageNumber },
    } = this.props;

    const pageNumberHasChanged = prevPageNumber !== pageNumber;
    const pageNumberIsNumber = !Number.isNaN(parseInt(prevPageNumber));

    return pageNumberHasChanged && prevPageNumber && pageNumber && pageNumberIsNumber;
  };

  filterHasChanged = (prevProps) => {
    const {
      location: {
        query: { filterUserType: prevFilterUserType, filterSearch: prevFilterSearch },
      },
    } = prevProps;
    const {
      location: {
        query: { filterUserType, filterSearch },
      },
    } = this.props;

    return prevFilterUserType !== filterUserType || prevFilterSearch !== filterSearch;
  };

  handleAddUserButtonClick() {
    return redirect('/users/create');
  }

  handleFilterUserTypeChange = (value) => {
    const {
      params: { pageNumber },
    } = this.props;

    const queryString = getURLQueryString({ filterUserType: value });
    const shouldReplaceHistory = true;

    return redirect(`/users/${pageNumber}${queryString}`, shouldReplaceHistory);
  };

  handleFilterSearchChange = (value) => {
    const queryString = getURLQueryString({ filterSearch: value });
    const shouldReplaceHistory = true;

    return redirect(`/users/1${queryString}`, shouldReplaceHistory);
  };

  handlePaginationClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;

    this.updatePageNumber(pageNumber);
  };

  handleHideDeleteDialog = () => {
    this.setState({
      showDeleteUserDialog: false,
    });
  };

  handleHideRestoreDialog = () => {
    this.setState({
      showRestoreUserDialog: false,
    });
  };

  handleEditUserClick = (userId) => {
    redirect(`/users/${userId}/edit`);
  };

  handleDeleteUserClick = (user) => {
    this.setState({
      showDeleteUserDialog: true,
      deletedUser: user,
    });
  };

  handleRestoreUserClick = (user) => {
    this.setState({
      showRestoreUserDialog: true,
      restoredUser: user,
    });
  };

  handleConvertToFullUserClick = (userId) => {
    redirect(`/users/${userId}/edit/lite-to-full`);
  };

  handleDeleteUser = () => {
    const { t, dispatch } = this.props;
    const { deletedUser } = this.state;
    const userName = deletedUser ? deletedUser.get('name') : ' ';

    dispatch(deleteUser(deletedUser.get('id')))
      .then(() => {
        this.setState({ deletedUser: '' });
        dispatch(success(t('The user: {{userName}}, has been deleted.', { userName })));
      })
      .catch(() => {
        this.setState({ deletedUser: '' });
        dispatch(error(t('The user: {{userName}}, could not be deleted.', { userName })));
      });

    this.setState({ showDeleteUserDialog: false });
  };

  handleRestoreUser = () => {
    const { t, dispatch } = this.props;
    const { restoredUser } = this.state;
    const userName = restoredUser ? restoredUser.get('name') : ' ';

    dispatch(restoreUser(restoredUser.get('id')))
      .then(() => {
        this.setState({ restoredUser: '' });
        dispatch(success(t('The user: {{userName}}, has been restored.', { userName })));
      })
      .catch(() => {
        this.setState({ restoredUser: '' });
        dispatch(error(t('The user: {{userName}}, could not be restored.', { userName })));
      });

    this.setState({
      showRestoreUserDialog: false,
    });
  };

  renderToolbar = () => {
    const { t, filterUserTypeValue, filterSearchValue, isFetchingUsers, authUser, hasFetchedUsers } = this.props;

    const filterOptions = [
      { value: '', label: 'Active users' },
      { value: 'only_trashed', label: t('Deleted users') },
      { value: 'is_lite_user', label: t('Lite users') },
      { value: 'is_expired', label: t('Expired users') },
    ];

    return (
      <div className={style.toolbar}>
        <div className={style.filterUserType}>
          <Dropdown
            label={t('Filter by: ')}
            source={filterOptions}
            value={filterUserTypeValue}
            onChange={this.handleFilterUserTypeChange}
            disabled={!hasFetchedUsers}
          />
        </div>
        <div className={style.filterSearch}>
          <Input
            label={t('Filter by user name or email')}
            type="text"
            value={filterSearchValue}
            onChange={this.handleFilterSearchChange}
            disabled={!hasFetchedUsers}
          />
        </div>
        <div className={style.addUserBtn}>
          {authUser.get('is-super-user') ? (
            <Button
              onClick={this.handleAddUserButtonClick}
              icon={<Icon iconType={IconTypes.ADD} />}
              label={t('Add User')}
              disabled={isFetchingUsers}
            />
          ) : null}
        </div>
      </div>
    );
  };

  renderList() {
    const { authUser, t, users, isFetchingUsers, hasFetchedUsers } = this.props;
    return (
      <div>
        <div className={style.fetching}>{(!hasFetchedUsers || isFetchingUsers) && <ProgressBar />}</div>
        {hasFetchedUsers && (
          <UsersList
            allowDelete={Boolean(authUser.get('is-super-user', false))}
            t={t}
            users={users}
            hasFetchedUsers={hasFetchedUsers}
            isFetchingUsers={isFetchingUsers}
            handleEditUserClick={this.handleEditUserClick}
            handleDeleteUserClick={this.handleDeleteUserClick}
            handleRestoreUserClick={this.handleRestoreUserClick}
            handleConvertToFullUserClick={this.handleConvertToFullUserClick}
          />
        )}
      </div>
    );
  }

  renderPagination = () => {
    const { isFetchingUsers, pagination } = this.props;
    const pageNumber = this.getPageNumber();
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <div>
        {pagination.get('total_pages') > 1 ? (
          <Pagination
            forceSelected={pageNumber - 1}
            hidden={isFetchingUsers}
            initialSelected={initialSelected}
            onClick={this.handlePaginationClick}
            pageNum={pagination.get('total_pages')}
          />
        ) : null}
      </div>
    );
  };

  renderDeleteDialog() {
    const { t } = this.props;
    const { showDeleteUserDialog, deletedUser } = this.state;
    const userName = deletedUser ? deletedUser.get('name') : ' ';

    const actions = [
      { label: t('Cancel'), onClick: this.handleHideDeleteDialog },
      { label: t('Yes'), onClick: this.handleDeleteUser },
    ];

    return (
      <Dialog active={showDeleteUserDialog} actions={actions} type="small">
        <p>{t('Are you sure you want to delete the user: {{userName}}?', { userName })}</p>
      </Dialog>
    );
  }

  renderRestoreDialog() {
    const { t } = this.props;
    const { showRestoreUserDialog, restoredUser } = this.state;
    const userName = restoredUser ? restoredUser.get('name') : ' ';

    const actions = [
      { label: t('Cancel'), onClick: this.handleHideRestoreDialog },
      { label: t('Yes'), onClick: this.handleRestoreUser },
    ];

    return (
      <Dialog active={showRestoreUserDialog} actions={actions} type="small">
        <p>{t('Are you sure you want to restore the user: {{userName}}?', { userName })}</p>
      </Dialog>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <ColumnContainer>
        <Heading className={style.heading} tag="H1" size="largest">
          {t('Users')}
        </Heading>
        <div className={style.pageContainer}>
          {this.renderToolbar()}
          {this.renderList()}
          {this.renderPagination()}
          {this.renderDeleteDialog()}
          {this.renderRestoreDialog()}
        </div>
      </ColumnContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  users: state.users.users,
  isFetchingUsers: state.users.isFetchingUsers,
  hasFetchedUsers: state.users.hasFetchedUsers,
  filterUserTypeValue: state.users.filterUserTypeValue,
  filterSearchValue: state.users.filterSearchValue,
  pagination: state.users.pagination,
});

export default withNamespaces(['common', 'users'], { wait: true })(connect(mapStateToProps)(Users));

Users.propTypes = {
  authUser: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      filterUserType: PropTypes.string,
      filterSearch: PropTypes.string,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({
    pageNumber: PropTypes.string,
  }).isRequired,
  users: PropTypes.instanceOf(List).isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  hasFetchedUsers: PropTypes.bool.isRequired,
  filterUserTypeValue: PropTypes.string.isRequired,
  filterSearchValue: PropTypes.string.isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
};
