import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { isFunction } from 'lodash';
import moment from 'moment';

import ProgressBar from 'components/patterns/ProgressBar';
import Drawer from 'assets/components/presentational/Drawer';
import Table from 'assets/components/presentational/Table';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import style from './assetHistoryDrawer.scss';

class AssetHistoryDrawer extends Component {
  handleClose = (e) => {
    const { onClose } = this.props;

    if (isFunction(onClose)) {
      return onClose(e);
    }
  };

  renderTitle() {
    const { t, title } = this.props;

    return (
      <div>
        <Heading className={style.assetHistoryHeading} tag={HeadingTags.H3} size={HeadingSizes.LARGE}>
          {t('Asset History')}
        </Heading>
        <h3 className={style.title}>{title}</h3>
      </div>
    );
  }

  renderProgress() {
    const { isFetching } = this.props;

    if (isFetching) {
      return (
        <span className={style.progress}>
          <ProgressBar circular />
        </span>
      );
    }
  }

  renderTable() {
    const { isFetching, creative, t } = this.props;

    if (isFetching || !(creative.get('asset') instanceof Map)) {
      return null;
    }

    const assetHistory = creative.get('asset-history', new List());
    if (!assetHistory.count()) {
      return (
        <p className={style.notFound}>
          {t(
            'The asset history for this creative is not currently available. If this creative has been recently replaced, it may take a few minutes before the history is generated.',
          )}
        </p>
      );
    }

    const data = assetHistory
      .sortBy((a) => a.get('created-at'))
      .reverse()
      .map((asset) => {
        const url = (
          <a href={asset.get('url')} target="_blank" rel="noopener noreferrer">
            [{t('show')}]
          </a>
        );
        return {
          changedDate: moment(asset.get('created-at')).format('DD MMM YYYY h:mm:ss a'),
          changedBy: asset.getIn(['user', 'name'], '-'),
          originalFileName: asset.get('original-filename') || '-',
          mime: asset.get('mime'),
          dimensions: `${asset.get('width')} x ${asset.get('height')}`,
          url,
        };
      })
      .toJS();

    const model = {
      changedDate: { type: String, title: 'Upload Date (UTC)' },
      changedBy: { type: String, title: 'Uploaded By' },
      originalFileName: { type: String, title: 'Original File Name' },
      dimensions: { type: String, title: 'Dimensions' },
      mime: { type: String, title: 'Type' },
      url: { type: String, title: 'Link' },
    };

    return <Table className={style.assetHistoryTable} model={model} source={data} />;
  }

  render() {
    const { active, t } = this.props;

    return (
      <Drawer active={active} className={style.component} onOverlayClick={this.handleClose}>
        {this.renderTitle()}
        {this.renderProgress()}
        {this.renderTable()}

        <Button
          className={style.assetHistoryCloseButton}
          label={t('Close')}
          onClick={this.handleClose}
          theme={ButtonThemes.WHITE}
          disabled={!active}
          neutral
        />
      </Drawer>
    );
  }
}

export default withNamespaces(['common', 'creative'], { wait: false })(AssetHistoryDrawer);

AssetHistoryDrawer.propTypes = {
  active: PropTypes.bool,
  authenticatedUserId: PropTypes.string,
  isFetching: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func,
  title: PropTypes.string,
  creative: PropTypes.instanceOf(Map),
};

AssetHistoryDrawer.defaultProps = {
  active: false,
  authenticatedUserId: '',
  isFetching: false,
  title: 'Asset History',
};
