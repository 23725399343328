import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces } from 'react-i18next';

import { setActiveSection } from 'actions/campaign/navigation';
import { clearPreview, fetchCreatives, setActiveCreativeId } from 'actions/preview';
import { getFrameSpecifications, getRuleSets } from 'selectors/campaign';

import Preview from './Preview';

const mapStateToProps = (state) => ({
  activeCampaignId: state.campaign.activeCampaignId,
  activeCreativeId: state.preview.activeCreativeId,
  activeFrameSpecificationId: state.preview.activeFrameSpecificationId,
  activeRuleSetIds: state.preview.activeRuleSetIds,
  activeContentItemIds: state.preview.contentItemIds,
  activeContent: state.preview.activeContent,
  creatives: state.preview.creatives,
  frameSpecifications: getFrameSpecifications(state.campaign.campaign),
  isFetchingCampaign: state.campaign.isFetching,
  isFetchingCreatives: state.preview.isFetchingCreatives,
  isFetchingFrameSpecifications: state.preview.isFetchingFrameSpecifications,
  ruleSets: getRuleSets(state),
  campaign: state.campaign.campaign,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveSection,
      clearPreview,
      setActiveCreativeId,
      fetchCreatives,
    },
    dispatch,
  );

export default withNamespaces(['common', 'preview'], { wait: false })(
  connect(mapStateToProps, mapDispatchToProps)(Preview),
);
