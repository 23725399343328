import React from 'react';
import PropTypes from 'prop-types';
import style from './fixedHeadings.scss';

const PermissionsBusinessUnitsFixedHeadings = ({ headings }) => (
  <div className={style.row}>
    <span className={style.rowContent}>
      <span className={style.itemName} />
      {headings.map((item) => (
        <span key={item}>
          <p>{item}</p>
        </span>
      ))}
    </span>
  </div>
);

export default PermissionsBusinessUnitsFixedHeadings;

PermissionsBusinessUnitsFixedHeadings.propTypes = {
  headings: PropTypes.array,
};
