import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { fromJS, List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { v4 } from 'uuid';
import { withRouter } from 'react-router';
import Scroll from 'react-scroll';

import { triggerBodyClickToClose } from 'modules/Helpers';
import { NotificationTypes } from 'actions/snackbar';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import Heading from 'assets/components/presentational/Heading';
import Button from 'assets/components/presentational/Button';
import Icon, { IconTypes, IconSizes } from 'assets/components/presentational/Icon';
import ProgressBar from 'components/patterns/ProgressBar';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative';
import ConfirmationDialog from 'assets/components/containers/ConfirmationDialog';
import Dropdown from 'assets/components/presentational/Dropdown';

import Stage from './Stage';

import { mapStateToProps, mapDispatchToProps } from './Management.connected';

import style from './management.scss';

class CreativeModerationManagement extends Component {
  componentDidMount() {
    const {
      params: { campaignId },
      route,
      router,
      fetchModerationStages,
      fetchCampaignsWithStages,
    } = this.props;

    fetchModerationStages(campaignId);
    fetchCampaignsWithStages();

    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentDidUpdate() {
    const { shouldScrollToStage, resetScrollToStage } = this.props;

    if (shouldScrollToStage !== '') {
      this.scrollToStage(shouldScrollToStage);
      resetScrollToStage();
    }
  }

  routerWillLeave = (nextLocation) => {
    const { hasUnsavedChanges, showDialog, triggerConfirmationDialog } = this.props;

    if (hasUnsavedChanges) {
      triggerConfirmationDialog(nextLocation);
    }

    if (!showDialog && hasUnsavedChanges) {
      return false;
    }
  };

  handleResendInvites = (stage) => {
    const {
      params: { campaignId },
      postSendNewInvites,
    } = this.props;

    postSendNewInvites(campaignId, stage.get('id'));
  };

  getNewStage = () => {
    const { stages } = this.props;
    const order = stages.size
      ? stages
          .sortBy((stage) => stage.get('sort-order', 0))
          .reverse()
          .first()
          .get('sort-order', 0)
      : 0;

    return fromJS({
      id: v4(),
      'sort-order': order + 1,
      'moderation-groups': [this.getNewGroup()],
      isNew: true,
    });
  };

  getNewGroup = () =>
    fromJS({
      id: v4(),
      name: `New group`,
      users: new List(),
      'require-all': true,
      'require-n': null,
    });

  handleNewStageClick = () => {
    const { addNewStage } = this.props;

    addNewStage(this.getNewStage());
    Scroll.animateScroll.scrollToBottom();
  };

  handleUserChange = (stage, group, user, email) => {
    const { updateUser } = this.props;

    updateUser(stage.get('id'), group.get('id'), user.get('id'), email);
  };

  handleModerationRuleChange = (stage, group, requireAll) => {
    const { updateModerationRule } = this.props;

    updateModerationRule(stage.get('id'), group.get('id'), requireAll);
  };

  handleModerationNumberChange = (stage, group, requireN) => {
    const { updateModerationNumber } = this.props;

    updateModerationNumber(stage.get('id'), group.get('id'), requireN);
  };

  handleGroupAdded = (stage) => {
    const { addNewGroup } = this.props;

    addNewGroup(stage.get('id'), this.getNewGroup());
  };

  handleDeleteStage = (stage) => {
    const { deleteStage } = this.props;

    deleteStage(stage.get('id'));
  };

  handleDeleteGroup = (group, stage) => {
    const { deleteGroup } = this.props;

    deleteGroup(stage.get('id'), group.get('id'));
  };

  handleGroupNameChange = (stage, group, name) => {
    const { updateGroupName } = this.props;

    updateGroupName(stage.get('id'), group.get('id'), name);
  };

  handleMoveStage = (fromPos, toPos) => {
    const { moveStage } = this.props;

    moveStage(fromPos, toPos);
  };

  handleSaveChanges = () => {
    const {
      t,
      patchModerationStages,
      notify,
      params: { campaignId },
    } = this.props;
    const { stages } = this.props;

    const numErrors = this.validateData();

    if (numErrors < 1) {
      const onSuccess = () => notify(NotificationTypes.SUCCESS, t('Changes have been saved successfully'));
      const onError = (error) => {
        const { translationKey, email } = error;
        notify(NotificationTypes.ERROR, email ? t(translationKey, { email }) : t(translationKey));
      };

      patchModerationStages(campaignId, this.parseDataForSaving(stages).toJS(), onSuccess, onError);
    } else {
      const copy = numErrors === 1 ? t('1 validation error') : t('X validation errors', { count: numErrors });
      notify(NotificationTypes.ERROR, copy);
    }
  };

  parseDataForSaving = (stages) =>
    // removes the isNew key added to any new stages before they are saved
    stages.map((stage) => stage.delete('isNew'));

  scrollToStage = (toPos) => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const distance = ReactDOM.findDOMNode(this)
      .querySelector(`[name="stage${toPos}"]`)
      .getBoundingClientRect().top;
    const offset = 60;

    Scroll.animateScroll.scrollTo(top + distance - offset);
  };

  validateData = () => {
    const { stages, updateValidationData } = this.props;
    let numErrors = 0;
    const validationData = stages.map((s) =>
      s.updateIn(['moderation-groups'], List, (groups) =>
        groups.map((group) => {
          const errors = this.validateGroup(group);
          numErrors += errors.size;

          return new Map(
            Object.assign(
              {},
              {
                id: group.get('id'),
                errors,
              },
            ),
          );
        }),
      ),
    );

    updateValidationData(validationData);

    return numErrors;
  };

  validateGroup = (group) => {
    const { t } = this.props;
    let errors = new Map();

    // validate name
    const name = group.get('name');
    if (!name || name.length < 1) {
      errors = errors.set('name', t('Name field should not be empty'));
    }

    // validate requireN input
    const requireAll = group.get('require-all');
    const requireN = group.get('require-n');
    const users = group.get('users');

    if (!requireAll && (!requireN || requireN < 1)) {
      errors = errors.set('require-n', t('There must be at least 1 moderator'));
    }

    // validate users
    if (!users || users.size < 1) {
      errors = errors.set('users', t('There must be at least 1 user'));
    }

    if (requireN && users.size < requireN) {
      errors = errors.set(
        'users',
        t('The number of users provided must be greater than or equal to the required number for the group.'),
      );
    }

    return errors;
  };

  renderStages() {
    const { stages, isLoading, validationData, dirtyStages, isResendingInvites } = this.props;

    if (isLoading) {
      return <ProgressBar />;
    }
    return stages
      .sortBy((stage) => stage.get('sort-order'))
      .map((stage, index) => {
        const stageValidationData = validationData.find((row) => row.get('id') === stage.get('id')) || new Map();
        return (
          <div key={stage.get('id')}>
            <Stage
              stage={stage}
              position={index}
              isFirstStage={index === 0}
              isLastStage={index + 1 === stages.size}
              onGroupAdded={this.handleGroupAdded}
              onUserChange={(group, user, email) => this.handleUserChange(stage, group, user, email)}
              onDeleteStage={this.handleDeleteStage}
              onDeleteGroup={this.handleDeleteGroup}
              onStageMove={this.handleMoveStage}
              onGroupNameChange={this.handleGroupNameChange}
              onModerationRuleChange={(group, requireAll) => this.handleModerationRuleChange(stage, group, requireAll)}
              onModerationNumberChange={(group, requireN) => this.handleModerationNumberChange(stage, group, requireN)}
              onResendInvites={this.handleResendInvites}
              validate={this.validateData}
              validationData={stageValidationData}
              isDirty={dirtyStages.get(stage.get('id'), false)}
              isResendingInvites={isResendingInvites.get(stage.get('id'), false)}
            />
            {this.renderArrow(index)}
          </div>
        );
      });
  }

  renderDefaultStage() {
    const { t } = this.props;
    return (
      <div>
        <Heading size="small" className={style.header}>
          <Icon iconType={IconTypes.VERIFIED_USER} />
          Stage 0
        </Heading>
        <div className={style.defaultStage}>
          {t(
            'Stage 0 is the default moderation team for your campaigns. This includes Moderation Agents and Campaign Moderators',
          )}
        </div>
      </div>
    );
  }

  renderArrow(index) {
    const { stages } = this.props;

    if (index < stages.size - 1) {
      return (
        <span className={style.arrowIconContainer}>
          <Icon iconType={IconTypes.ARROW_DOWNWARD} size={IconSizes.LARGE} />
        </span>
      );
    }

    return null;
  }

  parseCampaignsForDropdown() {
    const { campaigns } = this.props;
    return campaigns
      .map(
        (campaign) =>
          new Map({
            value: campaign.get('id'),
            label: campaign.get('display-name'),
          }),
      )
      .toJS();
  }

  handleCampaignChange(campaignId) {
    const { campaigns, replaceStagesByCampaign } = this.props;
    const selectedCampaign = campaigns.find((campaign) => campaign.get('id') === campaignId);
    replaceStagesByCampaign(selectedCampaign);
  }

  renderCampaignStagesDropdown() {
    const { t, isFetchingCampaigns } = this.props;
    const campaignStages = this.parseCampaignsForDropdown();

    if (campaignStages.length) {
      return (
        <Dropdown
          allowBlank
          auto={false}
          className={style.campaignsDropdown}
          disabled={isFetchingCampaigns}
          label={t('Copy stages from another campaign')}
          onChange={(campaignId) => this.handleCampaignChange(campaignId)}
          onBlur={triggerBodyClickToClose}
          source={campaignStages}
          value=""
        />
      );
    }

    return null;
  }

  render() {
    const {
      isSaving,
      params: { campaignId },
      t,
      user,
      hasUnsavedChanges,
    } = this.props;
    const savingBar = isSaving ? <ProgressBar /> : null;
    const saveBtnLabel = isSaving ? t('Saving...') : t('Save Changes');

    return (
      <div className={style.component}>
        <HeadingGroup
          intro={t(
            'Enhanced Creative Moderation allows permitted users to build a moderation workflow on a campaign by campaign basis. Creative decisions are made at a point in time, therefore any changes made here will only affect creatives not yet moderated. Stage users will be notified when the first creative is available to moderate, and there is the facility below to resend invite notifications.',
          )}
          subNavigation={
            <SubNavigation active={SubNavigationLabels.ENHANCED_MODERATION} campaignId={campaignId} user={user} />
          }
          title={t('Creative')}
        />
        {this.renderDefaultStage()}
        {this.renderCampaignStagesDropdown()}
        <span className={style.buttonContainer}>
          <Button
            className={style.button}
            icon={<Icon iconType={IconTypes.ADD} />}
            label={t('Add New Stage')}
            onClick={this.handleNewStageClick}
            theme="white"
          />
          <Button
            className={style.button}
            onClick={this.handleSaveChanges}
            label={saveBtnLabel}
            disabled={isSaving || !hasUnsavedChanges}
          />
        </span>

        {savingBar}

        {this.renderStages()}
        <ConfirmationDialog message={t('You have unsaved changes. Are you sure you want to leave the page?')} />
      </div>
    );
  }
}

CreativeModerationManagement.propTypes = {
  campaigns: PropTypes.instanceOf(List).isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  isFetchingCampaigns: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    setRouteLeaveHook: PropTypes.func.isRequired,
  }).isRequired,
  shouldScrollToStage: PropTypes.string.isRequired,
  showDialog: PropTypes.bool.isRequired,
  stages: PropTypes.instanceOf(List).isRequired,
  dirtyStages: PropTypes.instanceOf(Map).isRequired,
  isResendingInvites: PropTypes.instanceOf(Map).isRequired,
  validationData: PropTypes.instanceOf(List).isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  notify: PropTypes.func.isRequired,
  fetchCampaignsWithStages: PropTypes.func.isRequired,
  fetchModerationStages: PropTypes.func.isRequired,
  patchModerationStages: PropTypes.func.isRequired,
  addNewStage: PropTypes.func.isRequired,
  addNewGroup: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateModerationRule: PropTypes.func.isRequired,
  updateModerationNumber: PropTypes.func.isRequired,
  updateGroupName: PropTypes.func.isRequired,
  updateValidationData: PropTypes.func.isRequired,
  deleteStage: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  moveStage: PropTypes.func.isRequired,
  replaceStagesByCampaign: PropTypes.func.isRequired,
  resetScrollToStage: PropTypes.func.isRequired,
  postSendNewInvites: PropTypes.func.isRequired,
  triggerConfirmationDialog: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
};

export default withNamespaces(['common', 'enhancedModeration', 'errorCodes'], { wait: false })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreativeModerationManagement)),
);
