import React from 'react';
import { PublicationPropTypes } from 'store/publications/propTypes';
import { toMonthDateYearTime } from 'modules/Helpers/dates';

const PublishDetails = ({ publication }) => (
  <div>
    <p>
      <strong>on</strong> {toMonthDateYearTime(publication['created-at'])}
    </p>
    <p>
      <strong>by</strong> {publication['published-by'].name}
    </p>
  </div>
);

PublishDetails.propTypes = {
  publication: PublicationPropTypes.isRequired,
};

export default PublishDetails;
