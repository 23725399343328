import React from 'react';
import PropTypes from 'prop-types';

import styles from './LazyRender.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.node,
  wait: PropTypes.number,
  onLoad: PropTypes.func,
};

const defaultProps = {
  placeholder: null,
  wait: 0,
  onLoad: () => {},
};

class LazyRender extends React.Component {
  constructor() {
    super();
    this.sentinel = null;
    this.observer = null;
    this.timeoutRef = null;
  }

  state = {
    enteredScreen: false,
  };

  componentDidUpdate(_, prevState) {
    const { onLoad } = this.props;
    const { enteredScreen } = this.state;

    if (!prevState.enteredScreen && enteredScreen) {
      onLoad();
    }
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onEnterScreen, {
      root: null,
      rootMargin: '0px',
      threshold: [0],
    });
    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    if (this.sentinel) {
      this.observer.unobserve(this.sentinel);
    }
    clearTimeout(this.timeoutRef);
  }

  onEnterScreen = (entries, self) => {
    for (const entry of entries) {
      const { wait } = this.props;
      const { enteredScreen } = this.state;
      if (!enteredScreen) {
        if (entry.isIntersecting) {
          this.timeoutRef = setTimeout(() => {
            self.unobserve(entry.target);
            this.setState({
              enteredScreen: true,
            });
          }, wait);
        } else {
          clearTimeout(this.timeoutRef);
        }
      }
    }
  };

  setRef = (element) => {
    this.sentinel = element;
  };

  render() {
    const { enteredScreen } = this.state;
    const { children, placeholder } = this.props;

    if (!enteredScreen) {
      return (
        <div ref={this.setRef} className={styles.container}>
          {placeholder}
        </div>
      );
    }

    return children;
  }
}

LazyRender.propTypes = propTypes;
LazyRender.defaultProps = defaultProps;

export default LazyRender;
