import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import style from './row.scss';

const Row = (props) => {
  const { className, children } = props;

  const classNames = `${className} ${style.row}`;

  // we need extra props to enable tooltip props to be added with a HOC if needed, but we don't want the below:
  const rest = omit(props, ['theme', 'tooltip', 'tooltipDelay', 'tooltipHideOnClick', 'className', 'tagTheme', 'copy']);

  return (
    <span className={classNames} {...rest}>
      {children}
    </span>
  );
};

export default Row;

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Row.defaultProps = {
  className: '',
  children: null,
};
