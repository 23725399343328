import React from 'react';

import Sidebar from './Sidebar';
import Campaigns from './Campaigns';

import style from './DashboardNew.scss';

const DashboardNew = () => {
  return (
    <section className={style.component}>
      <Sidebar />
      <main className={style.content}>
        <Campaigns />
      </main>
    </section>
  );
};

DashboardNew.propTypes = {};

export default DashboardNew;
