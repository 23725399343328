const getFieldFrom = (pointer) => {
  const parts = pointer.split('/');
  return !parts ? pointer : parts[parts.length - 1];
};

const transform = (data, acc) => {
  const errors = acc;

  data.errors.forEach((error) => {
    const {
      detail,
      source: { pointer },
    } = error;
    const field = getFieldFrom(pointer);

    if (Array.isArray(acc)) {
      errors.push({ [field]: detail });
    } else {
      errors[field] = detail;
    }
  });

  return errors;
};

const hasErrors = (data) => typeof data === 'object' && data.errors;
const hasSource = (data) => !!data.errors[0].source;
const isOnlyError = (data) => data.errors.length === 1;

export const getErrors = (data) => {
  if (hasErrors(data)) {
    if (hasSource(data)) {
      return transform(data, []);
    }

    if (isOnlyError(data)) {
      return [[data.errors[0].detail]];
    }
  }

  return [];
};

export const getFormErrors = (data) => {
  if (hasErrors(data)) {
    if (hasSource(data)) {
      return transform(data, {});
    }

    if (isOnlyError(data)) {
      return { '0': [data.errors[0].detail] };
    }
  }

  return {};
};

export const getCode = (data) => (hasErrors(data) && isOnlyError(data) ? data.errors[0].code : '');
