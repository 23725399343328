import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import cx from 'classnames';

import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import style from './playlistRule.scss';

class PlaylistRule extends Component {
  handleClick = () => {
    const { onClick, ruleSet, creativeCount, playlistHasData } = this.props;

    if (creativeCount < 2 || !playlistHasData) return;

    onClick(ruleSet);
  };

  renderCreativeCount = () => {
    const { t, creativeCount } = this.props;

    let message = `${t('Creatives')}: ${creativeCount}`;

    if (creativeCount < 2) {
      message = `${t('There must be as least 2 creatives')}`;
    }

    return <span>{message}</span>;
  };

  renderHeading = () => {
    const { active, ruleSet, creativeCount, playlistHasData } = this.props;

    const headingClasses = cx(style.ruleHeading, {
      [style.ruleHeadingActive]: active,
      [style.ruleHeadingDisabled]: creativeCount < 2 || !playlistHasData,
    });

    return (
      <div className={headingClasses} onClick={this.handleClick}>
        <div className={style.ruleHeadingDetails}>
          <Heading size={HeadingSizes.SMALLEST} tag={HeadingTags.H3}>
            {`Playlist: ${ruleSet.get('name')}`}
          </Heading>
          {this.renderCreativeCount()}
        </div>
      </div>
    );
  };

  render() {
    const { children, active, creativeCount } = this.props;

    return (
      <div className={style.rule}>
        {this.renderHeading()}
        {active && creativeCount > 1 ? children : null}
      </div>
    );
  }
}

export default withNamespaces(['common', 'campaignPlaylists'], { wait: false })(PlaylistRule);

PlaylistRule.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  ruleSet: PropTypes.instanceOf(Map).isRequired,
  onClick: PropTypes.func,
  creativeCount: PropTypes.number,
  playlistHasData: PropTypes.bool,
  t: PropTypes.func,
};
