import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './details.scss';

class Details extends Component {
  render() {
    const { children, className } = this.props;

    return <div className={`${className} ${style.details}`}>{children}</div>;
  }
}

export default Details;

Details.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Details.defaultProps = {
  className: '',
  children: null,
};
