import { handleActions } from 'redux-actions';
import { List, fromJS } from 'immutable';
import { UPDATE_ACTIVE_USER_SUCCESS } from 'actions/users';

const auth = handleActions(
  {
    AUTH_CHECK_REQUEST: (state) => ({
      ...state,
      isChecking: true,
    }),
    AUTH_CHECK_COMPLETE: (state) => ({
      ...state,
      isChecking: false,
    }),
    AUTH_MERGE: (state, action) => ({
      ...state,
      factor: action.factor,
      isAuthenticated: action.isAuthenticated,
      user: state.user.merge(fromJS(action.user)),
    }),
    AUTH_SEND_SMS_REQUEST: (state) => ({
      ...state,
      isSendingSms: true,
    }),
    AUTH_SEND_SMS_COMPLETE: (state) => ({
      ...state,
      isSendingSms: false,
    }),
    USER_BUSINESS_UNITS_FETCH_REQUEST: (state) => ({
      ...state,
    }),
    USER_BUSINESS_UNITS_FETCH_COMPLETE: (state) => ({
      ...state,
    }),
    USER_BUSINESS_UNITS_FETCH_SUCCESS: (state, action) => ({
      ...state,
      user: state.user.set('businessUnits', action.businessUnits),
    }),
    UPDATE_USER_LOCATION: (state, action) => ({
      ...state,
      user: state.user.set('location', fromJS({ latitude: action.latitude, longitude: action.longitude })),
    }),
    UPDATE_USER_LOCATION_FAILED: (state) => ({
      ...state,
      user: state.user.set('locationBlocked', true),
    }),
    FETCH_USER_PERMISSIONS_SUCCESS: (state, action) => ({
      ...state,
      // Clear all permissions matching action.object and add the new ones
      user: state.user.updateIn(['permissions'], List(), (permissions) =>
        permissions.filter((permission) => permission.get('object') !== action.object).concat(action.permissions),
      ),
    }),
    [UPDATE_ACTIVE_USER_SUCCESS]: (state, action) => ({
      ...state,
      user: state.user.merge(action.user),
    }),
  },
  {
    factor: 1,
    isAuthenticated: false,
    isChecking: false,
    isSendingSms: false,
    user: fromJS({ location: { latitude: 51.5225573, longitude: -0.1632761 } }),
  },
);

export default auth;
