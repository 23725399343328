import moment from 'moment';

// i.e 2018-12-31T15:48:35+00:00
const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

function transformCampaign(hit) {
  return {
    id: hit.objectID,
    name: hit.name,
    booking_engine_id: hit.booking_engine_id, // eslint-disable-line camelcase
    client: hit.client,
    starts_at: hit.starts_at && moment(hit.starts_at * 1000).format(dateFormat), // eslint-disable-line camelcase
    ends_at: hit.ends_at && moment(hit.ends_at * 1000).format(dateFormat), // eslint-disable-line camelcase
  };
}

// TODO: Ideally we want to transform to camel case here, but a lot of existing code relies
// on these variable names at moment. This will be part of a subsequent refactor.
function transformBooking(hit) {
  return {
    name: hit.name,
    code: hit.booking_code,
    advertiser: hit.advertiser,
    brand: hit.brand,
    'updated-at': hit.updated_at && moment(hit.updated_at * 1000).format(dateFormat),
    id: hit.objectID,
    'business-unit-id': hit.business_unit_id,
    'starts-at': hit.starts_at && moment(hit.starts_at * 1000).format(dateFormat),
    'ends-at': hit.ends_at && moment(hit.ends_at * 1000).format(dateFormat),
    'created-at': hit.created_at && moment(hit.created_at * 1000).format(dateFormat),
  };
}

export { transformCampaign, transformBooking };
