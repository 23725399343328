import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Map, List } from 'immutable';

import { userHasCampaignPermission } from 'store/user/helpers';
import { setActiveSection } from 'actions/campaign/navigation';
import { clearCampaignRelation, fetchCampaignRelations } from 'actions/campaign';
import {
  showContentTypeAddForm,
  closeContentTypeAddForm,
  deleteContentTypeRequest,
  mergePagination,
  showContentTypeEditForm,
  closeContentTypeEditForm,
} from 'actions/contentTypes';
import { getContentTypes } from 'selectors/campaign';
import { redirect, smoothScroll } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import Drawer from 'assets/components/presentational/Drawer';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import Pagination from 'assets/components/presentational/Pagination';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Content';
import Create from 'assets/components/presentational/Rows/Create';
import ContentTypeRow from 'assets/components/presentational/Campaign/ContentType/ContentTypeRow';

import ContentTypeFormContainer from './ContentTypeFormContainer';

import style from './contentType.scss';

class ContentType extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // The rule prompting deletion
      prompting: new Map(),
      selectedContentTypes: new List(),
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(setActiveSection('content'));

    this.setContentFilter(this.props, true);
  }

  componentDidUpdate() {
    this.setContentFilter(this.props);
  }

  setContentFilter = (nextProps, forceUpdate) => {
    const {
      params: { pageNumber },
    } = nextProps;

    const pageChanged = this.pageNumberHasChanged(nextProps);
    if (!forceUpdate && !pageChanged) {
      return;
    }

    this.fetchContentTypes(pageNumber);
  };

  renderPagination = () => {
    const { isFetching, pagination } = this.props;

    const pageNumber = this.getPageNumber();
    const initialSelected = pageNumber > 1 ? pageNumber - 1 : 0;

    return (
      <div>
        {pagination.get('total_pages') > 1 ? (
          <Pagination
            forceSelected={pageNumber - 1}
            hidden={isFetching}
            initialSelected={initialSelected}
            onClick={this.handlePaginationClick}
            pageNum={pagination.get('total_pages')}
          />
        ) : null}
      </div>
    );
  };

  pageNumberHasChanged = (nextProps) => {
    const {
      params: { pageNumber: currentPageNumber },
    } = this.props;
    const {
      params: { pageNumber: nextPageNumber },
    } = nextProps;

    const pageNumberHasChanged = currentPageNumber !== nextPageNumber;
    const pageNumberExists = typeof currentPageNumber !== 'undefined';
    const pageNumberIsNumber = !isNaN(parseInt(currentPageNumber));

    return pageNumberHasChanged && pageNumberExists && pageNumberIsNumber;
  };

  updatePageNumber = (pageNumber) => {
    const {
      params: { campaignId },
    } = this.props;
    return redirect(`/campaigns/${campaignId}/content-types/${pageNumber}`);
  };

  getPageNumber = () => {
    let {
      params: { pageNumber },
    } = this.props;
    pageNumber = parseInt(pageNumber);

    return isNaN(pageNumber) ? 1 : pageNumber;
  };

  handlePaginationClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;

    this.updatePageNumber(pageNumber);
  };

  fetchContentTypes = (pageNumber = 1) => {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(clearCampaignRelation(campaignId, ['content-types']));

    return dispatch(
      fetchCampaignRelations(campaignId, 'content-types', {
        pageNumber,
      }),
    ).then((response) => dispatch(mergePagination(response.data.body.meta.pagination)));
  };

  renderProgress() {
    const { isFetching } = this.props;

    if (isFetching) {
      return <ProgressBar />;
    }
  }

  handleDeleteContentTypeConfirmClick = (contentType) => {
    const { campaign, dispatch } = this.props;
    const { prompting } = this.state;
    this.setState({
      prompting: prompting.clear(),
    });
    // Delete forever
    return dispatch(deleteContentTypeRequest(campaign.get('id'), contentType));
  };

  handleDeleteContentTypeClick = (contentType) => {
    this.setState({
      prompting: contentType,
    });
  };

  handleDeleteContentTypeCancelClick = () => {
    const { prompting } = this.state;

    this.setState({
      prompting: prompting.clear(),
    });
  };

  setDrawerRef = (ref) => {
    this.drawerRef = ref;
  };

  scrollToError = () => {
    smoothScroll(this.drawerRef);
  };

  renderContent = () => {
    const { isFetching, contentTypes } = this.props;

    if (!isFetching) {
      return (
        <div>
          {this.renderNewContentType()}
          {contentTypes.map((contentType, key) => this.renderContentType(contentType, key))}
        </div>
      );
    }
  };

  renderNewContentType = () => {
    const { campaign, dispatch, t, user } = this.props;
    const userHasPermission = userHasCampaignPermission(user, campaign.get('id'), 'manage_dynamic_content_types');

    if (!userHasPermission) {
      return null;
    }

    return (
      <Create createLabel={t('Create a new content type')} onCreateClick={() => dispatch(showContentTypeAddForm())} />
    );
  };

  renderContentType = (contentType, contentTypeKey) => {
    const { campaign, dispatch } = this.props;
    const { prompting, selectedContentTypes } = this.state;

    return (
      <ContentTypeRow
        campaign={campaign}
        isDeleting={prompting.get('id') === contentType.get('id')}
        key={contentTypeKey}
        isSelected={selectedContentTypes.indexOf(contentType) !== -1}
        isDeletable
        isEditable
        isSelectable={false}
        contentType={contentType}
        onEditClick={() => dispatch(showContentTypeEditForm(contentType))}
        onDeleteClick={this.handleDeleteContentTypeClick}
        onDeleteCancelClick={this.handleDeleteContentTypeCancelClick}
        onDeleteConfirmClick={this.handleDeleteContentTypeConfirmClick}
      />
    );
  };

  render() {
    const { campaign, isDrawerActive, isSaving, editingContentType, dispatch, user, t } = this.props;

    return (
      <div className={style.component}>
        <Drawer
          onOverlayClick={() => dispatch(closeContentTypeAddForm())}
          active={isDrawerActive}
          getRef={this.setDrawerRef}
        >
          {isDrawerActive ? (
            <ContentTypeFormContainer
              campaign={campaign}
              dispatch={dispatch}
              submitting={isSaving}
              contentType={editingContentType || null}
              onCancel={() => dispatch(closeContentTypeEditForm())}
              scrollToError={this.scrollToError}
            />
          ) : null}
        </Drawer>
        <HeadingGroup
          subNavigation={
            <SubNavigation active={SubNavigationLabels.CONTENT_TYPES} campaignId={campaign.get('id')} user={user} />
          }
          title={t('Content')}
          intro={t('Content Types section introduction')}
        />
        {this.renderProgress()}
        {this.renderContent()}
        {this.renderPagination()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  contentTypes: getContentTypes(state),
  isFetching: state.contentTypes.isFetching,
  isSaving: state.contentTypes.isSaving,
  isDrawerActive: state.contentTypes.isDrawerActive,
  editingContentType: state.contentTypes.editingContentType,
  pagination: state.contentTypes.pagination,
  user: state.auth.user,
});

export default withNamespaces(['contentType', 'common'], { wait: false })(connect(mapStateToProps)(ContentType));

ContentType.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  contentTypes: PropTypes.instanceOf(List),
  isFetching: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isDrawerActive: PropTypes.bool.isRequired,
  editingContentType: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  params: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map),
};
