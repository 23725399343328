import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Map, List, fromJS } from 'immutable';

import ProgressBar from 'components/patterns/ProgressBar';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import FrameSpecifications from 'assets/components/presentational/Campaign/FrameSpecifications';
import FrameSpecification from 'assets/components/presentational/Campaign/FrameSpecification';
import PlaylistRules from 'assets/components/presentational/Campaign/Playlists/PlaylistRules';
import PlaylistRule from 'assets/components/presentational/Campaign/Playlists/PlaylistRule';
import PlaylistContainer from 'assets/components/containers/Campaign/Playlists/PlaylistContainer';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative';
import ConfirmationDialog from 'assets/components/containers/ConfirmationDialog';

import { mapStateToProps, mapDispatchToProps } from './Playlists.connected';

import style from './playlists.scss';

class Playlists extends Component {
  constructor(props) {
    super(props);

    this.removeRouteLeaveHook = null;
  }

  componentDidMount() {
    const {
      params: { campaignId },
      router,
      route,
      setActiveSection,
      fetchPlaylistsData,
    } = this.props;

    setActiveSection('playlists');
    fetchPlaylistsData(campaignId);

    this.removeRouteLeaveHook = router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentDidUpdate(prevProps) {
    this.expandFirstFrameSpecAfterFetch(prevProps, this.props);
  }

  componentWillUnmount() {
    if (this.removeRouteLeaveHook) {
      this.removeRouteLeaveHook();
    }
  }

  expandFirstFrameSpecAfterFetch = (props, nextProps) => {
    const { isFetching } = props;
    const { isFetching: nextIsFetching, frameSpecifications: nextFrameSpecifications } = nextProps;

    if (isFetching && !nextIsFetching && nextFrameSpecifications.size) {
      const firstFrameSpec = nextFrameSpecifications.first();

      this.handleFrameSpecClick(firstFrameSpec);
    }
  };

  routerWillLeave = (nextLocation) => {
    const { showDialog, hasUnsavedChanges, triggerConfirmationDialog } = this.props;

    if (hasUnsavedChanges) {
      triggerConfirmationDialog(nextLocation);
    }

    if (!showDialog && hasUnsavedChanges) {
      return false;
    }
  };

  generateRuleSetsArray = () => {
    const { ruleSets } = this.props;
    const initArray = [{ id: null, name: 'Default', creatives: [] }];

    const rsArray = ruleSets.reduce(
      (acc, rs) => acc.concat({ id: rs.get('id'), name: rs.get('name'), creatives: [] }),
      initArray,
    );

    return rsArray;
  };

  getCreativesByRuleSet = (creatives) => {
    const ruleSets = this.generateRuleSetsArray();

    creatives.toJS().forEach((creative) => {
      if (creative['is-default'] && creative['moderation-status'] === 1) {
        ruleSets.filter((rs) => rs.id === null)[0].creatives.push(creative);
      }

      if (!creative['rule-sets']) return;

      creative['rule-sets'].forEach((ruleSet) => {
        if (creative['moderation-status'] !== 1) return;
        if (!ruleSets.filter((rs) => rs.id === ruleSet.id).length) {
          const newrs = { id: ruleSet.id, name: ruleSet.name, creatives: [creative] };
          ruleSets.push(newrs);
        } else {
          ruleSets.filter((rs) => rs.id === ruleSet.id)[0].creatives.push(creative);
        }
      });
    });

    return fromJS(ruleSets).sortBy((rs) => (rs.get('id') !== null ? rs.get('name') : ''));
  };

  getPlaylistsData = (frameSpecId, ruleSetId) => {
    const { playlists } = this.props;
    return playlists.find(
      (pl) => pl.get('frame-specification-id') === frameSpecId && pl.get('ruleset-id') === ruleSetId,
    );
  };

  handleFrameSpecClick = (frameSpec) => {
    const { setActiveFrameSpec, setActiveRuleSet, activeFrameSpecId } = this.props;

    if (activeFrameSpecId !== frameSpec.get('id')) {
      setActiveFrameSpec(frameSpec.get('id'));
      setActiveRuleSet('');
    }
  };

  handleRuleSetClick = (ruleSet) => {
    const { setActiveRuleSet } = this.props;

    setActiveRuleSet(ruleSet.get('id'));
  };

  renderHeading = () => {
    const { t, user, activeCampaignId } = this.props;

    return (
      <HeadingGroup
        intro={t('Playlists section introduction')}
        subNavigation={
          <SubNavigation active={SubNavigationLabels.PLAYLISTS} campaignId={activeCampaignId} user={user} />
        }
        title={t('Creative')}
      />
    );
  };

  renderRuleSet(frameSpec, frameSpecKey) {
    const { activeRuleSetId, hasUnsavedChanges } = this.props;

    const creatives = frameSpec.get('creatives', List());
    const creativesByRuleSet = this.getCreativesByRuleSet(creatives);

    return (
      <div>
        {creativesByRuleSet.map((ruleSet) => {
          const playlist = this.getPlaylistsData(frameSpec.get('id'), ruleSet.get('id'));
          const playlistHasData = playlist && playlist.get('creatives').size > 0;

          return (
            <PlaylistRule
              active={ruleSet.get('id') === activeRuleSetId && playlistHasData}
              playlistHasData={playlistHasData}
              ruleSet={ruleSet}
              key={ruleSet.get('id')}
              onClick={this.handleRuleSetClick}
              creativeCount={ruleSet.get('creatives').size}
            >
              <PlaylistContainer
                frameSpecification={frameSpec}
                frameSpecKey={frameSpecKey}
                ruleSet={ruleSet}
                playlist={playlist}
                creatives={ruleSet.get('creatives')}
                hasUnsavedChanges={hasUnsavedChanges}
              />
            </PlaylistRule>
          );
        })}
      </div>
    );
  }

  renderRuleSets(frameSpec, frameSpecKey) {
    const { ruleSets } = this.props;

    return <PlaylistRules ruleSets={ruleSets}>{this.renderRuleSet(frameSpec, frameSpecKey)}</PlaylistRules>;
  }

  renderFrameSpecs() {
    const { isFetching, frameSpecifications, activeFrameSpecId, activeCampaignId } = this.props;

    if (isFetching) {
      return <ProgressBar />;
    }

    return (
      <FrameSpecifications
        frameSpecifications={frameSpecifications}
        emptyFrameSpecsMessage="There are no frames available for this campaign"
      >
        {frameSpecifications.map((frameSpec, frameSpecKey) => (
          <FrameSpecification
            active={frameSpec.get('id') === activeFrameSpecId}
            campaignId={activeCampaignId}
            frameSpecification={frameSpec}
            key={frameSpec.get('id')}
            onClick={this.handleFrameSpecClick}
          >
            {this.renderRuleSets(frameSpec, frameSpecKey)}
          </FrameSpecification>
        ))}
      </FrameSpecifications>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className={style.component}>
        {this.renderHeading()}
        {this.renderFrameSpecs()}
        <ConfirmationDialog message={t('You have unsaved changes. Are you sure you want to continue?')} />
      </div>
    );
  }
}

Playlists.propTypes = {
  t: PropTypes.func.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  playlists: PropTypes.instanceOf(List).isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  activeCampaignId: PropTypes.string.isRequired,
  activeFrameSpecId: PropTypes.string.isRequired,
  activeRuleSetId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  showDialog: PropTypes.bool.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    setRouteLeaveHook: PropTypes.func.isRequired,
  }).isRequired,
  setActiveSection: PropTypes.func.isRequired,
  fetchPlaylistsData: PropTypes.func.isRequired,
  setActiveFrameSpec: PropTypes.func.isRequired,
  setActiveRuleSet: PropTypes.func.isRequired,
  triggerConfirmationDialog: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'campaignPlaylists'], { wait: false })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Playlists)),
);
