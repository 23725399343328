import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import Icon, { IconTypes, IconColors, IconSizes } from 'assets/components/presentational/Icon';

import style from './RecapItem.scss';

const cx = classnames.bind(style);

export const VARIANTS = {
  PROMOTED: 'PROMOTED',
  PENDING: 'PENDING',
  NEUTRAL: 'NEUTRAL',
  ERROR: 'ERROR',
};

const themeElements = {
  [VARIANTS.PROMOTED]: {
    icon: IconTypes.DONE,
    className: 'promoted',
    iconColor: IconColors.GREEN,
  },
  [VARIANTS.PENDING]: {
    icon: IconTypes.HOURGLASS_EMPTY,
    className: 'pending',
    iconColor: IconColors.YELLOW,
  },
  [VARIANTS.NEUTRAL]: {
    icon: IconTypes.CLEAR,
    className: 'unpromoted',
    iconColor: IconColors.GRAY,
  },
  [VARIANTS.ERROR]: {
    icon: IconTypes.WARNING,
    className: 'errors',
    iconColor: IconColors.RED,
  },
};

const propTypes = {
  total: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

const RecapItem = ({ total, label, variant }) => (
  <div
    className={cx('component', themeElements[variant].className, {
      dimmed: total === 0 && variant === VARIANTS.ERROR,
    })}
  >
    <div className={cx('content')}>
      <span className={style.icon}>
        <Icon iconType={themeElements[variant].icon} color={themeElements[variant].iconColor} size={IconSizes.MEDIUM} />
      </span>
      <span className={cx('number')}>{total}</span>
      <span className={cx('label')}>
        <span>{label}</span>
      </span>
    </div>
  </div>
);

RecapItem.propTypes = propTypes;

export default withNamespaces(['common', 'frames'], { wait: false })(RecapItem);
