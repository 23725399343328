import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonThemes } from 'assets/components/presentational/Button';
import ConfirmButton from 'assets/components/presentational/ConfirmButton';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

export default class DeleteConfirmButton extends Component {
  render() {
    const { className, label, confirmLabel, onConfirmClick } = this.props;
    return (
      <ConfirmButton
        className={className}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        confirmIcon={<Icon iconType={IconTypes.HELP} />}
        buttonTheme={ButtonThemes.DANGER}
        confirmButtonTheme={ButtonThemes.WARNING}
        label={label}
        confirmLabel={confirmLabel}
        onConfirmClick={onConfirmClick}
      />
    );
  }
}

DeleteConfirmButton.propTypes = {
  className: PropTypes.string,
  onConfirmClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  confirmLabel: PropTypes.string,
};

DeleteConfirmButton.defaultProps = {
  className: '',
  label: '',
  confirmLabel: '',
};
