import React from 'react';
import PropTypes from 'prop-types';

export default class Handle extends React.Component {
  render() {
    const {
      className,
      vertical,
      offset,
      percentage,
      style,
      disabled,
      min,
      max,
      value,
      prefixCls,
      ...restProps
    } = this.props;

    const postionStyle = vertical ? { bottom: `${offset}%` } : { left: `${offset}%` };
    const elStyle = {
      ...style,
      ...postionStyle,
    };
    let ariaProps = {};
    if (value !== undefined) {
      ariaProps = {
        ...ariaProps,
        'aria-valuemin': min,
        'aria-valuemax': max,
        'aria-valuenow': value,
        'aria-disabled': !!disabled,
      };
    }
    return (
      <div role="slider" {...ariaProps} {...restProps} className={className} style={elStyle}>
        <div className={`${prefixCls}-label`}>
          <div className={`${prefixCls}-label-inner`}>{percentage}%</div>
        </div>
        <div className={`${prefixCls}-button`} />
      </div>
    );
  }
}

Handle.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  offset: PropTypes.number,
  percentage: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
};
