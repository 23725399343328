import FEATURES from 'features';
import { DISE, CAMPAIGN } from 'app-constants';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { animateScroll } from 'react-scroll';
import moment from 'moment';

import { isCampaignFlaggedAsLate, isCampaignFlaggableAsLate } from 'store/campaign/helpers';
import { isContentProviderForCampaign, userHasCampaignPermission } from 'store/user/helpers';
import { isCampaignFullyPromotable } from 'store/bookings/selectors';

import {
  fetchCampaign,
  markContentCompleted,
  showCampaignSettingsForm,
  toggleCampaignActive,
  markAsLate,
} from 'actions/campaign';
import { togglePinCampaign } from 'store/pinnedCampaigns/actions';

import { List, ListItem } from 'react-toolbox/lib/list';
import ProgressBar from 'components/patterns/ProgressBar';
import { IconButton } from 'react-toolbox/lib/button';

import { checkDeviceWidthSmallerThanDesktop } from 'modules/Helpers';
import Link from 'assets/components/presentational/Link';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import { PushPin, ICON_COLORS } from 'assets/components/presentational/CustomIcon';
import Switch, { SwitchVariants } from 'components/patterns/Switch';
import Tag, { TagTheme, TagVariant } from 'assets/components/presentational/Tag';
import ConfirmButton from 'assets/components/presentational/ConfirmButton';
import CampaignStatus, { CAMPAIGN_STAGES } from 'assets/components/presentational/Campaign/CampaignStatus';
import SnoozeCampaign from 'assets/components/presentational/Campaign/SnoozeCampaign';
import ContainerFixed, { Section, Content } from 'assets/components/presentational/ContainerFixed';

import itemTheme from './itemTheme.scss';
import style from './navigation.scss';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      isMobile: false,
    };
  }

  componentDidMount = () => {
    this.displayNav();
    window.addEventListener('resize', this.displayNav);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.displayNav);
  };

  displayNav = () => {
    if (checkDeviceWidthSmallerThanDesktop(window.innerWidth)) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  handleToggleClick = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  handleListItemClick = () => {
    this.setState({ isExpanded: false });

    animateScroll.scrollToTop({
      duration: 200,
      delay: 0,
      smooth: true,
    });
  };

  handleSettingsClick = () => {
    const { campaign, dispatch } = this.props;
    const campaignId = campaign.get('id');
    dispatch(fetchCampaign(campaignId));
    dispatch(showCampaignSettingsForm(campaignId));
  };

  handleContentCompletedClick = (campaignId) => {
    const { dispatch } = this.props;

    dispatch(markContentCompleted(campaignId));
  };

  handleMarkAsLateClick = () => {
    const { campaign, dispatch } = this.props;
    dispatch(markAsLate(campaign.get('id'), campaign.get('name')));
  };

  renderProgress() {
    const { isInitialFetch, isFetchingCampaign } = this.props;

    if (isFetchingCampaign && isInitialFetch) {
      return (
        <div className={style.progress}>
          <ProgressBar circular />
        </div>
      );
    }
    return null;
  }

  renderPinButton() {
    const { campaign, pinnedCampaigns, dispatch } = this.props;

    const isCampaignPinned = pinnedCampaigns.filter((c) => c.id === campaign.get('id')).length > 0;
    return (
      <IconButton
        icon={<PushPin fill={isCampaignPinned ? ICON_COLORS.BLUE : ICON_COLORS.MEDIUM_GRAY} />}
        onClick={() => dispatch(togglePinCampaign(campaign.get('id'), this.setIsFetchingPinnedCampaign))}
        theme={{ toggle: style.pinButtonTheme }}
      />
    );
  }

  renderOverview = ({
    showActiveStatus,
    showActiveSwitch,
    disableActiveSwitch,
    showContentCompletedButton,
    showContentCompletedStatus,
    showMarkAsLateButton,
    showLateStatus,
    onMarkAsLateClick,
  }) => {
    const { campaign, isInitialFetch, user } = this.props;

    if (isInitialFetch) {
      return null;
    }

    const startDate = moment.utc(campaign.get('starts-at')).format('DD MMM YYYY');
    const endDate = moment.utc(campaign.get('ends-at')).format('DD MMM YYYY');
    const isLiteUser = user.get('is-lite-user');

    if (campaign.size && campaign.has('client')) {
      return (
        <div className={style.overviewNew}>
          <div className={style.statusWrapper}>
            <div className={style.overviewStatus}>
              {this.renderActiveSwitch({ showActiveSwitch, disableActiveSwitch })}
              {this.renderActiveStatus({ showActiveStatus })}
              {this.renderCampaignStatus(true)}
            </div>
            <div className={style.campaignInfoIcons}>
              {!isLiteUser && (
                <div className={style.pinButton} data-testid="pinCampaign">
                  {this.renderPinButton()}
                </div>
              )}
              {this.renderSettingsButton()}
            </div>
          </div>
          <div className={style.infoWrapper}>
            <div className={style.overviewInfo}>
              <span className={style.clientTitle}>{campaign.getIn(['client', 'name'], 'Client')}</span>
              <strong className={style.campaignTitle}>{campaign.get('name')}</strong>
              <span className={style.campaignDate}>
                {startDate}&nbsp;-&nbsp;{endDate}&nbsp;
              </span>
            </div>
            <div className={style.completeStatus}>
              <div className={style.lateStatus}>
                {FEATURES.LATE_AD_COPY && this.renderMarkAsLateButton({ showMarkAsLateButton, onMarkAsLateClick })}
                {FEATURES.LATE_AD_COPY && this.renderLateStatus({ showLateStatus })}
              </div>
              <div className={style.statusAction}>
                {this.renderContentCompletedStatus({ showContentCompletedStatus })}
                {this.renderContentCompletedButton({ showContentCompletedButton })}
              </div>
            </div>
            {campaign.get('booking-engine-id') && (
              <div className={style.campaignBookingEngine}>{campaign.get('booking-engine-id')}</div>
            )}
            {!isLiteUser && <SnoozeCampaign className={style.snoozeCampaign} />}
          </div>
        </div>
      );
    }

    return null;
  };

  renderSettingsButton() {
    const { user, campaign } = this.props;
    const campaignId = campaign.get('id');

    if (userHasCampaignPermission(user, campaignId, 'edit')) {
      return (
        <div className={style.settingsButton}>
          <IconButton icon={<Icon iconType={IconTypes.SETTINGS} />} onClick={this.handleSettingsClick} />
        </div>
      );
    }
    return null;
  }

  renderCampaignStatus = (newTheme) => {
    const { campaign } = this.props;

    const className = newTheme ? style.campaignStatusNew : style.campaignStatus;

    if (FEATURES.PUBLISHING(campaign)) {
      switch (campaign.get('publishing-status')) {
        case DISE.PUBLISHED:
          return <CampaignStatus label="Published" currentCampaignStage={CAMPAIGN_STAGES.END} className={className} />;
        case DISE.UNPUBLISHED:
          return (
            <CampaignStatus label="Unpublished" currentCampaignStage={CAMPAIGN_STAGES.START} className={className} />
          );
        case DISE.DRAFT:
          return <CampaignStatus label="Draft" currentCampaignStage={CAMPAIGN_STAGES.PARTIAL} className={className} />;
        default:
          return null;
      }
    } else if (FEATURES.PROMOTION(campaign)) {
      switch (campaign.get('promotion-status')) {
        case CAMPAIGN.UNPROMOTED:
          return (
            <CampaignStatus label="Unpromoted" currentCampaignStage={CAMPAIGN_STAGES.START} className={className} />
          );
        case CAMPAIGN.PARTIALLY_PROMOTED:
          return (
            <CampaignStatus
              label="partially_promoted"
              currentCampaignStage={CAMPAIGN_STAGES.PARTIAL}
              className={className}
            />
          );
        case CAMPAIGN.FULLY_PROMOTED:
          return <CampaignStatus label="Promoted" currentCampaignStage={CAMPAIGN_STAGES.END} className={className} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  renderActiveStatus = ({ showActiveStatus }) => {
    const { campaign, t } = this.props;

    if (showActiveStatus) {
      return (
        <span className={campaign.get('is-active', false) ? style.campaignStatusActive : style.campaignStatus}>
          {campaign.get('is-active', false) ? t('Active') : t('Inactive')}
        </span>
      );
    }
    return null;
  };

  renderActiveSwitch = ({ disableActiveSwitch, showActiveSwitch }) => {
    const { campaign, dispatch, t } = this.props;

    if (showActiveSwitch) {
      return (
        <div className={style.activeSwitch}>
          <Switch
            disabled={disableActiveSwitch}
            variant={SwitchVariants.NAVIGATION_NEW}
            label={campaign.get('is-active', false) ? t('Active') : t('Inactive')}
            checked={campaign.get('is-active', false)}
            onChange={() => dispatch(toggleCampaignActive(!campaign.get('is-active'), campaign.get('id')))}
          />
        </div>
      );
    }

    return null;
  };

  renderContentCompletedStatus = ({ showContentCompletedStatus }) => {
    const { t } = this.props;

    if (showContentCompletedStatus) {
      return (
        <Tag
          copy={t('Content Completed')}
          tagTheme={TagTheme.GREEN}
          tagVariant={TagVariant.CLEAR}
          icon={<Icon iconType={IconTypes.IMAGE} />}
          className={style.contentCompletedStatus}
        />
      );
    }

    return null;
  };

  renderContentCompletedButton = ({ showContentCompletedButton }) => {
    const { campaign, contentCompleting, t } = this.props;
    const campaignId = campaign.get('id');

    if (showContentCompletedButton) {
      return (
        <ConfirmButton
          className={style.statusButton}
          confirmLabel={t('Are you sure?')}
          disabled={contentCompleting}
          label={t('Mark content as completed')}
          onConfirmClick={() => this.handleContentCompletedClick(campaignId)}
          showProgress={false}
        />
      );
    }

    return null;
  };

  renderMarkAsLateButton({ showMarkAsLateButton, onMarkAsLateClick }) {
    const { campaign, t } = this.props;

    if (showMarkAsLateButton) {
      return (
        <ConfirmButton
          className={style.statusButton}
          confirmLabel={t('Are you sure?')}
          disabled={Boolean(campaign.get('is-late', false))}
          label={t('Mark as late')}
          onConfirmClick={onMarkAsLateClick}
          showProgress={false}
        />
      );
    }

    return null;
  }

  renderLateStatus({ showLateStatus }) {
    const { t } = this.props;

    if (showLateStatus) {
      return <Tag className={style.navigationTag} copy={t('Late copy')} tagTheme={TagTheme.RED} />;
    }

    return null;
  }

  renderBookingEngineRef() {
    const { campaign } = this.props;

    if (campaign.get('booking-engine-id')) {
      return <div className={style.campaignBookingEngine}>{campaign.get('booking-engine-id')}</div>;
    }
    return null;
  }

  getCampaignNavigationItems = (user, campaign) => {
    const campaignId = campaign.get('id');

    return [
      { label: 'Creative', to: `/campaigns/${campaignId}/creative`, shouldShow: true },
      {
        label: 'Bookings',
        to: `/campaigns/${campaignId}/bookings`,
        shouldShow:
          FEATURES.PROMOTION(campaign) &&
          (user.get('is-business-unit-admin') || userHasCampaignPermission(user, campaign.get('id'), 'deploy')),
      },
      {
        label: 'Publishing',
        to: `/campaigns/${campaignId}/publishing`,
        shouldShow: FEATURES.PUBLISHING(campaign),
      },
      { label: 'Rules', to: `/campaigns/${campaignId}/rules`, shouldShow: FEATURES.PROMOTION(campaign) },
      {
        label: 'Content',
        to: `/campaigns/${campaignId}/content`,
        shouldShow:
          FEATURES.PROMOTION(campaign) && userHasCampaignPermission(user, campaign.get('id'), 'view_dynamic_content'),
      },
      {
        label: 'Moderation',
        to: `/campaigns/${campaignId}/moderation`,
        shouldShow: FEATURES.PROMOTION(campaign) && userHasCampaignPermission(user, campaign.get('id'), 'moderate'),
      },
      { label: 'Preview', to: `/campaigns/${campaignId}/preview`, shouldShow: true },
      {
        label: 'Media Buy',
        to: `/campaigns/${campaignId}/media-buy`,
        shouldShow: userHasCampaignPermission(user, campaign.get('id'), 'manage_media_buy'),
      },
      {
        label: 'Builds',
        to: `/campaigns/${campaignId}/builds`,
        shouldShow: FEATURES.PROMOTION(campaign) && userHasCampaignPermission(user, campaign.get('id'), 'add_build'),
      },
    ];
  };

  renderLinks() {
    const { activeSection, campaign, isInitialFetch, t, user, links: _links } = this.props;

    if (isInitialFetch) {
      return null;
    }

    const links = _links || this.getCampaignNavigationItems(user, campaign).filter((item) => item.shouldShow);

    return (
      <List className={style.items} selectable ripple>
        {links.map((item, key) => (
          <ListItem
            key={key}
            className={activeSection === item.label.toLowerCase() ? style.itemActiveNew : ''}
            disabled={!campaign.size}
            theme={itemTheme}
            itemContent={
              <Link to={item.to} onClick={this.handleListItemClick} className={style.linkNew}>
                {t(item.label)}
              </Link>
            }
          />
        ))}
      </List>
    );
  }

  render() {
    const {
      campaign,
      user,
      userIsContentProviderForCampaign,
      isTogglingCampaignActive,
      isFullyPromotable,
      t,
    } = this.props;
    const { isExpanded, isMobile } = this.state;

    let showActiveStatus;
    let showActiveSwitch;
    let disableActiveSwitch;
    let showContentCompletedButton;
    let showContentCompletedStatus;
    let showMarkAsLateButton;
    let showLateStatus;

    const campaignId = campaign.get('id');

    if (userIsContentProviderForCampaign) {
      showContentCompletedButton = Boolean(userIsContentProviderForCampaign && !campaign.get('is-content-completed'));
      showContentCompletedStatus = Boolean(userIsContentProviderForCampaign && campaign.get('is-content-completed'));
    } else {
      showActiveSwitch = Boolean(
        FEATURES.PROMOTION(campaign) &&
          (user.get('is-super-user') ||
            user.get('is-business-unit-admin') ||
            (!isFullyPromotable && userHasCampaignPermission(user, campaignId, 'edit'))),
      );
      disableActiveSwitch = Boolean(isTogglingCampaignActive);
      showActiveStatus = Boolean(!showActiveSwitch && !isFullyPromotable);
      showMarkAsLateButton = Boolean(
        isCampaignFlaggableAsLate(campaign) &&
          !isCampaignFlaggedAsLate(campaign) &&
          userHasCampaignPermission(user, campaignId, 'deploy'),
      );
      showLateStatus = isCampaignFlaggedAsLate(campaign);
    }

    if (!isMobile) {
      return (
        <div className={style.componentFixedNew}>
          <ContainerFixed className={style.container}>
            <Section className={style.section}>
              <Content>
                {this.renderProgress()}
                {this.renderOverview({
                  showActiveStatus,
                  showActiveSwitch,
                  disableActiveSwitch,
                  showContentCompletedButton,
                  showContentCompletedStatus,
                  showMarkAsLateButton,
                  showLateStatus,
                  onMarkAsLateClick: this.handleMarkAsLateClick,
                })}
              </Content>
              <Content isScrollable>{this.renderLinks()}</Content>
            </Section>
          </ContainerFixed>
        </div>
      );
    }

    return (
      <div>
        <div className={style.toggle}>
          <div className={style.toggleContentWrapper}>
            <span>{t('Campaign navigation')}</span>
            {isExpanded ? (
              <IconButton
                onClick={this.handleToggleClick}
                icon={<Icon iconType={IconTypes.CLEAR} color={IconColors.WHITE} />}
              />
            ) : (
              <IconButton
                onClick={this.handleToggleClick}
                icon={<Icon iconType={IconTypes.MENU} color={IconColors.WHITE} />}
              />
            )}
          </div>
        </div>
        <div className={isExpanded ? style.componentExpanded : style.componentCollapsed}>
          {this.renderProgress()}
          {this.renderOverview({
            showActiveStatus,
            showActiveSwitch,
            disableActiveSwitch,
            showContentCompletedButton,
            showContentCompletedStatus,
            showMarkAsLateButton,
            showLateStatus,
            onMarkAsLateClick: this.handleMarkAsLateClick,
          })}
          {this.renderLinks()}
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activeSection: PropTypes.string,
  campaign: PropTypes.instanceOf(Map).isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isInitialFetch: PropTypes.bool,
  user: PropTypes.instanceOf(Map).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  contentCompleting: PropTypes.bool,
  pinnedCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  userIsContentProviderForCampaign: PropTypes.bool.isRequired,
  isTogglingCampaignActive: PropTypes.bool.isRequired,
  isFullyPromotable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  activeSection: '',
  contentCompleting: false,
  isInitialFetch: true,
  links: null,
};

const mapStateToProps = (state) => ({
  isFullyPromotable: isCampaignFullyPromotable(state),
  userIsContentProviderForCampaign: isContentProviderForCampaign(state.auth.user, state.campaign.campaign),
  isTogglingCampaignActive: state.campaign.isTogglingCampaignActive,
  pinnedCampaigns: state.pinned.campaigns,
});

export default withNamespaces(['common', 'campaign', 'bookings'], { wait: false })(
  connect(mapStateToProps)(Navigation),
);
