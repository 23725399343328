import * as React from 'react';
import PropTypes from 'prop-types';

import { ICON_COLORS } from '../helpers';

const PushPin = ({ fill, height, width, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 12 12" className={className}>
    <path
      fill={fill}
      fillRule="nonzero"
      d="M.0046862 4.82090624c.0154089-.07447333.0673006-.13649672.1380002-.1645657.6236067-.2483199 1.2848293-.37417758 1.96531295-.37417758 1.06072062 0 1.87920446.30287333 2.19531325.43959641l3.37160052-2.86552571c-.11783267-1.03402492.4405129-1.74118207.46543904-1.7719674C8.18091379.0342401 8.24050993.00368115 8.30463809 0c.06390156-.00129884.12712332.02065834.17244358.06615725l3.456576 3.45293697c.04577346.04549891.0697932.10842775.06594097.17316749-.00362562.06428701-.03467.12427313-.08520208.16433933-.4942174.39160751-1.09743002.47400354-1.5161892.47400354-.09335973 0-.17312338-.00430089-.23407913-.0090545L7.28130669 7.70657823c.19668991.50478888.79763652 2.32474517.06956659 4.15081327-.02809856.0708515-.08996071.1224622-.16473913.1378549C7.17095186 11.9984155 7.15554298 12 7.14036069 12c-.05959614 0-.11737947-.0235417-.16020711-.0663243L3.67857286 8.63557085.6035934 11.707313c-.0441872.0441407-.1021972.0663243-.1602071.0663243-.0580099 0-.1160198-.0221836-.1602071-.0663243-.0886011-.0885078-.0886011-.2315691 0-.3200769l3.07497944-3.07174214L.0663218 5.02689632c-.0539311-.05387433-.0770445-.13129038-.0616356-.20599008z"
    />
  </svg>
);

PushPin.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

PushPin.defaultProps = {
  fill: ICON_COLORS.BLACK,
  height: 12,
  width: 12,
  className: '',
};

export default PushPin;
