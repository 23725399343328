import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './addSetButton.scss';

const AddSetButton = ({ disabled, label, onClick, t }) => (
  <Button
    className={style.component}
    disabled={disabled}
    icon={<Icon iconType={IconTypes.ADD} />}
    label={t(label)}
    onClick={onClick}
    theme={ButtonThemes.WHITE}
  />
);

export default withNamespaces(['common', 'rules'], { wait: false })(AddSetButton);

AddSetButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

AddSetButton.defaultProps = {
  disabled: true,
  label: '',
};
