import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { notifyApiError } from './notify';

export const FETCH_REQUEST = 'business-units/FETCH_REQUEST';
export const FETCH_SUCCESS = 'business-units/FETCH_SUCCESS';
export const FETCH_FAILURE = 'business-units/FETCH_FAILURE';

export const fetchBusinessUnits = () => (dispatch) => {
  dispatch({ type: FETCH_REQUEST });
  return Request.send({
    method: 'GET',
    endpoint: 'business-units',
    pagination: Request.ALL_PAGES,
  })
    .then((response) =>
      dispatch({
        type: FETCH_SUCCESS,
        businessUnits: fromJS(response.data.body.parsed),
      }),
    )
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_FAILURE });
    });
};
