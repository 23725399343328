import Request from 'modules/API/request';

const END_POINTS = {
  CAMPAIGNS: 'campaigns',
  BOOKINGS: 'open-layer-campaigns',
  TAGS: 'tag-keys',
  SUB_TAGS: 'tag-key-values',
};

const search = async (query, endpoint, { facetFilters, hitsPerPage = 20 } = {}) => {
  const extraSerializerConfiguration = {
    keyForAttribute: (key) => key,
  };

  const options = {
    endpoint: `search/${endpoint}`,
    method: 'POST',
    data: { query, facetFilters, hitsPerPage },
    extraSerializerConfiguration,
  };

  try {
    const response = await Request.send(options);
    return response.data.body.data.hits;
  } catch (error) {
    throw error;
  }
};

const searchCampaigns = async (query) => search(query, END_POINTS.CAMPAIGNS);

const searchBookings = async (query, businessUnits) => {
  const [businessUnitId] = businessUnits;
  return search(query, END_POINTS.BOOKINGS, { facetFilters: { business_unit_id: businessUnitId } });
};

const searchTags = async (query) => search(query, END_POINTS.TAGS, { hitsPerPage: 10 });

const searchSubTags = async (query, tagId) =>
  search(query, END_POINTS.SUB_TAGS, { facetFilters: { tag_key_id: tagId }, hitsPerPage: 10 });

export { searchCampaigns, searchBookings, searchTags, searchSubTags };
