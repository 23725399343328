import React from 'react';
import PropTypes from 'prop-types';

import style from './Section.scss';

const Section = ({ className, children }) => {
  return <div className={`${style.component} ${className}`}>{children}</div>;
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Section.defaultProps = {
  className: '',
  children: null,
};

export default Section;
