import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import style from './pagination.scss';

const Pagination = (props) => {
  const { containerClassName, forceSelected, hidden, initialSelected, onClick, pageNum } = props;

  return (
    <ReactPaginate
      forcePage={forceSelected}
      initialPage={initialSelected}
      pageCount={!pageNum ? 1 : pageNum}
      onPageChange={onClick}
      previousLabel="<"
      nextLabel=">"
      containerClassName={`${style.componentNew} ${containerClassName} ${hidden ? style.hidden : ''}`}
      nextLinkClassName={style.link}
      pageLinkClassName={style.link}
      previousLinkClassName={style.link}
      breakClassName={style.break}
      activeClassName={style.active}
      disabledClassName={style.disabled}
    />
  );
};

export default withNamespaces(['common'], { wait: false })(Pagination);

Pagination.propTypes = {
  containerClassName: PropTypes.string,
  forceSelected: PropTypes.number,
  hidden: PropTypes.bool,
  initialSelected: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  pageNum: PropTypes.number,
  t: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  containerClassName: '',
  forceSelected: undefined,
  hidden: false,
  initialSelected: 0,
  pageNum: 0,
};
