import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getModerationIcon } from 'modules/Helpers';

import Tooltip from 'components/patterns/Tooltip';
import Icon, { IconColors } from 'assets/components/presentational/Icon';

const IconTooltip = Tooltip(Icon);

class ModerationStatusChip extends Component {
  getIconColor = () => {
    const { status } = this.props;

    switch (status) {
      case 1:
        return IconColors.GREEN;
      case -1:
        return IconColors.RED;
      default:
        return IconColors.ORANGE;
    }
  };

  render = () => {
    const { status, label } = this.props;

    return <IconTooltip iconType={getModerationIcon(status)} color={this.getIconColor()} tooltip={label} />;
  };
}

export default ModerationStatusChip;

ModerationStatusChip.propTypes = {
  label: PropTypes.string,
  status: PropTypes.number.isRequired,
};

ModerationStatusChip.defaultProps = {
  label: '',
};
