import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import DatePicker from 'components/patterns/DatePicker';

import AddSetButton from '../AddSetButton';
import SetContainer from '../SetContainer';

import style from './datesForm.scss';

const Options = {
  FROM: 0,
  TO: 1,
};

class DatesForm extends Component {
  handleRemoveSetClick = (key) => {
    const { fields, notifyError, t } = this.props;
    if (fields.length > 1) {
      fields.removeField(key);
    } else {
      notifyError(t('You cannot delete this since it is the only sub rule available'));
    }
  };

  addNewSet = () => {
    const { fields, ruleType } = this.props;

    return fields.addField(ruleType.get('default-value').toJS()[0]);
  };

  getFromValue = (set) =>
    moment(set[Options.FROM].value ? set[Options.FROM].value : moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate();

  getToValue = (set) =>
    moment(set[Options.TO].value ? set[Options.TO].value : moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate();

  render() {
    const { fields, ruleType, t } = this.props;

    return (
      <div>
        {fields.map((set, key) => (
          <SetContainer
            enabled={ruleType.get('is-selected')}
            key={key}
            onDeleteClick={this.handleRemoveSetClick}
            sequence={key}
          >
            <div className={style.formGroup}>
              <div className={style.picker}>
                <DatePicker
                  autoOk
                  label={t('From')}
                  onChange={(value) => {
                    set[Options.FROM].onChange(moment(value).format('YYYY-MM-DD'));
                    set[Options.TO].onChange(moment(value).format('YYYY-MM-DD'));
                  }}
                  readonly={!ruleType.get('is-selected')}
                  value={this.getFromValue(set)}
                />
              </div>
              <div className={style.picker}>
                <DatePicker
                  autoOk
                  label={t('To')}
                  onChange={(value) => set[Options.TO].onChange(moment(value).format('YYYY-MM-DD'))}
                  value={this.getToValue(set)}
                  readonly={!ruleType.get('is-selected')}
                  className={style.formElement}
                />
              </div>
            </div>
          </SetContainer>
        ))}
        <AddSetButton disabled={!ruleType.get('is-selected')} label={t('Add date range')} onClick={this.addNewSet} />
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(DatesForm);

DatesForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  notifyError: PropTypes.func,
};

DatesForm.defaultProps = {
  notifyError: () => {},
};
