import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import Icon from 'assets/components/presentational/Icon';

import style from './tag.scss';

export const TagTheme = {
  GREEN: 'green',
  RED: 'red',
  BLUE: 'blue',
  GREY: 'grey',
  YELLOW: 'yellow',
  DEFAULT: 'blue',
};

export const TagVariant = {
  FILLED: 'filled',
  CLEAR: 'clear',
  DEFAULT: 'filled',
};

const Tag = (props) => {
  const { className, tagTheme, tagVariant, icon, copy, children } = props;

  const classNames = `
    ${className}
    ${style.tag}
    ${style[tagTheme]}
    ${style[tagVariant]}
  `;

  // we need extra props to enable tooltip props to be added with a HOC if needed, but we don't want the below:
  const rest = omit(props, [
    'theme',
    'tooltip',
    'tooltipDelay',
    'tooltipHideOnClick',
    'className',
    'tagTheme',
    'tagVariant',
    'copy',
  ]);

  return (
    <span className={classNames} {...rest}>
      {icon ? (
        <span className={style.tagIcon}>
          <Icon iconType={icon} />
        </span>
      ) : null}
      {copy}
      {children}
    </span>
  );
};

export default Tag;

Tag.propTypes = {
  children: PropTypes.node,
  copy: PropTypes.string.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  tagTheme: PropTypes.string,
  tagVariant: PropTypes.string,
};

Tag.defaultProps = {
  className: '',
  children: null,
  icon: null,
  tagTheme: TagTheme.DEFAULT,
  tagVariant: TagVariant.DEFAULT,
};
