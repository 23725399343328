import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { isFunction, flow } from 'lodash';

import { isCreativeProcessed, CreativeTypes, getCreativeTypeIcon } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';

import LazyRender from 'components/common/LazyRender';
import Icon, { IconTypes, IconColors, IconSizes } from 'assets/components/presentational/Icon';
import Input from 'components/patterns/Input';
import WeightIndicator from 'assets/components/presentational/Campaign/Playlists/WeightIndicator';

import Row from 'assets/components/presentational/Rows/Row';
import Thumbnail from 'assets/components/presentational/Rows/Thumbnail';
import Details from 'assets/components/presentational/Rows/Details';
import Actions from 'assets/components/presentational/Rows/Actions';
import Action from 'assets/components/presentational/Rows/Action';

import style from './playlistCreativeRow.scss';

class PlaylistCreativeRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      tempWeight: this.props.weight,
      shouldForceUpdateTempWeight: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { weight } = this.props;
    const { shouldForceUpdateTempWeight } = this.state;

    if (prevProps.weight !== weight || shouldForceUpdateTempWeight) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tempWeight: weight,
        shouldForceUpdateTempWeight: false,
      });
    }
  }

  handleOnInputFocus = () => {
    this.setState({
      tempWeight: '',
    });
  };

  handleOnInputChange = (value) => {
    if (value.length > 2) return;
    this.setState({
      tempWeight: value < 1 ? '' : value,
    });
  };

  handleOnInputBlur = () => {
    const { creative, onHandlePercentInputChange, weight } = this.props;
    const { tempWeight } = this.state;

    if (tempWeight === '') {
      this.setState({
        tempWeight: weight,
      });
      return;
    }

    this.setState({
      shouldForceUpdateTempWeight: true,
    });

    onHandlePercentInputChange(creative.get('id'), tempWeight);
  };

  handleOnInputKeyPress = (event) => {
    const { creative, onHandlePercentInputChange, weight } = this.props;
    const { tempWeight } = this.state;

    if (event.key === 'Enter') {
      if (tempWeight === '') {
        this.setState({
          tempWeight: weight,
        });
        return;
      }
      onHandlePercentInputChange(creative.get('id'), tempWeight);
    }
  };

  renderProgress() {
    const { creative } = this.props;
    const isBackgroundProcessing = !isCreativeProcessed(creative);

    if (isBackgroundProcessing) {
      return (
        <div className={style.loader}>
          <ProgressBar circular />;
        </div>
      );
    }
  }

  renderThumbnail() {
    const { creative, creativeUrl, imageFallbackSrc, isViewable } = this.props;
    const src =
      [CreativeTypes.IMAGE, CreativeTypes.VIDEO].includes(creative.get('type')) && creative.get('thumbnail')
        ? creative.get('thumbnail').get('url')
        : null;

    return (
      <Thumbnail
        key={src}
        src={src || imageFallbackSrc}
        alt={`Creative: ${creative.get('name', '')}`}
        link={isViewable ? creativeUrl : null}
      />
    );
  }

  renderLockAction = () => {
    const { creative, locked, showLock, onHandleCreativeWeightLock } = this.props;

    if (!showLock) {
      return <div className={style.actionLock} />;
    }

    return (
      <div className={locked ? style.actionLocked : style.actionLock}>
        <Action
          icon={locked ? <Icon iconType={IconTypes.LOCK_OUTLINE} /> : <Icon iconType={IconTypes.LOCK_OPEN} />}
          onClick={() => onHandleCreativeWeightLock(creative.get('id'))}
        />
      </div>
    );
  };

  renderRow() {
    const { creative, isDraggable, isDragging, isOver, weight, locked, color, position, onLoad } = this.props;
    const { tempWeight } = this.state;

    const rowClass = `
      ${style.creativeRow}
      ${isDraggable ? style.draggable : ''}
      ${isOver ? style.over : ''}
      ${isDragging ? style.dragging : ''}
    `;

    const placeholder = <div className={style.creativeRowPlaceholder} />;

    return (
      <LazyRender placeholder={placeholder} onLoad={onLoad}>
        <Row className={rowClass}>
          <div className={style.position}>
            <span>{position + 1}</span>
            <div>
              <Icon iconType={IconTypes.OPEN_WITH} color={IconColors.WHITE} size={IconSizes.SMALL} />
            </div>
          </div>
          {this.renderProgress()}
          {this.renderThumbnail()}
          <Details className={style.detailsRow}>
            <div className={style.creativeNameRow}>
              <span className={style.creativeName}>
                <span className={style.creativeTypeIcon}>
                  <Icon iconType={getCreativeTypeIcon(creative.get('type'))} />
                </span>
                <span>
                  <strong>{creative.get('name')}</strong>
                </span>
              </span>
            </div>
            <div className={style.weightIndicator}>
              <WeightIndicator weight={weight || 0} color={color} />
            </div>
          </Details>
          <Actions className={style.actions}>
            <span className={style.weightInput}>
              <Input
                className={style.weightInputField}
                type="number"
                disabled={locked}
                value={tempWeight}
                onFocus={this.handleOnInputFocus}
                onBlur={this.handleOnInputBlur}
                onChange={this.handleOnInputChange}
                onKeyPress={this.handleOnInputKeyPress}
              />
              <span className={!locked ? style.weightPercent : style.weightPercentLocked}>%</span>
            </span>
            {this.renderLockAction()}
          </Actions>
        </Row>
      </LazyRender>
    );
  }

  renderRowContainer(children) {
    const { className, connectDragSource, connectDropTarget, isDraggable } = this.props;

    // react dnd needs to be passed a native component
    const wrappedChildren = <div className={className}>{children}</div>;

    return isDraggable ? connectDropTarget(connectDragSource(wrappedChildren)) : wrappedChildren;
  }

  render() {
    const { color } = this.props;

    const row = this.renderRow();

    return (
      <div className={style.rowContainer} style={{ borderColor: color }}>
        {this.renderRowContainer(row)}
      </div>
    );
  }
}

// START: DRAG AND DROP
const ItemTypes = { CREATIVE: 'creative' };

const creativeSource = {
  beginDrag: (props) => ({
    creativeId: props.creative.get('id'),
  }),
};

const targetCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

const sourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const creativeTarget = {
  drop: (props, monitor) => {
    const dragId = monitor.getItem().creativeId;
    const hoverId = props.creative.get('id');

    // Don't replace items with themselves
    if (dragId === hoverId) {
      return;
    }

    // Time to actually perform the action
    if (isFunction(props.onMoved)) {
      props.onMoved(dragId, hoverId);
    }

    monitor.getItem().creativeId = hoverId;
  },
};
// END: DRAG AND DROP

export default flow(
  DropTarget(ItemTypes.CREATIVE, creativeTarget, targetCollect),
  DragSource(ItemTypes.CREATIVE, creativeSource, sourceCollect),
  withNamespaces(['common', 'campaignPlaylists'], { wait: false }),
)(PlaylistCreativeRow);

PlaylistCreativeRow.propTypes = {
  className: PropTypes.string.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  imageFallbackSrc: PropTypes.string,
  isDraggable: PropTypes.bool,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  isViewable: PropTypes.bool,
  onLoad: PropTypes.func,
  creativeUrl: PropTypes.string,
  onMoved: PropTypes.func,
  weight: PropTypes.number,
  position: PropTypes.number,
  locked: PropTypes.bool,
  showLock: PropTypes.bool,
  color: PropTypes.string,
  onHandlePercentInputChange: PropTypes.func.isRequired,
  onHandleCreativeWeightLock: PropTypes.func.isRequired,
  t: PropTypes.func,
};

PlaylistCreativeRow.defaultProps = {
  className: '',
  imageFallbackSrc: '',
  isDraggable: false,
  isViewable: false,
};
