import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';
import * as at from 'actions/campaign/creative/preview';

const getInitialState = () => ({
  creative: new Map(),
  creatives: new List(),
  isCheckingModeration: false,
  isFetching: false,
  isFetchingContentTypes: false,
  isFetchingModerationSummary: false,
  isFetchingRuleSets: false,
  isInitialFetch: true,
  isFetchingAllCreatives: false,
  isRecalculatingModeration: false,
  isSaving: false,
  moderationSummary: List([]),
  userCanModerate: false,
});

export default handleActions(
  {
    [at.FETCH_REQUEST]: (state) => ({
      ...state,
      creative: state.isInitialFetch ? state.creative.clear() : state.creative,
      isFetching: true,
    }),
    [at.FETCH_SUCCESS]: (state, action) => ({
      ...state,
      creative: state.creative.clear().merge(fromJS(action.creative)),
      isInitialFetch: false,
    }),
    [at.FETCH_COMPLETE]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [at.FETCH_ALL_CREATIVES_REQUEST]: (state) => ({
      ...state,
      creatives: state.creatives.clear(),
      isFetchingAllCreatives: true,
    }),
    [at.FETCH_ALL_CREATIVES_SUCCESS]: (state, action) => ({
      ...state,
      creatives: state.creatives.clear().merge(fromJS(action.creatives)),
    }),
    [at.FETCH_ALL_CREATIVES_COMPLETE]: (state) => ({
      ...state,
      isFetchingAllCreatives: false,
    }),
    [at.UPDATE_REQUEST]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [at.UPDATE_SUCCESS]: (state, action) => ({
      ...state,
      creative: state.creative.merge(fromJS(action.creative)),
    }),
    [at.UPDATE_COMPLETE]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [at.UPDATE_MODERATION_STATUS]: (state, action) => ({
      ...state,
      creative: state.creative.set('moderation-status', action.status),
    }),
    [at.SYNC_REQUEST]: (state) => ({
      ...state,
      isSaving: true,
    }),
    [at.SYNC_SUCCESS]: (state, action) => ({
      ...state,
      creative: state.creative.set(
        action.relation,
        fromJS(action.ids).map((id) => new Map({ id })),
      ),
    }),
    [at.SYNC_COMPLETE]: (state) => ({
      ...state,
      isSaving: false,
    }),
    [at.MERGE_CREATIVE]: (state, action) => ({
      ...state,
      creative: state.creative.merge(fromJS(action.creative)),
    }),
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingRuleSets: action.relation === 'rule-sets' ? true : state.isFetchingRuleSets,
      isFetchingContentTypes: action.relation === 'content-types' ? true : state.isFetchingContentTypes,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingRuleSets: action.relation === 'rule-sets' ? false : state.isFetchingRuleSets,
      isFetchingContentTypes: action.relation === 'content-types' ? false : state.isFetchingContentTypes,
    }),
    [at.CHECK_MODERATION_REQUEST]: (state, action) => ({
      ...state,
      isCheckingModeration: action.creativeId === state.creative.get('id') ? true : state.isCheckingModeration,
    }),
    [at.CHECK_MODERATION_SUCCESS]: (state, action) => ({
      ...state,
      userCanModerate: action.creativeId === state.creative.get('id') ? true : state.userCanModerate,
    }),
    [at.CHECK_MODERATION_FAILURE]: (state, action) => ({
      ...state,
      userCanModerate: action.creativeId === state.creative.get('id') ? false : state.userCanModerate,
    }),
    [at.CHECK_MODERATION_COMPLETE]: (state, action) => ({
      ...state,
      isCheckingModeration: action.creativeId === state.creative.get('id') ? false : state.isCheckingModeration,
    }),
    [at.SAVE_MODERATION_REQUEST]: (state, action) => ({
      ...state,
      isSaving: action.creativeId === state.creative.get('id') ? true : state.isSaving,
    }),
    [at.SAVE_MODERATION_SUCCESS]: (state, action) => ({
      ...state,
      creative:
        action.creativeId === state.creative.get('id') ? state.creative.merge(fromJS(action.creative)) : state.creative,
      userCanModerate: false,
    }),
    [at.SAVE_MODERATION_COMPLETE]: (state, action) => ({
      ...state,
      isSaving: action.creativeId === state.creative.get('id') ? false : state.isSaving,
    }),
    [at.FETCH_MODERATION_SUMMARY_REQUEST]: (state) => ({
      ...state,
      isFetchingModerationSummary: true,
    }),
    [at.FETCH_MODERATION_SUMMARY_COMPLETE]: (state) => ({
      ...state,
      isFetchingModerationSummary: false,
    }),
    [at.FETCH_MODERATION_SUMMARY_SUCCESS]: (state, action) => ({
      ...state,
      moderationSummary: fromJS(action.moderationSummary),
    }),
    [at.RECALCULATE_MODERATION_SUMMARY_REQUEST]: (state) => ({
      ...state,
      isRecalculatingModeration: true,
    }),
    [at.RECALCULATE_MODERATION_SUMMARY_COMPLETE]: (state) => ({
      ...state,
      isRecalculatingModeration: false,
    }),
    [at.CLEAR]: () => getInitialState(),
  },
  getInitialState(),
);
