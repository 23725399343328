import { handleActions } from 'redux-actions';
import {
  BOOKINGS_STORE,
  BOOKING_ADD,
  BOOKING_REMOVE,
  BOOKINGS_PROMOTE,
  BOOKING_DCM_SUCCESS,
  BOOKING_DCM_FAILED,
} from 'store/bookings/actions';

const initialState = [];

const isSameBooking = (a, b) => a.id === b.id && a.network === b.network;
const ERROR_DEFAULT_CREATIVE = 'default_creative';
const ERRORS_BLOCKING_SEND = [ERROR_DEFAULT_CREATIVE];

const getHasErrors = (booking) => booking['booking-errors'] && booking['booking-errors'].length > 0;
const getHasErrorsBlockingSend = (booking) =>
  booking['booking-errors'] && booking['booking-errors'].some((error) => ERRORS_BLOCKING_SEND.includes(error.code));

export const STAT_PROMOTED = 'promoted';
export const STAT_UNPROMOTED = 'unpromoted';
export const STAT_PROMOTING = 'promoting';
export const STAT_ERROR = 'error';

const getSummaryCategory = (remoteStatus) => {
  if (!remoteStatus) {
    return STAT_ERROR;
  }

  if (remoteStatus.isCompleted) {
    return STAT_PROMOTED;
  }

  if (remoteStatus.hasErrors || remoteStatus.isFailed) {
    return STAT_ERROR;
  }

  if (remoteStatus.isInProgress) {
    return STAT_PROMOTING;
  }

  return STAT_UNPROMOTED;
};

export function getWithRemoteStatus(booking) {
  const hasErrors = getHasErrors(booking);
  const hasBlockingErrors = getHasErrorsBlockingSend(booking);

  const remoteStatus = {
    canSend: booking['can-send-dcm'] && !booking['is-dcm-in-progress'] && !booking['is-dcm-completed'],
    isCompleted: booking['is-dcm-completed'],
    isInProgress: booking['is-dcm-in-progress'],
    isFailed: booking['dcm-failed'],
    sentAt: booking['dcm-sent-at'],
    sentBy: booking['dcm-user'],
  };

  return {
    ...booking,
    'remote-status': { ...remoteStatus, hasErrors, hasBlockingErrors },
    'summary-category': getSummaryCategory(remoteStatus),
  };
}

export default handleActions(
  {
    [BOOKINGS_STORE]: (bookings, action) =>
      (action.payload.bookings || []).map((booking) => getWithRemoteStatus(booking)),
    [BOOKING_ADD]: (bookings, action) => [...bookings, getWithRemoteStatus(action.payload.booking)],
    [BOOKING_REMOVE]: (bookings, action) => {
      const bookingToRemove = action.payload.booking;
      return bookings.filter((booking) => !isSameBooking(booking, bookingToRemove));
    },
    [BOOKINGS_PROMOTE]: (bookings, action) =>
      bookings.map((booking) => {
        const bookingsToPromote = action.payload.bookings;
        const isBookingAmongBookingsToPromote = bookingsToPromote.find((btp) => isSameBooking(booking, btp));
        if (isBookingAmongBookingsToPromote) {
          return getWithRemoteStatus({
            ...booking,
            'is-dcm-in-progress': true,
            'is-dcm-completed': false,
            'booking-errors': [],
          });
        }
        return getWithRemoteStatus(booking);
      }),
    [BOOKING_DCM_SUCCESS]: (bookings, action) =>
      bookings.map((booking) =>
        getWithRemoteStatus(
          isSameBooking(booking, action.payload.booking)
            ? {
                ...booking,
                'is-dcm-in-progress': false,
                'is-dcm-completed': true,
              }
            : booking,
        ),
      ),
    [BOOKING_DCM_FAILED]: (bookings, action) =>
      bookings.map((booking) =>
        getWithRemoteStatus(
          isSameBooking(booking, action.payload.booking)
            ? {
                ...booking,
                'is-dcm-in-progress': false,
                'is-dcm-completed': false,
                'dcm-failed': true,
              }
            : booking,
        ),
      ),
  },
  initialState,
);
