import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authenticateUser } from 'actions/liteModeration';
import AuthLiteLiteModeration from './AuthLiteLiteModeration';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authenticateUser,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AuthLiteLiteModeration);
