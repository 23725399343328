import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import cx from 'classnames';
import { getRuleIcon } from 'modules/Helpers';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Popup from 'assets/components/presentational/Campaign/ContentSchedule/Popup';
import style from './slot.scss';

class ScheduleSlot extends Component {
  handleSlotClick = () => {
    const { handleSlotClick, day, slotNumber } = this.props;
    handleSlotClick(day.get('dayNum'), slotNumber);
  };

  renderSlotInfo() {
    const { day, slot } = this.props;

    const dayPos = day.get('position');
    const dayNum = day.get('dayNum');
    const slotPos = slot.get('position');
    const isoWd = day.get('isoWeekday');

    const showInfo =
      slotPos === 'start' ||
      slotPos === 'dayofweek' ||
      isoWd === 1 ||
      (dayPos === 'middle' && dayNum === 1) ||
      (dayPos === 'start' && slotPos === 'end') ||
      dayPos === 'start' ||
      dayPos === 'single';

    if (!showInfo) {
      return null;
    }

    return (
      <div className={style.slotInfo}>
        <div className={style.meta}>
          <span className={style.icons}>
            {slot.get('id') === 'default' && <Icon iconType={IconTypes.GRADE} />}
            {slot.get('rules') &&
              slot.get('rules').map((rule, key) => <Icon key={key} iconType={getRuleIcon(rule.get('type'))} />)}
          </span>
          <span className={style.copy}>{slot.get('name')}</span>
        </div>
      </div>
    );
  }

  render() {
    const { campaignId, day, slot, slotNumber, activePopup, campaignPosition, handleClosePopup } = this.props;

    let showPopup = false;

    if (
      activePopup &&
      day.get('dayNum') === activePopup.get('dayNum') &&
      slotNumber === activePopup.get('slotNumber')
    ) {
      showPopup = true;
    }

    const position = slot.get('position');
    const slotClasess = cx(style.day, {
      [style.slot]: true,
      [style.slotStart]: position === 'start' || campaignPosition === 'start',
      [style.slotMid]: position === 'middle',
      [style.slotEnd]: position === 'end' || campaignPosition === 'end',
      [style.slotSingle]: slot.get('isSingle') || campaignPosition === 'single',
      [style.dayOfWeek]: position === 'dayofweek',
      [style.isConditional]: slot.get('isConditional'),
    });

    return (
      <div className={style.wrapper}>
        <div>
          <div
            className={slotClasess}
            onClick={this.handleSlotClick}
            style={{ backgroundColor: slot.get('backgroundColor') }}
          >
            {this.renderSlotInfo()}
          </div>
          <Popup day={day} slot={slot} campaignId={campaignId} active={showPopup} handleClosePopup={handleClosePopup} />
        </div>
      </div>
    );
  }
}

export default ScheduleSlot;

ScheduleSlot.propTypes = {
  campaignId: PropTypes.string.isRequired,
  day: PropTypes.instanceOf(Map).isRequired,
  slot: PropTypes.instanceOf(Map).isRequired,
  slotNumber: PropTypes.number.isRequired,
  activePopup: PropTypes.instanceOf(Map),
  campaignPosition: PropTypes.string.isRequired,
  handleSlotClick: PropTypes.func.isRequired,
  handleClosePopup: PropTypes.func.isRequired,
};
