import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import { toMonthDateYear } from 'modules/Helpers/dates';

import Table from 'assets/components/presentational/Table';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';

import styles from './BookingsTable.scss';

const cx = classNames.bind(styles);

const propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      'external-id': PropTypes.string,
      advertiser: PropTypes.string,
      brand: PropTypes.string,
      'business-unit-id': PropTypes.string,
      'build-url': PropTypes.string,
      'starts-at': PropTypes.string,
      'ends-at': PropTypes.string,
      'pushed-build-identifier': PropTypes.string,
    }),
  ).isRequired,
  businessUnits: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const defaultProps = {};

class BookingsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableSelectedIndexes: [],
    };
  }

  onSelectItem = (tableSelectedIndexes) =>
    this.setState({
      tableSelectedIndexes,
    });

  onChange = () => {
    const { bookings, onChange } = this.props;
    const { tableSelectedIndexes } = this.state;

    const remainingItemsAfterDeletion = bookings.filter((item, index) => !tableSelectedIndexes.includes(index));
    onChange(remainingItemsAfterDeletion);
    this.clearSelectedItems();
  };

  clearSelectedItems = () => this.setState({ tableSelectedIndexes: [] });

  render() {
    const { bookings } = this.props;
    if (!bookings.length) {
      return null;
    }

    const { t, businessUnits } = this.props;
    const { tableSelectedIndexes } = this.state;

    const tableModel = {
      [t('Name')]: { type: String, title: 'Name' },
      [t('Reservation Id')]: { type: String, title: 'Reservation Id' },
      [t('Advertiser')]: { type: String, title: 'Advertiser' },
      [t('Brand')]: { type: String, title: 'Brand' },
      [t('Starts')]: { type: String, title: 'Starts' },
      [t('Ends')]: { type: String, title: 'Ends' },
      [t('Business Unit')]: { type: String, title: 'Business Unit' },
      [t('Build')]: { type: String, title: 'Build' },
      [t('Ad Copy Id')]: { type: String, title: 'Ad Copy Id' },
    };

    const tableSource = bookings.map((booking) => ({
      [t('Name')]: booking.name,
      [t('Reservation Id')]: booking['external-id'],
      [t('Advertiser')]: booking.advertiser,
      [t('Brand')]: booking.brand,
      [t('Starts')]: toMonthDateYear(booking['starts-at']),
      [t('Ends')]: toMonthDateYear(booking['ends-at']),
      [t('Business Unit')]: Array.isArray(businessUnits)
        ? businessUnits.find((bu) => bu.id === booking['business-unit-id']).name
        : booking['business-unit-id'],
      [t('Build')]: booking['build-url'] ? <a href={booking['build-url']}>{t('Download')}</a> : '',
      [t('Ad Copy Id')]: booking['pushed-build-identifier'] || '',
    }));

    const buttonLabel = t('Remove selected booking', { count: tableSelectedIndexes.length });

    return (
      <div className={cx('component')}>
        <Table
          model={tableModel}
          source={tableSource}
          onSelect={this.onSelectItem}
          multiSelectable
          selectable
          selected={tableSelectedIndexes}
        />
        <div className={cx('button')}>
          <Button
            label={buttonLabel}
            type="button"
            onClick={this.onChange}
            theme={ButtonThemes.PRIMARY}
            disabled={!tableSelectedIndexes.length}
            tabIndex="-1"
          />
        </div>
      </div>
    );
  }
}

BookingsTable.propTypes = propTypes;
BookingsTable.defaultProps = defaultProps;

const withTranslation = withNamespaces(['common', 'campaignForm'], { wait: false })(BookingsTable);

export default withTranslation;
export { BookingsTable };
