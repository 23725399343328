import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import Resumable from 'resumablejs';
import { v4 } from 'uuid';
import { withNamespaces } from 'react-i18next';

import Request from 'modules/API/request';
import { API_EXCEPTION_AUTHJWTEXPIRED, API_EXCEPTION_AUTHEXPIRED } from 'modules/API/constants';

import { uploadsPropTypes } from 'store/uploads/propTypes';

import { addUpload, updateUpload, removeUpload, clearUploads } from 'store/uploads/actions';
import { refresh } from 'actions/auth';
import { error as notifyError } from 'actions/notify';

import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Dialog from 'assets/components/presentational/Dialog';

import style from './Upload.scss';

class Upload extends Component {
  state = {
    showDialog: false,
    files: [],
    isProcessing: false,
  };

  constructor(props, context) {
    super(props, context);
    const { id } = props;

    this.id = id || v4();
    this.setupResumable();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearUploads(this.id));

    this.resumable.events = [];
  }

  setupResumable = () => {
    const { target, query } = this.props;

    this.resumable = new Resumable({
      target,
      query,
      headers: Request.mergeAuthorizationHeader({ Accept: 'application/vnd.api+json' }),
      maxChunkRetries: 3,
      testChunks: true,
      generateUniqueIdentifier: () => v4(),
    });
    // Unfortunately Resumable exposes defaults as an instance property only
    this.resumable.opts.permanentErrors = [...this.resumable.defaults.permanentErrors, 405];

    this.resumable.on('fileAdded', this.handleFileAdded);
    this.resumable.on('fileProgress', this.handleFileProgress);
    this.resumable.on('fileSuccess', this.handleFileSuccess);
    this.resumable.on('fileError', this.handleFileError);
    this.resumable.on('complete', this.handleUploadComplete);
  };

  updateResumableOptions = (jwt) => {
    this.resumable.opts.headers = Request.mergeAuthorizationHeader({ Accept: 'application/vnd.api+json' }, jwt);
  };

  getUploadsForThisInstance = () => {
    const { uploads } = this.props;
    return uploads.filter((upload) => upload.instanceId === this.id);
  };

  // TODO: Move this into Reponse class
  static tryParseResponse(response) {
    let parsedResponse;

    try {
      parsedResponse = JSON.parse(response);
    } catch (error) {
      parsedResponse = null;
    }

    return parsedResponse;
  }

  handleFileAdded = (file) => {
    const { dispatch } = this.props;
    const { uniqueIdentifier, fileName } = file;
    dispatch(
      addUpload(uniqueIdentifier, {
        instanceId: this.id,
        fileName,
      }),
    );
    this.resumable.upload();
  };

  handleFileProgress = (file) => {
    const { dispatch, onProgress } = this.props;
    const { uniqueIdentifier, progress } = file;
    dispatch(
      updateUpload(uniqueIdentifier, {
        progress: progress() * 100,
      }),
    );
    onProgress(file);
  };

  handleFileSuccess = (file, response) => {
    const { onSuccess, dispatch } = this.props;
    const { uniqueIdentifier } = file;
    this.resumable.removeFile(file);
    dispatch(
      updateUpload(uniqueIdentifier, {
        progress: 100,
      }),
    );

    const parsedResponse = Upload.tryParseResponse(response);
    if (parsedResponse) {
      onSuccess(file, parsedResponse.data);
    } else {
      this.resumable.removeFile(file);
      dispatch(
        updateUpload(uniqueIdentifier, {
          errors: [{ message: 'Failed to parse response' }],
        }),
      );
      const { onFail } = this.props;
      onFail([{ message: 'Failed to parse response' }], file);
    }
  };

  handleFileError = async (file, response) => {
    const { dispatch, onFail } = this.props;
    const parsedResponse = Upload.tryParseResponse(response);
    if (!parsedResponse) {
      onFail([{ message: 'Failed to parse response' }], file);
      return;
    }

    const hasErrors = parsedResponse.errors.length;
    if (hasErrors) {
      const isTokenExpired =
        parsedResponse.errors[0].code === API_EXCEPTION_AUTHJWTEXPIRED ||
        parsedResponse.errors[0].code === API_EXCEPTION_AUTHEXPIRED;

      if (isTokenExpired) {
        try {
          const jwt = await refresh();
          this.updateResumableOptions(jwt);
          file.retry();
        } catch (error) {
          parsedResponse.errors = [...parsedResponse.errors, error];
        }
      } else {
        const { uniqueIdentifier } = file;
        this.resumable.removeFile(file);
        dispatch(
          updateUpload(uniqueIdentifier, {
            errors: parsedResponse.errors,
          }),
        );
        onFail(parsedResponse.errors, file);
      }
    }
  };

  handleUploadComplete = () => {
    this.setState({ isProcessing: true });
  };

  handleDrop = (files = []) => {
    const { confirmDrop } = this.props;
    return confirmDrop ? this.openConfirmationDialog(files) : this.addFiles(files);
  };

  openConfirmationDialog = (files) => {
    this.setState({
      showDialog: true,
      files,
    });
  };

  closeConfirmationDialog = () => {
    this.setState({
      showDialog: false,
      files: [],
    });
  };

  handleDialogConfirmClick = () => {
    const { files } = this.state;
    this.addFiles(files);
    this.closeConfirmationDialog();
  };

  handleDialogCancelClick = () => {
    this.closeConfirmationDialog();
  };

  addFiles = (files) => {
    const {
      resumableJS: { maxFiles },
    } = config;
    const { dispatch, t } = this.props;

    if (files.length > maxFiles) {
      dispatch(notifyError(t('Please upload a maximum of {{count}} files at a time', { count: maxFiles })));
      return;
    }

    this.setState({ isProcessing: false });
    dispatch(clearUploads(this.id));
    this.resumable.addFiles(files);

    const { onAddFiles } = this.props;
    onAddFiles(files);
  };

  dismissErrors = () => {
    const { dispatch } = this.props;
    this.getUploadsForThisInstance().forEach((upload) => dispatch(removeUpload(upload.uniqueIdentifier)));
  };

  renderFailure(numberOfUploadsWithErrors) {
    const { children, t } = this.props;

    return (
      <button className={style.errorButton} onClick={this.dismissErrors} type="button">
        <div className={style.component}>
          {children}
          <div className={`${style.content} ${style.contentError}`}>
            <span className={style.contentIcon}>
              <Icon iconType={IconTypes.ERROR_OUTLINE} />
            </span>
            <div>
              {t('{{count}} files failed to upload', {
                count: numberOfUploadsWithErrors,
              })}
              <br />
              {t('Click to dismiss')}
            </div>
          </div>
        </div>
      </button>
    );
  }

  renderUploading(progress = 0) {
    const { children, t } = this.props;

    return (
      <div className={style.component}>
        {children}
        <div className={`${style.content} ${style.contentUploading}`}>
          <div className={style.progress} style={{ width: `${Math.floor(progress)}%` }} />
          <div className={style.progressText}>{t('Uploading your file(s)', { progress })}</div>
        </div>
      </div>
    );
  }

  renderProcessing() {
    const { children } = this.props;

    return (
      <div className={style.component}>
        {children}
        <div className={`${style.content} ${style.contentUploading}`}>
          <div className={style.progressText}>Processing your file(s)</div>
        </div>
      </div>
    );
  }

  renderUpload() {
    const { children, multiple, allowFileBrowsing, dropzoneRef, t } = this.props;

    return (
      <Dropzone
        ref={(node) => dropzoneRef(node)}
        activeClassName={style.isDropping}
        className={style.component}
        multiple={multiple}
        onDrop={this.handleDrop}
        disableClick={!allowFileBrowsing}
      >
        {children}
        {this.renderDialog()}
        {allowFileBrowsing && (
          <div className={`${style.content} ${style.fileBrowsing}`}>
            <span className={style.contentIcon}>
              <Icon iconType={IconTypes.ADD} />
            </span>
            <span>{t('Drop your file(s) here or select')}</span>
          </div>
        )}
        <div className={`${style.content} ${style.fileDropping}`}>
          <span className={style.contentIcon}>
            <Icon iconType={IconTypes.ARROW_DOWNWARD} />
          </span>
          <span>{t('Drop your file(s) here')}</span>
        </div>
      </Dropzone>
    );
  }

  renderDialog() {
    const { confirmDropText, t } = this.props;
    const { showDialog } = this.state;

    return (
      <Dialog
        actions={[
          { label: t('Cancel'), onClick: this.handleDialogCancelClick },
          { label: t('Confirm'), onClick: this.handleDialogConfirmClick },
        ]}
        active={showDialog}
      >
        {confirmDropText}
      </Dialog>
    );
  }

  render() {
    const { isCheckingAuth } = this.props;
    const uploads = this.getUploadsForThisInstance();
    const numberOfUploadsWithErrors = uploads.filter((upload) => upload.errors.length).length;
    if (numberOfUploadsWithErrors > 0) {
      return this.renderFailure(numberOfUploadsWithErrors);
    }

    if (uploads.length) {
      if (isCheckingAuth) {
        return this.renderUploading();
      }

      const progress = uploads.reduce((total, upload) => total + upload.progress || 0, 0) / uploads.length;

      const { isProcessing } = this.state;
      return isProcessing ? this.renderProcessing() : this.renderUploading(progress);
    }

    return this.renderUpload();
  }
}

const mapStateToProps = (state) => ({
  uploads: state.uploads,
  isCheckingAuth: state.auth.isChecking,
});

export default withNamespaces(['common', 'upload'], { wait: false })(connect(mapStateToProps)(Upload));

Upload.propTypes = {
  uploads: uploadsPropTypes.isRequired,
  isCheckingAuth: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  children: PropTypes.node,
  allowFileBrowsing: PropTypes.bool,
  multiple: PropTypes.bool,
  confirmDrop: PropTypes.bool,
  confirmDropText: PropTypes.string,
  target: PropTypes.string.isRequired,
  query: PropTypes.shape({}),
  dropzoneRef: PropTypes.func,
  onAddFiles: PropTypes.func,
  onSuccess: PropTypes.func,
  onProgress: PropTypes.func,
  onFail: PropTypes.func,
  t: PropTypes.func.isRequired,
};

Upload.defaultProps = {
  id: null,
  children: null,
  multiple: true,
  allowFileBrowsing: true,
  confirmDrop: false,
  confirmDropText: '',
  query: {},
  onAddFiles: () => {},
  onSuccess: () => {},
  onProgress: () => {},
  onFail: () => {},
  dropzoneRef: () => {},
};
