import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import RecapItem, { VARIANTS } from './RecapItem';

import style from './Recap.scss';

const Recap = ({ promotedTotal, promotingTotal, unpromotedTotal, haveErrorsTotal, t }) => (
  <div className={style.component}>
    <RecapItem total={unpromotedTotal} label={t('Unpromoted')} variant={VARIANTS.NEUTRAL} />
    <RecapItem total={promotingTotal} label={t('in_progress')} variant={VARIANTS.PENDING} />
    <RecapItem total={promotedTotal} label={t('promoted')} variant={VARIANTS.PROMOTED} />
    <RecapItem total={haveErrorsTotal} label={t('need_attention')} variant={VARIANTS.ERROR} />
  </div>
);

export default withNamespaces(['common', 'bookings'], { wait: false })(Recap);

Recap.propTypes = {
  promotedTotal: PropTypes.number.isRequired,
  promotingTotal: PropTypes.number.isRequired,
  unpromotedTotal: PropTypes.number.isRequired,
  haveErrorsTotal: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
