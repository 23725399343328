import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

import Recent from './Recent';

const mapStateToProps = (state) => ({
  userId: state.auth.user.get('id'),
});

export default withNamespaces(['dashboardNew'], { wait: false })(withRouter(connect(mapStateToProps)(Recent)));
