import EchoService from './EchoService';

let service;

export function privateUserChannel(userId) {
  return `private-App.User.${userId}`;
}

export function presenceCampaignRequestChannel(campaignId) {
  return `App.Campaign.${campaignId}`;
}

export function privateCampaignChannel(campaignId) {
  return `private-App.Campaign.${campaignId}`;
}

export function publicSystemChannel() {
  return 'App.System';
}

export const BROADCAST_EVENTS = {
  CREATIVE_UPLOAD_SUCCESSFUL: 'App\\Events\\CreativeUploadSucceeded',
  CREATIVE_UPLOAD_FAILED: 'App\\Events\\CreativeUploadFailed',
  BUILD_COMPLETED: 'App\\Events\\Build\\BuildCompleted',
  BUILD_FAILED: 'App\\Events\\Build\\BuildFailed',
  CREATIVE_MODERATION_DECISION_MADE: 'App\\Events\\CreativeModeration\\DecisionMade',
  CREATIVE_MODERATION_USER_INDEXED: 'App\\Events\\CreativeModeration\\UserIndexed',
  CREATIVE_MODERATION_CAMPAIGN_INDEXED: 'App\\Events\\CreativeModeration\\CampaignIndexed',
  BOOKING_PROMOTION_COMPLETED: 'App\\Events\\Booking\\Promotion\\BookingPromotionCompleted',
  BOOKING_PROMOTION_FAILED: 'App\\Events\\Booking\\Promotion\\BookingPromotionFailed',
  BOOKING_DCM_COMPLETED: 'App\\Events\\Booking\\Promotion\\BookingDcmCompleted',
  BOOKING_DCM_FAILED: 'App\\Events\\Booking\\Promotion\\BookingDcmFailed',
  DEPLOY_IN_PROGRESS: 'App\\Events\\System\\Deploy\\DeployStart',
  DEPLOY_COMPLETE: 'App\\Events\\System\\Deploy\\DeployComplete',
  BROADSIGN_ENABLED: 'App\\Events\\System\\Broadsign\\BroadsignEnabled',
  BROADSIGN_DISABLED: 'App\\Events\\System\\Broadsign\\BroadsignDisabled',
  PUBLISH_STATUS_UPDATED: 'App\\Events\\Campaign\\CampaignPublishingStatusChanged',
};

export function broadcasting() {
  if (service) {
    return service;
  }

  service = new EchoService();

  return service;
}
