import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import style from './TimelineItem.scss';

const TimelineItem = ({ badge, title, details }) => (
  <li className={style.gridContainer}>
    <div className={style.leftColumn}>{badge}</div>
    <div className={style.timelineDetails}>
      {title}
      {details}
    </div>
  </li>
);

TimelineItem.propTypes = {
  badge: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
  details: PropTypes.element.isRequired,
};

export default withNamespaces(['common', 'publish'], { wait: false })(TimelineItem);
