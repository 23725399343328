export const ADD_UPLOAD = 'ADD_UPLOAD';
export const UPDATE_UPLOAD = 'UPDATE_UPLOAD';
export const REMOVE_UPLOAD = 'REMOVE_UPLOAD';
export const CLEAR_UPLOADS = 'CLEAR_UPLOADS';
export const RESET_UPLOADS = 'RESET_UPLOADS';

export const addUpload = (uniqueIdentifier, { fileName = '', progress = 0, errors = [], ...rest }) => ({
  type: ADD_UPLOAD,
  upload: {
    uniqueIdentifier,
    fileName,
    progress,
    errors,
    ...rest,
  },
});

export const updateUpload = (uniqueIdentifier, upload) => ({
  type: UPDATE_UPLOAD,
  uniqueIdentifier,
  upload: {
    uniqueIdentifier,
    ...upload,
  },
});

export const removeUpload = (uniqueIdentifier) => ({
  type: REMOVE_UPLOAD,
  uniqueIdentifier,
});

export const clearUploads = (instanceId) => ({
  type: CLEAR_UPLOADS,
  instanceId,
});

export const resetUploads = () => ({
  type: RESET_UPLOADS,
});
