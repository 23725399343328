import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
import * as at from 'actions/users';
import moment from 'moment';

const getInitialState = () => ({
  activeUser: new Map(),
  users: new List(),
  editableUser: new Map(),

  isFetchingUser: false,
  isFetchingUsers: false,
  isFetchingAllEditData: false,
  hasFetchedUsers: false,

  isUpdatingUser: false,
  isUpdatingPermissionsUser: false,

  isProfileDrawerShowing: false,
  isProfileEdited: false,
  isPermissionsProfileEdited: false,
  userCreateProfileEdited: false,

  filterUserTypeValue: '',
  filterSearchValue: '',

  pagination: new Map(),
});

export default handleActions(
  {
    [at.FETCH_ACTIVE_USER_REQUEST]: (state) => ({
      ...state,
      isFetchingUser: true,
    }),
    [at.FETCH_ACTIVE_USER_SUCCESS]: (state, action) => ({
      ...state,
      activeUser: action.activeUser,
      isFetchingUser: false,
    }),
    [at.FETCH_ACTIVE_USER_COMPLETE]: (state) => ({
      ...state,
      isFetchingUser: false,
    }),
    [at.FETCH_USERS_REQUEST]: (state) => ({
      ...state,
      isFetchingUsers: true,
    }),
    [at.FETCH_USERS_SUCCESS]: (state, action) => ({
      ...state,
      users: action.users,
      isFetchingUsers: false,
    }),
    [at.FETCH_USERS_COMPLETE]: (state) => ({
      ...state,
      isFetchingUsers: false,
      hasFetchedUsers: true,
    }),
    [at.FETCH_USER_EDIT_DATA_REQUEST]: (state) => ({
      ...state,
      isFetchingAllEditData: true,
    }),
    [at.FETCH_USER_EDIT_DATA_SUCCESS]: (state, action) => ({
      ...state,
      editableUser: action.userEditData.user,
    }),
    [at.FETCH_USER_EDIT_DATA_COMPLETE]: (state) => ({
      ...state,
      isFetchingAllEditData: false,
    }),
    [at.PROFILE_EDITED]: (state) => ({
      ...state,
      isProfileEdited: true,
    }),
    [at.UPDATE_ACTIVE_USER_REQUEST]: (state) => ({
      ...state,
      isUpdatingUser: true,
    }),
    [at.UPDATE_ACTIVE_USER_SUCCESS]: (state, action) => ({
      ...state,
      activeUser: action.user,
      isProfileEdited: false,
    }),
    [at.UPDATE_ACTIVE_USER_COMPLETE]: (state) => ({
      ...state,
      isUpdatingUser: false,
    }),
    [at.PROFILE_EDIT_CANCELLED]: (state) => ({
      ...state,
      isProfileEdited: false,
    }),
    [at.PERMISSIONS_PROFILE_EDITED]: (state) => ({
      ...state,
      isPermissionsProfileEdited: true,
    }),
    [at.UPDATE_PERMISSIONS_USER_REQUEST]: (state) => ({
      ...state,
      isUpdatingPermissionsUser: true,
    }),
    [at.USER_CREATE_PROFILE_EDITED]: (state) => ({
      ...state,
      userCreateProfileEdited: true,
    }),
    [at.USER_CREATE_PROFILE_EDIT_CANCELLED]: (state) => ({
      ...state,
      userCreateProfileEdited: false,
    }),
    [at.UPDATE_PERMISSIONS_USER_SUCCESS]: (state, action) => ({
      ...state,
      editableUser: action.user,
      isPermissionsProfileEdited: false,
    }),
    [at.UPDATE_PERMISSIONS_USER_COMPLETE]: (state) => ({
      ...state,
      isUpdatingPermissionsUser: false,
    }),
    [at.PERMISSIONS_PROFILE_EDIT_CANCELLED]: (state) => ({
      ...state,
      isPermissionsProfileEdited: false,
    }),
    [at.SHOW_PROFILE_DRAWER]: (state) => ({
      ...state,
      isProfileDrawerShowing: true,
    }),
    [at.HIDE_PROFILE_DRAWER]: (state) => ({
      ...state,
      isProfileDrawerShowing: false,
    }),
    [at.SET_USERS_FILTER_USERTYPE_VALUE]: (state, action) => ({
      ...state,
      filterUserTypeValue: action.filterValue,
    }),
    [at.SET_USERS_FILTER_SEARCH_VALUE]: (state, action) => ({
      ...state,
      filterSearchValue: action.filterValue,
    }),
    [at.CLEAR_USERS_STATE]: (state) => ({
      ...state,
      users: new List(),
      hasFetchedUsers: false,
    }),
    [at.PAGINATION_MERGE]: (state, action) => ({
      ...state,
      pagination: action.pagination,
    }),
    [at.CLEAR_EDITABLE_USER]: (state) => ({
      ...state,
      editableUser: new Map(),
    }),
    [at.RESTORE_USER_REQUEST]: (state) => ({
      ...state,
      isFetchingUsers: true,
    }),
    [at.RESTORE_USER_COMPLETE]: (state) => ({
      ...state,
      isFetchingUsers: false,
    }),
    [at.RESTORE_USER_SUCCESS]: (state, action) => {
      const userIndex = state.users.findIndex((i) => i.get('id') === action.user.get('id'));
      return {
        ...state,
        isFetchingUsers: false,
        users: state.users.set(userIndex, action.user),
      };
    },
    [at.DELETE_USER_REQUEST]: (state) => ({
      ...state,
      isFetchingUsers: true,
    }),
    [at.DELETE_USER_COMPLETE]: (state) => ({
      ...state,
      isFetchingUsers: false,
    }),
    [at.DELETE_USER_SUCCESS]: (state, action) => {
      const userIndex = state.users.findIndex((i) => i.get('id') === action.userId);
      const user = state.users.get(userIndex).set('deleted-at', moment().toISOString());

      return {
        ...state,
        isFetchingUsers: false,
        users: state.users.set(userIndex, user),
      };
    },
  },
  getInitialState(),
);
