import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { togglePinCampaign } from 'store/pinnedCampaigns/actions';

import Grid from './Grid';

const mapStateToProps = (state) => ({
  campaigns: state.dashboard.campaigns,
  pinnedCampaigns: state.pinned.campaigns,
  campaignsInProcess: state.pinned.campaignsInProcess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      togglePinCampaign,
    },
    dispatch,
  );

export default withNamespaces(['dashboardNew'], { wait: false })(connect(mapStateToProps, mapDispatchToProps)(Grid));
