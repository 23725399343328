import EchoChannel from './EchoChannel';

export default class EchoPresenceChannel extends EchoChannel {
  here(callback) {
    this.channel.here(callback);

    return this;
  }

  joining(callback) {
    this.channel.joining(callback);

    return this;
  }

  leaving(callback) {
    this.channel.leaving(callback);

    return this;
  }

  leave() {
    this.client.leave(this.name);

    return this;
  }
}
