import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';

import { hideProfileDrawer } from 'actions/users';

import ProgressBar from 'components/patterns/ProgressBar';
import Drawer from 'assets/components/presentational/Drawer';
import Heading, { HeadingSizes } from 'assets/components/presentational/Heading';
import UserFormProfile from 'assets/components/containers/Users/UserFormProfile';

import style from './profileDrawer.scss';

class ProfileDrawer extends Component {
  handleOnOverlayClick = () => {
    const { dispatch } = this.props;

    dispatch(hideProfileDrawer());
  };

  renderProgress() {
    const { isFetchingUser, isUpdatingUser } = this.props;

    if (isUpdatingUser || isFetchingUser) {
      return (
        <div className={style.progress}>
          <ProgressBar />
        </div>
      );
    }
  }

  render() {
    const { authUser, t, isProfileDrawerShowing, userId } = this.props;

    return (
      <Drawer active={isProfileDrawerShowing} onOverlayClick={this.handleOnOverlayClick}>
        <Heading size={HeadingSizes.LARGE}>{t('My Profile')}</Heading>
        {this.renderProgress()}
        <UserFormProfile authUser={authUser} t={t} userId={userId} />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  userId: state.auth.user.get('id'),
  isProfileDrawerShowing: state.users.isProfileDrawerShowing,
  isFetchingUser: state.users.isFetchingUser,
  isUpdatingUser: state.users.isUpdatingUser,
  activeUser: state.users.activeUser,
});

export default withNamespaces(['common', 'users'], { wait: true })(connect(mapStateToProps)(ProfileDrawer));

ProfileDrawer.propTypes = {
  authUser: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isFetchingUser: PropTypes.bool,
  isUpdatingUser: PropTypes.bool,
  isProfileDrawerShowing: PropTypes.bool,
};
