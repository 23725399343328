import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import Switch from 'react-toolbox/lib/switch';
import style from './Switch.scss';

const cx = classnames.bind(style);

export const SwitchVariants = {
  DEFAULT: 'DEFAULT',
  NAVIGATION: 'NAVIGATION',
  NAVIGATION_NEW: 'NAVIGATION_NEW',
};

const CustomSwitch = ({ label, checked, onChange, disabled, variant }) => (
  <Switch
    label={label}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    theme={style}
    className={cx({
      [style.navigation]: variant === SwitchVariants.NAVIGATION,
      [style.navigationNew]: variant === SwitchVariants.NAVIGATION_NEW,
    })}
  />
);

export default CustomSwitch;

CustomSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(SwitchVariants)),
};

CustomSwitch.defaultProps = {
  checked: false,
  onChange: () => {},
  disabled: false,
  variant: SwitchVariants.DEFAULT,
};
