import { handleActions } from 'redux-actions';
import { PUBLICATIONS_STORE } from 'store/publications/actions';

const initialState = [];

export default handleActions(
  {
    [PUBLICATIONS_STORE]: (publications, action) => action.payload.publications,
  },
  initialState,
);
