import React from 'react';
import PropTypes from 'prop-types';

import PrivateLite from 'components/common/Layouts/PrivateLite';

const PrivateLiteModeration = ({ children, params: { campaignId } }) => (
  <PrivateLite baseUrl={`lite/moderation/view/${campaignId}`}>{children}</PrivateLite>
);

PrivateLiteModeration.propTypes = {
  children: PropTypes.node.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string,
  }).isRequired,
};

export default PrivateLiteModeration;
