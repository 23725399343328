import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authenticateUser } from 'actions/drop-zone';
import AuthLiteDropZone from './AuthLiteDropZone';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authenticateUser,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(AuthLiteDropZone);
