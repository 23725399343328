import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Map, List } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Logger from 'logger';
import { uploadsPropTypes } from 'store/uploads/propTypes';

import ProgressBar from 'components/patterns/ProgressBar';
import Card from 'components/patterns/Card';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Upload from 'assets/components/containers/Upload';
import CustomSwitch from 'components/patterns/Switch/Switch';

import style from './frame-specification.scss';

const cx = classnames.bind(style);

class FrameSpecification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnmoderated: props.campaign && props.campaign.get('is-exchange-holding', false),
    };
  }

  handleClick = () => {
    const { onClick, frameSpecification } = this.props;
    onClick(frameSpecification);
  };

  handleUploadSuccess = (file, response) => {
    const { id: creativeId } = response;
    const { uploads, updateUpload, removeUpload } = this.props;

    // This is handling an edge case. If the processing of the file happens very fast,
    // and the endpoind that we call to upload the file returns a response after some delay,
    // it may happen that the pusher event comes before the endpoint response
    const uploadAddedFromPusherEvent = uploads.find((upload) => upload.creativeId === creativeId);
    if (uploadAddedFromPusherEvent) {
      const { addCreative, campaignId, frameSpecificationIndex } = this.props;
      addCreative(creativeId, campaignId, frameSpecificationIndex);
      removeUpload(uploadAddedFromPusherEvent.uniqueIdentifier);
      removeUpload(file.uniqueIdentifier);
      removeUpload(creativeId);
    } else {
      const { uniqueIdentifier } = file;
      updateUpload(uniqueIdentifier, {
        creativeId,
      });
    }
  };

  handleUploadFailed = (errors, file) => {
    Logger.error('Upload failed', { errors, file });
  };

  renderFramesCount = (frameSpec) => {
    const { t } = this.props;

    if (frameSpec.get('campaign-frame-count')) {
      return <span className={style.frameSpec}>{`${t('Frames')}: ${frameSpec.get('campaign-frame-count')}`}</span>;
    }

    return null;
  };

  renderUploadCount = (frameSpec) => {
    const { t } = this.props;

    if (frameSpec.get('creatives', List()).size) {
      return (
        <span className={style.frameSpec}>
          {`${t('Uploaded')} ${t('Creative').toLowerCase()}: ${frameSpec.get('creatives').size}`}
        </span>
      );
    }

    return null;
  };

  renderAudioEnabled = (frameSpec) => {
    const { t } = this.props;

    if (frameSpec.get('has-sound')) {
      return <span className={style.frameSpec}>{`${t('Audio Enabled')}`}</span>;
    }

    return null;
  };

  renderNewUpload = () => {
    const { allowNewUploads, campaignId, frameSpecification } = this.props;
    const query = {
      campaign_id: campaignId, // eslint-disable-line camelcase
      frame_specification_id: frameSpecification.get('id'), // eslint-disable-line camelcase
    };

    if (config.xdebug.enabled) {
      query.XDEBUG_SESSION_START = config.xdebug.key;
    }

    if (allowNewUploads) {
      return (
        <div className={style.uploadPlaceholder}>
          <Upload
            onSuccess={this.handleUploadSuccess}
            onFail={this.handleUploadFailed}
            target={`${config.api.url}/creatives/files`}
            query={query}
          />
        </div>
      );
    }
  };

  moderationStatusSwitchHandler = () => {
    const { fetchCreativesByModerationStatus, frameSpecificationIndex, frameSpecification } = this.props;
    this.setState(
      (state) => {
        return {
          showUnmoderated: !state.showUnmoderated,
        };
      },
      () => {
        const { showUnmoderated } = this.state;
        fetchCreativesByModerationStatus(showUnmoderated, frameSpecification, frameSpecificationIndex);
      },
    );
  };

  renderHeading = () => {
    const { frameSpecification, active, t, showModerationSwitch } = this.props;
    const { showUnmoderated } = this.state;

    return (
      <div className={active ? style.frameHeadingActive : style.frameHeading} onClick={this.handleClick}>
        <div className={style.frameHeadingDetails}>
          <div>
            <Heading size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
              <Icon iconType={IconTypes.TV} />{' '}
              <span>
                {`${frameSpecification.get('name')} - ${frameSpecification.get('width')} x ${frameSpecification.get(
                  'height',
                )}`}
              </span>
            </Heading>

            <span className={style.frameSpecs}>
              {this.renderFramesCount(frameSpecification)}

              <span className={style.frameSpec}>{`${t('Aspect Ratio')}: ${frameSpecification.get(
                'aspect-ratio',
              )}`}</span>

              {this.renderUploadCount(frameSpecification)}
              {this.renderAudioEnabled(frameSpecification)}
            </span>
          </div>
          <div className={style.switchLabel}>
            {showModerationSwitch ? (
              <span className={style.moderationSwitch}>
                <CustomSwitch
                  label={`${t('Unmoderated Only')}`}
                  checked={showUnmoderated}
                  onChange={this.moderationStatusSwitchHandler}
                  variant="NAVIGATION"
                />
              </span>
            ) : null}
          </div>
        </div>

        {this.renderNewUpload()}
      </div>
    );
  };

  render() {
    const { children, active, isFetchingCreativesByFrameSpec, frameSpecification } = this.props;
    const isFetching = isFetchingCreativesByFrameSpec[frameSpecification.get('id')];

    return (
      <div className={style.frame}>
        <Card>
          {this.renderHeading()}
          <div
            className={cx('frameContent', {
              hidden: !active,
            })}
          >
            {children}
          </div>
        </Card>
        {isFetching && (
          <div className={style.frameSpecProgressBar}>
            <ProgressBar />
          </div>
        )}
      </div>
    );
  }
}

export default withNamespaces(['common', 'creative'], { wait: false })(FrameSpecification);

FrameSpecification.propTypes = {
  uploads: uploadsPropTypes.isRequired,
  updateUpload: PropTypes.func.isRequired,
  removeUpload: PropTypes.func.isRequired,
  addCreative: PropTypes.func.isRequired,
  active: PropTypes.bool,
  allowNewUploads: PropTypes.bool,
  children: PropTypes.node.isRequired,
  campaignId: PropTypes.string.isRequired,
  frameSpecification: PropTypes.instanceOf(Map).isRequired,
  frameSpecificationIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  isFetchingCreativesByFrameSpec: PropTypes.objectOf(PropTypes.bool).isRequired,
  showModerationSwitch: PropTypes.bool,
  fetchCreativesByModerationStatus: PropTypes.func.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
};

FrameSpecification.defaultProps = {
  allowNewUploads: false,
  active: false,
  onClick: () => {},
  showModerationSwitch: false,
};
