import React from 'react';
import ReactToolboxTooltip from 'react-toolbox/lib/tooltip';

import theme from './Tooltip.scss';

const Tooltip = (element) => {
  const ComponentWithTooltip = ReactToolboxTooltip(element);
  return (props) => <ComponentWithTooltip {...props} theme={theme} />;
};

export default Tooltip;
