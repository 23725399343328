import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

import Link from 'assets/components/presentational/Link';

import style from './Thumbnail.scss';

const cx = classnames.bind(style);

export const thumbnailSize = {
  SMALL: 'small',
  LARGE: 'large',
};

class Thumbnail extends Component {
  state = {
    loadFailed: false,
  };

  onLoadError = () => {
    this.setState({
      loadFailed: true,
    });
  };

  render() {
    const { alt, src, link, size, seconds, t } = this.props;
    const { loadFailed } = this.state;

    const showAlternateText = !src || loadFailed;

    const content = (
      <span className={cx('thumbnail', size)}>
        {showAlternateText ? (
          <span className={style.noThumbnailLabel}>{t('No thumbnail')}</span>
        ) : (
          <img className={style.image} src={src} alt={alt} onError={this.onLoadError} />
        )}
        {seconds && <div className={style.length}>{seconds}s</div>}
      </span>
    );

    if (link) {
      return <Link to={link}>{content}</Link>;
    }

    return content;
  }
}

export default withNamespaces(['common'], { wait: false })(Thumbnail);

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
  seconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  src: '',
  alt: '',
  link: '',
  size: '',
  seconds: undefined,
};
