import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { fetchScreenshots, clearScreenshots } from 'actions/frames/screenshots';
import ScreenshotsPres from 'assets/components/presentational/Screenshots';
import { fetchFrame } from 'actions/frames/frames';
import style from './screenshots.scss';

class Screenshots extends Component {
  componentDidMount() {
    const {
      dispatch,
      params: { frameId },
    } = this.props;
    dispatch(fetchFrame(frameId));
    dispatch(fetchScreenshots(frameId));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearScreenshots());
  }

  render() {
    const { isFetchingFrame, isFetching, isLoaded, frame, screenshots } = this.props;

    return (
      <ScreenshotsPres
        className={style.component}
        frame={frame}
        isFetchingFrame={isFetchingFrame}
        isFetching={isFetching}
        isLoaded={isLoaded}
        screenshots={screenshots}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.screenshots.isFetching,
  isFetchingFrame: state.screenshots.isFetchingFrame,
  isLoaded: state.screenshots.isLoaded,
  frame: state.screenshots.frame,
  screenshots: state.screenshots.screenshots,
});

export default connect(mapStateToProps)(Screenshots);

Screenshots.propTypes = {
  frame: PropTypes.instanceOf(Map).isRequired,
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingFrame: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  screenshots: PropTypes.instanceOf(List).isRequired,
};
