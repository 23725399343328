import React from 'react';
import PropTypes from 'prop-types';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

const Private = ({ children }) => (
  <div>
    <Header showBackLink showSearch showSettings />
    {children}
    <Footer />
  </div>
);

Private.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Private;
