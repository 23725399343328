import { handleActions } from 'redux-actions';
import { List, Map } from 'immutable';
import * as at from 'actions/frames/frames';

const getInitialState = () => ({
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  updatingId: null,
  frames: List(),
  pagination: Map(),
  sorts: [],
  filters: [],
  isFetchingExportData: false,
  exportDataItems: List(),
});

const frames = handleActions(
  {
    [at.FETCH_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [at.FETCH_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      frames: action.frames,
      pagination: action.pagination,
    }),
    [at.FETCH_FAILURE]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [at.UPDATE_REQUEST]: (state, action) => ({
      ...state,
      isUpdating: true,
      updatingId: action.frameId,
    }),
    [at.UPDATE_SUCCESS]: (state, action) => ({
      ...state,
      isUpdating: false,
      frames: state.frames.set(
        state.frames.findIndex((i) => i.get('id') === action.frameId),
        action.frame,
      ),
    }),
    [at.UPDATE_FAILURE]: (state) => ({
      ...state,
      isUpdating: false,
      updatingId: null,
    }),
    [at.DELETE_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
      isDeleting: true,
    }),
    [at.DELETE_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      isRestoring: false,
      frames: state.frames.set(
        state.frames.findIndex((i) => i.get('id') === action.frameId),
        action.frame,
      ),
    }),
    [at.DELETE_FAILURE]: (state) => ({
      ...state,
      isFetching: false,
      isDeleting: false,
    }),
    [at.RESTORE_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
      isRestoring: true,
    }),
    [at.RESTORE_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      isRestoring: false,
      frames: state.frames.set(
        state.frames.findIndex((i) => i.get('id') === action.frameId),
        action.frame,
      ),
    }),
    [at.RESTORE_FAILURE]: (state) => ({
      ...state,
      isFetching: false,
      isRestoring: false,
    }),
    [at.CLEAR]: () => getInitialState(),
    [at.FETCH_CSV_REQUEST]: (state) => ({
      ...state,
      isFetchingExportData: true,
    }),
    [at.FETCH_CSV_COMPLETE]: (state) => ({
      ...state,
      isFetchingExportData: false,
    }),
    [at.FETCH_CSV_SUCCESS]: (state, action) => ({
      ...state,
      exportDataItems: action.items,
    }),
  },
  getInitialState(),
);

export default frames;
