import Request from 'modules/API/request';
import { sortBy } from 'lodash';
import { notifyApiError } from 'actions/notify';

export const I18N_FETCH_REQUEST = 'I18N_FETCH_REQUEST';
export const I18N_FETCH_SUCCESS = 'I18N_FETCH_SUCCESS';
export const I18N_FETCH_COMPLETE = 'I18N_FETCH_COMPLETE';

export const fetchIndex = (i18n = 'continents', options = {}) => (dispatch) => {
  Object.assign(options, { endpoint: `i18n/${i18n}`, unauthenticated: true });

  dispatch({ type: I18N_FETCH_REQUEST, i18n, options });

  Request.send(options)
    .then((response) => {
      const data = sortBy(response.data.body.parsed, ['name']);
      dispatch({ type: I18N_FETCH_SUCCESS, i18n, data });
      dispatch({ type: I18N_FETCH_COMPLETE, i18n, options });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: I18N_FETCH_COMPLETE, i18n, options });
    });
};
