import React from 'react';
import PropTypes from 'prop-types';

import { redirect, getURLQueryString } from 'modules/Helpers';
import ROUTES from 'modules/Constants/routes';

import MultiSelect from './MultiSelect';

export const OPTIONS = {
  bookingStatus: [
    { label: 'Promoted', value: 'fully_promoted' },
    { label: 'Unpromoted', value: 'unpromoted' },
    { label: 'Partially promoted', value: 'partially_promoted' },
  ],
  contentStatus: [
    { label: 'Moderated', value: 'awaiting_promotion' },
    { label: 'Unmoderated', value: 'pending_moderation' },
    { label: 'Missing content', value: 'missing_content' },
    { label: 'Ready', value: 'ready' },
    { label: 'Manual', value: 'manual' },
  ],
};

const GROUPS = {
  bookingStatus: 'Booking status',
  contentStatus: 'Content status',
};

const StatusFilter = ({ validatedBookingStatus, validatedContentStatus }) => {
  const onStatusChange = (value, groupId) => {
    const queryString = getURLQueryString({ [groupId]: value });
    redirect(`${ROUTES.DASHBOARD}1${queryString}`, true);
  };

  const selected = {
    bookingStatus: validatedBookingStatus ? validatedBookingStatus.split(',') : [],
    contentStatus: validatedContentStatus ? validatedContentStatus.split(',') : [],
  };

  return (
    <MultiSelect
      options={OPTIONS}
      groups={GROUPS}
      selected={selected}
      onChange={onStatusChange}
      placeholder="Select filters"
    />
  );
};

StatusFilter.propTypes = {
  validatedBookingStatus: PropTypes.string,
  validatedContentStatus: PropTypes.string,
};

StatusFilter.defaultProps = {
  validatedBookingStatus: null,
  validatedContentStatus: null,
};

export default StatusFilter;
