import React from 'react';
import PropTypes from 'prop-types';
import style from './weightIndicator.scss';

const WeightIndicator = ({ weight, color }) => (
  <div className={style.weightIndicator}>
    <div className={style.weightIndicatorValue} style={{ width: `${weight}%`, backgroundColor: color }}>
      &nbsp;
    </div>
  </div>
);

export default WeightIndicator;

WeightIndicator.propTypes = {
  weight: PropTypes.number,
  color: PropTypes.string,
};
