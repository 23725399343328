import React, { Component } from 'react';
import { Autocomplete as BaseAutocomplete } from 'react-toolbox/lib/autocomplete';
import theme from './autocomplete.scss';

class Autocomplete extends Component {
  render() {
    return <BaseAutocomplete theme={theme} {...this.props} />;
  }
}

export default Autocomplete;

Autocomplete.propTypes = BaseAutocomplete.propTypes;
Autocomplete.defaultProps = BaseAutocomplete.defaultProps;
