import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { get } from 'lodash';
import { notifyApiError } from 'actions/notify';

import { fetchCampaignRelations, mergeCampaignRelation } from 'actions/campaign';
import { fetchCreativesByFrameSpecifications } from 'actions/creative';

export const FETCH_REQUEST = 'campaign/playlists/FETCH_REQUEST';
export const FETCH_SUCCESS = 'campaign/playlists/FETCH_SUCCESS';
export const FETCH_COMPLETE = 'campaign/playlists/FETCH_COMPLETE';

export const FETCH_COMPUTED_REQUEST = 'campaign/playlists/FETCH_COMPUTED_REQUEST';
export const FETCH_COMPUTED_SUCCESS = 'campaign/playlists/FETCH_COMPUTED_SUCCESS';
export const FETCH_COMPUTED_COMPLETE = 'campaign/playlists/FETCH_COMPUTED_COMPLETE';

export const SET_HAS_UNSAVED = 'campaign/playlists/SET_HAS_UNSAVED';
export const CLEAR_HAS_UNSAVED = 'campaign/playlists/CLEAR_HAS_UNSAVED';

export const UPDATE_REQUEST = 'campaign/playlists/UPDATE_REQUEST';
export const UPDATE_COMPLETE = 'campaign/playlists/UPDATE_COMPLETE';

export const SET_ACTIVE_FRAME_SPEC = 'campaign/playlists/SET_ACTIVE_FRAME_SPEC';
export const SET_ACTIVE_RULE_SET = 'campaign/playlists/SET_ACTIVE_RULE_SET';

export const CLEAR_COMPUTED = 'campaign/playlists/CLEAR_COMPUTED';

export const fetchPlaylistsData = (campaignId) => (dispatch) => {
  dispatch({ type: FETCH_REQUEST });

  return Promise.all([
    dispatch(fetchPlaylists(campaignId)),
    dispatch(fetchRuleSets(campaignId)),
    dispatch(fetchFrameSpecifications(campaignId)),
  ])
    .then((responses) => {
      const playlists = fromJS(responses[0]);

      dispatch({ type: FETCH_SUCCESS, playlists });
      dispatch({ type: FETCH_COMPLETE });

      return Promise.resolve(playlists);
    })
    .catch((e) => {
      dispatch({ type: FETCH_COMPLETE });

      return Promise.reject(e);
    });
};

export const fetchPlaylists = (campaignId) => async (dispatch) => {
  try {
    const response = await Request.send({
      endpoint: 'creative-playlists',
      method: 'GET',
      filters: [{ campaign_id: campaignId }], // eslint-disable-line camelcase
      pagination: Request.ALL_PAGES,
    });
    const items = fromJS(response.data.body.parsed);

    return Promise.resolve(items);
  } catch (error) {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  }
};

export const fetchComputedPlaylist = (playlistId) => (dispatch) => {
  const options = {
    endpoint: `creative-playlists/${playlistId}/sequence`,
    method: 'GET',
  };

  dispatch({ type: FETCH_COMPUTED_REQUEST });

  return Request.send(options)
    .then((response) => {
      const computedPlaylist = fromJS(get(response.data.body.parsed, 'creative-sequence'));

      dispatch({ type: FETCH_COMPUTED_SUCCESS, computedPlaylist });
      dispatch({ type: FETCH_COMPUTED_COMPLETE });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const fetchFrameSpecifications = (campaignId) => async (dispatch) => {
  const responseOfFrameSpecs = await dispatch(fetchCampaignRelations(campaignId, 'frame-specifications'));
  const frameSpecs = responseOfFrameSpecs.data.body.parsed;
  dispatch(fetchCreativesByFrameSpecifications(campaignId, frameSpecs, { includes: ['rule-sets'] }));
};

export const fetchRuleSets = (campaignId) => (dispatch) => {
  dispatch(
    fetchCampaignRelations(campaignId, 'rule-sets', {
      includes: ['creatives', 'frames', 'rules'],
    }),
  );
};

export const fetchCreative = (campaignId, frameSpecificationKey, creativeId, extraOptions = {}) => (dispatch) => {
  const options = Object.assign(
    {
      endpoint: `creatives/${creativeId}`,
    },
    extraOptions,
  );

  return Request.send(options)
    .then((response) => {
      const creative = fromJS(response.data.body.parsed);

      dispatch(mergeCreativeIntoFrameSpecs(campaignId, frameSpecificationKey, creative));

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const setHasUnsavedChanges = () => ({
  type: SET_HAS_UNSAVED,
});

export const clearHasUnsavedChanges = () => ({
  type: CLEAR_HAS_UNSAVED,
});

export const playlistUpdate = (data, playlistId) => (dispatch) => {
  dispatch({ type: UPDATE_REQUEST, playlistId });

  return Request.send({ endpoint: `creative-playlists/${playlistId}`, method: 'PATCH', data })
    .then((response) => {
      const playlist = fromJS(response.data.body.parsed);
      dispatch({ type: UPDATE_COMPLETE, playlistId, playlist });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const mergeCreativeIntoFrameSpecs = (campaignId, frameSpecificationKey, creative) => (dispatch) => {
  dispatch(mergeCampaignRelation(campaignId, ['frame-specifications', frameSpecificationKey, 'creatives'], creative));
};

export const setActiveFrameSpec = (activeFrameSpecId) => ({
  type: SET_ACTIVE_FRAME_SPEC,
  activeFrameSpecId,
});

export const setActiveRuleSet = (activeRuleSetId) => ({
  type: SET_ACTIVE_RULE_SET,
  activeRuleSetId,
});

export const clearComputedPlaylist = () => ({
  type: CLEAR_COMPUTED,
});
