import { handleActions } from 'redux-actions';
import { List, Map } from 'immutable';

const getInitialState = () => ({
  activeContentItemId: '',
  bypassProfanityFilter: false,
  collapsedSections: List(),
  isContentItemDrawerActive: false,
  isFetchingContentItems: false,
  isFetchingContentItemsPending: false,
  isInitialFetch: true,
  isPollingContentItems: false,
  isPrompting: false,
  isSaving: false,
  lastFetchAt: null,
  pagination: new Map(),
  selectedContentItemIds: List(),
  selectedContentTypeIds: List(),
  searchContentValue: '',
  selectedContentTypeFilter: '',
});

const moderation = handleActions(
  {
    CONTENT_MODERATION_UPDATE_REQUEST: (state) => ({
      ...state,
      isSaving: true,
    }),
    CONTENT_MODERATION_UPDATE_COMPLETE: (state) => ({
      ...state,
      isSaving: false,
      bypassProfanityFilter: false,
    }),
    CONTENT_MODERATION_UPDATE_BYPASS_PROFANITY_FILTER: (state, action) => ({
      ...state,
      bypassProfanityFilter: action.bypass,
    }),
    CONTENT_MODERATION_DELETE_PROMPT_TOGGLE: (state) => ({
      ...state,
      isPrompting: !state.isPrompting,
    }),
    CONTENT_MODERATION_MERGE_SELECTED_CONTENT_ITEM_IDS: (state, action) => ({
      ...state,
      selectedContentItemIds: action.selectedContentItemIds,
    }),
    CONTENT_MODERATION_MERGE_COLLAPSED_SECTIONS: (state, action) => ({
      ...state,
      collapsedSections: action.collapsedSections,
    }),
    CONTENT_MODERATION_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingContentItems: action.pending ? state.isFetchingContentItems : true,
      isFetchingContentItemsPending: action.pending ? true : state.isFetchingContentItemsPending,
    }),
    CONTENT_MODERATION_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingContentItems: action.pending ? state.isFetchingContentItems : false,
      isFetchingContentItemsPending: action.pending ? false : state.isFetchingContentItemsPending,
      isInitialFetch: false,
    }),
    CONTENT_MODERATION_FETCH_SUCCESS: (state, action) => ({
      ...state,
      lastFetchAt: action.lastFetchAt,
    }),
    CONTENT_MODERATION_RESET: () => getInitialState(),
    CONTENT_MODERATION_MERGE_PAGINATION: (state, action) => ({
      ...state,
      pagination: state.pagination.merge(action.pagination),
    }),
    CONTENT_MODERATION_SET_POLLING: (state, action) => ({
      ...state,
      isPollingContentItems: action.isPollingContentItems,
    }),
    CONTENT_MODERATION_SET_ACTIVE_CONTENT_ITEM_ID: (state, action) => ({
      ...state,
      activeContentItemId: action.contentItemId,
    }),
    CONTENT_MODERATION_TOGGLE_CONTENT_ITEM_DRAWER: (state) => ({
      ...state,
      isContentItemDrawerActive: !state.isContentItemDrawerActive,
    }),
    SET_CONTENT_SEARCH_VALUE: (state, action) => ({
      ...state,
      searchContentValue: action.searchValue,
    }),
    SET_CONTENT_TYPE_FILTER: (state, action) => ({
      ...state,
      selectedContentTypeFilter: action.filter,
    }),
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingContentItems: action.relation === 'content-items' ? true : state.isFetchingContentItems,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingContentItems: action.relation === 'content-items' ? false : state.isFetchingContentItems,
    }),
  },
  getInitialState(),
);

export default moderation;
