export default class EchoChannel {
  client = null;
  channel = null;
  name = null;

  constructor(client, channel, channelName) {
    this.client = client;
    this.channel = channel;
    this.name = channelName;
  }

  bind(event, callback) {
    this.channel.listen(event, callback);

    return this;
  }

  unbind(event) {
    this.channel.stopListening(event);

    return this;
  }

  unsubscribe() {
    this.client.leaveChannel(this.name);

    return this;
  }
}
