import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SYSTEM } from 'app-constants';

import Snackbar from 'assets/components/containers/Snackbar';
import CreateCampaignForm from 'assets/components/containers/CreateCampaignForm';
import ProfileDrawer from 'assets/components/containers/Users/ProfileDrawer';

import { notifyDeploymentStatus, warnDeployingInProgress } from 'actions/system';

import style from './app.scss';
import { BROADCAST_EVENTS, publicSystemChannel, broadcasting } from '../../../../modules/Broadcasting/broadcasting';

class App extends Component {
  broadcastingChannel = null;

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(warnDeployingInProgress());
  }

  componentDidUpdate() {
    this.subscribeToPushNotificationService();
  }

  subscribeToPushNotificationService = () => {
    const { dispatch } = this.props;

    this.broadcastingChannel = broadcasting()
      .setJwt(localStorage.getItem('jwt'))
      .subscribe(publicSystemChannel())
      .bind(BROADCAST_EVENTS.DEPLOY_IN_PROGRESS, () => {
        dispatch(notifyDeploymentStatus(SYSTEM.DEPLOYMENT_STATUS_DEPLOYING));
      })
      .bind(BROADCAST_EVENTS.DEPLOY_COMPLETE, () => {
        dispatch(notifyDeploymentStatus(SYSTEM.DEPLOYMENT_STATUS_COMPLETE));
      });
  };

  // eslint-disable-next-line class-methods-use-this
  unsubscribeToPushNotificationService() {
    if (!this.broadcastingChannel) {
      return;
    }

    this.broadcastingChannel.unsubscribe();
  }

  componentWillUnmount() {
    this.unsubscribeToPushNotificationService();
  }

  render() {
    const { children, isUserAuthenticated, user: immutableUser } = this.props;

    const user = immutableUser.toJS();

    return (
      <div className={style.component.concat(' ', isUserAuthenticated ? style.componentLoggedInNew : '')}>
        <div className={style.content.concat(' ', isUserAuthenticated ? style.contentLoggedIn : '')}>{children}</div>
        <Snackbar />
        {user.id ? (
          <div>
            <CreateCampaignForm />
            <ProfileDrawer />
          </div>
        ) : null}
      </div>
    );
  }
}

App.propTypes = {
  isUserAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
};

App.defaultProps = { children: null };

export default App;
