import config from 'app-config';
import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';
import {
  checkAuth,
  checkGuest,
  checkCanAccessCampaign,
  checkCanAccessBookings,
  checkCanModerateCampaignContent,
  checkCanAddBuilds,
  checkCanViewCampaignContent,
  checkCanManageCampaignMediaBuy,
  checkCanEditFrames,
  checkIsSuperUser,
  checkCanManageContentTypes,
  checkIsSuperUserOrBusinessUnitAdmin,
  checkLiteUser,
} from 'actions/auth';

import Public from 'components/common/Layouts/Public';
import Private from 'components/common/Layouts/Private';
import PrivateDropZone from 'components/common/Layouts/PrivateLite/PrivateDropZone';
import PrivateLiteModeration from 'components/common/Layouts/PrivateLite/PrivateLiteModeration';
import Bookings from 'components/pages/Bookings';
import Publish from 'components/pages/Publish';

import App from 'assets/components/containers/App';
import DashboardNew from 'assets/components/containers/DashboardNew';
import Login from 'assets/components/containers/Auth/Login';
import Logout from 'assets/components/containers/Auth/Logout';
import ResetLink from 'assets/components/containers/Auth/ResetLink';
import Reset from 'assets/components/containers/Auth/Reset';
import Builds from 'assets/components/containers/Campaign/Builds';
import Campaign from 'assets/components/containers/Campaign';
import { Creative, CreativeDropZone, CreativeLiteModeration } from 'assets/components/containers/Campaign/Creative';
import {
  CreativePreview,
  CreativePreviewDropZone,
  CreativePreviewLiteModeration,
} from 'assets/components/containers/Campaign/Creative/Preview';
import Rulesets from 'assets/components/containers/Campaign/Rulesets';
import Preview from 'assets/components/containers/Campaign/Preview';
import Moderation from 'assets/components/containers/Campaign/Moderation';
import Content from 'assets/components/containers/Campaign/Content';
import ContentType from 'assets/components/containers/Campaign/ContentType';
import MediaBuy from 'assets/components/containers/Campaign/MediaBuy';
import NotFound from 'assets/components/containers/NotFound';
import DownTime from 'assets/components/presentational/DownTime';
import DropZoneAuth from 'assets/components/containers/AuthLite/AuthLiteDropZone';
import DropZone from 'assets/components/containers/DropZone';
import LiteModerationAuth from 'assets/components/containers/AuthLite/AuthLiteLiteModeration';
import LiteModeration from 'assets/components/containers/LiteModeration';
import LiteModerationHistory from 'assets/components/containers/LiteModeration/History';
import CreativeFailedHistory from 'assets/components/containers/Campaign/Creative/CreativeFailedHistory';
import CreativesModerationSummary from 'assets/components/containers/Campaign/Creative/CreativesModerationSummary';
import CreativeModerationManagement from 'assets/components/containers/Campaign/Creative/Moderation/Management';
import Playlists from 'assets/components/containers/Campaign/Playlists';
import ContentSchedule from 'assets/components/containers/Campaign/ContentSchedule';
import Users from 'assets/components/containers/Users';
import UserEdit from 'assets/components/containers/Users/UserEdit';
import UserCreate from 'assets/components/containers/Users/UserCreate';
import Frames from 'assets/components/containers/Frames';
import FrameScreenshots from 'assets/components/containers/Frames/Screenshots';
import MediaBuyScreenshots from 'assets/components/containers/Campaign/MediaBuy/Screenshots';

export default ({ dispatch, getState }) => {
  if (config.applicationDisabled) {
    return (
      <Route path="" component={Public}>
        <Route path="*" component={DownTime} />;
      </Route>
    );
  }

  return (
    <Route path="/" component={App}>
      <Route
        path=""
        component={PrivateDropZone}
        onEnter={(nextState, replace, callback) => {
          dispatch(checkLiteUser(nextState, callback));
        }}
      >
        <Route path="drop-zone/:token" component={DropZoneAuth} />
        <Route path="drop-zone/view/:campaignId" component={DropZone}>
          <IndexRoute component={CreativeDropZone} />
          <Route path="dashboard" component={CreativeDropZone} />
          <Route path="creative/:creativeId" component={CreativePreviewDropZone} />
        </Route>
      </Route>

      <Route
        path=""
        component={PrivateLiteModeration}
        onEnter={(nextState, replace, callback) => {
          dispatch(checkLiteUser(nextState, callback));
        }}
      >
        <Route path="lite/moderation/:token" component={LiteModerationAuth} />
        <Route path="lite/moderation/view/:campaignId" component={LiteModeration}>
          <IndexRoute component={CreativeLiteModeration} />
          <Route path="dashboard" component={CreativeLiteModeration} />
          <Route path="history(/:pageNumber)" component={LiteModerationHistory} />
          <Route path="creative/:creativeId" component={CreativePreviewLiteModeration} />
        </Route>
      </Route>

      <Route path="" component={Public}>
        <Route
          path="auth/login"
          component={Login}
          onEnter={(nextState, replace, callback) => dispatch(checkGuest(nextState, replace, callback))}
        />
        <Route
          path="auth/reset"
          component={ResetLink}
          onEnter={(nextState, replace, callback) => dispatch(checkGuest(nextState, replace, callback))}
        />
        <Route
          path="auth/reset/:token"
          component={Reset}
          onEnter={(nextState, replace, callback) => dispatch(checkGuest(nextState, replace, callback))}
        />
        <Route path="auth/logout" component={Logout} />
        <Route path="404" component={NotFound} />
      </Route>

      <Route path="" component={Private}>
        <IndexRoute
          component={DashboardNew}
          onEnter={(nextState, replace, callback) => dispatch(checkAuth(nextState, replace, callback))}
        />
        <Route
          path="campaigns/:campaignId"
          component={Campaign}
          onEnter={(nextState, replace, callback) => dispatch(checkCanAccessCampaign(nextState, replace, callback))}
        >
          <IndexRoute component={Creative} />
          <Route
            path="bookings"
            component={Bookings}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanAccessBookings(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route path="publishing" component={Publish} />
          <Route path="creative" component={Creative} />
          <Route path="creative/moderation/summary(/:pageNumber)" component={CreativesModerationSummary} />
          <Route path="creative/moderation/manage" component={CreativeModerationManagement} />
          <Route path="creative/failed-history" component={CreativeFailedHistory} />
          <Route path="creative/:creativeId(/:drawer)(/:option)" component={CreativePreview} />
          <Route
            path="content(/:pageNumber)"
            component={Content}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanViewCampaignContent(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route
            path="content-types(/:pageNumber)"
            component={ContentType}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanManageContentTypes(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route path="rules(/:pageNumber)" component={Rulesets} />
          <Route path="preview" component={Preview} />
          <Route
            path="moderation(/:pageNumber)"
            component={Moderation}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanModerateCampaignContent(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route
            path="media-buy(/:pageNumber)"
            component={MediaBuy}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanManageCampaignMediaBuy(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route
            path="media-buy/:frameId/screenshots"
            component={MediaBuyScreenshots}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanManageCampaignMediaBuy(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route
            path="builds"
            component={Builds}
            onEnter={(nextState, replace, callback) =>
              dispatch(checkCanAddBuilds(getState().auth.user, nextState, replace, callback))
            }
          />
          <Route path="playlists" component={Playlists} />
          <Route path="schedule" component={ContentSchedule} />
        </Route>
        <Route
          path="users/create"
          component={UserCreate}
          onEnter={(nextState, replace, callback) => dispatch(checkIsSuperUser(nextState, replace, callback))}
        />
        <Route
          path="users/:userId/edit(/:mode)"
          component={UserEdit}
          onEnter={(nextState, replace, callback) =>
            dispatch(checkIsSuperUserOrBusinessUnitAdmin(nextState, replace, callback))
          }
        />
        <Route
          path="users(/:pageNumber)"
          component={Users}
          onEnter={(nextState, replace, callback) =>
            dispatch(checkIsSuperUserOrBusinessUnitAdmin(nextState, replace, callback))
          }
        />
        <Route
          path="frames/:frameId/screenshots"
          component={FrameScreenshots}
          onEnter={(nextState, replace, callback) => dispatch(checkCanEditFrames(replace, callback))}
        />
        <Route
          path="frames(/:page)"
          component={Frames}
          onEnter={(nextState, replace, callback) => dispatch(checkCanEditFrames(replace, callback))}
        />
        <Route
          path="(:pageNumber)"
          component={DashboardNew}
          onEnter={(nextState, replace, callback) => dispatch(checkAuth(nextState, replace, callback))}
        />
      </Route>

      <Redirect from="*" to="/404" />
    </Route>
  );
};
