import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { uniqueId } from 'lodash';

import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Input from 'components/patterns/Input';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import Checkbox from '../Checkbox';

import style from './checkboxPicker.scss';

class CheckboxPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValue: '',
      valueFiltered: props.value,
    };
  }

  handleCheckChange(val, checked) {
    let { valueFiltered } = this.state;

    if (valueFiltered.length > 0) {
      valueFiltered = valueFiltered.map((option) => {
        if (val === option.value) {
          return {
            ...option,
            checked,
          };
        }
        return option;
      });

      this.setState({
        valueFiltered,
      });
    }

    const { onChange, value } = this.props;
    onChange(
      value.map((option) => {
        if (val === option.value) {
          return {
            ...option,
            checked,
          };
        }
        return option;
      }),
    );
  }

  handleFilterChange = (filterValue) => {
    let { value: valueFiltered } = this.props;

    valueFiltered = valueFiltered.filter((item) => item.label.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);

    this.setState({
      filterValue,
      valueFiltered,
    });
  };

  renderSearchFilter() {
    const { disabled, value, t, isFilterable } = this.props;
    const { filterValue } = this.state;

    if (value.length < 1 || !isFilterable) {
      return null;
    }

    return (
      <span className={style.searchFilter}>
        <Input
          disabled={disabled}
          type="text"
          id={uniqueId('search')}
          icon={<Icon iconType={IconTypes.SEARCH} />}
          onChange={this.handleFilterChange}
          label={t('Filter')}
          value={filterValue}
        />
      </span>
    );
  }

  renderResults() {
    const { disabled, t } = this.props;
    const { valueFiltered } = this.state;

    if (valueFiltered.length > 0) {
      return (
        <span>
          {valueFiltered.map((option) => (
            <Checkbox
              key={option.value}
              onChange={(checked) => this.handleCheckChange(option.value, checked)}
              label={option.label}
              checked={option.checked}
              disabled={disabled}
            />
          ))}
        </span>
      );
    }
    return <span className={style.noOptionsLabel}>{t('No Results Found')}</span>;
  }

  render() {
    const { value, className, isFilterable, noOptionsLabel, t, title } = this.props;

    return (
      <div className={className}>
        <Heading
          className={isFilterable ? style.headingFilterable : style.heading}
          size={HeadingSizes.SMALL}
          tag={HeadingTags.H3}
        >
          {title}
        </Heading>

        {this.renderSearchFilter()}
        {this.renderResults()}

        {!value.length ? <span className={style.noOptionsLabel}>{t(noOptionsLabel)}</span> : null}
      </div>
    );
  }
}

export default withNamespaces(['common', 'rules'], { wait: false })(CheckboxPicker);

CheckboxPicker.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  noOptionsLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  isFilterable: PropTypes.bool,

  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

CheckboxPicker.defaultProps = {
  id: uniqueId(),
  className: '',
  noOptionsLabel: 'No options available',
  title: '',
  isFilterable: true,
};
