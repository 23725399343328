import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces, Trans } from 'react-i18next';
import { isFunction } from 'lodash';
import fit from 'fit.js';
import axios from 'axios';

import { CreativeTypes } from 'modules/Helpers';

import { DefaultPlayer as Video } from 'react-html5video';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/first
import '!style-loader!css-loader!react-html5video/dist/styles.css';
import ProgressBar from 'components/patterns/ProgressBar';
import Link from 'assets/components/presentational/Link';
import Icon, { IconTypes, IconColors, IconSizes } from 'assets/components/presentational/Icon';

import HtmlPlayButton from './HtmlPlayButton';

import style from './creativePreview.scss';

class CreativePreview extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // Determines whether the html asset exists
      htmlAssetExists: false,
      // Determines whether the actual asset (the image, video of HTML frame) has been loaded
      assetLoaded: false,
    };

    // Underlying react reference to the HTML which is set on mount
    this.htmlFrame = null;

    // Underlying react reference to the video which is set on mount
    this.refVideo = null;
  }

  componentDidMount() {
    const { creative, onVisible } = this.props;

    this.mounted = true;

    this.checkHtmlAssetExists(creative);

    if (isFunction(onVisible)) {
      onVisible();
    }
  }

  componentDidUpdate(prevProps) {
    const { creative } = this.props;
    const { creative: prevCreative } = prevProps;

    // Sometimes it doesn't resize in time
    setTimeout(this.applyFit.bind(this), 1);
    setTimeout(this.applyFit.bind(this), 250);
    setTimeout(this.applyFit.bind(this), 1000);

    const wrapper = document.getElementById('preview__wrapper');

    if (!wrapper) {
      return null;
    }

    wrapper.classList.remove(style.wrapperVisible);

    this.applyFit();

    wrapper.classList.add(style.wrapperVisible);

    if (creative.get('id') !== prevCreative.get('id')) {
      this.checkHtmlAssetExists(creative);

      if (this.refVideo) {
        this.refVideo.videoEl.load();
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { creative: nextCreative } = nextProps;
    const { assetLoaded: nextAssetLoaded, htmlAssetExists: nextHtmlAssetExists } = nextState;
    const { creative } = this.props;
    const { assetLoaded, htmlAssetExists } = this.state;

    return (
      nextCreative.get('id') !== creative.get('id') ||
      (!assetLoaded && nextAssetLoaded) ||
      (!htmlAssetExists && nextHtmlAssetExists)
    );
  }

  applyFit = () => {
    const wrapper = document.getElementById('preview__wrapper');
    const frame = document.getElementById('preview__frame');

    if (!wrapper || !frame) {
      return null;
    }

    const viewportHeight = this.getViewportHeight();
    const height = viewportHeight - 100; // Magic number alert! My intuition is that this will feel nicer in the interface.

    frame.style.height = height.toString().concat('px');

    wrapper.classList.add(style.wrapperVisible);

    fit(wrapper, frame, { watch: true });
  };

  handleOnHtmlFrameLoad = (e) => {
    this.handleOnAssetLoad();
    this.htmlFrame = e.target;

    const { creative } = this.props;
    const frameMetaData = creative.get('preview-frame', new Map()).toJS();
    const contentItems = creative.get('preview-content-items', new List()).toJS();
    const ruleSets = this.mapRuleSetsToObject(creative.get('preview-rule-sets', new List()));

    // DIRTY FIX.
    // The field 'meta' gets stripped away by the serializer
    // but it's needed by the HTML creative to work. As a quick solution, we use a different
    // name in the response and then we change it here back to 'meta'
    if (frameMetaData.metadata) {
      frameMetaData.meta = frameMetaData.metadata;
      delete frameMetaData.metadata;
    }

    // For backwards compatibility, we send two versions of each event.
    // One with the event prefixed with "v1." and data wrapped in "data" (old method) and one without.

    const data = { frameMetaData, contentItems, ruleSets };

    this.sendEventsToFrame(data);
    this.sendEventsToFrame(data, 1);

    this.forceUpdate();
  };

  handleHtmlPlayClick = (e) => {
    const { onHtmlPlayClick } = this.props;

    this.sendEventToFrame('start');
    this.sendEventToFrame('viooh.play');

    if (isFunction(onHtmlPlayClick)) {
      return onHtmlPlayClick(e);
    }
  };

  handleOnAssetLoad = () => {
    this.setState({
      assetLoaded: true,
    });

    this.forceUpdate(() => {
      if (this.refVideo) {
        this.refVideo.videoEl.play();
      }
    });
  };

  handleOnVideoMounted = (video) => {
    this.refVideo = video;
  };

  /*
   Show progress if HTML asset returns anything other than a 200
   */
  checkHtmlAssetExists = (creative) => {
    this.setState({
      htmlAssetExists: false,
    });

    if (creative.get('type') === CreativeTypes.HTML5) {
      return axios({
        url: creative.get('html-asset-url'),
        withCredentials: false,
      }).then(() => {
        if (this.mounted) {
          this.setState({
            htmlAssetExists: true,
          });
        }

        return Promise.resolve();
      });
    }
  };

  renderImage() {
    const { creative } = this.props;

    if (!creative.get('prepared-asset')) {
      return null;
    }

    const { onMouseEnterAsset } = this.props;
    const { assetLoaded } = this.state;

    return (
      <img
        className={assetLoaded ? style.asset : style.assetHidden}
        onMouseEnter={onMouseEnterAsset}
        src={creative.get('prepared-asset', new Map()).get('url')}
        onLoad={this.handleOnAssetLoad}
        style={this.getRotationStyle(creative)}
      />
    );
  }

  renderHtml() {
    const { creative, frameSpecification } = this.props;
    const { assetLoaded } = this.state;

    return (
      <iframe
        className={assetLoaded ? style.asset : style.assetHidden}
        onLoad={this.handleOnHtmlFrameLoad}
        src={creative.get('html-asset-url')}
        style={{ width: frameSpecification.get('width'), height: frameSpecification.get('height') }}
      />
    );
  }

  renderVideo() {
    const { creative, frameSpecification } = this.props;

    if (!creative.get('prepared-asset')) {
      return null;
    }

    const { assetLoaded } = this.state;

    let videoClassName = assetLoaded ? style.asset : style.assetHidden;
    if (creative.getIn(['frame-specification', 'rotation']) === 90) {
      videoClassName = `${videoClassName} ${style.assetFlippedVertically}`;
    }

    return (
      <Video
        className={videoClassName}
        controls={false}
        onPlayPauseClick={() => {}}
        style={this.getRotationStyle(creative)}
        onCanPlayThrough={this.handleOnAssetLoad}
        ref={this.handleOnVideoMounted}
        muted={!frameSpecification.get('has-sound')}
      >
        <source src={creative.get('prepared-asset', new Map()).get('url')} />
      </Video>
    );
  }

  renderCreative() {
    const { creative } = this.props;

    switch (creative.get('type')) {
      case CreativeTypes.IMAGE:
        return this.renderImage();
      case CreativeTypes.HTML5:
        return this.renderHtml();
      case CreativeTypes.VIDEO:
        return this.renderVideo();
    }
  }

  renderProgress() {
    const { creative, working } = this.props;
    const { assetLoaded, htmlAssetExists } = this.state;

    if (working || !assetLoaded || (creative.get('type') === CreativeTypes.HTML5 && !htmlAssetExists)) {
      return (
        <div className={style.progress}>
          <ProgressBar circular />
        </div>
      );
    }
  }

  renderNoPreview() {
    const { creative, frameSpecification, working, campaignId } = this.props;

    if (!working && creative.size && frameSpecification.size) {
      return null;
    }

    return (
      <div className={style.noPreview}>
        <div className={style.desktopIcon}>
          <Icon iconType={IconTypes.DESKTOP_WINDOWS} color={IconColors.LIGHTGRAY} size={IconSizes.LARGE} />
        </div>
        <Trans i18nKey="No content to preview. Click here to add content.">
          No content to preview.&nbsp;
          <Link to={`campaigns/${campaignId}/creative`}>Click here</Link>
          &nbsp;to add content.
        </Trans>
      </div>
    );
  }

  renderHtmlPlayButton() {
    const { creative, showHtmlPlayButton, htmlPlayButtonPosition } = this.props;

    if (showHtmlPlayButton && this.htmlFrame && creative.get('type') === CreativeTypes.HTML5) {
      return <HtmlPlayButton handleHtmlPlayClick={this.handleHtmlPlayClick} position={htmlPlayButtonPosition} />;
    }
  }

  renderPreviewFrame() {
    const { creative, frameSpecification } = this.props;

    if (!creative.size && frameSpecification.size) {
      return null;
    }

    return (
      <div className={style.frame} id="preview__frame">
        {this.renderProgress()}
        <div
          className={style.wrapper}
          id="preview__wrapper"
          style={{ width: frameSpecification.get('width'), height: frameSpecification.get('height') }}
        >
          {this.renderCreative()}
        </div>
        {this.renderHtmlPlayButton()}
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        {this.renderNoPreview()}
        {this.renderPreviewFrame()}
      </div>
    );
  }

  getViewportHeight = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  getRotation = () => {
    const { frameSpecification } = this.props;

    const rotation = frameSpecification.get('rotation');

    if (rotation) {
      return rotation * -1;
    }

    return 0;
  };

  getRotationStyle = (creative) => {
    const rotation = this.getRotation(creative);

    if (rotation) {
      const transform = `rotate(${rotation}deg)`;

      return {
        transform,
        WebkitTransform: transform,
        MozTransform: transform,
        msTransform: transform,
      };
    }

    return {};
  };

  mapRuleSetsToObject = (ruleSets) =>
    ruleSets.reduce((carry, ruleSet) => {
      carry[ruleSet.get('slug')] = ruleSet.toJS();

      return carry;
    }, {});

  sendEventsToFrame = (data, version = null) => {
    const { frameMetaData, contentItems, ruleSets } = data;

    this.sendEventToFrame('metaDataReceived', frameMetaData, version);
    this.sendEventToFrame('dataReceived', contentItems, version);
    this.sendEventToFrame('campaignDataReceived', {}, version);
    this.sendEventToFrame('rulesetsDataReceived', ruleSets, version);
  };

  sendEventToFrame = (event, data = {}, version = null) => {
    if (version != null) {
      event = `v${version}.${event}`;
      data = { data };
    }

    this.htmlFrame.contentWindow.postMessage(
      {
        event,
        data,
      },
      '*',
    );
  };
}

export default withNamespaces(['common', 'creative'], { wait: false })(CreativePreview);

CreativePreview.propTypes = {
  className: PropTypes.string,
  creative: PropTypes.instanceOf(Map),
  frameSpecification: PropTypes.instanceOf(Map).isRequired,
  working: PropTypes.bool,
  onMouseEnterAsset: PropTypes.func,
  onHtmlPlayClick: PropTypes.func,
  showHtmlPlayButton: PropTypes.bool,
  htmlPlayButtonPosition: PropTypes.string,
  onVisible: PropTypes.func,
  campaignId: PropTypes.string,
};

CreativePreview.defaultProps = {
  className: '',
  creative: new Map(),
  showHtmlPlayButton: true,
  working: false,
  campaignId: '',
  onMouseEnterAsset: () => {},
  onHtmlPlayClick: () => {},
  onVisible: () => {},
};
