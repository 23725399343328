import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { fromJS, List, Map } from 'immutable';
import { getModelRelationsForCheckboxPicker } from 'modules/Helpers';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import CheckboxPicker from 'assets/components/presentational/CheckboxPicker';
import ProgressBar from 'components/patterns/ProgressBar';
import Drawer from 'assets/components/presentational/Drawer';

import style from './contentItemSettings.scss';

class ContentItemSettings extends Component {
  isWorking = () => {
    const { fetching, saving } = this.props;

    return fetching || saving;
  };

  handleCheckboxChange = (values, items, onChange) => {
    const { contentItem } = this.props;

    const checked = items.filter(
      (item) => fromJS(values).findIndex((value) => value.get('value') === item.get('id') && value.get('checked')) > -1,
    );

    return onChange(contentItem, checked);
  };

  handleRuleSetsChange = (checkboxes) => {
    const { ruleSets, onRuleSetsChange } = this.props;

    return this.handleCheckboxChange(checkboxes, ruleSets, onRuleSetsChange);
  };

  renderProgress = () => {
    if (this.isWorking()) {
      return <ProgressBar />;
    }
  };

  renderContent = () => {
    const { contentItem, fetching, onClose, ruleSets, saving, t } = this.props;

    if (!fetching) {
      const items = getModelRelationsForCheckboxPicker(contentItem, ruleSets, ['rule-sets']);

      return (
        <div>
          <Heading className={style.name} size={HeadingSizes.LARGE} tag={HeadingTags.H2}>
            {contentItem.get('name', '')}
          </Heading>

          <div className={style.groupContainer}>
            <CheckboxPicker
              key={JSON.stringify(items)}
              disabled={saving}
              title={t('Apply Rules')}
              value={items}
              onChange={this.handleRuleSetsChange}
            />
          </div>

          <div className={style.buttonContainer}>
            <Button
              className={style.button}
              disabled={this.isWorking()}
              onClick={onClose}
              label={t('Close')}
              theme={ButtonThemes.WHITE}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    const { active, onClose } = this.props;

    return (
      <Drawer active={active} className={style.component} onOverlayClick={onClose}>
        {this.renderProgress()}
        {this.renderContent()}
      </Drawer>
    );
  }
}

export default withNamespaces(['content', 'common'], { wait: false })(ContentItemSettings);

ContentItemSettings.propTypes = {
  active: PropTypes.bool,
  contentItem: PropTypes.instanceOf(Map).isRequired,
  fetching: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRuleSetsChange: PropTypes.func.isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  saving: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ContentItemSettings.defaultProps = {
  active: false,
};
