import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import * as at from 'actions/campaign/creative/failed';

const getInitialState = () => ({
  creatives: List(),
  isFetching: false,
});

export default handleActions(
  {
    [at.FETCH_CREATIVES_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [at.FETCH_CREATIVES_SUCCESS]: (state, action) => ({
      ...state,
      creatives: action.creatives,
    }),
    [at.FETCH_CREATIVES_COMPLETE]: (state) => ({
      ...state,
      isFetching: false,
    }),
  },
  getInitialState(),
);
