import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { Map } from 'immutable';

import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative/Preview';

import style from './creativeHeading.scss';

class CreativeHeading extends Component {
  handleViewAllCreativeClick = (e) => {
    const { onViewAllCreativeClick } = this.props;

    if (onViewAllCreativeClick) {
      onViewAllCreativeClick(e);
    }
  };

  handleViewPrevCreativeClick = (e) => {
    const { onViewPrevCreativeClick } = this.props;

    if (onViewPrevCreativeClick) {
      onViewPrevCreativeClick(e);
    }
  };

  handleViewNextCreativeClick = (e) => {
    const { onViewNextCreativeClick } = this.props;

    if (onViewNextCreativeClick) {
      onViewNextCreativeClick(e);
    }
  };

  handleViewModerationSummaryClick = (e) => {
    const { onViewModerationSummaryClick } = this.props;

    if (onViewModerationSummaryClick) {
      onViewModerationSummaryClick(e);
    }
  };

  handleViewAssetHistoryClick = (e) => {
    const { onViewAssetHistoryClick } = this.props;

    if (onViewAssetHistoryClick) {
      onViewAssetHistoryClick(e);
    }
  };

  render() {
    const { creative, showModerationSummaryLink, showAssetHistoryLink, showPrevNextLinks, t } = this.props;

    if (!creative.size) {
      return null;
    }

    return (
      <div className={style.component}>
        <HeadingGroup
          intro={t('Creative section introduction')}
          subNavigation={
            <SubNavigation
              active={SubNavigationLabels.CURRENT_CREATIVE}
              creative={creative}
              onAllClick={this.handleViewAllCreativeClick}
              onPrevClick={this.handleViewPrevCreativeClick}
              onNextClick={this.handleViewNextCreativeClick}
              onModerationSummaryClick={this.handleViewModerationSummaryClick}
              onAssetHistoryClick={this.handleViewAssetHistoryClick}
              showPrevNextLinks={showPrevNextLinks}
              showModerationSummaryLink={showModerationSummaryLink}
              showAssetHistoryLink={showAssetHistoryLink}
            />
          }
          title={t('Creative')}
        />
        <span className={style.details}>
          <span className={style.detail}>
            <Icon iconType={IconTypes.TV} />
            &nbsp;
            {creative.get('frame-specification').get('name')} - &nbsp;
            {creative
              .get('frame-specification')
              .get('width')
              .toString()
              .concat(' x ', creative.get('frame-specification').get('height'))}
          </span>
          <span className={style.detail}>
            {t('Last updated')}: {moment(creative.get('updated-at')).fromNow()}
          </span>
        </span>
      </div>
    );
  }
}

CreativeHeading.propTypes = {
  creative: PropTypes.instanceOf(Map).isRequired,
  onViewAllCreativeClick: PropTypes.func.isRequired,
  onViewPrevCreativeClick: PropTypes.func,
  onViewNextCreativeClick: PropTypes.func,
  onViewModerationSummaryClick: PropTypes.func,
  onViewAssetHistoryClick: PropTypes.func,
  showModerationSummaryLink: PropTypes.bool,
  showAssetHistoryLink: PropTypes.bool,
  showPrevNextLinks: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CreativeHeading.defaultProps = {
  onViewPrevCreativeClick: () => {},
  onViewNextCreativeClick: () => {},
  onViewModerationSummaryClick: () => {},
  onViewAssetHistoryClick: () => {},
  showModerationSummaryLink: false,
  showAssetHistoryLink: false,
  showPrevNextLinks: false,
};

export default withNamespaces(['common', 'creative'], { wait: false })(CreativeHeading);
