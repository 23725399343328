import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { includes, reject } from 'lodash';
import Checkbox from 'assets/components/presentational/Checkbox';

import style from './dayPartForm.scss';

const Options = {
  TIME_OF_DAY: 0,
};

class DayPartForm extends Component {
  handleCheckboxChanged = (value, checked, field) => {
    if (checked) {
      field.value.push(value);
    } else {
      field.value = reject(field.value, (c) => c === value);
    }

    return field.onChange(field.value);
  };

  getTimeOfDayField = () => {
    const { fields } = this.props;

    return fields[Options.TIME_OF_DAY];
  };

  render() {
    const { ruleType } = this.props;

    return (
      <div className={style.component}>
        <div className={style.grid}>
          {ruleType
            .get('options')
            .get(Options.TIME_OF_DAY)
            .map((timeOfDay, key) => {
              const selected = includes(this.getTimeOfDayField().value, timeOfDay.get('label'));

              return (
                <div className={selected ? style.gridItemSelected : style.gridItem} key={key}>
                  <span className={style.optionTitle}>{timeOfDay.get('label')}</span>

                  <Checkbox
                    checked={selected}
                    disabled={!ruleType.get('is-selected')}
                    onChange={(checked) =>
                      this.handleCheckboxChanged(timeOfDay.get('label'), checked, this.getTimeOfDayField())
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default DayPartForm;

DayPartForm.propTypes = {
  fields: PropTypes.object.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
};

DayPartForm.defaultProps = {};
