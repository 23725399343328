/* eslint-disable class-methods-use-this, no-console */

const logger = console;

export default class Logger {
  static critical(message, ...rest) {
    return this.error(message, ...rest);
  }

  static error(message, ...rest) {
    logger.error(message, ...rest);
  }

  static warning(message, ...rest) {
    logger.warn(message, ...rest);
  }

  static info(message, ...rest) {
    logger.info(message, ...rest);
  }

  static debug(message, ...rest) {
    logger.log(message, ...rest);
  }
}
