import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';
import * as at from '../actions/drop-zone';

export default handleActions(
  {
    [at.FETCH_CAMPAIGN_INVITE_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [at.FETCH_CAMPAIGN_INVITE_SUCCESS]: (state, action) => ({
      ...state,
      campaignInvite: fromJS(action.campaignInvite),
    }),
    [at.FETCH_CAMPAIGN_INVITE_COMPLETE]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [at.FETCH_FRAME_SPECIFICATIONS]: (state, action) => ({
      ...state,
      campaignInvite: state.campaignInvite.setIn(['campaign', 'frame-specifications'], action.frameSpecifications),
    }),
    [at.UPLOAD_CREATIVE_SUCCESS]: (state, action) => ({
      ...state,
      campaignInvite: state.campaignInvite.updateIn(['campaign', 'frame-specifications'], List(), (frameSpecs) =>
        frameSpecs.map((f) => {
          if (f.get('id') === action.creative.get('frame-specification-id')) {
            return f.updateIn(['creatives'], List(), (creatives) => {
              if (!creatives.find((creative) => creative.get('id') === action.creative.get('id'))) {
                return creatives.push(action.creative);
              }

              return creatives;
            });
          }
          return f;
        }),
      ),
    }),
    [at.MERGE_CREATIVE_INTO_CAMPAIGN_INVITE]: (state, action) => ({
      ...state,
      campaignInvite: state.campaignInvite.updateIn(['campaign', 'frame-specifications'], List(), (frameSpecs) =>
        frameSpecs.map((f) => {
          if (f.get('id') === action.creative.get('frame-specification-id')) {
            return f.updateIn(['creatives'], List(), (creatives) =>
              creatives.map((c) => {
                if (c.get('id') === action.creative.get('id')) {
                  return c.merge(action.creative);
                }
                return c;
              }),
            );
          }
          return f;
        }),
      ),
    }),
    [at.REPLACE_CREATIVE]: (state, action) => ({
      ...state,
      campaignInvite: state.campaignInvite.updateIn(
        ['campaign', 'frame-specifications', action.frameSpecKey, 'creatives'],
        List(),
        (creatives) =>
          creatives.map((creative) => {
            if (creative.get('id') === action.oldCreative.get('id')) {
              return action.newCreative;
            }

            return creative;
          }),
      ),
    }),
    [at.DELETE_CREATIVE_REQUEST]: (state, action) => setCreativeIsDeleting(state, action),
    [at.DELETE_CREATIVE_SUCCESS]: (state, action) => removeCreative(state, action),
    [at.DELETE_CREATIVE_COMPLETE]: (state, action) => setCreativeIsDeleting(state, action),
    [at.FETCH_CREATIVE_REQUEST]: (state) => ({
      ...state,
      isFetchingCreative: true,
    }),
    [at.FETCH_CREATIVE_SUCCESS]: (state, action) => ({
      ...state,
      creative: state.creative.clear().merge(fromJS(action.creative)),
    }),
    [at.FETCH_CREATIVE_COMPLETE]: (state) => ({
      ...state,
      isFetchingCreative: false,
    }),
    [at.SET_ACTIVE_FRAME_SPEC]: (state, action) => ({
      ...state,
      activeFrameSpecId: action.activeFrameSpecId,
    }),
    FETCH_USER_PERMISSIONS_SUCCESS: (state, action) => ({
      ...state,
      campaignInvite: state.campaignInvite.updateIn(['user', 'permissions'], List(), (permissions) => {
        if (
          action.userId === state.campaignInvite.get('user-id') &&
          action.object === 'App\\Campaign' &&
          action.objectId === state.campaignInvite.get('campaign-id')
        ) {
          return permissions.clear().merge(action.permissions);
        }
        return permissions;
      }),
    }),
  },
  {
    activeFrameSpecId: '',
    areCreativeWeightsUpdating: new Map(),
    isFetching: false,
    isFetchingCreative: false,
    campaignInvite: new Map(),
    creative: new Map(),
  },
);

/**
 * Sets the creative.is-deleting flag based on the current state and the new action
 *
 * @param {object} state Current state
 * @param {object} action Creative
 * @return {object}
 */
const setCreativeIsDeleting = (state, action) => ({
  ...state,
  campaignInvite: state.campaignInvite.updateIn(['campaign', 'frame-specifications'], List(), (frameSpecs) =>
    frameSpecs.map((f) => {
      if (f.get('id') === action.creative.get('frame-specification-id')) {
        return f.updateIn(['creatives'], List(), (creatives) =>
          creatives.map((c) => {
            if (c.get('id') === action.creative.get('id')) {
              return c.set('is-deleting', !c.get('is-deleting'));
            }
            return c;
          }),
        );
      }
      return f;
    }),
  ),
});

/**
 * Removes the given action.creative from state
 *
 * @param {object} state Current state
 * @param {object} action Creative
 * @return {object}
 */
const removeCreative = (state, action) => ({
  ...state,
  campaignInvite: state.campaignInvite.updateIn(['campaign', 'frame-specifications'], List(), (frameSpecs) =>
    frameSpecs.map((f) => {
      if (f.get('id') === action.creative.get('frame-specification-id')) {
        return f.updateIn(['creatives'], List(), (creatives) =>
          creatives.filter((c) => c.get('id') !== action.creative.get('id')),
        );
      }
      return f;
    }),
  ),
});
