import Request from 'modules/API/request';
import { notifyApiError } from 'actions/notify';

export const fetchBookingsRequest = (campaignId) => async (dispatch) => {
  try {
    const response = await Request.send({
      endpoint: `campaigns/${campaignId}/bookings`,
      method: 'GET',
      includes: ['booking-errors'],
    });
    return response.data.body.parsed;
  } catch (error) {
    dispatch(notifyApiError(error));
    throw error;
  }
};

export const promoteBookingsRequest = (campaignId, bookings = []) => async (dispatch) => {
  try {
    await Request.send({
      endpoint: `campaigns/${campaignId}/promote`,
      method: 'POST',
      data: {
        bookings: bookings.map((b) => ({
          network: b.network,
          code: b.code,
          business_unit: b['business-unit-id'],
        })),
      },
    });
  } catch (error) {
    dispatch(notifyApiError(error));
    throw error;
  }
};

export const detachBookingRequest = (campaignId, { network, code, 'business-unit-id': businessUnitId }) => async (
  dispatch,
) => {
  try {
    await Request.send({
      endpoint: `campaigns/${campaignId}/bookings/${code}/${network}/${businessUnitId}`,
      method: 'DELETE',
    });
    return Promise.resolve();
  } catch (error) {
    dispatch(notifyApiError(error));
    throw error;
  }
};

export const attachBookingRequest = (campaignId, { 'business-unit-id': businessUnitId, code, network }) => async (
  dispatch,
) => {
  try {
    await Request.send({
      endpoint: `campaigns/${campaignId}/bookings`,
      method: 'POST',
      data: {
        bookings: [
          {
            code,
            network,
            business_unit: businessUnitId, // eslint-disable-line camelcase
          },
        ],
      },
    });
  } catch (error) {
    dispatch(notifyApiError(error));
    throw error;
  }
};
