import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/patterns/Input';
import omit from 'object.omit';
import { withNamespaces } from 'react-i18next';

/**
 * Ok, we've got to provide an appropriate settings form for each type of field
 * currently only video and image. Select options have their own widget
 */
class FieldSettings extends Component {
  getFormConfiguration() {
    const { t } = this.props;
    return {
      Video: [
        {
          name: 'width',
          label: t('Width in pixels. Video must be exactly this size.'),
          type: 'number',
          constraints: 'min:1',
        },
        {
          name: 'height',
          label: t('Height in pixels. Video must be exactly this size.'),
          type: 'number',
          constraints: 'min:1',
        },
        {
          name: 'fps',
          label: t('Maximum frames per second, maximum 25'),
          type: 'number',
          constraints: 'min:1|max:25',
        },
        {
          name: 'bitrate',
          label: t('Maximum bitrate in Megabits per second, maximum 8'),
          type: 'number',
          constraints: 'min:1|max:8',
        },
      ],
      Image: [
        {
          label: t('Width'),
          name: 'width',
          hint: t('Width in pixels. Image will be resized or cropped'),
          type: 'number',
          constraints: 'min:1',
        },
        {
          label: t('Height'),
          name: 'height',
          hint: t('Height in pixels. Image will be resized or cropped'),
          type: 'number',
          constraints: 'min:1',
        },
      ],
    };
  }

  render() {
    const { fieldType, fieldSettings } = this.props;
    const formConfig = this.getFormConfiguration();

    if (!formConfig[fieldType]) {
      return null;
    }

    return (
      <div>
        {formConfig[fieldType].map((config) => (
          <Input
            key={config.name}
            value={this.extractElementValue(fieldSettings, config.name)}
            onChange={(v) => this.handleChange(v, fieldSettings, config)}
            {...omit(config, 'constraints')}
            {...this.parseConstraints(config.constraints)}
          />
        ))}
      </div>
    );
  }

  /**
   * Don't allow invalid values (apart from empty ones)
   * to be entered
   *
   * @param value
   * @param field
   * @param config
   */
  handleChange = (value, field, config) => {
    if (!this.validate(value, config) && value) {
      return;
    }
    const newValue = field.value ? JSON.parse(field.value) : {};
    newValue[config.name] = value;
    field.onChange(JSON.stringify(newValue));
  };

  /**
   * Uses the HTML5 validation API to validate input
   *
   * There is also backend validation
   *
   * @param value
   * @param config
   * @returns {boolean}
   */
  validate = (value, config) => {
    const constraints = this.parseConstraints(config.constraints);
    const input = document.createElement('input');
    if (!input.checkValidity) {
      // just rely on backend validation for now
      return true;
    }
    input.type = config.type;
    Object.keys(constraints).forEach((key) => {
      input[key] = constraints[key];
    });
    input.value = value;
    return input.checkValidity();
  };

  /**
   * Gets a settings field value out of the redux-form settings value JSON string
   * @param field
   * @param name
   */
  extractElementValue = (field, name) => (field.value ? JSON.parse(field.value)[name] : '');

  /**
   * Converts eg. "min:5|max:25|required" to {min: "5", max: "25", required: true}
   * @param constraints
   */
  parseConstraints = (constraints) =>
    Object.assign(
      ...constraints.split('|').map((i) => ({ [i.split(':')[0]]: i.split(':').length > 1 ? i.split(':')[1] : true })),
    );
}

FieldSettings.propTypes = {
  fieldType: PropTypes.string,
  fieldSettings: PropTypes.object,
  t: PropTypes.func.isRequired,
};

FieldSettings.defaultProps = {};

export default withNamespaces(['contentType', 'common'], { wait: false })(FieldSettings);
