import { events } from 'react-toolbox/lib/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import ProgressBar from 'components/patterns/ProgressBar';

export default class ConfirmButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompting: false,
      working: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (e) => {
    const { prompting } = this.state;
    if (prompting && !events.targetIsDescendant(e, ReactDOM.findDOMNode(this))) {
      this.setState({
        prompting: false,
      });
    }
  };

  handleOnClick = async () => {
    const { onConfirmClick } = this.props;
    const { prompting } = this.state;

    if (!prompting) {
      this.setState({ prompting: true });
    } else {
      this.setState({
        working: true,
      });
      try {
        await onConfirmClick();
      } finally {
        if (this.mounted) {
          this.setState({ prompting: false });
          this.setState({
            working: false,
          });
        }
      }
    }
  };

  render() {
    const {
      label,
      className,
      confirmLabel,
      icon,
      confirmIcon,
      buttonTheme,
      confirmButtonTheme,
      disabled,
      showProgress,
    } = this.props;
    const { prompting, working } = this.state;

    if (working && showProgress) {
      return <ProgressBar circular />;
    }

    return (
      <Button
        className={className}
        icon={prompting ? confirmIcon : icon}
        label={prompting ? confirmLabel : label}
        onClick={this.handleOnClick}
        theme={prompting ? confirmButtonTheme : buttonTheme}
        disabled={disabled}
      />
    );
  }
}

ConfirmButton.propTypes = {
  buttonTheme: PropTypes.string,
  className: PropTypes.string,
  confirmButtonTheme: PropTypes.string,
  confirmIcon: PropTypes.element,
  confirmLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  showProgress: PropTypes.bool,
};

ConfirmButton.defaultProps = {
  buttonTheme: ButtonThemes.PRIMARY,
  confirmButtonTheme: ButtonThemes.WARNING,
  confirmIcon: null,
  icon: null,
  showProgress: true,
};
