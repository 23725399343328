import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateUpload, removeUpload } from 'store/uploads/actions';
import { replaceCreative } from 'actions/creative';

import CreativeRow from './CreativeRow';

const mapStateToProps = (state) => ({
  uploads: state.uploads,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUpload,
      removeUpload,
      replaceCreative,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreativeRow);
