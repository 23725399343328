import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { fetchScreenshots, clearScreenshots, fetchFrame } from 'actions/campaign/mediaBuy';
import ScreenshotsPres from 'assets/components/presentational/Screenshots';
import { setActiveSection } from 'actions/campaign/navigation';

class Screenshots extends Component {
  componentDidMount() {
    const {
      dispatch,
      params: { campaignId, frameId },
    } = this.props;

    dispatch(setActiveSection('media buy'));
    dispatch(fetchFrame(campaignId, frameId));
    dispatch(fetchScreenshots(campaignId, frameId));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearScreenshots());
  }

  render() {
    const { isFetching, isFetchingFrame, frame, isLoaded, screenshots } = this.props;
    return (
      <ScreenshotsPres
        frame={frame}
        isFetching={isFetching}
        isFetchingFrame={isFetchingFrame}
        isLoaded={isLoaded}
        screenshots={screenshots}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  frame: state.campaignMediaBuy.screenshotFrame,
  isFetchingFrame: state.campaignMediaBuy.isFetchingFrame,
  isFetching: state.campaignMediaBuy.isFetchingScreenshots,
  isLoaded: state.campaignMediaBuy.areScreenshotsLoaded,
  screenshots: state.campaignMediaBuy.screenshots,
});

export default connect(mapStateToProps)(Screenshots);

Screenshots.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  frame: PropTypes.instanceOf(Map).isRequired,
  isFetchingFrame: PropTypes.bool.isRequired,
  screenshots: PropTypes.instanceOf(List).isRequired,
};
