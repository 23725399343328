import { fromJS, Map, List } from 'immutable';
import Request from 'modules/API/request';
import { get } from 'lodash';

import { error as notifyError, notifyApiError } from 'actions/notify';
import { lite as authLite } from 'actions/auth';
import { fetchCreativeForPreview as fetchCreativeForPreviewBase } from 'actions/creative';

export const AUTH_SUCCESS = 'lite-moderation/AUTH_SUCCESS';

export const FETCH_CREATIVES_SUCCESS = 'lite-moderation/FETCH_CREATIVES_SUCCESS';

export const CLEAR_CREATIVES = 'lite-moderation/CLEAR_CREATIVES';

export const FETCH_CAMPAIGN_REQUEST = 'lite-moderation/FETCH_CAMPAIGN_REQUEST';
export const FETCH_CAMPAIGN_SUCCESS = 'lite-moderation/FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_COMPLETE = 'lite-moderation/FETCH_CAMPAIGN_COMPLETE';

export const FETCH_CREATIVE_REQUEST = 'lite-moderation/FETCH_CREATIVE_REQUEST';
export const FETCH_CREATIVE_SUCCESS = 'lite-moderation/FETCH_CREATIVE_SUCCESS';
export const FETCH_CREATIVE_COMPLETE = 'lite-moderation/FETCH_CREATIVE_COMPLETE';

export const FETCH_HISTORY_REQUEST = 'lite-moderation/FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_SUCCESS = 'lite-moderation/FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_COMPLETE = 'lite-moderation/FETCH_HISTORY_COMPLETE';

export const MERGE_CREATIVE_INTO_CAMPAIGNS = 'lite-moderation/MERGE_CREATIVE_INTO_CAMPAIGNS';
export const MERGE_PAGINATION_HISTORY = 'lite-moderation/MERGE_PAGINATION_HISTORY';

export const SET_ACTIVE_FRAME_SPEC = 'lite-moderation/SET_ACTIVE_FRAME_SPEC';

const JWT_LOCAL_STORAGE_KEY = 'jwt';

export const authenticateUser = (jwt) => async (dispatch) => {
  try {
    const response = await dispatch(authLite(jwt));
    const campaignInviteId = response.data.body.parsed.key;
    localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwt);
    dispatch({ type: AUTH_SUCCESS, campaignInviteId });
    return campaignInviteId;
  } catch (e) {
    throw e;
  }
};

export const fetchCampaign = (campaignInviteId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CAMPAIGN_REQUEST });

    const response = await Request.send({
      endpoint: `creative-moderation-assignments/${campaignInviteId}/campaign`,
      includes: ['client'],
    });
    const campaign = fromJS(response.data.body.parsed);

    dispatch({ type: FETCH_CAMPAIGN_SUCCESS, campaign });
    dispatch({ type: FETCH_CAMPAIGN_COMPLETE });
    return campaign;
  } catch (error) {
    dispatch(notifyApiError(error));
    dispatch({ type: FETCH_CAMPAIGN_COMPLETE });
    return null;
  }
};

const groupCreativesIntoFrameSpecifications = (creatives) => {
  const campaign = creatives.size > 0 ? creatives.first().get('campaign', new Map()) : new Map();

  let frameSpecifications = creatives.reduce((frameSpecs, creative) => {
    const frameSpec = creative.get('frame-specification', new Map());
    const frameSpecIndex = frameSpecs.findIndex((f) => f.get('id') === frameSpec.get('id'));

    if (frameSpecIndex === -1) {
      return frameSpecs.push(frameSpec);
    }

    return frameSpecs;
  }, new List());

  creatives.forEach((creative) => {
    const cleanedUpCreative = creative.delete('campaign').delete('frame-specification');

    const creativeFrameSpecId = creative.get('frame-specification-id', '');
    const frameSpecIndex = frameSpecifications.findIndex((f) => f.get('id') === creativeFrameSpecId);

    if (frameSpecIndex !== -1) {
      frameSpecifications = frameSpecifications.update(frameSpecIndex, (frameSpec) => {
        const c = frameSpec.get('creatives', new List());
        return frameSpec.set('creatives', c.push(cleanedUpCreative));
      });
    }
  });

  return campaign.set('frame-specifications', frameSpecifications);
};

export const fetchFrameSpecifications = (campaignInviteId) => async (dispatch) => {
  try {
    const response = await Request.send({
      includes: ['frame-specification', 'asset', 'campaign.client'],
      sorts: ['+frame_specification_id'],
      endpoint: `creative-moderation-assignments/${campaignInviteId}/creatives`,
      pagination: Request.ALL_PAGES,
    });

    dispatch({
      type: FETCH_CREATIVES_SUCCESS,
      campaign: groupCreativesIntoFrameSpecifications(fromJS(response.data.body.parsed)),
    });
  } catch (error) {
    dispatch(notifyError(get(error, 'response.data.errors')));
  }
};

export const fetchCreativeForPreview = (creativeId) => (dispatch) => {
  dispatch({ type: FETCH_CREATIVE_REQUEST, creativeId });

  return dispatch(
    fetchCreativeForPreviewBase(
      creativeId,
      ['prepared-asset', 'frame-specification'],
      ['preview-frame', 'preview-content-items', 'preview-rule-sets'],
    ),
  )
    .then((creative) => {
      dispatch({ type: FETCH_CREATIVE_SUCCESS, creative });
      dispatch({ type: FETCH_CREATIVE_COMPLETE, creativeId });

      return Promise.resolve(fromJS(creative));
    })
    .catch(() => {
      dispatch({ type: FETCH_CREATIVE_COMPLETE, creativeId });
    });
};

export const mergeCreativeIntoCampaigns = (creative) => ({
  type: MERGE_CREATIVE_INTO_CAMPAIGNS,
  creative,
});

export const fetchCreative = (creativeId, extraOptions = {}) => (dispatch) => {
  const options = {
    endpoint: `creatives/${creativeId}`,
  };

  Object.assign(options, extraOptions);

  return Request.send(options)
    .then((response) => {
      dispatch(mergeCreativeIntoCampaigns(fromJS(response.data.body.parsed)));

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const mergePaginationHistory = (pagination) => ({
  type: MERGE_PAGINATION_HISTORY,
  pagination,
});

export const fetchModerationHistory = (campaignInviteId, pageNumber = 1) => (dispatch) => {
  const options = {
    includes: ['creative.asset', 'creative.frame-specification'],
    pagination: {
      pageNumber,
    },
    endpoint: `creative-moderation-assignments/${campaignInviteId}/history`,
  };

  dispatch({ type: FETCH_HISTORY_REQUEST });

  return Request.send(options)
    .then((response) => {
      const history = fromJS(response.data.body.parsed);

      dispatch(mergePaginationHistory(response.data.body.meta.pagination));

      dispatch({ type: FETCH_HISTORY_SUCCESS, history });
      dispatch({ type: FETCH_HISTORY_COMPLETE });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const clearCreatives = () => ({
  type: CLEAR_CREATIVES,
});

export const setActiveFrameSpec = (activeFrameSpecId) => ({
  type: SET_ACTIVE_FRAME_SPEC,
  activeFrameSpecId,
});
