import { handleActions } from 'redux-actions';
import { fromJS, List } from 'immutable';

const getInitialState = () => ({
  airlines: List(),
  airports: List(),
});

const transport = handleActions(
  {
    TRANSPORT_FETCH_REQUEST: (state) => ({
      ...state,
    }),
    TRANSPORT_FETCH_SUCCESS: (state, action) => ({
      ...state,
      [action.resource]:
        action.resource in state && List.isList(state[action.resource])
          ? state[action.resource].update((existingItems) => {
              const actionItems = fromJS(action.data);

              if (!List.isList(actionItems)) {
                return existingItems;
              }

              const newItems = actionItems.filter(
                (actionItem) =>
                  existingItems.findIndex((exitingItem) => exitingItem.get('id') === actionItem.get('id')) === -1,
              );

              return existingItems
                .map((existingItem) => {
                  const matchedItem = actionItems.find((actionItem) => actionItem.get('id') === existingItem.get('id'));

                  if (matchedItem) {
                    return existingItem.merge(matchedItem);
                  }

                  return existingItem;
                })
                .concat(newItems)
                .sortBy((i) => i.get('name', ''));
            })
          : fromJS(action.data),
    }),
    TRANSPORT_FETCH_COMPLETE: (state) => ({
      ...state,
    }),
  },
  getInitialState(),
);

export default transport;
