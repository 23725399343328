import config from 'app-config';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';
import moment from 'moment';
import deepmerge from 'deepmerge';

import Request from 'modules/API/request';
import Logger from 'logger';
import {
  CreativeTypes,
  isCreativeProcessed,
  getCreativeTypeIcon,
  getCreativeModerationDescription,
} from 'modules/Helpers';
import { assetDownloadUrl } from 'modules/Helpers/creative';

import { uploadsPropTypes } from 'store/uploads/propTypes';

import Tooltip from 'components/patterns/Tooltip';
import ProgressBar from 'components/patterns/ProgressBar';
import Warnings from 'components/common/Warnings';
import Upload from 'assets/components/containers/Upload';
import Icon, { IconTypes, IconSizes, IconColors } from 'assets/components/presentational/Icon';
import Tag, { TagTheme } from 'assets/components/presentational/Tag';
import ModerationStatusChip from 'assets/components/presentational/ModerationStatusChip';
import Dialog from 'assets/components/presentational/Dialog';
import Checkbox from 'assets/components/presentational/Checkbox';

import Thumbnail from 'assets/components/presentational/Rows/Thumbnail';
import Action from 'assets/components/presentational/Rows/Action';

import style from './CreativeRow.scss';

const cx = classnames.bind(style);

const TooltipIcon = Tooltip(Icon);

class CreativeRow extends Component {
  constructor(props, context) {
    super(props, context);

    this.processingTimeout = null;

    this.state = {
      isLoaded: false,
      isReplacing: false,
      isProcessingTimedout: false,
      additionalCreativeData: {
        asset: {
          length: 0,
          url: '',
        },
        user: {
          name: '',
        },
        thumbnail: {
          url: '',
        },
        'created-at': '',
        warnings: [],
        type: CreativeTypes.UNPROCESSED,
        'html-asset-url': '',
        'prepared-asset': {},
        'user-id': null,
      },
    };
  }

  componentDidMount() {
    this.startProcessingTimeout();
    this.fetchData();
  }

  componentDidUpdate() {
    if (!this.processingTimeout) {
      this.startProcessingTimeout();
    }
  }

  componentWillUnmount() {
    this.clearProcessingTimeout();
  }

  fetchAdditionalCreativeData = async () => {
    const { creative } = this.props;

    try {
      const response = await Request.send({
        endpoint: `creatives/${creative.get('id')}`,
        includes: ['asset', 'user', 'prepared-asset', 'thumbnail', 'content-types', 'rule-sets', 'warnings'],
      });

      this.setState((prevState) => {
        const data = { ...response.data.body.parsed };

        ['user', 'thumbnail', 'asset', 'warnings'].forEach((key) => {
          if (data[key] == null) {
            delete data[key];
          }
        });

        return { additionalCreativeData: deepmerge(prevState.additionalCreativeData, data) };
      });
    } catch (error) {
      Logger.error('Additional creative data failed', { creativeId: creative.get('id'), error });
    }
  };

  fetchData = () => {
    this.setState({
      isLoaded: true,
    });

    const { creative, onLoad } = this.props;
    this.fetchAdditionalCreativeData();
    onLoad(creative.get('id'));
  };

  isReplaceable = () => {
    const { canUserReplaceCreative } = this.props;
    const { additionalCreativeData } = this.state;
    return isCreativeProcessed(additionalCreativeData) && canUserReplaceCreative(additionalCreativeData);
  };

  startProcessingTimeout = () => {
    this.processingTimeout = setTimeout(() => {
      this.setState({
        isProcessingTimedout: true,
      });
      this.clearProcessingTimeout();
    }, 5000);
  };

  clearProcessingTimeout = () => {
    clearTimeout(this.processingTimeout);
    this.processingTimeout = null;
  };

  handleCreativeDeleteClick = () => {
    const { creative, onDeleteClick } = this.props;

    if (typeof onDeleteClick === 'function') {
      onDeleteClick(creative);
    }
  };

  handleCreativeDeleteConfirmClick = () => {
    const { creative, onDeleteConfirmClick, frameSpecificationIndex } = this.props;

    if (typeof onDeleteConfirmClick === 'function') {
      onDeleteConfirmClick(creative, frameSpecificationIndex);
    }
  };

  handleCreativeDeleteCancelClick = () => {
    const { creative, onDeleteCancelClick } = this.props;

    if (typeof onDeleteCancelClick === 'function') {
      onDeleteCancelClick(creative);
    }
  };

  handleReplaceSuccess = (file, response) => {
    const { id: creativeId } = response;
    const { uploads, updateUpload, removeUpload } = this.props;

    // This is handling an edge case. If the processing of the file happens very fast,
    // and the endpoind that we call to upload the file returns a response after some delay,
    // it may happen that the pusher event comes before the endpoint response
    const uploadAddedFromPusherEvent = uploads.find((upload) => upload.creativeId === creativeId);
    if (uploadAddedFromPusherEvent) {
      const { replaceCreative, activeCampaignId, frameSpecificationIndex, creative } = this.props;
      replaceCreative(frameSpecificationIndex, creativeId, {}, activeCampaignId, creative, {
        includes: ['rule-sets', 'content-types'],
      });
      removeUpload(uploadAddedFromPusherEvent.uniqueIdentifier);
      removeUpload(file.uniqueIdentifier);
      removeUpload(creativeId);
    } else {
      const { creative } = this.props;
      const { uniqueIdentifier } = file;
      updateUpload(uniqueIdentifier, {
        creativeId,
        oldCreativeId: creative.get('id'),
      });
    }
  };

  handleUploadFailed = (errors, file) => {
    Logger.error('Upload failed', { errors, file });
  };

  handleCheckboxChange = (checked) => {
    const { creative, onCheckboxChange } = this.props;

    if (typeof onCheckboxChange === 'function') {
      onCheckboxChange(creative, checked);
    }
  };

  isUploadError() {
    const { creative } = this.props;

    return creative.get('-failed');
  }

  renderRuleSetsAndContentTypes() {
    const { creative } = this.props;

    const ruleSets = creative.get('rule-sets', List()).toJS();
    const contentTypes = creative.get('content-types', List()).toJS();

    return (
      <div>
        {ruleSets.length
          ? ruleSets.map((rule) => (
              <span key={rule.id}>
                <Tag className={style.creativeTag} tagTheme={TagTheme.GREY} copy={rule.name} />
              </span>
            ))
          : null}
        {contentTypes.length
          ? contentTypes.map((contentType) => (
              <span key={contentType.id}>
                <Tag className={style.creativeTag} tagTheme={TagTheme.DEFAULT} copy={contentType.name} />
              </span>
            ))
          : null}
      </div>
    );
  }

  renderRowWithUpload() {
    const { t, creative } = this.props;
    const query = {};
    if (config.xdebug.enabled) {
      query.XDEBUG_SESSION_START = config.xdebug.key;
    }

    return (
      <div className={style.creativeUpload}>
        <Upload
          target={`${config.api.url}/creatives/${creative.get('id')}/files`}
          query={query}
          allowFileBrowsing={false}
          multiple={false}
          confirmDrop
          confirmDropText={t('Are you sure you want to overwrite {{name}} with a new file?', {
            name: creative.get('name'),
          })}
          onSuccess={this.handleReplaceSuccess}
          onFail={this.handleUploadFailed}
          dropzoneRef={(el) => {
            this.dropzoneRef = el;
          }}
        >
          {this.renderRow()}
        </Upload>
      </div>
    );
  }

  renderProgressBar = () => (
    <div className={style.progressContainer}>
      <div className={style.rowBlocker} />
      <ProgressBar />
    </div>
  );

  renderRow() {
    const { creative, isSelected, uploads } = this.props;
    const { isProcessingTimedout } = this.state;

    const isReprocessing =
      uploads.filter((upload) => upload.creativeId === upload.oldCreativeId && upload.creativeId === creative.get('id'))
        .length > 0;

    return (
      <div>
        <div
          className={cx('creative', {
            isSelected,
          })}
        >
          {isReprocessing && this.renderProgressBar()}
          <div className={style.thumbnail}>
            {this.renderThumbnail()}
            {!isCreativeProcessed(creative) && !isProcessingTimedout && <ProgressBar />}
          </div>
          <div className={style.details}>
            <div className={style.info}>
              <div className={style.name}>
                <span className={style.creativeTypeIcon}>
                  <Icon iconType={getCreativeTypeIcon(creative.get('type'))} />
                </span>
                <strong className={style.creativeName}>{creative.get('name')}</strong>&nbsp;
              </div>
              <div className={style.meta}>{this.renderUploadedBy()}</div>
            </div>
            <div className={style.rulesets}>{this.renderRuleSetsAndContentTypes()}</div>
            <div className={style.status}>
              {this.renderDefaultStatus()}
              {this.renderModerationStatus()}
            </div>
          </div>
          <div className={style.actions}>
            {this.renderViewAction()}
            {this.renderReplaceAction()}
            {this.renderDeleteAction()}
            {this.renderDownloadAction()}
            {this.renderSelectAction()}
          </div>
        </div>
        {this.renderWarnings()}
      </div>
    );
  }

  renderDefaultStatus() {
    const { creative, t } = this.props;

    if (creative.get('is-default')) {
      return (
        <span className={style.defaultIcon}>
          <TooltipIcon iconType={IconTypes.GRADE} tooltip={t('This is a default creative')} />
        </span>
      );
    }

    return null;
  }

  renderModerationStatus() {
    const { creative } = this.props;

    return (
      <ModerationStatusChip
        label={getCreativeModerationDescription(creative.get('moderation-status'))}
        status={creative.get('moderation-status')}
      />
    );
  }

  renderViewAction() {
    const { creativeUrl } = this.props;
    const { additionalCreativeData } = this.state;
    const isViewable = isCreativeProcessed(additionalCreativeData);

    return (
      <Action
        disabled={!isViewable}
        icon={<Icon iconType={IconTypes.VISIBILITY} />}
        to={isViewable ? creativeUrl : ''}
        tooltip="Preview"
      />
    );
  }

  handleCreativeReplaceClick = () => {
    this.dropzoneRef.open();
  };

  renderReplaceAction() {
    return (
      <Action
        disabled={!this.isReplaceable()}
        icon={<Icon iconType={IconTypes.SWAP_HORIZ} />}
        onClick={this.handleCreativeReplaceClick}
        tooltip="Replace creative"
      />
    );
  }

  renderDeleteAction() {
    const { creative, canUserDeleteCreative } = this.props;

    return (
      <Action
        disabled={!canUserDeleteCreative || creative.get('is-deleting')}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        onClick={() => this.handleCreativeDeleteClick()}
        tooltip="Delete creative"
      />
    );
  }

  renderDownloadAction() {
    const { additionalCreativeData } = this.state;
    const isDownloadable = isCreativeProcessed(additionalCreativeData);

    if (!isDownloadable) {
      return <Action disabled icon={<Icon iconType={IconTypes.FILE_DOWNLOAD} />} />;
    }

    return (
      <a href={assetDownloadUrl(additionalCreativeData)} target="_blank" rel="noopener noreferrer">
        <Action icon={<Icon iconType={IconTypes.FILE_DOWNLOAD} />} tooltip="Download" />
      </a>
    );
  }

  renderSelectAction = () => {
    const { isSelected, shouldShowRowActions } = this.props;

    if (!shouldShowRowActions) {
      return null;
    }

    return <Checkbox className={style.creativeCheckbox} checked={isSelected} onChange={this.handleCheckboxChange} />;
  };

  renderThumbnail() {
    const { creative, creativeUrl, imageFallbackSrc } = this.props;
    const { additionalCreativeData } = this.state;
    const videoLength = creative.get('type') === CreativeTypes.VIDEO && additionalCreativeData.asset.length;
    const image = additionalCreativeData.thumbnail.url;
    const src = image || imageFallbackSrc;

    return (
      <Thumbnail
        key={src}
        src={src}
        alt={`Creative: ${creative.get('name', '')}`}
        link={creativeUrl}
        seconds={videoLength || null}
      />
    );
  }

  renderUploadedBy() {
    const { t } = this.props;
    const { additionalCreativeData } = this.state;
    const userName = additionalCreativeData.user.name;
    const uploadTime = moment(additionalCreativeData['created-at']).fromNow();

    return (
      <span className={style.uploadedBy}>
        <Icon iconType={IconTypes.ACCOUNT_CIRCLE} size={IconSizes.SMALL} />
        {userName ? (
          <span>
            {t('Uploaded by')}: {userName} - {uploadTime}
          </span>
        ) : (
          <span>
            {t('Uploaded')} - {uploadTime}
          </span>
        )}
      </span>
    );
  }

  renderWarnings() {
    const { additionalCreativeData } = this.state;
    const { warnings } = additionalCreativeData;

    if (!warnings.length) {
      return null;
    }

    return (
      <div className={style.warnings}>
        <Warnings
          warnings={warnings.map((warning) => warning.message)}
          icon={<Icon iconType={IconTypes.WARNING} color={IconColors.RED} />}
        />
      </div>
    );
  }

  renderDeleteDialog() {
    const { isDeleting, creative, t } = this.props;

    const actions = [
      { label: t('Cancel'), onClick: () => this.handleCreativeDeleteCancelClick(creative) },
      { label: t('Yes'), onClick: () => this.handleCreativeDeleteConfirmClick(creative) },
    ];

    return (
      <Dialog
        actions={actions}
        active={isDeleting}
        onEscKeyDown={() => this.handleCreativeDeleteCancelClick(creative)}
        onOverlayClick={() => this.handleCreativeDeleteCancelClick(creative)}
        type="small"
      >
        <p>{t('Are you sure you want to delete {{name}}?', { name: creative.get('name') })}</p>
      </Dialog>
    );
  }

  render() {
    const { isLoaded, isReplacing } = this.state;

    const allowUpload = this.isReplaceable() && (isLoaded || this.isUploadError());
    const row = allowUpload ? this.renderRowWithUpload() : this.renderRow();

    return (
      <div>
        {isReplacing ? null : row}
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

CreativeRow.propTypes = {
  canUserReplaceCreative: PropTypes.func,
  uploads: uploadsPropTypes.isRequired,
  updateUpload: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  removeUpload: PropTypes.func.isRequired,
  activeCampaignId: PropTypes.string.isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  creativeUrl: PropTypes.string.isRequired,
  frameSpecificationIndex: PropTypes.number.isRequired,
  replaceCreative: PropTypes.func.isRequired,
  imageFallbackSrc: PropTypes.string,
  isDeleting: PropTypes.bool,
  isSelected: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDeleteCancelClick: PropTypes.func,
  onDeleteConfirmClick: PropTypes.func,
  onLoad: PropTypes.func,
  t: PropTypes.func.isRequired,
  shouldShowRowActions: PropTypes.bool,
  canUserDeleteCreative: PropTypes.bool,
};

CreativeRow.defaultProps = {
  canUserReplaceCreative: () => {},
  imageFallbackSrc: '',
  isDeleting: false,
  isSelected: false,
  onLoad: () => {},
  onCheckboxChange: () => {},
  onDeleteClick: () => {},
  onDeleteCancelClick: () => {},
  onDeleteConfirmClick: () => {},
  shouldShowRowActions: false,
  canUserDeleteCreative: false,
};

export default withNamespaces(['common', 'creative', 'upload', 'errorCodes'], { wait: false })(CreativeRow);
