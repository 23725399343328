import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { redirect } from 'modules/Helpers';
import { mapDispatchToProps } from './Logout.connected';

class Logout extends Component {
  componentDidMount() {
    const { logout, location } = this.props;
    logout();
    const search = queryString.parse(location.search);
    redirect(`/auth/login?next=${search.next}`);
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(Logout);
