import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { redirect, CreativeModerationStatuses, getURLQueryString } from 'modules/Helpers';
import Input from 'components/patterns/Input';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Dropdown from 'assets/components/presentational/Dropdown';
import style from './contentFilters.scss';

class ContentItemsFilters extends Component {
  componentDidMount = () => {
    const {
      location: {
        query: { contentTypeId, contentItemName, modStatus },
      },
    } = this.props;

    if (contentTypeId || contentItemName || modStatus) {
      this.setContentFilters(this.props, true);
    }
  };

  componentDidUpdate = (prevProps) => {
    this.setContentFilters(prevProps);
  };

  pageNumberHasChanged = (prevProps) => {
    const {
      params: { pageNumber: prevPageNumber },
    } = prevProps;
    const {
      params: { pageNumber },
    } = this.props;

    const pageNumberHasChanged = prevPageNumber !== pageNumber;
    const pageNumberExists = typeof prevPageNumber !== 'undefined';
    const pageNumberIsNumber = !Number.isNaN(parseInt(prevPageNumber));

    return pageNumberHasChanged && pageNumberExists && pageNumberIsNumber;
  };

  filterHasChanged = (prevProps, forceUpdate) => {
    const {
      location: {
        query: { contentTypeId: prevContentTypeId, contentItemName: prevContentItemName, modStatus: prevModStatus },
      },
    } = prevProps;
    const {
      onContentTypeFilterChange,
      onContentModerationStatusFilterChange,
      onContentSearchValueChange,
      location: {
        query: { contentTypeId, contentItemName, modStatus },
      },
    } = this.props;

    const filterHasChanged = prevContentTypeId !== contentTypeId;
    const searchHasChanged = prevContentItemName !== contentItemName;
    const modStatusHasChanged = prevModStatus !== modStatus;

    if (forceUpdate || filterHasChanged) onContentTypeFilterChange(contentTypeId);
    if (forceUpdate || searchHasChanged) onContentSearchValueChange(contentItemName);
    if ((forceUpdate && onContentModerationStatusFilterChange) || modStatusHasChanged)
      onContentModerationStatusFilterChange(modStatus);

    return filterHasChanged || searchHasChanged || modStatusHasChanged;
  };

  setContentFilters = (prevProps, forceUpdate = false) => {
    const {
      params: { pageNumber },
      location: {
        query: { contentTypeId, contentItemName, modStatus },
      },
      fetchContentItems,
    } = this.props;

    const pageChanged = this.pageNumberHasChanged(prevProps);
    const filterChanged = this.filterHasChanged(prevProps, forceUpdate);

    if (!forceUpdate && !pageChanged && !filterChanged) {
      return;
    }

    const opts = {
      contentTypeId,
      contentItemName,
      modStatus,
    };

    fetchContentItems(pageNumber, opts);
  };

  handleContentTypeFilterChange = (value) => {
    const {
      activeNavSection,
      params: { campaignId },
    } = this.props;
    const queryString = getURLQueryString({ contentTypeId: value });

    return redirect(`/campaigns/${campaignId}/${activeNavSection}/1${queryString}`);
  };

  handleModerationStatusFilterChange = (value) => {
    const {
      activeNavSection,
      params: { campaignId },
    } = this.props;
    const queryString = getURLQueryString({ modStatus: value });

    return redirect(`/campaigns/${campaignId}/${activeNavSection}/1${queryString}`);
  };

  handleSearchChange = (value) => {
    const {
      activeNavSection,
      params: { campaignId },
    } = this.props;
    const queryString = getURLQueryString({ contentItemName: value });
    const shouldReplaceHistory = true;

    return redirect(`/campaigns/${campaignId}/${activeNavSection}/1${queryString}`, shouldReplaceHistory);
  };

  renderFilterContentTypes = () => {
    const { contentTypes, selectedContentTypeFilter, contentTypeFilterLabel, t } = this.props;

    const sourceArray = contentTypes
      .map((c) => ({
        value: c.get('id'),
        label: c.get('name'),
      }))
      .toJS();

    // add a blank row to reset filter
    sourceArray.unshift({
      value: '',
      label: t('All'),
    });

    return (
      <div className={style.contentFilterWrapper}>
        <Dropdown
          className={style.dropdownFilter}
          label={contentTypeFilterLabel}
          onChange={this.handleContentTypeFilterChange}
          source={sourceArray}
          value={selectedContentTypeFilter}
        />
      </div>
    );
  };

  renderFilterModerationStatuses = () => {
    const { activeNavSection, selectedModerationStatusFilter, moderationStatusFilterLabel } = this.props;

    if (activeNavSection === 'moderation') {
      return null;
    }

    const sourceArray = Object.keys(CreativeModerationStatuses).map((key) => ({
      value: CreativeModerationStatuses[key],
      label: key.toLowerCase().replace(/^[a-z]/, (x) => x.toUpperCase()),
    }));

    const value = parseInt(selectedModerationStatusFilter);

    // add a blank row to reset filter
    sourceArray.unshift({
      value: '',
      label: '',
    });

    return (
      <div className={style.contentFilterWrapper}>
        <Dropdown
          className={style.dropdownFilter}
          allowBlank
          label={moderationStatusFilterLabel}
          onChange={this.handleModerationStatusFilterChange}
          source={sourceArray}
          value={value}
        />
      </div>
    );
  };

  renderContentSearch = () => {
    const { searchContent = '', searchPlaceholderText } = this.props;

    return (
      <div className={style.contentFilterWrapper}>
        <Input
          className={style.contentTypesSearch}
          type="text"
          placeholder={searchPlaceholderText}
          icon={<Icon iconType={IconTypes.SEARCH} />}
          onChange={this.handleSearchChange}
          value={decodeURIComponent(searchContent)}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderFilterContentTypes()}
        {this.renderFilterModerationStatuses()}
        {this.renderContentSearch()}
      </div>
    );
  }
}

export default withNamespaces(['common'])(ContentItemsFilters);

ContentItemsFilters.propTypes = {
  activeNavSection: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  contentTypes: PropTypes.instanceOf(List).isRequired,
  contentTypeFilterLabel: PropTypes.string.isRequired,
  selectedContentTypeFilter: PropTypes.string,
  moderationStatusFilterLabel: PropTypes.string,
  selectedModerationStatusFilter: PropTypes.string,
  onContentTypeFilterChange: PropTypes.func,
  onContentSearchValueChange: PropTypes.func,
  onContentModerationStatusFilterChange: PropTypes.func,
  searchPlaceholderText: PropTypes.string,
  searchContent: PropTypes.string,
  fetchContentItems: PropTypes.func.isRequired,
  t: PropTypes.func,
};

ContentItemsFilters.defaultProps = {};
