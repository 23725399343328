import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as BaseDialog } from 'react-toolbox/lib/dialog';
import style from './dialog.scss';

const Dialog = ({ className, ...rest }) => <BaseDialog {...rest} className={style.component.concat(' ', className)} />;

export default Dialog;

Dialog.propTypes = {
  actions: PropTypes.array,
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onEscKeyDown: PropTypes.func,
  onOverlayClick: PropTypes.func,
  onOverlayMouseDown: PropTypes.func,
  onOverlayMouseMove: PropTypes.func,
  onOverlayMouseUp: PropTypes.func,
  theme: PropTypes.shape({
    active: PropTypes.string,
    body: PropTypes.string,
    button: PropTypes.string,
    dialog: PropTypes.string,
    navigation: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
  type: PropTypes.string,
};

Dialog.defaultProps = {
  actions: [],
  active: false,
  className: '',
  type: 'normal',
};
