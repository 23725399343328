import React from 'react';
import PropTypes from 'prop-types';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import style from './headingGroup.scss';

const propTypes = {
  intro: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subNavigation: PropTypes.node,
};

const defaultProps = {
  subNavigation: null,
};

const HeadingGroup = ({ intro, subNavigation, title }) => (
  <div>
    <Heading className={style.heading} size={HeadingSizes.LARGEST} tag={HeadingTags.H1}>
      {title}
    </Heading>

    {subNavigation}

    <span className={style.intro}>
      {intro.split('\n').map((item, key) => (
        <span key={key}>
          {item}
          <br />
        </span>
      ))}
    </span>
  </div>
);

HeadingGroup.propTypes = propTypes;
HeadingGroup.defaultProps = defaultProps;

export default HeadingGroup;
