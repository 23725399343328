import { CAMPAIGN } from 'app-constants';
import { STAT_ERROR, STAT_PROMOTED, STAT_PROMOTING, STAT_UNPROMOTED } from './reducer';

export const getBookings = (state) => state.bookings || [];

export const getTotalBookings = (state) => getBookings(state).length;

export const getPromotedTotal = (state) =>
  getBookings(state).filter((booking) => booking['summary-category'] === STAT_PROMOTED).length;

export const getPromotingTotal = (state) =>
  getBookings(state).filter((booking) => booking['summary-category'] === STAT_PROMOTING).length;

export const getUnpromotedTotal = (state) =>
  getBookings(state).filter((booking) => booking['summary-category'] === STAT_UNPROMOTED).length;

export const getHaveErrorsTotal = (state) =>
  getBookings(state).filter((booking) => booking['summary-category'] === STAT_ERROR).length;

export const getPromotableTotal = (state) =>
  getBookings(state)
    .filter((booking) => booking['remote-status'].canSend)
    .filter((booking) => !booking['remote-status'].isFailed).length;

export const isCampaignFullyPromotable = (state) => getPromotableTotal(state) === getTotalBookings(state);

export const isCampaignPartiallyPromotable = (state) =>
  getPromotableTotal(state) > 0 && getPromotableTotal(state) < getTotalBookings(state);

export const isCampaignFullyPromoted = (state) =>
  state.campaign.campaign.get('promotion-status') === CAMPAIGN.FULLY_PROMOTED;

export const isCampaignPartiallyPromoted = (state) =>
  state.campaign.campaign.get('promotion-status') === CAMPAIGN.PARTIALLY_PROMOTED;

export const isCampaignUnpromoted = (state) => state.campaign.campaign.get('promotion-status') === CAMPAIGN.UNPROMOTED;
