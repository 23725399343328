import { handleActions } from 'redux-actions';
import * as at from 'actions/campaign/contentSchedule';

const getInitialState = () => ({
  isFetching: false,
  activeFrameSpecId: '',
});

export default handleActions(
  {
    [at.FETCH_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [at.FETCH_SUCCESS]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [at.SET_ACTIVE_FRAME_SPEC]: (state, action) => ({
      ...state,
      activeFrameSpecId: action.activeFrameSpecId,
    }),
  },
  getInitialState(),
);
