import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';

import FEATURES from 'features';
import { uploadsPropTypes } from 'store/uploads/propTypes';
import { userHasCampaignPermission } from 'store/user/helpers';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative';
import BaseCreative from 'assets/components/containers/Campaign/Creative/BaseCreative';
import { CreativeRowActions } from 'assets/components/containers/Campaign/Creative/CreativeRows';

class Creative extends Component {
  state = {
    isFetching: true,
  };

  clearAndFetchFrameSpecifications = () => {
    const {
      fetchFrameSpecifications,
      params: { campaignId },
      clearCampaignRelation,
      clearFrameSpecsFilter,
      campaign,
    } = this.props;
    clearFrameSpecsFilter(['moderationStatus']);
    clearCampaignRelation(campaignId, ['frame-specifications']);
    const options = campaign.get('is-exchange-holding') ? { filters: [{ unmoderated: true }] } : {};
    fetchFrameSpecifications(campaignId, options).then(() => {
      this.setState({
        isFetching: false,
      });
      this.setFrameSpecsFilter();
    });
  };

  setFrameSpecsFilter = () => {
    const moderationStatus = {};
    const { frameSpecifications, setFrameSpecsFilter, campaign } = this.props;
    const defaultModerationStatus = campaign.get('is-exchange-holding', false);

    frameSpecifications.forEach((frameSpec) => {
      moderationStatus[frameSpec.get('id')] = defaultModerationStatus;
    });

    const keyPath = ['moderationStatus'];
    setFrameSpecsFilter(moderationStatus, keyPath);
  };

  canUserReplaceCreative = (creative) => {
    const { activeCampaignId, user } = this.props;
    if (userHasCampaignPermission(user, activeCampaignId, 'replace_creative')) {
      return true;
    }

    const canUpload = userHasCampaignPermission(user, activeCampaignId, 'upload_creative');
    const canDelete = userHasCampaignPermission(user, activeCampaignId, 'delete_creative');

    if (canUpload && canDelete) {
      return true;
    }

    if (creative['user-id'] !== user.get('id')) {
      return false;
    }

    if (userHasCampaignPermission(user, activeCampaignId, 'replace_own_creative')) {
      return true;
    }

    return canUpload && userHasCampaignPermission(user, activeCampaignId, 'delete_own_creative');
  };

  handleCreativeDeleteConfirmClick = (creative, frameSpecificationIndex) => {
    const { activeCampaignId, deleteCreative } = this.props;
    return deleteCreative(
      activeCampaignId,
      'creatives',
      ['frame-specifications', frameSpecificationIndex, 'creatives'],
      creative,
    );
  };

  renderHeading = () => {
    const {
      params: { campaignId },
      t,
      user,
    } = this.props;
    return (
      <HeadingGroup
        title={t('Creative')}
        intro={t('Creative section introduction')}
        subNavigation={<SubNavigation active={SubNavigationLabels.ALL_CREATIVE} campaignId={campaignId} user={user} />}
      />
    );
  };

  getAllowedRowActions = () => {
    const { activeCampaignId, user, campaign } = this.props;

    const {
      DELETE_MULTIPLE,
      SET_SELECTED_AS_DEFAULT,
      UNSET_SELECTED_AS_DEFAULT,
      MANAGE_RULES,
      MANAGE_CONTENT_TYPES,
      REPROCESS,
    } = CreativeRowActions;

    const allowedRowActions = [DELETE_MULTIPLE];

    if (userHasCampaignPermission(user, activeCampaignId, 'target')) {
      allowedRowActions.push(SET_SELECTED_AS_DEFAULT, UNSET_SELECTED_AS_DEFAULT);

      if (FEATURES.PROMOTION(campaign)) {
        allowedRowActions.push(MANAGE_RULES, MANAGE_CONTENT_TYPES);
      }
    }

    if (user.get('is-super-user')) {
      allowedRowActions.push(REPROCESS);
    }

    return allowedRowActions;
  };

  fetchCreativesByModerationStatus = (moderationStatus, frameSpecification, frameSpecificationIndex) => {
    const {
      activeCampaignId,
      fetchCreativesForSingleFrameSpecification,
      clearCampaignRelation,
      setFrameSpecsFilter,
      frameSpecsFilter,
    } = this.props;
    const filters = [{ frame_specification_id: frameSpecification.get('id') }];

    if (moderationStatus) {
      filters.push({ unmoderated: true });
    }
    const options = {
      includes: ['rule-sets', 'content-types'],
      filters,
    };
    clearCampaignRelation(activeCampaignId, ['frame-specifications', frameSpecificationIndex, 'creatives']);
    fetchCreativesForSingleFrameSpecification(activeCampaignId, frameSpecification, frameSpecificationIndex, options);
    const moderationStatusFilter = frameSpecsFilter.get('moderationStatus');
    moderationStatusFilter[frameSpecification.get('id')] = moderationStatus;
    const keyPath = ['moderationStatus'];
    setFrameSpecsFilter(moderationStatusFilter, keyPath);
  };

  render() {
    const {
      activeCampaignId,
      activeFrameSpecId,
      addCreative,
      addUpload,
      areCreativesUpdating,
      campaign,
      fetchCampaign,
      frameSpecifications,
      notifyError,
      params: { campaignId },
      removeUpload,
      replaceCreative,
      resetUploads,
      setActiveFrameSpec,
      setActiveSection,
      updateUpload,
      uploads,
      user,
      setActiveCampaignId,
    } = this.props;
    const { isFetching } = this.state;
    const previewUrl = `campaigns/${activeCampaignId}/creative/`;
    const moderationSwitch = true;
    return (
      <BaseCreative
        activeCampaignId={activeCampaignId}
        activeFrameSpecId={activeFrameSpecId}
        addCreative={addCreative}
        addUpload={addUpload}
        areCreativesUpdating={areCreativesUpdating}
        campaign={campaign}
        campaignId={campaignId}
        fetchCampaign={fetchCampaign}
        fetchFrameSpecifications={this.clearAndFetchFrameSpecifications}
        frameSpecifications={frameSpecifications}
        isFetching={isFetching}
        notifyError={notifyError}
        previewUrl={previewUrl}
        removeUpload={removeUpload}
        renderHeading={this.renderHeading}
        replaceCreative={replaceCreative}
        resetUploads={resetUploads}
        setActiveFrameSpec={setActiveFrameSpec}
        setActiveSection={setActiveSection}
        setActiveCampaignId={setActiveCampaignId}
        updateUpload={updateUpload}
        uploads={uploads}
        user={user}
        canUserReplaceCreative={this.canUserReplaceCreative}
        handleCreativeDeleteConfirmClick={this.handleCreativeDeleteConfirmClick}
        shouldShowRowActions
        allowedRowActions={this.getAllowedRowActions()}
        fetchCreativesByModerationStatus={this.fetchCreativesByModerationStatus}
        showModerationSwitch={moderationSwitch}
      />
    );
  }
}

Creative.propTypes = {
  activeCampaignId: PropTypes.string.isRequired,
  activeFrameSpecId: PropTypes.string.isRequired,
  addCreative: PropTypes.func.isRequired,
  addUpload: PropTypes.func.isRequired,
  areCreativesUpdating: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  deleteCreative: PropTypes.func.isRequired,
  fetchCampaign: PropTypes.func.isRequired,
  fetchFrameSpecifications: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  notifyError: PropTypes.func.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  removeUpload: PropTypes.func.isRequired,
  replaceCreative: PropTypes.func.isRequired,
  resetUploads: PropTypes.func.isRequired,
  setActiveFrameSpec: PropTypes.func.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  setActiveCampaignId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateUpload: PropTypes.func.isRequired,
  uploads: uploadsPropTypes.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  clearCampaignRelation: PropTypes.func.isRequired,
  fetchCreativesForSingleFrameSpecification: PropTypes.func.isRequired,
  setFrameSpecsFilter: PropTypes.func.isRequired,
  frameSpecsFilter: PropTypes.instanceOf(Map).isRequired,
  clearFrameSpecsFilter: PropTypes.func.isRequired,
};

export default withNamespaces(['common'], { wait: false })(Creative);
