import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

class PlaylistCreativeRows extends Component {
  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

export default DragDropContext(HTML5Backend)(PlaylistCreativeRows);

PlaylistCreativeRows.propTypes = {
  children: PropTypes.node,
};

PlaylistCreativeRows.defaultProps = {
  children: null,
};
