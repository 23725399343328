import Request from 'modules/API/request';
import { notifyApiError } from 'actions/notify';
import { SNOOZE_OPTIONS } from 'app-constants';

export const SNOOZE_DROPDOWN_CHANGE = 'SNOOZE_CAMPAIGN/SNOOZE_DROPDOWN_CHANGE';
export const FETCH_SNOOZED_CAMPAIGN_SUCCESS = 'SNOOZE_CAMPAIGN/FETCH_SNOOZED_CAMPAIGN_SUCCESS';

const snoozeCampaign = async (campaignId, untilDate, userId) => {
  const options = {
    endpoint: `users/${userId}/snooze-campaign`,
    method: 'POST',
    data: {
      campaign_id: campaignId,
      snooze_till_date: untilDate,
    },
  };

  return Request.send(options);
};

const unsnoozeCampaign = async (campaignId, userId) => {
  const options = {
    endpoint: `users/${userId}/snooze-campaign/${campaignId}`,
    method: 'DELETE',
  };

  return Request.send(options);
};

export const snoozeDropdownChange = (selectedValue, untilDate, setIsFetching = () => {}) => async (
  dispatch,
  getState,
) => {
  setIsFetching(true);

  try {
    const {
      snooze: { campaign },
      auth: { user },
    } = getState();

    if (selectedValue === SNOOZE_OPTIONS.UN_SNOOZE) {
      await unsnoozeCampaign(campaign.id, user.get('id'));
    } else {
      await snoozeCampaign(campaign.id, untilDate, user.get('id'));
    }

    dispatch({
      type: SNOOZE_DROPDOWN_CHANGE,
      payload: { selectedValue, untilDate },
    });
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    setIsFetching(false);
  }
};

export const fetchSnoozedCampaign = (campaignId) => async (dispatch, getState) => {
  try {
    const {
      auth: { user },
    } = getState();

    const options = {
      endpoint: `users/${user.get('id')}/snooze-campaign/${campaignId}`,
      method: 'GET',
    };

    const response = await Request.send(options);

    const isCampaignSnoozed = response.status !== 204;

    dispatch({
      type: FETCH_SNOOZED_CAMPAIGN_SUCCESS,
      payload: {
        campaignId,
        snoozeUntilDate: isCampaignSnoozed ? response.data.body.parsed['snooze-till-date'] : null,
        isCampaignSnoozed,
      },
    });
  } catch (error) {
    dispatch(notifyApiError(error));
  }
};
