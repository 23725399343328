import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { get } from 'lodash';

import DatePicker from 'components/patterns/DatePicker';
import Input from 'components/patterns/Input';
import ProgressBar from 'components/patterns/ProgressBar';
import Checkbox from 'assets/components/presentational/Checkbox';
import Dropdown from 'assets/components/presentational/Dropdown';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import VideoField from './Fields/Video';
import ImageField from './Fields/Image';

import style from './configurable-form.scss';

class ConfigurableForm extends Component {
  renderFormElement(config) {
    const { fields, readonly, t } = this.props;
    let ele;
    const field = config.slug ? fields[config.slug] : {};
    switch (config.type) {
      case 'heading':
        ele = (
          <div className={style.heading}>
            <Heading size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
              {config.name}
            </Heading>
          </div>
        );
        break;
      case 'longtext':
        ele = <Input type="text" multiline label={config.name} {...field} disabled={readonly} />;
        break;
      case 'text':
        ele = <Input type="text" label={config.name} {...field} disabled={readonly} />;
        break;
      case 'number':
        ele = <Input type="number" label={config.name} {...field} disabled={readonly} />;
        break;
      case 'checkbox':
        ele = <Checkbox label={config.name} {...field} disabled={readonly} />;
        break;
      case 'video':
        ele = <VideoField config={config} field={field} readonly={readonly} t={t} />;
        break;
      case 'image':
        ele = <ImageField config={config} field={field} readonly={readonly} t={t} />;
        break;
      case 'select':
        ele = (
          <Dropdown
            label={config.name}
            source={config.options}
            {...field}
            className={style.dropdown}
            disabled={readonly}
          />
        );
        break;
      case 'date':
        ele = (
          <DatePicker label={config.name} {...field} onBlur={(event) => event.preventDefault()} readonly={readonly} />
        );
        break;
      default:
        ele = null;
        break;
    }
    return ele;
  }

  renderFormHint(config) {
    if (!config.meta || !config.meta.length) {
      return null;
    }

    return (
      <div>
        {Object.keys(config.meta).map((metaKey, key) => (
          <span key={key} />
        ))}
      </div>
    );
  }

  renderFormElementGroup = (config, key) => (
    <div key={key}>
      {this.renderFormElement(config)}
      {this.renderFormHint(config)}
    </div>
  );

  renderAddAnotherCheckbox = () => {
    const { onAddAnotherItem, shouldAddAnotherContentItem, submitting, t, isAddAnotherItemHidden } = this.props;
    if (!isAddAnotherItemHidden) {
      return (
        <Checkbox
          checked={shouldAddAnotherContentItem}
          label={t('Create another')}
          disabled={submitting}
          onChange={onAddAnotherItem}
          className={style.addAnotherCheckbox}
        />
      );
    }

    return null;
  };

  renderButtons = () => {
    const { readonly, onCancel, shouldAddAnotherContentItem, handleSubmit, submitting, t } = this.props;
    if (!readonly) {
      return (
        <div className={style.actionGroup}>
          <Button
            label={t('Cancel all changes')}
            className={style.cancelButton}
            onClick={onCancel}
            theme={ButtonThemes.WHITE}
            disabled={submitting}
          />
          <Button
            label={shouldAddAnotherContentItem ? t('Save') : t('Save and close')}
            type="button"
            onClick={handleSubmit}
            className={style.saveButton}
            disabled={submitting}
          />
          {this.renderAddAnotherCheckbox()}
        </div>
      );
    }

    return null;
  };

  renderButtonsReadOnly = () => {
    const { readonly, onCancel, submitting, t } = this.props;
    if (readonly) {
      return (
        <div className={style.actionGroup}>
          <Button
            type="cancel"
            label={t('Close')}
            className={style.closeButton}
            onClick={onCancel}
            theme={ButtonThemes.WHITE}
            disabled={submitting}
          />
        </div>
      );
    }
  };

  render() {
    const { formObject } = this.props;
    const fields = get(formObject, 'fields', []);
    const { submitting } = this.props;

    return (
      <div className={style.component}>
        {submitting ? (
          <div className={style.progressBar}>
            <ProgressBar />
          </div>
        ) : null}
        <form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          {fields.map((field, index) => this.renderFormElementGroup(field, index))}

          {this.renderButtons()}
        </form>
        {this.renderButtonsReadOnly()}
      </div>
    );
  }
}

ConfigurableForm.propTypes = {
  t: PropTypes.func,
  fields: PropTypes.object.isRequired,
  formObject: PropTypes.object,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onAddAnotherItem: PropTypes.func,
  shouldAddAnotherContentItem: PropTypes.bool,
  readonly: PropTypes.bool,
  isAddAnotherItemHidden: PropTypes.bool,
};

ConfigurableForm.defaultProps = {
  onAddAnotherItem: () => {},
  readonly: false,
  shouldAddAnotherContentItem: false,
  isAddAnotherItemHidden: false,
};

export default reduxForm()(withNamespaces(['common', 'validation'], { wait: false })(ConfigurableForm));
