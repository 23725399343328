import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { success, notifyApiError } from 'actions/notify';

const CAMPAIGN_BUILDS_FETCH_BUILDS_REQUEST = 'CAMPAIGN_BUILDS_FETCH_BUILDS_REQUEST';
const CAMPAIGN_BUILDS_FETCH_BUILDS_SUCCESS = 'CAMPAIGN_BUILDS_FETCH_BUILDS_SUCCESS';
const CAMPAIGN_BUILDS_FETCH_BUILDS_FAILURE = 'CAMPAIGN_BUILDS_FETCH_BUILDS_FAILURE';

const CAMPAIGN_BUILDS_MERGE_BUILD = 'CAMPAIGN_BUILDS_MERGE_BUILD';

const CAMPAIGN_BUILDS_CREATE_BUILDS_REQUEST = 'CAMPAIGN_BUILDS_CREATE_BUILDS_REQUEST';
const CAMPAIGN_BUILDS_CREATE_BUILDS_SUCCESS = 'CAMPAIGN_BUILDS_CREATE_BUILDS_SUCCESS';

const CAMPAIGN_BUILDS_DELETE_BUILDS_REQUEST = 'CAMPAIGN_BUILDS_DELETE_BUILDS_REQUEST';
const CAMPAIGN_BUILDS_DELETE_BUILDS_SUCCESS = 'CAMPAIGN_BUILDS_DELETE_BUILDS_SUCCESS';
const CAMPAIGN_BUILDS_DELETE_BUILDS_FAILURE = 'CAMPAIGN_BUILDS_DELETE_BUILDS_FAILURE';

export const fetchBuilds = (campaignId) => (dispatch) => {
  dispatch({ type: CAMPAIGN_BUILDS_FETCH_BUILDS_REQUEST });

  return Request.send({
    endpoint: `campaigns/${campaignId}`,
    includes: ['builds'],
  })
    .then((response) => {
      dispatch({ type: CAMPAIGN_BUILDS_FETCH_BUILDS_SUCCESS, builds: fromJS(response.data.body.parsed.builds) });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_BUILDS_FETCH_BUILDS_FAILURE });
    });
};

export const mergeBuild = (build) => ({
  type: CAMPAIGN_BUILDS_MERGE_BUILD,
  build,
});

export const fetchBuild = (buildId) => (dispatch) =>
  Request.send({
    endpoint: `builds/${buildId}`,
  })
    .then((response) => {
      dispatch(mergeBuild(fromJS(response.data.body.parsed)));
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });

export const createBuild = (campaignId) => (dispatch) => {
  dispatch({ type: CAMPAIGN_BUILDS_CREATE_BUILDS_REQUEST });

  return Request.send({
    data: {
      campaignId,
    },
    endpoint: 'builds',
    method: 'POST',
  })
    .then((response) => {
      dispatch({ type: CAMPAIGN_BUILDS_CREATE_BUILDS_SUCCESS, build: fromJS(response.data.body.parsed) });
      dispatch(
        success('A new Build has been requested. The status will refresh automatically when the build is complete.'),
      );
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const deleteBuild = (buildId) => (dispatch) => {
  dispatch({ type: CAMPAIGN_BUILDS_DELETE_BUILDS_REQUEST, buildId });

  return Request.send({
    endpoint: `builds/${buildId}`,
    method: 'DELETE',
  })
    .then(() => {
      dispatch({ type: CAMPAIGN_BUILDS_DELETE_BUILDS_SUCCESS, buildId });
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: CAMPAIGN_BUILDS_DELETE_BUILDS_FAILURE, buildId });
      return Promise.reject(error);
    });
};
