import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import Logger from 'logger';
import en from 'locales/en';

i18next.use(reactI18nextModule).init({
  saveMissing: true,
  resources: {
    en,
  },
  lng: 'en',
  fallbackLng: 'en',
  // nsSeparator is colon (:) by default which causes issues with api errors containing this character.
  // Changing to something less common
  nsSeparator: '>',
  // Same thing here as above
  keySeparator: '->',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    nsMode: 'fallback',
  },
});

i18next.on('missingKey', (lngs, namespace, key, res) => {
  Logger.warning(`Missing translation key "${key}" in ${namespace}.json for "${lngs}" locale(s)`, {
    locales: lngs,
    jsonFile: namespace,
    key,
    res,
  });
});

export default i18next;
