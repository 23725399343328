import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import Checkbox from 'assets/components/presentational/Checkbox';
import Dialog from 'assets/components/presentational/Dialog';

import Row from 'assets/components/presentational/Rows/Row';
import Details from 'assets/components/presentational/Rows/Details';
import Actions from 'assets/components/presentational/Rows/Actions';
import Action from 'assets/components/presentational/Rows/Action';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './contentTypeRow.scss';

class ContentTypeRow extends Component {
  handleContentTypeEditClick() {
    const { onEditClick, contentType } = this.props;
    onEditClick(contentType);
  }

  handleContentTypeDeleteClick() {
    const { onDeleteClick, contentType } = this.props;
    onDeleteClick(contentType);
  }

  handleContentTypeDeleteConfirmClick() {
    const { onDeleteConfirmClick, contentType } = this.props;
    onDeleteConfirmClick(contentType);
  }

  handleContentTypeDeleteCancelClick() {
    const { onDeleteCancelClick, contentType } = this.props;
    onDeleteCancelClick(contentType);
  }

  renderEditAction() {
    const { isEditable } = this.props;

    return (
      <Action
        disabled={!isEditable}
        icon={<Icon iconType={IconTypes.EDIT} />}
        onClick={() => this.handleContentTypeEditClick()}
      />
    );
  }

  renderDeleteDialog() {
    const { isDeleting, t } = this.props;

    const actions = [
      { label: t('Cancel'), onClick: () => this.handleContentTypeDeleteCancelClick() },
      { label: t('Yes'), onClick: () => this.handleContentTypeDeleteConfirmClick() },
    ];

    return (
      <Dialog
        actions={actions}
        active={isDeleting}
        onEscKeyDown={() => this.handleContentTypeDeleteCancelClick()}
        onOverlayClick={() => this.handleContentTypeDeleteCancelClick()}
        type="small"
      >
        <p>{t('Are you sure? This will cause all Content using this Content Type to be deleted.')}</p>
      </Dialog>
    );
  }

  renderDeleteAction() {
    const { isDeletable } = this.props;

    return (
      <Action
        disabled={!isDeletable}
        icon={<Icon iconType={IconTypes.DELETE_FOREVER} />}
        onClick={() => this.handleContentTypeDeleteClick()}
      />
    );
  }

  renderSelectAction() {
    const { isSelectable, isSelected } = this.props;

    if (!isSelectable) {
      return null;
    }

    return (
      <Checkbox
        className={style.checkBox}
        checked={isSelected}
        disabled={!isSelectable}
        onChange={this.handleCheckboxChange}
      />
    );
  }

  render() {
    const { contentType, t } = this.props;
    const subtitle = `${t('Updated')}: ${
      contentType.get('updated-at')
        ? moment(contentType.get('updated-at')).fromNow()
        : moment(contentType.get('created-at')).fromNow()
    }`;
    const fieldsText = (fields) => {
      if (!fields) {
        return t('X fields', { count: 0 });
      }
      return t('X fields', { count: fields.size });
    };

    return (
      <div className={style.component}>
        <Row className={style.contentTypeRow}>
          <Details className={style.detailsRow}>
            <div className={style.contentTypeName}>
              <span className={style.contentTypeNameRow}>
                <strong>{contentType.get('name')}</strong>
                &nbsp;
                <span className={style.lastUpdated}>{` -${subtitle}`}</span>
                <span className={style.lastUpdated}>{` -${fieldsText(contentType.get('fields'), t)}`}</span>
              </span>
            </div>
          </Details>
          <Actions className={style.actions}>
            {this.renderEditAction()}
            {this.renderDeleteAction()}
            {this.renderSelectAction()}
          </Actions>
        </Row>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'contentType'], { wait: false })(ContentTypeRow);

ContentTypeRow.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  contentType: PropTypes.object.isRequired,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onDeleteCancelClick: PropTypes.func,
  onDeleteConfirmClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ContentTypeRow.defaultProps = {
  isDeletable: false,
  isDeleting: false,
  isSelectable: false,
  isSelected: false,
  isEditable: false,
};
