import { handleActions } from 'redux-actions';
import { List } from 'immutable';
import * as at from 'actions/campaign/playlists';

const getInitialState = () => ({
  isFetching: false,
  isFetchingComputedPlaylist: false,
  activeFrameSpecId: '',
  activeRuleSetId: '',
  playlistsId: '',
  playlists: new List(),
  computedPlaylist: new List(),
  isPlaylistUpdating: false,
  hasUnsavedChanges: false,
});

export default handleActions(
  {
    [at.FETCH_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [at.FETCH_SUCCESS]: (state, action) => ({
      ...state,
      playlistsId: action.playlists.get('id'),
      playlists: action.playlists,
    }),
    [at.FETCH_COMPLETE]: (state) => ({
      ...state,
      isFetching: false,
    }),
    [at.FETCH_COMPUTED_REQUEST]: (state) => ({
      ...state,
      isFetchingComputedPlaylist: true,
    }),
    [at.FETCH_COMPUTED_SUCCESS]: (state, action) => ({
      ...state,
      computedPlaylist: action.computedPlaylist,
    }),
    [at.FETCH_COMPUTED_COMPLETE]: (state) => ({
      ...state,
      isFetchingComputedPlaylist: false,
    }),
    [at.SET_ACTIVE_FRAME_SPEC]: (state, action) => ({
      ...state,
      activeFrameSpecId: action.activeFrameSpecId,
    }),
    [at.SET_ACTIVE_RULE_SET]: (state, action) => ({
      ...state,
      activeRuleSetId: action.activeRuleSetId,
    }),
    [at.SET_HAS_UNSAVED]: (state) => ({
      ...state,
      hasUnsavedChanges: true,
    }),
    [at.CLEAR_HAS_UNSAVED]: (state) => ({
      ...state,
      hasUnsavedChanges: false,
    }),
    [at.UPDATE_REQUEST]: (state) => ({
      ...state,
      isPlaylistUpdating: true,
    }),
    [at.UPDATE_COMPLETE]: (state, action) => ({
      ...state,
      isPlaylistUpdating: false,
      playlists: state.playlists.update(
        state.playlists.findIndex((playlist) => playlist.get('id') === action.playlistId),
        () => action.playlist,
      ),
    }),
    [at.CLEAR_COMPUTED]: (state) => ({
      ...state,
      computedPlaylist: new List(),
    }),
  },
  getInitialState(),
);
