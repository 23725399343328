import Request from 'modules/API/request';
import { notifyApiError } from 'actions/notify';

export const TRANSPORT_FETCH_REQUEST = 'TRANSPORT_FETCH_REQUEST';
export const TRANSPORT_FETCH_SUCCESS = 'TRANSPORT_FETCH_SUCCESS';
export const TRANSPORT_FETCH_COMPLETE = 'TRANSPORT_FETCH_COMPLETE';

export const TRANSPORT_RESOURCE_AIRLINES = 'airlines';
export const TRANSPORT_RESOURCE_AIRPORTS = 'airports';

export const fetchIndex = (resource, options = {}) => (dispatch) => {
  Object.assign(options, { endpoint: `transport/${resource}`, unauthenticated: true });

  dispatch({ type: TRANSPORT_FETCH_REQUEST, resource, options });

  Request.send(options)
    .then((response) => {
      const data = response.data.body.parsed;

      dispatch({ type: TRANSPORT_FETCH_SUCCESS, resource, data });
      dispatch({ type: TRANSPORT_FETCH_COMPLETE, resource, options });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: TRANSPORT_FETCH_COMPLETE, resource, options });
    });
};
