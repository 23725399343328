import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { List } from 'immutable';

import DialogActionItem from './DialogActionItem';

import style from './rowActionsDialogActions.scss';

class RowActionsDialogActions extends Component {
  handleApplyActionItem(actionItem) {
    const { onApplyActionItem } = this.props;

    return onApplyActionItem(actionItem);
  }

  handleRemoveActionItem(actionItem) {
    const { onRemoveActionItem } = this.props;

    return onRemoveActionItem(actionItem);
  }

  render() {
    const { actionList, className, isUpdating, selectedCreatives, t } = this.props;

    return (
      <div className={className}>
        <span>{t('Selected Creatives: ')}</span>
        <div className={style.selectedCreatives}>
          {selectedCreatives.map((selectedCreative, index) => {
            const isLastCreative = index === selectedCreatives.size - 1;
            return <span key={index}>{`${selectedCreative.get('name')}${isLastCreative ? '' : ','} `}</span>;
          })}
        </div>
        <hr className={style.divider} />
        <div className={style.ruleSelection}>
          {actionList.map((actionItem) => {
            const actionItemId = actionItem.get('id');
            return (
              <DialogActionItem
                actionItem={actionItem}
                key={actionItemId}
                isUpdating={isUpdating}
                onApplyActionItem={() => this.handleApplyActionItem(actionItem)}
                onRemoveActionItem={() => this.handleRemoveActionItem(actionItem)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withNamespaces(['common'], { wait: false })(RowActionsDialogActions);

RowActionsDialogActions.propTypes = {
  actionList: PropTypes.instanceOf(List),
  className: PropTypes.string,
  isUpdating: PropTypes.bool,
  onApplyActionItem: PropTypes.func,
  onRemoveActionItem: PropTypes.func,
  selectedCreatives: PropTypes.instanceOf(List),
  t: PropTypes.func,
};

RowActionsDialogActions.defaultProps = {
  className: '',
};
