import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';

import { contentTypeRequest } from 'actions/contentTypes';

import ContentTypeForm from '../ContentTypeForm';

class ContentTypeFormContainer extends Component {
  validate(values) {
    const errors = {};
    const { t } = this.props;

    // validate name
    if (!values.name || typeof values.name !== 'string' || values.name.length < 1) {
      errors.name = t('Name required');
    }

    // Redux form validation for nested fields is broken, so I am just going to use _error to supply a general message
    const hasAllFieldNames = values.elements.reduce(
      (prev, curr) => prev && curr.name && typeof curr.name === 'string' && curr.name.length > 0,
      true,
    );

    if (!hasAllFieldNames) {
      errors.fieldNames = t('All fields need names');
    }

    // validate single select fields.
    const options = values.elements.reduce((prev, curr) => prev.concat(curr.selectOptions), []);
    if (options.length) {
      // Test that all options have labels
      const hasAllOptionLabels = options.reduce(
        (prev, curr) => prev && curr.label && typeof curr.label === 'string' && curr.label.length > 0,
        true,
      );

      if (!hasAllOptionLabels) {
        errors.optionNames = t('All select options need labels');
      }

      // Test that all options have values
      const hasAllOptionValues = options.reduce((prev, curr) => prev && curr.value);

      if (!hasAllOptionValues) {
        errors.optionValues = t('All select options need values');
      }
    }

    if (Object.keys(errors).length > 0) {
      errors._error = `${t('Errors on form')}: ${Object.values(errors).join('; ')}.`;
      return errors;
    }

    return true;
  }

  handleCancel(event) {
    const { onCancel } = this.props;
    event.preventDefault();
    onCancel();
  }

  getInitialValues() {
    const { campaign, contentType } = this.props;
    if (contentType !== null) {
      return {
        campaign_id: campaign.get('id'), // eslint-disable-line camelcase
        name: contentType.get('name'),
        elements: contentType
          .get('fields')
          .toJS()
          .map((field) => ({
            name: field.name,
            type: field.type.replace('App\\Fields\\', ''),
            order: field.weight,
            selectOptions:
              field['meta-data'] && field['meta-data'].options
                ? Object.keys(field['meta-data'].options).map((value) => ({
                    label: field['meta-data'].options[value],
                    value,
                  }))
                : [],
            fieldSettings: JSON.stringify(field['meta-data']),
            uuid: field.id,
          })),
      };
    }
    return {
      campaign_id: campaign.get('id'), // eslint-disable-line camelcase
    };
  }

  getFormName() {
    const { contentType } = this.props;
    let name = 'ContentTypeForm';
    if (contentType !== null) name += `-${contentType.get('id')}`;
    return name;
  }

  handleSubmit(values) {
    const { campaign, contentType, scrollToError, dispatch } = this.props;

    const valid = this.validate(values);

    if (valid !== true) {
      scrollToError();
      return Promise.reject(valid);
    }
    // do the request
    return dispatch(contentTypeRequest(campaign.get('id'), values, contentType));
  }

  render() {
    const { submitting } = this.props;
    return (
      <ContentTypeForm
        onCancel={(event) => this.handleCancel(event)}
        onSubmit={(values) => this.handleSubmit(values)}
        submitting={submitting}
        onFieldAdd={() => {}}
        onFieldRemove={() => {}}
        form={this.getFormName()}
        fields={[
          'name',
          'campaign_id',
          'elements[].name',
          'elements[].type',
          'elements[].order',
          'elements[].uuid',
          'elements[].newField',
          'elements[].selectOptions[].label',
          'elements[].selectOptions[].value',
          'elements[].fieldSettings',
        ]}
        initialValues={this.getInitialValues()}
      />
    );
  }
}

export default withNamespaces(['contentType', 'common'], { wait: false })(ContentTypeFormContainer);

ContentTypeFormContainer.propTypes = {
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  onCancel: PropTypes.func.isRequired,
  contentType: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  scrollToError: PropTypes.func,
};

ContentTypeFormContainer.defaultProps = {
  scrollToError: () => {},
};
