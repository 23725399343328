import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import notify from 'actions/snackbar';
import {
  canUserModerate,
  clear,
  fetchCreative,
  fetchAllCreatives,
  moderate,
  updateDefaultStatus,
  syncRelations,
  fetchModerationSummary,
  fetchFrameSpecifications,
  recalculateModerationSummary,
  updateCreativeModerationStatus,
} from 'actions/campaign/creative/preview';
import { htmlPlayClick } from 'actions/creative';
import { setActiveSection } from 'actions/campaign/navigation';

import { getFrameSpecifications } from 'selectors/campaign';

import CreativePreview from './CreativePreview';

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  creative: state.campaignCreativePreview.creative,
  frameSpecifications: getFrameSpecifications(state.campaign.campaign),
  isInitialFetch: state.campaignCreativePreview.isInitialFetch,
  isCheckingModeration: state.campaignCreativePreview.isCheckingModeration,
  isFetchingCreative: state.campaignCreativePreview.isFetching,
  isFetchingModerationSummary: state.campaignCreativePreview.isFetchingModerationSummary,
  isRecalculatingModeration: state.campaignCreativePreview.isRecalculatingModeration,
  isSavingCreative: state.campaignCreativePreview.isSaving,
  moderationSummary: state.campaignCreativePreview.moderationSummary,
  showHtmlPlayButton: state.creative.showHtmlPlayButton,
  user: state.auth.user,
  userCanModerate: state.campaignCreativePreview.userCanModerate,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notify,
      canUserModerate,
      clear,
      fetchCreative,
      fetchAllCreatives,
      moderate,
      updateDefaultStatus,
      syncRelations,
      fetchModerationSummary,
      fetchFrameSpecifications,
      recalculateModerationSummary,
      updateCreativeModerationStatus,
      htmlPlayClick,
      setActiveSection,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreativePreview);
