import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';
import * as at from 'actions/creative';

const getInitialState = () => ({
  activeCreativeId: '',
  activeFrameSpecId: '',
  areCreativesUpdating: new Map(),
  creativeForPreview: new Map(),
  creativesModerationSummary: List(),
  creativesModerationSummaryPagination: new Map(),
  creativesUploadedThisSession: List(),
  isCreativeSaving: false,
  isFetchingCreative: false,
  isFetchingCreativesModerationSummary: false,
  isFetchingFrameSpecifications: false,
  isRuleSetsSaving: false,
  isContentTypesSaving: false,
  showHtmlPlayButton: true,
  showSettingsForm: false,
  isFetchingCreativesByFrameSpec: {},
});

const creative = handleActions(
  {
    [at.SET_ACTIVE_CREATIVE_ID]: (state, action) => ({
      ...state,
      activeCreativeId: action.creativeId,
    }),
    [at.SAVE_REQUEST]: (state) => ({
      ...state,
      isCreativeSaving: true,
    }),
    [at.SAVE_COMPLETE]: (state) => ({
      ...state,
      isCreativeSaving: false,
    }),
    CAMPAIGN_RELATIONS_SYNC_REQUEST: (state, action) => ({
      ...state,
      isRuleSetsSaving:
        action.modelName === 'creatives' && action.relationName === 'rule-sets' ? true : state.isRuleSetsSaving,
      isContentTypesSaving:
        action.modelName === 'creatives' && action.relationName === 'content-types' ? true : state.isContentTypesSaving,
    }),
    CAMPAIGN_RELATIONS_SYNC_COMPLETE: (state, action) => ({
      ...state,
      isRuleSetsSaving:
        action.modelName === 'creatives' && action.relationName === 'rule-sets' ? false : state.isRuleSetsSaving,
      isContentTypesSaving:
        action.modelName === 'creatives' && action.relationName === 'content-types'
          ? false
          : state.isContentTypesSaving,
    }),
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingFrameSpecifications:
        action.relation === 'frame-specifications' ? true : state.isFetchingFrameSpecifications,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingFrameSpecifications:
        action.relation === 'frame-specifications' ? false : state.isFetchingFrameSpecifications,
    }),
    CAMPAIGN_RELATION_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingCreative: action.relation === 'creatives' ? true : state.isFetchingCreative,
    }),
    CAMPAIGN_RELATION_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingCreative: action.relation === 'creatives' ? false : state.isFetchingCreative,
    }),
    [at.TOGGLE_SHOW_SETTINGS_FORM]: (state) => ({
      ...state,
      showSettingsForm: !state.showSettingsForm,
    }),
    [at.MERGE_PREVIEW]: (state, action) => ({
      ...state,
      creativeForPreview: state.creativeForPreview.merge(fromJS(action.creative)),
    }),
    [at.HTML_PLAY_CLICK]: (state) => ({
      ...state,
      showHtmlPlayButton: false,
    }),
    [at.FETCH_MODERATION_SUMMARY_REQUEST]: (state) => ({
      ...state,
      isFetchingCreativesModerationSummary: true,
    }),
    [at.FETCH_MODERATION_SUMMARY_SUCCESS]: (state, action) => ({
      ...state,
      creativesModerationSummary: action.creativesModerationSummary,
    }),
    [at.FETCH_MODERATION_SUMMARY_COMPLETE]: (state) => ({
      ...state,
      isFetchingCreativesModerationSummary: false,
    }),
    [at.MODERATION_SUMMARY_PAGINATION_MERGE]: (state, action) => ({
      ...state,
      creativesModerationSummaryPagination: fromJS(action.pagination),
    }),
    [at.RESET_STATE]: () => getInitialState(),
    [at.PATCH_CREATIVES_REQUEST]: (state, action) => ({
      ...state,
      areCreativesUpdating: state.areCreativesUpdating.set(action.frameSpecId, true),
    }),
    [at.PATCH_CREATIVES_COMPLETE]: (state, action) => ({
      ...state,
      areCreativesUpdating: state.areCreativesUpdating.set(action.frameSpecId, false),
    }),
    [at.SET_ACTIVE_FRAME_SPEC]: (state, action) => ({
      ...state,
      activeFrameSpecId: action.activeFrameSpecId,
    }),
    [at.FETCHING_CREATIVES_BY_FRAME_SPEC_START]: (state, action) => ({
      ...state,
      isFetchingCreativesByFrameSpec: {
        ...state.isFetchingCreativesByFrameSpec,
        [action.frameSpecId]: true,
      },
    }),
    [at.FETCHING_CREATIVES_BY_FRAME_SPEC_COMPLETE]: (state, action) => ({
      ...state,
      isFetchingCreativesByFrameSpec: {
        ...state.isFetchingCreativesByFrameSpec,
        [action.frameSpecId]: false,
      },
    }),
  },
  getInitialState(),
);

export default creative;
