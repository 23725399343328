import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import ProgressBar from 'components/patterns/ProgressBar';
import { ComputedPlaylistItem } from 'assets/components/presentational/Campaign/Playlists/ComputedPlaylist/ComputedPlaylistItem';

import style from './computedPlaylist.scss';

const ComputedPlaylist = ({ isFetching, computedPlaylist }) => (
  <div className={style.computedPlaylist}>
    {isFetching && (
      <div className={style.progress}>
        <ProgressBar />
      </div>
    )}
    {computedPlaylist.map((playlistItem, key) => (
      <ComputedPlaylistItem
        key={key}
        name={playlistItem.get('name')}
        type={playlistItem.get('type')}
        color={playlistItem.get('color')}
        position={playlistItem.get('position')}
        last={computedPlaylist.size === key + 1}
      />
    ))}
  </div>
);

export default ComputedPlaylist;

ComputedPlaylist.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  computedPlaylist: PropTypes.instanceOf(List).isRequired,
};
