import { fromJS, List } from 'immutable';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const NotificationTypes = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

const notify = (notificationType = NotificationTypes.INFO, notifications = List([]), timesOut = true) => (dispatch) => {
  // Convert string to array if required
  if (typeof notifications !== 'object') {
    notifications = convertNotificationStringToArray(notifications);
  }
  dispatch(updateNotifications(notificationType, notifications, timesOut));
};

const convertNotificationStringToArray = (notification) => {
  let notifications = fromJS([]);
  notifications = notifications.push(fromJS({ 0: notification }));

  return notifications;
};

const updateNotifications = (notificationType = NotificationTypes.INFO, notifications = List([]), timesOut = true) => ({
  type: UPDATE_NOTIFICATIONS,
  notificationType,
  notifications,
  timesOut,
});

export default notify;
