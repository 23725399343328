import { SESSION } from 'app-constants';
import { browserHistory } from 'react-router';
import { List, Map } from 'immutable';
import { isEmpty } from 'lodash';
import { IconTypes } from 'assets/components/presentational/Icon';

import weatherClear from 'assets/images/weather-icons/weather-clear.png';
import weatherCloudy from 'assets/images/weather-icons/weather-cloudy.png';
import weatherFog from 'assets/images/weather-icons/weather-fog.png';
import weatherHail from 'assets/images/weather-icons/weather-hail.png';
import weatherPartlyCloudy from 'assets/images/weather-icons/weather-partly-cloudy.png';
import weatherRain from 'assets/images/weather-icons/weather-rain.png';
import weatherSleet from 'assets/images/weather-icons/weather-sleet.png';
import weatherSnow from 'assets/images/weather-icons/weather-snow.png';
import weatherThunderstorm from 'assets/images/weather-icons/weather-thunderstorm.png';
import weatherWind from 'assets/images/weather-icons/weather-wind.png';

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const CreativeTypes = {
  HTML5: 'html5',
  IMAGE: 'image',
  PDF: 'pdf',
  SWF: 'swf',
  UNPROCESSED: 'unprocessed',
  VIDEO: 'video',
};

export const ModerationDecisionStatuses = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const getCreativeTypeIcon = (type) => {
  switch (type) {
    case CreativeTypes.IMAGE:
      return IconTypes.IMAGE;
    case CreativeTypes.HTML5:
      return IconTypes.CODE;
    case CreativeTypes.PDF:
      return IconTypes.PICTURE_AS_PDF;
    case CreativeTypes.VIDEO:
      return IconTypes.VIDEOCAM;
    default:
      return IconTypes.FILE_UPLOAD;
  }
};

export const CreativeModerationStatuses = {
  APPROVED: 1,
  PENDING: 0,
  REJECTED: -1,
};

export const ContentModerationStatuses = CreativeModerationStatuses;

export const ContentItemsRejectReasons = {
  CONTAINS_PROFANITY: 'This content may contain profanity. Please review and amend.',
};

export const getModerationIcon = (status) => {
  switch (status) {
    case CreativeModerationStatuses.REJECTED:
      return IconTypes.ERROR_OUTLINE;
    case CreativeModerationStatuses.APPROVED:
      return IconTypes.DONE;
    default:
      return IconTypes.HELP_OUTLINE;
  }
};

export const getCreativeModerationDescription = (status) => {
  switch (status) {
    case CreativeModerationStatuses.REJECTED:
      return 'Rejected';
    case CreativeModerationStatuses.APPROVED:
      return 'Accepted';
    default:
      return 'Pending approval';
  }
};
/*
 * Redirects the user to the given url.
 *
 * @param {String} to                   The url to redirect to
 * @param {Bool}   shouldReplaceHistory If true, replaces the browser history item with the new one.
 *                                      Useful when redirecting after filtering or searching
 */
export const redirect = (to, shouldReplaceHistory = false) => {
  // Decodes the 'to' string before putting it through further encoding below with encodeURI
  to = decodeURIComponent(to);

  const url = encodeURI(`/${getLinkWithoutPrecedingSlash(to)}`);

  if (shouldReplaceHistory) {
    return browserHistory.replace(url);
  }
  return browserHistory.push(url);
};

export const removeJwtFromLocalStorage = () => {
  localStorage.removeItem(SESSION.JWT_LOCAL_STORAGE_KEY);
};

export const getLinkWithoutPrecedingSlash = (link) => {
  while (link.charAt(0) === '/') {
    link = link.substr(1);
  }

  return link;
};

/**
 * getURLQueryObj: gets existing url query variables, and merges any new ones
 *
 * @param  {object} curQuery: an object with new current query parameters
 * @param  {object} queryUpdates: an object with new query parameters to merge
 * @return {object}              an object containing the new query variables
 */
export const getURLQueryObj = (curQuery, queryUpdates = {}) => {
  const query = Object.assign({}, curQuery);

  if (isEmpty(queryUpdates)) {
    return query;
  }

  Object.keys(queryUpdates).forEach((key) => {
    const newVal = queryUpdates[key];
    if (typeof newVal === 'undefined' || newVal === null || newVal.length < 1) {
      return delete query[key];
    }

    query[key] = newVal;
  });

  return query;
};

/**
 * getURLQueryString: returns a query string containing all existing parameters
 * with any newly provided ones merged in
 *
 * @param  {object} curQuery: an object with new current query parameters
 * @param  {object} queryUpdates: an object with new query parameters to merge
 * @returns {string}
 */
export const getURLQueryString = (queryUpdates = {}) => {
  const curQuery = getCurrentURLQueryObject();
  const query = getURLQueryObj(curQuery, queryUpdates);

  const queryStrings = Object.keys(query)
    .filter((key) => query[key].toString().length > 0)
    .map((key) => `${key}=${encodeURIComponent(query[key])}`);

  return queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '';
};

/**
 * convertURLQueryStringToJSON: accepts a query string and returns a JSON object
 * useful for components without access to react-routers location prop
 *
 * @returns {object}
 */
export const getCurrentURLQueryObject = () => {
  const pairs = location.search.slice(1).split('&');

  const result = {};
  pairs.forEach((pair) => {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

/*
 * Returns an array of checkbox options with a computed "checked" property based on model and campaign relations
 *
 * @param {Immutable.Map} model The model, e.g. creative
 * @param {Immutable.List} campaignRelations e.g. campaign.rule-sets
 * @param {Array} relationKeyPath The key path to the relation within the model e.g. ["rule-sets"]
 */
export const getModelRelationsForCheckboxPicker = (model, campaignRelations, modelRelationKeyPath) =>
  campaignRelations
    .map((campaignRelation) => ({
      label: campaignRelation.get('name'),
      checked:
        model
          .getIn(modelRelationKeyPath, List())
          .findIndex((modelRelation) => modelRelation.get('id') === campaignRelation.get('id')) > -1,
      value: campaignRelation.get('id'),
    }))
    .toJS();

/**
 *
 * Returns the relevant weather image based on the given condition
 *
 * @param {String} condition e.g. clear
 */
export const getWeatherImage = (condition) => {
  let iconImage;

  switch (condition) {
    case 'clear':
      iconImage = weatherClear;
      break;
    case 'cloudy':
      iconImage = weatherCloudy;
      break;
    case 'fog':
      iconImage = weatherFog;
      break;
    case 'hail':
      iconImage = weatherHail;
      break;
    case 'partly-cloudy':
      iconImage = weatherPartlyCloudy;
      break;
    case 'rain':
      iconImage = weatherRain;
      break;
    case 'sleet':
      iconImage = weatherSleet;
      break;
    case 'snow':
      iconImage = weatherSnow;
      break;
    case 'thunderstorm':
      iconImage = weatherThunderstorm;
      break;
    case 'wind':
      iconImage = weatherWind;
      break;
    default:
      iconImage = weatherClear;
      break;
  }

  return iconImage;
};

/**
 *
 * Returns the relevant rule image based on the given type
 *
 * @param {String} type e.g. "App\\Rules\\Weather"
 */
export const getRuleIcon = (type) => {
  let iconImage;

  switch (type) {
    case 'App\\Rules\\Weather':
    case 'App\\Rules\\Weather2':
      iconImage = IconTypes.WB_CLOUDY;
      break;
    case 'App\\Rules\\Temperature':
    case 'App\\Rules\\Temperature2':
      iconImage = IconTypes.WHATSHOT;
      break;
    case 'App\\Rules\\Location':
      iconImage = IconTypes.PIN_DROP;
      break;
    case 'App\\Rules\\DayPart':
    case 'App\\Rules\\TimeOfDay':
      iconImage = IconTypes.ACCESS_TIME;
      break;
    case 'App\\Rules\\DayOfWeek':
      iconImage = IconTypes.TODAY;
      break;
    case 'App\\Rules\\Frame':
    case 'App\\Rules\\FrameGroup':
      iconImage = IconTypes.TV;
      break;
    case 'App\\Rules\\UvIndex':
      iconImage = IconTypes.WB_SUNNY;
      break;
    case 'App\\Rules\\Transport\\FlightProperties':
      iconImage = IconTypes.LOCAL_AIRPORT;
      break;
    case 'App\\Rules\\Tags':
      iconImage = IconTypes.LABEL;
      break;
    case 'App\\Rules\\Dates':
      iconImage = IconTypes.DATE_RANGE;
      break;
    default:
      iconImage = '';
  }

  return iconImage;
};

export const triggerBodyClickToClose = () => {
  window.document.body.click();
};

export const isCreativeProcessed = (creative) => {
  const creativeObj = Map.isMap(creative) ? creative.toJS() : creative;

  switch (creativeObj.type) {
    case CreativeTypes.UNPROCESSED:
      return false;
    case CreativeTypes.HTML5:
      return Boolean(creativeObj['html-asset-url']);
    default:
      return Boolean(creativeObj['prepared-asset'] && Object.keys(creativeObj['prepared-asset']).length);
  }
};

export const smoothScroll = (element, top = 0, left = 0) => {
  if (element) element.scrollTo({ top, left, behavior: 'smooth' });
};

export const checkDeviceWidthSmallerThanDesktop = (width) => width < 1024;

export const validatePageNumber = (pageNumber) => {
  const page = parseInt(pageNumber);
  return !Number.isNaN(page) && page > 0 ? page : 1;
};
