import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';

import Dialog from 'assets/components/presentational/Dialog';
import { CreativeModerationStatuses } from 'modules/Helpers';
import Input from 'components/patterns/Input';
import Warnings from 'components/common/Warnings';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import style from './moderationDialog.scss';

class ModerationDialog extends Component {
  renderModerationDialog() {
    const { creative, saving, t, status, handleToggleModerationDialog, handleModerateClick } = this.props;

    const actions = [
      {
        label: t('Cancel'),
        onClick: () => handleToggleModerationDialog(null),
        disabled: saving,
        type: 'cancel',
      },
      { label: t('Save'), onClick: () => handleModerateClick(status), disabled: saving, type: 'submit' },
    ];

    return (
      <Dialog
        active={status !== null}
        actions={actions}
        onEscKeyDown={() => handleToggleModerationDialog(null)}
        onOverlayClick={() => handleToggleModerationDialog(null)}
        title={creative.get('name')}
        type="small"
      >
        {this.renderApprovingDialog()}
        {this.renderRejectingDialog()}
      </Dialog>
    );
  }

  renderApprovingDialog() {
    const { status } = this.props;

    if (status === CreativeModerationStatuses.APPROVED) {
      return (
        <div>
          {this.renderModerationPrompt(CreativeModerationStatuses.APPROVED)}
          <div className={style.additionalInfo}>{this.renderWarnings()}</div>
        </div>
      );
    }
  }

  renderRejectingDialog() {
    const { rejectReason, saving, status, t, validationError, handleRejectReasonChange } = this.props;
    const rejectDialogText = () => <span>{t('Please enter a valid reject reason')}</span>;

    if (status === CreativeModerationStatuses.REJECTED) {
      return (
        <div>
          {this.renderModerationPrompt(CreativeModerationStatuses.REJECTED)}
          <div className={style.additionalInfo}>
            <Input
              autoFocus
              disabled={saving}
              error={validationError ? rejectDialogText() : null}
              label={t('Please give a reason for rejecting this creative')}
              maxLength={255}
              onChange={handleRejectReasonChange}
              value={rejectReason}
              required
            />
          </div>
        </div>
      );
    }
  }

  renderModerationPrompt(status) {
    const { t } = this.props;
    const label = status === CreativeModerationStatuses.APPROVED ? 'approve' : 'reject';

    return <span>Are you sure you wish to {t(label)} this creative? This cannot be undone.</span>;
  }

  renderWarnings() {
    const { creative } = this.props;
    const warnings = creative.get('warnings');

    if (!warnings) {
      return null;
    }

    return (
      <Warnings
        warnings={warnings.toJS().map((warning) => warning.message)}
        icon={<Icon iconType={IconTypes.WARNING} color={IconColors.RED} />}
      />
    );
  }

  render() {
    return this.renderModerationDialog();
  }
}

export default withNamespaces(['common', 'creative', 'errorCodes'], { wait: false })(ModerationDialog);

ModerationDialog.propTypes = {
  creative: PropTypes.instanceOf(Map).isRequired,
  handleModerateClick: PropTypes.func.isRequired,
  handleRejectReasonChange: PropTypes.func.isRequired,
  handleToggleModerationDialog: PropTypes.func.isRequired,
  rejectReason: PropTypes.string,
  saving: PropTypes.bool,
  status: PropTypes.number,
  validationError: PropTypes.bool,
  t: PropTypes.func,
};
