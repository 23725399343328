import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { get, isFunction } from 'lodash';

import Link from 'assets/components/presentational/Link';

import style from './subNavigation.scss';

class SubNavigation extends Component {
  handleClick = (item, e) => {
    e.preventDefault();
    if (isFunction(get(item, 'onClick'))) {
      item.onClick(e);
    }
  };

  isActive = (item) => {
    const { active } = this.props;

    return item.label === active;
  };

  getClassName = (item) => {
    let className = style.item;

    if (this.isActive(item)) {
      className = style.itemActive;

      return className;
    }

    className = style.item;

    return className;
  };

  renderItem(i, key) {
    const { t } = this.props;

    const className = this.getClassName(i);
    const itemLabel = get(i, 'displayLabel') ? t(get(i, 'displayLabel')) : t(get(i, 'label'));

    if (this.isActive(i)) {
      return (
        <span className={className} key={key}>
          {itemLabel}
        </span>
      );
    }

    if (get(i, 'to')) {
      return (
        <Link className={className} key={key} to={get(i, 'to')}>
          {itemLabel}
        </Link>
      );
    }

    return (
      <Link className={className} key={key} onClick={(e) => this.handleClick(i, e)}>
        {itemLabel}
      </Link>
    );
  }

  render() {
    const { items } = this.props;

    return (
      <div className={style.component}>
        <div className={style.itemsLeft}>
          {items.filter((item) => !item.right).map((i, key) => this.renderItem(i, key))}
        </div>
        <div className={style.itemsRight}>
          {items.filter((item) => !!item.right).map((i, key) => this.renderItem(i, key))}
        </div>
      </div>
    );
  }
}

SubNavigation.propTypes = {
  active: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      // Float right
      right: PropTypes.bool,
      to: PropTypes.string,
    }),
  ),
  labels: PropTypes.object.isRequired,
  t: PropTypes.func,
};

SubNavigation.defaultProps = {
  active: '',
  labels: {},
  items: [],
  right: false,
};

export default withNamespaces(['common', 'navigation'], { wait: false })(SubNavigation);
