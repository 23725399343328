import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './frame-specifications.scss';

class FrameSpecifications extends Component {
  renderNoFrameSpecs() {
    const { frameSpecifications, emptyFrameSpecsMessage, t } = this.props;

    if (!frameSpecifications.size) {
      return (
        <div className={style.frame}>
          <div className={style.frameHeading}>
            <Heading size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
              <Icon iconType={IconTypes.TV} /> {t(emptyFrameSpecsMessage)}
            </Heading>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { children } = this.props;

    return (
      <div className={style.component}>
        {this.renderNoFrameSpecs()}
        {children}
      </div>
    );
  }
}

export default withNamespaces(['common', 'creative'], { wait: false })(FrameSpecifications);

FrameSpecifications.propTypes = {
  // TODO: Only accept array of frame specs
  children: PropTypes.node.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  emptyFrameSpecsMessage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

FrameSpecifications.defaultProps = {};
