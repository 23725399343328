import React from 'react';
import PropTypes from 'prop-types';
import Tag, { TagTheme, TagVariant } from 'assets/components/presentational/Tag';
import { withNamespaces } from 'react-i18next';

export const CAMPAIGN_STAGES = {
  START: {
    tagTheme: TagTheme.GREY,
    icon: 'clear',
  },
  PARTIAL: {
    tagTheme: TagTheme.YELLOW,
    icon: 'tonality',
  },
  END: {
    tagTheme: TagTheme.GREEN,
    icon: 'done_all',
  },
};

const CampaignStatus = ({ label, currentCampaignStage, t, className }) => (
  <Tag className={className} tagVariant={TagVariant.CLEAR} copy={t(label)} {...currentCampaignStage} />
);

const CampaignStatusTranslated = withNamespaces(['common', 'bookings'], { wait: false })(CampaignStatus);
export default CampaignStatusTranslated;

CampaignStatus.propTypes = {
  label: PropTypes.string.isRequired,
  currentCampaignStage: PropTypes.shape({
    tagTheme: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CampaignStatus.defaultProps = {
  className: '',
};
