import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'react-toolbox/lib/drawer';
import theme from './drawer.scss';

export default class CustomDrawer extends React.Component {
  componentDidUpdate() {
    const { getRef } = this.props;
    if (getRef) {
      getRef(document.querySelector('[data-react-toolbox="drawer"]'));
    }
  }

  render() {
    const { active, className, onOverlayClick, children } = this.props;

    return (
      <div>
        <Drawer active={active} className={className} onOverlayClick={onOverlayClick} type="right" theme={theme}>
          {children}
        </Drawer>
      </div>
    );
  }
}

CustomDrawer.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  onOverlayClick: PropTypes.func,
  getRef: PropTypes.func,
};

CustomDrawer.defaultProps = {
  className: '',
  children: null,
  onOverlayClick: null,
  getRef: () => {},
};
