import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { notifyApiError } from 'actions/notify';

export const FETCH_REQUEST = 'frames/FETCH_REQUEST';
export const FETCH_SUCCESS = 'frames/FETCH_SUCCESS';
export const FETCH_FAILURE = 'frames/FETCH_FAILURE';

export const UPDATE_REQUEST = 'frames/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'frames/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'frames/UPDATE_FAILURE';

export const DELETE_REQUEST = 'frames/DELETE_REQUEST';
export const DELETE_SUCCESS = 'frames/DELETE_SUCCESS';
export const DELETE_FAILURE = 'frames/DELETE_FAILURE';

export const RESTORE_REQUEST = 'frames/RESTORE_REQUEST';
export const RESTORE_SUCCESS = 'frames/RESTORE_SUCCESS';
export const RESTORE_FAILURE = 'frames/RESTORE_FAILURE';

export const FETCH_CSV_REQUEST = 'frames/FETCH_CSV_REQUEST';
export const FETCH_CSV_SUCCESS = 'frames/FETCH_CSV_SUCCESS';
export const FETCH_CSV_COMPLETE = 'frames/FETCH_CSV_COMPLETE';

export const FETCH_FRAME_REQUEST = 'frames/FETCH_FRAME_REQUEST';
export const FETCH_FRAME_SUCCESS = 'frames/FETCH_FRAME_SUCCESS';
export const FETCH_FRAME_COMPLETE = 'frames/FETCH_FRAME_COMPLETE';

export const CLEAR = 'frames/CLEAR';

export const getFilters = (filters) =>
  filters.map((filter) => {
    if (Object.hasOwnProperty.call(filter, 'player_id')) {
      return { by_player_id: filter.player_id }; // eslint-disable-line camelcase
    }

    if (Object.hasOwnProperty.call(filter, 'display_unit_id')) {
      return { by_display_unit_id: filter.display_unit_id }; // eslint-disable-line camelcase
    }

    if (Object.hasOwnProperty.call(filter, 'name')) {
      return { name: `%${filter.name}%` };
    }

    if (Object.hasOwnProperty.call(filter, 'external_id')) {
      return { external_id: `%${filter.external_id}%` }; // eslint-disable-line camelcase
    }

    if (Object.hasOwnProperty.call(filter, 'extended_code')) {
      return { extended_code: `%${filter.extended_code}%` }; // eslint-disable-line camelcase
    }

    if (Object.hasOwnProperty.call(filter, 'route_code')) {
      return { route_code: `%${filter.route_code}%` }; // eslint-disable-line camelcase
    }

    if (Object.hasOwnProperty.call(filter, 'actions')) {
      return { [filter.actions]: true };
    }

    return filter;
  });

export const fetchFramesCsv = (sorts = ['+name'], filters = []) => async (dispatch) => {
  dispatch({ type: FETCH_CSV_REQUEST });

  try {
    const response = await Request.send({
      method: 'GET',
      endpoint: 'frames',
      pagination: Request.ALL_PAGES,
      sorts,
      filters: getFilters(filters),
      includes: ['location'],
    });
    const items = fromJS(response.data.body.parsed);
    dispatch({ type: FETCH_CSV_SUCCESS, items });
    return response;
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    dispatch({ type: FETCH_CSV_COMPLETE });
  }
};

export const fetchFrames = (pageNumber = 1, sorts = ['+name'], filters = []) => (dispatch) => {
  dispatch({ type: FETCH_REQUEST });
  return Request.send({
    method: 'GET',
    endpoint: 'frames',
    pagination: {
      pageNumber,
    },
    sorts,
    filters: getFilters(filters),
  })
    .then((response) =>
      dispatch({
        type: FETCH_SUCCESS,
        frames: fromJS(response.data.body.parsed),
        pagination: fromJS(response.data.body.meta.pagination),
      }),
    )
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_FAILURE });
    });
};

export const updateFrame = (frameId, data) => (dispatch) => {
  dispatch({ type: UPDATE_REQUEST, frameId });
  return Request.send({
    method: 'PATCH',
    endpoint: `frames/${frameId}`,
    data,
  })
    .then((response) =>
      dispatch({
        type: UPDATE_SUCCESS,
        frame: fromJS(response.data.body.parsed),
        frameId,
      }),
    )
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: UPDATE_FAILURE, frameId });
      return Promise.reject(error);
    });
};

export const deleteFrame = (frameId) => (dispatch) => {
  dispatch({ type: DELETE_REQUEST, frameId });

  return Request.send({
    method: 'DELETE',
    endpoint: `frames/${frameId}`,
  })
    .then((response) =>
      dispatch({
        type: DELETE_SUCCESS,
        frame: fromJS(response.data.body.parsed),
        frameId,
      }),
    )
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: DELETE_FAILURE, frameId });
      return Promise.reject(error);
    });
};

export const fetchFrame = (frameId) => (dispatch) => {
  dispatch({ type: FETCH_FRAME_REQUEST, frameId });
  return Request.send({
    method: 'GET',
    endpoint: `frames/${frameId}`,
  })
    .then((response) => {
      dispatch({
        type: FETCH_FRAME_SUCCESS,
        frame: fromJS(response.data.body.parsed),
        frameId,
      });
      return dispatch({ type: FETCH_FRAME_COMPLETE, frameId });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_FRAME_COMPLETE, frameId });
      return Promise.reject(error);
    });
};

export const restoreFrame = (frameId) => (dispatch) => {
  dispatch({ type: RESTORE_REQUEST, frameId });
  return Request.send({
    method: 'PATCH',
    endpoint: `frames/restore/${frameId}`,
  })
    .then((response) =>
      dispatch({
        type: RESTORE_SUCCESS,
        frame: fromJS(response.data.body.parsed),
        frameId,
      }),
    )
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: RESTORE_FAILURE, frameId });
      return Promise.reject(error);
    });
};
