import Request from 'modules/API/request';
import { escapeFilterCommas } from 'modules/Helpers/function';
import { fromJS } from 'immutable';
import { notifyApiError } from 'actions/notify';

export const FETCH_FRAMES_REQUEST = 'campaign/mediabuy/FETCH_FRAMES_REQUEST';
export const FETCH_FRAMES_SUCCESS = 'campaign/mediabuy/FETCH_FRAMES_SUCCESS';
export const FETCH_FRAMES_COMPLETE = 'campaign/mediabuy/FETCH_FRAMES_COMPLETE';

export const FETCH_HISTORY_REQUEST = 'campaign/mediabuy/FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_SUCCESS = 'campaign/mediabuy/FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_COMPLETE = 'campaign/mediabuy/FETCH_HISTORY_COMPLETE';

export const FETCH_FRAMES_CSV_REQUEST = 'campaign/mediabuy/FETCH_FRAMES_CSV_REQUEST';
export const FETCH_FRAMES_CSV_SUCCESS = 'campaign/mediabuy/FETCH_FRAMES_CSV_SUCCESS';
export const FETCH_FRAMES_CSV_COMPLETE = 'campaign/mediabuy/FETCH_FRAMES_CSV_COMPLETE';

export const FETCH_FRAMES_PAGINATION_MERGE = 'campaign/mediabuy/FETCH_FRAMES_PAGINATION_MERGE';

export const SET_FRAMES_FILTER_VALUE = 'campaign/mediabuy/SET_FRAMES_FILTER_VALUE';

export const RESET = 'campaign/mediabuy/RESET';
export const SET_ADD_FRAMES_ERROR = 'campaign/mediabuy/SET_ADD_FRAMES_ERROR';
export const CLEAR_ADD_FRAMES_ERROR = 'campaign/mediabuy/CLEAR_ADD_FRAMES_ERROR';
export const SET_ADD_FRAMES_INPUT = 'campaign/mediabuy/SET_ADD_FRAMES_INPUT';

export const FETCH_SCREENSHOTS_REQUEST = 'campaign/mediabuy/FETCH_SCREENSHOTS_REQUEST';
export const FETCH_SCREENSHOTS_SUCCESS = 'campaign/mediabuy/FETCH_SCREENSHOTS_SUCCESS';
export const FETCH_SCREENSHOTS_COMPLETE = 'campaign/mediabuy/screenshots/FETCH_SCREENSHOTS_COMPLETE';

export const CLEAR_SCREENSHOTS = 'campaign/mediabuy/screenshots/CLEAR_SCREENSHOTS';

export const FETCH_FRAME_REQUEST = 'campaign/mediabuy/screenshots/FETCH_FRAME_REQUEST';
export const FETCH_FRAME_SUCCESS = 'campaign/mediabuy/screenshots/FETCH_FRAME_SUCCESS';
export const FETCH_FRAME_COMPLETE = 'campaign/mediabuy/screenshots/FETCH_FRAME_COMPLETE';

export const fetchFrames = (campaignId, pageNumber = 1, searchTerm = '') => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/frames`,
    pagination: {
      pageNumber,
      pageSize: 15,
    },
    includes: ['location'],
    filters: [],
    sorts: ['+external_id'],
  };

  const filterableFields = [
    'external_id',
    'name',
    'group',
    'like_display_unit_id',
    'by_display_unit_id',
    'like_player_id',
    'by_specification_name',
    'by_location_attributes',
    'by_specification_attributes',
  ];

  if (searchTerm) {
    options.filters.push({ [filterableFields.join(',')]: `%${escapeFilterCommas(searchTerm)}%` });
  }

  dispatch({ type: FETCH_FRAMES_REQUEST, campaignId });

  return Request.send(options)
    .then((response) => {
      const frames = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_FRAMES_SUCCESS, campaignId, frames });
      dispatch({ type: FETCH_FRAMES_COMPLETE, campaignId });
      dispatch(mergePaginationFrames(response.data.body.meta.pagination));

      return Promise.resolve(frames);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_FRAMES_COMPLETE, campaignId });
    });
};

export const fetchFramesCsv = (campaignId) => async (dispatch) => {
  dispatch({ type: FETCH_FRAMES_CSV_REQUEST, campaignId });

  try {
    const response = await Request.send({
      endpoint: `campaigns/${campaignId}/frames`,
      pagination: Request.ALL_PAGES,
    });
    const items = fromJS(response.data.body.parsed);

    dispatch({ type: FETCH_FRAMES_CSV_SUCCESS, campaignId, items });
    return response;
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    dispatch({ type: FETCH_FRAMES_CSV_COMPLETE, campaignId });
  }
};

export const fetchFrameHistoryStats = (campaignId) => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/frame-history-stats`,
  };

  dispatch({ type: FETCH_HISTORY_REQUEST, campaignId });

  return Request.send(options)
    .then((response) => {
      const frameHistoryStats = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_HISTORY_SUCCESS, campaignId, frameHistoryStats });
      dispatch({ type: FETCH_HISTORY_COMPLETE, campaignId });

      return Promise.resolve(frameHistoryStats);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_HISTORY_COMPLETE }, campaignId);
    });
};

export const mergePaginationFrames = (paginationFrames) => ({
  type: FETCH_FRAMES_PAGINATION_MERGE,
  paginationFrames,
});

export const setFramesFilterValue = (filterValue = '') => ({
  type: SET_FRAMES_FILTER_VALUE,
  filterValue,
});

export const resetState = () => ({
  type: RESET,
});

export const fetchScreenshots = (campaignId, frameId) => (dispatch) => {
  const options = {
    endpoint: `campaigns/${campaignId}/frames/${frameId}/screenshots`,
    method: 'GET',
  };

  dispatch({ type: FETCH_SCREENSHOTS_REQUEST });

  return Request.send(options)
    .then((response) => {
      const screenshots = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_SCREENSHOTS_SUCCESS, screenshots });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_SCREENSHOTS_COMPLETE });
      return Promise.resolve();
    });
};

export const clearScreenshots = () => ({
  type: CLEAR_SCREENSHOTS,
});

export const setAddFramesError = (error, missingFrames) => ({
  type: SET_ADD_FRAMES_ERROR,
  error,
  missingFrames,
});

export const clearAddFramesError = () => ({
  type: CLEAR_ADD_FRAMES_ERROR,
});

export const setAddFramesInput = (addFramesInput) => ({
  type: SET_ADD_FRAMES_INPUT,
  addFramesInput,
});

export const fetchFrame = (campaignId, frameId) => (dispatch) => {
  dispatch({ type: FETCH_FRAME_REQUEST, frameId });
  return Request.send({
    method: 'GET',
    endpoint: `campaigns/${campaignId}/frames/${frameId}`,
  })
    .then((response) => {
      dispatch({
        type: FETCH_FRAME_SUCCESS,
        frame: fromJS(response.data.body.parsed),
        frameId,
      });
      return dispatch({ type: FETCH_FRAME_COMPLETE, frameId });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_FRAME_COMPLETE, frameId });
      return Promise.reject(error);
    });
};
