import React from 'react';
import PropTypes from 'prop-types';
import SimpleFileInput from 'assets/components/presentational/SimpleFileInput';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import styles from './field.scss';

const formatHint = (t, spec) => {
  if (!spec) return '';
  const keys = Object.keys(spec);
  if (!keys.length) return;
  // We need both width and height for images
  if (isNaN(parseInt(spec.width)) || isNaN(parseInt(spec.height))) {
    return null;
  }
  const hint = t('Image will be resized to match the following dimensions: ');
  return `${hint} ${keys
    .map((k) => {
      switch (k) {
        case 'width':
          return t('Width {{x}} pixels', { x: spec[k] });
        case 'height':
          return t('Height {{x}} pixels', { x: spec[k] });
        default:
          return '';
      }
    })
    .reduce((v, c) => `${v}, ${c}`)}`;
};

const Video = function(props) {
  const { config, field, readonly, t } = props;
  const hint = formatHint(t, config.meta);
  return (
    <div>
      <SimpleFileInput label={config.name} fileType="image" {...field} disabled={readonly} />
      {hint ? (
        <div className={styles.fieldHint}>
          <div className={styles.infoIcon}>
            <Icon iconType={IconTypes.INFO_OUTLINE} />
          </div>
          {hint}
        </div>
      ) : null}
    </div>
  );
};

Video.propTypes = {
  config: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default Video;
