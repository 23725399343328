import config from 'app-config';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';
import moment from 'moment';

import Card from 'components/patterns/Card';
import Warnings from 'components/common/Warnings';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import ConfirmButton from 'assets/components/presentational/ConfirmButton';
import { ButtonThemes } from 'assets/components/presentational/Button';

import style from './BookingCard.scss';

const PromoteButton = withNamespaces(['bookings'], { wait: false })(({ onConfirmClick, disabled, t }) => (
  <ConfirmButton
    onConfirmClick={onConfirmClick}
    className={style.promoteButton}
    label={t('promote_booking')}
    confirmLabel={t('confirm_booking_promotion')}
    buttonTheme={ButtonThemes.PRIMARY}
    confirmButtonTheme={ButtonThemes.PRIMARY}
    disabled={disabled}
  />
));

const PromoteAgainButton = withNamespaces(['bookings'], { wait: false })(({ onConfirmClick, disabled, t }) => (
  <ConfirmButton
    onConfirmClick={onConfirmClick}
    className={style.promoteButton}
    label={t('promote_booking')}
    confirmLabel={t('confirm_booking_promotion')}
    buttonTheme={ButtonThemes.DANGER}
    confirmButtonTheme={ButtonThemes.DANGER}
    disabled={disabled}
  />
));

const RePromoteButton = withNamespaces(['bookings'], { wait: false })(({ onConfirmClick, disabled, t }) => (
  <ConfirmButton
    onConfirmClick={onConfirmClick}
    className={style.rePromoteButton}
    label={t('re-promote')}
    confirmLabel={t('confirm_booking_promotion')}
    buttonTheme={ButtonThemes.WARNING}
    confirmButtonTheme={ButtonThemes.WARNING}
    icon={<Icon iconType={IconTypes.AUTORENEW} />}
    disabled={disabled}
  />
));

class BookingCard extends Component {
  onPromote = () => {
    const { campaignId, booking, promoteBooking } = this.props;
    promoteBooking(campaignId, booking);
  };

  onDetach = () => {
    const { booking, campaignId, detachBooking } = this.props;

    // TODO: This returns the result of the promise because the
    // ConfirmButton components uses it to set its own internal state
    // Ideally, they should be decoupled
    return detachBooking(campaignId, booking);
  };

  renderRemoteStatus = () => {
    const { booking, t } = this.props;
    const { isCompleted, isFailed, isInProgress, sentAt, sentBy } = booking['remote-status'];

    if (isCompleted) {
      const promotedAt = sentAt && moment.utc(sentAt).format('D MMMM');
      const promotedBy = sentBy && sentBy.name;

      return (
        <>
          <div className={style.status}>
            <span className={style.statusIcon}>
              <Icon iconType={IconTypes.DONE} color={IconColors.GREEN} />
            </span>
            {t('promoted')}
          </div>
          <div className={style.promotedStatusMeta}>
            {promotedAt && `${t('on')} ${promotedAt} `}
            {promotedBy && `${t('by')} ${promotedBy}`}
          </div>
        </>
      );
    }

    if (isInProgress) {
      return (
        <p className={style.status}>
          <span className={style.statusIcon}>
            <Icon iconType={IconTypes.HOURGLASS_EMPTY} color={IconColors.YELLOW} />
          </span>
          {t('promotion_in_progress')}
        </p>
      );
    }

    if (isFailed) {
      return (
        <p className={style.status}>
          <span className={style.statusIcon}>
            <Icon iconType={IconTypes.WARNING} color={IconColors.RED} />
          </span>
          {t('post_promotion_warning')}
        </p>
      );
    }

    return (
      <p className={style.status}>
        <span className={style.statusIcon}>
          <Icon iconType={IconTypes.CLEAR} color={IconColors.GRAY} />
        </span>
        {t('status_ready')}
      </p>
    );
  };

  renderErrorText = () => {
    const { booking, t } = this.props;
    const { isCompleted, hasErrors, hasBlockingErrors } = booking['remote-status'];

    if (!hasErrors) {
      return null;
    }

    if (!isCompleted) {
      return (
        <div className={style.promotionWarning}>
          <p className={style.warning}>
            {hasBlockingErrors ? t('pre_promotion_error') : t('pre_promotion_error_not_blocking')}
          </p>
        </div>
      );
    }

    return (
      <div className={style.promotionWarning}>
        <p className={style.warning}>{t('post_promotion_error')}</p>
      </div>
    );
  };

  renderPromotionButton = () => {
    const { booking, user } = this.props;
    const { isCompleted, isFailed, canSend, hasBlockingErrors } = booking['remote-status'];
    const isSuperUser = user.get('is-super-user');

    if (hasBlockingErrors) {
      return null;
    }

    if (isCompleted && isSuperUser) {
      return (
        <div className={style.promotionButtons}>
          <RePromoteButton onConfirmClick={this.onPromote} />
        </div>
      );
    }

    if (!canSend) {
      return null;
    }

    return (
      <div className={style.promotionButtons}>
        {isFailed && isSuperUser ? (
          <PromoteAgainButton onConfirmClick={this.onPromote} />
        ) : (
          <PromoteButton onConfirmClick={this.onPromote} />
        )}
      </div>
    );
  };

  getBookingErrorFieldArrayValues = (error, field) => {
    const { t } = this.props;

    const hasFrameCodes =
      error.data && error.data[field] && Array.isArray(error.data[field]) && error.data[field].length;

    if (!hasFrameCodes) {
      return t('no_ids_available');
    }

    return error.data[field].join(', ');
  };

  renderBookingErrors = () => {
    const { booking, t } = this.props;

    if (!booking['booking-errors'] || !booking['booking-errors'].length) {
      return null;
    }

    const adNetwork = booking.network || t('Unknown AD network');

    const warnings = booking['booking-errors'].map((error) =>
      t(error.code, {
        ad_network: adNetwork, // eslint-disable-line camelcase
        display_units: this.getBookingErrorFieldArrayValues(error, 'display-units'), // eslint-disable-line camelcase
        frame_codes: this.getBookingErrorFieldArrayValues(error, 'frame-codes'), // eslint-disable-line camelcase
        defaultValue: t('default_error_message'),
        application: config.app.name,
      }),
    );

    return <Warnings warnings={warnings} icon={<Icon iconType={IconTypes.WARNING} color={IconColors.RED} />} />;
  };

  render() {
    const { booking, t } = this.props;
    const bookingStartDate = booking['starts-at'] && moment.utc(booking['starts-at']).format('D MMMM YYYY');
    const bookingEndDate = booking['ends-at'] && moment.utc(booking['ends-at']).format('D MMMM YYYY');

    return (
      <div className={style.component}>
        <Card>
          <div className={style.header}>
            <Heading size={HeadingSizes.MEDIUM} tag={HeadingTags.H2} className={style.name}>
              {booking.name}
            </Heading>
            <ConfirmButton
              onConfirmClick={this.onDetach}
              label=""
              confirmLabel={t('detach_booking_confirmation')}
              icon={<Icon iconType={IconTypes.DELETE} />}
              confirmIcon={<Icon iconType={IconTypes.DELETE} />}
              buttonTheme={ButtonThemes.TRANSPARENT}
              confirmButtonTheme={ButtonThemes.WARNING}
              className={style.deleteButton}
            />
          </div>
          <div className={style.date}>
            {t('from')} <em>{bookingStartDate}</em> {t('to')} <em>{bookingEndDate || ''}</em>
          </div>
          <div className={style.info}>
            <div className={style.detail}>
              <p className={style.detailLabel}>{t('booking_reference')}:</p>
              <p className={style.detailValue}>{booking.code ? booking.code : t('manual')}</p>
            </div>
            <div>
              <div className={style.remoteStatus}>{this.renderRemoteStatus()}</div>
              {this.renderPromotionButton()}
              {this.renderErrorText()}
            </div>
          </div>

          {booking['booking-errors'] && booking['booking-errors'].length ? (
            <div className={style.errorDetail}>{this.renderBookingErrors()}</div>
          ) : null}
        </Card>
      </div>
    );
  }
}

const TempBookingPropTypes = {
  name: PropTypes.string.isRequired,
};

const RemoteStatusPropTypes = PropTypes.shape({
  remoteName: PropTypes.string,
  canSend: PropTypes.bool,
  hasBlockingErrors: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isFailed: PropTypes.bool,
  hasErrors: PropTypes.bool,
  sentAt: PropTypes.string,
  sentBy: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
});

const BookingPropTypes = PropTypes.shape({
  ...TempBookingPropTypes,
  'external-id': PropTypes.string,
  network: PropTypes.string,
  'starts-at': PropTypes.string,
  'ends-at': PropTypes.string,
  code: PropTypes.string,
  'pushed-build-identifier': PropTypes.string,
  'remote-status': RemoteStatusPropTypes,
  'booking-errors': PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.shape({
        'display-units': PropTypes.arrayOf(PropTypes.number.isRequired),
      }),
    }),
  ),
});

BookingCard.propTypes = {
  t: PropTypes.func.isRequired,
  booking: BookingPropTypes.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  campaignId: PropTypes.string.isRequired,
  promoteBooking: PropTypes.func.isRequired,
  detachBooking: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'bookings', 'errorCodes'], { wait: false })(BookingCard);
export { BookingPropTypes };
