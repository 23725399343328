import { handleActions } from 'redux-actions';
import { fromJS, Map } from 'immutable';

const getInitialState = () => ({
  activeContentItemId: '',
  isFetchingContentItems: false,
  isFetchingContentItem: false,
  isSaving: false,
  isSavingRuleSets: false,
  pagination: new Map(),
  showContentItemForm: false,
  showSettingsForm: false,
  shouldAddAnotherContentItem: false,
  searchContentValue: '',
  selectedContentTypeFilter: '',
  selectedModerationStatusFilter: '',
});

const manualContent = handleActions(
  {
    UPDATE_CONTENT_ITEMS: (state, action) => ({
      ...state,
      contentItems: fromJS(action.contentItems),
    }),
    EDIT_CONTENT_ITEM: (state, action) => ({
      ...state,
      showContentItemForm: true,
      activeContentItemId: action.contentItemId,
    }),
    CLOSE_CONTENT_ITEM_FORM: (state) => ({
      ...state,
      isSaving: false,
      showContentItemForm: false,
      activeContentItemId: '',
      selectedContentType: null,
    }),
    UPDATE_CONTENT_ITEM_REQUEST: (state) => ({
      ...state,
      isSaving: true,
    }),
    UPDATE_CONTENT_ITEM_FAILURE: (state) => ({
      ...state,
      isSaving: false,
    }),
    ADD_CONTENT_ITEM: (state) => ({
      ...state,
      showContentItemForm: true,
    }),
    SHOULD_ADD_ANOTHER_CONTENT_ITEM: (state, action) => ({
      ...state,
      shouldAddAnotherContentItem: !!action.addAnother,
    }),
    SELECT_CONTENT_TYPE: (state, action) => ({
      ...state,
      selectedContentType: action.contentType,
    }),
    CREATE_CONTENT_ITEM_REQUEST: (state) => ({
      ...state,
      isSaving: true,
    }),
    CREATE_CONTENT_ITEM_COMPLETE: (state) => ({
      ...state,
      isSaving: false,
    }),
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingContentItems: action.relation === 'content-items' ? true : state.isFetchingContentItems,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingContentItems: action.relation === 'content-items' ? false : state.isFetchingContentItems,
    }),
    CAMPAIGN_CONTENT_RESET: () => getInitialState(),
    CAMPAIGN_CONTENT_PAGINATION_MERGE: (state, action) => ({
      ...state,
      pagination: fromJS(action.pagination),
    }),
    CAMPAIGN_RELATION_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingContentItem: action.relation === 'content-items' ? true : state.isFetchingContentItem,
    }),
    CAMPAIGN_RELATION_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingContentItem: action.relation === 'content-items' ? false : state.isFetchingContentItem,
    }),
    CAMPAIGN_CONTENT_TOGGLE_SHOW_DRAWER: (state, action) => ({
      ...state,
      showContentItemForm: action.component === 'edit' ? !state.showContentItemForm : state.showContentItemForm,
      showSettingsForm: action.component === 'settings' ? !state.showSettingsForm : state.showSettingsForm,
    }),
    CAMPAIGN_CONTENT_SET_ACTIVE_CONTENT_ITEM_ID: (state, action) => ({
      ...state,
      activeContentItemId: action.contentItemId,
    }),
    CAMPAIGN_RELATIONS_SYNC_REQUEST: (state, action) => ({
      ...state,
      isSavingRuleSets:
        action.modelName === 'content-items' && action.relationName === 'rule-sets' ? true : state.isSavingRuleSets,
    }),
    CAMPAIGN_RELATIONS_SYNC_COMPLETE: (state, action) => ({
      ...state,
      isSavingRuleSets:
        action.modelName === 'content-items' && action.relationName === 'rule-sets' ? false : state.isSavingRuleSets,
    }),
    SET_CONTENT_SEARCH_VALUE: (state, action) => ({
      ...state,
      searchContentValue: action.searchValue,
    }),
    SET_CONTENT_TYPE_FILTER: (state, action) => ({
      ...state,
      selectedContentTypeFilter: action.filter,
    }),
    SET_MODERATION_STATUS_FILTER: (state, action) => ({
      ...state,
      selectedModerationStatusFilter: action.filter,
    }),
  },
  getInitialState(),
);

export default manualContent;
