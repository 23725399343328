import { fromJS } from 'immutable';
import { fetchPermissions } from './permissions';

const FETCH_USER_PERMISSIONS_REQUEST = 'FETCH_USER_PERMISSIONS_REQUEST';
const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS';
const FETCH_USER_PERMISSIONS_COMPLETE = 'FETCH_USER_PERMISSIONS_COMPLETE';

export const fetchUserPermissions = (userId, object, objectId = '', extraOptions = {}) => (dispatch) => {
  dispatch({ type: FETCH_USER_PERMISSIONS_REQUEST, userId, object, objectId });

  const options = {
    filters: [{ user_id: userId }, { object }], // eslint-disable-line camelcase
  };

  // objectId is optional, for instance when checking if a user has add_campaign business_unit permission
  if (objectId) {
    options.filters.push({ object_id: objectId }); // eslint-disable-line camelcase
  }

  Object.assign(options, extraOptions);

  return dispatch(fetchPermissions(options))
    .then((response) => {
      const permissions = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_USER_PERMISSIONS_SUCCESS, userId, object, objectId, permissions });
      dispatch({ type: FETCH_USER_PERMISSIONS_COMPLETE, userId, object, objectId });

      return Promise.resolve(permissions);
    })
    .catch(() => {
      dispatch({ type: FETCH_USER_PERMISSIONS_COMPLETE, userId, object, objectId });
    });
};
