import deepmerge from 'deepmerge';
import { fromJS } from 'immutable';

import Request from 'modules/API/request';
import { notifyApiError } from 'actions/notify';

export const FETCH_CAMPAIGNS = 'DASHBOARD/FETCH_CAMPAIGNS';

export const fetchCampaigns = (extraOptions = {}, pageNumber = 1, setIsFetching = () => {}) => async (dispatch) => {
  setIsFetching(true);

  try {
    let options = {
      endpoint: 'campaigns',
      method: 'GET',
      pagination: {
        pageNumber,
        pageSize: 15,
      },
    };

    options = deepmerge(options, extraOptions);

    const response = await Request.send(options);
    const campaigns = fromJS(response.data.body.parsed);
    const totalPages = response.data.body.meta.pagination.total_pages;

    dispatch({
      type: FETCH_CAMPAIGNS,
      campaigns,
      totalPages,
    });
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    setIsFetching(false);
  }
};
