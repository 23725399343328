import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Input from 'components/patterns/Input';

import style from './frameForm.scss';

const Options = {
  EXTERNAL_IDS: {
    id: 0,
    label: 'List of External Ids, comma separated or one per line. Add business unit code to the beginning.',
  },
  DISPLAY_UNIT_IDS: {
    id: 1,
    label: 'List of Display Unit Ids, comma separated or one per line.',
  },
  PLAYER_IDS: {
    id: 2,
    label: 'List of Player Ids, comma separated or one per line.',
  },
};

class FrameForm extends Component {
  handleOnChange = (value, field) => field.onChange(value);

  render = () => (
    <div className={style.component}>{Object.keys(Options).map((option) => this.renderOption(option))}</div>
  );

  renderOption = (option) => {
    const { fields, ruleType, t } = this.props;
    const items = fields[Options[option].id].value;
    let value = items;

    if (typeof items === 'object') {
      value = [];

      Object.values(items).forEach((item) => {
        value.push(item);
      });
    }

    return (
      <Input
        key={option}
        className={style.inputBox}
        disabled={!ruleType.get('is-selected')}
        label={t(Options[option].label)}
        multiline
        onChange={(value) => this.handleOnChange(value, fields[Options[option].id])}
        {...fields[Options[option].id]}
        value={value}
      />
    );
  };
}

export default withNamespaces(['common', 'rules'], { wait: false })(FrameForm);

FrameForm.propTypes = {
  fields: PropTypes.object.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
