import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { List } from 'immutable';

import { redirect } from 'modules/Helpers';

import { fetchBusinessUnits } from 'actions/auth';
import { hideCreateCampaignForm, addCampaignRequest } from 'actions/campaign';

import Drawer from 'assets/components/presentational/Drawer';
import Heading, { HeadingSizes } from 'assets/components/presentational/Heading';

import Form from '../CampaignForm/form';
import validate from '../CampaignForm/validate';

class CreateCampaignForm extends Component {
  updateBusinessUnits(businessUnits) {
    const { userId, dispatch } = this.props;
    if (!businessUnits || !businessUnits.size) {
      dispatch(fetchBusinessUnits(userId));
    }
  }

  handleSubmit(values) {
    const { dispatch, t } = this.props;
    const errors = validate(values, t);
    if (Object.keys(errors).length > 0) {
      return Promise.reject(errors);
    }
    return dispatch(addCampaignRequest(values)).then((campaign) => {
      if (values.Bookings && Array.isArray(values.Bookings)) {
        // redirect to campaign home if there are bookings
        redirect(`/campaigns/${campaign.id}`);
      } else {
        // redirect to campaign media buy if there are no bookings
        redirect(`/campaigns/${campaign.id}/media-buy`);
      }
      return Promise.resolve();
    });
  }

  render() {
    const { dispatch, isShowing, businessUnits, isDialogActive, dialogMessage, managers, t } = this.props;
    return (
      <Drawer active={isShowing} onOverlayClick={() => dispatch(hideCreateCampaignForm())}>
        <Heading size={HeadingSizes.LARGE}>{t('Create a new Campaign')}</Heading>

        {isShowing ? (
          <Form
            t={t}
            dispatch={dispatch}
            onSubmit={(values) => this.handleSubmit(values)}
            businessUnits={businessUnits}
            isDialogActive={isDialogActive}
            updateBusinessUnits={(bu) => this.updateBusinessUnits(bu)}
            dialogMessage={dialogMessage}
            onCancelClick={() => dispatch(hideCreateCampaignForm())}
            includeManagers
            managers={managers}
            includeBookings
          />
        ) : (
          ''
        )}
      </Drawer>
    );
  }
}

CreateCampaignForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  businessUnits: PropTypes.instanceOf(List),
  managers: PropTypes.instanceOf(List),
  bookings: PropTypes.object,
  isDialogActive: PropTypes.bool,
  dialogMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};
CreateCampaignForm.defaultProps = {
  isShowing: false,
  businessUnits: new List([]),
  managers: new List([]),
};

const mapStateToProps = (state) => ({
  isShowing: state.campaign.isShowingCreateCampaignForm,
  businessUnits: state.auth.user.get('businessUnits'),
  userId: state.auth.user.get('id', ''),
  isDialogActive: state.campaign.isShowingCampaignFormDialog,
  dialogMessage: state.campaign.campaignFormDialogMessage,
});

const Translated = withNamespaces(['common', 'campaignForm'], { wait: false })(CreateCampaignForm);
export default connect(mapStateToProps)(Translated);
