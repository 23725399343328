import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { redirect } from 'modules/Helpers';

import styles from './AuthLite.scss';

class AuthLite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticationFailed: false,
    };
  }

  async componentDidMount() {
    const { authenticateUser, token, baseUrl } = this.props;

    try {
      const campaignInviteId = await authenticateUser(token);
      redirect(`${baseUrl}/${campaignInviteId}`);
    } catch (error) {
      this.setState({ isAuthenticationFailed: true });
    }
  }

  render() {
    const { t } = this.props;
    const { isAuthenticationFailed } = this.state;

    return (
      <div className={styles.authLiteContainer}>
        <h1>
          {isAuthenticationFailed
            ? t('Unable to login. Your link may have expired or been revoked')
            : t('Checking credentials')}
        </h1>
      </div>
    );
  }
}

AuthLite.propTypes = {
  authenticateUser: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'auth'], { wait: false })(AuthLite);
