import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/patterns/Input';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import { withNamespaces } from 'react-i18next';
import style from './selectOptions.scss';

const SelectOptions = ({ selectOptions, t }) => (
  <div>
    {selectOptions.map(({ label, value }, index) => (
      <div className={style.optionGroup} key={index}>
        <Input className={style.input} label={t('Option Label')} {...label} />
        <Input className={style.input} label={t('Option Value')} {...value} />
        <Button
          className={style.button}
          icon={<Icon iconType={IconTypes.DELETE} />}
          theme={ButtonThemes.DANGER}
          onClick={(event) => {
            event.preventDefault();
            selectOptions.removeField(index);
          }}
        />
      </div>
    ))}
  </div>
);

export default withNamespaces(['contentType', 'common'], { wait: false })(SelectOptions);

SelectOptions.propTypes = {
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape({
        value: PropTypes.string,
      }),
      value: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
};
