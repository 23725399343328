import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import Field from '../Field';

class FieldList extends Component {
  deleteField = (field) => {
    const { fields, onReorder } = this.props;
    const {
      originalIndex,
      order: { value },
    } = field;

    if (value !== fields.length - 1) {
      onReorder(value, fields.length - 1);
    }
    fields.removeField(originalIndex);
  };

  handleReorder = (event, fromIndex, toIndex) => {
    event.preventDefault();

    const { onReorder } = this.props;
    onReorder(fromIndex, toIndex);
  };

  render() {
    const { fields, availableTypes, onReorder } = this.props;
    const fieldsToDisplay = fields
      .map((field, index) => ({
        ...field,
        originalIndex: index,
      }))
      .sort((a, b) => a.order.value - b.order.value);

    return (
      <div>
        {fieldsToDisplay.map((field) => {
          const {
            originalIndex,
            order: { value },
          } = field;
          return (
            <Field
              key={originalIndex}
              field={field}
              onDelete={() => this.deleteField(field)}
              onReorder={onReorder}
              onMoveUpClick={(event) => this.handleReorder(event, value, value - 1)}
              onMoveDownClick={(event) => this.handleReorder(event, value, value + 1)}
              availableTypes={availableTypes}
            />
          );
        })}
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(FieldList);

FieldList.propTypes = {
  fields: PropTypes.array,
  availableTypes: PropTypes.array,
  onReorder: PropTypes.func,
};

FieldList.defaultProps = {
  fields: [],
  availableTypes: [],
  onReorder: () => {},
};
