import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';

import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Heading from 'assets/components/presentational/Heading';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import CircleButton, { CircleButtonThemes } from 'assets/components/presentational/CircleButton';
import Dialog from 'assets/components/presentational/Dialog';

import style from './stage.scss';

import Group from './Group';

class Stage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showDeleteDialog: false,
    };
  }

  handleUserChange = (group, user, email) => {
    const { onUserChange } = this.props;

    if (onUserChange) {
      onUserChange(group, user, email);
    }
  };

  handleModerationRuleChange = (group, requireAll) => {
    const { onModerationRuleChange } = this.props;

    if (onModerationRuleChange) {
      onModerationRuleChange(group, requireAll);
    }
  };

  handleModerationNumberChange = (group, requireN) => {
    const { onModerationNumberChange } = this.props;

    if (onModerationNumberChange) {
      onModerationNumberChange(group, requireN);
    }
  };

  handleNewGroupClick = () => {
    const { stage } = this.props;

    const { onGroupAdded } = this.props;

    if (onGroupAdded) {
      onGroupAdded(stage);
    }
  };

  handleDeleteStage = () => {
    const { onDeleteStage, stage } = this.props;

    onDeleteStage(stage);
    this.hideDeleteStageDialog();
  };

  showDeleteStageDialog = () => {
    this.setState({
      showDeleteDialog: true,
    });
  };

  hideDeleteStageDialog = () => {
    this.setState({
      showDeleteDialog: false,
    });
  };

  getStageName = () => {
    const { position } = this.props;

    return `Stage ${position + 1}`;
  };

  renderDialog() {
    const { t } = this.props;
    const { showDeleteDialog } = this.state;

    const actions = [
      { label: t('Cancel'), onClick: this.hideDeleteStageDialog },
      { label: t('Yes'), onClick: () => this.handleDeleteStage() },
    ];

    return (
      <Dialog
        actions={actions}
        active={showDeleteDialog}
        onEscKeyDown={this.hideDeleteStageDialog}
        onOverlayClick={this.hideDeleteStageDialog}
        type="small"
      >
        <p>{t('Delete Stage prompt', { stage: this.getStageName() })}</p>
      </Dialog>
    );
  }

  renderGroups() {
    const { stage, onDeleteGroup, onGroupNameChange, validate, validationData } = this.props;

    return stage.get('moderation-groups', List()).map((group, index) => {
      const groupValidationData =
        validationData.get('moderation-groups', new List()).find((row) => row.get('id') === group.get('id')) ||
        new Map();

      return (
        <Group
          group={group}
          stage={stage}
          isLastOfStage={index === stage.get('moderation-groups', List).size - 1}
          key={group.get('id')}
          onUserChange={(user, email) => this.handleUserChange(group, user, email)}
          onDeleteGroup={onDeleteGroup}
          onGroupNameChange={onGroupNameChange}
          onModerationRuleChange={(requireAll) => this.handleModerationRuleChange(group, requireAll)}
          onModerationNumberChange={(requireN) => this.handleModerationNumberChange(group, requireN)}
          validate={validate}
          validationData={groupValidationData}
        />
      );
    });
  }

  render() {
    const {
      t,
      onStageMove,
      position,
      isFirstStage,
      isLastStage,
      stage,
      onResendInvites,
      isDirty,
      isResendingInvites,
    } = this.props;

    const stageName = this.getStageName();

    return (
      <div className={style.component} name={`stage${position}`}>
        <Heading size="small" className={style.header}>
          <Icon iconType={IconTypes.VERIFIED_USER} />
          {stageName}
        </Heading>
        <CircleButton
          className={style.moveStageButton}
          onClick={() => onStageMove(position, position + 1)}
          disabled={isLastStage}
          icon={<Icon iconType={IconTypes.ARROW_DOWNWARD} />}
          theme={CircleButtonThemes.WHITE}
        />
        <CircleButton
          className={style.moveStageButton}
          onClick={() => onStageMove(position, position - 1)}
          disabled={isFirstStage}
          icon={<Icon iconType={IconTypes.ARROW_UPWARD} />}
          theme={CircleButtonThemes.WHITE}
        />
        <Button
          className={`${style.button} ${style.buttonDelete}`}
          icon={<Icon iconType={IconTypes.CLEAR} />}
          label={t('Delete stage', { stage: stageName })}
          onClick={() => this.showDeleteStageDialog()}
          theme={ButtonThemes.WARNING}
        />
        <Button
          className={style.button}
          icon={<Icon iconType={IconTypes.GROUP_ADD} />}
          label={t('Add new Group')}
          onClick={this.handleNewGroupClick}
          theme="white"
        />
        {!stage.get('isNew', false) ? (
          <Button
            className={style.button}
            icon={<Icon iconType={IconTypes.EMAIL} />}
            label={t('Resend invites')}
            onClick={() => onResendInvites(stage)}
            theme="white"
            disabled={isDirty || isResendingInvites}
          />
        ) : null}

        <div className={style.groups}>{this.renderGroups()}</div>

        {this.renderDialog()}
      </div>
    );
  }
}

Stage.propTypes = {
  stage: PropTypes.instanceOf(Map),
  position: PropTypes.number,
  onGroupAdded: PropTypes.func,
  onUserChange: PropTypes.func,
  onDeleteStage: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onStageMove: PropTypes.func,
  onGroupNameChange: PropTypes.func,
  onModerationRuleChange: PropTypes.func,
  onModerationNumberChange: PropTypes.func,
  onResendInvites: PropTypes.func,
  validate: PropTypes.func,
  isFirstStage: PropTypes.bool,
  isLastStage: PropTypes.bool,
  t: PropTypes.func,
  isDirty: PropTypes.bool,
  isResendingInvites: PropTypes.bool,
  validationData: PropTypes.instanceOf(Map),
};

export default withNamespaces(['common', 'enhancedModeration'], { wait: false })(Stage);
