import { handleActions } from 'redux-actions';
import { List, Map } from 'immutable';

const getInitialState = () => ({
  isFetching: false,
  builds: List(),
  isDeleting: new Map(),
});

const buildReducer = handleActions(
  {
    CAMPAIGN_BUILDS_FETCH_BUILDS_REQUEST: (state) => ({
      ...state,
      isFetching: true,
    }),
    CAMPAIGN_BUILDS_FETCH_BUILDS_SUCCESS: (state, action) => ({
      ...state,
      builds: action.builds,
      isFetching: false,
    }),
    CAMPAIGN_BUILDS_FETCH_BUILDS_FAILURE: (state) => ({
      ...state,
      isFetching: false,
    }),
    CAMPAIGN_BUILDS_MERGE_BUILD: (state, action) => ({
      ...state,
      builds: state.builds.map((build) => (build.get('id') === action.build.get('id') ? action.build : build)),
    }),
    CAMPAIGN_BUILDS_CREATE_BUILDS_SUCCESS: (state, action) => ({
      ...state,
      builds: state.builds.push(action.build),
    }),
    CAMPAIGN_BUILDS_DELETE_BUILDS_REQUEST: (state, action) => ({
      ...state,
      isDeleting: state.isDeleting.set(action.buildId, true),
    }),
    CAMPAIGN_BUILDS_DELETE_BUILDS_SUCCESS: (state, action) => ({
      ...state,
      builds: state.builds.filter((build) => build.get('id') !== action.buildId),
      isDeleting: state.isDeleting.set(action.buildId, false),
    }),
    CAMPAIGN_BUILDS_DELETE_BUILDS_FAILURE: (state, action) => ({
      ...state,
      isDeleting: state.isDeleting.set(action.buildId, false),
    }),
  },
  getInitialState(),
);

export default buildReducer;
