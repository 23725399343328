import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';
import cx from 'classnames';
import Dialog from 'react-toolbox/lib/dialog';

import { getRuleIcon } from 'modules/Helpers';

import LazyRender from 'components/common/LazyRender';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import CreativeDetail, { placeholder } from './CreativeDetail';

import style from './popup.scss';

class SchedulePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rulesOpen: false,
    };
  }

  handleOnRulesClick = () => {
    this.setState(({ rulesOpen }) => ({
      rulesOpen: !rulesOpen,
    }));
  };

  renderHeader() {
    const { slot } = this.props;

    return (
      <div className={style.header} style={{ backgroundColor: slot.get('color') }}>
        <span
          className={style.arrow}
          style={{ borderColor: `transparent transparent ${slot.get('color')} transparent` }}
        />
        <Heading size={HeadingSizes.SMALLEST} tag={HeadingTags.H5}>
          <span className={style.icons}>
            {slot.get('id') === 'default' && <Icon iconType={IconTypes.EMAIL} />}
            {slot.get('rules') &&
              slot.get('rules').map((rule, key) => <Icon key={key} iconType={getRuleIcon(rule.get('type'))} />)}
          </span>
          <span className={style.copy}>{slot.get('name')}</span>
        </Heading>
      </div>
    );
  }

  renderCreatives() {
    const { t, slot } = this.props;

    const creatives = slot.get('creatives');

    if (creatives.size === 0) {
      return <div className={style.noCreatives}>{t('No creatives assigned')}</div>;
    }

    return <div className={style.creatives}>{creatives.map((creative) => this.renderCreative(creative))}</div>;
  }

  renderCreative(creative) {
    const { campaignId } = this.props;

    return (
      <div className={style.creative} key={creative.get('id')}>
        <LazyRender placeholder={placeholder} wait={500}>
          <CreativeDetail creative={creative} campaignId={campaignId} />
        </LazyRender>
      </div>
    );
  }

  renderRules() {
    const { t, slot } = this.props;
    const { rulesOpen } = this.state;

    const rules = slot.get('rules');

    if (!rules || rules.size === 0) {
      return null;
    }

    const classes = cx(style.rules, {
      [style.open]: rulesOpen,
      [style.close]: !rulesOpen,
    });

    return (
      <div className={classes}>
        <a onClick={this.handleOnRulesClick}>
          <span>{t('Rules')}</span>
          <Icon iconType={IconTypes.ARROW_DROP_DOWN} />
        </a>
        <ul>{rules.map((rule, key) => this.renderRule(rule, key))}</ul>
      </div>
    );
  }

  renderRule(rule, key) {
    return <li key={key}>{rule.get('description')}</li>;
  }

  render() {
    const { active, handleClosePopup, handleOpenPopup } = this.props;
    handleOpenPopup();

    return (
      <Dialog
        className={style.popup}
        active={active}
        onOverlayClick={() => {
          handleClosePopup();
        }}
      >
        {this.renderHeader()}
        {this.renderCreatives()}
        {this.renderRules()}
      </Dialog>
    );
  }
}

export default withNamespaces(['common', 'contentSchedule'], { wait: false })(SchedulePopup);

SchedulePopup.propTypes = {
  t: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  slot: PropTypes.instanceOf(Map).isRequired,
  handleOpenPopup: PropTypes.func,
  handleClosePopup: PropTypes.func,
  active: PropTypes.bool.isRequired,
};

SchedulePopup.defaultProps = {
  handleOpenPopup: () => {},
  handleClosePopup: () => {},
};
