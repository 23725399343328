import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'assets/components/presentational/Drawer';
import Heading, { HeadingSizes } from 'assets/components/presentational/Heading';
import { connect } from 'react-redux';
import { fetchCampaign, hideCampaignSettingsForm, updateCampaignRequest } from 'actions/campaign';
import { withNamespaces } from 'react-i18next';
import { List } from 'immutable';
import { fetchBusinessUnits } from 'actions/auth';
import moment from 'moment';
import { isEqual } from 'lodash';
import { userHasCampaignPermission } from 'store/user/helpers';
import ProgressBar from 'components/patterns/ProgressBar';

import Form from '../../CampaignForm/form';
import validate from '../../CampaignForm/validate';

import style from './settings.scss';

class Settings extends Component {
  updateBusinessUnits(businessUnits) {
    const { user, dispatch } = this.props;
    if (!businessUnits || !businessUnits.size) {
      dispatch(fetchBusinessUnits(user.get('id')));
    }
  }

  checkCanManageRoles() {
    const { user, campaign } = this.props;
    return userHasCampaignPermission(user, campaign.get('id'), 'manage_roles');
  }

  handleSubmit(values) {
    // Exclude bookings field from request since settings form
    // does not include bookings, but redux-form still include the object.
    delete values.Bookings;

    const { dispatch, campaign, t } = this.props;
    const errors = validate(values, t);
    const initial = this.prepareInitialFormValues();
    if (Object.keys(errors).length > 0) {
      return Promise.reject(errors);
    }
    // Check if anything has changed
    if (isEqual(values, initial)) {
      return dispatch(hideCampaignSettingsForm());
    }
    // Else update
    return dispatch(updateCampaignRequest(values, campaign.get('id'))).then(() => {
      dispatch(fetchCampaign(campaign.get('id')));
      dispatch(hideCampaignSettingsForm());
    });
  }

  renderProgress() {
    const { isFetching } = this.props;

    if (!isFetching) {
      return null;
    }

    return (
      <div className={style.progress}>
        <ProgressBar />
      </div>
    );
  }

  renderHeading() {
    const { isFetching, t } = this.props;

    if (isFetching) {
      return null;
    }

    return <Heading size={HeadingSizes.LARGE}>{t('Campaign Settings')}</Heading>;
  }

  renderSettings() {
    const {
      dispatch,
      isFetching,
      isShowing,
      businessUnits,
      isDialogActive,
      dialogMessage,
      campaign,
      user,
      t,
    } = this.props;

    if (isShowing && !isFetching) {
      return (
        <Form
          t={t}
          dispatch={dispatch}
          onSubmit={(values) => this.handleSubmit(values)}
          businessUnits={businessUnits}
          isDialogActive={isDialogActive}
          updateBusinessUnits={(businessUnits) => this.updateBusinessUnits(businessUnits)}
          onCancelClick={() => dispatch(hideCampaignSettingsForm())}
          dialogMessage={dialogMessage}
          initialValues={this.prepareInitialFormValues()}
          successLabel={t('Update Settings')}
          includeManagers={this.checkCanManageRoles()}
          campaign={campaign}
          user={user}
        />
      );
    }

    return null;
  }

  render() {
    const { dispatch, isShowing } = this.props;

    return (
      <Drawer active={isShowing} onOverlayClick={() => dispatch(hideCampaignSettingsForm())}>
        {this.renderProgress()}
        {this.renderHeading()}
        {this.renderSettings()}
      </Drawer>
    );
  }

  prepareInitialFormValues() {
    const { campaign } = this.props;
    // So I have the utc date strings
    // Parsing the utc date strings gives me a (correct) local date
    // I have to pass a date to the shitty DatePicker, which couldn't care less about timezones if it tried
    // I want to display the utc date

    // So I can either change the datepicker do a timezone offset
    // or just add the offset whenever I'm putting the value in
    // and subtract it when I'm taking it out...
    // Which is what I've done
    let startDate = moment(campaign.get('starts-at'));
    startDate = startDate.add(startDate.toDate().getTimezoneOffset(), 'minutes').toDate();

    let endDate = moment(campaign.get('ends-at'));
    endDate = endDate.add(endDate.toDate().getTimezoneOffset(), 'minutes').toDate();

    const [businessUnit] = campaign
      .get('business-units')
      .map((item) => item.get('id'))
      .toJS();

    return {
      Brand: campaign.get('client').get('name'),
      Name: campaign.get('name'),
      BusinessUnit: businessUnit,
      StartDate: startDate,
      EndDate: endDate,
      UpdateFrequency: campaign.get('build-frequency'),
      Bookings: [],
      IsActive: campaign.get('is-active'),
      Managers: campaign
        .get('campaign-roles')
        .filter((item) => item.get('role') === 'manager')
        .map((item) => item.get('email'))
        .toJS(),
      RequiresWeatherData: campaign.get('requires-weather') || false,
    };
  }
}

Settings.propTypes = {
  campaign: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  businessUnits: PropTypes.object,
  isDialogActive: PropTypes.bool,
  dialogMessage: PropTypes.string,
  isFetching: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  isShowing: false,
  businessUnits: new List([]),
};

const mapStateToProps = (state) => ({
  campaign: state.campaign.campaign,
  user: state.auth.user,
  isShowing: state.campaign.isShowingCampaignSettingsForm,
  businessUnits: state.auth.user.get('businessUnits'),
  isDialogActive: state.campaign.isShowingCampaignFormDialog,
  dialogMessage: state.campaign.campaignFormDialogMessage,
  isFetching: state.campaign.isFetching,
});

const Translated = withNamespaces(['common', 'campaignForm'])(Settings);
export default connect(mapStateToProps)(Translated);
