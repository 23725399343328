import React from 'react';
import PropTypes from 'prop-types';
import style from './PublishStatus.scss';

const PublishStatus = ({ status }) => <p className={style.timelineStatus}>{status}</p>;

PublishStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PublishStatus;
