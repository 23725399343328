import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as BaseLink } from 'react-router';
import { getLinkWithoutPrecedingSlash } from '../../../../modules/Helpers';

class Link extends Component {
  render() {
    const { className, children, onClick, to, target } = this.props;

    return (
      <BaseLink className={className} onClick={onClick} to={`/${getLinkWithoutPrecedingSlash(to)}`} target={target}>
        {children}
      </BaseLink>
    );
  }
}

export default Link;

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  target: PropTypes.string,
};

Link.defaultProps = {
  className: '',
  to: '',
};
