import auth from './auth.json';
import bookings from './bookings.json';
import campaign from './campaign.json';
import campaignForm from './campaignForm.json';
import campaignInvites from './campaignInvites.json';
import campaignPlaylists from './campaignPlaylists.json';
import common from './common.json';
import content from './content.json';
import contentSchedule from './contentSchedule.json';
import contentType from './contentType.json';
import creative from './creative.json';
import dashboard from './dashboard.json';
import enhancedModeration from './enhancedModeration.json';
import errorCodes from './errorCodes.json';
import frames from './frames.json';
import header from './header.json';
import liteModeration from './liteModeration.json';
import moderation from './moderation.json';
import navigation from './navigation.json';
import notifications from './notifications.json';
import preview from './preview.json';
import rules from './rules.json';
import upload from './upload.json';
import users from './users.json';
import validation from './validation.json';
import snoozeCampaign from './snoozeCampaign.json';
import dashboardNew from './dashboardNew.json';

export default {
  auth,
  bookings,
  campaign,
  campaignForm,
  campaignInvites,
  campaignPlaylists,
  common,
  content,
  contentSchedule,
  contentType,
  creative,
  dashboard,
  enhancedModeration,
  errorCodes,
  frames,
  header,
  liteModeration,
  moderation,
  navigation,
  notifications,
  preview,
  rules,
  upload,
  users,
  validation,
  snoozeCampaign,
  dashboardNew,
};
