import { List } from 'immutable';
import * as constants from 'modules/API/constants';
import notify, { NotificationTypes } from './snackbar';

export const info = (notifications = List([])) => notify(NotificationTypes.INFO, notifications);

export const success = (notifications = List([])) => notify(NotificationTypes.SUCCESS, notifications);

export const error = (notifications = List([])) => notify(NotificationTypes.ERROR, notifications);

export const warning = (notifications = List([])) => notify(NotificationTypes.WARNING, notifications);

export const notifyApiError = (err) => (dispatch) => {
  if (typeof err !== 'object' || !err.response) {
    dispatch(error('A server error occurred.'));
    throw err;
  }

  // We don't want to display notifications for the following API codes as these are linked
  // to 401 status codes and the user will be redirected to the login page
  const ignoredNotificationCodes = [
    constants.API_EXCEPTION_AUTHTWOFACTOREXPIRED,
    constants.API_EXCEPTION_AUTHINVALIDKEYCREDENTIALS,
    constants.API_EXCEPTION_AUTHNOMETHOD,
    constants.API_EXCEPTION_AUTHJWTEXPIRED,
    constants.API_EXCEPTION_AUTHJWTINVALID,
    constants.API_EXCEPTION_AUTHJWTERROR,
    constants.API_EXCEPTION_AUTHTWOFACTORNOTSETUP,
    constants.API_EXCEPTION_AUTHTWOFACTORMISMATCH,
  ];

  if (err.response.code && ignoredNotificationCodes.includes(err.response.code)) {
    return;
  }

  dispatch(error(err.response.errors));
};
