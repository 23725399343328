import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import styles from './Warnings.scss';

const Warnings = ({ warnings, icon, className }) => {
  if (!warnings || !warnings.length) {
    return null;
  }

  return (
    <div className={`${styles.component} ${className}`}>
      {warnings.map((warning, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.warning} key={typeof warning === 'string' ? warning : i}>
          <span className={styles.icon}>{icon}</span>
          <span className={styles.text}>{warning}</span>
        </div>
      ))}
    </div>
  );
};

Warnings.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.element,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};
Warnings.defaultProps = {
  warnings: [],
  icon: null,
  className: '',
};

export default withNamespaces(['common'], { wait: false })(Warnings);
