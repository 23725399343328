import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import style from './creativeReorderActions.scss';

class CreativeReorderActions extends Component {
  render() {
    const { t, isUpdating, isActive, onDiscard, onSave } = this.props;

    if (!isActive) {
      return null;
    }

    return (
      <div className={style.reorderButtons}>
        <Button
          className={style.reorderButton}
          label={t('Discard changes')}
          type="button"
          disabled={isUpdating}
          onClick={onDiscard}
          theme={ButtonThemes.WHITE}
        />
        <Button
          className={style.reorderButton}
          label={t('Save new order')}
          type="submit"
          disabled={isUpdating}
          onClick={onSave}
        />
      </div>
    );
  }
}

export default withNamespaces(['common', 'creative'], { wait: false })(CreativeReorderActions);

CreativeReorderActions.propTypes = {
  t: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

CreativeReorderActions.defaultProps = {};
