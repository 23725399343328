import React from 'react';
import PropTypes from 'prop-types';

import style from './Menu.scss';

const Menu = ({ children }) => {
  return <div className={style.component}>{children}</div>;
};

Menu.propTypes = {
  children: PropTypes.node,
};

Menu.defaultProps = {
  children: null,
};

export default Menu;
