import { fromJS } from 'immutable';
import Request from 'modules/API/request';

import { success, error as notifyError, notifyApiError } from 'actions/notify';
import { lite as authLite } from 'actions/auth';
import { fetchCreativeForPreview as fetchCreativeForPreviewBase } from 'actions/creative';
import { fetchUserPermissions } from 'actions/user';
import { broadcasting } from '../modules/Broadcasting/broadcasting';

export const FETCH_CAMPAIGN_INVITE_REQUEST = 'drop-zone/FETCH_CAMPAIGN_INVITE_REQUEST';
export const FETCH_CAMPAIGN_INVITE_SUCCESS = 'drop-zone/FETCH_CAMPAIGN_INVITE_SUCCESS';
export const FETCH_CAMPAIGN_INVITE_COMPLETE = 'drop-zone/FETCH_CAMPAIGN_INVITE_COMPLETE';

export const UPLOAD_CREATIVE_SUCCESS = 'drop-zone/UPLOAD_CREATIVE_SUCCESS';

export const MERGE_CREATIVE_INTO_CAMPAIGN_INVITE = 'drop-zone/MERGE_CREATIVE_INTO_CAMPAIGN_INVITE';
export const REPLACE_CREATIVE = 'drop-zone/REPLACE_CREATIVE';

export const DELETE_CREATIVE_REQUEST = 'drop-zone/DELETE_CREATIVE_REQUEST';
export const DELETE_CREATIVE_SUCCESS = 'drop-zone/DELETE_CREATIVE_SUCCESS';
export const DELETE_CREATIVE_COMPLETE = 'drop-zone/DELETE_CREATIVE_COMPLETE';

export const FETCH_CREATIVE_REQUEST = 'drop-zone/FETCH_CREATIVE_REQUEST';
export const FETCH_CREATIVE_SUCCESS = 'drop-zone/FETCH_CREATIVE_SUCCESS';
export const FETCH_CREATIVE_COMPLETE = 'drop-zone/FETCH_CREATIVE_COMPLETE';

export const SET_ACTIVE_FRAME_SPEC = 'drop-zone/SET_ACTIVE_FRAME_SPEC';

export const FETCH_FRAME_SPECIFICATIONS = 'drop-zone/FETCH_FRAME_SPECIFICATIONS';

const JWT_LOCAL_STORAGE_KEY = 'jwt';

export const authenticateUser = (jwt) => async (dispatch) => {
  try {
    const response = await dispatch(authLite(jwt));
    const campaignInviteId = response.data.body.parsed.key;
    localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwt);
    broadcasting().setJwt(jwt);
    return campaignInviteId;
  } catch (e) {
    throw e;
  }
};

export const fetchCampaign = (campaignInviteId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CAMPAIGN_INVITE_REQUEST });

    const response = await Request.send({
      endpoint: `campaign-invites/${campaignInviteId}`,
      includes: ['campaign', 'campaign.client'],
    });
    const campaignInvite = fromJS(response.data.body.parsed);

    dispatch({ type: FETCH_CAMPAIGN_INVITE_SUCCESS, campaignInvite });
    dispatch({ type: FETCH_CAMPAIGN_INVITE_COMPLETE });
    return campaignInvite;
  } catch (error) {
    dispatch(notifyError('Unable to fetch campaign'));
    dispatch({ type: FETCH_CAMPAIGN_INVITE_COMPLETE });
    return null;
  }
};

export const fetchFrameSpecifications = (campaignInviteId) => async (dispatch) => {
  try {
    const response = await Request.send({
      endpoint: `campaign-invites/${campaignInviteId}`,
      includes: ['campaign.frame-specifications.creatives'],
    });

    const frameSpecifications = fromJS(response.data.body.parsed.campaign['frame-specifications']);

    dispatch({ type: FETCH_FRAME_SPECIFICATIONS, frameSpecifications });
  } catch (error) {
    dispatch(notifyError(error.response.data.errors));
  }
};

export const fetchUserCampaignPermissions = (userId, campaignId) => (dispatch) =>
  dispatch(fetchUserPermissions(userId, 'App\\Campaign', campaignId)).then((response) => Promise.resolve(response));

export const deleteCreative = (creative) => (dispatch) => {
  dispatch({ type: DELETE_CREATIVE_REQUEST, creative });

  const options = {
    endpoint: `creatives/${creative.get('id')}`,
    method: 'DELETE',
  };

  return Request.send(options)
    .then((response) => {
      dispatch(success('The item was deleted successfully'));
      dispatch({ type: DELETE_CREATIVE_SUCCESS, creative });
      dispatch({ type: DELETE_CREATIVE_COMPLETE, creative });

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: DELETE_CREATIVE_COMPLETE, creative });

      return Promise.reject(error);
    });
};

export const fetchCreativeForPreview = (creativeId) => (dispatch) => {
  dispatch({ type: FETCH_CREATIVE_REQUEST, creativeId });

  return dispatch(
    fetchCreativeForPreviewBase(
      creativeId,
      ['prepared-asset', 'frame-specification'],
      ['preview-frame', 'preview-content-items', 'preview-rule-sets'],
    ),
  )
    .then((creative) => {
      dispatch({ type: FETCH_CREATIVE_SUCCESS, creative });
      dispatch({ type: FETCH_CREATIVE_COMPLETE, creativeId });

      return Promise.resolve(fromJS(creative));
    })
    .catch(() => {
      dispatch({ type: FETCH_CREATIVE_COMPLETE, creativeId });
    });
};

export const fetchCreative = (creativeId, extraOptions = {}) => (dispatch) => {
  const options = {
    endpoint: `creatives/${creativeId}`,
  };

  Object.assign(options, extraOptions);

  return Request.send(options).then((response) => {
    dispatch(mergeCreativeIntoCampaignInvite(fromJS(response.data.body.parsed)));

    return Promise.resolve(response);
  });
};

export const uploadCreativeSuccess = (creative) => ({
  type: UPLOAD_CREATIVE_SUCCESS,
  creative,
});

export const mergeCreativeIntoCampaignInvite = (creative) => ({
  type: MERGE_CREATIVE_INTO_CAMPAIGN_INVITE,
  creative,
});

export const setActiveFrameSpec = (activeFrameSpecId) => ({
  type: SET_ACTIVE_FRAME_SPEC,
  activeFrameSpecId,
});

export const addCreative = (creativeId = {}) => (dispatch) => {
  const options = {
    endpoint: `creatives/${creativeId}`,
    includes: ['asset', 'user', 'prepared-asset', 'thumbnail'],
  };

  return Request.send(options)
    .then((response) => {
      dispatch(uploadCreativeSuccess(fromJS(response.data.body.parsed)));

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const replaceCreative = (frameSpecKey, creativeId = {}, oldCreative) => (dispatch) => {
  const options = {
    endpoint: `creatives/${creativeId}`,
    includes: ['asset', 'user', 'prepared-asset', 'thumbnail'],
  };

  return Request.send(options)
    .then((response) => {
      const newCreative = fromJS(response.data.body.parsed);
      dispatch(replaceOldWithNewCreative(frameSpecKey, newCreative, oldCreative));

      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const replaceOldWithNewCreative = (frameSpecKey, newCreative, oldCreative) => ({
  type: REPLACE_CREATIVE,
  frameSpecKey,
  newCreative,
  oldCreative,
});
