import React from 'react';
import PropTypes from 'prop-types';
import Button from 'assets/components/presentational/Button';

const PublishButton = ({ isPublishable, campaignId, publish }) =>
  isPublishable && <Button label="PUBLISH" onClick={() => publish(campaignId)} />;

PublishButton.propTypes = {
  isPublishable: PropTypes.bool.isRequired,
  campaignId: PropTypes.string,
  publish: PropTypes.func.isRequired,
};

PublishButton.defaultProps = {
  campaignId: '',
};

export default PublishButton;
