import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { ListItem } from 'react-toolbox/lib/list';
import Checkbox from 'assets/components/presentational/Checkbox';
import Tooltip from 'components/patterns/Tooltip';
import { IconButton } from 'react-toolbox/lib/button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './row.scss';

const TooltipIconButton = Tooltip(IconButton);

class PermissionsBusinessUnitsRow extends Component {
  handleCheckboxChange = (rowId, optionId, checked) => {
    const { onCheckboxChange } = this.props;

    onCheckboxChange(rowId, optionId, checked);
  };

  handleRevokePermissionsClick = () => {
    const { rowId, onRevokePermissionsClick } = this.props;

    onRevokePermissionsClick(rowId);
  };

  renderContent() {
    const { t, rowHeading, headings, rowId, options = [], isRevokingPermissions } = this.props;

    if (headings) {
      return (
        <span className={style.rowContent}>
          <span className={style.itemName} />
          {headings.map((item) => (
            <span key={item}>
              <p>{item}</p>
            </span>
          ))}
        </span>
      );
    }

    return (
      <span className={style.rowContent}>
        <span className={style.itemName}>{rowHeading}</span>
        {options.toJS().map((option) => (
          <span key={option.id}>
            <Checkbox
              checked={option.checked}
              disabled={option.disabled}
              onChange={(checked) => this.handleCheckboxChange(rowId, option, checked)}
            />
          </span>
        ))}
        <span>
          <TooltipIconButton
            tooltip={t('Revoke all permissions for campaigns in {{bu}}', { bu: rowHeading })}
            onClick={this.handleRevokePermissionsClick}
            icon={<Icon iconType={IconTypes.REMOVE_CIRCLE} />}
            disabled={isRevokingPermissions}
          />
        </span>
      </span>
    );
  }

  render() {
    return <ListItem ripple={false} className={style.row} itemContent={this.renderContent()} />;
  }
}

export default PermissionsBusinessUnitsRow;

PermissionsBusinessUnitsRow.propTypes = {
  t: PropTypes.func.isRequired,
  headings: PropTypes.array,
  rowHeading: PropTypes.string,
  rowId: PropTypes.string,
  options: PropTypes.instanceOf(List),
  onCheckboxChange: PropTypes.func,
  onRevokePermissionsClick: PropTypes.func.isRequired,
  isRevokingPermissions: PropTypes.bool.isRequired,
};
