import { connect } from 'react-redux';

import { getBusinessUnitIds } from 'selectors/campaign';
import BookingSearch from './BookingsSearch';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  businessUnitIds: getBusinessUnitIds(state),
});

export default connect(mapStateToProps)(BookingSearch);
