/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/patterns/Tooltip';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import { TV } from 'assets/components/presentational/CustomIcon';

import style from './computedPlaylistItem.scss';

const TooltipSpan = Tooltip('span');

export const ComputedPlaylistArrow = () => (
  <span className={style.arrow}>
    <Icon iconType={IconTypes.PLAY_ARROW} />
  </span>
);

export const ComputedPlaylistItem = ({ name, type, color, position, last }) => {
  const icons = {
    default: IconTypes.PHOTO,
    image: IconTypes.PHOTO,
    html5: IconTypes.CODE,
    video: IconTypes.VIDEOCAM,
  };

  const totalDigits = (position + 1).toString().length;

  return (
    <span className={style.item}>
      <TooltipSpan className={style.creative} style={{ color }} tooltip={name}>
        <TV fill={color} preserveAspectRatio="none" height={42} width={32 + totalDigits * 10} />
        <div className={style.playoutItemWrapper}>
          <div className={style.playoutItem}>
            <span className={style.position}>{position + 1}</span>
            <Icon iconType={icons[type] || icons.default} className={style.icon} />
          </div>
        </div>
      </TooltipSpan>
      {!last && <ComputedPlaylistArrow />}
    </span>
  );
};

ComputedPlaylistItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  last: PropTypes.bool,
};

ComputedPlaylistItem.defaultProps = {
  last: false,
};
