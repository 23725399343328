import moment from 'moment';

function isValid(dateOrStringOrUnixTimestamp) {
  return moment(dateOrStringOrUnixTimestamp).isValid();
}

/**
 * i.e. June 7th 2018
 */
function toMonthDateYear(d) {
  return isValid(d) ? moment.utc(d).format('MMMM Do YYYY') : '';
}

function convertDateFormat(d, from, to) {
  return isValid(d) ? moment(d, from).format(to) : '';
}

/**
 * i.e. June 7th 2018 12:45:59
 */
function toMonthDateYearTime(d) {
  return isValid(d) ? moment.utc(d).format('MMMM Do YYYY HH:mm:ss') : '';
}

export { isValid, toMonthDateYear, toMonthDateYearTime, convertDateFormat };
