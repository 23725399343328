import Request from 'modules/API/request';
import { fromJS } from 'immutable';
import { notifyApiError } from 'actions/notify';

export const FETCH_REQUEST = 'frames/screenshots/FETCH_REQUEST';
export const FETCH_SUCCESS = 'frames/screenshots/FETCH_SUCCESS';
export const FETCH_COMPLETE = 'frames/screenshots/FETCH_COMPLETE';

export const CLEAR_SCREENSHOTS = 'frames/screenshots/CLEAR_SCREENSHOTS';

export const fetchScreenshots = (frameId) => (dispatch) => {
  const options = {
    endpoint: `frames/${frameId}/screenshots`,
    method: 'GET',
  };

  dispatch({ type: FETCH_REQUEST });

  return Request.send(options)
    .then((response) => {
      const screenshots = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_SUCCESS, screenshots });
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_COMPLETE });
      return Promise.resolve();
    });
};

export const clearScreenshots = () => ({
  type: CLEAR_SCREENSHOTS,
});
