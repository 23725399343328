import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { fromJS, List, Map } from 'immutable';
import classNames from 'classnames/bind';

import {
  CreativeTypes,
  CreativeModerationStatuses,
  getModelRelationsForCheckboxPicker,
  getModerationIcon,
  isCreativeProcessed,
} from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import Tooltip from 'components/patterns/Tooltip';
import Warnings from 'components/common/Warnings';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import CheckboxPicker from 'assets/components/presentational/CheckboxPicker';
import Switch from 'components/patterns/Switch';
import Icon, { IconTypes, IconColors, IconSizes } from 'assets/components/presentational/Icon';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import ModerationDialog from 'assets/components/presentational/Campaign/Creatives/ModerationDialog';

import style from './creativeSettings.scss';

const cx = classNames.bind(style);

const TooltipIcon = Tooltip(Icon);

class Settings extends Component {
  state = {
    status: null,
    rejectReason: '',
    validationError: false,
  };

  handleIsDefaultChange = (checked) => {
    const { onDefaultChange } = this.props;

    return onDefaultChange(checked);
  };

  handleCheckboxChange = (values, items, onChange) => {
    const ids = items
      .filter(
        (item) =>
          fromJS(values).findIndex((value) => value.get('value') === item.get('id') && value.get('checked')) > -1,
      )
      .map((i) => i.get('id'))
      .toJS();

    return onChange(ids);
  };

  handleRuleSetsChange = (checkboxes) => {
    const { ruleSets, onRuleSetsChange } = this.props;

    return this.handleCheckboxChange(checkboxes, ruleSets, onRuleSetsChange);
  };

  handleContentTypesChange = (values) => {
    const { contentTypes, onContentTypesChange } = this.props;

    return this.handleCheckboxChange(values, contentTypes, onContentTypesChange);
  };

  handleModerateClick = (status) => {
    const { onModerateClick } = this.props;
    const { rejectReason } = this.state;
    const notes = status === CreativeModerationStatuses.REJECTED ? rejectReason : '';

    if (status === CreativeModerationStatuses.REJECTED && rejectReason.trim() === '') {
      return this.setState({
        validationError: true,
      });
    }

    this.setState({
      status: null,
      rejectReason: '',
      validationError: false,
    });

    return onModerateClick(status, notes);
  };

  handleToggleModerationDialog = (status) => {
    this.setState({
      status,
      rejectReason: '',
      validationError: false,
    });
  };

  handleRejectReasonChange = (val) => {
    this.setState({
      rejectReason: val,
      validationError: false,
    });
  };

  renderRules() {
    const { creative, ruleSets, saving, onRuleSetsChange, showApplyRules, t } = this.props;

    if (!showApplyRules) {
      return false;
    }

    if (onRuleSetsChange === null) {
      return null;
    }

    if (!ruleSets.size) {
      return null;
    }

    const items = getModelRelationsForCheckboxPicker(creative, ruleSets, ['rule-sets']);

    return (
      <div className={style.groupContainer}>
        <CheckboxPicker
          key={JSON.stringify(items)}
          className={style.checkBoxPicker}
          disabled={saving}
          title={t('Apply Rules')}
          value={items}
          onChange={this.handleRuleSetsChange}
        />
      </div>
    );
  }

  renderContentTypes() {
    const { contentTypes, creative, saving, showApplyContent, t } = this.props;

    if (!showApplyContent) {
      return null;
    }

    if (!contentTypes.size) {
      return null;
    }

    if (creative.get('type') === CreativeTypes.HTML5) {
      const items = getModelRelationsForCheckboxPicker(creative, contentTypes, ['content-types']);

      return (
        <div className={style.groupContainer}>
          <CheckboxPicker
            key={JSON.stringify(items)}
            className={style.checkBoxPicker}
            disabled={saving}
            title={t('Apply Content')}
            value={items}
            onChange={this.handleContentTypesChange}
          />
        </div>
      );
    }
  }

  renderDefaultSwitch() {
    const { creative, saving, onDefaultChange, t, shouldRenderDefaultToggle } = this.props;

    let label = '';
    if (!creative.get('is-default-editable', false)) {
      label = 'There must be at least one default creative';
    } else {
      label = creative.get('is-default', false) ? 'Active' : 'Inactive';
    }

    if (onDefaultChange === null || !shouldRenderDefaultToggle) {
      return null;
    }

    return (
      <div className={style.groupContainer}>
        <Heading className={style.groupHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H3}>
          {t('Fallback Status')}
        </Heading>
        <Switch
          label={t(label)}
          onChange={this.handleIsDefaultChange}
          checked={creative.get('is-default', false)}
          disabled={
            !creative.get('is-default-editable', false) ||
            saving ||
            (!isCreativeProcessed(creative) && !creative.get('is-default', false))
          }
        />
      </div>
    );
  }

  renderModerationStatus() {
    const { creative, allowModeration, t } = this.props;

    let iconClassName = '';
    let tooltip = '';

    switch (creative.get('moderation-status')) {
      case CreativeModerationStatuses.APPROVED:
        iconClassName = IconColors.GREEN;
        tooltip = t('Approved');
        break;
      case CreativeModerationStatuses.REJECTED:
        iconClassName = IconColors.RED;
        tooltip = t('Rejected');
        break;
      default:
        iconClassName = IconColors.ORANGE;
        tooltip = t('This creative is currently pending moderation');
    }

    if (!allowModeration) {
      return (
        <div className={style.moderationStatusContainer}>
          <Heading className={style.groupHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H3}>
            {t('Moderation')} {t('Status')}
          </Heading>
          <span>
            <TooltipIcon
              iconType={getModerationIcon(creative.get('moderation-status'))}
              color={iconClassName}
              size={IconSizes.MEDIUM}
              tooltip={tooltip}
            />
          </span>
        </div>
      );
    }
  }

  renderModeration() {
    const { t, allowModeration } = this.props;

    if (allowModeration) {
      return (
        <div className={style.groupContainer}>
          <Heading className={style.groupHeading} size={HeadingSizes.SMALL} tag={HeadingTags.H3}>
            {t('Moderation')}
          </Heading>
          {this.renderModerationButton(
            style.buttonLeft,
            'done',
            t('Approve'),
            CreativeModerationStatuses.APPROVED,
            ButtonThemes.GREEN,
          )}
          {this.renderModerationButton(
            style.buttonRight,
            'clear',
            t('Reject'),
            CreativeModerationStatuses.REJECTED,
            ButtonThemes.DANGER,
          )}
        </div>
      );
    }
  }

  renderModerationButton(className, icon, label, status, theme) {
    const { saving, creative } = this.props;

    return (
      <Button
        className={className}
        disabled={saving || !isCreativeProcessed(creative)}
        icon={<Icon iconType={icon} />}
        label={label}
        onClick={() => this.handleToggleModerationDialog(status)}
        theme={theme}
      />
    );
  }

  renderProgress() {
    const { fetching, saving } = this.props;

    if (fetching || saving) {
      return (
        <div className={style.progressContainer}>
          <ProgressBar circular />
        </div>
      );
    }
  }

  renderSoundNotice = () => (
    <Warnings
      warnings={[
        <Trans i18nKey="The creative could have sound, make sure to listen for audio">
          The creative <strong>could</strong> have sound, make sure to listen for audio.
        </Trans>,
      ]}
      icon={<Icon iconType={IconTypes.VOLUME_UP} />}
    />
  );

  renderCreativeWarnings() {
    const { creative } = this.props;
    const warnings = creative.get('warnings');

    return (
      <Warnings
        warnings={warnings.toJS().map((warning) => warning.message)}
        icon={<Icon iconType={IconTypes.WARNING} color={IconColors.RED} />}
      />
    );
  }

  renderWarnings() {
    const { frameSpecification, creative } = this.props;

    const warnings = creative.get('warnings', List());
    const isVideoOrHtml5 = creative.get('type') === CreativeTypes.VIDEO || creative.get('type') === CreativeTypes.HTML5;

    const shouldRenderCreativeWarnings = warnings.size > 0;
    const shouldRenderSoundNotice = frameSpecification.get('has-sound') && isVideoOrHtml5;

    if (!shouldRenderCreativeWarnings && !shouldRenderSoundNotice) {
      return null;
    }

    return (
      <div className={cx(style.groupContainer, style.warnings)}>
        {shouldRenderCreativeWarnings && this.renderCreativeWarnings()}
        {!!shouldRenderSoundNotice && this.renderSoundNotice()}
      </div>
    );
  }

  renderContent() {
    const { fetching, saving, creative } = this.props;
    const { rejectReason, status, validationError } = this.state;
    const { handleRejectReasonChange } = this;
    const { handleToggleModerationDialog } = this;
    const { handleModerateClick } = this;

    if (!fetching) {
      return (
        <div>
          {this.renderWarnings()}
          {this.renderModeration()}
          {this.renderModerationStatus()}
          {this.renderDefaultSwitch()}
          {this.renderRules()}
          {this.renderContentTypes()}
          <ModerationDialog
            creative={creative}
            handleModerateClick={handleModerateClick}
            handleRejectReasonChange={handleRejectReasonChange}
            handleToggleModerationDialog={handleToggleModerationDialog}
            rejectReason={rejectReason}
            saving={saving}
            status={status}
            validationError={validationError}
          />
        </div>
      );
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <Heading className={style.heading} size={HeadingSizes.SMALL} tag={HeadingTags.H3}>
          <Icon iconType={IconTypes.SETTINGS} /> Settings
        </Heading>
        {this.renderContent()}
        {this.renderProgress()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'creative', 'errorCodes'], { wait: false })(Settings);

Settings.propTypes = {
  className: PropTypes.string,
  contentTypes: PropTypes.instanceOf(List).isRequired,
  frameSpecification: PropTypes.instanceOf(Map).isRequired,
  creative: PropTypes.instanceOf(Map).isRequired,
  fetching: PropTypes.bool,
  onDefaultChange: PropTypes.func,
  onRuleSetsChange: PropTypes.func,
  onContentTypesChange: PropTypes.func.isRequired,
  onModerateClick: PropTypes.func.isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  saving: PropTypes.bool,
  t: PropTypes.func,
  allowModeration: PropTypes.bool,
  shouldRenderDefaultToggle: PropTypes.bool,
  showApplyRules: PropTypes.bool,
  showApplyContent: PropTypes.bool,
};

Settings.defaultProps = {
  onRuleSetsChange: null,
  onDefaultChange: null,
  showApplyRules: false,
  showApplyContent: false,
};
