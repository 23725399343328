import React from 'react';
import PropTypes from 'prop-types';
import style from './screenshot.scss';

const Screenshot = ({ url, date }) => (
  <div className={style.screenshot}>
    <img src={url} />
    <p>{date}</p>
  </div>
);

export default Screenshot;

Screenshot.propTypes = {
  url: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
