import { handleActions } from 'redux-actions';
import { fromJS, List, Set, Map } from 'immutable';

const getInitialState = () => ({
  isFetching: false,
  isSendingInvites: false,
  inviteErrors: List(),
  invites: List(),
  resendingIds: Set(),
  revokingIds: Set(),
  pagination: new Map(),
});

const resendInviteSuccess = (state, action) => {
  const actionInvite = fromJS(action.invite);

  return {
    ...state,
    invites: state.invites.map((i) => {
      if (i.get('id') === actionInvite.get('id')) {
        return i.merge(actionInvite);
      }
      return i;
    }),
  };
};

const invites = handleActions(
  {
    CAMPAIGN_INVITES_FETCH_INVITES_REQUEST: (state) => ({
      ...state,
      isFetching: true,
    }),
    CAMPAIGN_INVITES_PAGINATION_MERGE: (state, action) => ({
      ...state,
      pagination: state.pagination.merge(fromJS(action.pagination)),
    }),
    CAMPAIGN_INVITES_FETCH_INVITES_SUCCESS: (state, action) => ({
      ...state,
      invites: state.invites.clear().merge(fromJS(action.invites)),
    }),
    CAMPAIGN_INVITES_FETCH_INVITES_COMPLETE: (state) => ({
      ...state,
      isFetching: false,
    }),
    CAMPAIGN_INVITES_SEND_INVITES_REQUEST: (state) => ({
      ...state,
      isSendingInvites: true,
      inviteErrors: state.inviteErrors.clear(),
    }),
    CAMPAIGN_INVITES_SEND_INVITES_FAILURE: (state, action) => ({
      ...state,
      inviteErrors: state.inviteErrors.merge(fromJS(action.errors)),
    }),
    CAMPAIGN_INVITES_SEND_INVITES_COMPLETE: (state) => ({
      ...state,
      isSendingInvites: false,
    }),
    CAMPAIGN_INVITES_RESEND_INVITE_REQUEST: (state, action) => ({
      ...state,
      resendingIds: state.resendingIds.add(action.inviteId),
    }),
    CAMPAIGN_INVITES_RESEND_INVITE_SUCCESS: (state, action) => resendInviteSuccess(state, action),
    CAMPAIGN_INVITES_RESEND_INVITE_COMPLETE: (state, action) => ({
      ...state,
      resendingIds: state.resendingIds.remove(action.inviteId),
    }),
    CAMPAIGN_INVITES_REVOKE_INVITE_REQUEST: (state, action) => ({
      ...state,
      revokingIds: state.revokingIds.add(action.inviteId),
    }),
    CAMPAIGN_INVITES_REVOKE_INVITE_COMPLETE: (state, action) => ({
      ...state,
      revokingIds: state.revokingIds.remove(action.inviteId),
    }),
    CAMPAIGN_INVITES_CLEAR_STATE: () => getInitialState(),
  },
  getInitialState(),
);

export default invites;
