import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'react-toolbox/lib/button';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import style from './accordion.scss';

const Accordion = ({ children, className, collapsed, disabled, label, onClick }) => (
  <div data-accordion data-accordion-collapsed={collapsed} data-accordion-disabled={disabled} className={className}>
    <Heading className={style.heading} size={HeadingSizes.SMALL} tag={HeadingTags.H2}>
      <span data-accordion-title onClick={onClick}>
        {label}
      </span>

      <span data-accordion-toggle>
        <IconButton
          onClick={onClick}
          icon={<Icon iconType={collapsed ? IconTypes.REMOVE : IconTypes.ADD} color={IconColors.LIGHTBLUE} />}
        />
      </span>
    </Heading>

    <div data-accordion-content>{children}</div>
  </div>
);

export default Accordion;

Accordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
};

Accordion.defaultProps = {
  className: '',
  children: null,
  collapsed: false,
  disabled: false,
};
