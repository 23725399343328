import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import style from './alertBar.scss';

const cx = classNames.bind(style);

export const AlertBarColors = {
  RED: 'red',
  ORANGE: 'orange',
  PRIMARY_BLUE: 'primaryBlue',
};

const AlertBar = ({ color, text, link, icon }) => (
  <div className={cx(style.component, color)}>
    <Icon iconType={icon} />
    &nbsp;
    {link ? (
      <a className={style.link} href={link}>
        {text}
      </a>
    ) : (
      text
    )}
  </div>
);

export default AlertBar;

AlertBar.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
};

AlertBar.defaultProps = {
  color: '',
  text: '',
  link: '',
  icon: IconTypes.ERROR_OUTLINE,
};
