import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'react-toolbox/lib/button';

import { ButtonThemes, getClassFromTheme } from '../Button';

import style from './circleButton.scss';

export const CircleButtonThemes = ButtonThemes;

const CircleButton = ({ className, icon, theme, ...props }) => {
  const classNames = `
    ${getClassFromTheme(style, theme)}
    ${className}
  `;

  return <IconButton className={classNames} icon={icon} {...props} />;
};

export default CircleButton;

CircleButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
  theme: PropTypes.string,
  children: PropTypes.node,
};

CircleButton.defaultProps = {
  className: '',
  icon: null,
  theme: '',
  children: null,
};
