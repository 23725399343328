import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames/bind';

import PresenceIndicator from 'assets/components/containers/Campaign/PresenceIndicator';
import Navigation from 'assets/components/presentational/Campaign/Navigation';
import { CampaignAlert } from 'assets/components/presentational/Campaign/CampaignAlert';
import WeatherAlert from 'assets/components/presentational/Campaign/WeatherAlert';

import Settings from './Settings';
import styles from './Campaign.scss';
import { BROADCAST_EVENTS, privateCampaignChannel, broadcasting } from '../../../../modules/Broadcasting/broadcasting';

const cx = classNames.bind(styles);

class Campaign extends Component {
  broadcastingChannel = null;

  componentDidMount = () => {
    const {
      fetchCampaign,
      bookingPromotionCompleted,
      bookingPromotionFailed,
      bookingDcmCompleted,
      bookingDcmFailed,
      setCampaignPublishStatus,
      params,
    } = this.props;

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const campaignId = (params && params.campaignId) || '';
    this.fetchCampaignData(campaignId);

    this.broadcastingChannel = broadcasting()
      .setJwt(localStorage.getItem('jwt'))
      .subscribe(privateCampaignChannel(campaignId));

    this.broadcastingChannel
      .bind(BROADCAST_EVENTS.BOOKING_PROMOTION_COMPLETED, (payload) => {
        bookingPromotionCompleted(payload);
        fetchCampaign(campaignId);
      })
      .bind(BROADCAST_EVENTS.BOOKING_PROMOTION_FAILED, (payload) => {
        bookingPromotionFailed(payload);
      })
      .bind(BROADCAST_EVENTS.BOOKING_DCM_COMPLETED, (payload) => {
        bookingDcmCompleted(payload);
        fetchCampaign(campaignId);
      })
      .bind(BROADCAST_EVENTS.BOOKING_DCM_FAILED, (payload) => {
        bookingDcmFailed(payload);
      })
      .bind(BROADCAST_EVENTS.PUBLISH_STATUS_UPDATED, (payload) => {
        setCampaignPublishStatus(payload);
      });
  };

  componentDidUpdate(prevProps) {
    const { params } = this.props;
    const { params: prevParams } = prevProps;
    const prevCampaignId = (prevProps.params && prevParams.campaignId) || '';
    const campaignId = (params && params.campaignId) || '';
    const isUrlChanged = prevCampaignId !== campaignId;

    if (isUrlChanged) {
      this.clearCampaignData();
      this.fetchCampaignData(params.campaignId);
    }

    this.addToRecentlyViewedCampaigns();
  }

  componentWillUnmount() {
    this.clearCampaignData();
  }

  async fetchCampaignData(campaignId) {
    const {
      setActiveCampaignId,
      fetchCampaign,
      fetchFrameSpecifications,
      location: { pathname },
    } = this.props;
    setActiveCampaignId(campaignId);

    const includes = ['content-types'];
    if (!pathname.includes('rules')) {
      includes.push('rule-sets');
    }

    await fetchCampaign(campaignId, { includes });
    const campaignPage = `/campaigns/${campaignId}`;
    const creativePage = `/campaigns/${campaignId}/creative`;
    if (pathname !== campaignPage && pathname !== creativePage) await fetchFrameSpecifications(campaignId);
  }

  addToRecentlyViewedCampaigns() {
    const { updateMyRecentCampaignsStore, campaign, user } = this.props;

    if (campaign.get('id')) {
      updateMyRecentCampaignsStore(user.get('id'), campaign.get('id'), campaign.get('name'));
    }
  }

  unsubscribeFromBroadcasting() {
    if (!this.broadcastingChannel) {
      return;
    }

    this.broadcastingChannel.unsubscribe();
  }

  clearCampaignData() {
    const { setActiveCampaignId, clearCampaign } = this.props;
    setActiveCampaignId();
    clearCampaign();

    this.unsubscribeFromBroadcasting();
  }

  render() {
    const {
      activeSection,
      campaign,
      children,
      contentCompleting,
      isInitialFetch,
      isFetchingCampaign,
      params: { campaignId },
      promoting,
      user,
      location: { pathname },
    } = this.props;

    const showWeatherAlert =
      ['rules'].filter((page) => pathname.includes(page)).length > 0 ||
      ['creative', `campaigns/${campaignId}`].filter((page) => pathname.endsWith(page)).length > 0;

    return (
      <div>
        <div className={cx('campaign')}>
          <Navigation
            activeSection={activeSection}
            campaign={campaign}
            isFetchingCampaign={isFetchingCampaign}
            isInitialFetch={isInitialFetch}
            user={user}
            contentCompleting={contentCompleting}
            promoting={promoting}
            showLinks
          />
          <div className={cx('campaign__content')}>
            <CampaignAlert />
            {showWeatherAlert && <WeatherAlert />}
            <div className={cx('campaign__main-content')}>{campaignId ? children : null}</div>
          </div>
        </div>
        <Settings />
        <div className={cx('campaign__presence-indicator')}>
          <PresenceIndicator campaignId={campaignId} myID={user.get('id')} />
        </div>
      </div>
    );
  }
}

Campaign.propTypes = {
  fetchCampaign: PropTypes.func.isRequired,
  bookingPromotionCompleted: PropTypes.func.isRequired,
  bookingPromotionFailed: PropTypes.func.isRequired,
  bookingDcmCompleted: PropTypes.func.isRequired,
  bookingDcmFailed: PropTypes.func.isRequired,
  setCampaignPublishStatus: PropTypes.func.isRequired,
  setActiveCampaignId: PropTypes.func.isRequired,
  fetchFrameSpecifications: PropTypes.func.isRequired,
  updateMyRecentCampaignsStore: PropTypes.func.isRequired,
  clearCampaign: PropTypes.func.isRequired,
  activeSection: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  isInitialFetch: PropTypes.bool.isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  contentCompleting: PropTypes.bool.isRequired,
  promoting: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces(['common', 'campaign'], { wait: false })(Campaign);
