import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chip as BaseChip } from 'react-toolbox/lib/chip';
import style from './chip.scss';

class Chip extends Component {
  render = () => {
    const { active, children, className, deletable, onDeleteClick, theme } = this.props;
    const componentClassName = active ? style.componentActive : style.component;

    return (
      <BaseChip
        className={componentClassName.concat(' ', className)}
        deletable={deletable}
        onDeleteClick={onDeleteClick}
        theme={theme}
      >
        {children}
      </BaseChip>
    );
  };
}

export default Chip;

Chip.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  deletable: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  theme: PropTypes.shape({
    avatar: PropTypes.string,
    chip: PropTypes.string,
    deletable: PropTypes.string,
    delete: PropTypes.string,
    deleteIcon: PropTypes.string,
    deleteX: PropTypes.string,
  }),
};

Chip.defaultProps = {
  active: true,
  className: '',
};
