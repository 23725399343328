import React from 'react';
import PropTypes from 'prop-types';

import style from './ContainerFixed.scss';

const ContainerFixed = ({ className, children }) => {
  return <div className={`${style.component} ${className}`}>{children}</div>;
};

ContainerFixed.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

ContainerFixed.defaultProps = {
  className: '',
  children: null,
};

export default ContainerFixed;
