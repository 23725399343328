import { DISE } from 'app-constants';
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { PublicationPropTypes } from 'store/publications/propTypes';

import EmptyState from 'components/common/EmptyState';
import ProgressBar from 'components/patterns/ProgressBar';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';

import PublishChange from './PublishChange';
import PublishedTimelineItem from './PublishedTimelineItem';
import { IconVariants } from './TimelineItem/PublishIcon';
import PublishButton from './TimelineItem/PublishButton';
import style from './Publish.scss';

class Publish extends React.Component {
  componentDidMount() {
    const {
      setActiveSection,
      fetchPublications,
      fetchCampaign,
      params: { campaignId },
    } = this.props;

    setActiveSection('publishing');

    if (campaignId) {
      fetchCampaign(campaignId);
      fetchPublications(campaignId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      publishStatus,
      fetchCampaign,
      params: { campaignId },
    } = this.props;

    if (prevProps.publishStatus !== publishStatus) {
      fetchCampaign(campaignId);
    }
  }

  renderTimeline = () => {
    const {
      publishStatus,
      isPublishable,
      publications,
      params: { campaignId },
      t,
    } = this.props;

    if (publishStatus === DISE.UNPUBLISHED) {
      return (
        <div>
          <EmptyState text={t('This campaign is currently unpublished')} />
          <PublishButton isPublishable={isPublishable} campaignId={campaignId} />
        </div>
      );
    }

    return (
      <ul className={style.timeline}>
        {isPublishable && (
          <PublishChange
            variant={IconVariants.DRAFT}
            status={t('Unpublished changes')}
            isPublishable={isPublishable}
            campaignId={campaignId}
          />
        )}
        {publications.map((publication, index) => (
          <PublishedTimelineItem
            variant={index === 0 && !isPublishable ? IconVariants.PUBLISHED : IconVariants.STEP}
            status={t('Published')}
            publication={publication}
            key={publication.id}
          />
        ))}
      </ul>
    );
  };

  render() {
    const { isFetchingPublications, t } = this.props;

    return (
      <div>
        <HeadingGroup title={t('Publishing')} intro={t('Publish campaign changes and see previous publish dates')} />
        {isFetchingPublications ? <ProgressBar /> : this.renderTimeline()}
      </div>
    );
  }
}

Publish.propTypes = {
  params: PropTypes.shape({
    campaignId: PropTypes.string,
  }).isRequired,
  publications: PropTypes.arrayOf(PublicationPropTypes).isRequired,
  publishStatus: PropTypes.oneOf(Object.values(DISE)).isRequired,
  isPublishable: PropTypes.bool.isRequired,
  isFetchingPublications: PropTypes.bool.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  fetchPublications: PropTypes.func.isRequired,
  fetchCampaign: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces(['common', 'publish'], { wait: false })(Publish);
