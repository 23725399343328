import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List, fromJS } from 'immutable';
import { connect } from 'react-redux';
import moment from 'moment';
import { ruleType } from 'modules/Helpers/rule';
import { getRuleSets, groupCreativesByRuleSet } from 'selectors/campaign';
import ProgressBar from 'components/patterns/ProgressBar';
import Schedule from 'assets/components/presentational/Campaign/ContentSchedule/Schedule';

const RULESET_DEFAULT_ID = 'default';

class ScheduleContainer extends Component {
  constructor(props) {
    super(props);

    this.colors = ['#345c6f', '#e89628', '#2eaa47', '#ff6747', '#3e7fc7', '#50b2e3', '#a95db7', '#93d6cc'];

    const calendarMonthsWithDuplicates = this.getDatesInRange(
      moment.utc(props.campaign.get('starts-at')).format('DD MMM YYYY'),
      moment.utc(props.campaign.get('ends-at')).format('DD MMM YYYY'),
      'days',
      'MMMM YYYY',
    );

    // Remove duplicates maintaining month order
    const calendarMonths = calendarMonthsWithDuplicates.reduce((carry, item) => {
      if (carry.last() === item) {
        return carry;
      }
      return carry.push(item);
    }, List());

    // set the active month to the current one if it's within the campaign,
    //  otherwise show the beginning of the campaign
    const monthNow = moment().format('MMMM YYYY');
    const startActiveMonth = calendarMonths.findIndex((item) => item === monthNow);
    this.state = {
      activeMonth: startActiveMonth === -1 ? 0 : startActiveMonth,
      activePopup: null,
      calendarMonths,
    };
  }

  generateCalendar() {
    const { frameSpec, ruleSets, campaign } = this.props;
    const { calendarMonths, activeMonth } = this.state;

    const ruleSetsForFrameSpec = this.assignColors(this.ruleSetsForFrameSpec(frameSpec, ruleSets));

    const days = this.calendarDays(
      campaign.get('starts-at'),
      campaign.get('ends-at'),
      calendarMonths,
      activeMonth,
    ).map((day) => day.set('slots', fromJS(Array(ruleSetsForFrameSpec.size + 1).fill(false))));

    const calendar = this.populateSlots(days, ruleSetsForFrameSpec);

    return this.padCalendar(calendar);
  }

  /**
   * Used to add the rules in the correct slot position for each calendar day.
   * @param {array} calendar - the array of days in the calendar.
   * @param {array} ruleSetsForFrameSpec - rules for each frame.
   */
  populateSlots(calendar, ruleSetsForFrameSpec) {
    // Treating these too separately helps with the consistent allocation of slots
    // First, allocate slots to ruleSets that don't have a day of week rule
    let calendarDest = this.populateSlotsWithRuleSets(
      calendar,
      this.getRuleSetsWithoutDayOfWeek(ruleSetsForFrameSpec),
      false,
    );
    // Second, allocate slots to those with a day of week rule
    calendarDest = this.populateSlotsWithRuleSets(
      calendarDest,
      this.getRuleSetsWithDayOfWeek(ruleSetsForFrameSpec),
      true,
    );

    return calendarDest;
  }

  populateSlotsWithRuleSets(calendar, ruleSets, isDayOfWeek) {
    const { campaign, creativesByRuleSet } = this.props;
    let tempRulesets = ruleSets;

    // Sort date range ruleSets by start date, using unix in this case
    if (!isDayOfWeek) {
      tempRulesets = tempRulesets.sortBy((rs) => rs.get('unix')).unshift(this.getDefaultRuleSet());
    }

    tempRulesets.forEach((ruleSet) => {
      const ruleSetMeta = this.getRuleSetMeta(campaign, calendar, ruleSet);
      const creatives = creativesByRuleSet
        .get(creativesByRuleSet.findIndex((crs) => crs.get('id') === ruleSet.get('id')))
        .get('creatives', List());
      let applicableDates = List();
      const isDateRange = !!ruleSet.get('dateRange');
      const hasActiveCreatives = creatives.size > 0;

      if (!ruleSetMeta.get('isVisibleInActiveMonth') || !hasActiveCreatives) {
        return;
      }

      if (isDateRange) {
        ruleSet.get('dateRange').forEach((dateRange) => {
          const startDate = moment(dateRange.get('from'), 'YYYY MM DD');
          const endDate = moment(dateRange.get('to'), 'YYYY MM DD');
          while (startDate.isSameOrBefore(endDate)) {
            const date = startDate.clone();
            applicableDates = applicableDates.push(date.format('DD MMM YYYY'));
            startDate.add(1, 'd');
          }
        });
      }

      const firstVisibleIndex = ruleSetMeta.get('firstVisibleIndex');

      let slotIndex = calendar.getIn([firstVisibleIndex, 'slots']).indexOf(false);
      let dayIndex = 0;
      let position = null;
      let prevSlotIndex = null;
      let isSingleSlot = false;

      while (dayIndex < ruleSetMeta.get('visibleLength')) {
        // For a little more consistency we try and keep the same slot across the different applicable days
        if (prevSlotIndex && calendar.getIn([firstVisibleIndex + dayIndex, 'slots', prevSlotIndex]) === false) {
          slotIndex = prevSlotIndex;
        } else {
          slotIndex = calendar.getIn([firstVisibleIndex + dayIndex, 'slots']).indexOf(false);
          prevSlotIndex = slotIndex;
        }

        // Used for styling and whether slot info should show
        // dayofweek: a dayOfWeek ruleSet slot
        // start: the first day of a date range ruleSet
        // middle: any day between first and last day of a date range ruleSet
        // end: the last day of a date range ruleSet
        if (isDayOfWeek) {
          position = 'dayofweek';
        } else if (isDateRange) {
          // the dateRange attribute at this point is Null for non-date related rules, so all this fails
          // We will be showing only the default creatives and rules that are ONLY date rules
          // Trying to show non-date based rulesets would fail as a date is tried to be read and mapped in here
          const startDates = ruleSet
            .get('dateRange', List())
            .map((dR) => moment(dR.get('from'), 'YYYY MM DD').format('DD MMM YYYY'));
          const endDates = ruleSet
            .get('dateRange', List())
            .map((dR) => moment(dR.get('to'), 'YYYY MM DD').format('DD MMM YYYY'));
          const startIndexFound = startDates.indexOf(calendar.getIn([firstVisibleIndex + dayIndex, 'date']));
          if (startIndexFound >= 0 || (dayIndex === 0 && ruleSetMeta.get('ruleSetStartsInActiveMonth'))) {
            position = 'start';
            // any 'start' day could also be a single slot rule
            if (ruleSet.get('id') !== RULESET_DEFAULT_ID) {
              isSingleSlot = startDates.get(startIndexFound) === endDates.get(startIndexFound);
            }
          } else if (
            endDates.includes(calendar.getIn([firstVisibleIndex + dayIndex, 'date'])) ||
            (dayIndex === ruleSetMeta.get('visibleLength') - 1 && ruleSetMeta.get('ruleSetEndsInActiveMonth'))
          ) {
            position = 'end';
          } else {
            position = 'middle';
          }
        }

        ruleSet = ruleSet.set('position', position);
        ruleSet = ruleSet.set('isSingle', isSingleSlot);
        ruleSet = ruleSet.set('hasActiveCreatives', hasActiveCreatives);
        ruleSet = ruleSet.set('isConditional', this.isConditional(ruleSet));
        ruleSet = ruleSet.set('creatives', creatives);

        calendar = calendar.updateIn([firstVisibleIndex + dayIndex, 'slots', slotIndex], () => ruleSet); // eslint-disable-line no-loop-func

        dayIndex++;
      }
    });

    return calendar;
  }

  getRuleSetMeta(campaign, calendar, ruleSet) {
    const monthStart = moment(calendar.getIn([0, 'date']), 'DD MMM YYYY');
    const monthEnd = moment(calendar.getIn([calendar.size - 1, 'date']), 'DD MMM YYYY');
    const campaignStartsInActiveMonth = moment
      .utc(campaign.get('starts-at'))
      .isBetween(monthStart, monthEnd, null, '[]');
    const campaignEndsInActiveMonth = moment.utc(campaign.get('ends-at')).isBetween(monthStart, monthEnd, null, '[]');
    const ruleSetStart = moment(ruleSet.get('start'), 'DD MMM YYYY');
    const ruleSetEnd = moment(ruleSet.get('end'), 'DD MMM YYYY');
    const ruleSetStartsInActiveMonth = moment(ruleSetStart, 'DD MMM YYYY').isBetween(monthStart, monthEnd, null, '[]');
    const ruleSetEndsInActiveMonth = moment(ruleSetEnd, 'DD MMM YYYY').isBetween(monthStart, monthEnd, null, '[]');
    const ruleSetStartsBeforeActiveMonth = moment(ruleSetStart, 'DD MMM YYYY').isBefore(monthStart);
    const ruleSetEndAfterActiveMonth = moment(ruleSetEnd, 'DD MMM YYYY').isAfter(monthEnd);

    let first = monthStart;
    let last = monthEnd;

    if (campaignStartsInActiveMonth && !ruleSetStartsBeforeActiveMonth) {
      first = campaign.get('starts-at');
    }

    if (campaignEndsInActiveMonth) {
      last = campaign.get('ends-at');
    }

    const meta = {
      isVisibleInActiveMonth: false,
      visibleLength: calendar.size,
      firstVisibleIndex: 0,
      ruleSetStartsInActiveMonth,
      ruleSetEndsInActiveMonth,
    };

    if (ruleSetStartsInActiveMonth && ruleSet.get('id') !== RULESET_DEFAULT_ID) {
      const ruleSetStartDayNum = calendar
        .filter((day) => moment(day.get('date'), 'DD MMM YYYY').isSame(ruleSetStart, 'day'))
        .first()
        .get('dayNum');
      meta.firstVisibleIndex = ruleSetStartDayNum - 1;
    } else {
      meta.firstVisibleIndex = 0;
    }

    if (ruleSetStartsInActiveMonth || ruleSetEndsInActiveMonth) {
      meta.isVisibleInActiveMonth = true;
    } else if (ruleSetStartsBeforeActiveMonth && ruleSetEndAfterActiveMonth) {
      meta.isVisibleInActiveMonth = true;
    }

    if (ruleSet.get('id') !== RULESET_DEFAULT_ID) {
      if (ruleSetStartsInActiveMonth && ruleSetEndsInActiveMonth) {
        // The ruleSet starts and ends in the active month
        meta.visibleLength = this.getDatesInRange(ruleSetStart, ruleSetEnd, 'day').size;
      } else if (ruleSetStartsInActiveMonth && !ruleSetEndsInActiveMonth) {
        // The ruleSet starts in the active month
        meta.visibleLength = this.getDatesInRange(ruleSetStart, last, 'day').size;
      } else if (!ruleSetStartsInActiveMonth && ruleSetEndsInActiveMonth) {
        // The ruleSet ends in the active month
        meta.visibleLength = this.getDatesInRange(first, ruleSetEnd, 'day').size;
      }
    }

    return fromJS(meta);
  }

  calendarDays(startDate, endDate, calendarMonths, activeMonth) {
    const currentMonth = calendarMonths.get(activeMonth);

    return this.getDatesInRange(
      moment(currentMonth, 'MMMM YYYY')
        .startOf('month')
        .format('DD MMM YYYY'),
      moment(currentMonth, 'MMMM YYYY')
        .endOf('month')
        .format('DD MMM YYYY'),
      'days',
      'DD MMM YYYY',
    ).reduce((acc, date) => {
      const mDate = moment.utc(date, 'DD MMM YYYY');

      return acc.push(
        fromJS({
          position: this.getPosition(startDate, endDate, mDate),
          isoWeekday: mDate.isoWeekday(),
          dayNum: parseInt(mDate.format('D')),
          dow: mDate.format('dddd'),
          date,
        }),
      );
    }, List());
  }

  getRuleSetsWithoutDayOfWeek(ruleSets) {
    return this.assignDateRanges(
      ruleSets.filter(
        (ruleSet) => !ruleSet.get('rules', List()).some((rule) => rule.get('type') === ruleType('dayOfWeek')),
      ),
    );
  }

  getRuleSetsWithDayOfWeek(ruleSets) {
    return this.assignDateRanges(
      ruleSets.filter((ruleSet) =>
        ruleSet.get('rules', List()).some((rule) => rule.get('type') === ruleType('dayOfWeek')),
      ),
    );
  }

  assignDateRanges(ruleSets) {
    const { campaign } = this.props;

    return ruleSets.map((ruleSet) => {
      const datesRule = ruleSet.get('rules', List()).filter((rule) => rule.get('type') === ruleType('dates'));

      if (datesRule.size > 0) {
        // If has date rule use it
        datesRule.getIn([0, 'data']).forEach((dateRange) => {
          ruleSet = ruleSet.set('dateRange', ruleSet.get('dateRange', List()).push(dateRange));
        });
      } else {
        ruleSet = ruleSet.set('dateRange', null);
      }
      ruleSet = ruleSet
        .set('start', moment.utc(campaign.get('starts-at')).format('DD MMM YYYY'))
        .set('end', moment.utc(campaign.get('ends-at')).format('DD MMM YYYY'))
        .set('unix', moment.utc(campaign.get('starts-at'), 'YYYY-MM-DD').unix());
      return ruleSet;
    });
  }

  getDefaultRuleSet() {
    const { campaign, creativesByRuleSet } = this.props;

    const defaultRuleSet = creativesByRuleSet.get(
      creativesByRuleSet.findIndex((rs) => rs.get('id') === RULESET_DEFAULT_ID),
    );

    return fromJS({
      id: RULESET_DEFAULT_ID,
      name: 'Default',
      creatives: defaultRuleSet.get('creatives', List()),
      isConditional: false,
      start: moment.utc(campaign.get('starts-at')).format('DD MMM YYYY'),
      end: moment.utc(campaign.get('ends-at')).format('DD MMM YYYY'),
      unix: 0,
    });
  }

  padCalendar(days) {
    return fromJS(Array(days.first().get('isoWeekday') - 1).fill(fromJS({ date: null })))
      .concat(days)
      .concat(fromJS(Array(7 - days.last().get('isoWeekday')).fill(fromJS({ date: null }))));
  }

  getPosition(start, end, current, inclusive = '()') {
    if (current.isSame(start, 'day') && current.isSame(end, 'day')) return 'single';
    if (current.isSame(start, 'day')) return 'start';
    if (current.isSame(end, 'day')) return 'end';
    if (current.isBetween(start, end, null, inclusive)) return 'middle';
    return null;
  }

  getDatesInRange(startDate, endDate, unit, format = 'DD MMM YYYY') {
    const start = moment(startDate, 'DD MMM YYYY');
    const end = moment(endDate, 'DD MMM YYYY');
    const diff = end.diff(start, unit);
    let dates = List();

    if (!start.isValid() || !end.isValid()) return null;

    dates = dates.push(start.format(format));

    for (let i = 0; i < diff; i++) {
      dates = dates.push(start.add(1, unit).format(format));
    }

    return dates;
  }

  ruleSetsForFrameSpec(frameSpecification, ruleSets) {
    return ruleSets.filter(
      (rs) =>
        rs.get('creatives') &&
        !!rs.get('creatives').filter((c) => c.get('frame-specification-id') === frameSpecification.get('id')).size,
    );
  }

  dowDataToIsoWeekDay(dowData) {
    let newDowData = dowData;

    if (dowData.indexOf(0) !== -1) {
      newDowData = dowData.splice(dowData.indexOf(0), 1, 7);
    }

    return newDowData.sort();
  }

  assignColors = (ruleSets) =>
    ruleSets.map((ruleSet, key) => {
      // cycle through the available colours
      const index = key < this.colors.length ? key : key % this.colors.length;
      return ruleSet.set('backgroundColor', this.colors[index]);
    });

  // checks and returns if a ruleset is conditional, aka not only date dependant
  isConditional(ruleSet) {
    const rules = ruleSet.get('rules');

    if (!rules) return false;

    // if there's anything BUT only date related rules, then it's conditional
    const nonDateRules = rules.filter((rule) => {
      const currentRuleType = rule.get('type');
      return currentRuleType !== ruleType('dayOfWeek') && currentRuleType !== ruleType('dates');
    });

    return nonDateRules.size > 0;
  }

  handleNextClick = () => {
    this.setState(({ activeMonth, calendarMonths }) => {
      const newActiveMonth = activeMonth < calendarMonths.size - 1 ? activeMonth + 1 : calendarMonths.size - 1;

      return {
        activeMonth: newActiveMonth,
        calendar: this.generateCalendar(),
      };
    });
  };

  handlePrevClick = () => {
    this.setState(({ activeMonth }) => {
      const newActiveMonth = activeMonth > 0 ? activeMonth - 1 : 0;
      return {
        activeMonth: newActiveMonth,
        calendar: this.generateCalendar(),
      };
    });
  };

  handleSlotClick = (dayNum, slotNumber) => {
    this.setState(() => ({
      activePopup: fromJS({ dayNum, slotNumber }),
    }));
  };

  handleClosePopup = () => {
    this.setState(() => ({
      activePopup: null,
    }));
  };

  render() {
    const { isFetchingCampaign, campaign } = this.props;
    const { activeMonth, calendarMonths, activePopup } = this.state;

    if (isFetchingCampaign) {
      return <ProgressBar />;
    }

    const calendar = this.generateCalendar();

    return (
      <Schedule
        campaignId={campaign.get('id')}
        calendar={calendar}
        activeMonth={activeMonth}
        calendarMonths={calendarMonths}
        activePopup={activePopup}
        handleNextClick={this.handleNextClick}
        handlePrevClick={this.handlePrevClick}
        handleSlotClick={this.handleSlotClick}
        handleClosePopup={this.handleClosePopup}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetchingCampaign: state.campaign.isFetching,
  campaign: state.campaign.campaign,
  ruleSets: getRuleSets(state),
  creativesByRuleSet: groupCreativesByRuleSet(state, ownProps),
});

export default connect(mapStateToProps)(ScheduleContainer);

ScheduleContainer.propTypes = {
  isFetchingCampaign: PropTypes.bool.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  frameSpec: PropTypes.instanceOf(Map).isRequired,
  creativesByRuleSet: PropTypes.instanceOf(List).isRequired,
};
