import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

const snackbar = handleActions(
  {
    UPDATE_NOTIFICATIONS: (state, action) => ({
      ...state,
      notificationType: action.notificationType,
      notifications: state.notifications.clear().merge(fromJS(action.notifications)),
      timesOut: action.timesOut,
    }),
  },
  {
    notificationType: '',
    notifications: fromJS([]),
    timesOut: true,
  },
);

export default snackbar;
