import React from 'react';
import PropTypes from 'prop-types';
import style from './actions.scss';

const Actions = (props) => {
  const { children, className } = props;

  return <div className={`${className} ${style.actions}`}>{children}</div>;
};

export default Actions;

Actions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Actions.defaultProps = {
  className: '',
  children: null,
};
