import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';

import { fetchCampaign, fetchUserCampaignPermissions } from 'actions/drop-zone';

import Navigation from 'assets/components/presentational/Campaign/Navigation';
import { CampaignAlertDropZone } from 'assets/components/presentational/Campaign/CampaignAlert';

import style from './drop-zone.scss';

class DropZone extends Component {
  async componentDidMount() {
    const {
      user,
      dispatch,
      params: { campaignId },
    } = this.props;

    const campaignInvite = await dispatch(fetchCampaign(campaignId));
    dispatch(fetchUserCampaignPermissions(user.get('id'), campaignInvite.get('campaign-id')));
  }

  componentWillUnmount() {
    // Unset memoized links in case component is reused
    this.navLinks = null;
  }

  navigationLinks() {
    if (this.navLinks) {
      return this.navLinks;
    }

    const {
      params: { campaignId },
      t,
    } = this.props;

    this.navLinks = [
      {
        to: `/drop-zone/view/${campaignId}`,
        label: t('Creative'),
      },
    ];

    return this.navLinks;
  }

  renderNavigation() {
    const { activeSection, campaignInvite, user, isFetching } = this.props;

    return (
      <Navigation
        activeSection={activeSection}
        campaign={campaignInvite.get('campaign', new Map())}
        isFetchingCampaign={isFetching}
        isInitialFetch={false}
        user={user}
        links={this.navigationLinks()}
      />
    );
  }

  render() {
    const { children } = this.props;

    return (
      <div className={style.component}>
        {this.renderNavigation()}
        <div className={style.content}>
          <CampaignAlertDropZone />
          <div className={style.mainContent}>{children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSection: state.campaignNavigation.activeSection,
  campaignInvite: state.dropZone.campaignInvite,
  isFetching: state.dropZone.isFetching,
  user: state.auth.user,
});

DropZone.propTypes = {
  activeSection: PropTypes.string.isRequired,
  campaignInvite: PropTypes.instanceOf(Map).isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces(['common'], { wait: false })(connect(mapStateToProps)(DropZone));
