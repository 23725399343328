import { fetchCampaignRelations } from 'actions/campaign';
import { fetchCreativesByFrameSpecifications } from 'actions/creative';

export const FETCH_REQUEST = 'campaign/contentSchedule/FETCH_REQUEST';
export const FETCH_SUCCESS = 'campaign/contentSchedule/FETCH_SUCCESS';
export const FETCH_FAILURE = 'campaign/contentSchedule/FETCH_FAILURE';

export const SET_ACTIVE_FRAME_SPEC = 'campaign/contentSchedule/SET_ACTIVE_FRAME_SPEC';

export const fetchFrameSpecifications = (campaignId) => async (dispatch) => {
  const responseOfFrameSpecs = await dispatch(fetchCampaignRelations(campaignId, 'frame-specifications'));
  const frameSpecs = responseOfFrameSpecs.data.body.parsed;
  dispatch(fetchCreativesByFrameSpecifications(campaignId, frameSpecs, { includes: ['rule-sets'] }));
};

export const fetchRuleSets = (campaignId) => (dispatch) => {
  dispatch(
    fetchCampaignRelations(campaignId, 'rule-sets', {
      includes: ['creatives', 'frames', 'rules'],
    }),
  );
};

export const fetchContentScheduleData = (campaignId) => (dispatch) => {
  dispatch({ type: FETCH_REQUEST });

  return Promise.all([dispatch(fetchFrameSpecifications(campaignId)), dispatch(fetchRuleSets(campaignId))])
    .then(() => {
      dispatch({ type: FETCH_SUCCESS });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch({ type: FETCH_FAILURE, error });

      return Promise.reject(error);
    });
};

export const setActiveFrameSpec = (activeFrameSpecId) => ({
  type: SET_ACTIVE_FRAME_SPEC,
  activeFrameSpecId,
});
