import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as BaseButton } from 'react-toolbox/lib/button';
import ProgressBar from 'components/patterns/ProgressBar';

import style from './button.scss';

export const ButtonThemes = {
  PRIMARY: '',
  GREEN: 'green',
  DANGER: 'danger',
  WARNING: 'warning',
  GREY: 'grey',
  WHITE: 'white',
  TRANSPARENT: 'transparent',
  SECONDARY: 'secondary',
  SECONDARYCONFIRM: 'secondaryConfirm',
};

export const getClassFromTheme = (style, theme) => {
  let className = '';

  switch (theme) {
    case ButtonThemes.GREEN:
      className = style.componentGreen;
      break;
    case ButtonThemes.WARNING:
      className = style.componentWarning;
      break;
    case ButtonThemes.DANGER:
      className = style.componentDanger;
      break;
    case ButtonThemes.GREY:
      className = style.componentGrey;
      break;
    case ButtonThemes.WHITE:
      className = style.componentWhite;
      break;
    case ButtonThemes.TRANSPARENT:
      className = style.componentTransparent;
      break;
    default:
      className = style.componentDefault;
      break;
  }

  return className;
};

class Button extends Component {
  getClassName = () => {
    const { className: parentClassName, floating, theme } = this.props;

    const className = getClassFromTheme(style, theme);

    if (floating) {
      return parentClassName;
    }

    return className.concat(' ', parentClassName);
  };

  render = () => {
    const { disabled, floating, icon, isWorking, label, onClick, type, tabIndex, href } = this.props;

    if (isWorking) {
      return <ProgressBar circular />;
    }

    return (
      <BaseButton
        className={this.getClassName()}
        disabled={disabled}
        floating={floating}
        icon={icon}
        label={label}
        onClick={onClick}
        primary
        tabIndex={tabIndex}
        type={type}
        href={href}
      />
    );
  };
}

export default Button;

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  floating: PropTypes.bool,
  icon: PropTypes.element,
  isWorking: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  type: PropTypes.string,
  tabIndex: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  href: null,
  disabled: false,
  floating: false,
  icon: null,
  isWorking: false,
  label: '',
  onClick: null,
  theme: ButtonThemes.PRIMARY,
  type: 'button',
  tabIndex: '0',
};
