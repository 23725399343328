import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { get } from 'lodash';
import { List } from 'immutable';

import { escapeNewLineItems } from 'modules/Helpers/function';

import { attachCampaignFrames } from 'actions/campaign';
import { setAddFramesError, clearAddFramesError, setAddFramesInput } from 'actions/campaign/mediaBuy';
import { success, info, error } from 'actions/notify';

import Input from 'components/patterns/Input';
import Button from 'assets/components/presentational/Button';

import style from './addFramesWidget.scss';

class AddFramesWidget extends Component {
  handleOnClick = (e) => {
    const { campaignId, dispatch, t, fetchFrames, addFramesInput, frames } = this.props;
    e.preventDefault();

    const isExist = frames.filter((frame) => frame.get('external-id') === addFramesInput).size > 0;
    if (isExist) {
      dispatch(error(t('Frame already exists')));
      return;
    }

    dispatch(attachCampaignFrames(campaignId, addFramesInput))
      .then((missingFrames) => {
        if (missingFrames) {
          const error = t('Missing Frames: ') + missingFrames.join(', ');
          dispatch(info(t('Missing Frames: ') + missingFrames.join(', ')));
          dispatch(setAddFramesError(error, missingFrames.toString()));
        } else {
          // If we've had success we should reset our form
          dispatch(success('Frames added'));
          dispatch(clearAddFramesError());
        }
        fetchFrames();
        return Promise.resolve();
      })
      .catch((e) => {
        dispatch(error(get(e, '[0].detail', t('Error adding frames'))));
      });
  };

  render = () => {
    const { t, isAttachingFrames, dispatch, addFramesInput, addFramesError } = this.props;

    return (
      <div className={style.addFramesWidget}>
        <Input
          label={t('List of External Ids, comma separated or one per line. Add business unit code to the beginning.')}
          className={style.addFramesInput}
          error={addFramesError}
          onChange={(v) => {
            dispatch(setAddFramesInput(v));
          }}
          value={addFramesInput}
          multiline
        />
        <div className={style.addFramesButton}>
          <Button
            label={t('Manually add Frames')}
            disabled={isAttachingFrames || addFramesInput === '' || escapeNewLineItems(addFramesInput).size <= 0}
            onClick={this.handleOnClick}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  campaignId: state.campaign.campaign.get('id'),
  isAttachingFrames: state.campaign.isAttachingFrames,
  addFramesInput: state.campaignMediaBuy.addFramesInput,
  addFramesError: state.campaignMediaBuy.addFramesError,
  frames: state.campaignMediaBuy.frames,
});

export default withNamespaces(['common'], { wait: false })(connect(mapStateToProps)(AddFramesWidget));

AddFramesWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  campaignId: PropTypes.string,
  isAttachingFrames: PropTypes.bool,
  t: PropTypes.func.isRequired,
  fetchFrames: PropTypes.func.isRequired,
  addFramesInput: PropTypes.string,
  addFramesError: PropTypes.string,
  frames: PropTypes.instanceOf(List).isRequired,
};
