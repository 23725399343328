import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';

const getInitialState = () => ({
  activeCreativeId: '',
  activeFrameSpecificationId: '',
  activeRuleSetIds: List([]),
  activeContentItemIds: List([]),
  activeContent: Map(),
  creatives: List([]),
  isFetchingCreatives: false,
  isFetchingFrameSpecifications: false,
});

const preview = handleActions(
  {
    CAMPAIGN_PREVIEW_FETCH_CREATIVES_REQUEST: (state) => ({
      ...state,
      isFetchingCreatives: true,
    }),
    CAMPAIGN_PREVIEW_FETCH_CREATIVES_SUCCESS: (state, action) => ({
      ...state,
      activeFrameSpecificationId: action.frameSpecificationId,
      activeRuleSetIds: fromJS(action.ruleSetIds),
      activeContentItemIds: fromJS(action.contentItemIds),
      creatives: fromJS(action.creatives),
    }),
    CAMPAIGN_PREVIEW_FETCH_CREATIVES_COMPLETE: (state) => ({
      ...state,
      isFetchingCreatives: false,
    }),
    CAMPAIGN_PREVIEW_SET_ACTIVE_CREATIVE_ID: (state, action) => ({
      ...state,
      activeCreativeId: action.activeCreativeId,
    }),
    CAMPAIGN_PREVIEW_SET_ACTIVE_RULESET_IDS: (state, action) => ({
      ...state,
      activeRuleSetIds: fromJS(action.activeRuleSetIds),
    }),
    CAMPAIGN_PREVIEW_SET_ACTIVE_CONTENT: (state, action) => ({
      ...state,
      activeContent: state.activeContent.merge(action.activeContent),
    }),
    CAMPAIGN_PREVIEW_RESET_STATE: () => getInitialState(),
    CAMPAIGN_RELATIONS_FETCH_REQUEST: (state, action) => ({
      ...state,
      isFetchingFrameSpecifications:
        action.relation === 'frame-specifications' ? true : state.isFetchingFrameSpecifications,
    }),
    CAMPAIGN_RELATIONS_FETCH_COMPLETE: (state, action) => ({
      ...state,
      isFetchingFrameSpecifications:
        action.relation === 'frame-specifications' ? false : state.isFetchingFrameSpecifications,
    }),
  },
  getInitialState(),
);

export default preview;
