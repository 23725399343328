import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { includes, reject } from 'lodash';

import Checkbox from 'assets/components/presentational/Checkbox';

import style from './dayOfWeekForm.scss';

const Options = {
  DAYS_OF_WEEK: 0,
};

const handleCheckboxChanged = (value, checked, field) => {
  if (checked) {
    field.value.push(value);
  } else {
    field.value = reject(field.value, (c) => c === value);
  }

  return field.onChange(field.value);
};

const renderGrid = (ruleType, fields) => (
  <div className={ruleType.get('is-selected') ? style.grid : style.gridDisabled}>
    {ruleType
      .get('options')
      .get(Options.DAYS_OF_WEEK)
      .map((dayOfWeek, key) => {
        const selected = includes(fields[Options.DAYS_OF_WEEK].value, dayOfWeek.get('value'));

        return (
          <div className={selected ? style.gridItemSelected : style.gridItem} key={key}>
            <Checkbox
              checked={selected}
              disabled={!ruleType.get('is-selected')}
              onChange={(checked) =>
                handleCheckboxChanged(dayOfWeek.get('value'), checked, fields[Options.DAYS_OF_WEEK])
              }
            >
              <span className={style.optionTitle}>{dayOfWeek.get('label')}</span>
            </Checkbox>
          </div>
        );
      })}
  </div>
);

const DayOfWeekForm = ({ fields, ruleType }) => <div className={style.component}>{renderGrid(ruleType, fields)}</div>;

export default DayOfWeekForm;

DayOfWeekForm.propTypes = {
  fields: PropTypes.object.isRequired,
  ruleType: PropTypes.instanceOf(Map).isRequired,
};

DayOfWeekForm.defaultProps = {};
