import FEATURES from 'features';
import config from 'app-config';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import getReducer from '../reducers';

const rootReducer = (FEAT) => (state, action) => {
  const reducer = getReducer(FEAT);
  if (action.type === 'APP_CLEAR_STATE') {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const createStoreWithInitialState = (initialState = {}, FEAT = {}) => {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    (config.reduxDevTools && config.reduxDevTools.enabled && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const store = createStore(rootReducer(FEAT), initialState, composeEnhancers(applyMiddleware(thunk)));
  /* eslint-enable */
  return store;
};

export default createStoreWithInitialState(undefined, FEATURES);
export { createStoreWithInitialState as getStore };
