import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import BaseCreativePreview from './BaseCreativePreview';

const CreativePreviewDropZone = ({
  creative,
  fetchCreativeForPreview,
  frameSpecifications,
  isFetching,
  params,
  setActiveSection,
}) => {
  const baseUrl = '/drop-zone/view';

  return (
    <BaseCreativePreview
      baseUrl={baseUrl}
      creative={creative}
      fetchCreativeForPreview={fetchCreativeForPreview}
      frameSpecifications={frameSpecifications}
      isFetching={isFetching}
      params={params}
      setActiveSection={setActiveSection}
    />
  );
};

export default CreativePreviewDropZone;

CreativePreviewDropZone.propTypes = {
  creative: PropTypes.instanceOf(Map).isRequired,
  fetchCreativeForPreview: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    campaignId: PropTypes.string.isRequired,
    creativeId: PropTypes.string.isRequired,
  }).isRequired,
  setActiveSection: PropTypes.func.isRequired,
};
