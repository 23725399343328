import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Heading, { HeadingSizes } from 'assets/components/presentational/Heading';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Dialog from 'assets/components/presentational/Dialog';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';

import { BookingPropTypes } from '../BookingCard';

import style from './BulkPromotion.scss';

const propTypes = {
  t: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  bookings: PropTypes.arrayOf(BookingPropTypes).isRequired,
  bulkPromoteBookings: PropTypes.func.isRequired,
  promotableTotal: PropTypes.number.isRequired,
  isFullyPromoted: PropTypes.bool.isRequired,
  isFullyPromotable: PropTypes.bool.isRequired,
  isPartiallyPromotable: PropTypes.bool.isRequired,
};

class BulkPromotion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogVisible: false,
      dialogMessage: '',
    };
  }

  onConfirm = () => {
    const { campaignId, bookings, bulkPromoteBookings } = this.props;
    this.hideDialog();
    bulkPromoteBookings(campaignId, bookings);
  };

  showDialog = (message) => {
    this.setState({
      dialogVisible: true,
      dialogMessage: message,
    });
  };

  hideDialog = () => {
    this.setState({
      dialogVisible: false,
    });
  };

  renderConfirmDialog() {
    const { t } = this.props;
    const { dialogVisible, dialogMessage } = this.state;

    const actions = [
      {
        label: t('Cancel'),
        onClick: this.hideDialog,
      },
      {
        label: t('Confirm'),
        onClick: this.onConfirm,
      },
    ];

    return (
      <Dialog active={dialogVisible} actions={actions} title={t('Are you sure?')} type="normal">
        <p>{dialogMessage}</p>
      </Dialog>
    );
  }

  renderPromotionArea() {
    const { promotableTotal, isFullyPromotable, isPartiallyPromotable, isFullyPromoted, t } = this.props;

    if (isFullyPromoted) {
      return (
        <div className={style.promotionStatus}>
          <span className={style.promotionStatusIcon}>
            <Icon iconType={IconTypes.DONE_ALL} />
          </span>
          <Heading size={HeadingSizes.MEDIUM}>{t('campaign_status_promoted')}</Heading>
        </div>
      );
    }
    if (isPartiallyPromotable) {
      return (
        <div className={style.promoteCTA}>
          <Button
            theme={ButtonThemes.PRIMARY}
            label={t('promote_ready')}
            onClick={() => this.showDialog(t('promote_ready_confirmation', { total: promotableTotal }))}
          />
          <p className={style.promoteCTADescription}>{t('promote_ready__description', { total: promotableTotal })}</p>
        </div>
      );
    }
    if (isFullyPromotable) {
      return (
        <div className={style.promoteCTA}>
          <Button
            theme={ButtonThemes.PRIMARY}
            label={t('promote_all')}
            onClick={() => this.showDialog(t('promoted_all_confirmation'))}
          />
          <p className={style.promoteCTADescription}>{t('promote_all_description')}</p>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        {this.renderPromotionArea()}
        {this.renderConfirmDialog()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'bookings'], { wait: false })(BulkPromotion);

BulkPromotion.propTypes = propTypes;
