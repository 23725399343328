import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { isFunction } from 'lodash';
import { withNamespaces } from 'react-i18next';

import { CreativeModerationStatuses } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import Drawer from 'assets/components/presentational/Drawer';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import CreativeModerationSection from 'assets/components/presentational/Campaign/CreativeModerationSection';

import style from './moderationSummary.scss';

class ModerationSummary extends Component {
  handleClose = (e) => {
    const { onClose } = this.props;

    if (isFunction(onClose)) {
      onClose(e);
    }
  };

  renderProgress() {
    const { isFetchingModerationSummary } = this.props;

    if (isFetchingModerationSummary) {
      return (
        <span className={style.progress}>
          <ProgressBar circular />
        </span>
      );
    }
  }

  render() {
    const {
      active,
      creative,
      isRecalculatingModeration,
      moderationSummary,
      onRecalculateModerationSummaryClick,
      onRefreshModerationSummaryClick,
      t,
    } = this.props;

    const isRecalculateButtonDisabled = creative.get('moderation-status') !== CreativeModerationStatuses.PENDING;

    return (
      <Drawer active={active} onOverlayClick={this.handleClose}>
        <Heading className={style.moderationSummaryHeading} size={HeadingSizes.LARGE} tag={HeadingTags.H3}>
          <span>{t('Moderation Summary')}</span>
          <Button
            className={isRecalculatingModeration ? style.recalculateButtonDisabled : style.recalculateButton}
            disabled={isRecalculatingModeration || isRecalculateButtonDisabled}
            icon={<Icon iconType={IconTypes.AUTORENEW} />}
            label={t('recalculate moderation')}
            onClick={() => onRecalculateModerationSummaryClick()}
          />
          <Button
            className={style.refreshButton}
            disabled={isRecalculatingModeration}
            icon={<Icon iconType={IconTypes.REFRESH} />}
            label={t('refresh')}
            onClick={() => onRefreshModerationSummaryClick()}
          />
        </Heading>
        {this.renderProgress()}
        <CreativeModerationSection
          creativeName={creative.get('name', '')}
          moderationSummary={moderationSummary}
          numberOfModerationDecisionsDisplayed={50}
        />
        <Button
          className={style.moderationButton}
          label={t('Close')}
          onClick={this.handleClose}
          theme={ButtonThemes.WHITE}
          disabled={!active}
        />
      </Drawer>
    );
  }
}

export default withNamespaces(['common', 'creative'], { wait: false })(ModerationSummary);

ModerationSummary.propTypes = {
  active: PropTypes.bool,
  creative: PropTypes.instanceOf(Map),
  isFetchingModerationSummary: PropTypes.bool,
  isRecalculatingModeration: PropTypes.bool,
  moderationSummary: PropTypes.instanceOf(List),
  onRecalculateModerationSummaryClick: PropTypes.func,
  onRefreshModerationSummaryClick: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
};
