import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { animateScroll as scroll } from 'react-scroll';
import { get, isEqual, truncate } from 'lodash';
import queryString from 'query-string';

import FEATURES from 'features';
import { getCreativeTypeIcon, redirect, triggerBodyClickToClose } from 'modules/Helpers';

import ProgressBar from 'components/patterns/ProgressBar';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Icon, { IconTypes } from 'assets/components/presentational/Icon';
import Dropdown from 'assets/components/presentational/Dropdown';
import CreativePreview from 'assets/components/presentational/CreativePreview';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import Button from 'assets/components/presentational/Button';

import SetContentWidget from './SetContentWidget';
import SetRuleSetsWidget from './SetRuleSetsWidget';

import style from './Preview.scss';

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creativesToPreview: new List(),
    };
  }

  componentDidMount() {
    const { setActiveSection, campaign } = this.props;

    setActiveSection('preview');

    if (campaign.size) {
      this.fetchCreatives();
    }
  }

  componentDidUpdate(prevProps) {
    const { campaign } = this.props;

    const prevQueryParams = this.getQueryParams(prevProps);
    const queryParams = this.getQueryParams();
    if (!isEqual(prevQueryParams, queryParams) || !campaign.equals(prevProps.campaign)) {
      this.fetchCreatives();
    }
  }

  componentWillUnmount() {
    const { clearPreview } = this.props;
    clearPreview();
  }

  getActiveFrameSpecification = () => {
    const { activeFrameSpecificationId, frameSpecifications } = this.props;

    if (!frameSpecifications.size) {
      return new Map();
    }

    const activeFrameSpecification = frameSpecifications.find((f) => f.get('id') === activeFrameSpecificationId);

    if (activeFrameSpecification) {
      return activeFrameSpecification;
    }

    return frameSpecifications.first();
  };

  getCreativesByFrameSpecAndRuleSet = () => {
    const { activeRuleSetIds, creatives } = this.props;

    const frameSpecification = this.getActiveFrameSpecification();
    let frameCreatives = creatives;

    if (frameSpecification) {
      frameCreatives = creatives.filter(
        (creative) => creative.get('frame-specification-id') === frameSpecification.get('id'),
      );
    }

    if (activeRuleSetIds.size < 1) {
      frameCreatives = frameCreatives.filter((c) => c.get('is-default'));
    }

    return frameCreatives;
  };

  getActiveCreative = () => {
    const { activeCreativeId } = this.props;

    const { creativesToPreview } = this.state;

    if (!creativesToPreview.size) {
      return new Map();
    }
    const activeCreative = creativesToPreview.find((creative) => creative.get('id') === activeCreativeId);

    if (activeCreative && activeCreative.size) {
      return activeCreative;
    }
    return creativesToPreview.first();
  };

  getQueryParams = (props = this.props) => {
    const {
      params: { campaignId },
      location: { query },
    } = props;
    const frameSpecificationId = get(query, 'frame_specification_id');
    const ruleSetIds = [].concat(get(query, 'rule_set_ids[]', []));
    const contentItemIds = [].concat(get(query, 'content_item_ids[]', []));
    return { campaignId, ruleSetIds, frameSpecificationId, contentItemIds };
  };

  getAvailableFrameSpecificationsForDropdown() {
    const { frameSpecifications } = this.props;
    return frameSpecifications
      .map((frameSpecification) => ({
        value: frameSpecification.get('id'),
        label: frameSpecification.get('name'),
      }))
      .toJS();
  }

  getActiveRuleSets = () => {
    const { activeRuleSetIds, ruleSets } = this.props;
    const activeRuleSets = ruleSets.filter((r) => activeRuleSetIds.includes(r.get('id')));

    let ruleSetNames = '';
    activeRuleSets.forEach((ruleSet) => {
      ruleSetNames = `${ruleSetNames + ruleSet.get('name')} `;
    });
    return ruleSetNames;
  };

  refreshPreview = () => {
    const queryParams = this.getQueryParams();
    const { campaignId } = queryParams;
    let { frameSpecificationId } = queryParams;
    if (!frameSpecificationId) {
      frameSpecificationId = this.getActiveFrameSpecification().get('id');
    }

    let activeContentItemIds = List([]);
    const { activeRuleSetIds, activeContent } = this.props;
    activeContent.valueSeq().forEach((v) => {
      activeContentItemIds = activeContentItemIds.concat(v.toJS());
    });
    this.redirect(campaignId, activeRuleSetIds.toJS(), frameSpecificationId, activeContentItemIds.toJS());
  };

  handleFrameSpecificationChange = (newFrameSpecificationId) => {
    const queryParams = this.getQueryParams();
    const { campaignId, ruleSetIds, contentItemIds } = queryParams;
    let { frameSpecificationId } = queryParams;

    frameSpecificationId = newFrameSpecificationId;

    this.redirect(campaignId, ruleSetIds, frameSpecificationId, contentItemIds);
  };

  /** *
   * Determines whether the creatives showing are all defaults
   *
   * If activeRuleSetId is set AND all the creatives returned are defaults AND
   * the activeRuleSetId doesn't exist in each of the creative rule-sets
   */
  isShowingDefaultCreatives = () => {
    const { activeRuleSetIds } = this.props;
    const { creativesToPreview } = this.state;

    return (
      activeRuleSetIds.size > 1 &&
      creativesToPreview.every((c) => c.get('is-default')) &&
      creativesToPreview.every((c) => c.get('rule-sets').findIndex((r) => activeRuleSetIds.has(r.get('id'))) === -1)
    );
  };

  redirect = (campaignId, ruleSetIds, frameSpecId, contentItemIds) => {
    const { location } = this.props;
    let nextUrl = `${['/campaigns', campaignId, 'preview'].join('/')}?`;
    const query = { rule_set_ids: ruleSetIds, frame_specification_id: frameSpecId, content_item_ids: contentItemIds }; // eslint-disable-line camelcase
    nextUrl += queryString.stringify(query, { arrayFormat: 'bracket' });
    const currentUrl = location.pathname + location.search;
    const shouldReplaceHistory = true;

    if (currentUrl === encodeURI(nextUrl)) {
      this.fetchCreatives();
      return this.forceUpdate();
    }

    return redirect(nextUrl, shouldReplaceHistory);
  };

  setCreativesToPreview = () => {
    const { setActiveCreativeId } = this.props;

    this.setState({ creativesToPreview: this.getCreativesByFrameSpecAndRuleSet() }, () => {
      const { creativesToPreview } = this.state;
      if (creativesToPreview.size) {
        setActiveCreativeId(creativesToPreview.first().get('id'));
      }
    });
  };

  fetchCreatives = () => {
    const { fetchCreatives } = this.props;
    const { campaignId, ruleSetIds, frameSpecificationId, contentItemIds } = this.getQueryParams();

    // Scroll to the top so the user can see the loading state
    scroll.scrollToTop({
      duration: 200,
      delay: 0,
      smooth: true,
    });

    fetchCreatives(campaignId, ruleSetIds, frameSpecificationId, contentItemIds).then(() => {
      this.setCreativesToPreview();
    });
  };

  handleCreativeNameClick = (newCreativeId) => {
    const { setActiveCreativeId } = this.props;

    setActiveCreativeId(newCreativeId);
  };

  renderSetContentWidget() {
    const activeCreative = this.getActiveCreative();
    if (activeCreative.get('content-types', List()).size > 0) {
      return <SetContentWidget activeCreative={activeCreative} />;
    }
    return null;
  }

  renderFrames() {
    const { isFetchingCreatives, t } = this.props;

    const activeFrameSpecificationId = this.getActiveFrameSpecification().get('id');

    return (
      <div className={style.sideBarSection}>
        <div className={style.sideBarItem}>
          <div className={style.previewSelect}>
            <Dropdown
              allowBlank
              auto={false}
              className={style.dropDownSection}
              disabled={isFetchingCreatives}
              label={t('Preview with frame')}
              onChange={(frameSpecificationId) => this.handleFrameSpecificationChange(frameSpecificationId)}
              onBlur={triggerBodyClickToClose}
              source={this.getAvailableFrameSpecificationsForDropdown()}
              value={activeFrameSpecificationId}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCreative = () => {
    const {
      params: { campaignId },
    } = this.props;

    return (
      <CreativePreview
        key={this.getActiveCreative().get('id')}
        campaignId={campaignId}
        creative={this.getActiveCreative()}
        frameSpecification={this.getActiveFrameSpecification()}
      />
    );
  };

  renderPreviewTitle = () => {
    const { t } = this.props;
    const frameSpecification = this.getActiveFrameSpecification();
    let title = '';

    if (frameSpecification.has('name')) {
      title = t('Preview title', {
        replace: {
          rule: truncate(this.getActiveRuleSets(), 50),
          frame: frameSpecification.get('name'),
        },
      });
    }

    return (
      <span>
        <Heading className={style.previewTitle} tag={HeadingTags.H2} size={HeadingSizes.SMALL}>
          <Icon iconType={IconTypes.TV} />
          {title}
        </Heading>
      </span>
    );
  };

  renderMatchedCreatives = () => {
    const { activeCreativeId, t } = this.props;
    const { creativesToPreview } = this.state;

    return (
      <div className={`${style.sideBarSection} ${style.sideBarSectionScrollable}`}>
        <Heading className={style.creativesHeading} size={HeadingSizes.SMALLEST} tag={HeadingTags.H3}>
          {this.isShowingDefaultCreatives()
            ? t('Displaying X fallbacks', { count: creativesToPreview.size })
            : t('X matched creatives', { count: creativesToPreview.size })}
        </Heading>

        {/* TODO: Replace span with a proper button  */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        {creativesToPreview.size > 0 &&
          creativesToPreview.map((creative, key) => (
            <div className={style.sideBarItem} key={creative.get('id')}>
              <span
                className={
                  creative.get('id') === activeCreativeId || (!activeCreativeId && key === 0)
                    ? style.creativeNameActive
                    : style.creativeName
                }
                onClick={() => this.handleCreativeNameClick(creative.get('id'))}
              >
                <Icon iconType={getCreativeTypeIcon(creative.get('type'))} />
                {creative.get('name')}
              </span>
            </div>
          ))}
        {/* eslint-enable */}
      </div>
    );
  };

  renderSideBar = () => {
    const { t, campaign } = this.props;
    return (
      <div className={style.sideBar}>
        {this.renderMatchedCreatives()}
        {FEATURES.PROMOTION(campaign) && (
          <>
            <SetRuleSetsWidget />
            {this.renderSetContentWidget()}
          </>
        )}
        {this.renderFrames()}
        <Button
          className={style.refreshButton}
          icon={<Icon iconType={IconTypes.SYNC} />}
          label={t('Refresh')}
          onClick={this.refreshPreview}
        />
      </div>
    );
  };

  renderContent = () => {
    const {
      isFetchingCampaign,
      isFetchingCreatives,
      isFetchingFrameSpecifications,
      frameSpecifications,
      t,
    } = this.props;

    if (isFetchingCampaign || isFetchingCreatives || isFetchingFrameSpecifications) {
      return <ProgressBar />;
    }

    if (!frameSpecifications.size) {
      return (
        <span>
          <Heading className={style.previewTitle} tag={HeadingTags.H2} size={HeadingSizes.SMALL}>
            <Icon iconType={IconTypes.TV} />
            {t('There are no frames available for this campaign')}
          </Heading>
        </span>
      );
    }

    return (
      <div>
        {this.renderPreviewTitle()}

        <div className={style.preview}>
          <div className={style.previewContainer}>{this.renderCreative()}</div>

          {this.renderSideBar()}
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className={style.component}>
        <HeadingGroup title={t('Preview')} intro={t('Preview section introduction')} />

        {this.renderContent()}
      </div>
    );
  }
}

Preview.propTypes = {
  activeCreativeId: PropTypes.string.isRequired,
  activeFrameSpecificationId: PropTypes.string.isRequired,
  activeRuleSetIds: PropTypes.instanceOf(List).isRequired,
  activeContent: PropTypes.instanceOf(Map).isRequired,
  creatives: PropTypes.instanceOf(List).isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isFetchingCreatives: PropTypes.bool.isRequired,
  isFetchingFrameSpecifications: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  t: PropTypes.func.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  setActiveSection: PropTypes.func.isRequired,
  clearPreview: PropTypes.func.isRequired,
  setActiveCreativeId: PropTypes.func.isRequired,
  fetchCreatives: PropTypes.func.isRequired,
};

export default Preview;
