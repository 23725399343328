import { API } from 'app-constants';

import Request from 'modules/API/request';
import { List, fromJS } from 'immutable';
import moment from 'moment';
import { summaryFilterItems } from 'modules/Helpers/dashboard';
import { notifyApiError } from 'actions/notify';

export const FETCH_CAMPAIGNS_REQUEST = 'dashboard/FETCH_CAMPAIGNS_REQUEST';
export const FETCH_CAMPAIGNS_SUCCESS = 'dashboard/FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_COMPLETE = 'dashboard/FETCH_CAMPAIGNS_COMPLETE';

export const FETCH_BUSINESSUNITS_REQUEST = 'dashboard/FETCH_BUSINESSUNITS_REQUEST';
export const FETCH_BUSINESSUNITS_SUCCESS = 'dashboard/FETCH_BUSINESSUNITS_SUCCESS';
export const FETCH_BUSINESSUNITS_COMPLETE = 'dashboard/FETCH_BUSINESSUNITS_COMPLETE';

export const FETCH_SUMMARY_REQUEST = 'dashboard/FETCH_SUMMARY_REQUEST';
export const FETCH_SUMMARY_SUCCESS = 'dashboard/FETCH_SUMMARY_SUCCESS';
export const FETCH_SUMMARY_COMPLETE = 'dashboard/FETCH_SUMMARY_COMPLETE';

export const FETCH_CAMPAIGNS_PAGINATION_MERGE = 'dashboard/FETCH_CAMPAIGNS_PAGINATION_MERGE';

export const CLEAR_CAMPAIGNS = 'dashboard/CLEAR_CAMPAIGNS';

export const SET_FILTER_SEARCH_VALUE = 'dashboard/SET_FILTER_SEARCH_VALUE';
export const SET_FILTER_TIME_PERIOD_VALUE = 'dashboard/SET_FILTER_TIME_PERIOD_VALUE';
export const SET_FILTER_BU_VALUE = 'dashboard/SET_FILTER_BU_VALUE';

export const SET_ACTIVE_SUMMARY_ITEM = 'dashboard/SET_ACTIVE_SUMMARY_ITEM';

export const UPDATE_MY_RECENT_CAMPAIGNS = 'dashboard/UPDATE_MY_RECENT_CAMPAIGNS';
export const GET_MY_RECENT_CAMPAIGNS = 'dashboard/GET_MY_RECENT_CAMPAIGNS';

const getFiltersObject = (summaryItemId) => {
  let activeFilterItem = null;

  Object.keys(summaryFilterItems).forEach((filterSection) => {
    if (activeFilterItem) {
      return;
    }

    activeFilterItem = summaryFilterItems[filterSection].find((filterItem) => summaryItemId === filterItem.id);
  });

  return activeFilterItem ? activeFilterItem.filter : [];
};

export const fetchCampaigns = (opts = {}, activeSummaryFilterItem) => (dispatch) => {
  const options = {
    endpoint: 'campaigns?stats=1',
    method: 'GET',
    pagination: {
      pageNumber: opts.pageNumber ? opts.pageNumber : 1,
      pageSize: opts.pageSize ? opts.pageSize : API.MAX_PAGE_SIZE,
    },
    includes: opts.includes ? ['client'].concat(opts.includes) : ['client'],
    filters: opts.filters ? opts.filters : [],
    sorts: opts.sorts ? opts.sorts : [],
  };

  if (activeSummaryFilterItem) {
    options.filters = options.filters.concat(getFiltersObject(activeSummaryFilterItem));
  }

  dispatch({ type: FETCH_CAMPAIGNS_REQUEST });

  return Request.send(options)
    .then((response) => {
      const campaigns = fromJS(response.data.body.parsed);
      const pagination = fromJS(response.data.body.meta.pagination);

      dispatch({ type: FETCH_CAMPAIGNS_SUCCESS, campaigns });
      dispatch({ type: FETCH_CAMPAIGNS_COMPLETE });
      dispatch({ type: FETCH_CAMPAIGNS_PAGINATION_MERGE, pagination });

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      dispatch({ type: FETCH_CAMPAIGNS_COMPLETE });

      return Promise.reject(error);
    });
};

export const fetchCampaignsSummary = (filters) => (dispatch) => {
  const options = {
    endpoint: 'campaigns/summary',
    method: 'GET',
    filters: [].concat(filters),
  };

  return Request.send(options).catch((error) => {
    dispatch(notifyApiError(error));
    return Promise.reject(error);
  });
};

export const fetchSummary = (timePeriod, businessUnit, filterSearch) => (dispatch) => {
  let filtersLive = [{ live: true }];
  let filtersUpcoming = [
    {
      starts_after: moment() // eslint-disable-line camelcase
        .add(1, 'days')
        .format('YYYY-MM-D'),
    },
    {
      starts_before: moment() // eslint-disable-line camelcase
        .add(timePeriod, 'weeks')
        .format('YYYY-MM-D'),
    },
  ];
  if (filterSearch && filterSearch.length) {
    filtersLive = filtersLive.concat({ search: `%${filterSearch}%` });
    filtersUpcoming = filtersUpcoming.concat({ search: `%${filterSearch}%` });
  }

  if (businessUnit && businessUnit !== 'all') {
    filtersLive = filtersLive.concat({ business_unit: businessUnit }); // eslint-disable-line camelcase
    filtersUpcoming = filtersUpcoming.concat({ business_unit: businessUnit }); // eslint-disable-line camelcase
  }

  dispatch({ type: FETCH_SUMMARY_REQUEST });

  return Promise.all([dispatch(fetchCampaignsSummary(filtersUpcoming)), dispatch(fetchCampaignsSummary(filtersLive))])
    .then((responses) => {
      const summary = fromJS({
        upcoming: responses[0].data.body.parsed,
        live: responses[1].data.body.parsed,
      });

      dispatch({ type: FETCH_SUMMARY_SUCCESS, summary });
      dispatch({ type: FETCH_SUMMARY_COMPLETE });

      return Promise.resolve(summary);
    })
    .catch((e) => {
      dispatch({ type: FETCH_SUMMARY_COMPLETE });

      return Promise.reject(e);
    });
};

export const fetchBusinessUnits = () => (dispatch) => {
  const options = {
    endpoint: 'business-units',
    method: 'GET',
    pagination: Request.ALL_PAGES,
  };

  dispatch({ type: FETCH_BUSINESSUNITS_REQUEST });

  return Request.send(options)
    .then((response) => {
      const businessUnits = fromJS(response.data.body.parsed);

      dispatch({ type: FETCH_BUSINESSUNITS_SUCCESS, businessUnits });
      dispatch({ type: FETCH_BUSINESSUNITS_COMPLETE });

      return Promise.resolve(businessUnits);
    })
    .catch((error) => {
      dispatch(notifyApiError(error));
      return Promise.reject(error);
    });
};

export const clearCampaigns = () => ({
  type: CLEAR_CAMPAIGNS,
});

export const setFilterSearchValue = (searchTerm) => ({
  type: SET_FILTER_SEARCH_VALUE,
  searchTerm: typeof searchTerm === 'string' ? searchTerm : '',
});

export const setFilterTimePeriodValue = (timePeriod) => ({
  type: SET_FILTER_TIME_PERIOD_VALUE,
  timePeriod,
});

export const setFilterBUValue = (businessUnit) => ({
  type: SET_FILTER_BU_VALUE,
  businessUnit,
});

export const setActiveSummaryItem = (activeSummaryFilterItem) => ({
  type: SET_ACTIVE_SUMMARY_ITEM,
  activeSummaryFilterItem,
});

export const updateMyRecentCampaignsStore = (userId, campaignId, campaignName) => {
  const recentCampaign = {
    id: campaignId,
    name: campaignName,
  };

  let recentlyViewedCampaigns = JSON.parse(localStorage.getItem(`recentCampaigns-${userId}`)) || [];

  if (!recentlyViewedCampaigns.find((item) => item.id === recentCampaign.id)) {
    recentlyViewedCampaigns.unshift(recentCampaign);
  } else {
    recentlyViewedCampaigns = recentlyViewedCampaigns.filter((item) => item.id !== recentCampaign.id);
    recentlyViewedCampaigns.unshift(recentCampaign);
  }

  recentlyViewedCampaigns.splice(5);
  localStorage.setItem(`recentCampaigns-${userId}`, JSON.stringify(recentlyViewedCampaigns));

  return { type: UPDATE_MY_RECENT_CAMPAIGNS };
};

export const getMyRecentCampaigns = (userId) => {
  const recentCampaigns = fromJS(JSON.parse(localStorage.getItem(`recentCampaigns-${userId}`))) || new List();
  return { type: GET_MY_RECENT_CAMPAIGNS, recentCampaigns };
};
