import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon, { IconTypes, IconSizes } from 'assets/components/presentational/Icon';

import style from './create.scss';

class Create extends Component {
  handleCreateClick = () => {
    const { onCreateClick } = this.props;
    return onCreateClick();
  };

  render() {
    const { createLabel, children } = this.props;

    const label = createLabel;

    return (
      <div className={style.newItemContainer}>
        <div className={style.newItemDetails}>{children}</div>
        <div className={style.newItemCreate} onClick={this.handleCreateClick}>
          <div className={style.newItemCreateText}>
            <span className={style.newItemIcon}>
              <Icon iconType={IconTypes.ADD} size={IconSizes.SMALL} />
            </span>
            <span>{label}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Create;

Create.propTypes = {
  onCreateClick: PropTypes.func.isRequired,
  createLabel: PropTypes.string,
  children: PropTypes.node,
};

Create.defaultProps = {
  createLabel: '',
  children: null,
};
