import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';

const initialState = {
  activeCampaignId: '',
  campaign: new Map(),
  promoting: false,
  isDeletingContentItems: false,
  isFetching: false,
  isInitialFetch: true,
  isTogglingCampaignActive: false,
  lastFetchAt: null,
  isShowingCreateCampaignForm: false,
  isShowingCampaignFormDialog: false,
  campaignFormDialogMessage: null,
  contentCompleting: false,
  frameSpecsFilter: new Map(),
};

export default handleActions(
  {
    CAMPAIGN_TOGGLE_CREATE_FORM: (state) => ({
      ...state,
      isShowingCreateCampaignForm: !state.campaign.isShowingCreateCampaignForm,
    }),
    CAMPAIGN_SHOW_CREATE_FORM: (state) => ({
      ...state,
      isShowingCreateCampaignForm: true,
    }),
    CAMPAIGN_HIDE_CREATE_FORM: (state) => ({
      ...state,
      isShowingCreateCampaignForm: false,
    }),
    CAMPAIGN_SET_ACTIVE_ID: (state, action) => ({
      ...state,
      activeCampaignId: action.campaignId,
    }),
    CAMPAIGN_FETCH_REQUEST: (state) => ({
      ...state,
      isFetching: true,
    }),
    CAMPAIGN_FETCH_SUCCESS: (state, action) => {
      let campaign;

      const isRefetchingCurrentCampaign = action.campaign.id === state.activeCampaignId;
      if (isRefetchingCurrentCampaign) {
        const freshCampaigData = fromJS(action.campaign);
        campaign = state.campaign.merge(freshCampaigData);
      } else {
        ({ campaign } = state);
      }

      return {
        ...state,
        campaign,
        isFetching: false,
        isInitialFetch: false,
        lastFetchAt: action.lastFetchAt,
      };
    },
    CAMPAIGN_FETCH_COMPLETE: (state) => ({
      ...state,
      isFetching: false,
    }),
    DELETE_CAMPAIGN_CONTENT_ITEMS_REQUEST: (state) => ({
      ...state,
      isDeletingContentItems: true,
    }),
    DELETE_CAMPAIGN_CONTENT_ITEMS_SUCCESS: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(['content-items'], (contentItems) => {
        if (action.campaignId !== state.activeCampaignId) {
          return contentItems;
        }

        return contentItems.filter(
          (contentItem) =>
            action.contentItems.findIndex(
              (deletedContentItem) => deletedContentItem.get('id') === contentItem.get('id'),
            ) === -1,
        );
      }),
    }),
    DELETE_CAMPAIGN_CONTENT_ITEMS_COMPLETE: (state) => ({
      ...state,
      isDeletingContentItems: false,
    }),
    // Removes an existing campaign relation item
    CAMPAIGN_RELATION_REMOVE: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) => {
        if (action.campaignId !== state.activeCampaignId) {
          return items;
        }

        return items.filter((item) => item.get('id') !== action.item.get('id'));
      }),
    }),
    CAMPAIGN_RELATION_REPLACE: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) => {
        if (action.campaignId !== state.activeCampaignId) {
          return items;
        }

        const index = items.findIndex((item) => item.get('id') === action.oldItem.get('id'));

        return items.splice(index, 1, action.newItem);
      }),
    }),
    CAMPAIGN_CLEAR: (state) => ({
      ...state,
      campaign: state.campaign.clear(),
    }),
    CAMPAIGN_RELATION_CLEAR: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) => {
        if (action.campaignId !== state.activeCampaignId) {
          return items;
        }

        if (List.isList(items)) {
          return items.clear();
        }
      }),
    }),
    CAMPAIGN_RELATION_DELETE_PROMPT: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) =>
        items.map((item) => {
          if (item.get('id') === action.item.get('id')) {
            return item.set('is-prompting', !item.get('is-prompting', false));
          }

          return item;
        }),
      ),
    }),
    CAMPAIGN_RELATION_DELETE_REQUEST: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) => {
        if (!List.isList(items)) {
          return items;
        }

        return items.map((item) => {
          if (item.get('id') === fromJS(action.item).get('id')) {
            return item.merge({ 'is-prompting': false, 'is-deleting': true });
          }

          return item;
        });
      }),
    }),
    CAMPAIGN_RELATION_DELETE_COMPLETE: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) => {
        if (!List.isList(items)) {
          return items;
        }

        return items.map((item) => {
          if (item.get('id') === fromJS(action.item).get('id')) {
            return item.merge({ 'is-prompting': false, 'is-deleting': false });
          }

          return item;
        });
      }),
    }),
    // Merges a single or many items into a list at the given keyPath, e.g. campaign."content-items"
    // The method updates any existing items based on id within the relation or creates new ones
    CAMPAIGN_RELATION_MERGE: (state, action) => ({
      ...state,
      campaign: state.campaign.updateIn(action.keyPath, (items) => {
        if (action.campaignId !== state.activeCampaignId) {
          return items;
        }

        let actionItems = List();
        action.data = fromJS(action.data);

        // Convert action data to list if required
        if (!List.isList(action.data)) {
          actionItems = List().push(action.data);
        } else {
          actionItems = action.data;
        }

        // Existing items may not exist, so just return new ones
        if (!List.isList(items)) {
          return actionItems;
        }

        // Get all items from the action which don't currently exist in the state
        const newItems = actionItems.filter(
          (actionItem) => !items.find((exitingItem) => exitingItem && exitingItem.get('id') === actionItem.get('id')),
        );

        // Update any state items with the action items
        items = items.map((existingItem) => {
          const updatedItem = actionItems.find(
            (actionItem) => existingItem && existingItem.get('id') === actionItem.get('id'),
          );

          if (updatedItem) {
            return existingItem.merge(updatedItem);
          }

          return existingItem;
        });

        // Return our updated items with any new ones added
        return items.concat(newItems);
      }),
    }),
    TOGGLE_CAMPAIGN_FORM_DIALOG: (state) => ({
      ...state,
      isShowingCampaignFormDialog: !state.isShowingCampaignFormDialog,
    }),
    SET_CAMPAIGN_FORM_DIALOG_MESSAGE: (state, action) => ({
      ...state,
      campaignFormDialogMessage: action.message,
    }),
    SHOW_CAMPAIGN_SETTINGS_FORM: (state, action) => ({
      ...state,
      isShowingCampaignSettingsForm: action.campaignId === state.campaign.get('id'),
    }),
    HIDE_CAMPAIGN_SETTINGS_FORM: (state) => ({
      ...state,
      isShowingCampaignSettingsForm: false,
    }),
    TOGGLE_CAMPAIGN_ACTIVE_REQUEST: (state) => ({
      ...state,
      isTogglingCampaignActive: true,
    }),
    TOGGLE_CAMPAIGN_ACTIVE_SUCCESS: (state, action) => ({
      ...state,
      campaign: state.campaign.merge(fromJS(action.campaign)),
      isTogglingCampaignActive: false,
    }),
    TOGGLE_CAMPAIGN_ACTIVE_FAILURE: (state) => ({
      ...state,
      isTogglingCampaignActive: false,
    }),
    UPDATE_CAMPAIGN_SUCCESS: (state, action) => ({
      ...state,
      campaign: state.campaign.merge(fromJS(action.campaign)),
    }),
    CAMPAIGN_FRAMES_ATTACH_REQUEST: (state) => ({
      ...state,
      isAttachingFrames: true,
    }),
    CAMPAIGN_FRAMES_ATTACH_SUCCESS: (state) => ({
      ...state,
      isAttachingFrames: false,
    }),
    CAMPAIGN_FRAMES_ATTACH_FAILURE: (state) => ({
      ...state,
      isAttachingFrames: false,
    }),
    CAMPAIGN_FRAMES_FETCH_SUCCESS: (state, action) => ({
      ...state,
      campaign: state.campaign.merge(action.campaign),
    }),
    CAMPAIGN_CONTENT_COMPLETED_REQUEST: (state) => ({
      ...state,
      contentCompleting: true,
    }),
    CAMPAIGN_CONTENT_COMPLETED_SUCCESS: (state, action) => ({
      ...state,
      contentCompleting: false,
      campaign:
        action.campaign.get('id') === state.activeCampaignId ? state.campaign.merge(action.campaign) : state.campaign,
    }),
    CAMPAIGN_CONTENT_COMPLETED_FAILURE: (state) => ({
      ...state,
      contentCompleting: false,
    }),
    CAMPAIGN_SET_FLAGGED_AS_LATE_TIME: (state, action) => ({
      ...state,
      campaign: state.campaign.setIn(['is-late-copy-flagged'], action.payload.isMarkedAsLate),
    }),
    CAMPAIGN_SET_PUBLISH_STATUS: (state, action) => ({
      ...state,
      campaign: state.campaign.setIn(['publishing-status'], action.payload.publishStatus),
    }),
    SET_FRAMESPECIFICATION_FILTER: (state, action) => ({
      ...state,
      frameSpecsFilter: state.frameSpecsFilter.setIn(action.keyPath, action.data),
    }),
    CLEAR_FRAMESPECIFICATION_FILTER: (state, action) => ({
      ...state,
      frameSpecsFilter: state.frameSpecsFilter.setIn(action.keyPath, {}),
    }),
  },
  initialState,
);
