import Request from 'modules/API/request';
import { notifyApiError } from 'actions/notify';

export const PIN_CAMPAIGN_SUCCESS = 'PINNED_CAMPAIGNS/PIN_CAMPAIGN_SUCCESS';
export const UNPIN_CAMPAIGN_SUCCESS = 'PINNED_CAMPAIGNS/UNPIN_CAMPAIGN_SUCCESS';
export const FETCH_PIN_CAMPAIGNS_SUCCESS = 'PINNED_CAMPAIGNS/FETCH_PIN_CAMPAIGNS_SUCCESS';
export const SET_IS_FETCHING_PIN_BY_CAMPAIGNID = 'PINNED_CAMPAIGNS/SET_IS_FETCHING_PIN_BY_CAMPAIGNID';

export const pinCampaign = async (campaignId, userId) => {
  const extraSerializerConfiguration = {
    keyForAttribute: (key) => key,
  };

  const options = {
    endpoint: `users/${userId}/pinned-campaigns`,
    method: 'POST',
    data: { campaign_id: campaignId },
    extraSerializerConfiguration,
  };

  return Request.send(options);
};

export const unpinCampaign = async (campaignId, userId) => {
  const options = {
    endpoint: `users/${userId}/pinned-campaigns/${campaignId}`,
    method: 'DELETE',
  };

  return Request.send(options);
};

export const setIsFetchingPinByCampaignId = (campaignId, isPinned) => {
  return {
    type: SET_IS_FETCHING_PIN_BY_CAMPAIGNID,
    payload: { campaignId, isPinned },
  };
};

export const togglePinCampaign = (campaignId) => async (dispatch, getState) => {
  try {
    dispatch(setIsFetchingPinByCampaignId(campaignId, true));

    const {
      pinned: { campaigns },
      auth: { user },
    } = getState();

    const isPinned = campaigns.filter((c) => c.id === campaignId).length > 0;

    if (isPinned) {
      await unpinCampaign(campaignId, user.get('id'));
      dispatch({
        type: UNPIN_CAMPAIGN_SUCCESS,
        campaignId,
      });
    } else {
      const response = await pinCampaign(campaignId, user.get('id'));
      const campaign = response.data.body.parsed.campaigns;

      dispatch({
        type: PIN_CAMPAIGN_SUCCESS,
        campaign: { id: campaign.id, name: campaign.name },
      });
    }
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    dispatch(setIsFetchingPinByCampaignId(campaignId, false));
  }
};

export const fetchPinnedCampaigns = (setIsFetching = () => {}) => async (dispatch, getState) => {
  setIsFetching(true);

  try {
    const {
      auth: { user },
    } = getState();

    const options = {
      endpoint: `users/${user.get('id')}/pinned-campaigns`,
      method: 'GET',
    };

    const response = await Request.send(options);
    const campaigns = response.data.body.parsed.map((campaign) => ({
      id: campaign.campaigns.id,
      name: campaign.campaigns.name,
    }));

    dispatch({
      type: FETCH_PIN_CAMPAIGNS_SUCCESS,
      campaigns,
    });
  } catch (error) {
    dispatch(notifyApiError(error));
  } finally {
    setIsFetching(false);
  }
};
