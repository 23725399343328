import React, { Component } from 'react';
import { ListSubHeader as BaseListSubHeader, ListSubHeaderProps } from 'react-toolbox/lib/list';
import theme from './listSubHeader.scss';

class ListSubHeader extends Component {
  render() {
    const { theme: themeOverride } = this.props;
    return <BaseListSubHeader {...this.props} theme={Object.assign(theme, themeOverride)} />;
  }
}

export default ListSubHeader;

ListSubHeader.propTypes = ListSubHeaderProps;
ListSubHeader.defaultProps = BaseListSubHeader.defaultProps;
