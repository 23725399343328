/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fit from 'fit.js';
import { withNamespaces } from 'react-i18next';
import { get } from 'lodash';

import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import style from './simple-file-input.scss';

class SimpleFileInput extends Component {
  constructor() {
    super();
    this.state = {
      dataUrl: null,
      fileSizeError: null,
    };
  }

  handleDragEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleDrop(e) {
    const { maxUploadSize, maxSizeForHumans, onChange, t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const { fileType } = this.props;
    const file = get(e, 'dataTransfer.files.0');
    const type = get(e, 'dataTransfer.files.0.type', '');
    const maxSize = maxUploadSize || maxUploadSize;
    const maxSizeText = maxSizeForHumans || maxSizeForHumans;
    if (file || (file && fileType !== 'all' && type.indexOf(fileType) === 0)) {
      if (e.dataTransfer.files[0].size > maxSize) {
        this.setState({
          fileSizeError: t('File must be less than {{maxSizeForHumans}}', { maxSizeForHumans: maxSizeText }),
          dataUrl: null,
        });
        onChange();
        return;
      }
      this.setState({
        fileSizeError: '',
      });
      if (e.dataTransfer.files[0]) {
        onChange(e.dataTransfer.files[0]);
        this.updateDataUrl(e.dataTransfer.files);
      }
    }
  }

  handleChange(e) {
    const { maxUploadSize, maxSizeForHumans, onChange, t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    const maxSize = maxUploadSize || maxUploadSize;
    const maxSizeText = maxSizeForHumans || maxSizeForHumans;
    if (e.target.files[0] && e.target.files[0].size > maxSize) {
      this.setState({
        fileSizeError: t('File must be less than {{maxSizeForHumans}}', { maxSizeForHumans: maxSizeText }),
        dataUrl: null,
      });
      onChange();
      return;
    }
    this.setState({
      fileSizeError: null,
    });
    if (e.target.files[0]) {
      onChange(e.target.files[0]);
      this.updateDataUrl(e.target.files);
    }
  }

  componentDidMount() {
    this.fitPreview();
    window.setTimeout(this.fitPreview.bind(this), 250); // Sometimes it doesn't resize in time
    window.setTimeout(this.fitPreview.bind(this), 1000); // Sometimes it doesn't resize in time...
    this.resizeTimeout = null;
    window.addEventListener('resize', this.resizeThrottler.bind(this), false);
  }

  resizeThrottler() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.fitPreview();
      }, 66);
    }
  }

  componentDidUpdate() {
    this.fitPreview();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeThrottler);
  }

  fitPreview() {
    window.setTimeout(() => {
      if (this._fitInner && this._fitOuter) {
        fit(this._fitInner, this._fitOuter);
      }
    }, 1);
  }

  // hasValue () {
  //   return (typeof this.props.value === "string" && this.props.value.length > 0)
  //     || (this.props.value instanceof FileList && this.props.value.length > 0)
  // }

  updateDataUrl(fileList) {
    if (fileList instanceof FileList && fileList.length > 0) {
      new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => resolve(event.target.result);
        fileReader.onerror = reject;
        fileReader.readAsDataURL(fileList[0]);
      }).then((dataUrl) => {
        this.setState({
          dataUrl,
        });
        // For some reason, 50 ms sometime doesn't get there in time to resize bigger videos, so we'll try 1s as well.
        // No harm done.
        window.setTimeout(this.fitPreview.bind(this), 50);
        window.setTimeout(this.fitPreview.bind(this), 1000);
      });
    }
  }

  handleDelete() {
    const { onChange } = this.props;
    onChange('');
    this.setState({
      dataUrl: null,
    });
    if (this._fileInputResetForm) {
      this._fileInputResetForm.reset();
    }
  }

  renderUploadForm() {
    const { error, preview, dark, disabled, name } = this.props;
    const classNames = [style.simpleFileInput];
    if (error) {
      classNames.push(style.simpleFileInputError);
    }
    if (!preview) {
      classNames.push(style.simpleFileInputNoPreview);
    }
    if (dark) {
      classNames.push(style.simpleFileInputDark);
    }

    if (!disabled) {
      return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          className={classNames.join(' ')}
          onDragEnter={(e) => this.handleDragEnter(e)}
          onDragOver={(e) => this.handleDragOver(e)}
          onDrop={(e) => this.handleDrop(e)}
          tabIndex="0"
          htmlFor={`file-upload-${name}`}
        >
          <form
            ref={(el) => {
              this._fileInputResetForm = el;
            }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              id={`file-upload-${name}`}
              type="file"
              className={style.simpleFileInputHidden}
              tabIndex="-1"
              onChange={(e) => this.handleChange(e)}
              {...this.chooseAcceptProp()}
            />
          </form>
          <div>
            <Icon iconType={IconTypes.FILE_UPLOAD} color={IconColors.BLACK} />
          </div>
        </label>
      );
    }
  }

  chooseAcceptProp() {
    const { fileType } = this.props;
    if (fileType === 'all') {
      return {};
    }
    const accept = fileType === 'video' ? 'video/*' : 'image/*';
    return { accept };
  }

  renderDeleteButton() {
    const { disabled } = this.props;
    if (!disabled) {
      return (
        <span className={style.deleteButton} onClick={() => this.handleDelete()}>
          <Icon iconType={IconTypes.DELETE} />
        </span>
      );
    }
  }

  renderUploading() {
    const { disabled, uploadProgress, t } = this.props;

    if (!disabled) {
      return null;
    }
    return (
      <div className={style.componentUploading}>
        <span className={style.uploadPlaceholderText}>{t('Uploading your file(s)')}</span>
        <div className={style.progress} style={{ width: `${uploadProgress + 10}%` }} />
      </div>
    );
  }

  render() {
    const { props } = this;
    const { fileSizeError } = this.state;
    const { error, preview, disabled } = this.props;
    return (
      <div className={style.component}>
        <label className={style.simpleFileInputLabel}>{props.label}</label>
        <div className={style.formRowContainer}>
          {!disabled ? this.renderUploadForm() : null}
          {!disabled ? this.renderUploading() : null}
          {preview ? this.renderPreview() : null}
        </div>
        {error ? <span className={style.simpleFileInputErrorLabel}>{props.error}</span> : ''}
        {fileSizeError ? <span className={style.simpleFileInputErrorLabel}>{fileSizeError}</span> : ''}
      </div>
    );
  }

  renderPreview = () => {
    const { dataUrl } = this.state;
    const { value, fileType, disabled: uploadedDisabled } = this.props;
    const previewClass = uploadedDisabled ? style.readOnlyPreviewWrapper : style.previewWrapper;
    return (
      <div
        ref={(el) => {
          this._fitOuter = el;
        }}
        className={previewClass}
      >
        {dataUrl && fileType === 'image' ? (
          <img
            ref={(el) => {
              this._fitInner = el;
            }}
            src={dataUrl}
            className={style.previewImage}
            onLoad={() => this.fitPreview()}
          />
        ) : (
          ''
        )}
        {typeof value === 'string' && value.length > 0 && fileType === 'image' ? (
          <img
            ref={(el) => {
              this._fitInner = el;
            }}
            src={value}
            className={style.previewImage}
            onLoad={() => this.fitPreview()}
          />
        ) : (
          ''
        )}
        {dataUrl && fileType === 'video' ? (
          <video
            controls
            ref={(el) => {
              this._fitInner = el;
            }}
            src={dataUrl}
            className={style.previewImage}
            onCanPlay={() => this.fitPreview()}
          >
            Preview unsupported
          </video>
        ) : (
          ''
        )}
        {typeof value === 'string' && value.length > 0 && fileType === 'video' ? (
          <video
            controls
            ref={(el) => {
              this._fitInner = el;
            }}
            src={value}
            className={style.previewImage}
            onCanPlay={() => this.fitPreview()}
          >
            Preview unsupported
          </video>
        ) : (
          ''
        )}
        {(typeof value === 'string' && value.length > 0) || dataUrl ? this.renderDeleteButton() : null}
      </div>
    );
  };
}

export default withNamespaces(['content', 'common'], { wait: false })(SimpleFileInput);

SimpleFileInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  fileType: PropTypes.oneOf(['all', 'image', 'video']),
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  maxUploadSize: PropTypes.number,
  maxSizeForHumans: PropTypes.string,
  dark: PropTypes.bool,
  uploadProgress: PropTypes.number,
};

SimpleFileInput.defaultProps = {
  disabled: false,
  preview: true,
  dark: false,
};
