import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClick } from 'modules/CustomHooks';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import Menu from './Menu';
import OptionsGroup from './OptionsGroup';
import Chips from './Chips';

import style from './MultiSelect.scss';

const MultiSelect = ({ options, groups, selected, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false);
  });

  const onRemoveChip = (value, groupId, e) => {
    e.stopPropagation();

    setIsOpen(false);

    const isSelected = selected[groupId].includes(value);
    if (isSelected) {
      onChange(
        selected[groupId].filter((item) => item !== value),
        groupId,
      );
    } else {
      onChange([...selected[groupId], value], groupId);
    }
  };

  const getChipsSource = (groupId) =>
    selected[groupId].map((value) => options[groupId].find((option) => option.value === value));

  const isPlaceholderVisible = !Object.keys(groups).filter((groupId) => selected[groupId].length).length;

  return (
    <div ref={dropdownRef} className={style.component}>
      <button type="button" onClick={() => setIsOpen(!isOpen)} className={style.inputButton}>
        {isPlaceholderVisible ? (
          <span className={style.placeholder}>{placeholder}</span>
        ) : (
          <span className={style.chips}>
            {Object.keys(groups).map((groupId) => (
              <Chips
                key={`chips-${groupId}`}
                source={getChipsSource(groupId)}
                onDelete={(value, e) => onRemoveChip(value, groupId, e)}
              />
            ))}
          </span>
        )}
        <span className={style.icon}>
          <Icon iconType={IconTypes.ARROW_DROP_DOWN} color={IconColors.GRAY} />
        </span>
      </button>
      {isOpen && (
        <div className={style.options}>
          <Menu>
            {Object.keys(groups).map((groupId, index) => (
              <React.Fragment key={groupId}>
                {index !== 0 && <div className={style.divider} />}
                <OptionsGroup
                  key={groupId}
                  title={groups[groupId]}
                  options={options[groupId]}
                  selected={selected[groupId]}
                  onChange={(value) => {
                    setIsOpen(false);
                    onChange(value, groupId);
                  }}
                  multiple
                />
              </React.Fragment>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.shape({}),
  groups: PropTypes.shape({}),
  selected: PropTypes.shape({}),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

MultiSelect.defaultProps = {
  options: {},
  groups: {},
  selected: {},
  placeholder: '',
  onChange: () => {},
};

export default MultiSelect;
