import React from 'react';
import PropTypes from 'prop-types';

import { ICON_COLORS } from '../helpers';

const ArrowRight = ({ fill, height, width, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 24 24" className={className}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill={fill} d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
  </svg>
);

ArrowRight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

ArrowRight.defaultProps = {
  fill: ICON_COLORS.BLACK,
  height: 24,
  width: 24,
  className: '',
};

export default ArrowRight;
