import { handleActions } from 'redux-actions';
import { fromJS, List, Map } from 'immutable';
import * as at from 'actions/liteModeration';

export default handleActions(
  {
    [at.AUTH_SUCCESS]: (state, action) => ({
      ...state,
      userAssignmentId: action.userAssignmentId,
    }),
    [at.FETCH_CREATIVES_SUCCESS]: (state, action) => {
      return {
        ...state,
        creatives: fromJS(action.campaign),
      };
    },
    [at.FETCH_HISTORY_REQUEST]: (state) => ({
      ...state,
      isFetchingHistory: true,
    }),
    [at.FETCH_HISTORY_SUCCESS]: (state, action) => ({
      ...state,
      history: fromJS(action.history),
    }),
    [at.FETCH_HISTORY_COMPLETE]: (state) => ({
      ...state,
      isFetchingHistory: false,
    }),
    [at.MERGE_CREATIVE_INTO_CAMPAIGNS]: (state, action) => ({
      ...state,
      creatives: state.creatives.updateIn(['frame-specifications'], List(), (frameSpecs) =>
        frameSpecs.map((f) => {
          if (f.get('id') === action.creative.get('frame-specification-id')) {
            return f.updateIn(['creatives'], List(), (creatives) =>
              creatives.map((c) => {
                if (c.get('id') === action.creative.get('id')) {
                  return c.merge(action.creative);
                }
                return c;
              }),
            );
          }
          return f;
        }),
      ),
    }),
    [at.CLEAR_CREATIVES]: (state) => ({
      ...state,
      creatives: Map(),
    }),
    [at.FETCH_CREATIVE_REQUEST]: (state) => ({
      ...state,
      isFetchingCreative: true,
    }),
    [at.FETCH_CREATIVE_SUCCESS]: (state, action) => ({
      ...state,
      creative: state.creative.clear().merge(fromJS(action.creative)),
    }),
    [at.FETCH_CREATIVE_COMPLETE]: (state) => ({
      ...state,
      isFetchingCreative: false,
    }),
    [at.FETCH_CAMPAIGN_REQUEST]: (state) => ({
      ...state,
      isFetchingCampaign: true,
    }),
    [at.FETCH_CAMPAIGN_SUCCESS]: (state, action) => ({
      ...state,
      campaign: action.campaign,
    }),
    [at.FETCH_CAMPAIGN_COMPLETE]: (state) => ({
      ...state,
      isFetchingCampaign: false,
    }),
    [at.MERGE_PAGINATION_HISTORY]: (state, action) => ({
      ...state,
      paginationHistory: fromJS(action.pagination),
    }),
    [at.SET_ACTIVE_FRAME_SPEC]: (state, action) => ({
      ...state,
      activeFrameSpecId: action.activeFrameSpecId,
    }),
    FETCH_USER_PERMISSIONS_SUCCESS: (state, action) => ({
      ...state,
      creatives: state.creatives.updateIn(['user', 'permissions'], List(), (permissions) => {
        if (
          action.userId === state.creatives.get('user-id') &&
          action.object === 'App\\Campaign' &&
          action.objectId === state.creatives.get('campaign-id')
        ) {
          return permissions.clear().merge(action.permissions);
        }
        return permissions;
      }),
    }),
  },
  {
    activeFrameSpecId: '',
    campaign: new Map(),
    creatives: new Map(),
    creative: new Map(),
    history: List([]),
    isFetchingCreative: false,
    isFetchingCampaign: false,
    isFetchingHistory: false,
    isModerationSummaryActive: false,
    paginationDashboard: new Map(),
    paginationHistory: new Map(),
    moderationSummary: List([]),
    userAssignmentId: null,
  },
);
