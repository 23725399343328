import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { List, Map } from 'immutable';

import ProgressBar from 'components/patterns/ProgressBar';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';

import Screenshot from './Screenshot';

import style from './screenshots.scss';

class Screenshots extends Component {
  renderContent() {
    const { isFetching, screenshots, t } = this.props;

    if (isFetching) {
      return (
        <div className={style.progress}>
          <ProgressBar />
        </div>
      );
    }
    if (screenshots.size <= 0) {
      return <div className={style.pageContainer}>{t('There are no screenshots available for this frame')}</div>;
    }

    return (
      <div className={style.pageContainer}>
        {screenshots.map((screenshot, key) => (
          <Screenshot key={key} url={screenshot.get('url')} date={screenshot.get('date')} />
        ))}
      </div>
    );
  }

  getFrameInfo() {
    const { frame, isFetchingFrame } = this.props;
    if (isFetchingFrame) {
      return '';
    }
    return `${frame.get('external-id')} - ${frame.get('name')} ${frame.getIn([
      'specification',
      'name',
    ])}, ${frame.getIn(['specification', 'aspect-ratio'])},${frame.getIn(['specification', 'width'])}x${frame.getIn([
      'specification',
      'height',
    ])}, ${frame.getIn(['specification', 'rotation'])}`;
  }

  renderHeader() {
    const { t } = this.props;
    return <HeadingGroup title={t('Screenshots')} intro={this.getFrameInfo()} />;
  }

  getClassName() {
    const { className: parentClassName } = this.props;
    return style.screenshots.concat(' ', parentClassName);
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

export default withNamespaces(['common', 'frames'], { wait: true })(Screenshots);

Screenshots.propTypes = {
  className: PropTypes.string,
  frame: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
  isFetchingFrame: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  screenshots: PropTypes.instanceOf(List).isRequired,
};

Screenshots.defaultProps = {
  className: '',
};
