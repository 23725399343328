import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { withNamespaces } from 'react-i18next';
import style from './playlistRules.scss';

class PlaylistRules extends Component {
  render() {
    const { children } = this.props;

    return <div className={style.component}>{children}</div>;
  }
}

export default withNamespaces(['common', 'campaignPlaylists'], { wait: false })(PlaylistRules);

PlaylistRules.propTypes = {
  children: PropTypes.node,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  t: PropTypes.func,
};
