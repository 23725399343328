import Request from 'modules/API/request';
import { success, warning } from './notify';
import { mergeCampaignRelation, deleteRelationRequest } from './campaign';

export const SHOW_CONTENT_TYPE_ADD_FORM = 'SHOW_CONTENT_TYPE_ADD_FORM';
export const CLOSE_CONTENT_TYPE_ADD_FORM = 'CLOSE_CONTENT_TYPE_ADD_FORM';

export const ADD_CONTENT_TYPE_REQUEST = 'ADD_CONTENT_TYPE_REQUEST';
export const ADD_CONTENT_TYPE_SUCCESS = 'ADD_CONTENT_TYPE_SUCCESS';
export const ADD_CONTENT_TYPE_FAILURE = 'ADD_CONTENT_TYPE_FAILURE';

export const SHOW_CONTENT_TYPE_EDIT_FORM = 'SHOW_CONTENT_TYPE_EDIT_FORM';
export const CLOSE_CONTENT_TYPE_EDIT_FORM = 'CLOSE_CONTENT_TYPE_EDIT_FORM';

export const EDIT_CONTENT_TYPE_REQUEST = 'EDIT_CONTENT_TYPE_REQUEST';
export const EDIT_CONTENT_TYPE_SUCCESS = 'EDIT_CONTENT_TYPE_SUCCESS';
export const EDIT_CONTENT_TYPE_FAILURE = 'EDIT_CONTENT_TYPE_FAILURE';

export const CAMPAIGN_CONTENT_TYPE_PAGINATION_MERGE = 'CAMPAIGN_CONTENT_TYPE_PAGINATION_MERGE';

export const showContentTypeAddForm = () => ({
  type: SHOW_CONTENT_TYPE_ADD_FORM,
});

export const closeContentTypeAddForm = () => ({
  type: CLOSE_CONTENT_TYPE_ADD_FORM,
});

export const deleteContentTypeRequest = (campaignId, contentType) => (dispatch) =>
  dispatch(deleteRelationRequest(campaignId, 'content-types', ['content-types'], contentType));

const transformFormValuesForSending = (values, sendIds = false) => {
  const data = Object.assign({}, values);
  data.fields = data.elements;
  delete data.elements;
  data.fields = data.fields.map((field) => {
    if (field.fieldSettings) {
      field.meta = JSON.parse(field.fieldSettings);
      delete field.fieldSettings;
    }
    if (field.type === 'Select') {
      field.meta = {
        options: field.selectOptions.reduce((prev, curr) => {
          if (curr.value && curr.label) {
            prev[curr.value] = curr.label;
          }
          return prev;
        }, {}),
      };
      delete field.selectOptions;
    }
    if (sendIds && !field.newField) {
      field.id = field.uuid;
    }
    delete field.uuid;
    field.type = `App\\Fields\\${field.type}`;
    field.weight = field.order;
    delete field.order;
    return field;
  });
  return data;
};

export const contentTypeRequest = (campaignId, values, contentType) => (dispatch) => {
  if (contentType == null) {
    dispatch({
      type: ADD_CONTENT_TYPE_REQUEST,
    });
  } else {
    dispatch({
      type: EDIT_CONTENT_TYPE_REQUEST,
    });
  }

  let requestEndpoint = 'content-types';
  if (contentType !== null) {
    requestEndpoint += `/${contentType.get('id')}`;
  }

  const requestMethod = contentType == null ? 'POST' : 'PATCH';
  const requestData = transformFormValuesForSending(values, contentType !== null);

  return Request.send({
    endpoint: requestEndpoint,
    method: requestMethod,
    data: requestData,
    includes: ['fields'],
  })
    .then((response) => {
      dispatch(success('Content Type added'));
      dispatch(contentType == null ? closeContentTypeAddForm() : closeContentTypeEditForm());
      dispatch(contentType == null ? addContentTypeSuccess() : editContentTypeSuccess());
      dispatch(mergeCampaignRelation(campaignId, ['content-types'], response.data.body.parsed));
      return Promise.resolve();
    })
    .catch((e) => {
      dispatch(warning(contentType == null ? 'Unable to create Content Type' : 'Unable to update Content Type'));
      dispatch(contentType == null ? addContentTypeFailure() : editContentTypeFailure());

      return Promise.reject(e);
    });
};

export const addContentTypeSuccess = (contentType) => ({
  type: ADD_CONTENT_TYPE_SUCCESS,
  contentType,
});

export const addContentTypeFailure = (contentType) => ({
  type: ADD_CONTENT_TYPE_FAILURE,
  contentType,
});

export const showContentTypeEditForm = (contentType) => ({
  type: SHOW_CONTENT_TYPE_EDIT_FORM,
  contentType,
});

export const closeContentTypeEditForm = (contentType) => ({
  type: CLOSE_CONTENT_TYPE_EDIT_FORM,
  contentType,
});

export const editContentTypeSuccess = (contentType) => ({
  type: EDIT_CONTENT_TYPE_SUCCESS,
  contentType,
});

export const editContentTypeFailure = (contentType) => ({
  type: EDIT_CONTENT_TYPE_FAILURE,
  contentType,
});

export const mergePagination = (pagination) => ({
  type: CAMPAIGN_CONTENT_TYPE_PAGINATION_MERGE,
  pagination,
});
