import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Map } from 'immutable';
import FEATURES from 'features';
import { DISE } from 'app-constants';

import AlertBar, { AlertBarColors } from 'assets/components/presentational/Campaign/AlertBar';

const CampaignAlert = ({
  campaign,
  daysUntilLive,
  isCampaignAboutToGoLive,
  frameSpecificationsHaveAtLeastOneCreative,
  frameSpecificationsHaveAtLeastOneDefaultCreative,
  isFetchingFrameSpecifications,
  publishStatus,
  t,
}) => {
  const getAlertProps = () => {
    if (
      (!frameSpecificationsHaveAtLeastOneCreative || !frameSpecificationsHaveAtLeastOneDefaultCreative) &&
      !isCampaignAboutToGoLive
    ) {
      return {
        color: AlertBarColors.RED,
        text: t('Each frame specification must have at least one default creative.'),
      };
    }
    if (
      (!frameSpecificationsHaveAtLeastOneCreative || !frameSpecificationsHaveAtLeastOneDefaultCreative) &&
      isCampaignAboutToGoLive
    ) {
      return {
        color: AlertBarColors.RED,
        text: `${t('CampaignIsGoingLiveAlert', { count: daysUntilLive })} ${t(
          'Each frame specification must have at least one default creative.',
        )}`,
      };
    }
    if (FEATURES.PUBLISHING(campaign)) {
      if (publishStatus === DISE.DRAFT) {
        return {
          color: AlertBarColors.ORANGE,
          text: t('Unpublished changes'),
          link: `/campaigns/${campaign.get('id')}/publishing`,
        };
      }
    }
    if (
      frameSpecificationsHaveAtLeastOneCreative &&
      frameSpecificationsHaveAtLeastOneDefaultCreative &&
      isCampaignAboutToGoLive
    ) {
      return {
        color: AlertBarColors.RED,
        text: t('CampaignIsGoingLiveAlert', { count: daysUntilLive }),
      };
    }
    return null;
  };

  const renderCampaignAlert = () => {
    const showCampaignAlert =
      isCampaignAboutToGoLive ||
      publishStatus === DISE.DRAFT ||
      (!isFetchingFrameSpecifications &&
        (!frameSpecificationsHaveAtLeastOneCreative || !frameSpecificationsHaveAtLeastOneDefaultCreative));

    if (showCampaignAlert) {
      return <AlertBar {...getAlertProps()} />;
    }

    return null;
  };

  return renderCampaignAlert();
};

export default withNamespaces(['common', 'creative'], { wait: false })(CampaignAlert);

CampaignAlert.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  daysUntilLive: PropTypes.number,
  frameSpecificationsHaveAtLeastOneCreative: PropTypes.bool.isRequired,
  frameSpecificationsHaveAtLeastOneDefaultCreative: PropTypes.bool.isRequired,
  isCampaignAboutToGoLive: PropTypes.bool.isRequired,
  isFetchingFrameSpecifications: PropTypes.bool.isRequired,
  publishStatus: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CampaignAlert.defaultProps = {
  daysUntilLive: 0,
  publishStatus: '',
};
