import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

import { togglePinCampaign, fetchPinnedCampaigns } from 'store/pinnedCampaigns/actions';

import PinnedCampaigns from './PinnedCampaigns';

const mapStateToProps = (state) => ({
  pinnedCampaigns: state.pinned.campaigns,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      togglePinCampaign,
      fetchPinnedCampaigns,
    },
    dispatch,
  );

export default withNamespaces(['dashboardNew'], { wait: false })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PinnedCampaigns)),
);
