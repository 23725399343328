import config from 'app-config';
import React from 'react';
import PropTypes from 'prop-types';

const Redirect = ({ currentUrl }) => {
  const {
    app: { redirect, url },
  } = config;

  if (!redirect || !url) {
    return null;
  }

  const { origin: setOrigin } = new URL(url);
  const { origin, pathname, search } = new URL(currentUrl);

  if (setOrigin === origin) {
    return null;
  }

  return <meta httpEquiv="refresh" content={`0;url=${setOrigin}${pathname}${search}`} />;
};

Redirect.propTypes = {
  currentUrl: PropTypes.string.isRequired,
};

export default Redirect;
