import Request from 'modules/API/request';
import { notifyApiError } from 'actions/notify';

const RESPONSE_ERROR_STATUS = -1;

const fetchDeploymentStatus = () => async (dispatch) => {
  try {
    const options = { endpoint: 'systems/deploying' };
    const response = await Request.send(options);
    const status = response.data.body.parsed;
    return parseInt(status.value);
  } catch (error) {
    dispatch(notifyApiError(error));
    return RESPONSE_ERROR_STATUS;
  }
};

export { fetchDeploymentStatus };
