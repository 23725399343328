import React from 'react';
import PropTypes from 'prop-types';

import style from './Content.scss';

const Content = ({ isScrollable, className, children }) => {
  return <div className={`${isScrollable ? style.scrollable : ''} ${className}`}>{children}</div>;
};

Content.propTypes = {
  isScrollable: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Content.defaultProps = {
  isScrollable: false,
  className: '',
  children: null,
};

export default Content;
