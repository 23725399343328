import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './column.scss';

class Column extends Component {
  render() {
    const { children, flexProportion } = this.props;

    const flexStyle = flexProportion ? { flex: flexProportion } : { flex: 1 };

    return (
      <div className={style.pageColumn} style={flexStyle}>
        {children}
      </div>
    );
  }
}

export default Column;

Column.propTypes = {
  children: PropTypes.node,
  flexProportion: PropTypes.number,
};
