import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import ProgressBar from 'components/patterns/ProgressBar';
import moment from 'moment';
import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/Creative';
import { fetchCreativesFailedHistory } from 'actions/campaign/creative/failed';
import Table from 'assets/components/presentational/Table';

class CreativeFailedHistory extends Component {
  componentDidMount() {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;
    dispatch(fetchCreativesFailedHistory(campaignId));
  }

  renderMediaBuyHistoryTable = () => {
    const { isFetching, creatives, t } = this.props;

    if (isFetching) {
      return <ProgressBar />;
    }

    const model = {
      name: { type: String, title: 'Name' },
      user: { type: String, title: 'User' },
      date: { type: String, title: 'Upload Date (UTC)' },
    };

    const data = creatives
      .sortBy((c) => c.get('created-at'))
      .reverse()
      .map((history) => ({
        name: history.get('name'),
        user: history.getIn(['user', 'name'], '-'),
        date: moment(history.get('created-at')).format('DD MMM YYYY h:mm:ss a'),
      }))
      .toJS();

    if (creatives.count()) {
      return (
        <div>
          <Table model={model} source={data} />
        </div>
      );
    }
    return <p>{t('No processing failures to show.')}</p>;
  };

  render() {
    const {
      params: { campaignId },
      t,
      user,
    } = this.props;

    return (
      <div>
        <HeadingGroup
          intro={t('Creatives that have failed processing are listed here, limited to the 50 most recent.')}
          subNavigation={
            <SubNavigation active={SubNavigationLabels.FAILED_HISTORY} campaignId={campaignId} user={user} />
          }
          title={t('Creative')}
        />

        {this.renderMediaBuyHistoryTable()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.campaignCreativeFailed.isFetching,
  creatives: state.campaignCreativeFailed.creatives,
  user: state.auth.user,
});

export default withNamespaces(['common', 'creative', 'upload'], { wait: false })(
  connect(mapStateToProps)(CreativeFailedHistory),
);

CreativeFailedHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  creatives: PropTypes.instanceOf(List).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  params: PropTypes.object.isRequired,
};
