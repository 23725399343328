import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { List } from 'immutable';
import { selectContentType, closeContentItemForm, updateShouldAddAnotherContentItem } from 'actions/content';
import ConfigurableFormContainer from 'assets/components/containers/ConfigurableForm/ConfigurableFormContainer';
import Dropdown from 'assets/components/presentational/Dropdown';
import Link from 'assets/components/presentational/Link';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';

import style from './addContentItemForm.scss';

class AddContentItemForm extends Component {
  availableContentTypes() {
    const { availableContentTypes } = this.props;
    return availableContentTypes.toJS().map((contentItem) => ({
      value: contentItem,
      label: contentItem.name,
    }));
  }

  formConfig() {
    const { selectedContentType, t } = this.props;
    const form = {};
    form.fields = [];
    form.fields.unshift({
      name: t('Edit your content'),
      type: 'heading',
      slug: 'heading',
    });

    form.fields.unshift({
      name: t('Name your manual content block'),
      slug: 'name',
      type: 'text',
    });

    form.fields = form.fields.concat(selectedContentType.form.fields);

    return form;
  }

  handleContentTypeLinkClick = () => {
    const { dispatch } = this.props;

    dispatch(closeContentItemForm());

    return true;
  };

  handleCancelClick = (e) => {
    const { dispatch } = this.props;
    e.stopPropagation();
    e.preventDefault();
    dispatch(closeContentItemForm());
  };

  handleAddAnotherItem = (value) => {
    const { dispatch } = this.props;
    dispatch(updateShouldAddAnotherContentItem(value));
  };

  renderForm = () => {
    const { selectedContentType, shouldAddAnotherContentItem, isSaving, onSubmit, t } = this.props;

    if (selectedContentType) {
      return (
        <ConfigurableFormContainer
          name="addContentItemForm"
          formObject={this.formConfig()}
          submitting={isSaving}
          onCancel={this.handleCancelClick}
          onSubmit={onSubmit}
          onAddAnotherItem={this.handleAddAnotherItem}
          shouldAddAnotherContentItem={shouldAddAnotherContentItem}
        />
      );
    }

    return (
      <div className={style.buttonContainer}>
        <Button
          className={style.cancelButton}
          label={t('Cancel')}
          onClick={this.handleCancelClick}
          theme={ButtonThemes.WHITE}
        />
      </div>
    );
  };

  renderContentTypeDropdown = () => {
    const { selectedContentType, dispatch, t } = this.props;

    if (selectedContentType) {
      return null;
    }

    return (
      <Dropdown
        label={t('Select Content Type')}
        source={this.availableContentTypes()}
        onChange={(value) => {
          dispatch(selectContentType(value));
        }}
      />
    );
  };

  renderPrompt = () => {
    const { campaignId, selectedContentType } = this.props;

    if (selectedContentType) {
      return null;
    }

    return (
      <p>
        <Trans i18nKey="Choose an existing content type below or click here to add a new one">
          Choose an existing content type below or
          <Link onClick={this.handleContentTypeLinkClick} to={`campaigns/${campaignId}/content-types`}>
            click here
          </Link>
          to add a new one
        </Trans>
      </p>
    );
  };

  render() {
    return (
      <div className={style.component}>
        {this.renderPrompt()}
        {this.renderContentTypeDropdown()}
        {this.renderForm()}
      </div>
    );
  }
}

export default withNamespaces(['content', 'common'], { wait: false })(AddContentItemForm);

AddContentItemForm.propTypes = {
  campaignId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  availableContentTypes: PropTypes.instanceOf(List),
  selectedContentType: PropTypes.object,
  shouldAddAnotherContentItem: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

AddContentItemForm.defaultProps = {
  selectedContentType: null,
};
