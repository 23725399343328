import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { List, Map, fromJS } from 'immutable';
import { isEqual } from 'lodash';

import { triggerBodyClickToClose } from 'modules/Helpers';

import { setActiveContent } from 'actions/preview';

import ProgressBar from 'components/patterns/ProgressBar';
import Dropdown from 'assets/components/presentational/Dropdown';
import Heading from 'assets/components/presentational/Heading';
import Dialog from 'assets/components/presentational/Dialog';
import Autocomplete from 'assets/components/presentational/Autocomplete';
import Tag, { TagTheme } from 'assets/components/presentational/Tag';

import style from '../Preview.scss';

class SetContentWidget extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeContentTypeId: '',
      activeContentItemIds: List([]),
      isPrompting: false,
    };
  }

  componentDidMount() {
    const { activeContentItems, dispatch } = this.props;
    let { contentItems } = this.props;
    let activeContent = new Map();

    contentItems = contentItems.filter((contentItem) => activeContentItems.includes(contentItem.get('id')));
    contentItems.forEach((contentItem) => {
      const contentTypeId = contentItem.getIn(['content-type', 'id']);
      const existingContentItems = activeContent.get(contentTypeId, List([]));
      const updatedContentItems = existingContentItems.push(contentItem.get('id'));
      activeContent = activeContent.set(contentTypeId, updatedContentItems);
    });
    dispatch(setActiveContent(activeContent));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, activeContentItems, contentItems } = this.props;
    if (!isEqual(prevProps.activeContentItems, activeContentItems)) {
      const contentItemsFiltered = contentItems.filter((contentItem) => activeContentItems.has(contentItem.get('id')));
      const activeContent = Map();

      contentItemsFiltered.forEach((contentItem) => {
        const contentTypeId = contentItem.getIn(['content-type', 'id']);
        const existingContentItems = activeContent.get(contentTypeId, List([]));
        const updatedContentItems = existingContentItems.push(contentItem.get('id'));
        activeContent.set(contentTypeId, updatedContentItems);
      });
      dispatch(setActiveContent(activeContent));
    }
  }

  getAvailableContentTypesForDropdown = (contentTypes) => {
    let availableContentTypes = [];

    availableContentTypes = availableContentTypes.concat(
      contentTypes
        .map((contentType) =>
          contentType
            .clear()
            .set('label', contentType.get('name'))
            .set('value', contentType.get('id')),
        )
        .toJS(),
    );

    return availableContentTypes;
  };

  handleContentTypesChange = (contentTypeId) => {
    const { activeContent } = this.props;
    const activeContentItemIds = activeContent.get(contentTypeId, List([]));
    this.setState({
      activeContentTypeId: contentTypeId,
      isPrompting: true,
      activeContentItemIds,
    });
  };

  renderContentTypesDropdown() {
    const { activeCreative, isFetchingCreatives, t } = this.props;
    const { activeContentTypeId } = this.state;
    const contentTypes = activeCreative.get('content-types', List([]));
    if (contentTypes.size > 0) {
      return (
        <div className={style.contentTypes}>
          <span className={style.previewSelect}>
            <Dropdown
              allowBlank
              auto={false}
              className={style.dropDownSection}
              disabled={isFetchingCreatives}
              label={t('Preview with content')}
              onChange={(contentTypeId) => this.handleContentTypesChange(contentTypeId)}
              onBlur={triggerBodyClickToClose}
              source={this.getAvailableContentTypesForDropdown(contentTypes)}
              value={activeContentTypeId}
            />
          </span>
        </div>
      );
    }
  }

  handleConfirmAction = () => {
    const { activeContentTypeId, activeContentItemIds } = this.state;
    const { dispatch } = this.props;
    dispatch(setActiveContent(Map({ [activeContentTypeId]: activeContentItemIds })));
    this.setState({
      isPrompting: false,
      activeContentTypeId: '',
      activeContentItemIds: List([]),
    });
  };

  handleCancelAction = () => {
    this.setState({
      isPrompting: false,
      activeContentTypeId: '',
      activeContentItemIds: List([]),
    });
  };

  getAvailableContentItemsForSelect = () => {
    const { contentItems } = this.props;
    const { activeContentTypeId } = this.state;
    const availableContentItems = {};
    const activeContentItems = contentItems.filter(
      (contentItem) => contentItem.getIn(['content-type', 'id']) === activeContentTypeId,
    );
    activeContentItems.forEach((contentItem) => {
      availableContentItems[contentItem.get('id')] = contentItem.get('name');
    });

    return availableContentItems;
  };

  renderContentItemsSelect() {
    const { activeContentItemIds } = this.state;
    const { t } = this.props;

    return (
      <Autocomplete
        direction="down"
        onChange={this.handleContentItemsSelectChange}
        label={t('Preview with content')}
        source={this.getAvailableContentItemsForSelect()}
        value={activeContentItemIds.toJS()}
      />
    );
  }

  handleContentItemsSelectChange = (contentItemsIds) => {
    this.setState({
      activeContentItemIds: fromJS(contentItemsIds),
    });
  };

  getActiveContentTypeName = () => {
    const { activeContentTypeId } = this.state;
    const { activeCreative } = this.props;
    const contentTypes = activeCreative.get('content-types', List([]));
    const activeContentType = contentTypes.find((contentType) => contentType.get('id') === activeContentTypeId);
    const activeContentTypeName = activeContentType ? activeContentType.get('name') : null;
    return activeContentTypeName;
  };

  renderContentItemsDialog = () => {
    const { isPrompting } = this.state;
    const cancelAction = {
      label: 'cancel',
      onClick: this.handleCancelAction,
    };

    const confirmAction = {
      label: 'Confirm',
      onClick: this.handleConfirmAction,
    };
    const dialogActions = [cancelAction, confirmAction];

    return (
      <Dialog
        active={isPrompting}
        actions={dialogActions}
        className={style.dialog}
        onEscKeyDown={this.handleCancelAction}
        onOverlayClick={this.handleCancelAction}
      >
        <Heading size="medium" className={style.header}>
          <span>{this.getActiveContentTypeName()}</span>
        </Heading>
        {this.renderUpdatingProgress()}
        {this.renderContentItemsSelect()}
      </Dialog>
    );
  };

  renderUpdatingProgress = () => {
    const { isFetchingCreatives } = this.props;

    if (isFetchingCreatives) {
      return (
        <div className={style.updatingProgressContainer}>
          <ProgressBar />
        </div>
      );
    }
  };

  renderContentItemsTags = () => {
    let { activeContent, contentItems } = this.props;
    let activeContentItemIds = List([]);
    activeContent.valueSeq().forEach((v) => {
      activeContentItemIds = activeContentItemIds.concat(v.toJS());
    });
    contentItems = contentItems.filter((contentItem) => activeContentItemIds.includes(contentItem.get('id')));
    return contentItems.map((contentItem) => (
      <Tag
        key={contentItem.get('id')}
        className={style.contentItemTag}
        tagTheme={TagTheme.GREY}
        copy={contentItem.get('name')}
      />
    ));
  };

  render() {
    return (
      <div className={style.sideBarSection}>
        <div className={style.sideBarItem}>
          <div className={style.contentItemTags}>{this.renderContentItemsTags()}</div>
          {this.renderContentTypesDropdown()}
          {this.renderContentItemsDialog()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetchingCreatives: state.preview.isFetchingCreatives,
  contentItems: state.campaign.campaign.get('content-items', List()),
  activeContent: state.preview.activeContent,
  activeContentItems: state.preview.activeContentItemIds,
});

export default withNamespaces(['common', 'preview'], { wait: false })(connect(mapStateToProps)(SetContentWidget));

SetContentWidget.propTypes = {
  actions: PropTypes.instanceOf(List),
  activeCreative: PropTypes.instanceOf(Map),
  activeContent: PropTypes.instanceOf(Map),
  activeContentItems: PropTypes.instanceOf(List),
  dispatch: PropTypes.func.isRequired,
  contentItems: PropTypes.instanceOf(List).isRequired,
  isFetchingCreatives: PropTypes.bool,
  t: PropTypes.func,
};
