import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { reduxForm, reset } from 'redux-form';
import { Map } from 'immutable';
import { permissionsProfileEdited, permissionsProfileEditCancelled, updatePermissionsUser } from 'actions/users';
import { getUserFormInitialValues } from 'store/user/helpers';
import notify, { NotificationTypes } from 'actions/snackbar';
import UserFormProfile from 'assets/components/presentational/Users/UserFormProfile';

class UserFormUserEditContainer extends Component {
  handleCancel = () => {
    const { dispatch } = this.props;

    dispatch(permissionsProfileEditCancelled());
    dispatch(reset('userFormUserEdit'));
  };

  handleOnChange = () => {
    const { dispatch } = this.props;

    dispatch(permissionsProfileEdited());
  };

  handleUpdateUser = (data) => {
    const { t, dispatch, userId, isConvertingLiteToFull, handleConvertingLiteToFullSuccess } = this.props;

    if (isConvertingLiteToFull) {
      return dispatch(updatePermissionsUser(data, userId, isConvertingLiteToFull)).then(() => {
        dispatch(notify(NotificationTypes.SUCCESS, t('Lite user was successfully converted to a full user')));
        handleConvertingLiteToFullSuccess();
      });
    }

    return dispatch(updatePermissionsUser(data, userId)).then(() => {
      dispatch(notify(NotificationTypes.SUCCESS, t('User updated')));
    });
  };

  render() {
    const { isPermissionsProfileEdited, isUpdatingPermissionsUser, isConvertingLiteToFull } = this.props;
    const disableCancelButton = isUpdatingPermissionsUser || !isPermissionsProfileEdited;
    const disableSubmitButton = isUpdatingPermissionsUser || (!isPermissionsProfileEdited && !isConvertingLiteToFull);

    return (
      <UserFormProfile
        {...this.props}
        showActions
        isUserEdit
        handleCancel={this.handleCancel}
        handleOnChange={this.handleOnChange}
        handleUpdateUser={this.handleUpdateUser}
        isConvertingLiteToFull={isConvertingLiteToFull}
        disableCancelAction={disableCancelButton}
        disableSubmitAction={disableSubmitButton}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  isSuper: state.users.editableUser.get('is-super-user'),
  isLite: state.users.editableUser.get('is-lite-user'),
  initialValues: getUserFormInitialValues(state.users.editableUser),
  isPermissionsProfileEdited: state.users.isPermissionsProfileEdited,
  isUpdatingPermissionsUser: state.users.isUpdatingPermissionsUser,
});

export default withNamespaces(['common', 'users', 'errorCodes'], { wait: true })(
  connect(mapStateToProps)(
    reduxForm({
      form: 'userFormUserEdit',
      fields: ['name', 'email', 'isSubscribedToCampaignDigest'],
    })(UserFormUserEditContainer),
  ),
);

UserFormUserEditContainer.propTypes = {
  authUser: PropTypes.instanceOf(Map),
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  isPermissionsProfileEdited: PropTypes.bool.isRequired,
  isUpdatingPermissionsUser: PropTypes.bool,
  showActions: PropTypes.bool,
  isSuper: PropTypes.bool,
  isLite: PropTypes.bool,
  isUserEdit: PropTypes.bool,
  isConvertingLiteToFull: PropTypes.bool,
  handleConvertingLiteToFullSuccess: PropTypes.func.isRequired,
};
