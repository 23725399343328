import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { reduxForm } from 'redux-form';
import bowser from 'bowser';
import { v4 } from 'uuid';

import Dropdown from 'assets/components/presentational/Dropdown';
import Input from 'components/patterns/Input';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import AlertBar, { AlertBarColors } from 'assets/components/presentational/Campaign/AlertBar';

import FieldList from './FieldList';

import style from './contentTypeForm.scss';

class ContentTypeForm extends Component {
  constructor() {
    super();
    this.state = {
      elementSelector: 'Text',
      availableTypes: [
        {
          label: 'Simple Text',
          value: 'Text',
        },
        {
          label: 'Long Text',
          value: 'LongText',
        },
        {
          label: 'Video',
          value: 'Video',
        },
        {
          label: 'Image',
          value: 'Image',
        },
        {
          label: 'Date',
          value: 'Date',
        },
        {
          label: 'Single Select',
          value: 'Select',
        },
      ],
    };
  }

  handleReorder(orderOfItemBeingMoved, orderToInsertAt) {
    const { fields: _fields } = this.props;
    // Get a sorted field representation
    const fields = _fields.elements
      .map((field) => ({
        id: field.uuid.value,
        order: field.order.value,
      }))
      .sort((a, b) => a.order - b.order);

    // Don't move up out of range
    if (orderOfItemBeingMoved > orderToInsertAt && orderOfItemBeingMoved === fields[0].order) {
      return;
    }

    // Don't move down out of range
    if (orderOfItemBeingMoved < orderToInsertAt && orderOfItemBeingMoved === fields[fields.length - 1].order) {
      return;
    }

    // Do the move on this representation
    fields.splice(orderToInsertAt, 0, fields.splice(orderOfItemBeingMoved, 1)[0]);

    // Trigger on change on each field with the new index / order
    fields.forEach((field, i) => {
      _fields.elements.forEach((reduxFormField) => {
        if (field.id === reduxFormField.uuid.value) {
          reduxFormField.order.onChange(i);
        }
      });
    });
  }

  renderOrderPrompt = () => {
    if (!bowser.mobile && !bowser.tablet) {
      return (
        <Trans className={style.orderPrompt} i18nKey="You may order the fields below using the drag_handle icon">
          You may order the fields below using the
          <Icon iconType={IconTypes.DRAG_HANDLE} color={IconColors.LIGHTBLUE} />
          icon
        </Trans>
      );
    }
  };

  renderAlert = (text, color) => (
    <div className={style.alertBar}>
      <AlertBar text={text} color={color} />
    </div>
  );

  render() {
    const { fields, error, onCancel, handleSubmit, submitting, t } = this.props;
    const { availableTypes, elementSelector } = this.state;

    const isContentTypeEmpty = !fields.elements.length;
    return (
      <div className={style.component}>
        {isContentTypeEmpty && this.renderAlert(t('Content type must have at least one field'), AlertBarColors.RED)}
        <form>
          <Heading size={HeadingSizes.MEDIUM} tag={HeadingTags.h2}>
            {t('Content Type')}
          </Heading>
          {error ? <p className={style.error}>{error}</p> : null}
          <Input label={t('Name')} {...fields.name} />
          <div className={style.inlineFormRow}>
            <Dropdown
              label={t('Choose Field Type')}
              className={style.addFormElementDropdown}
              source={availableTypes}
              value={elementSelector}
              onChange={(value) => this.setState({ elementSelector: value })}
            />
            <div className={style.addFormElementButton}>
              <Button
                label={t('Add Field')}
                theme={ButtonThemes.GREEN}
                onClick={(event) => {
                  event.preventDefault();
                  fields.elements.addField({
                    name: '',
                    type: elementSelector,
                    order: fields.elements.length,
                    newField: true,
                    uuid: v4(),
                  });
                }}
              />
            </div>
          </div>
          {this.renderOrderPrompt()}
          <FieldList
            fields={fields.elements}
            availableTypes={availableTypes}
            onReorder={(orderOfItemBeingMoved, orderToInsertAt) =>
              this.handleReorder(orderOfItemBeingMoved, orderToInsertAt)
            }
          />
          <div className={style.actionGroup}>
            <Button
              label={t('Cancel all changes')}
              className={style.cancelButton}
              onClick={onCancel}
              theme={ButtonThemes.WHITE}
              disabled={submitting}
              type="button"
            />
            <Button
              label={t('Save and close')}
              className={style.saveButton}
              onClick={handleSubmit}
              disabled={submitting || isContentTypeEmpty}
              type="button"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm()(withNamespaces(['contentType', 'common'], { wait: false })(ContentTypeForm));

ContentTypeForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  error: PropTypes.string,
};

ContentTypeForm.defaultProps = {};
