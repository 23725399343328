import { handleActions } from 'redux-actions';
import moment from 'moment';

import { SNOOZE_OPTIONS } from 'app-constants';

import * as actions from './actions';

const getInitialState = () => ({
  selectedValue: SNOOZE_OPTIONS.UN_SNOOZE,
  campaign: {
    id: null,
    untilDate: null,
  },
});

const snoozeCampaign = handleActions(
  {
    [actions.SNOOZE_DROPDOWN_CHANGE]: (state, action) => {
      const {
        payload: { selectedValue, untilDate },
      } = action;
      return {
        ...state,
        selectedValue,
        campaign: { ...state.campaign, untilDate },
      };
    },
    [actions.FETCH_SNOOZED_CAMPAIGN_SUCCESS]: (state, action) => {
      const {
        payload: { campaignId, snoozeUntilDate, isCampaignSnoozed },
      } = action;

      let selectedValue = SNOOZE_OPTIONS.UN_SNOOZE;
      let untilDate = null;

      if (isCampaignSnoozed) {
        selectedValue = snoozeUntilDate ? SNOOZE_OPTIONS.UNTIL_CUSTOM_DATE : SNOOZE_OPTIONS.PERMANENTLY;
        untilDate = snoozeUntilDate ? moment(snoozeUntilDate).toDate() : null;
      }

      return {
        ...state,
        selectedValue,
        campaign: { ...state.campaign, id: campaignId, untilDate },
      };
    },
  },
  getInitialState(),
);

export default snoozeCampaign;
