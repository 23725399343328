import { handleActions } from 'redux-actions';
import { List } from 'immutable';

import { FETCH_REQUEST, FETCH_FAILURE, FETCH_SUCCESS } from 'actions/businessUnits';

const getInitialState = () => ({
  businessUnits: List(),
  isFetching: false,
});

const businessUnits = handleActions(
  {
    [FETCH_REQUEST]: (state) => ({
      ...state,
      isFetching: true,
    }),
    [FETCH_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      businessUnits: action.businessUnits,
    }),
    [FETCH_FAILURE]: (state) => ({
      ...state,
      isFetching: false,
    }),
  },
  getInitialState(),
);

export default businessUnits;
