export const summaryFilterItems = {
  live: [
    {
      id: 'live-total',
      filter: [{ live: true }],
      icon: '',
      isTotal: true,
      count: ({ summary }) => summary.getIn(['live', 'total']),
      copy: '{{count}} live-total',
      tooltip: 'All campaigns that have been set up in {{application}} that are currently in date.',
      showIf: () => true,
    },
    {
      id: 'live-missing-content',
      filter: [{ live: true }, { dashboard_workflow_stage: 'missing_content' }], // eslint-disable-line camelcase
      icon: 'error',
      count: ({ summary }) => summary.getIn(['live', 'missing-content']),
      copy: '{{count}} missing-content',
      summary: 'awaiting content',
      tooltip:
        'These are campaigns that have been set up in {{application}} that have one or more framespecs missing a default creative.',
      className: 'danger',
      showIf: ({ count }) => count > 0,
    },
    {
      id: 'live-pending-moderation',
      filter: [{ live: true }, { dashboard_workflow_stage: 'pending_moderation' }], // eslint-disable-line camelcase
      icon: 'error',
      count: ({ summary }) => summary.getIn(['live', 'pending-moderation']),
      copy: '{{count}} pending-moderation',
      summary: 'pending moderations',
      tooltip: 'These are campaigns that have creatives that have not been approved in {{application}}.',
      className: 'danger',
      showIf: ({ count }) => count > 0,
    },
    {
      id: 'live-awaiting-promotion',
      filter: [{ live: true }, { dashboard_workflow_stage: 'awaiting_promotion' }], // eslint-disable-line camelcase
      icon: 'error',
      count: ({ summary }) => summary.getIn(['live', 'awaiting-promotion']),
      copy: '{{count}} awaiting-promotion',
      summary: 'awaiting promotion',
      tooltip:
        'These are campaigns that have been booked automatically via BroadSign or VIOOH Automation, have default creatives that have been moderated and which can potentially be promoted to BroadSign for scheduling but have not yet been promoted.',
      className: 'danger',
      showIf: ({ count }) => count > 0,
    },
    {
      id: 'live-ready',
      filter: [{ live: true }, { dashboard_workflow_stage: 'ready' }], // eslint-disable-line camelcase
      icon: 'check_circle',
      count: ({ summary }) => summary.getIn(['live', 'ready']),
      copy: '{{count}} live',
      summary: 'that are live',
      tooltip: 'These are campaigns that are all set to go live if in date now, or will be live.',
      className: 'success',
      showIf: ({ count }) => count > 0,
    },
    {
      id: 'live-manual',
      filter: [{ live: true }, { dashboard_workflow_stage: 'manual' }], // eslint-disable-line camelcase
      icon: 'directions_walk',
      count: ({ summary }) => summary.getIn(['live', 'manual']),
      copy: '{{count}} manual',
      summary: 'that are manual',
      tooltip: 'These are campaigns that are manual.',
      className: 'neutral',
      showIf: ({ count }) => count > 0,
    },
  ],
  upcoming: [
    {
      id: 'upcoming-total',
      filter: [],
      icon: '',
      isTotal: true,
      count: ({ summary }) => summary.getIn(['upcoming', 'total']),
      copy: '{{count}} upcoming-total',
      tooltip: 'All campaigns that have been set up in {{application}} that are in the specified upcoming period.',
      showIf: () => true,
    },
    {
      id: 'upcoming-missing-content',
      filter: [{ dashboard_workflow_stage: 'missing_content' }], // eslint-disable-line camelcase
      icon: 'error',
      count: ({ summary }) => summary.getIn(['upcoming', 'missing-content']),
      copy: '{{count}} missing-content',
      summary: 'awaiting content',
      tooltip:
        'These are campaigns that have been set up in {{application}} that have one or more framespecs missing a default creative.',
      className: 'warning',
      showIf: () => true,
    },
    {
      id: 'upcoming-pending-moderation',
      filter: [{ dashboard_workflow_stage: 'pending_moderation' }], // eslint-disable-line camelcase
      icon: 'error',
      count: ({ summary }) => summary.getIn(['upcoming', 'pending-moderation']),
      copy: '{{count}} pending-moderation',
      summary: 'pending moderations',
      tooltip: 'These are campaigns that have creatives that have not been approved in {{application}}.',
      className: 'warning',
      showIf: () => true,
    },
    {
      id: 'upcoming-awaiting-promotion',
      filter: [{ dashboard_workflow_stage: 'awaiting_promotion' }], // eslint-disable-line camelcase
      icon: 'error',
      count: ({ summary }) => summary.getIn(['upcoming', 'awaiting-promotion']),
      copy: '{{count}} awaiting-promotion',
      summary: 'awaiting promotion',
      tooltip:
        'These are campaigns that have been booked automatically via BroadSign or VIOOH Automation, have default creatives that have been moderated and which can potentially be promoted to BroadSign for scheduling but have not yet been promoted.',
      className: 'warning',
      showIf: ({ count }) => count > 0,
    },
    {
      id: 'upcoming-ready',
      filter: [{ dashboard_workflow_stage: 'ready' }], // eslint-disable-line camelcase
      icon: 'check_circle',
      count: ({ summary }) => summary.getIn(['upcoming', 'ready']),
      copy: '{{count}} ready',
      summary: 'that are ready',
      tooltip: 'These are campaigns that are all set to go live if in date now, or will be live.',
      className: 'success',
      showIf: () => true,
    },
    {
      id: 'upcoming-manual',
      filter: [{ dashboard_workflow_stage: 'manual' }], // eslint-disable-line camelcase
      icon: 'directions_walk',
      count: ({ summary }) => summary.getIn(['upcoming', 'manual']),
      copy: '{{count}} manual',
      summary: 'that are manual',
      tooltip: 'These are campaigns that are manual.',
      className: 'neutral',
      showIf: ({ count }) => count > 0,
    },
  ],
};
