import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearCampaign, fetchCampaign, setActiveCampaignId, setCampaignPublishStatus } from 'actions/campaign';
import {
  bookingDcmCompleted,
  bookingDcmFailed,
  bookingPromotionCompleted,
  bookingPromotionFailed,
} from 'store/bookings/actions';
import { updateMyRecentCampaignsStore } from 'actions/dashboard';
import { fetchFrameSpecifications } from 'actions/creative';

import { isCampaignFullyPromoted } from 'store/bookings/selectors';

import Campaign from './Campaign';

const mapStateToProps = (state) => ({
  activeSection: state.campaignNavigation.activeSection,
  campaign: state.campaign.campaign,
  contentCompleting: state.campaign.contentCompleting,
  isInitialFetch: state.campaign.isInitialFetch,
  isFetchingCampaign: state.campaign.isFetching,
  promoting: state.campaign.promoting,
  user: state.auth.user,
  isFullyPromoted: isCampaignFullyPromoted(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCampaign,
      fetchCampaign,
      setActiveCampaignId,
      setCampaignPublishStatus,
      bookingPromotionCompleted,
      bookingPromotionFailed,
      bookingDcmCompleted,
      bookingDcmFailed,
      updateMyRecentCampaignsStore,
      fetchFrameSpecifications,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
