import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { withNamespaces } from 'react-i18next';
import bowser from 'bowser';

import { DragSource, DropTarget } from 'react-dnd';
import Input from 'components/patterns/Input';
import Button, { ButtonThemes } from 'assets/components/presentational/Button';
import CardPrompt from 'assets/components/presentational/CardPrompt';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';

import SelectOptions from '../SelectOptions';
import FieldSettings from '../FieldSettings';

import style from './field.scss';

const fieldSource = {
  beginDrag(props) {
    return {
      fieldId: props.field.uuid.value,
      order: props.field.order.value,
      onReorder: props.onReorder,
    };
  },
};

const fieldTarget = {
  hover(props, monitor, component) {
    const dragOrder = monitor.getItem().order;
    // const dragUuid = monitor.getItem().fieldId
    const hoverOrder = props.field.order.value;
    // const hoverUuid = props.field.uuid.value

    // console.log("Props passed to hover", props)
    // console.log("Order and uuid of dragging item", dragOrder, dragUuid)
    // console.log("Order and uuid of item hovered over", hoverOrder, hoverUuid)

    // Don't replace items with themselves
    if (dragOrder === hoverOrder) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
      return;
    }

    // // Time to actually perform the action
    props.onReorder(dragOrder, hoverOrder);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.

    monitor.getItem().order = hoverOrder;
    // NOTE: I think this is telling dnd that the dragging item now has a new order, after the order has
    // been changed
  },
};

function targetCollect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class Field extends Component {
  constructor() {
    super();
    this.state = {
      showDeletePrompt: false,
    };
  }

  render() {
    const {
      field,
      connectDragSource,
      isDragging,
      connectDropTarget,
      availableTypes,
      onMoveUpClick,
      onMoveDownClick,
      onDelete,
      t,
    } = this.props;
    const { showDeletePrompt } = this.state;

    const availableType = availableTypes.find((item) => item.value === field.type.value);
    return connectDropTarget(
      <div
        className={style.element}
        style={{
          opacity: isDragging ? 0.2 : 1,
        }}
      >
        {connectDragSource(
          <div className={bowser.mobile || bowser.tablet ? style.hidden : style.dragHandle}>
            <Icon iconType={IconTypes.DRAG_HANDLE} color={IconColors.LIGHTBLUE} />
          </div>,
        )}

        {availableType && <p>{availableType.label}</p>}
        <Input label={t('Name')} {...field.name} onDragOver={(e) => e.stopPropagation()} />
        <div>
          <Button
            className={style.deleteButton}
            theme={ButtonThemes.DANGER}
            icon={<Icon iconType={IconTypes.DELETE} color={IconColors.WHITE} />}
            onClick={(event) => {
              event.preventDefault();
              this.setState({ showDeletePrompt: true });
            }}
          />
          {bowser.mobile || bowser.tablet ? (
            <Button
              className={style.arrowButton}
              theme={ButtonThemes.GREY}
              icon={<Icon iconType={IconTypes.ARROW_UPWARD} color={IconColors.WHITE} />}
              onClick={onMoveUpClick}
            />
          ) : null}
          {bowser.mobile || bowser.tablet ? (
            <Button
              className={style.arrowButton}
              theme={ButtonThemes.GREY}
              icon={<Icon iconType={IconTypes.ARROW_DOWNWARD} color={IconColors.WHITE} />}
              onClick={onMoveDownClick}
            />
          ) : null}
        </div>
        <br />
        <br />

        {field.type.value === 'Select' ? (
          <Button
            label={t('Add option')}
            onClick={(event) => {
              event.preventDefault();
              field.selectOptions.addField();
            }}
          />
        ) : null}

        {field.type.value === 'Select' ? <SelectOptions selectOptions={field.selectOptions} /> : null}

        <FieldSettings fieldType={field.type.value} fieldSettings={field.fieldSettings} />

        <CardPrompt
          onDeleteConfirmClick={(event) => {
            event.preventDefault();
            this.setState({
              showDeletePrompt: false,
            });
            onDelete();
          }}
          onDeleteCancelClick={(event) => {
            event.preventDefault();
            this.setState({ showDeletePrompt: false });
          }}
          showDeletePrompt={showDeletePrompt}
        />
      </div>,
    );
  }
}

export default DropTarget(
  'field',
  fieldTarget,
  targetCollect,
)(DragSource('field', fieldSource, sourceCollect)(withNamespaces(['contentType', 'common'], { wait: false })(Field)));

Field.propTypes = {
  field: PropTypes.object,
  onDelete: PropTypes.func,
  availableTypes: PropTypes.array,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onReorder: PropTypes.func,
  onMoveUpClick: PropTypes.func,
  onMoveDownClick: PropTypes.func,
  t: PropTypes.func,
};
