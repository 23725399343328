import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-toolbox/lib/progress_bar';

import styles from './ProgressBar.scss';

const propTypes = {
  circular: PropTypes.bool,
};

const defaultProps = {
  circular: false,
};

const CustomProgressBar = ({ circular }) => (
  <ProgressBar theme={styles} type={circular ? 'circular' : 'linear'} mode="indeterminate" />
);

CustomProgressBar.propTypes = propTypes;
CustomProgressBar.defaultProps = defaultProps;

export default CustomProgressBar;
