import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { debounce, isEqual } from 'lodash';

import { redirect, getURLQueryString, validatePageNumber } from 'modules/Helpers';

import { userHasCampaignPermission } from 'store/user/helpers';

import { clearCampaignRelation, deleteRelationRequest } from 'actions/campaign';
import { setActiveSection } from 'actions/campaign/navigation';
import {
  closeDrawer,
  fetchRuleSets,
  fetchRuleSetForSettings,
  fetchRuleSetForEdit,
  fetchRuleTypes,
  syncRelations,
  toggleUnsaveChangesDialog,
  toggleShowDrawer,
} from 'actions/ruleSets';

import { getContentItems, getCreatives, getFrameSpecifications, getRuleSets } from 'selectors/campaign';

import ProgressBar from 'components/patterns/ProgressBar';
import Input from 'components/patterns/Input';

import HeadingGroup from 'assets/components/presentational/HeadingGroup';
import Dialog from 'assets/components/presentational/Dialog';
import Pagination from 'assets/components/presentational/Pagination';
import SubNavigation, { SubNavigationLabels } from 'assets/components/presentational/SubNavigation/RuleSets';
import Create from 'assets/components/presentational/Rows/Create';
import RuleSetRow from 'assets/components/presentational/Campaign/Rulesets/RuleSetRow';
import Icon, { IconTypes, IconColors } from 'assets/components/presentational/Icon';
import Heading, { HeadingSizes, HeadingTags } from 'assets/components/presentational/Heading';

import RuleSetSettings from './RuleSetSettings';
import RuleSetDrawerContainer from './RuleSetDrawerContainer';

import style from './rulesets.scss';

class RuleSets extends Component {
  constructor(props, context) {
    super(props, context);

    this.sortingOrder = {
      ASC: 'ASC',
      DESC: 'DESC',
      NONE: 'NONE',
    };

    this.state = {
      // The rule prompting deletion
      prompting: new Map(),
      selectedRuleSets: new List(),
    };
  }

  componentDidMount() {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(setActiveSection('rules'));
    dispatch(fetchRuleTypes(campaignId));
    this.fetchRuleSets();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { campaignId: prevCampaignId, pageNumber: prevPageNumber },
    } = prevProps;
    const {
      params: { campaignId, pageNumber },
      ruleSets,
    } = this.props;

    const isFilterChanged = this.isFilterChanged(prevProps);
    if (
      ruleSets.size > 15 ||
      prevCampaignId !== campaignId ||
      validatePageNumber(pageNumber) !== validatePageNumber(prevPageNumber) ||
      isFilterChanged
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.fetchRuleSets();
    }
  }

  componentWillUnmount() {
    const {
      dispatch,
      params: { campaignId },
    } = this.props;

    dispatch(clearCampaignRelation(campaignId, ['rule-sets']));
  }

  isFilterChanged = (prevProps) => {
    const {
      location: { query: currentFilters },
    } = prevProps;
    const {
      location: { query: nextFilters },
    } = this.props;

    return !isEqual(currentFilters, nextFilters);
  };

  redirectTo = (queryString, pageNumber) => {
    const {
      params: { campaignId },
    } = this.props;
    const shouldReplaceHistory = true;
    redirect(`/campaigns/${campaignId}/rules/${pageNumber}${queryString}`, shouldReplaceHistory);
  };

  fetchRuleSets = debounce(() => {
    const {
      dispatch,
      params: { campaignId, pageNumber },
      location: {
        query: { filter, sortBy, sortOrder },
      },
    } = this.props;

    const filters = filter ? [{ name: `%${filter}%` }] : [];
    const sorts = [];
    if (sortBy && sortOrder !== this.sortingOrder.NONE) {
      const prefix = sortOrder === this.sortingOrder.DESC ? '-' : '';
      if (sortBy === 'created-at') {
        sorts.push(`${prefix}created_at`);
      } else if (sortBy === 'updated-at') {
        sorts.push(`${prefix}updated_at`);
      } else {
        sorts.push(`${prefix}${sortBy}`);
      }
    }

    dispatch(clearCampaignRelation(campaignId, ['rule-sets']));
    dispatch(fetchRuleSets(campaignId, pageNumber, filters, sorts));
  }, 500);

  handlePaginationClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;
    const queryString = getURLQueryString();
    this.redirectTo(queryString, pageNumber);
  };

  handleEditRuleSetClick = (ruleSet) => {
    const { campaign, dispatch } = this.props;

    return dispatch(fetchRuleSetForEdit(campaign.get('id'), ruleSet.get('id')));
  };

  handleEditRuleSetCancelClick = () => {
    this.handleToggleUnsavedChangesDialog(true);
  };

  handleRuleSetSettingsClick = (ruleSet) => {
    const { dispatch, campaign } = this.props;

    return dispatch(fetchRuleSetForSettings(campaign.get('id'), ruleSet.get('id')));
  };

  handleNewRuleSetClick = () => {
    const { dispatch } = this.props;
    dispatch(toggleShowDrawer('edit'));
  };

  handleDeleteRuleSetConfirmClick = (ruleSet) => {
    const { campaign, dispatch } = this.props;
    const { prompting } = this.state;
    this.setState({
      prompting: prompting.clear(),
    });
    // Delete forever
    return dispatch(deleteRelationRequest(campaign.get('id'), 'rule-sets', ['rule-sets'], ruleSet));
  };

  handleDeleteRuleSetClick = (ruleSet) => {
    this.setState({
      prompting: ruleSet,
    });
  };

  handleDeleteRuleSetCancelClick = () => {
    const { prompting } = this.state;

    this.setState({
      prompting: prompting.clear(),
    });
  };

  handleToggleUnsavedChangesDialog = (active) => {
    const { dispatch } = this.props;

    return dispatch(toggleUnsaveChangesDialog(active));
  };

  handleCreativesChange = (ruleSet, creatives) => {
    const { campaign, dispatch } = this.props;

    dispatch(syncRelations(campaign.get('id'), ruleSet, creatives, 'creatives'));
  };

  handleContentItemsChange = (ruleSet, contentItems) => {
    const { campaign, dispatch } = this.props;

    dispatch(syncRelations(campaign.get('id'), ruleSet, contentItems, 'content-items'));
  };

  handleDrawerClose = (type) => {
    const { dispatch } = this.props;

    dispatch(closeDrawer(type));
  };

  renderRuleSets = () => {
    const { isFetchingCampaign, isFetchingRuleSets } = this.props;

    return (
      <div>
        {this.renderRuleSetHeader()}
        {isFetchingCampaign || isFetchingRuleSets ? (
          <>
            <div className={style.ruleRowPlaceholder} />
            <ProgressBar />
          </>
        ) : (
          this.renderRuleSetBody()
        )}
      </div>
    );
  };

  renderRuleSetHeader = () => {
    const { campaign, t, user } = this.props;
    const userHasPermission = userHasCampaignPermission(user, campaign.get('id'), 'edit_rules');

    if (!userHasPermission) {
      return null;
    }

    return (
      <Create createLabel={t('Create a new rule')} onCreateClick={this.handleNewRuleSetClick}>
        {this.renderFilters()}
      </Create>
    );
  };

  renderRuleSetBody = () => {
    const {
      t,
      ruleSets,
      location: {
        query: { filter },
      },
    } = this.props;

    if (!ruleSets.size) {
      const emptyRulesMessage = filter
        ? t('No rules match your search criteria, try refining your search.')
        : t('There are no rules at the moment.');
      return this.renderEmptyMessage(emptyRulesMessage);
    }
    return ruleSets.map((ruleSet, key) => this.renderRuleSet(ruleSet, key));
  };

  renderEmptyMessage = (text) => (
    <span>
      <Heading className={style.emptyData} tag={HeadingTags.H2} size={HeadingSizes.SMALL}>
        {text}
      </Heading>
    </span>
  );

  renderRuleSet = (ruleSet, ruleSetKey) => {
    const { campaign, user } = this.props;
    const { prompting, selectedRuleSets } = this.state;

    return (
      <RuleSetRow
        campaign={campaign}
        isDeleting={prompting.get('id') === ruleSet.get('id')}
        key={ruleSetKey}
        isSelected={selectedRuleSets.indexOf(ruleSet) !== -1}
        isDeletable={userHasCampaignPermission(user, campaign.get('id'), 'edit_rules')}
        isEditable={userHasCampaignPermission(user, campaign.get('id'), 'edit_rules')}
        isConfigurable={
          userHasCampaignPermission(user, campaign.get('id'), 'target') &&
          userHasCampaignPermission(user, campaign.get('id'), 'edit_rules')
        }
        isSelectable={false}
        onDeleteClick={this.handleDeleteRuleSetClick}
        onDeleteCancelClick={this.handleDeleteRuleSetCancelClick}
        onDeleteConfirmClick={this.handleDeleteRuleSetConfirmClick}
        onEditClick={this.handleEditRuleSetClick}
        onSettingsClick={this.handleRuleSetSettingsClick}
        ruleSet={ruleSet}
        user={user}
      />
    );
  };

  renderEditDrawer = () => {
    const { activeRuleSet, ruleTypes } = this.props;

    return (
      <RuleSetDrawerContainer
        onCloseClick={() => this.handleDrawerClose('edit')}
        onCancelClick={this.handleEditRuleSetCancelClick}
        ruleSet={activeRuleSet}
        ruleTypes={ruleTypes}
      />
    );
  };

  renderSettingsDrawer = () => {
    const {
      activeRuleSet,
      campaign,
      contentItems,
      creatives,
      frameSpecifications,
      isFetchingRuleSet,
      isSavingRuleSet,
      showSettingsDrawer,
    } = this.props;

    return (
      <RuleSetSettings
        active={showSettingsDrawer}
        campaign={campaign}
        creatives={creatives}
        contentItems={contentItems}
        frameSpecifications={frameSpecifications}
        fetching={isFetchingRuleSet}
        saving={isSavingRuleSet}
        onCloseClick={() => this.handleDrawerClose('settings')}
        onCreativesChange={this.handleCreativesChange}
        onContentItemsChange={this.handleContentItemsChange}
        ruleSet={activeRuleSet}
      />
    );
  };

  renderUnsavedChangesDialog = () => {
    const { isUnsavedChangesDialogActive, t } = this.props;
    const actions = [
      {
        label: t('Yes'),
        onClick: () => {
          this.handleDrawerClose('edit');
          this.handleToggleUnsavedChangesDialog(false);
        },
      },
      {
        label: t('No'),
        onClick: () => {
          this.handleToggleUnsavedChangesDialog(false);
        },
      },
    ];

    return (
      <Dialog
        active={isUnsavedChangesDialogActive}
        actions={actions}
        title={t('Are you sure you wish to cancel all changes?')}
        type="small"
      />
    );
  };

  renderPagination = () => {
    const {
      params: { pageNumber },
      isFetchingRuleSets,
      pagination,
    } = this.props;

    const currentPage = validatePageNumber(pageNumber) - 1;

    return (
      <div>
        {pagination.get('total_pages') > 1 ? (
          <Pagination
            forceSelected={currentPage}
            initialSelected={currentPage}
            hidden={isFetchingRuleSets}
            onClick={this.handlePaginationClick}
            pageNum={pagination.get('total_pages')}
          />
        ) : null}
      </div>
    );
  };

  renderSortingIcons = (sortingOrder = this.sortingOrder.NONE) => {
    if (sortingOrder !== this.sortingOrder.NONE) {
      return (
        <Icon
          iconType={sortingOrder === this.sortingOrder.ASC ? IconTypes.ARROW_DROP_UP : IconTypes.ARROW_DROP_DOWN}
          color={IconColors.WHITE}
        />
      );
    }

    return (
      <span className={style.unsort}>
        <Icon iconType={IconTypes.ARROW_DROP_UP} color={IconColors.WHITE} />
        <Icon iconType={IconTypes.ARROW_DROP_DOWN} color={IconColors.WHITE} />
      </span>
    );
  };

  sortHandler = (sortBy) => () => {
    const {
      location: {
        query: { sortBy: currentSortBy, sortOrder: currentSortOrder },
      },
    } = this.props;

    const sortOrder =
      sortBy === currentSortBy && currentSortOrder === this.sortingOrder.ASC
        ? this.sortingOrder.DESC
        : this.sortingOrder.ASC;
    const queryString = getURLQueryString({ sortBy, sortOrder });

    this.redirectTo(queryString, 1);
  };

  renderSortItem = (icon, label, key) => {
    const {
      location: {
        query: { sortBy, sortOrder },
      },
    } = this.props;

    return (
      <button type="button" className={style.sort} id={key} onClick={this.sortHandler(key)}>
        <Icon iconType={icon} color={IconColors.WHITE} />
        <span className={style.sortLabel}>{label}</span>
        {this.renderSortingIcons(sortBy === key ? sortOrder : this.sortingOrder.NONE)}
      </button>
    );
  };

  filterChangeHandler = (value) => {
    const queryString = getURLQueryString({ filter: value });
    this.redirectTo(queryString, 1);
  };

  renderFilters = () => {
    const {
      t,
      location: {
        query: { filter },
      },
      ruleSets,
    } = this.props;

    const isSearchingDisabled = !ruleSets.size && !filter;
    return (
      <div className={style.filterContainer}>
        <div className={style.sortContainer}>
          <span className={style.sort}>{t('SORT RULES:')}</span>
          {this.renderSortItem(IconTypes.SORT_BY_ALPHA, t('Rule name'), 'name')}
          {this.renderSortItem(IconTypes.DATE_RANGE, t('Date created'), 'created-at')}
          {this.renderSortItem(IconTypes.EVENT_NOTE, t('Last updated'), 'updated-at')}
        </div>
        <div className={style.searchContainer}>
          <Input
            className={style.searchInput}
            type="text"
            hint={t('Search rules')}
            icon={<Icon iconType={IconTypes.SEARCH} color={isSearchingDisabled ? IconColors.GRAY : IconColors.WHITE} />}
            value={filter || ''}
            onChange={this.filterChangeHandler}
            disabled={isSearchingDisabled}
          />
        </div>
      </div>
    );
  };

  render() {
    const { campaign, user, t } = this.props;

    return (
      <div>
        <HeadingGroup
          intro={t('Rules section introduction')}
          subNavigation={
            <SubNavigation active={SubNavigationLabels.ALL_RULESETS} campaignId={campaign.get('id')} user={user} />
          }
          title={t('Rules')}
        />
        {this.renderRuleSets()}
        {this.renderPagination()}
        {this.renderEditDrawer()}
        {this.renderSettingsDrawer()}
        {this.renderUnsavedChangesDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeRuleSet: getRuleSets(state).find((r) => r.get('id') === state.ruleSets.activeRuleSetId, null, new Map()),
  campaign: state.campaign.campaign,
  contentItems: getContentItems(state),
  creatives: getCreatives(state),
  frameSpecifications: getFrameSpecifications(state.campaign.campaign),
  isFetchingCampaign: state.campaign.isFetching,
  isFetchingRuleSet: state.ruleSets.isFetchingRuleSet,
  isFetchingRuleSets: state.ruleSets.isFetchingRuleSets,
  isSavingRuleSet: state.ruleSets.isSavingRuleSet,
  isUnsavedChangesDialogActive: state.ruleSets.isUnsavedChangesDialogActive,
  ruleSets: getRuleSets(state),
  ruleTypes: state.ruleSets.ruleTypes,
  showEditDrawer: state.ruleSets.showEditDrawer,
  showSettingsDrawer: state.ruleSets.showSettingsDrawer,
  pagination: state.ruleSets.pagination,
  user: state.auth.user,
});

export default withNamespaces(['common', 'rules'], { wait: false })(withRouter(connect(mapStateToProps)(RuleSets)));

RuleSets.propTypes = {
  activeRuleSet: PropTypes.instanceOf(Map).isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  contentItems: PropTypes.instanceOf(List).isRequired,
  creatives: PropTypes.instanceOf(List).isRequired,
  dispatch: PropTypes.func.isRequired,
  frameSpecifications: PropTypes.instanceOf(List).isRequired,
  isFetchingCampaign: PropTypes.bool.isRequired,
  isFetchingRuleSet: PropTypes.bool.isRequired,
  isFetchingRuleSets: PropTypes.bool.isRequired,
  isSavingRuleSet: PropTypes.bool.isRequired,
  isUnsavedChangesDialogActive: PropTypes.bool.isRequired,
  params: PropTypes.object,
  ruleSets: PropTypes.instanceOf(List).isRequired,
  ruleTypes: PropTypes.instanceOf(List).isRequired,
  showSettingsDrawer: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  pagination: PropTypes.instanceOf(Map).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      filter: PropTypes.string,
      sortBy: PropTypes.string,
      sortOrder: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
