import Request from 'modules/API/request';
import { omit } from 'lodash';
import notify, { NotificationTypes } from 'actions/snackbar';
import {
  fetchCampaignRelation,
  fetchCampaignRelations,
  mergeCampaignRelation,
  clearCampaignRelation,
  syncCampaignRelation,
} from 'actions/campaign';
import { notifyApiError } from 'actions/notify';

export const UPDATE_CONTENT_ITEMS = 'UPDATE_CONTENT_ITEMS';

export const EDIT_CONTENT_ITEM = 'EDIT_CONTENT_ITEM'; // show the form
export const CLOSE_CONTENT_ITEM_FORM = 'CLOSE_CONTENT_ITEM_FORM'; // close the form
export const UPDATE_CONTENT_ITEM_REQUEST = 'UPDATE_CONTENT_ITEM_REQUEST'; // start update request
export const UPDATE_CONTENT_ITEM_FAILURE = 'UPDATE_CONTENT_ITEM_FAILURE'; // update fails

export const ADD_CONTENT_ITEM = 'ADD_CONTENT_ITEM'; // show the form
export const SHOULD_ADD_ANOTHER_CONTENT_ITEM = 'SHOULD_ADD_ANOTHER_CONTENT_ITEM'; // add another item when the first is done
export const SELECT_CONTENT_TYPE = 'SELECT_CONTENT_TYPE'; // choose which content type the new content item should have

export const CREATE_CONTENT_ITEM_REQUEST = 'CREATE_CONTENT_ITEM_REQUEST'; // start the create request
export const CREATE_CONTENT_ITEM_COMPLETE = 'CREATE_CONTENT_ITEM_COMPLETE'; // create completes

export const CAMPAIGN_CONTENT_RESET = 'CAMPAIGN_CONTENT_RESET';
export const CAMPAIGN_CONTENT_PAGINATION_MERGE = 'CAMPAIGN_CONTENT_PAGINATION_MERGE';

export const CAMPAIGN_CONTENT_TOGGLE_SHOW_DRAWER = 'CAMPAIGN_CONTENT_TOGGLE_SHOW_DRAWER';

export const CAMPAIGN_CONTENT_SET_ACTIVE_CONTENT_ITEM_ID = 'CAMPAIGN_CONTENT_SET_ACTIVE_CONTENT_ITEM_ID';

export const SET_CONTENT_SEARCH_VALUE = 'SET_CONTENT_SEARCH_VALUE'; // search by content name
export const SET_CONTENT_TYPE_FILTER = 'SET_CONTENT_TYPE_FILTER'; // filter by content type
export const SET_MODERATION_STATUS_FILTER = 'SET_MODERATION_STATUS_FILTER'; // filter by moderation status

export const fetchContentItemForSettings = (campaignId, contentItemId) => (dispatch) => {
  dispatch(toggleShowDrawer('settings'));

  return Promise.all([
    dispatch(fetchCampaignRelation(campaignId, 'content-items', contentItemId, ['rule-sets'])),
    dispatch(fetchCampaignRelations(campaignId, 'rule-sets')),
  ]).then((responses) => {
    const contentItem = responses[0];
    dispatch(setActiveContentItemId(contentItem.get('id')));
  });
};

export const fetchContentItems = (
  campaignId,
  pageNumber = 1,
  { contentTypeId = null, contentItemName = null, modStatus = null } = {},
) => (dispatch) => {
  const filters = [];

  if (contentTypeId) {
    filters.push({ content_type_id: contentTypeId }); // eslint-disable-line camelcase
  }

  if (contentItemName) {
    filters.push({ 'content_items.name': `%${contentItemName}%` });
  }

  if (modStatus) {
    filters.push({ 'content_items.status': modStatus });
  }

  dispatch(clearCampaignRelation(campaignId, ['content-items']));

  return dispatch(
    fetchCampaignRelations(campaignId, 'content-items', {
      includes: ['rule-sets'],
      pageNumber,
      sorts: ['-created_at'],
      filters,
    }),
  ).then((response) => dispatch(mergePagination(response.data.body.meta.pagination)));
};

export const setActiveContentItemId = (contentItemId) => ({
  type: CAMPAIGN_CONTENT_SET_ACTIVE_CONTENT_ITEM_ID,
  contentItemId,
});

export const toggleShowDrawer = (component) => ({
  type: CAMPAIGN_CONTENT_TOGGLE_SHOW_DRAWER,
  component,
});

export const updateContentItems = (contentItems) => ({
  type: UPDATE_CONTENT_ITEMS,
  contentItems,
});

export const editContentItem = (contentItem) => ({
  type: EDIT_CONTENT_ITEM,
  contentItemId: contentItem.get('id'),
});

export const closeContentItemForm = () => ({
  type: CLOSE_CONTENT_ITEM_FORM,
});

export const syncRuleSets = (campaignId, contentItem, ruleSets) => (dispatch) =>
  dispatch(syncCampaignRelation(campaignId, ['content-items'], contentItem, 'content-items', ruleSets, 'rule-sets'));

export const mergePagination = (pagination) => ({
  type: CAMPAIGN_CONTENT_PAGINATION_MERGE,
  pagination,
});

export const resetState = () => ({
  type: CAMPAIGN_CONTENT_RESET,
});

/*
 ************
 * UPDATING / EDITING SINGLE
 ********* */

export const updateContentItemRequest = (campaignId, values, dispatch, contentItem) =>
  new Promise((resolve, reject) => {
    const contentItemId = contentItem.get('id');

    if (Object.keys(values).length === 0) {
      dispatch({
        type: CLOSE_CONTENT_ITEM_FORM,
      });
      return resolve();
    }

    dispatch({
      type: UPDATE_CONTENT_ITEM_REQUEST,
      contentItemId,
    });

    return assembleFieldData(values)
      .then((fieldData) =>
        Request.send({
          endpoint: `content-items/${contentItemId}`,
          method: 'PATCH',
          data: extractFieldValuesToContentFieldRelationship(fieldData),
          extraSerializerConfiguration: {
            attributes: ['name', 'content_type_id', 'content-fields'],
            'content-fields': {
              ref: (contentItem, contentField) => contentField.id,
              attributes: ['value'],
            },
          },
        }),
      )
      .then((response) => {
        dispatch({
          type: CLOSE_CONTENT_ITEM_FORM,
        });
        dispatch(mergeCampaignRelation(campaignId, ['content-items'], response.data.body.parsed));
        dispatch(notify(NotificationTypes.SUCCESS, 'Content Item Updated'));
        return resolve();
      })
      .catch((error) => {
        dispatch(notifyApiError(error));
        dispatch(updateContentItemFailure());

        return reject(error);
      });
  });

const containsFiles = (values) => {
  const fieldIds = Object.keys(values);
  for (let i = 0; i < fieldIds.length; i++) {
    if (values[fieldIds[i]] instanceof FileList || values[fieldIds[i]] instanceof File) {
      return true;
    }
  }
  return false;
};

const assembleFieldData = (values) => {
  if (!containsFiles(values)) {
    // If there no files we can resolve straight away
    return Promise.resolve(values);
  }

  const fieldIds = Object.keys(values);
  const filePromises = [];

  for (let i = 0; i < fieldIds.length; i++) {
    if (values[fieldIds[i]] instanceof File) {
      filePromises.push(
        getFileDataAsBase64(values[fieldIds[i]]).then((fileData) => {
          const result = {};
          result[fieldIds[i]] = fileData;
          return result;
        }),
      );
    }
    if (values[fieldIds[i]] instanceof FileList) {
      filePromises.push(
        getFileDataAsBase64(values[fieldIds[i]][0]).then((fileData) => {
          const result = {};
          result[fieldIds[i]] = fileData;
          return result;
        }),
      );
    }
  }

  return Promise.all(filePromises).then((files) => Object.assign(values, ...files));
};

const extractFieldValuesToContentFieldRelationship = (values) => {
  const transformed = {};
  if (values.id) {
    transformed.id = values.id;
  }
  if (values.content_type_id) {
    transformed.content_type_id = values.content_type_id; // eslint-disable-line camelcase
  }
  if (values.name) {
    transformed.name = values.name;
  }
  const contentFields = omit(values, ['id', 'name', 'content_type_id']);
  transformed['content-fields'] = Object.keys(contentFields).map((id) => ({
    id,
    value: contentFields[id],
  }));
  return transformed;
};

const getFileDataAsBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => resolve(event.target.result);
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });

export const updateContentItemFailure = () => ({
  type: UPDATE_CONTENT_ITEM_FAILURE,
});
/*
 ************
 * END UPDATING / EDITING SINGLE
 ********* */

/*
 ************
 * ADD SINGLE
 ********* */

export const addContentItem = () => ({
  type: ADD_CONTENT_ITEM,
});

export const updateShouldAddAnotherContentItem = (addAnother) => ({
  type: SHOULD_ADD_ANOTHER_CONTENT_ITEM,
  addAnother,
});

export const selectContentType = (contentType) => ({
  type: SELECT_CONTENT_TYPE,
  contentType,
});

export const createContentItemRequest = (campaignId, values, dispatch, contentTypeId, shouldAddAnotherContentItem) =>
  new Promise((resolve, reject) => {
    // if no values, close the form
    if (Object.keys(values).length === 0) {
      dispatch({
        type: CLOSE_CONTENT_ITEM_FORM,
      });
      return resolve();
    }

    // tells form it is submitting and disables form elements
    dispatch({
      type: CREATE_CONTENT_ITEM_REQUEST,
    });

    // post the new content items to api
    return (
      assembleFieldData(
        Object.assign(values, {
          content_type_id: contentTypeId, // eslint-disable-line camelcase
        }),
      )
        .then((fieldData) =>
          Request.send({
            endpoint: 'content-items',
            method: 'POST',
            data: extractFieldValuesToContentFieldRelationship(fieldData),
            extraSerializerConfiguration: {
              included: false,
              attributes: ['name', 'content_type_id', 'content-fields'],
              'content-fields': {
                ref: (contentItem, contentField) => contentField.id,
                attributes: ['value'],
              },
            },
          }),
        )
        // after posted
        .then((response) => {
          dispatch(mergeCampaignRelation(campaignId, ['content-items'], response.data.body.parsed));
          dispatch({
            type: CLOSE_CONTENT_ITEM_FORM,
          });
          dispatch(notify(NotificationTypes.SUCCESS, 'Content Item Created'));
          return resolve();
        })
        .catch((error) => {
          dispatch(notifyApiError(error));

          return reject(error);
        })
        .then(() => {
          dispatch({
            type: CREATE_CONTENT_ITEM_COMPLETE,
          });

          // if the user has checked to add another item, reload the form
          if (shouldAddAnotherContentItem) {
            dispatch({
              type: ADD_CONTENT_ITEM,
            });
          }
        })
        .catch(() => {
          dispatch({
            type: CREATE_CONTENT_ITEM_COMPLETE,
          });
        })
    );
  });
/*
 ************
 * END ADD SINGLE
 ********* */

/*
 ************
 * CONTENT FILTER
 ********* */

export const setContentSearchValue = (searchValue) => ({
  type: SET_CONTENT_SEARCH_VALUE,
  searchValue,
});

export const setContentTypeFilter = (filter) => ({
  type: SET_CONTENT_TYPE_FILTER,
  filter,
});

export const setModerationStatusFilter = (filter) => ({
  type: SET_MODERATION_STATUS_FILTER,
  filter,
});

/*
 ************
 * END CONTENT FILTER
 ********* */
